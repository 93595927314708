/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import formatDateTable from '../../../constants/dateFormatTable';
import { setNoteData } from "../../../redux/reducers/loanReducer";
import {readAppState}from "../../../redux/reducers/appReducer"
import NoteEdit from "./NoteEdit";
import GenericAgGrid, { getCurrentFilterValue } from "../../common/GenericAgGrid";

const Notes = (props: any) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const [isEdit, setEditVisibility] = useState(false);
    const appState = useAppSelector(readAppState);
    const gridNotesRef: any = useRef();
    // const gridRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'NOTES');

    useEffect(() => {}, [activeTab]);

   

    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }

    const columns = [
        {
            headerName: 'Note#', field: 'notes_id', width: 150,filter:'agNumberColumnFilter', cellRendererFramework: (params) =>
                <a className="link-primary"  onClick={() => { setEditVisibility(true); preserveFilter(params.data) }}>{params.data.notes_id}</a>
        },
        { headerName: 'Note Type Category', field: 'note_type_category', width: 260 },
        { headerName: 'Note Type', field: 'sbs_note_type_description', width: 260 },
        { headerName: 'Note Text', field: 'note_text', width: 360 },
        { headerName: 'Created By', field: 'sbs_created_by', width: 150 },
        { headerName: 'Created Date', field: 'sbs_created_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Modified By', field: 'sbs_modified_by', width: 150 },
        { headerName: 'Modified Date', field: 'sbs_modified_date',filter: 'agDateColumnFilter', width: 150,valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'High Importance', field: 'is_high_importance', width: 150, hide: true }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    const preserveFilter=(params: any)=>{
        let filterValue=getCurrentFilterValue(gridNotesRef);

        dispatch(setNoteData({sbs_note_type_description:params.sbs_note_type_description,is_high_importance:params.is_high_importance,note_text:params.note_text,  current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel, saved_sort:filterValue.SortState , is_new: false,is_edit:true }));
        
    }

    const afterGridLoad=()=>{
        if((!loanState.noteData?.is_edit) && (loanState.noteData?.saved_sort || loanState.noteData?.saved_filter)){
            gridNotesRef.current.columnApi.applyColumnState({
                    state: loanState.noteData.saved_sort,
              });
              gridNotesRef.current.api.setFilterModel(loanState.noteData.saved_filter);
              dispatch(setNoteData({ ...loanState?.noteData, is_edit:true,current_page:0}));
              
        }else{
            dispatch(setNoteData({ ...loanState?.noteData, saved_sort: null,saved_filter:null,current_page:0}));
        }
    }

    return (
        <div >

            <div style={{display:!isEdit?"block":"none"}}>  
                {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridNotesRef}
                            afterGridLoad={afterGridLoad}
                            currentPage={loanState.noteData?.current_page}
                        ></GenericAgGrid>
                    </div>}
                
            </div>
            {isEdit && <NoteEdit data={loanState.noteData} toggle={() => setEditVisibility(false)} />}
        </div>
    )
};

export default Notes;