export const visualTypeIcons = {
    RAW_DATA_TABLE: "fal fa-table",
    DONUT: "fad fa-spinner-third",
    KPI: "far fa-tachometer",
    UBER_BARS: "fas fa-chart-bar",
    HEAT_MAP: "fad fa-th",
    US_MAP: "fad fa-globe-americas",
    ARC: "fas fa-spinner-third",
    LINE_CHART: "fas fa-chart-line",
    FLOATING_BUBBLES: "fas fa-chart-scatter",
    PIE: "fas fa-chart-pie-alt",
    PIVOT_TABLE: "fas fa-border-style-alt",
    LIST_FILTER: "fad fa-list-alt",
    MULTI_METRIC_BARS: "fas fa-analytics",
    TREE_MAP: "fas fa-th-large",
    LINE_CHART_CONTINUOUS: "fas fa-chart-line",
    COMBO_CHART: "fas fa-analytics",
    LINE_AND_BARS: "fas fa-analytics",
    WORD_CLOUD: "fas fa-file-word",
    BOX_PLOT: "fal fa-chart-bar",
    BUBBLES: "fas fa-chart-scatter",
    CUSTOM: "far fa-user-chart"
}

export const ReportSortingValues = {
    ReportNameAtoZ: "Name A-Z",
    ReportNameZtoA: "Name Z-A",
    CreatedDateAscending: "Created Date Ascending",
    CreatedDateDescending: "Created Date Descending",
    ModifiedDateAscending: "Modified Date Ascending",
    ModifiedDateDescending: "Modified Date Descending"
}

export const ReportSortingTypes = [
    "Name A-Z",
    "Name Z-A",
    "Created Date Ascending",
    "Created Date Descending",
    "Modified Date Ascending",
    "Modified Date Descending"
];


export const DashboardSortingValues = {
    DashboardNameAtoZ: "Name A-Z",
    DashboardNameZtoA: "Name Z-A",
    ModifiedDateAscending: "Modified Date Ascending",
    ModifiedDateDescending: "Modified Date Descending"
}

export const DashboardSortingTypes = [
    "Name A-Z",
    "Name Z-A",
    "Modified Date Ascending",
    "Modified Date Descending"
];