/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGridTotalCountAndData = /* GraphQL */ `
  query GetGridTotalCountAndData(
    $view_name: String
    $where: String
    $order_by: String
    $offset: Int
    $row_fetch: Int
  ) {
    getGridTotalCountAndData(
      view_name: $view_name
      where: $where
      order_by: $order_by
      offset: $offset
      row_fetch: $row_fetch
    )
  }
`;
export const getGridTotalCountAndDataVertica = /* GraphQL */ `
  query GetGridTotalCountAndDataVertica(
    $view_name: String
    $where: String
    $order_by: String
    $offset: Int
    $row_fetch: Int
  ) {
    getGridTotalCountAndDataVertica(
      view_name: $view_name
      where: $where
      order_by: $order_by
      offset: $offset
      row_fetch: $row_fetch
    )
  }
`;
export const getAllGridCodes = /* GraphQL */ `
  query GetAllGridCodes {
    getAllGridCodes
  }
`;
export const getGridDetails = /* GraphQL */ `
  query GetGridDetails($grid_code: String) {
    getGridDetails(grid_code: $grid_code)
  }
`;
export const getLoanSummaryDetails = /* GraphQL */ `
  query GetLoanSummaryDetails($loan_number: String) {
    getLoanSummaryDetails(loan_number: $loan_number) {
      default_reason_description
      due_and_payable_date
      notification_sent_to_borrower_date
      death_date
      servicer_notified_of_death_date
      hud_notified_of_default_date
      order_title_date
      title_received_date
      order_default_appraisal_date
      default_appraisal_effective_date
      default_appraisal_received_date
      inspection_ordered_date
      inspection_received_date
      property_location_type
      auto_90_day_extension
      number_of_approved_extensions
      due_and_payable_loan_balance
      extension_expiration_date
      foreclosure_refer_to_council_date
      first_legal_due_date
      first_legal_filed_date
      hud_notice_of_initiation_date
      appraisal_ordered_date
      appraisal_effective_date
      appraisal_received_date
      liquidation_event
      sale_date
      sold_to
      deed_recorded_date
      data_gram_complete_date
      refer_to_reo_date
      number_of_days_in_bankruptcy
      number_of_bankruptcies
      bankruptcy_type
      bankruptcy_filed_date
      bankruptcy_discharged_date
      marketable_title_date
      reo_sale_date
      reo_sale_price
      reo_net_proceeds
      referred_to_claims_date
      original_transfer_property_charge
      current_transfer_property_charge_balance
      property_charge_pre_due_and_payable_balance
      corporate_advance_s305_flood_insurance_balance
      corporate_advance_s305_hazard_insurance_balance
      corporate_advance_s305_taxes_balance
      corporate_advance_s305_ground_rent_balance
      corporate_advance_s305_hoa_balance
      tots_balance
      active_repayment_plan
      payment_status
      number_repayments_made
      number_repayments_remaining
    }
  }
`;
export const listLoanDetails = /* GraphQL */ `
  query ListLoanDetails(
    $filter: [FilterInput]
    $skip: Int
    $row_fetch: Int
    $fields: String
    $order: String
    $scope_id: ID
  ) {
    listLoanDetails(
      filter: $filter
      skip: $skip
      row_fetch: $row_fetch
      fields: $fields
      order: $order
      scope_id: $scope_id
    )
  }
`;
export const listWorkflowDocumentsByFilter = /* GraphQL */ `
  query ListWorkflowDocumentsByFilter(
    $filter: [FilterInput]
    $skip: Int
    $row_fetch: Int
    $scope_id: ID
  ) {
    listWorkflowDocumentsByFilter(
      filter: $filter
      skip: $skip
      row_fetch: $row_fetch
      scope_id: $scope_id
    ) {
      loan_number
      sbs_status_description
      days_issue_pending
    }
  }
`;
export const getRuleOutputTotalrowsByProcessId = /* GraphQL */ `
  query GetRuleOutputTotalrowsByProcessId($process_id: Int) {
    getRuleOutputTotalrowsByProcessId(process_id: $process_id)
  }
`;
export const getpaymentshortage = /* GraphQL */ `
  query Getpaymentshortage($loan_id: String) {
    getpaymentshortage(loan_id: $loan_id)
  }
`;
export const getLogiAnalyticsToken = /* GraphQL */ `
  query GetLogiAnalyticsToken($data: AWSJSON) {
    getLogiAnalyticsToken(data: $data) {
      access_token
      expires_in
      token_type
    }
  }
`;
export const getAllLoansStatus = /* GraphQL */ `
  query GetAllLoansStatus($scope_id: String) {
    getAllLoansStatus(scope_id: $scope_id)
  }
`;
export const getAllLoans = /* GraphQL */ `
  query GetAllLoans {
    getAllLoans
  }
`;
export const getAllLoanAssetMgmt = /* GraphQL */ `
  query GetAllLoanAssetMgmt {
    getAllLoanAssetMgmt
  }
`;
export const getBorrowerPaymentInfoAssetMgmt = /* GraphQL */ `
  query GetBorrowerPaymentInfoAssetMgmt($loan_id: String) {
    getBorrowerPaymentInfoAssetMgmt(loan_id: $loan_id)
  }
`;
export const getLoanDataAssetMgmt = /* GraphQL */ `
  query GetLoanDataAssetMgmt($loan_id: String, $scope_id: ID) {
    getLoanDataAssetMgmt(loan_id: $loan_id, scope_id: $scope_id)
  }
`;
export const getLoanNotesAssetMgmt = /* GraphQL */ `
  query GetLoanNotesAssetMgmt($loan_id: String) {
    getLoanNotesAssetMgmt(loan_id: $loan_id)
  }
`;
export const getMaasLoansByFilter = /* GraphQL */ `
  query GetMaasLoansByFilter($input: LoanFilterInput, $scope_id: ID) {
    getMaasLoansByFilter(input: $input, scope_id: $scope_id)
  }
`;
export const getPropertyDetails = /* GraphQL */ `
  query GetPropertyDetails($sbs_loan_number: String, $scope_id: ID) {
    getPropertyDetails(sbs_loan_number: $sbs_loan_number, scope_id: $scope_id) {
      loan_number
      property_address_1
      property_address_2
      property_city
      property_state
      property_zip
      folio_number
      sbs_county_clerk_name
      sbs_property_type_description
      sbs_property_location_type_description
      legal_description
      number_of_units
      occupancy_date
      securing_ordered_date
      vacancy_date
      is_owner_occupied
      sbs_flood_zone_description
      flood_insurance_company_id
      flood_certificate_number
      is_requires_flood_insurance
      is_life_of_loan_policy
      sbs_created_by
      sbs_created_date
      sbs_modified_by
      sbs_modified_date
      most_recent_appraisal_value
      last_known_occupancy_date
    }
  }
`;
export const getLoanDetails = /* GraphQL */ `
  query GetLoanDetails($loan_number: String, $scope_id: ID) {
    getLoanDetails(loan_number: $loan_number, scope_id: $scope_id) {
      loan_number
      sbs_loan_number
      gnma_loan_number
      fha_case_number
      investor_loan_number
      sbs_product_type_description
      is_hud_insured
      is_securitized
      sbs_loan_status_description
      sbs_loan_sub_status_description
      monthly_payment_amount
      sbs_payment_plan_description
      arm_type
      current_interest_rate
      expected_interest_rate
      closing_date
      funding_date
      mic_date
      mers_min
      hermit_skey
      active_campaign_type
      last_borrower_contact_date
      debenture_interest_rate
      borrower_name
      coborrower_name
      property_address
      mailing_address
      home_phone
      death_date
      borrower_age
      estimated_death_date
      servicer_name
      original_loan_balance
      investor_name
      total_funds_available
      investor_pool_name
      maximum_claim_amount
      current_max_claim_percentage
      loan_balance_at_due_and_payable_date
      curtailment_event_missed
      estimated_curtailment_amount
      loss_reserve_amount
      sum_of_specific_advances
      payoff_quote_date
      payoff_quote_amount
      prior_servicer_name
      prior_servicer_loan_number
      service_transfer_date
      max_claim_transfer_percent
      current_loan_balance
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($loan_number: String, $offset: Int, $page_size: Int) {
    getNotes(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      loan_number
      notes_id
      note_type_category
      note_type_description
      sbs_note_type_description
      is_high_importance
      note_text
      sbs_created_by
      sbs_created_date
      sbs_modified_by
      sbs_modified_date
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($loan_number: String, $offset: Int, $page_size: Int) {
    getComments(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      server_time
      loan_comment_id
      loan_number
      comment_type
      comment_description
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const getLoanTransaction = /* GraphQL */ `
  query GetLoanTransaction(
    $loan_number: String
    $offset: Int
    $page_size: Int
  ) {
    getLoanTransaction(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      loan_number
      loan_transaction_id
      transaction_date
      effective_date
      transaction_code
      transaction_type
      principal_amount
      interest_amount
      mip_amount
      service_fee_amount
      unscheduled_disbursement_accrual_amount
      servicer_expense_amount
    }
  }
`;
export const getPaymentAndBalance = /* GraphQL */ `
  query GetPaymentAndBalance($loan_number: String) {
    getPaymentAndBalance(loan_number: $loan_number) {
      original_principal_limit
      current_principal_limit_balance
      current_principal_balance
      current_interest_balance
      current_mip_balance
      current_service_fee_balance
      current_loan_balance
      current_service_fee_set_aside_balance
      repair_set_aside_amount
      first_year_set_aside_amount
      lesa_amount
      current_net_principal_limit_balance
      lesa_type
      lesa_payment_status_description
      lesa_semi_annual_amount
      current_tax_insurance_withheld_balance
      imip_paid_by_borrower_amount
      imip_paid_by_lender_amount
      current_idl_net_credit_line
      payment_plan_description
      payment_plan_status_description
      months_remaining_in_term
      net_monthly_payment
      maximum_claim_amount
      monthly_service_fee_amount
      is_print_statements
      is_prohibit_all_correspondence
      loan_number
    }
  }
`;
export const getDisbursements = /* GraphQL */ `
  query GetDisbursements($loan_number: String, $offset: Int, $page_size: Int) {
    getDisbursements(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      sbs_disbursement_type_description
      voided
      payment_stop
      is_reimbursable
      check_amount
      disbursed_date
      check_id
      pay_to
      is_estimated
      disburesed
    }
  }
`;
export const getPaymentScheduleByLoanIdAssetMgmt = /* GraphQL */ `
  query GetPaymentScheduleByLoanIdAssetMgmt($loan_id: String) {
    getPaymentScheduleByLoanIdAssetMgmt(loan_id: $loan_id)
  }
`;
export const getPaymentReceivedByLoanIdAssetMgmt = /* GraphQL */ `
  query GetPaymentReceivedByLoanIdAssetMgmt($loan_id: String) {
    getPaymentReceivedByLoanIdAssetMgmt(loan_id: $loan_id)
  }
`;
export const getLoanBorrowerDetailAssetMgmt = /* GraphQL */ `
  query GetLoanBorrowerDetailAssetMgmt($loan_id: String) {
    getLoanBorrowerDetailAssetMgmt(loan_id: $loan_id)
  }
`;
export const getLoanTransactionByLoanIdAssetMgmt = /* GraphQL */ `
  query GetLoanTransactionByLoanIdAssetMgmt(
    $loan_id: String
    $from_date: String
    $to_date: String
  ) {
    getLoanTransactionByLoanIdAssetMgmt(
      loan_id: $loan_id
      from_date: $from_date
      to_date: $to_date
    )
  }
`;
export const getNewBorrowerPaymentInformation = /* GraphQL */ `
  query GetNewBorrowerPaymentInformation($loan_id: String, $due_date: String) {
    getNewBorrowerPaymentInformation(loan_id: $loan_id, due_date: $due_date)
  }
`;
export const getPaymentReceiveDetails = /* GraphQL */ `
  query GetPaymentReceiveDetails($payment_received_id: Int) {
    getPaymentReceiveDetails(payment_received_id: $payment_received_id)
  }
`;
export const listNavigationConfigurations = /* GraphQL */ `
  query ListNavigationConfigurations {
    listNavigationConfigurations {
      status_id
      loan_status
      screen_id
      name
      path
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const getNavigationByLoanStatus = /* GraphQL */ `
  query GetNavigationByLoanStatus($loan_status: String) {
    getNavigationByLoanStatus(loan_status: $loan_status) {
      status_id
      loan_status
      screen_id
      name
      path
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const getUsersWithRoles = /* GraphQL */ `
  query GetUsersWithRoles {
    getUsersWithRoles {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
      user_password
      role_id
      role_name
    }
  }
`;
export const getScreensWithRoles = /* GraphQL */ `
  query GetScreensWithRoles {
    getScreensWithRoles {
      screen_id
      screen_name
      icon_url
      redirect_url
      is_active
      is_hidden
      display_order
      screen_type
      dashboard_id
      role_id
      role_name
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
export const listBuckets = /* GraphQL */ `
  query ListBuckets {
    listBuckets {
      file_automation_bucket_detail_id
      file_type
      bucket_name
      bucket_path
      inserted_date
      inserted_by
      updated_date
      updated_by
    }
  }
`;
export const listScreens = /* GraphQL */ `
  query ListScreens {
    listScreens {
      screen_id
      screen_name
      icon_url
      redirect_url
      is_active
      is_hidden
      display_order
      screen_type
      dashboard_id
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles {
    listRoles {
      role_id
      role_name
      created_date
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups {
    listGroups {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const listDashboards = /* GraphQL */ `
  query ListDashboards {
    listDashboards {
      dashboard_id
      dashboard_name
      logi_dashboard_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports {
    listReports {
      report_id
      report_name
      logi_report_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const listServicers = /* GraphQL */ `
  query ListServicers {
    listServicers {
      servicer_id
      servicer_name
      servicer_bucket
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments {
    listDocuments {
      document_upload_detail_id
      document_name
      uploaded_date
      s3_bucket_name
      inserted_date
      inserted_by
      updated_date
      updated_by
    }
  }
`;
export const getUsersWithAllDetails = /* GraphQL */ `
  query GetUsersWithAllDetails {
    getUsersWithAllDetails {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
      role_id
      role_name
      group_id
      group_name
      dashboards_list
      reports_list
      data_sources_list
      rule_group_id
      profile_path
      created_date
    }
  }
`;
export const getCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($user_id: String) {
    getCurrentUserDetails(user_id: $user_id) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
      role_id
      role_name
      group_id
      group_name
      dashboards_list
      reports_list
      data_sources_list
      rule_group_id
      profile_path
      created_date
    }
  }
`;
export const getOutputTemplateTableData = /* GraphQL */ `
  query GetOutputTemplateTableData($output_template_id: Int) {
    getOutputTemplateTableData(output_template_id: $output_template_id) {
      output_template_id
      output_template_name
      output_template_table_id
      output_template_table_name
      output_template_schema_name
    }
  }
`;
export const listInputOutputTemplate = /* GraphQL */ `
  query ListInputOutputTemplate {
    listInputOutputTemplate {
      input_output_template_name
      template_filename_format
      input_output_mapping_id
      rule_view
      rule_key_column
    }
  }
`;
export const listTransformerLogs = /* GraphQL */ `
  query ListTransformerLogs($transformer_logs_id: String) {
    listTransformerLogs(transformer_logs_id: $transformer_logs_id) {
      transformer_logs_detail_id
      transformer_logs_id
      start_time
      end_time
      retry_count
      status_code
      file_name
      file_format
      file_path_in_s3
      error_file_path
      process_step_name
      process_step_id
      message
      server_time
      process_id
      process_step_code
    }
  }
`;
export const listInputOutputTemplateField = /* GraphQL */ `
  query ListInputOutputTemplateField($rule_view: String) {
    listInputOutputTemplateField(rule_view: $rule_view) {
      input_template_field
      field_data_type
    }
  }
`;
export const getExcutedRule = /* GraphQL */ `
  query GetExcutedRule($ruleQuery: String) {
    getExcutedRule(ruleQuery: $ruleQuery)
  }
`;
export const getBusinessRule = /* GraphQL */ `
  query GetBusinessRule {
    getBusinessRule {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const getLogiConfigurations = /* GraphQL */ `
  query GetLogiConfigurations {
    getLogiConfigurations {
      configuration_type
      configuration_value
    }
  }
`;
export const sendEmailService = /* GraphQL */ `
  query SendEmailService($data: AWSJSON) {
    sendEmailService(data: $data)
  }
`;
export const listRuleGroup = /* GraphQL */ `
  query ListRuleGroup {
    listRuleGroup {
      rule_group_id
      rule_group_name
      post_process_function
    }
  }
`;
export const listRuleGroupMapping = /* GraphQL */ `
  query ListRuleGroupMapping($rule_group_id: String) {
    listRuleGroupMapping(rule_group_id: $rule_group_id) {
      rule_group_mapping_id
      rule_id
      rule_group_id
      rule_property1
    }
  }
`;
export const executeAprrovalBatchProcess = /* GraphQL */ `
  query ExecuteAprrovalBatchProcess(
    $transformerLogId: Int
    $invokeFromUI: Boolean
    $bucket: String
    $s3FilePath: String
    $userID: Int
    $step: String
  ) {
    executeAprrovalBatchProcess(
      transformerLogId: $transformerLogId
      invokeFromUI: $invokeFromUI
      bucket: $bucket
      s3FilePath: $s3FilePath
      userID: $userID
      step: $step
    )
  }
`;
export const getSupervisorAuthHeaders = /* GraphQL */ `
  query GetSupervisorAuthHeaders {
    getSupervisorAuthHeaders
  }
`;
export const listRuleOutputByProcessId = /* GraphQL */ `
  query ListRuleOutputByProcessId(
    $process_id: Int
    $offset: Int
    $page_size: Int
  ) {
    listRuleOutputByProcessId(
      process_id: $process_id
      offset: $offset
      page_size: $page_size
    )
  }
`;
export const getWorkflowTask = /* GraphQL */ `
  query GetWorkflowTask($loan_number: String) {
    getWorkflowTask(loan_number: $loan_number) {
      workflow_task_id
      sbs_workflow_task_id
      sbs_workflow_task_description
      sbs_responsible_party_id
      original_schedule_date
      schedule_date
      due_date
      complete_date
      workflow_task_reason_id
      sbs_workflow_task_reason_id
      workflow_task_result_id
      workflow_task_result_description
      sbs_workflow_task_result_id
      sbs_workflow_task_result_description
      task_note
      sbs_status_id
      sbs_status_description
      document_id
      sbs_created_date
      sbs_created_by
      sbs_modified_date
      sbs_modified_by
      input_file_name
    }
  }
`;
export const listAlerts = /* GraphQL */ `
  query ListAlerts($loan_number: String) {
    listAlerts(loan_number: $loan_number) {
      sbs_alert_category_id
      sbs_alert_category_description
      alert_date
      alert_note
      sbs_alert_type_description
    }
  }
`;
export const listWorkflowDocuments = /* GraphQL */ `
  query ListWorkflowDocuments($loan_number: String) {
    listWorkflowDocuments(loan_number: $loan_number) {
      sbs_document_package_item_description
      sbs_document_id
      sbs_status_description
      sbs_document_issue_description
      input_file_name
      sbs_created_date
      sbs_created_by
      sbs_modified_date
      sbs_modified_by
    }
  }
`;
export const listAssignmentClaimsLoansByFilter = /* GraphQL */ `
  query ListAssignmentClaimsLoansByFilter(
    $filter: [FilterInput]
    $skip: Int
    $row_fetch: Int
    $scope_id: ID
    $source_query: String
    $view_name: String
    $sort_column: String
    $sort_order: String
  ) {
    listAssignmentClaimsLoansByFilter(
      filter: $filter
      skip: $skip
      row_fetch: $row_fetch
      scope_id: $scope_id
      source_query: $source_query
      view_name: $view_name
      sort_column: $sort_column
      sort_order: $sort_order
    )
  }
`;
export const getPresignedUrl = /* GraphQL */ `
  query GetPresignedUrl($input: GetPresignedUrls) {
    getPresignedUrl(input: $input)
  }
`;
export const listLoansByFilter = /* GraphQL */ `
  query ListLoansByFilter(
    $filterValue: String
    $filter: [FilterInput]
    $skip: Int
    $row_fetch: Int
    $fields: String
    $order: String
    $scope_id: ID
  ) {
    listLoansByFilter(
      filterValue: $filterValue
      filter: $filter
      skip: $skip
      row_fetch: $row_fetch
      fields: $fields
      order: $order
      scope_id: $scope_id
    )
  }
`;
export const listWorkflowTask = /* GraphQL */ `
  query ListWorkflowTask(
    $sbs_workflow_instance_id: String
    $loan_number: String
    $offset: Int
    $page_size: Int
  ) {
    listWorkflowTask(
      sbs_workflow_instance_id: $sbs_workflow_instance_id
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      workflow_task_id
      sbs_workflow_task_id
      sbs_workflow_task_description
      sbs_responsible_party_id
      original_schedule_date
      schedule_date
      due_date
      complete_date
      workflow_task_reason_id
      sbs_workflow_task_reason_id
      workflow_task_result_id
      workflow_task_result_description
      sbs_workflow_task_result_id
      sbs_workflow_task_result_description
      task_note
      sbs_status_id
      sbs_status_description
      document_id
      sbs_created_date
      sbs_created_by
      sbs_modified_date
      sbs_modified_by
      input_file_name
    }
  }
`;
export const getWorkflowType = /* GraphQL */ `
  query GetWorkflowType($loan_number: String) {
    getWorkflowType(loan_number: $loan_number) {
      sbs_workflow_instance_id
      sbs_workflow_type_description
    }
  }
`;
export const getWorkflowTypeById = /* GraphQL */ `
  query GetWorkflowTypeById($loan_number: String) {
    getWorkflowTypeById(loan_number: $loan_number) {
      sbs_workflow_type_id
    }
  }
`;
export const getExecuteRuleData = /* GraphQL */ `
  query GetExecuteRuleData($input: RuleDataInput) {
    getExecuteRuleData(input: $input)
  }
`;
export const getCodeViolations = /* GraphQL */ `
  query GetCodeViolations($loan_number: String, $offset: Int, $page_size: Int) {
    getCodeViolations(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      is_active_code_violation
      cv_type
      cv_received_date
      cv_received_from
      cv_fine_amount
    }
  }
`;
export const getLoanPropertyDetails = /* GraphQL */ `
  query GetLoanPropertyDetails($loan_number: String) {
    getLoanPropertyDetails(loan_number: $loan_number) {
      property_address
      property_address_2
      sbs_property_type_short_description
      property_type
      sbs_property_type_category_description
      actual_property_value
      repaired_property_value
      property_value_date
      property_value_type
      is_owner_occupied
      latest_occupancy_date
      vacancy_date
      loan_to_value
    }
  }
`;
export const getInspectionDetails = /* GraphQL */ `
  query GetInspectionDetails($loan_number: String) {
    getInspectionDetails(loan_number: $loan_number) {
      last_inspection_type
      last_inspection_date
      is_property_secured
      occupancy_status
      is_damage_reported
      damage_type
      damage_reported_date
    }
  }
`;
export const getLossDrafts = /* GraphQL */ `
  query GetLossDrafts($loan_number: String, $offset: Int, $page_size: Int) {
    getLossDrafts(
      loan_number: $loan_number
      offset: $offset
      page_size: $page_size
    ) {
      is_active_loss_draft
      loss_date
      loss_type
      insurance_provider_name
      insurance_claim_id
      last_contact_date
      loss_draft_funds
    }
  }
`;
export const getHoaDetails = /* GraphQL */ `
  query GetHoaDetails($loan_number: String) {
    getHoaDetails(loan_number: $loan_number) {
      is_required_hoa
      hoa_name
      hoa_lien_status
      lien_recording_date
      lien_filing_date
      lien_amount
    }
  }
`;
export const getTaxDetails = /* GraphQL */ `
  query GetTaxDetails($loan_number: String) {
    getTaxDetails(loan_number: $loan_number) {
      tax_status
      tax_frequency
      last_tax_disbursement_date
      next_tax_disbursement_due_date
    }
  }
`;
export const refreshGroupData = /* GraphQL */ `
  query RefreshGroupData($rule_group_id: String) {
    refreshGroupData(rule_group_id: $rule_group_id)
  }
`;
export const getUserGroupDataStatus = /* GraphQL */ `
  query GetUserGroupDataStatus {
    getUserGroupDataStatus
  }
`;
export const getTemplateRuleMapping = /* GraphQL */ `
  query GetTemplateRuleMapping($file_name: String, $bucket: String) {
    getTemplateRuleMapping(file_name: $file_name, bucket: $bucket) {
      post_rule_group_id
      rule_group_id
    }
  }
`;
export const listTag = /* GraphQL */ `
  query ListTag {
    listTag {
      tag_id
      tag_name
      tag_description
      is_active
      priority
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const getLoanTagMapping = /* GraphQL */ `
  query GetLoanTagMapping($loan_number: String) {
    getLoanTagMapping(loan_number: $loan_number) {
      loan_tag_id
      loan_number
      tag_id
      tag_name
      tag_description
      comments
      is_active
      created_by
      created_date
      modified_by
      modified_date
      tag_active
      inserted_date
    }
  }
`;
export const getLoanTagHistory = /* GraphQL */ `
  query GetLoanTagHistory($loan_number: String) {
    getLoanTagHistory(loan_number: $loan_number) {
      loan_tag_id
      loan_number
      tag_id
      tag_name
      tag_description
      comments
      is_active
      created_by
      created_date
      modified_by
      modified_date
      tag_active
      inserted_date
    }
  }
`;
export const listFnmaBillsView = /* GraphQL */ `
  query ListFnmaBillsView {
    listFnmaBillsView {
      fnma_billing_id
      initial_bill_number
      second_bill_number
      bill_group_id
      bill_reporting_servicer_name
      bill_status
      total_bill_amount
      total_loans
      bill_category
      billing_date
      rebuttal_received_stage_2_date
      fnma_response_stage_3_date
      rebuttal_received_stage_4_date
      fnma_response_stage_5_date
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const fileLoadDetails = /* GraphQL */ `
  query FileLoadDetails($file_name: String, $bucket: String) {
    fileLoadDetails(file_name: $file_name, bucket: $bucket)
  }
`;
export const listCannedReports = /* GraphQL */ `
  query ListCannedReports {
    listCannedReports {
      canned_report_id
      canned_report_name
    }
  }
`;
export const getLoanDocuments = /* GraphQL */ `
  query GetLoanDocuments($loan_skey: String) {
    getLoanDocuments(loan_skey: $loan_skey) {
      DOCUMENT_META_DATA_ID
      SOURCE
      LOAN_SKEY
      BORROWER_LAST_NAME
      DOC_TYPE_SKEY
      DOC_NAME
      Date
      S3_BUCKET_URL
      DOC_TYPE
      DOC_LAST_UPDATED_DATE
      INSERTED_DATE
      INSERTED_BY
      UPDATED_DATE
      UPDATED_BY
      TYPE
      CATEGORY
    }
  }
`;
export const getAllLoanDocuments = /* GraphQL */ `
  query GetAllLoanDocuments {
    getAllLoanDocuments {
      DOCUMENT_META_DATA_ID
      SOURCE
      LOAN_SKEY
      BORROWER_LAST_NAME
      DOC_TYPE_SKEY
      DOC_NAME
      Date
      S3_BUCKET_URL
      DOC_TYPE
      DOC_LAST_UPDATED_DATE
      INSERTED_DATE
      INSERTED_BY
      UPDATED_DATE
      UPDATED_BY
      TYPE
      CATEGORY
    }
  }
`;
export const exportRDSData = /* GraphQL */ `
  query ExportRDSData($input: ExportRDSDataInput) {
    exportRDSData(input: $input)
  }
`;
export const getCurrentUserDetailsByMaasUserId = /* GraphQL */ `
  query GetCurrentUserDetailsByMaasUserId($maas_user_id: String) {
    getCurrentUserDetailsByMaasUserId(maas_user_id: $maas_user_id) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
      role_id
      role_name
      group_id
      group_name
      dashboards_list
      reports_list
      data_sources_list
      rule_group_id
      profile_path
      created_date
    }
  }
`;
