/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Loader } from "../../helpers/loader";
import { getAllGridCodesListAsync, getCannedReportListAsync, getCurrentUserDataAsync, getLogiConfigurationAsync, getMasterDashboardListAsync, getScreensByRoleAsync, getUserGroupsAsync, login, logout, readAppState, setBreadcrumbLocation, setCurrentUser, setDashboardWithVisualsList, setIsSuperAdminUser, setMasterUiScreenList, setMasterVisualList, setScreenList, setSessionTimeout } from "../../redux/reducers/appReducer";
import { getCurrentUserData, userActivityLogs, getBrowserName } from "../../services/user.service";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import ResetPassword from "./ResetPassword";
import Cookies from 'js-cookie';
import { getAllScreens } from "../../services/screenData.service";
import { screenTypes } from "../../constants/screenTypes";
import { getDashboardsWithVisuals } from "../../services/dashboard.service";
import { getMasterVisualsList } from "../../services/visual.service";

export const Login = () => {
    const [modal, showModal] = useState(false);
    const navigation = useNavigate();
    const [inputType, changeInputType] = React.useState("password");
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [saveUserId, setSaveUserId] = useState(false)
    const [loading, setLoader] = useState(false);
    const [reset, setReset] = useState(false);
    const dispatch = useAppDispatch();
    const [cognitoUser, setCognitoUser] = useState<any>(null);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, onButtonClick: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    const appState = useAppSelector(readAppState);
    let screensVisible: any[] = [];
    const isLoggedIn = localStorage.getItem("isLogin");
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (isLoggedIn === "true") {
            navigation("/");
        }
        sessionStorage.removeItem("isAuthenticated");
        const userId = Cookies.get("username")
        const saveUserIdFlag = Boolean(Cookies.get("saveUserId"))
        setUsername(userId);
        setSaveUserId(saveUserIdFlag);
    }, []);

    useEffect(() => {
        if (isDataLoaded) {
            getRole();
        }
    }, [isDataLoaded])

    const getRole = () => {
        getRoles(username, cognitoUser);
    }

    const getSiteConfiguration = () => {
        dispatch(getLogiConfigurationAsync()).then(data => {
            if (data && data.payload) {
                dispatch(getMasterDashboardListAsync(data.payload)).catch(ex => console.log(ex));
                getDashboardsWithVisuals(data.payload).then((response: any) => {
                    if (response && response.length > 0) {
                        dispatch(setDashboardWithVisualsList(response));
                    }
                })
                    .catch(ex => console.log(ex));
                getMasterVisualsList(data.payload).then((data: any) => {
                    if (data && data.length > 0) {
                        dispatch(setMasterVisualList(data));
                        setIsDataLoaded(true)
                    }
                })
                    .catch(ex => console.log(ex));
            }
        });
        dispatch(getUserGroupsAsync()).catch(ex => console.log(ex));
        dispatch(getCannedReportListAsync());
    }

    const handleSubmit = async () => {
        try {
            if (!username) {
                showModal(!modal);
            }
            else {
                setLoader(true);
                let userId = username;
                const userPassword = password;
                const user = await Auth.signIn(userId.toLowerCase(), userPassword);
                dispatch(getCurrentUserDataAsync(userId.toLowerCase())).then(response => {
                    if (response && response.payload && response.payload.length > 0) {
                        userActivityLogs({
                            user_id: response.payload[0].user_id,
                            maas_user_id: response.payload[0].maas_user_id,
                            activity: "Logged In",
                            inserted_by: response.payload[0].maas_user_id,
                            inserted_date: new Date().toUTCString(),
                            browser_name: getBrowserName(window.navigator.userAgent.toLowerCase())
                        })
                        dispatch(setSessionTimeout(false));
                        localStorage.setItem('maas_user_id', `${response.payload[0].maas_user_id}`);
                    }
                })
                setCognitoUser(user);
                dispatch(login(user));
                localStorage.setItem('isLogin', 'true');
                localStorage.setItem('sessionTimeout', 'false');
                sessionStorage.setItem("isAuthenticated", "true");
                await getSiteConfiguration();
                dispatch(getAllGridCodesListAsync());
            }
        }
        catch (error) {
            setPopup({ ...popup, visible: true, message: "You’ve entered incorrect User ID or Password. Please enter the correct User ID or Password to continue ", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            setLoader(false);
            console.log(error);
        }
    }

    const getRoles = async (userId: any, cognitoUser: any) => {
        const user = await getCurrentUserData(userId);
        let screens = await getAllScreens();
        let uiScreens = screens.filter(s => s.screen_type === screenTypes.UI && s.is_active);
        dispatch(setMasterUiScreenList(uiScreens));
        if (user && user.length > 0) {
            const currentUser = user.find(x => x.maas_user_id.toLowerCase() === userId.toLowerCase());
            if (currentUser) {
                dispatch(setCurrentUser(currentUser));
                let superAdminUser = currentUser.maas_user_id === appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value
                dispatch(setIsSuperAdminUser(superAdminUser));
                dispatch(getScreensByRoleAsync()).then((response: any) => {
                    if (response && response.payload && response.payload.length > 0) {
                        let accessibleScreens = response.payload.filter(i => i.role_name === currentUser.role_name);
                        screens.forEach(sc => {
                            if (sc.screen_type === screenTypes.Dashboard && currentUser.maas_user_id === appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value && !accessibleScreens.find(s => s.screen_id === sc.screen_id)) {
                                accessibleScreens.push(sc);
                            }
                            else if (sc.screen_type === screenTypes.Dashboard && currentUser.dashboards_list && currentUser.dashboards_list.length > 0 && currentUser.dashboards_list.find(dash => dash.dashboard_id === sc.dashboard_id) && !accessibleScreens.find(s => s.screen_id === sc.screen_id)) {
                                accessibleScreens.push(sc);
                            }
                        });
                        if (accessibleScreens && accessibleScreens.length > 0) {
                            accessibleScreens.sort((a, b) => a.display_order - b.display_order);
                        }
                        screensVisible = [...accessibleScreens];
                        dispatch(setScreenList(accessibleScreens));
                    }
                    if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                        dispatch(logout());
                        Cookies.set("password", password, { expires: 365 });
                        sessionStorage.setItem("isAuthenticated", "false");
                        setReset(true);
                        setLoader(false)
                    }
                    else {
                        dispatch(setBreadcrumbLocation([{
                            "screen_id": screensVisible[0].screen_id,
                            "screen_name": screensVisible[0].screen_name,
                            "redirect_url": screensVisible[0].redirect_url,
                            "icon_url": screensVisible[0].icon_url
                        }]));
                        navigation(screensVisible.length > 0 ? `${screensVisible[0].redirect_url}` : "/");
                        localStorage.setItem("loginForOtherTabs", "true");
                    }
                })
            }
        }
    }
    const viewPassword = (e: any) => {
        if (inputType === "password") {
            changeInputType("text");
        }
        else {
            changeInputType("password");
        }
    }
    const handleKeyPress = (target) => {
        if (target.charCode === 13) {
            handleSubmit();
        }
    }
    const updateResetPassword = e => {
        setReset(false);
    }

    const setCookie = () => {
        setSaveUserId(!saveUserId)
        if (!saveUserId) {
            Cookies.set("username", username, { expires: 365 });
            Cookies.set("saveUserId", saveUserId, { expires: 365 });
        } else {
            Cookies.remove('username');
            Cookies.remove('saveUserId');
        }
    }
    return (
        <>
            <div className="login_page">
                <div className="login_section">
                    {loading && <Loader />}
                    {popup.visible && <CustomPopupComponent message={popup.message} bottom="0.1rem" isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
                    <div className="login_logo_sect">
                        <div className="login_logo">
                            <span className="l_m_logo"><img src={massCdnUrl + "assets/img/site-login.png"} /></span>
                        </div>
                    </div>
                    <div className="login_form_sect">
                        <div className="login_form_in_sect">
                            {reset ?
                                <ResetPassword user={cognitoUser} setLoader={(value: boolean) => setLoader(value)} updateResetPassword={updateResetPassword} />
                                : <>
                                    <h1 className="login_title">Welcome to Mortgage Assets Management!</h1>

                                    <div className="form-floating ct_form_field ct_form_icon_group">
                                        <input tabIndex={1} type="text" className="form-control" id="userId" placeholder="User ID" value={username} onChange={(e) => setUsername(e.target.value.replace(/[^A-Za-z0-9_.]/gi, ''))} />
                                        <span className="far bi bi-person" aria-hidden="true"></span>
                                        <label htmlFor="userId">User ID</label>
                                    </div>

                                    <div className="d-flex justify-content-end" >
                                        <a className="forgot_link" tabIndex={5} onClick={() => navigation("/ForgotUserId")} onKeyPress={(target) => { if (target.charCode === 13) { navigation("/ForgotUserId") } }}>Forgot User ID</a>
                                    </div>

                                    <div className="form-floating ct_form_field ct_form_icon_group">
                                        <input tabIndex={2} type={inputType} className="form-control" id={`password+${Math.random()}`} placeholder="Password" autoComplete="new-password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} />
                                        <span onClick={viewPassword} className={`far ${inputType === "text" ? "fa-key" : "fa-lock"}`}></span>
                                        <label htmlFor="password">Password</label>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="form-check">
                                                <input tabIndex={3} className="form-check-input" disabled={!username || !password} type="checkbox" onChange={setCookie} checked={saveUserId} id="flexCheckDefault" />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">Save User ID</label>
                                            </div>
                                        </div>
                                        <div>
                                            <a tabIndex={6} onClick={() => navigation("/ForgotPassword")} onKeyPress={(target) => { if (target.charCode === 13) { navigation("/ForgotPassword") } }} className="forgot_link">Forgot Password</a>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="d-grid mt-4">
                                            <button tabIndex={4} disabled={!username || !password} className="btn btn-primary  text-center" type="submit" onClick={handleSubmit}>Sign in</button>
                                        </div>
                                        <div style={{ marginTop: "5px" }} className="error-text">
                                            <span>{appState?.sessionTimeout ? "Session Logged-out due to Inactivity." : ""}</span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}