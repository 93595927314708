/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState, useRef } from "react";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { readAppState, setBreadcrumbLocation, setMasterUserGroupList } from "../../../redux/reducers/appReducer";
import Breadcrumb from "../../common/Breadcrumb";
import { setUserGroup, setUserGroupList, updateGroupAsync } from "../../../redux/reducers/userReducer";
import { Loader } from "../../../helpers/loader";
import { deleteGroupById, deleteUserGroupByGroupId, getGroupList, getUserGroupsWithStatus, refreshUserGroupData, updateRecords } from "../../../services/user.service";
import { deleteLogiUser, getLogiUsersList } from "../../../services/logi.service";
import { deleteDashboardsAndReportsByGroupId, getDeletedGroupDashboardsAndReports } from "../../../services/dashboard.service";


const UserGroupListComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const [groupList, setGroupList] = useState([]);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "User Group Management",
            redirect_url: "/UserGroups",
            icon_url: "fad fa-users"
        }]));
        getMasterUserGroupList();
        getUserGroups();
    }, []);

    useEffect(() => {
        if (gridApi) {
            setTimeout(() => {
                gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen]);


    const onGridReady = async (params: any) => {
        try {
            let groups: any;
            groups = await getUserGroupsWithStatus();
            groups = groups.filter(x => x.group_name !== appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value);
            setRowData(groups);
            params.api.paginationGoToPage(10)
            params.api.sizeColumnsToFit();
            setGridApi(params.api);
            var sort = [{
                colId: "created_date",
                sort: "desc"
            }];
            params.columnApi.applyColumnState({
                state: sort,
                defaultState: { sort: null }
            })
            setLoading(false);
        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const getMasterUserGroupList = async () => {
        try {
            const userGroups = await getLogiUsersList(appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value);
            if (userGroups && userGroups.length > 0) {
                dispatch(setMasterUserGroupList(userGroups));
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const getUserGroups = async () => {
        try {
            let userGroups = await getGroupList();
            userGroups = userGroups.filter(x => x.group_name !== appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value);
            dispatch(setUserGroupList(userGroups));
            setGroupList(userGroups);
            return userGroups;
        }
        catch (ex) {
            console.log(ex);
            return null;
        }
    }

    const refreshGridData = async () => {
        let updatedGroups = await getUserGroupsWithStatus();
        updatedGroups = updatedGroups.filter(x => x.group_name !== appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value);
        setRowData(updatedGroups);
        setPopup({ ...popup, visible: true, message: "The grid data is refreshed!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
    }

    const handleDataRefresh = async (params: any) => {
        setLoading(true);
        try {
            let ruleGroupId = params.data?.rule_group_id;
            const selectedData = gridApi.getSelectedRows();
            if(ruleGroupId){
            let response = await refreshUserGroupData(ruleGroupId);
            const selectedGroup: any = groupList?.find((gr: any) => gr.group_id === selectedData[0].group_id.toString());

            if (response && response.statusCode === 200 && response.body && response.body.transformerLogId && selectedGroup) {
                let updateResponse = await dispatch(updateGroupAsync({
                    group_id: selectedGroup.group_id,
                    group_name: selectedGroup.group_name,
                    dashboards_list: JSON.stringify(selectedGroup.dashboards_list).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    reports_list: JSON.stringify(selectedGroup.reports_list).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    data_sources_list: JSON.stringify(selectedGroup.data_sources_list).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    modified_by: appState.currentUser?.maas_user_id,
                    modified_date: new Date().toUTCString(),
                    rule_group_id: selectedGroup.rule_group_id,
                    transformer_logs_id: response.body.transformerLogId.toString()
                }));
                if (updateResponse && updateResponse.payload) {
                    setPopup({ ...popup, visible: true, message: "Data Refresh for the user group is in progress. Please check the status for further updates. ", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                    refreshGridData();
                }
                else {
                    setPopup({ ...popup, visible: true, message: "Data Refresh for the user group could not be performed!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                }
            }
            else {
                setPopup({ ...popup, visible: true, message: "Data Refresh for the user group could not be performed!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            }
        }
        else {
            setPopup({ ...popup, visible: true, message: "Data Refresh for the user group could not be performed, rule group is not attached!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
        

        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "Data Refresh for the user group could not be performed!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
        setLoading(false);
    }

    const handleEditClick = (params: any) => {
        let data = { ...params.data, is_new: false }
        dispatch(setUserGroup(data));
        navigation("/UserGroupManagement")
    }

    const columns: any[] = [
        { headerName: 'User Id', field: 'user_id', hide: true },
        { headerName: 'Created Date', field: 'created_date', hide: true },
        { headerName: 'User Group Name', field: 'group_name', headerTooltip: 'User Group Name' },
        { headerName: 'Data Refresh Status', field: 'status_code', headerTooltip: 'Data Refresh Status' },
        { headerName: 'Action', pinned: 'right', filter: false, width: 150, suppressSizeToFit: true, cellRendererFramework: (params) => <div className="grid-action">
                <button onClick={() => { handleDataRefresh(params) }} title="Refresh" className="refresh_icon_link" disabled={params.data.status_code === "STARTED"}><span className="fas fa-sync-alt"></span></button>
                <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
                <button onClick={() => {
                    setPopup({
                        ...popup,
                        visible: true,
                        message: getDeleteGroupConfirmationMessage(params.data.group_id),
                        type: ModalType.ActionDialog,
                        toggle: () => setPopup({ ...defaultPopup }),
                        primaryButtonAction: () => removeUser(params.data.group_id),
                        primaryButtonName: "Delete",
                        secondaryButtonName: "Cancel",
                        secondaryButtonAction: () => setPopup({ ...defaultPopup })
                    });
                }} title="Delete" className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
            </div>
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }
    const gridRef: any = useRef();

    const getDeleteGroupConfirmationMessage = (groupId: string) => {
        let deletedGroupCreatedDashboards: any = [];
        let deletedGroupCreatedReports: any = [];
        let deletedUserGroup: any = appState.userGroups.find(g => g.group_id === groupId);

        if (deletedUserGroup && deletedUserGroup.dashboards_list && deletedUserGroup.dashboards_list.data && deletedUserGroup.dashboards_list.data.length > 0) {
            deletedUserGroup.dashboards_list.data.forEach(dash => {
                let masterDashboard = appState.masterDashboardList.find(md => md.inventoryItemId === dash.dashboard_id);
                if (masterDashboard && masterDashboard.creatorName.toLowerCase() === deletedUserGroup.group_name.toLowerCase()) {
                    deletedGroupCreatedDashboards.push(masterDashboard);
                }
            });
        }
        if (deletedGroupCreatedDashboards.length === 0 && deletedUserGroup && deletedUserGroup.reports_list && deletedUserGroup.reports_list.data && deletedUserGroup.reports_list.data.length > 0) {
            deletedUserGroup.reports_list.data.forEach(repo => {
                let masterVisual = appState.masterVisualList.find(mv => mv.id === repo.report_id);
                if (masterVisual && masterVisual.creatingUserName.toLowerCase() === deletedUserGroup.group_name.toLowerCase()) {
                    deletedGroupCreatedReports.push(masterVisual);
                }
            });
        }
        if ((deletedGroupCreatedDashboards && deletedGroupCreatedDashboards.length > 0) || (deletedGroupCreatedReports && deletedGroupCreatedReports.length > 0)) {
            return ("There exist 1 or more dashboards and reports created by this user group. All the created dashboards and reports will be removed along with this user group. This is an irreversible action. Please confirm that you would like to delete this user group.")
        }
        else {
            return ("Please confirm that you would like to delete this user group.")
        }
    }

    const removeUser = async (groupId) => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
            const selectedData = gridApi.getSelectedRows();
            if (selectedData[0].group_id !== appState.currentUser!.group_id) {
                const deletedGroupDetails = await getDeletedGroupDashboardsAndReports({ groupId: groupId, userGroup: selectedData[0], currentUser: appState.currentUser, logiConfiguration: appState.logiConfiguration })
                const deleteUserResponse = await deleteLogiUser(selectedData[0].logi_user_id);

                if (deleteUserResponse?.success) {
                    let userGroups = appState.masterUserGroupList;
                    userGroups = userGroups.filter(z => z.name.toLowerCase() !== selectedData[0].group_name.toLowerCase());
                    let updatedUserGroupList = groupList.filter((gr: any) => gr.group_id !== selectedData[0].group_id.toString());
                    setGroupList(updatedUserGroupList);
                    dispatch(setUserGroupList(updatedUserGroupList));
                    dispatch(setMasterUserGroupList(userGroups));
                    await deleteDashboardsAndReportsByGroupId({
                        groupId: groupId,
                        userGroup: selectedData[0],
                        currentUser: appState.currentUser,
                        logiConfiguration: appState.logiConfiguration,
                        dashboards: appState.masterDashboardList,
                        reports: appState.masterVisualList,
                        deletedGroupDashboards: deletedGroupDetails.deletedGroupDashboards,
                        deletedGroupReports: deletedGroupDetails.deletedGroupReports
                    });


                    gridApi.updateRowData({ remove: selectedData });
                    await deleteGroupById(selectedData[0].group_id);
                    await deleteUserGroupByGroupId(selectedData[0].group_id);
                    const inactiveStatus = "D"
                    await updateRecords(selectedData[0].group_id, appState.currentUser?.maas_user_id, inactiveStatus);
                    refreshGridData();
                    setPopup({ ...popup, visible: true, message: "This user group has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                }
                else {
                    setPopup({ ...popup, visible: true, message: "User Group could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                }
            }
            else {
                setPopup({ ...popup, visible: true, message: "User Group cannot be deleted! Logged in user belong to this group.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            }
        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "User Group could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
        setLoading(false);
    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                    <a className="btn btn-primary" style = {{marginLeft: "7px" }} onClick={() => { refreshGridData() }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem"}} className={`fas fa-sync-alt`} /> Refresh Grid
                    </a>
                    <a className="btn btn-primary" onClick={() => { navigation("/UserGroupManagement"); dispatch(setUserGroup({ is_new: true })) }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} /> Add User Group
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            {loading && <Loader />}
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
            <div className="ag-theme-alpine" style={{ height: '72vh', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columns}
                    rowData={rowData}
                    rowSelection={'single'}
                    defaultColDef={defaultColDef}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}>
                </AgGridReact>
            </div>
        </section>
    </>
    )
}

export default memo(UserGroupListComponent);