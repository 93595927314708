import { useNavigate } from "react-router";
import { Loader } from "../../helpers/loader";
import { Auth, auth0SignInButton } from "aws-amplify";
import { RootState } from "../../app/store";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../common/CustomPopupComponent";
import { updateUserData } from "../../services/user.service";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readAppState, setCurrentUser } from "../../redux/reducers/appReducer";
import { useState, useEffect } from "react";

const Email = (props: any) => {
  const userState = useAppSelector((state: RootState) => state.app.currentUser);
  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [otp, setOtp] = useState("" as any);
  const [loader, setLoader] = useState(false);
  const [otpFlag, setOtpFlag] = useState(false);
  const [otpSentFlag, setOtpSentFlag] = useState(false);
  const [cognitoCurrentUser, setCognitoCurrentUser] = useState(undefined as any);
  const [changeEmailBypass, setBypassFlag] = useState(false);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const [showPopover, setPopover] = useState(false);
  const dispatch = useAppDispatch();
  const appState = useAppSelector(readAppState);

  useEffect(() => {
    getCurrentUser();
  }, [])


  const handleSubmit = async (event: any) => {
    setLoader(true);
    try {
      await Auth.updateUserAttributes(cognitoCurrentUser, {
        email: email,
      }).then(() => {
        getCurrentUser();
        setOtpFlag(true);
        setOtpSentFlag(true);
      });

      let res: any = await updateUserData(
        "email",
        email,
        appState?.currentUser?.user_id
      );
      setPopup({
        ...popup,
        visible: true,
        message: `Please enter the verification code send to ${email}`,
        type: ModalType.Snackbar,
        variant: VariantType.Success,
        toggle: () => {
          setPopup({ ...defaultPopup });
        },
      });
      dispatch(setCurrentUser({ ...appState.currentUser, email }));
      reset()
      setTimeout(() => {
        navigation("/MyProfileAndSettings");
      }, 3000);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
    }
  };

  const isDisabled = () => {
    let disabled = !email || !newEmail || email !== newEmail;
    if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) || !newEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      disabled = true;
    }
    if (appState?.currentUser?.email === email) {
      return true
    }
    return disabled;
  };

  const reset = () => {
    setEmail("");
    setNewEmail("");
    setOtp("");
  };

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(data => {
      setCognitoCurrentUser(data);
    })
  }

  const verifyOtp = async () => {
    setLoader(true);
    try {
      const response = await Auth.verifyCurrentUserAttributeSubmit('email', otp);
      setLoader(false);
      if (response === "SUCCESS") {
        setPopup({
          ...popup,
          visible: true,
          message: "Your Email has been verified successfully!",
          type: ModalType.Snackbar,
          variant: VariantType.Success,
          toggle: () => {
            setPopup({ ...defaultPopup });
          },
        });
        setOtp("");
        setOtpFlag(false);
        setOtpSentFlag(false);
        getCurrentUser();
        const editButton = document.getElementById("email-edit");
        if (editButton) {
          editButton.click();
        }
      }
    }
    catch (e: any) {
      setLoader(false);
      console.log(e);
      setPopup({
        ...popup,
        visible: true,
        message: e.message,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => {
          setPopup({ ...defaultPopup });
        },
      });
    }
  }

  const resendOTP = async () => {
    try {
      setLoader(true);
      setOtp("");
      await Auth.verifyCurrentUserAttribute('email');
      setLoader(false);
      setOtpFlag(true);
      setOtpSentFlag(true);
      setPopup({
        ...popup,
        visible: true,
        message: "Verification code sent successfully!",
        type: ModalType.Snackbar,
        variant: VariantType.Success,
        toggle: () => {
          setPopup({ ...defaultPopup });
        }
      });
    }
    catch (e) {
      console.log(e);
      setLoader(false);
    }
  }
  const handleChange = event => {
    let value = event.target.value;
    value = value.replace(/[^\d]/g, '');
    if (value.length <= 6) {
      setOtp(value);
    }
  }
  return (
    <>
      {popup.visible && (
        <CustomPopupComponent
          isOpen={popup.visible}
          toggle={popup.toggle}
          message={popup.message}
          type={ModalType.Snackbar}
          variant={popup.variant}
        />
      )}
      {loader && <Loader />}
      <h3 className="profile_edit_title">
        Email
        <a
          id="email-edit"
          className="p_edit_icon"
          data-bs-toggle="collapse"
          data-bs-target="#vertacc2"
          aria-expanded="true"
        >
          <span className="fa fa-pen-square" style={{ color: "#006b7f", cursor: "pointer" }}></span>
        </a>
      </h3>
      <div className="p_info_edit_block p_info_edit_block-m">
        <span className="p_info_edit_col1  p_info_edit_email_col1">Primary Email</span>
        <span title={cognitoCurrentUser && !cognitoCurrentUser.attributes.email_verified ? "Email Not Verfied" : "Verified Email"} className="p_info_edit_col2 p_info_edit_email_col2">{userState?.email}{cognitoCurrentUser && !cognitoCurrentUser.attributes.email_verified && <span style={{ color: "red" }}>*</span>}</span>
        {cognitoCurrentUser && !cognitoCurrentUser.attributes.email_verified && <p className="error-message">
          Please verify your email address to enable password reset feature.
        </p>}
      </div>
      <div id="vertacc2" className="collapse" data-bs-parent="#myprofile">
        <div className="profile_edit_form">
          <div className="row justify-content-md-center">
            {!otpFlag ? <>
              {
                (cognitoCurrentUser && cognitoCurrentUser.attributes.email_verified) || changeEmailBypass ? <>
                  <div className="col-lg-4">
                    <div className="form-floating ct_form_field ct_form_icon_group">
                      <input
                        className="form-control transparent-background"
                        type="email"
                        id="newEmail"
                        placeholder="Enter New Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label>Enter New Email Address</label>
                      {email &&
                        !email.match(
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                        ) && (
                          <p className="error-message">
                            Please enter valid email address.
                          </p>
                        )}
                    </div>
                  </div>
                  <br />
                  <div className="col-lg-4">
                    <div className="form-floating ct_form_field ct_form_icon_group">
                      <input
                        className="form-control transparent-background"
                        type="email"
                        id="reEnterMail"
                        placeholder={"Re-Enter New Email Address"}
                        value={newEmail}
                        onFocus={() => setPopover(true)}
                        onBlur={() => setPopover(false)}
                        onChange={(e) => setNewEmail(e.target.value)}
                      />
                      <label htmlFor="reEnterMail">
                        Re-Enter New Email Address
                      </label>
                      {email !== newEmail && newEmail && (
                        <p className="error-message">Email address does not match.</p>
                      )}
                    </div>
                  </div>

                  <div className="row" style={{ textAlign: "center", marginBottom: "-10px" }}>
                    {
                      appState?.currentUser?.email === email && email === newEmail &&
                      <span className="error-message">
                        This email address is already entered into your contact details.
                      </span>
                    }
                  </div>
                  <div className="row justify-content-md-center mb-3 mt-0 g-4">
                    {cognitoCurrentUser && !cognitoCurrentUser.attributes.email_verified && <div className="col-lg-2 col normal-screen">
                      <button
                        onClick={() => {
                          setOtpFlag(true); setBypassFlag(false);
                          if (!otpSentFlag) {
                            resendOTP();
                          }
                        }}
                        data-bs-target="#vertacc2"
                        className="btn btn-primary text-center btn-block"
                      >
                        Verify Email
                      </button>
                    </div>}
                    <div className="col-lg-2 col normal-screen">
                      <button
                        data-bs-target="#vertacc2"
                        disabled={isDisabled()}
                        className="btn btn-primary text-center btn-block"
                        onClick={handleSubmit}
                        type="button"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-lg-2 col normal-screen">
                      <button
                        onClick={() => {
                          reset();
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target="#vertacc2"
                        className="btn btn-outline-primary text-center btn-block"
                      >
                        {" "}
                        Cancel
                      </button>
                    </div>
                  </div>
                </> :
                  <>
                    <div className="row justify-content-md-center mt-3">
                      <div className="col-sm-5 col col-lg-auto col ">
                        <button
                          onClick={() => { setOtpFlag(false); setBypassFlag(true); }}
                          data-bs-target="#vertacc2"
                          className="btn btn-primary text-center btn-block"
                        >
                          Change Email
                        </button>
                      </div>
                      <div className="col-sm-5 col col-lg-auto col ">
                        <button
                          onClick={() => {
                            resendOTP();
                          }}
                          data-bs-target="#vertacc2"
                          className="btn btn-outline-primary text-center btn-block"
                        >
                          Verify Email
                        </button>
                      </div>
                    </div>
                  </>
              }
            </>
              : <>
                <div className="normalscreen mt-0 mb-3">

                  <div className="form-floating ct_form_field ct_form_icon_group">
                    <input
                      className="form-control transparent-background"
                      type="text"
                      id="otp"
                      placeholder="Enter Verification Code"
                      value={otp}
                      onChange={handleChange}
                    />
                    <label>Please enter the verification code</label>
                  </div>
                  <br />
                  <div className="row justify-content-center g-4">
                    <div className="col-lg-4 col">
                      <button
                        data-bs-target="#vertacc2"
                        disabled={otp.length !== 6}
                        className="btn btn-primary text-center btn-block"
                        onClick={verifyOtp}
                        type="button"
                      >
                        Verify
                      </button>
                    </div>
                    <div className="col-lg-4 col">
                      <button
                        data-bs-target="#vertacc2"
                        className="btn btn-primary text-center btn-block"
                        onClick={resendOTP}
                        type="button"
                      >
                        Resend
                      </button>
                    </div>
                    <div className="col-lg-4 col-xs-5 col changeEmail">
                      <button
                        onClick={() => { setOtpFlag(false); setBypassFlag(true); }}
                        data-bs-target="#vertacc2"
                        className="btn btn-outline-primary text-center btn-block"
                      >
                        Change Email
                      </button>
                    </div>

                  </div>
                </div>
              </>
            }
          </div>
          {popup.visible && (
            <CustomPopupComponent
              isOpen={popup.visible}
              toggle={popup.toggle}
              message={popup.message}
              type={ModalType.Snackbar}
              variant={popup.variant}
            />
          )}
          {loader && <Loader />}
        </div>
      </div>
    </>
  );
};
export default Email;
