/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from "@ag-grid-community/react";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getGridFilters } from "../../constants/gridHelper";
import { Loader } from "../../helpers/loader";
import {
  readAppState, setBreadcrumbLocation
} from "../../redux/reducers/appReducer";
import { setStep, readAssignmentClaimState } from "../../redux/reducers/assignmentClaimReducer";
import { readDashboardState } from "../../redux/reducers/dashboardReducer";
import { addLoanTab } from "../../redux/reducers/loanReducer";
import { getLogiSourceQuery } from "../../services/dashboard.service";
import { getAssignmentsByFilter } from "../../services/loanSearch.services";


const DocumentLevelSearch = (props: any) => {
  const appState = useAppSelector(readAppState);
  const [loading, setLoader] = useState(false);
  const dashboardState = useAppSelector(readDashboardState);
  const assignmentClaimState = useAppSelector(readAssignmentClaimState);
  const breadcrumbs = useRef(appState.breadcrumbLocation);
  const gridApi = useRef(null as any);
  const filter = useRef(appState.acDlSearchFilters as any);
  const dispatch = useAppDispatch();
  const currentVisualName = useRef(null as any);
  const dashboardDetails = useRef({ appliedFilters: dashboardState.appliedFilters, currentSource: dashboardState.currentSource });


  const getLoans = async (documentLevelFilter: any) => {
    let userGroups = appState.userGroups ? appState.userGroups : [];
    let currentUserGroup = userGroups.find(g => g.group_id === appState.currentUser?.group_id)
    let sourceQuery = await getLogiSourceQuery(appState.logiToken, dashboardDetails.current.currentSource || "")
    if (currentVisualName.current && currentVisualName.current === "Documents Expired") {
      sourceQuery = sourceQuery + " and documents_expired=1";
    } else if (currentVisualName.current && currentVisualName.current === "Upcoming Expirations") {
      sourceQuery = sourceQuery + " and upcoming_expirations=1";
    }
    let data = await getAssignmentsByFilter(documentLevelFilter, currentUserGroup?.logi_user_id, sourceQuery.replace("${User.UserId}", `'${currentUserGroup?.logi_user_id}'`), "MAAS.VW_ASSIGNMENT_CLAIM_LOANS");
    if (data && data.length) {
      let loans = data;
      if (!loans || loans.length === 0) {
        gridApi.current.showNoRowsOverlay();
      }
      return {
        success: true,
        rows: loans ? loans[1] : [],
        lastRow: !loans ? 0 : Number(loans[0][0].total_rows)
      };
    } else {
      gridApi.current.showNoRowsOverlay();
      return { success: true, rows: [], lastRow: 0 };
    }
  };

  useEffect(() => {
    currentVisualName.current = assignmentClaimState.heading;
    if (gridApi && gridApi.current) {
      gridApi.current.refreshServerSideStore({ purge: true });
    }
  }, [assignmentClaimState.heading]);

  useEffect(() => {
    dashboardDetails.current = ({ appliedFilters: dashboardState.appliedFilters, currentSource: dashboardState.currentSource })
    if (gridApi && gridApi.current) {
      gridApi.current.setFilterModel(null);
      gridApi.current?.columnModel?.columnApi?.applyColumnState({
        defaultState: { sort: null },
      });
      gridApi.current.refreshServerSideStore({ purge: true });
    }
  }, [dashboardState.appliedFilters, dashboardState.currentSource]);

  useEffect(() => {
    filter.current = appState.acDlSearchFilters;
  }, [appState.acDlSearchFilters]);

  useEffect(() => {
    dispatch(
      setBreadcrumbLocation([
        {
          screen_name: "Document List",
          redirect_url: "/DocumentLevelSearch",
          icon_url: "fad fa-user",
        },
      ])
    );
  }, []);

  useEffect(() => {
    breadcrumbs.current = appState.breadcrumbLocation;
  }, [appState.breadcrumbLocation]);

  const dataSource = {
    getRows: (params) => {
      gridApi.current.hideOverlay();
      let filterData: any[] = dashboardDetails.current.appliedFilters ? [...dashboardDetails.current.appliedFilters] : [];
      const agGridFilterModel = params.request.filterModel;
      if (agGridFilterModel) {
        filterData = getGridFilters(agGridFilterModel, filterData);
      }
      getLoans({
        filter: filterData,
        skip: params.request.startRow,
        row_fetch: 100,
        sort_order: params.request.sortModel && params.request.sortModel.length > 0 ? params.request.sortModel[params.request.sortModel.length - 1].sort : null,
        sort_column: params.request.sortModel && params.request.sortModel.length > 0 ? params.request.sortModel[params.request.sortModel.length - 1].colId : null
      }).then((response) => {
        const element = document.getElementById("collapseExample1");
        if (element) {
          element.classList.remove("show");
        }
        if (response.success) {
          if (response.rows.length === 0) {
            gridApi.current.showNoRowsOverlay();
          }
          params.successCallback(response.rows, response.lastRow);
        } else {
          params.fail();
        }
      });
    },
  };


  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.setServerSideDatasource({ ...dataSource });
    if (params.api) {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 500)
    }
  };

  const defaultColDef = {
    sortable: true,
    resizable: true
  };

  const handleRowClick = (e: any) => {
    if (e.data?.LOAN_NUMBER) {
      dispatch(setBreadcrumbLocation([{
        screen_name: "Loan Details",
        redirect_url: "/AssignmentClaims",
        icon_url: "fad fa-user"
      }]))
      dispatch(setStep(3));
      dispatch(addLoanTab(e.data.LOAN_NUMBER));
    }
  };

  const linkTest = ({ value }) => {
    return <a onClick={() => handleRowClick(value)} style={{ textDecoration: "underline", color: 'var(--url-color)' }}>{value}</a>;
  };

  return (
    <>
      <section className="section dashboard">
        <div className={"block_sect"} >
          <div style={{ height: "72vh", marginTop: "1em", padding: '1px' }} className={`ag-theme-alpine`}>
            <h6 style={{ marginBottom: "12px", marginTop: "-23px" }}>{assignmentClaimState && assignmentClaimState?.heading}</h6>
            <AgGridReact
              columnDefs={[
                {
                  field: "LOAN_NUMBER",
                  headerName: "Loan Number",
                  tooltipField: "LOAN_NUMBER",
                  headerTooltip: "Loan Number",
                  cellRendererFramework: linkTest,
                  sortable: true,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "SBS_STATUS_DESCRIPTION",
                  headerName: "Document Status",
                  tooltipField: "SBS_STATUS_DESCRIPTION",
                  headerTooltip: "Document Status",
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "DAYS_ISSUE_PENDING",
                  headerName: "Number of Days Since Document in Status",
                  tooltipField: "DAYS_ISSUE_PENDING",
                  headerTooltip: "Number of Days Since Document in Status",
                  filter: 'agNumberColumnFilter'
                }
              ]}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              cacheBlockSize={100}
              headerHeight={35}
              rowHeight={40}
              overlayNoRowsTemplate={
                '<span class="ag-overlay-loading-center">No records were found!</span>'
              }
              overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
              onGridReady={onGridReady}
              onRowClicked={(e) => handleRowClick(e)}
              rowModelType={"serverSide"}
              serverSideStoreType={"partial"}
              modules={[ServerSideRowModelModule, RowGroupingModule]}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default DocumentLevelSearch;
