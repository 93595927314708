/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useRef, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { getLossDraftAsync } from "../../../redux/reducers/loanReducer";
import {readAppState}from "../../../redux/reducers/appReducer"
import formatDateTable from '../../../constants/dateFormatTable';
import GenericAgGrid from "../../common/GenericAgGrid";

const LossDraft = (props: any) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    const appState = useAppSelector(readAppState);
    const gridLossDraftRef: any = useRef();
    // const gridRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'PROPERTY_LOSS_DRAFT');

    useEffect(() => { }, [activeTab]);

   
    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }

    const columns = [
        { headerName: 'Active Loss Draft', field: 'is_active_loss_draft', width: 150 },
        { headerName: 'Loss Date', field: 'loss_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Loss Type', field: 'loss_type', width: 150 },
        { headerName: 'Insurance Provider Name', field: 'insurance_provider_name', width: 150 },
        { headerName: 'Insurance Claim #', field: 'insurance_claim_id', width: 150 },
        { headerName: 'Last Contact Date', field: 'last_contact_date',filter: 'agDateColumnFilter', width: 150,valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Loss Draft Funds', field: 'loss_draft_funds', width: 150 }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    return (
        <div >
            {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridLossDraftRef}
                        ></GenericAgGrid>
                    </div>}
            
        </div>
    );
}

export default LossDraft;