import moment from "moment";

const formatDateTable = ({value},withTime?:boolean) => {
    if(value){
        if(withTime){
            let date = moment(value).format("MM-DD-YYYY HH:mm:ss")
            return date!=="Invalid date"?date:value
        }
        let date = moment(value).format("MM-DD-YYYY")
        return date!=="Invalid date"?date:value
    }
}

export default formatDateTable;