export const Rules = [
    { screenName: "Rules Management", url: ["/BusinessRule", "/BusinessRuleManagement"] },
    { screenName: "Rule Group", url: ["/RuleGroup", "/RuleGroupManagement"] },
];

export const Users = [
    { screenName: "Users Management", url: ["/Users", "/UserManagement"] },
    { screenName: "User Groups", url: ["/UserGroups", "/UserGroupManagement"] },
    { screenName: "Roles", url: ["/Roles", "/RoleManagement"] },
];

export const Tags = [
    { screenName: "Tags Management", url: ["/Tags", "/TagManagement"] },
];

export const Inventories = [
    { screenName: "All Dashboards", url: ["/AllDashboards"] },
    { screenName: "All Reports", url: ["/AllReports"] }
];

export const AdminSubMenu = [
    { screenName: "Screen Navigations", url: "/ScreenNavigations", subMenus: [] },
    { screenName: "Users", url: "", subMenus: Users },
    { screenName: "Rules", url: "", subMenus: Rules },
    { screenName: "Tags", url: "", subMenus: Tags },
    { screenName: "File Upload", url: "/UploadFile", subMenus: [] },
    { screenName: "Inventories", visibility: "SuperAdmin", url: "", subMenus: Inventories }
];

export const AssignmentClaimsSubMenu = [
    { screenName: "Assignment Claims", url: "/AssignmentClaims", subMenus: [] },
    { screenName: "Impediments", url: "/AssignmentClaimsImpediments", subMenus: [] }
];

export const FnmaSubMenu = [
    { screenName: "FNMA Billing", url: "/FNMABilling", subMenus: [] },
    { screenName: "Bills View", url: "/FNMABillsView", subMenus: [] },
    { screenName: "Bills Summary", url: "/BillSummary", subMenus: [] },
    { screenName: "Open Bill Analysis", url: "/OpenBillAnalysis", subMenus: [] }
];

export const SubMenus = {
    "Admin": AdminSubMenu,
    "FNMA Billing": FnmaSubMenu,
    "Assignment Claims": AssignmentClaimsSubMenu
};