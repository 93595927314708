import { API, graphqlOperation } from 'aws-amplify';
import { getDashboardToken } from './dashboard.service';
import axios from "axios";
import { updateGroupReportList } from '../graphql/mutations';
import { getGroupList } from './user.service';
const composerUrl = process.env.REACT_APP_COMPOSER_URL || '';


export const getVisuals = async (token: any, currentUser: any) => {
    try {
        if (token) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const inventoryResponse = await axios.get(`${composerUrl}/api/visuals`, { headers });
            if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.content && inventoryResponse.data.content.length > 0) {
                if (currentUser) {
                    return inventoryResponse.data.content;
                }
            }
        }
        return [];
    }
    catch (ex) {
        console.log(ex);
        return [];
    }
}

export const getFilteredVisuals = async (token: any, currentUser: any, logiConfig: any, userGroups: any[]) => {
    try {
        if (token) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const inventoryResponse = await axios.get(`${composerUrl}/api/visuals`, { headers });
            if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.content && inventoryResponse.data.content.length > 0) {
                if (currentUser) {
                    if (currentUser?.maas_user_id !== logiConfig.find(u => u.configuration_type === "admin_user_name")?.configuration_value) {
                        let reports = inventoryResponse.data.content.filter(value => (currentUser?.reports_list.find(x => x.report_name === value.visualName) ||
                            (currentUser?.maas_user_id === logiConfig.find(u => u.configuration_type === "admin_user_name")?.configuration_value && userGroups.length > 0 && userGroups.find(x => x.group_name.toLowerCase() === value.creatingUserName.toLowerCase()))
                        ))
                        return reports;
                    }
                    else {
                        return inventoryResponse.data.content;
                    }
                }
            }
        }
        return [];
    }
    catch (ex) {
        console.log(ex);
        return [];
    }
}

export const deleteReportById = async (token: any, reportId: string) => {
    try {
        const headers = {
            'Content-Type': 'application/vnd.composer.v2+json',
            'Authorization': 'Bearer ' + token.access_token
        }
        const deleteResponse: any = await axios.delete(`${composerUrl}/api/visuals/${reportId}`, { headers });
        if (deleteResponse) {
            return reportId;
        }
        else {
            return null;
        }
    }
    catch (e: any) {
        console.log(e);
        return null;
    }
}

export const removeReport = async (appState: any, report: any) => {
    try {
        const userGroups = await getGroupList();
        if (userGroups && userGroups.length > 0) {
            const updatedList: any[] = [];
            userGroups.forEach(group => {
                const updatedReports = group.reports_list.data.filter(x => x.report_id !== report.id);
                if (updatedReports.length > 0 && group.reports_list.data.length > 0 && updatedReports.length !== group.reports_list.data.length) {
                    updatedList.push({
                        groupId: group.group_id,
                        reports: updatedReports
                    });
                }
            });
            if (updatedList.length > 0) {
                updatedList.forEach(async z => {
                    const inputData = {
                        group_id: z.groupId,
                        reports_list: JSON.stringify({ data: z.reports }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                        modified_by: appState.currentUser?.maas_user_id,
                        modified_date: new Date().toUTCString()
                    }
                    await API.graphql(graphqlOperation(updateGroupReportList, { input: inputData }));
                });
            }
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getMasterVisualsList = async (logiConfiguration: any) => {
    try {
        if (logiConfiguration && logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value) {
            const token = await getDashboardToken({ user: { group_name: logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value } });
            let visualList: any[] = [];
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v2+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const visualResponse = await axios.get(`${composerUrl}/api/visuals`, { headers });
                if (visualResponse && visualResponse.data && visualResponse.data.content && visualResponse.data.content.length > 0) {
                    visualList = [...visualResponse.data.content];
                }
            }
            return visualList;
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}