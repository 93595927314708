
import { Logger } from '@ag-grid-community/core';
import { Auth } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearState, logout, readAppState, setLogiToken, setSessionTimeout } from "../../redux/reducers/appReducer";
import { resetDashboardState } from "../../redux/reducers/dashboardReducer";
import { getBrowserName, userActivityLogs } from '../../services/user.service';
import moment, { Moment } from 'moment';

function SessionTimeout() {
    const lastTabChangeTime = useRef(Date.now());
    let startInactiveTimeInterval = useRef<any>();
    const events = ['mousedown', 'mousemove', 'keypress', 'touchstart', 'click', 'load', 'scroll'];
    const appState = useAppSelector(readAppState);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const sessionExpireTime = 15 * 60 * 1000;
    let timeStamp = useRef<any>();

    useEffect(() => {
        if (appState.isLoggedIn) {
            localStorage.setItem('logoutdueto', "");
            document.addEventListener('visibilitychange', handleVisibilityChange);
            events.forEach((event) => {
                window.addEventListener(event, setTimeStamp);
            });
            startInactiveTimer();
        }
        window.addEventListener('storage', storageChnage);
        return () => {
            clearInterval(startInactiveTimeInterval.current);
            events.forEach((event) => {
                window.removeEventListener(event, setTimeStamp);
            });
            window.removeEventListener('storage', storageChnage)
        }
    }, [appState.isLoggedIn, appState.currentUser])

    const setTimeStamp = () => {
        startInactiveTimer();
        timeStamp.current = moment();
        localStorage.setItem('lastTimeStamp', timeStamp.current);
    }
    /* function works for other tabs */
    const storageChnage = (event: any) => {
        /* if current tab will logout it will logout other tabs also for same url */
        if (event.key == "logoutdueto" && (event.newValue === "manually" || event.newValue === "sessionTimeOut")) {
            clearInterval(startInactiveTimeInterval.current);
            logoutHandler(event.newValue)
        }
        /* if current tab will logIn it will logIn other tabs also for same url */
        if (event.key == "loginForOtherTabs" && event.newValue === "true") {
            window.location.href = "/";
        }
        /*it will reset Timer for other tabs if we will do any activity in current tab for same url */
        if (event.key === "lastTimeStamp") {
            startInactiveTimer();
        }
    }

    function handleVisibilityChange() {
        const currentTime = Date.now();
        if (appState.isLoggedIn) {
            if (document.visibilityState === 'hidden') {
                lastTabChangeTime.current = currentTime;
            }
            else {
                const timeSinceLastInteraction = currentTime - lastTabChangeTime.current;
                if (timeSinceLastInteraction > sessionExpireTime) {
                    if (appState?.currentUser) {
                        logoutHandler("sessionTimeOut");
                    }
                }
                else {
                    startInactiveTimer();
                }
            }
        }
    }

    const startInactiveTimer = () => {
        clearInterval(startInactiveTimeInterval.current);
        let minPast = 0;
        startInactiveTimeInterval.current = setInterval(() => {
            minPast += 1000;

            if (minPast >= sessionExpireTime) {
                if (appState?.currentUser) {
                    logoutHandler("sessionTimeOut");
                }
            }
        }, 1000);
    }


    const logoutHandler = (logoutType) => {
        let sessionflag = logoutType == "manually" ? false : true;
        clearInterval(startInactiveTimeInterval.current);
        userActivityLogs({
            user_id: appState?.currentUser?.user_id,
            maas_user_id: appState?.currentUser?.maas_user_id,
            activity: "Logged Out:Due To Inactivity",
            inserted_by: appState?.currentUser?.maas_user_id,
            inserted_date: new Date().toUTCString(),
            browser_name: getBrowserName(window.navigator.userAgent.toLowerCase())
        }).then(res => {
            if (res) {
                Auth.signOut();
                dispatch(logout())
                localStorage.clear();
                sessionStorage.clear();
                dispatch(clearState());
                dispatch(setSessionTimeout(sessionflag))
                dispatch(resetDashboardState());
                dispatch(setLogiToken(null));
                localStorage.setItem('isLogin', 'false');
                localStorage.setItem('sessionTimeout', 'false');
                localStorage.setItem('maas_user_id', `${appState?.currentUser?.maas_user_id}`);
                localStorage.setItem('logoutdueto', "sessionTimeOut");
                navigate("/login");
            }
        }).catch((err) => {
            console.log("err", err)
        })
    }

    return <></>
}

export default SessionTimeout;