/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { getAllDashboards, getDashboardWithVisualsById, getDashboardToken, removeDashboard, updateDashboards, updateReports } from "../../services/dashboard.service";
import { Loader } from "../../helpers/loader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readAppState, setBreadcrumbLocation, setCurrentDashboard, setDashboardsList, setDescription, setLogiToken, setMasterDashboardList, setReportsList } from "../../redux/reducers/appReducer";
import Breadcrumb from "../common/Breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { grantDashboardPermissions, grantReportPermissions } from "../../services/logi.service";
import { getGroupList } from "../../services/user.service";
import { getVisuals } from "../../services/visual.service";

const DashboardView = (props: any) => {
  const [loading, setLoader] = useState(true);
  const appState = useAppSelector(readAppState);
  const [dashboardId, setDashboardId] = useState<any>(appState && appState.currentDashboard ? `${appState.currentDashboard.accountId}+${appState.currentDashboard.inventoryItemId}` : null);
  const logiEmbedManager = useRef(undefined as any);
  const componentInstanceId = useRef(undefined as any);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const appStateRef = useRef(appState);
  const dashEvent = useRef(false);
  const dashDesc = useRef(appState.currentDashboard.description);
  const location = useLocation();
  let mainParentDiv;



  const currentDashboard = appState && (appState.masterDashboardList.find(dash => dash.inventoryItemId === appState.currentDashboard.id) || appState.masterDashboardList.find(dash => dash.inventoryItemId === appState.currentDashboard.inventoryItemId));
  const isSharedDashboard = currentDashboard && currentDashboard.creatorId !== (appState.userGroups.find(g => g.group_id === appState.currentUser?.group_id)).logi_user_id;
  const sharedDashboardInteractivity = {
    "name": "interactive",
    "type": "SYSTEM",
    "overrideVisualInteractivity": true,
    "settings": {
      "REFRESH": true,
      "CHANGE_LAYOUT": false,
      "RENAME": false,
      "SHARE_FILTER_SETS": false,
      "DASHBOARD_INTERACTIONS": false,
      "ADD_TO_FAVORITES": false,
      "DELETE": false,
      "FILTER": true,
      "EXPORT_PNG_PDF": true,
      "ADD_VISUALS": false,
      "EXPORT_CONFIGURATION": false,
      "DASHBOARD_LINKS": false,
      "SAVE": true,
    },
    "visualSettings": {
      "ACTIONS_ACTION": true,
      "RULERS": true,
      "ZOOM_ACTION": false,
      "FILTER_ACTION": true,
      "COLORS": true,
      "METRICS": true,
      "ACTIONS": true,
      "TREND_ACTION": false,
      "VISUAL_STYLE": true,
      "KEYSET_ACTION": false,
      "KEYSET": false,
      "COPY": true,
      "SETTINGS": true,
      "EXPORT": true,
      "TIMEBAR_PANEL": true,
      "DETAILS_ACTION": true,
      "MAXIMIZE": true,
      "LINK_ACTION": true,
      "FILTER": true,
      "REMOVE": false,
      "GROUPING": true,
      "RENAME": false,
      "SORT": true,
      "TIMEBAR_FIELD": true
    }
  }


  useEffect(() => {
    appStateRef.current = appState;
  }, [appState]);



  const getMasterToken = async () => {
    if (appState.logiToken) {
      return appState.logiToken;
    }
    const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
      return r
    })
    if (token) {
      dispatch(setLogiToken(token));
    }
    return token;

  };

  const addDescriptionDiv = () => {
    if (!dashEvent.current) {
      let embed: any = document.getElementById("embed")
      let desc = document.querySelectorAll(`[data-testid="dashboard-description-toggle-button"]`);
      if(embed && desc[embed?.children.length - 1]){
        desc[embed?.children.length - 1]?.addEventListener("click", handleDescriptionClick )
        dashEvent.current = true;
      }
    }
  }

  const handleDescriptionClick = () => {
    let embed: any = document.getElementById("embed")
    let id = document.getElementById("DataRoostNode");
    if (id) {
      id.remove();
    } else {
      mainParentDiv = document.getElementsByClassName("zdView-DashboardHeader")[embed?.children.length - 1];
      let descDiv = document.createElement("div");
      descDiv.id = "DataRoostNode";
      mainParentDiv?.appendChild(descDiv);
      let childDiv = document.createElement("div");
      descDiv.appendChild(childDiv);
      let pDesc = document.createElement("span");
      pDesc.className = "bp3-text-content";
      childDiv.appendChild(pDesc);
      const node = document.createTextNode(dashDesc.current);
      pDesc.appendChild(node);

    }
  }


  useEffect(() => {
    let breadcrumbLocation = [...appState.breadcrumbLocation];
    breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
      i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/DashboardLibrary")
    );
    breadcrumbLocation.push({
      "screen_name": "Dashboard",
      "redirect_url": "/DashboardView",
      "icon_url": "fad fa-route"
    });
    dispatch(setBreadcrumbLocation(breadcrumbLocation));
    (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then((embedManager: any) => {
      logiEmbedManager.current = embedManager;
      setLoader(false);
      embedManager.createComponent('dashboard', {
        "dashboardId": dashboardId,
        "theme": props.theme,
        "header": {
          'visible': props.showEditor,
          'showTitle': props.showTitle,
          'showActions': props.showActions,
          'showEditor': props.showEditor,
        },
        "interactivityProfileName": props.interactivityProfileName,
        "interactivityOverrides": isSharedDashboard ? sharedDashboardInteractivity : props.interactivityOverrides
      }).then((dash: any) => {
        componentInstanceId.current = dash.componentInstanceId;
        dash.render(document.getElementById('embed'), { width: props.width, height: props.height });
        dash.addEventListener("composer-dashboard-deleted", (e) => {
          removeDashboard(appState, e);
          navigation("/DashboardLibrary");
        });
        dash.addEventListener("composer-dashboard-saved", (e) => {
          handleSave(e);
          addDescriptionDiv();
        });
        dash.addEventListener("composer-dashboard-ready", (e) => {
          addDescriptionDiv();

        });
        dash.addEventListener("composer-visual-rendered", (e) => {
          let saveHtml = document.querySelector(`[data-testid="dashboard-save-menu-button"]`);
          if (isSharedDashboard && !appState.isSuperAdminUser) {
            saveHtml?.remove()
          }

        });
      });
    })
      .catch((e: any) => { console.log(`Logi Error: ${e}`); })
  }, [dashboardId, appState.currentDashboard.name]);

  useEffect(() => {
    return (() => {
      if (logiEmbedManager.current && componentInstanceId.current) {
        logiEmbedManager.current.removeComponent(componentInstanceId.current);
      }
    });
  }, []);

  const handleSave = async (e: any) => {
    dashDesc.current = e.detail.dashboard.description
    dispatch(setDescription(e.detail.dashboard.description));
    let createVisualCopy = false;
    const token = await getMasterToken();
    let updatedDashboardsList: any[] = [...appStateRef.current.currentUser!.dashboards_list];
    let updatedReportsList = [...appStateRef.current.currentUser!.reports_list];
    let updatedMasterDashboardList = [...appState.masterDashboardList]
    if (e.detail.dashboard.name === appStateRef.current.currentDashboard.name && e.detail.dashboard.id === appStateRef.current.currentDashboard.inventoryItemId) {
      const newDashboard = await getDashboardWithVisualsById(token, e.detail.dashboard.id);
      if (newDashboard && newDashboard.widgets && newDashboard.widgets.length > 0) {
        let newVisuals: any[] = [];
        newDashboard.widgets.forEach(newVisual => {
          if (!updatedReportsList.find(oldVisual => oldVisual.report_id === newVisual.visualId)) {
            newVisuals.push(newVisual);
          }
        });
        if (newVisuals.length > 0) {
          let newVisualsCreated: any[] = [];
          const visualList = await getVisuals(token, appStateRef.current.currentUser);
          newVisuals.forEach(vis => {
            const visualCreated = visualList.find(v => v.id === vis.visualId);
            if (visualCreated) {
              newVisualsCreated.push(visualCreated);
              updatedReportsList.push({ report_id: visualCreated.id, report_name: visualCreated.visualName })
            }
          });
          await grantReportPermissions(appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'admin_user_id')?.configuration_value, newVisualsCreated, appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value, true)//access to madmin/maas_admin
          const input = {
            group_id: appStateRef.current.currentUser?.group_id,
            reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            modified_by: appStateRef.current.currentUser?.maas_user_id,
            modified_date: new Date().toUTCString()
          }
          dispatch(setReportsList(updatedReportsList));
          await updateReports(input);
        }
        return;
      }
    }
    else {
      dashEvent.current = false;
      if (e.detail.dashboard.name !== appStateRef.current.currentDashboard.name && e.detail.dashboard.id !== appStateRef.current.currentDashboard.inventoryItemId) {
        if (logiEmbedManager.current && componentInstanceId.current) {
          logiEmbedManager.current.removeComponent(componentInstanceId.current);
        }
        const dashboardList: any = await getAllDashboards(token, appStateRef.current.currentUser);
        await grantDashboardPermissions(appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'admin_user_id')?.configuration_value, [dashboardList.find(x => x.inventoryItemId === e.detail.dashboard.id)], appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value, true)//access to madmin/maas_admin

        const newDashboard = await getDashboardWithVisualsById(token, e.detail.dashboard.id);
        if (newDashboard && newDashboard.widgets && newDashboard.widgets.length > 0) {
          if (!updatedReportsList.find(z => z.report_id === newDashboard.widgets[0].visualId)) {
            createVisualCopy = true;
          }

        }

        if (createVisualCopy) {
          let copyVisualsCreated: any[] = [];
          const visualList = await getVisuals(token, appStateRef.current.currentUser);
          newDashboard.widgets.forEach(vis => {
            const visualCreated = visualList.find(v => v.id === vis.visualId);
            if (visualCreated) {
              copyVisualsCreated.push(visualCreated);
              updatedReportsList.push({ report_id: visualCreated.id, report_name: visualCreated.visualName })
            }
          });
          await grantReportPermissions(appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'admin_user_id')?.configuration_value, copyVisualsCreated, appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value, true)//access to madmin/maas_admin

          const input = {
            group_id: appStateRef.current.currentUser?.group_id,
            reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            modified_by: appStateRef.current.currentUser?.maas_user_id,
            modified_date: new Date().toUTCString()
          }
          dispatch(setReportsList(updatedReportsList));
          await updateReports(input);
        }
        setDashboardId(`${e.detail.dashboard.accountId}+${e.detail.dashboard.id}`);
        updatedDashboardsList.push({ dashboard_id: e.detail.dashboard.id, dashboard_name: e.detail.dashboard.name });
        updatedMasterDashboardList.push(dashboardList.find(d => d.inventoryItemId === e.detail.dashboard.id));
      }

      else if (e.detail.dashboard.name !== appStateRef.current.currentDashboard.name && e.detail.dashboard.id === appStateRef.current.currentDashboard.inventoryItemId) {
        const userGroups = await getGroupList();
        if (userGroups && userGroups.length > 0) {
          let updatedInputs: any[] = [];
          userGroups.forEach(g => {
            if (g.group_name !== appStateRef.current.currentUser!.group_name && g.dashboards_list && g.dashboards_list.data && g.dashboards_list.data.length > 0) {
              let updatedList = [...g.dashboards_list.data];
              updatedList = g.dashboards_list.data.map(x => {
                if (x.dashboard_id === e.detail.dashboard.id) {
                  x = { ...x, dashboard_name: e.detail.dashboard.name };
                }
                return x;
              });
              updatedInputs.push({
                group_id: appStateRef.current.currentUser?.group_id,
                dashboards_list: JSON.stringify({ data: updatedList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                modified_by: appStateRef.current.currentUser?.maas_user_id,
                modified_date: new Date().toUTCString()
              });
            }
          });
          if (updatedInputs.length > 0) {
            updatedInputs.forEach(async i => {
              await updateDashboards(i);
            })
          }
        }
        updatedDashboardsList = appStateRef.current.currentUser!.dashboards_list.map(x => {
          if (x.dashboard_id === e.detail.dashboard.id) {
            x = { ...x, dashboard_name: e.detail.dashboard.name };
          }
          return x;
        })
      }
    }
    const input = {
      group_id: appStateRef.current.currentUser?.group_id,
      dashboards_list: JSON.stringify({ data: updatedDashboardsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
      modified_by: appStateRef.current.currentUser?.maas_user_id,
      modified_date: new Date().toUTCString()
    }

    dispatch(setMasterDashboardList(updatedMasterDashboardList));
    dispatch(setDashboardsList(updatedDashboardsList));
    await updateDashboards(input);
    dispatch(setCurrentDashboard(e.detail.dashboard));
  }

  return (<>
    <div className={`pagetitle ${location?.pathname?.split('/').length === 2 ? "fixed-position" : ""}`}><Breadcrumb /></div>
    <div className="container-fluid position-relative">
      <div className="container min_height_dashboard">
        {loading && <Loader />}
        <div id="embed"></div>
      </div>
    </div>
  </>);
}

DashboardView.defaultProps = {
  composerUrl: '',
  theme: "modern",
  appBanner: false,
  appLogo: false,
  showEditor: true,
  showActions: true,
  showTitle: false,
  width: "80%",
  height: "100%",
  interactivityProfileName: "interactive",
  interactivityOverrides: {
    "name": "interactive",
    "type": "SYSTEM",
    "overrideVisualInteractivity": true,
    "settings": {
      "REFRESH": true,
      "CHANGE_LAYOUT": true,
      "RENAME": false,
      "SHARE_FILTER_SETS": false,
      "DASHBOARD_INTERACTIONS": false,
      "ADD_TO_FAVORITES": false,
      "DELETE": false,
      "FILTER": true,
      "EXPORT_PNG_PDF": true,
      "ADD_VISUALS": true,
      "EXPORT_CONFIGURATION": false,
      "DASHBOARD_LINKS": false,
      "SAVE": true,

    },
    "visualSettings": {
      "ACTIONS_ACTION": true,
      "RULERS": true,
      "ZOOM_ACTION": false,
      "FILTER_ACTION": true,
      "COLORS": true,
      "METRICS": true,
      "ACTIONS": true,
      "TREND_ACTION": false,
      "VISUAL_STYLE": true,
      "KEYSET_ACTION": false,
      "KEYSET": false,
      "COPY": true,
      "SETTINGS": true,
      "EXPORT": true,
      "TIMEBAR_PANEL": true,
      "DETAILS_ACTION": true,
      "MAXIMIZE": true,
      "LINK_ACTION": true,
      "FILTER": true,
      "REMOVE": true,
      "GROUPING": true,
      "RENAME": true,
      "SORT": true,
      "TIMEBAR_FIELD": true
    }
  }
}
export default DashboardView;