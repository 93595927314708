import AceEditor from "react-ace";
import langTools from "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { useAppDispatch } from "../../app/hooks";
import { setSelectedRule } from "../../redux/reducers/ruleReducer";

const AceRuleEditor = (props: any) => {
    const dispatch = useAppDispatch();

    const onChange = (newValue) => {
        let updatedRule: any = { ...props.ruleState.rule };
        updatedRule[props.field] = newValue;
        dispatch(setSelectedRule({ ...updatedRule }))

        const textareaElement: any = document?.getElementById("aceEditor")?.children[1].children[0].getBoundingClientRect().left;
        const popupElement: any = document.querySelector<HTMLElement>('.ace_autocomplete');


        if(textareaElement > 724 && textareaElement <= 1069){
            popupElement?.classList.add("fixed-rule-popup2");
        }
        else if (textareaElement > 1069 && textareaElement <= 1463){
            popupElement?.classList.remove("fixed-rule-popup2");
            popupElement?.classList.add("fixed-rule-popup");
        }
        else{
            popupElement?.classList.remove("fixed-rule-popup2");
            popupElement?.classList.remove("fixed-rule-popup");

        }
    }

    var staticWordCompleter = {
        getCompletions: function (editor, session, pos, prefix, callback) {
            var wordList = props.customSuggestions
            callback(null, wordList.map(function (words) {
                return {
                    caption: words,
                    value: words,
                    meta: "static"
                };
            }));
        }
    }

    langTools.setCompleters([staticWordCompleter])

    return (
        <div className="form-group custm_field" id="aceEditor">
            <label>{props.label}</label>
            <AceEditor
                mode="pgsql"
                onChange={onChange}
                name="UNIQUE_ID_OF_DIV"
                showPrintMargin={false}
                showGutter={false}
                value={props.value}
                theme="github"
                highlightActiveLine={false}
                className="form-control"
                width={"100%"}
                style={{resize: "vertical"}}
                fontSize={"28px"}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    maxLines: 6,
                    minLines: 3,
                    showLineNumbers: false,
                    useWorker: true
                }}
                editorProps={{ $blockScrolling: false }}
            />
        </div>
    );
}

export default AceRuleEditor;