import moment from "moment";

const formatDate = (value: string, withTime?:boolean) => {
    if(withTime){
        let date = moment(value).format("MM-DD-YYYY HH:mm:ss")
        return date!=="Invalid date"?date:value
    }
    let date = moment(value).format("MM-DD-YYYY")
    return date!=="Invalid date"?date:value
}


export default formatDate;