import { API, graphqlOperation, Storage } from "aws-amplify";
import axios from "axios";
import awsmobile from "../aws-exports";
import { createDocument } from "../graphql/mutations";
import { getPresignedUrl ,listDocuments,listBuckets} from "../graphql/queries";

export const uploadFileToS3 = async (bucket:any ,file, fileName: any, fileType: any, folderName:any) => {
    Storage.configure({
        AWSS3: {
            bucket: bucket,
            region: awsmobile.aws_cognito_region
        }
    });
    try {
        const result = await Storage.put(`${folderName}/${fileName}`, file , {
            customPrefix: {
                public:''
                },
            contentType: fileType,
        })
        return result;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export const getPresignedUrlForService = async (bucketName, objectKey, service) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getPresignedUrl, { input:{bucket_name: bucketName, object_key: objectKey, service: service}}));        
        return response && response.data && response.data.getPresignedUrl ? response.data.getPresignedUrl : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}
// ***Commented for backup purpose in case of side effected can use below service in future***
// export const S3upload = async (preSignedPostUrl, headersList, bodyContent) => {
//     try {
//         const response: any = await axios.request({
//             url: preSignedPostUrl,
//             method: "POST",
//             headers: headersList,
//             data: bodyContent
//             }).then(function (response) {
//             return true;
//         })
//         return response && (response.status===204) ? true: false;
//     }
//     catch (e) {
//         console.log(e);
//         return [];
//     }
// }

export const insertDocument = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createDocument, { input: data }));
        return response && response.data ? response.data.createDocument : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getDocument = async () => {
    try {
        let documents: any[] = [];
        const response: any = await API.graphql(graphqlOperation(listDocuments));
        if (response && response.data && response.data.listDocuments) {
            documents = response.data.listDocuments;
        }
        return documents;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getAllBucketList = async () => {
    try {
        let bucketList: any[] = [];
        const response: any = await API.graphql(graphqlOperation(listBuckets));
        if (response && response.data && response.data.listBuckets) {
            bucketList = response.data.listBuckets;
        }
        return bucketList;
    }
    catch (ex) {
        console.log(ex);
    }
}
