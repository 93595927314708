import { createGroup, createUserActivityLog, createUserGroupMapping, deleteGroup, deleteUserGroupMappingByGroupId, deleteUserGroupMappingByUserId, updateGroup, updateUserGroupMapping, updateUserGroupRecords, updateUserPassword } from './../graphql/mutations';

import { updateUser } from '../graphql/mutations';
import { getCurrentUserDetails, getUserGroupDataStatus, getUsersWithAllDetails, listCannedReports, listGroups, listUsers, refreshGroupData } from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { Storage } from "aws-amplify";
import axios from "axios";
import awsmobile from "../aws-exports";
import { createUser, createUserRoleMapping, deleteUser, deleteUserRoleMappingByRoleId, deleteUserRoleMappingByUserId, updateUserRoleMapping } from '../graphql/mutations';
const nodeApiUrl = process.env.REACT_APP_NODE_API_URL;
const profileBucket = process.env.REACT_APP_PROFILE_BUCKET;

export const authenticateUser = async (username: string, password: string) => {
    return await axios.post(`${nodeApiUrl}/authenticate`, { username: username, password: password });
}

export const getUserList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(getUsersWithAllDetails));
        if (response && response.data && response.data.getUsersWithAllDetails) {
            let userData = response.data.getUsersWithAllDetails.map(x => {
                x = { ...x, dashboards_list: x.dashboards_list ? JSON.parse(x.dashboards_list).data : [], reports_list: x.reports_list ? JSON.parse(x.reports_list).data : [] };
                return x;
            });
            return userData;
        }
        return null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getCurrentUserData = async (user_id: string) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getCurrentUserDetails, { user_id: user_id }));
        if (response && response.data && response.data.getCurrentUserDetails) {
            let userData = response.data.getCurrentUserDetails.map(x => {
                x = { ...x, dashboards_list: x.dashboards_list ? JSON.parse(x.dashboards_list).data : [], reports_list: x.reports_list ? JSON.parse(x.reports_list).data : [] };
                return x;
            });
            return userData;
        }
        return null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getGroupList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listGroups));
        return response && response.data ? response.data.listGroups.map(x => {
            x.dashboards_list = x.dashboards_list ? JSON.parse(x.dashboards_list) : null;
            x.reports_list = x.reports_list ? JSON.parse(x.reports_list) : null;
            x.data_sources_list = x.data_sources_list ? JSON.parse(x.data_sources_list) : null;
            return x;
        })
            : [];
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export const deleteUserByUserId = async (user_id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteUser, { input: { user_id: user_id } }));
        return response && response.data ? response.data.deleteUser : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteGroupById = async (group_id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteGroup, { input: { group_id: group_id } }));
        return response && response.data ? response.data.deleteGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteUserRoleByUserId = async (user_id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteUserRoleMappingByUserId, { input: { user_id: user_id } }));
        return response && response.data ? response.data.deleteUserRoleMappingByUserId : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteUserRoleByRoleId = async (role_id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteUserRoleMappingByRoleId, { input: { role_id: role_id } }));
        return response && response.data ? response.data.deleteUserRoleMappingByRoleId : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertUser = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createUser, { input: data }));
        return response && response.data ? response.data.createUser : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createGroup, { input: data }));
        return response && response.data ? response.data.createGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const createUserRole = async (data) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createUserRoleMapping, { input: data }));
        return response && response.data ? response.data.createUserRoleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateUserRole = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateUserRoleMapping, { input: data }));
        return response && response.data ? response.data.updateUserRoleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateGroupData = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateGroup, { input: data }));
        return response && response.data ? response.data.updateGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const createUserGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createUserGroupMapping, { input: data }));
        return response && response.data && response.data.createUserGroupMapping ? response.data.createUserGroupMapping : null;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const updateUserGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateUserGroupMapping, { input: data }));
        return response && response.data && response.data.updateUserGroupMapping ? response.data.updateUserGroupMapping : null;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const deleteUserGroupByUserId = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteUserGroupMappingByUserId, { input: { user_id: data } }));
        return response && response.data && response.data.deleteUserGroupMappingByUserId ? response.data.deleteUserGroupMappingByUserId : null;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const deleteUserGroupByGroupId = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteUserGroupMappingByGroupId, { input: { group_id: data } }));
        return response && response.data && response.data.deleteUserGroupMappingByGroupId ? response.data.deleteUserGroupMappingByGroupId : null;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const updatePasswordByUserId = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateUserPassword, { input: data }));
        return response && response.data && response.data.updateUserPassword ? response.data.updateUserPassword : null;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getUsers = async () => {
    try {
        let users: any[] = [];
        const response: any = await API.graphql(graphqlOperation(listUsers));
        if (response && response.data && response.data.listUsers) {
            users = response.data.listUsers;
        }
        return users;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateUserData = async (fieldName: any, value: any, user_id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateUser, { input: { fieldName, value, user_id } }));
        return response && response.data ? response.data.updateUser : null;

    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const loadProfile = async (profile_path) => {
    Storage.configure({
        AWSS3: {
            bucket: profileBucket,
            region: awsmobile.aws_cognito_region,
        },
    });
    try {
        const result = await Storage.get(
            `${profile_path}`
        )
        return result;
    } catch (e) {
        return null
    }

};

export const refreshUserGroupData = async (ruleGroupId: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(refreshGroupData, { rule_group_id: ruleGroupId }));
        return response && response.data ? JSON.parse(response.data.refreshGroupData) : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const getUserGroupsWithStatus = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(getUserGroupDataStatus));
        return response && response.data ? JSON.parse(response.data.getUserGroupDataStatus).map(x => {
            x.dashboards_list = x.dashboards_list ? JSON.parse(x.dashboards_list) : null;
            x.reports_list = x.reports_list ? JSON.parse(x.reports_list) : null;
            x.data_sources_list = x.data_sources_list ? JSON.parse(x.data_sources_list) : null;
            return x;
        })
            : [];
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const updateRecords = async (group_id: any, user_name: any, status: any) => {
    try {
        const data = {
            scope_id: group_id,
            modified_date: new Date().toUTCString(),
            modified_by: user_name,
            status: status
        }
        const response: any = await API.graphql(graphqlOperation(updateUserGroupRecords, { input: data }));
        return response && response.data ? JSON.parse(response.data.updateUserGroupRecords) : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getCannedReportList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listCannedReports));
        return response && response.data ? response.data.listCannedReports : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const userActivityLogs = async (data:any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createUserActivityLog,{input:data}));
        return response && response.data ? response.data.createUserActivityLog : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getBrowserName = (agent:any)=>{
    switch (true) {
        case agent.indexOf("edge") > -1: return "edge";
        case agent.indexOf("edg") > -1: return "chromium based edge (dev or canary)";
        case agent.indexOf("opr") > -1 : return "opera";
        case agent.indexOf("chrome") > -1: return "chrome";
        case agent.indexOf("trident") > -1: return "ie";
        case agent.indexOf("firefox") > -1: return "firefox";
        case agent.indexOf("safari") > -1: return "safari";
        default: return "other";
    }
}