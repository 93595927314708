/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect } from "react"
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
export interface CustomPopupComponentProps {
  isOpen: boolean,
  toggle: () => void;
  type: string;
  headerText?: string;
  message: any;
  fadeTime?: number;
  primaryButtonName?: string;
  primaryButtonAction?: () => any;
  primaryButtonClass?: string;
  secondaryButtonName?: string;
  secondaryButtonClass?: string;
  secondaryButtonAction?: () => any;
  cancelButtonAction?: () => any;
  cancelButtonClass?: string;
  variant?: string;
  bottom?: string
}

export const ModalType = {
  ActionDialog: "action dialog",
  AlertBox: "alert box",
  Snackbar: "snack bar"
}

export const VariantType = {
  Success: "success",
  Error: "danger"
}

const CustomPopupComponent = (props: CustomPopupComponentProps) => {
  useEffect(() => {
    if (props.type === "snack bar") {
      const timer = setTimeout(() => { props.toggle && props.toggle() }, props.fadeTime || 3000);
      return () => clearTimeout(timer);
    }
  }, [props.type])

  return (
    <div>
      {props.isOpen && <>
        {props.type === ModalType.ActionDialog &&
          <Modal isOpen={props.isOpen} top className="custom-modal-style">
            {props.headerText &&
              <ModalHeader toggle={() => props.toggle()}>
                {props.headerText}
              </ModalHeader>
            }
            <ModalBody style={{ textAlign: "center" }}>
              <p style={{ fontSize: '20px', marginTop: "1rem", marginBottom: "20px" }}>  {props.message}</p>
              {/* <div className="justify-center">
                {props.primaryButtonName && props.primaryButtonAction && <a className={`btn btn-primary text-center mr1rem ${props.primaryButtonClass || ""}`} onClick={() => props.primaryButtonAction!()}>{props.primaryButtonName}</a>}
                {props.secondaryButtonName && props.secondaryButtonAction && <a className={`btn btn-outline-primary text-center mr1rem ${props.secondaryButtonClass || ""}`} onClick={() => props.secondaryButtonAction!()}>{props.secondaryButtonName}</a>}
              </div> */}
              <div className="row justify-content-md-center mt30 gx-3">
                <div className="col-lg-3 col ">
                  {props.primaryButtonName && props.primaryButtonAction && <a className={`btn btn-primary text-center btn-block ${props.primaryButtonClass || ""}`} onClick={() => props.primaryButtonAction!()} onKeyPress={(target) => { if (target.charCode === 13) {props.primaryButtonAction!()}}}>{props.primaryButtonName}</a>}
                </div>
                <div className="col-lg-3 col ">
                  {props.secondaryButtonName && props.secondaryButtonAction && <a className={`btn btn-outline-primary text-center btn-block ${props.secondaryButtonClass || ""}`} onClick={() => props.secondaryButtonAction!()} onKeyPress={(target) => { if (target.charCode === 13) {props.secondaryButtonAction!()}}}>{props.secondaryButtonName}</a>}
                </div>
              </div>
            </ModalBody>
          </Modal>}
        {
          props.type === ModalType.AlertBox &&
          <Modal isOpen={props.isOpen} top style={{ height: "10px", width: "900px" }}>
            <ModalBody style={{ textAlign: "center" }}>
              <div style={{ textAlign: "right" }}>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => props.toggle()} onKeyPress={(target) => { if (target.charCode === 13) {props.toggle()}}}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              {props.message}
            </ModalBody>
          </Modal>
        }
        {
          props.type === ModalType.Snackbar &&
          <div className="login-SnackBar" style={{ position: "fixed", right: "2rem", bottom: props.bottom != null ? props.bottom : "3rem", maxWidth: "450px", zIndex: "99999", textAlign: "left" }}>
            <Alert color={props.variant} isOpen={props.isOpen} toggle={() => { props.toggle() }}>
              {props.message}
            </Alert>
          </div>
        }
      </>
      }
    </div >
  );
}

export default memo(CustomPopupComponent);