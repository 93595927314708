import { getNavigationByLoanStatus, listNavigationConfigurations } from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { createNavigation, deleteNavigation, updateNavigation } from '../graphql/mutations';

export const saveNavigationAsync = async (inputData: any) => {
    try {
        let response: any;
        if (inputData) {
            const existingNavigation: any = await API.graphql(graphqlOperation(getNavigationByLoanStatus, { loan_status: inputData.loan_status }));
            if (existingNavigation && existingNavigation.data && existingNavigation.data.getNavigationByLoanStatus) {
                let existingData = existingNavigation.data.getNavigationByLoanStatus;
                let updateNavigationInput = {
                    status_id: existingData.status_id,
                    screen_id: inputData.screen_id,
                    name: inputData.name,
                    path: inputData.path,
                    modified_by: "admin",
                    modified_date: new Date().toUTCString()
                }
                response = await API.graphql(graphqlOperation(updateNavigation, { input: updateNavigationInput }));
            }
            else {
                let createNavigationInput = {
                    loan_status: inputData.loan_status,
                    screen_id: inputData.screen_id,
                    name: inputData.name,
                    path: inputData.path,
                    created_by: "admin",
                    created_date: new Date().toUTCString(),
                    modified_by: "admin",
                    modified_date: new Date().toUTCString()
                }
                response = await API.graphql(graphqlOperation(createNavigation, { input: createNavigationInput }));
            }
        }
        return response;
    }
    catch (ex) {
        console.log(ex);
    }
}

export const deleteNavigationAsync = async (statusId: any) => {
    try {
        if (statusId) {
            const response: any = await API.graphql(graphqlOperation(deleteNavigation, { input: { status_id: statusId } }));
            return response.data.deleteNavigation;
        }
    }
    catch (e) {
        console.log(e);
    }

}

export const getAllNavigations = async () => {
    try {
        let navigationList: any[] = [];
        const response: any = await API.graphql(graphqlOperation(listNavigationConfigurations));
        if (response && response.data && response.data.listNavigationConfigurations) {
            navigationList = response.data.listNavigationConfigurations;
        }
        return navigationList;
    }
    catch (ex) {
        console.log(ex);
    }
}