/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { memo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from "../../app/store";
import formatDateTable from '../../constants/dateFormatTable';
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { setStep } from '../../redux/reducers/assignmentClaimReducer';
import { addLoanTab, setDocumentsData } from "../../redux/reducers/loanReducer";
import { getPresignedUrlForService } from '../../services/upload.service';
import Breadcrumb from '../common/Breadcrumb';
import DocumentPreviewComponent from '../common/DocumentPreviewComponent';
import GenericAgGrid, { getCurrentFilterValue } from '../common/GenericAgGrid';
import { urlStyle } from '../common/CustomStyles';
import { getFileData } from '../../constants/FilePathConstant';


const AllLoanDocuments = (props: any) => {
    const location = useLocation();
    const currentLocation = location.pathname.split('/');
    const dispatch = useAppDispatch();
    const [file, setFile] = useState(null as any);
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const navigation = useNavigate();
    const gridAllLoanDocumentsListRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'DOCUMENTS');

    const loanlinkTest = ({ value }) => {
        return <a onClick={(e) => handleLoanRowClick(e, value)} style={urlStyle}>{value}</a>;
    };

    const handleLoanRowClick = (e: any, value: String) => {
        let filterValue = getCurrentFilterValue(gridAllLoanDocumentsListRef);
        dispatch(setDocumentsData({ current_page: filterValue.CurrentPage, saved_filter: filterValue.FilterModel, saved_sort: filterValue.SortState, is_new: false, is_edit: true }));
        dispatch(setBreadcrumbLocation([{
            screen_name: "Loan Details",
            redirect_url: "./Documents/LoanDetail",
            icon_url: "fad fa-user"
        }]))
        navigation("/Documents/LoanDetail", { state: { loan_id: value } });
        dispatch(setStep(3));
        dispatch(addLoanTab(e.target.innerHTML));
    };

    const getFilePath = async (file: any) => {
        const { bucket_name, object_key } = getFileData(file);
        let fileType = file?.DOC_NAME ? file.DOC_NAME.substring(file.DOC_NAME.lastIndexOf(".") + 1) : "";
        try {
            let result = await getPresignedUrlForService(bucket_name, object_key, "download"); if (result) {
                const url = JSON.parse(JSON.parse(result).body).url;
                setFile({ ...file, FILE_PATH: url, DOC_TYPE: fileType });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    const handleRowClick = (e: any) => {
        getFilePath(e.data);
    }

    const columns = [
        {
            field: 'DOC_NAME', headerName: 'Document Name', width: 320, cellRendererFramework: (params) =>
                <a className="link-primary" style={urlStyle}
                    onClick={() => { preserveFilter(params.data) }}>{params.data.DOC_NAME}
                </a>,
        },
        { field: "LOAN_NUMBER", headerName: "Loan Number", cellRendererFramework: loanlinkTest },
        { field: "SERVICER_LOAN_NUMBER", headerName: "Servicer Loan Number" },
        { field: "BORROWER_NAME", headerName: "Borrower Name" },
        { field: "CATEGORY", headerName: "Category", width: 240 },
        { field: "TYPE", headerName: "Type", width: 280 },
        { field: "SOURCE", headerName: "Source", width: 130 },
        { field: "DOC_LAST_UPDATED_DATE", headerName: "Created Date",filter: 'agDateColumnFilter', valueFormatter: (param) => formatDateTable(param, false) },
        { field: "S3_BUCKET_URL", headerName: "s3_bucket URL", hide: true }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true
    }

    const preserveFilter = (params: any) => {
        let filterValue = getCurrentFilterValue(gridAllLoanDocumentsListRef);
        dispatch(setDocumentsData({ document_name: params.DOC_NAME, source: params.SOURCE, borrower_name: params.BORROWER_NAME, current_page: filterValue.CurrentPage, saved_filter: filterValue.FilterModel, saved_sort: filterValue.SortState, is_new: false, is_edit: true }));
    }


    const afterGridLoad = () => {
        if ((loanState.documentsData?.is_edit) && (loanState.documentsData?.saved_sort || loanState.documentsData?.saved_filter)) {
            gridAllLoanDocumentsListRef.current.columnApi.applyColumnState({
                state: loanState.documentsData.saved_sort,
            });
            gridAllLoanDocumentsListRef.current.api.setFilterModel(loanState.documentsData.saved_filter);
            dispatch(setDocumentsData({ ...loanState?.documentsData, is_edit: true, current_page: 0 }));
        }
        else {
            dispatch(setDocumentsData({ ...loanState?.documentsData, saved_sort: null, saved_filter: null, current_page: 0 }));
        }
    }

    const getCustomStaticFilter = () => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId: string = userGroups.find(g => g.group_id === appState.currentUser?.group_id)?.logi_user_id;
        return `SCOPE_ID = '${userGroupId}'`;
    }

    return (
        <>
            <div className="pagetitle"> <Breadcrumb /> </div>
            {file ?
                <div>
                    <DocumentPreviewComponent
                        file={file}
                        setFile={setFile}
                    />
                </div> :
                currentLocation.length <= 2 ?
                    !!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridAllLoanDocumentsListRef}
                            afterGridLoad={afterGridLoad}
                            onRowClicked={handleRowClick}
                        ></GenericAgGrid>
                    </div> : <Outlet />
            }
        </>
    )
}

export default memo(AllLoanDocuments);