/* eslint-disable react-hooks/exhaustive-deps */
import {
    bootstrapControlClassnames,
    bootstrapControlElements,
} from '@react-querybuilder/bootstrap';
import { memo } from 'react';
import QueryBuilder from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css'

const operators = [
    { name: '=', label: '=' },
    { name: '!=', label: '!=' },
    { name: '<', label: '<' },
    { name: '>', label: '>' },
    { name: '<=', label: '<=' },
    { name: '>=', label: '>=' },
    { name: 'contains', label: 'contains' },
    { name: 'beginsWith', label: 'begins with' },
    { name: 'endsWith', label: 'ends with' },
    { name: 'doesNotContain', label: 'does not contain' },
    { name: 'doesNotBeginWith', label: 'does not begin with' },
    { name: 'doesNotEndWith', label: 'does not end with' },
    { name: 'null', label: 'is null' },
    { name: 'notNull', label: 'is not null' },
    { name: 'in', label: 'in' },
    { name: 'notIn', label: 'not in' }
];

const BasicQueryBuilder = (props: any) => {
    return  props.ruleState.rule.rule_view ?  <QueryBuilder
        fields={props.fieldList.map(x => {
            return {
                name: x,
                label: x.replaceAll("_", " ").toUpperCase()
            }
        })}
        showNotToggle={true}
        autoSelectOperator={true}
        enableDragAndDrop={true}
        operators={operators}
        query={props.query}
        onQueryChange={(query: any) => props.handleQueryChange(query)}
        controlElements={bootstrapControlElements}
        controlClassnames={bootstrapControlClassnames}
    /> :
       <QueryBuilder
        fields={props.fieldList.map(x => {
            return {
                name: x,
                label: x.replaceAll("_", " ").toUpperCase()
            }
        })}
        showNotToggle={true}
        autoSelectOperator={true}
        enableDragAndDrop={true}
        operators={operators}
        query={props.query}
        onQueryChange={(query: any) => props.handleQueryChange(query)}
        controlElements={bootstrapControlElements}
        controlClassnames={bootstrapControlClassnames}
        disabled
    />
    
}

export default memo(BasicQueryBuilder);