/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../common/Breadcrumb';
import Toast from '../StaticComponents/Toast';
import LoanDetails from './LoanDetails';
import LoanSearch from './LoanSearch';


const LoanScreen = (props: any) => {
    const [activeTab, setActiveTab] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [loanTabs, setLoanTabs] = useState<any>({});
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let loanScreenData: any = localStorage.getItem("loanScreenData");
        const locationData = location.state as any;
        if (!loanScreenData && locationData.loan_id) {
            addLoanTab(locationData.loan_id);
        }
        if (localStorage.getItem("isRefreshed") === 'true') {
            localStorage.setItem("isRefreshed", 'false');
            let loanScreenData: any = localStorage.getItem("loanScreenData");
            if (loanScreenData) {
                loanScreenData = JSON.parse(loanScreenData);
                setActiveTab(loanScreenData.currentTab);
                setLoanTabs(loanScreenData.loanTabs);
            }
        }
        window.addEventListener("beforeunload", handleRefresh);
        return () => {
            sessionStorage.removeItem("LoanViewLocationKey");
            window.removeEventListener("beforeunload", handleRefresh);
        };
    }, []);

    const handleRefresh = () => {
        localStorage.setItem("isRefreshed", 'true');
    }

    const addLoanTab = (loan_id: string) => {
        if (Object.keys(loanTabs).length < 6) {
            if (!loanTabs[loan_id]) {
                let newData = { ...loanTabs };
                newData[loan_id] = 1;
                setLoanTabs(newData);
                localStorage.setItem("loanScreenData", JSON.stringify({
                    loanTabs: newData,
                    currentTab: loan_id
                }));
            }
            setActiveTab(loan_id);
        } else {
            setToastMessage("Please remove some tabs")
        }
    };

    const handelRemoveTab = (loan_id: string) => {
        if (loanTabs[loan_id]) {
            const currentActiveTab = activeTab;
            let currentTab: any;
            let newData = { ...loanTabs }
            delete newData[loan_id];
            setLoanTabs(newData)
            let count = Object.keys(newData).length
            if (currentActiveTab === loan_id) {
                currentTab = count === 0 ? "loansearch" : Object.keys(newData)[count - 1];
                setActiveTab(currentTab);
            }
            else {
                currentTab = currentActiveTab;
                setActiveTab(currentTab);
            }
            localStorage.setItem("loanScreenData", JSON.stringify({
                loanTabs: newData,
                currentTab: currentTab
            }));
        }

    }

    const handelCrossHover = (e) => {
        if (e.target.classList[1] === "fa-times-circle-o") {
            e.target.classList.remove("fa-times-circle-o")
            e.target.classList.add("fa-times-circle")
        } else {
            e.target.classList.remove("fa-times-circle")
            e.target.classList.add("fa-times-circle-o")
        }
    }

    const selectActiveTab = (tab) => {
        if (loanTabs[tab]) {
            setActiveTab(tab)
        }
    }

    return (
        <>
            <div className="message_page">
                <div className="pagetitle"><Breadcrumb /></div>
                <div className="position-relative">
                    <div className="content_section">
                        <div className="dashboard-content">
                            <Toast msg={toastMessage} setMsg={setToastMessage} />
                            <div className={`${Object.keys(loanTabs).length === 0 ? "d-none" : ""} mb-3`}>
                                <ul className="nav cm_tabs" id="pills-tab" role="tablist">
                                    <li className={activeTab === "loansearch" ? "active" : ""}><a className={`nav-link ${activeTab === "loansearch" ? "active" : ""}`} onClick={() => setActiveTab("loansearch")} id="dash1-one-tab" data-toggle="tab" href="#dash1-one" role="tab" aria-controls="dash-one" aria-selected="false"><span>Loan Search</span></a></li>
                                    {
                                        Object.keys(loanTabs).map((tab, i) => {
                                            return (
                                                <li key={i}>
                                                    <a className={`nav-link ${activeTab === tab ? "active" : ""} d-flex justify-content-between align-items-center `} onClick={() => selectActiveTab(tab)} id={`dash${i + 2}-two-tab`} role="tab" >

                                                        <span className='invisible' style={{ display: "flex" }}>
                                                            <span className="fa fa-refresh" onClick={() => setRefresh(true)} style={{ fontSize: "1.2rem" }}></span>
                                                            <span className='fa fa-times-circle-o ' style={{ fontSize: "1.2rem", marginLeft: "9px" }} role="button" onMouseOver={handelCrossHover} onMouseLeave={handelCrossHover} onClick={() => { handelRemoveTab(tab) }}></span>
                                                        </span>
                                                        <span>{tab}</span>
                                                        <span style={{ display: "flex" }}>
                                                            <span className="fa fa-refresh refresh-anim" onClick={() => setRefresh(true)} style={{ fontSize: "1.2rem" }}></span>
                                                            <span className='fa fa-times-circle-o ' style={{ fontSize: "1.2rem", marginLeft: "9px" }} role="button" onMouseOver={handelCrossHover} onMouseLeave={handelCrossHover} onClick={() => { handelRemoveTab(tab) }}></span>
                                                        </span>
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                            <div className="tab-content" id="dash-tabContent">
                                <div className={`tab-pane fade ${activeTab === "loansearch" ? "show active" : ""}`} id="dash1-two" role="tabpanel" aria-labelledby="dash1-two-tab">
                                    <div className="dashboard-content">
                                        <LoanSearch addLoanTab={addLoanTab} />
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${activeTab !== "loansearch" ? "show active" : ""}`} id="dash2-two" role="tabpanel" aria-labelledby="dash2-two-tab">
                                    <div className="dashboard-content">
                                        <LoanDetails loan_id={activeTab !== "loansearch" ? activeTab : ""} refresh={refresh} setRefresh={setRefresh} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LoanScreen;