/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import AWS from "aws-sdk";
import awsmobile from "../../../aws-exports";
import {
  createTagAsync,
  updateTagAsync,
} from "../../../redux/reducers/loanReducer";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../../common/CustomPopupComponent";
import { Loader } from "../../../helpers/loader";
import {
  readAppState,
  setBreadcrumbLocation,
} from "../../../redux/reducers/appReducer";
import FormError from "../../common/FormError";
import Breadcrumb from "../../common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getTagList } from "../../../services/tagList.service";
import {
  readLoanState,
  setTagDetails,
} from "../../../redux/reducers/loanReducer";

const TagFormComponent = () => {
  const navigation = useNavigate();
  const [tagScreen, setTagScreen] = useState<any>(null);
  const [isDirty, setIsDirty] = useState(false);
  const loanState = useAppSelector(readLoanState);
  const appState = useAppSelector(readAppState);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const [loader, setLoader] = useState(false);
  const {handleSubmit,formState: { errors },} = useForm();
  const [error, setError] = useState({ errors: { cognito: null } });
  // const [disable, setDisable] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [tagDetail, setTagDetail] = useState({
    tagName: loanState.tagDetails?.tag_name
      ? loanState.tagDetails.tag_name
      : "",
  });
  const dispatch = useAppDispatch();
  const [localTagName, setLocalTagName] = useState<any>("");
  const [localPriority, setLocalPriority] = useState<any>("");
  const [localTagDescription, setLocalTagDescription] = useState<any>("");

  const screens = appState.masterUiScreenList
    ? appState.masterUiScreenList
    : [];

  useEffect(() => {
    setLocalPriority(loanState?.tagDetails?.priority);
    setLocalTagName(loanState?.tagDetails?.tag_name);
    setLocalTagDescription(loanState.tagDetails?.tag_description);
    let breadcrumbLocation = [...appState.breadcrumbLocation];
    breadcrumbLocation = breadcrumbLocation.filter(
      (x, i) =>
        i <= breadcrumbLocation.findIndex((x) => x.redirect_url === "/Tags")
    );
    loanState.tagDetails?.is_new
      ? breadcrumbLocation.push({
          screen_name: "Add Tag",
          redirect_url: "/TagManagement",
          icon_url: "fad fa-toolbox",
        })
      : breadcrumbLocation.push({
          screen_name: "Edit Tag",
          redirect_url: "/TagManagement",
          icon_url: "fad fa-toolbox",
        });

    dispatch(setBreadcrumbLocation(breadcrumbLocation));
    !loanState.tagDetails?.is_new &&
      getTagList().then((response: any) => {
        if (response && response.length > 0)
          setTagScreen(
            response
              .filter((x) => x.tag_id === loanState.tagDetails?.tag_id)
              .map((x) => {
                return {
                  label: x.tag_name,
                  value: x.tag_id,
                };
              })
          );
      });
  }, []);

  const handleSubmitForm = async () => {
    if (!tagScreen && tagScreen?.length === 0) {
      loanState.tagDetails?.is_new && setSubmit(true);
      return;
    }
    setLoader(true);
    
    if (loanState.tagDetails?.is_new) {
      try {
        const tagData = {
          tag_name: loanState.tagDetails.tag_name,
          tag_description: loanState.tagDetails.tag_description,
          priority: loanState.tagDetails.priority
            ? parseInt(loanState.tagDetails.priority)
            : null,
          is_active: true,
          created_date: new Date().toUTCString(),
          created_by: appState.currentUser!.maas_user_id,
        };
        dispatch(createTagAsync(tagData)).then((data: any) => {
          if (data.payload && data.payload.tag_id) {
            setPopup({
              ...popup,
              visible: true,
              message: "This Tag has been added.",
              type: ModalType.Snackbar,
              variant: VariantType.Success,
              toggle: () => {
                navigation("/Tags");
                setPopup({ ...defaultPopup });
              },
            });
          }
        });
      } catch (error: any) {
        let err = !error.message ? { message: error } : error;
        console.log(error);
        setError({
          errors: {
            ...error,
            cognito: err,
          },
        });
      }
    } else {
      if (loanState && loanState.tagDetails) {
        try {
          const data = {
            tag_id: loanState.tagDetails.tag_id,
            tag_name: loanState.tagDetails.tag_name,
            tag_description: loanState.tagDetails.tag_description,
            priority: loanState.tagDetails.priority
              ? parseInt(loanState.tagDetails.priority)
              : null,
            is_active: true,
            modified_date: new Date().toUTCString(),
            modified_by: appState.currentUser!.maas_user_id,
          };
          dispatch(updateTagAsync(data)).then((data: any) => {
            setPopup({
              ...popup,
              visible: true,
              message: "This Tag has been updated.",
              type: ModalType.Snackbar,
              variant: VariantType.Success,
              toggle: () => {
                navigation("/Tags");
                setPopup({ ...defaultPopup });
              },
            });
          });
        } catch (error: any) {
          let err = !error.message ? { message: error } : error;
          console.log(error);
          setError({
            errors: {
              ...error,
              cognito: err,
            },
          });
          setLoader(false);
          // setDisable(false);
          setPopup({
            ...popup,
            visible: true,
            message: "Tag could not be created!",
            type: ModalType.Snackbar,
            variant: VariantType.Error,
            toggle: () => {
              navigation("/Tags");
              setPopup({ ...defaultPopup });
            },
          });
        }
      }
    }
  };

  const submitButton = () => {
    if (loanState?.tagDetails?.is_new) {
      
      if(loanState?.tagDetails?.is_new &&  loanState.tagDetails?.tag_name && loanState.tagDetails?.tag_name !== "" && !loanState.tagNameList!.find((x: any) =>x.tag_name?.trim().toLowerCase() ===loanState.tagDetails?.tag_name?.trim().toLowerCase()) &&loanState.tagDetails?.tag_description && loanState.tagDetails?.tag_description !== "" && loanState.tagDetails?.priority &&loanState.tagDetails?.priority !== "" && !loanState.tagNameList!.find((x: any) =>x.priority?.toString() === loanState.tagDetails?.priority?.toString())){
        
        return (
          <button className="btn btn-primary text-center btn-block" type="submit"> Save</button>
        );
      } else {
       
        return (
          <button className="btn btn-primary text-center btn-block" type="submit" disabled>Save</button>
        );
      }
    } else {
      let flagA: boolean = false,
        flagB: boolean = false,
        flagC: boolean = false;
      if (
        localTagName?.trim()?.toLowerCase() !==
          loanState?.tagDetails?.tag_name?.trim()?.toLowerCase() ||
        localTagDescription?.trim()?.toLowerCase() !==
          loanState?.tagDetails?.tag_description?.trim()?.toLowerCase() ||
        localPriority?.toString() !==
          loanState?.tagDetails?.priority?.toString()
      ) {
        if (
          (loanState?.tagDetails?.tag_name?.toString() !== "" &&
            localTagName?.trim()?.toLowerCase() !==
              loanState?.tagDetails?.tag_name?.trim()?.toLowerCase() &&
            !loanState.tagNameList!.find(
              (x: any) =>
                x.tag_name.trim().toLowerCase() ===
                loanState.tagDetails?.tag_name.trim().toLowerCase()
            )) ||
          localTagName?.trim()?.toLowerCase() ===
            loanState?.tagDetails?.tag_name?.trim()?.toLowerCase()
        ) {
          flagA = true;
        }
        if (
          (loanState?.tagDetails?.tag_description?.toString() !== "" &&
            localTagDescription?.trim()?.toLowerCase() !==
              loanState?.tagDetails?.tag_description?.trim()?.toLowerCase()) ||
          localTagDescription?.trim()?.toLowerCase() ===
            loanState?.tagDetails?.tag_description?.trim()?.toLowerCase()
        ) {
          flagB = true;
        }
        if (
          (loanState?.tagDetails?.priority?.toString() !== "" &&
            localPriority?.toString() !==
              loanState?.tagDetails?.priority?.toString() &&
            !loanState.tagNameList!.find(
              (x: any) =>
                x.priority?.toString() ===
                loanState.tagDetails?.priority?.toString()
            )) ||
          localPriority?.toString() ===
            loanState?.tagDetails?.priority?.toString()
        ) {
          flagC = true;
        }
      }
      if (flagA && flagB && flagC) {
        return (
          <button
            className="btn btn-primary text-center btn-block"
            type="submit" onClick={()=>handleSubmitForm()}
          >
            Save
          </button>
        );
      } else {
        return (
          <button
            className="btn btn-primary text-center btn-block"
            type="submit"
            disabled
          >
            Save
          </button>
        );
      }
    }
  };

  const handleChnage = (e, field_name) => {
    let tagDetail = { ...loanState.tagDetails };

    if (field_name === "priority") {
      tagDetail[field_name] = e.target.value;
    } else {
      tagDetail[field_name] = e.target.value.replace(
        /[$&+,:;=?[\]@#|{}'<>.^*()%!-/_~`0-9]/,
        ""
      );
    }
    dispatch(setTagDetails({ ...tagDetail }));
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  return (
    <>
      <div className="pagetitle">
        <Breadcrumb />
      </div>
      <section className="section dashboard">
        {loader && <Loader />}
        {popup.visible && (
          <CustomPopupComponent
            message={popup.message}
            isOpen={popup.visible}
            toggle={popup.toggle}
            type={popup.type}
            variant={popup.variant}
          />
        )}
        <div className="block_sect">
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="row">
              <FormError formerrors={error} />
              <div className="col-lg-4">
                <div className="form-floating ct_form_field">
                  <input
                    type="text"
                    className="form-control"
                    id="tagname"
                    placeholder="Tag Name"
                    autoComplete="off"
                    value={loanState ? loanState.tagDetails?.tag_name : ""}
                    onChange={(e) => {
                      setIsDirty(true);
                      handleChnage(e, "tag_name");
                    }}
                  />
                  {loanState.tagDetails?.is_new
                    ? loanState.tagDetails?.is_new &&
                      loanState.tagNameList!.find(
                        (x: any) =>
                          x.tag_name?.trim()?.toLowerCase() ===
                          loanState.tagDetails?.tag_name?.trim()?.toLowerCase()
                      ) && (
                        <p style={{ color: "red" }}>
                          Tag already exists with this tag name.
                        </p>
                      )
                    : localTagName !== loanState?.tagDetails?.tag_name &&
                      loanState.tagNameList!.find(
                        (x: any) =>
                          x.tag_name?.trim()?.toLowerCase() ===
                          loanState.tagDetails?.tag_name?.trim()?.toLowerCase()
                      ) && (
                        <p style={{ color: "red" }}>
                          Tag already exists with this tag name.
                        </p>
                      )}
                  <label htmlFor="tagname">Tag Name</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating ct_form_field">
                  <input
                    type="text"
                    className="form-control"
                    id="tagdescription"
                    placeholder="Tag Description"
                    autoComplete="off"
                    value={loanState && loanState.tagDetails?.tag_description}
                    onChange={(e) => {
                      handleChnage(e, "tag_description");
                    }}
                  />
                  <label htmlFor="tagDescription">Tag Description</label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating ct_form_field">
                  <input
                    type="number"
                    className="form-control"
                    id="priority"
                    placeholder="Priority"
                    autoComplete="off"
                    min="0"
                    onKeyDown={blockInvalidChar}
                    value={loanState && loanState.tagDetails?.priority}
                    onChange={(e) => {
                      handleChnage(e, "priority");
                    }}
                  />
                  {loanState.tagDetails?.is_new
                    ? loanState.tagDetails?.is_new &&
                      loanState.tagNameList!.find(
                        (x: any) =>
                          x.priority?.toString() ===
                          loanState.tagDetails?.priority?.toString()
                      ) && (
                        <p style={{ color: "red" }}>
                          Priority already exists .
                        </p>
                      )
                    : localPriority?.toString() !==
                        loanState?.tagDetails?.priority?.toString() &&
                      loanState.tagNameList!.find(
                        (x: any) =>
                          x.priority?.toString() ===
                          loanState.tagDetails?.priority?.toString()
                      ) && (
                        <p style={{ color: "red" }}>
                          Priority already exists .
                        </p>
                      )}
                  <label htmlFor="priority">Priority</label>
                </div>
              </div>
            </div>
            <div className="row justify-content-md-center mt30">
              <div className="col-lg-2 col">
                {submitButton()}</div>
              <div className="col-lg-2 col">
                <button
                  className="btn btn-outline-primary text-center btn-block"
                  type="submit"
                  onClick={() => { navigation("/Tags"); dispatch(setTagDetails({ ...loanState?.tagDetails, is_edit: false}))}}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
export default memo(TagFormComponent);
