/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
    return (<footer id="footer" className="footer fixed-bottom">
        <div className="row">
            <div className="col">
                <span className="copyright">
                    &copy; Copyright 2022. All rights reserved
                </span>
            </div>
            <div className="col">
                <ul className="footer_links">
                    <li><a >T&amp;C</a></li>
                    <li><a >Blog</a></li>
                    <li><a >Policy</a></li>
                </ul>
            </div>
        </div>
    </footer>)
}

export default Footer;