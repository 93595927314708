/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoanTransactionAsync } from '../../../redux/reducers/loanReducer';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { LoanTransactionTypes } from '../../../constants/loanDetail';
import { RootState } from '../../../app/store';
import formatAmountTable from "../../../constants/formatAmountTable";
import Select from "react-select";
import { selectCustomStyles} from "../../common/CustomStyles";
import formatDateTable from '../../../constants/dateFormatTable';
import {readAppState}from "../../../redux/reducers/appReducer"
import GenericAgGrid from "../../common/GenericAgGrid";


const LoanTransations = (props: any) => {
    const dispatch = useAppDispatch();
    const [transactionType, setTransactionType] = useState(LoanTransactionTypes.BalanceTransactions);
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    // const gridRef: any = useRef();
    const [option,setOption]=useState([{label:"Balance Transactions",value:"BalanceTransactions"}])
    const [optionValue,setOptionValue]=useState<any>(null)
    const appState = useAppSelector(readAppState);
    const gridLoanTransactionRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'LOAN_TRANSACTION');


    useEffect(() => { }, [activeTab]);

   

    const formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const percentageFormatter = (params) => {
        return formatNumber(params.value) + '%';
    };

     const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }

    const balanceTransactionColumns = [
        { headerName: 'Transaction Date', field: 'TRANSACTION_DATE', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, false)},
        { headerName: 'Effective Date', field: 'EFFECTIVE_DATE', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, false)},
        { headerName: 'Incurred Date', field: 'INCURRED_DATE', width: 150 ,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, false)},
        { headerName: 'Transaction Code', field: 'TRANSACTION_CODE', width: 150 },
        { headerName: 'Transaction Description', field: 'TRANSACTION_DESCRIPTION', width: 150 },
        { headerName: 'Principal Amount', field: 'PRINCIPAL_AMOUNT', width: 150,type: 'rightAligned', valueFormatter: formatAmountTable },
        { headerName: 'Interest Amount', field: 'INTEREST_AMOUNT', width: 150, valueFormatter: formatAmountTable,type: 'rightAligned' },
        { headerName: 'MIP Amount', field: 'MIP_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Service Fee Amount', field: 'SERVICE_FEE_AMOUNT', width: 150, valueFormatter: formatAmountTable,type: 'rightAligned' },
        { headerName: 'Loan Balance Transaction Amount', field: 'TOTAL_TRANSACTION_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Running Loan Balance', field: 'CURRENT_LOAN_BALANCE', width: 150, valueFormatter: formatAmountTable,type: 'rightAligned' },
        { headerName: 'Unscheduled Disbursement Accrual Amount ', field: 'UNSCHEDULED_DISBURSEMENT_ACCRUAL_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Corp Advance Borrower', field: 'CORPORATE_ADVANCE_BORROWER_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Corp Advance Investor Amount', field: 'CORPORATE_ADVANCE_INVESTOR_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Recoverable %', field: 'RECOVERABLE_PERCENT', width: 150 , valueFormatter: percentageFormatter,type: 'rightAligned'},
        { headerName: 'Servicer Expense Amount', field: 'SERVICER_EXPENSE_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
        { headerName: 'Recoverable Amount', field: 'RECOVERABLE_AMOUNT', width: 150 , valueFormatter: formatAmountTable,type: 'rightAligned'},
    ];



    const creditLienColumns = [
        { headerName: 'Transaction Date', field: 'TRANSACTION_DATE', width: 300,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Effective Date', field: 'EFFECTIVE_DATE', width: 300,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Incurred Date', field: 'INCURRED_DATE', width: 300,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Transaction Code', field: 'TRANSACTION_CODE', width: 320 },
        { headerName: 'Transaction Type', field: 'TRANSACTION_TYPE', width: 350 }
    ];

    const setAsideTransactionColumns = [
        { headerName: 'Transaction Date', field: 'TRANSACTION_DATE', width: 300,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Effective Date', field: 'EFFECTIVE_DATE', width: 300,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Incurred Date', field: 'INCURRED_DATE', width: 300 ,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true)},
        { headerName: 'Transaction Code', field: 'TRANSACTION_CODE', width: 320 },
        { headerName: 'Transaction Type', field: 'TRANSACTION_TYPE', width: 350 }
    ];

    const getColumns = () => {
        switch (transactionType) {
            case LoanTransactionTypes.BalanceTransactions: return balanceTransactionColumns;
            case LoanTransactionTypes.CreditLinePrincipalLimitTransaction: return creditLienColumns;
            case LoanTransactionTypes.SetAsideTransaction: return setAsideTransactionColumns;
            default: return balanceTransactionColumns;
        }
    }

    const defaultColDef = {
        sortable: true, resizable: true, filter: true
    }
    const handleSelectChange = (e) => {
        setOptionValue(e.value);
        if(e.value==="BalanceTransactions"){
            setTransactionType(LoanTransactionTypes.BalanceTransactions) 
        }
    }  
    return (<>
    <div className="row">
            <div className="float-r col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div className="font-color form-floating multi-select-field ct_form_field select_dd" style={{marginBottom:"10px",marginTop:"-25px"}}>
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={ selectCustomStyles}
                                        className=" font-color form-control multi-select-dd dropdown-padding " 
                                        id="role"
                                        options={option}
                                        value={optionValue}
                                        onChange={(e) => handleSelectChange(e)}
                                        placeholder={transactionType}
                                       
                                    />
                                </div>
                            </div>
                        </div>
           
        
        <div className="table-responsive">
            {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={getColumns()}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridLoanTransactionRef}
                        ></GenericAgGrid>
                    </div>}
           
        </div>
    </>
    );
}

export default LoanTransations;