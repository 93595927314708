
import { useAppSelector } from "../app/hooks";
import { readAppState } from "../redux/reducers/appReducer";


export const Loader = () => {
    const appState = useAppSelector(readAppState);
    return <>
        <div className="loading_box" style={{ position: "fixed", zIndex: "999" }}>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            {appState.loaderMessage !== "" && <span className="spin_text">{appState.loaderMessage}</span>}
        </div>
    </>

}