import { useState ,useEffect,useRef } from "react";
import Select from "react-select";
import { commentOptions } from "../../../constants/loanDetail";
import { selectCustomStyles } from "../../common/CustomStyles";
import { readAppState } from "../../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createCommentAsync, updateCommentAsync,setCommentList,readLoanState  } from "../../../redux/reducers/loanReducer";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";

const CommentsDetailForm = (props: any) => {
    
    const [commentType, setCommentType] = useState<any>(null);
    const [commentTypeOptions, setCommentOptions] = useState<any>([]);
    const [commentDetail, setCommentDetail] = useState({ comment_description: "" });
    const appState = useAppSelector(readAppState);
    const commentsState=useAppSelector(readLoanState);
    const dispatch = useAppDispatch();
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [isNew, setIsNew] = useState(props.data&&props.data.loan_comment_id>0?false:true);  
    const ref = useRef(null);  
    
    useEffect(() => {
        let commentTypeOption=commentOptions().map((commentArr) => {
            return {
              value: commentArr[0],
              label: commentArr[1],
            };
          })
        setCommentOptions(commentTypeOption);
        if(!isNew){
            setCommentType(commentTypeOption.find(x => x.value === props.data.comment_type))
            setCommentDetail({comment_description:props.data.comment_description})
        }
    }, []);

  const handleSubmitForm=()=>{
    if(isNew){
        const commentData = {
            loan_number:props.loan_id,
            comment_type: commentType.value,
            comment_description:commentDetail.comment_description,
            created_by: appState.currentUser!.maas_user_id,
            created_date: new Date().toUTCString(),
            modified_by: appState.currentUser!.maas_user_id,
            modified_date: new Date().toUTCString(),
        };       
        
        dispatch(createCommentAsync(commentData)).then((data: any) => {        
            if (data.payload && data.payload.loan_comment_id) {            
              setPopup({ ...popup, visible: true, message: "This new comment has been added.", type: ModalType.Snackbar, variant: VariantType.Success });
              setTimeout(() => {
                  setPopup({ ...defaultPopup }); 
                  props.toggle()               
              }, 2000);
              setCommentType('');
              setCommentDetail({comment_description:''})
            }
        });
    }else{
        const commentData = {
            loan_comment_id:props.data.loan_comment_id,
            loan_number:props.loan_id,
            comment_type: commentType.value,
            comment_description:commentDetail.comment_description,            
            modified_by: appState.currentUser!.maas_user_id,
            modified_date: new Date().toUTCString(),
        };       
        
        dispatch(updateCommentAsync(commentData)).then((data: any) => { 
            if (data.payload && data.payload.loan_comment_id) {            
                setPopup({ ...popup, visible: true, message: "Comment is updated.", type: ModalType.Snackbar, variant: VariantType.Success });
                setTimeout(() => {
                    setPopup({ ...defaultPopup }); 
                    props.toggle()               
                }, 2000);
                setCommentType('')
                setCommentDetail({comment_description:''})
            }
        })
    }
  }

  const submitButton = () => {
        if (isNew?commentDetail.comment_description !== "" && (commentType) :((commentDetail.comment_description !== props.data.comment_description)||(commentType&& commentType.value!==props.data.comment_type))) {
            return (<button className="btn btn-primary text-center btn-block"  type="submit" onClick={() => { handleSubmitForm() }}>Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center text-center btn-block" type="submit" disabled >Save</button>)
        }
    }

  return (
    <div className="row">
      {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
        <div className="col">
          <div className="row payment-boxes ">
            <div className="col-lg-12 payment-box-heading">
              <h6 className="accordion_card1_title">{isNew?"Add":"Edit"} Comment</h6>
            </div>
            <div className="col-lg-4 col-md-4 " style={{float: 'none',margin: '0 auto'}}>            
              <div className="form-floating multi-select-field ct_form_field select_dd">
                <Select isClearable={true}
                          styles={selectCustomStyles}
                          className="form-control  multi-select-dd"
                          id="commentType"
                          options={commentTypeOptions}
                          value={commentType}
                          onChange={(e) => {setCommentType(e) }}
                          placeholder="Select Type..."
                      />
                <label htmlFor="commentType">Comment Type</label>
              </div>            
              <div className="form-floating ct_form_field">
                  <textarea  className="form-control"
                      id="comment"
                      placeholder="Comments.."
                      style={{height:"auto",minHeight:"80px"}}
                      rows={1}
                      value={commentDetail.comment_description}                    
                      onChange={(e) => setCommentDetail({ ...commentDetail, comment_description: e.target.value })}
                  />                
                  <label htmlFor="comment" ref={el => {
                    if (el) {
                      el.style.setProperty('padding-right', '120px', 'important');
                      el.style.setProperty('padding-bottom', '0px', 'important');
                    }
                    }}
                   style={{background:"#fff",height:"auto"}}>Comment</label>
              </div>
            </div>
            <div className="row justify-content-md-center mt30" style={{ margin: "1rem" }}>
            <div className="col-lg-2 col text-center">
              {submitButton()}</div>
              <div className="col-lg-2 col text-center">
                <button className="btn btn-outline-primary text-center btn-block" onClick={() => {props.toggle(); dispatch(setCommentList({ ...commentsState.commentsData, is_edit: false}))}}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CommentsDetailForm;
