/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import formatDate from "../../../constants/dateFormat";
import formatAmount from "../../../constants/formatAmount";


const Payment = (params: any) => {
    let loanDetails = params.loanDetails;
    return (
        <div className="row">
            <div className="col-md-4">
                <div className="card_widget">
                    <div className="card_w_header2">Payment Information</div>
                    <ul className="l_info_list_group2">
                        <li>
                            <span>Principal & Interest</span>
                            <strong>{formatAmount(loanDetails.monthly_payment)}</strong>
                        </li>
                        <li>
                            <span>Escrow</span><strong>{formatAmount(loanDetails.current_month_escrow_payment)}</strong>
                        </li>
                        <li>
                            <span>Late Charge Amount</span><strong>{formatAmount(loanDetails.total_paid_late_charges_amount)}</strong>
                        </li>
                        <li>
                            <span>Total Monthly Payment	</span><strong>{formatAmount(loanDetails.monthly_payment)}</strong>
                        </li>
                        <li>
                            <span>Payment + Late Charge</span><strong >{formatAmount(Number(loanDetails.monthly_payment || 0) + Number(loanDetails.total_paid_late_charges_amount || 0))}</strong>
                        </li>
                    </ul>
                </div>
                <div className="card_widget">
                    <div className="card_w_header2">Escrow</div>
                    <ul className="l_info_list_group2">
                        <li>
                            <span>Escrow Balance</span>
                            <strong>{formatAmount(loanDetails.current_escrow_balance)}</strong>
                        </li>
                        <li>
                            <span>Escrow Advance</span><strong>{formatAmount(loanDetails.current_escrow_advance_balance)}</strong>
                        </li>
                    </ul>
                </div>
                <div className="card_widget">
                    <div className="card_w_header2">Advances </div>
                    <ul className="l_info_list_group2">
                        <li>
                            <span>Corporate Advances</span>
                            <strong>{formatAmount(loanDetails.current_corporate_advance)}</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card_widget">
                    <div className="card_w_header2">
                        Payment Due
                    </div>
                    <ul className="l_info_list_group2">
                        <li>
                            <span>Last Payment Received	</span>
                            <strong>{loanDetails.last_payment_date ? formatDate(loanDetails?.last_payment_date) : "---"}</strong>
                        </li>
                        <li>
                            <span>Next Payment Due</span><strong>{loanDetails.next_due_date ? formatDate(loanDetails?.next_due_date) : "---"}</strong>
                        </li>
                        <li>
                            <span>Due Payments</span><strong>{loanDetails.remaining_payments}</strong>
                        </li>
                        <li>
                            <span>Principal & Interest</span><strong>{formatAmount((Number(loanDetails.monthly_payment) * Number(loanDetails.remaining_payments)))}</strong>
                        </li>
                        <li>
                            <span>Escrow</span><strong>{formatAmount(Number(loanDetails.current_month_escrow_payment) * Number(loanDetails.remaining_payments))}</strong>
                        </li>
                        {/* <li>
                            <span>Past Deficiency	</span><strong style={{ textAlign: "right" }}>{formatAmount(loanDetails?.monthly_payment	)}</strong>
                        </li>   */}



                        <li>
                            <span>late charges</span><strong style={{ textAlign: "right" }}>{formatAmount(loanDetails.late_charge_due)}</strong>
                        </li>
                        <li>
                            <span>Bad Check Fees</span><strong style={{ textAlign: "right" }}>{formatAmount(loanDetails.nsf_charges?.loan_fee?.total_nsf_charges_amount)}</strong>
                        </li>
                        <li>
                            <span>Other Fees</span><strong style={{ textAlign: "right" }}>{formatAmount(loanDetails.other_fees)}</strong>
                        </li>
                        <li>
                            <span>Total Due</span><strong style={{ textAlign: "right" }}>{((Number(loanDetails.monthly_payment || "0") * Number(loanDetails.remaining_payments || "0")) + Number(loanDetails.late_charge_due || "0") + Number(loanDetails.other_fees || "0")).toFixed(2)}</strong>
                        </li>

                    </ul>

                </div>
            </div>
        </div>
    );
}
export default React.memo(Payment);