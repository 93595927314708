/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useEffect, useRef } from "react";
import { readAppState } from '../../redux/reducers/appReducer';
import { useAppSelector } from "../../app/hooks";


const DataGrid = (props: any) => {
    const appState = useAppSelector(readAppState);
    const gridRef: any = useRef();

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const onGridReady = async (params: any) => {
        try {            
            params.api.paginationGoToPage(10);
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 500)
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    useEffect(() => {

        if (gridRef.current.api) {
            setTimeout(() => {
                gridRef.current.api.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen])

    return (
        <div className="ag-theme-alpine" style={{ height: '70vh', width: '98%', marginTop: "10px", marginLeft: "15px" }}>
            <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-lg-6 col-md-6">
                    <h6 className="page_title3" style={{ color: "gray" }}><strong>Preview Rules</strong></h6>
                </div>
            </div>
            {props.rowData && props.rowData.length > 0 && <p style={{ color: "grey", margin: "5px" }}>{props.rowData.length} records has been fetched. </p>}
            <AgGridReact
                ref={gridRef}
                rowData={props.rowData}
                columnDefs={props.columns}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                // headerHeight={35}
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Result Set is empty. Please execute rule</span>'}
                modules={[ClientSideRowModelModule]}>
            </AgGridReact>
        </div>
    );
}

export default DataGrid;