/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { getDashboardToken } from "../../services/dashboard.service";
import { Loader } from "../../helpers/loader";
import { getAllNavigations } from "../../services/navigationConfiguration.service";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../common/Breadcrumb";
import { readAppState, resetFilterKeys, setBreadcrumbLocation, setCurrentEmbededDashboard, setLogiToken } from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { toggleAppContainerClass } from "../../services/toggleAppContainerClass";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { clearLoanTabs, clearSearchedLoans, setActiveTab } from "../../redux/reducers/loanReducer";
import { setFileInformation } from "../../redux/reducers/transformerLogsReducer";
import { dashNavCustomStyle } from "../common/CustomStyles";

const DashboardScreenComponent = (props: any) => {
    const [loading, setLoader] = useState(true);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const appStateRef = useRef(appState);
    const dispatch = useAppDispatch();
    const logiEmbedManager = useRef(undefined as any);
    const componentInstanceId = useRef(undefined as any);
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const location = useLocation();
    const reloadFlag = useRef(false);
    const breadcrumbs = useRef(appState.breadcrumbLocation);
    const isSharedDashboard =useRef(false) 
    const current_dashboard_id=useRef('')
   
    const getMasterToken = async (optional?: boolean) => {
        if (appState.logiToken && optional) {
            return appState.logiToken;
        }
        const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
            return r
        })
        if (token) {
            dispatch(setLogiToken(token));
        }
        return token;
    };

    useEffect(()=>{
        current_dashboard_id.current=appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value?appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value.split('+')[1]:''    
        const currentDashboard = appState && (appState.masterDashboardList.find(dash => dash.inventoryItemId === current_dashboard_id.current));
        isSharedDashboard.current = currentDashboard && currentDashboard.creatorId !== (appState.userGroups.find(g => g.group_id === appState.currentUser?.group_id)).logi_user_id;

    },[])

    const sharedDashboardInteractivity = {
        "name": "interactive",
        "type": "SYSTEM",
        "overrideVisualInteractivity": true,
        "settings": {
        "REFRESH": true,
        "CHANGE_LAYOUT": false,
        "RENAME": false,
        "SHARE_FILTER_SETS": false,
        "DASHBOARD_INTERACTIONS": false,
        "ADD_TO_FAVORITES": false,
        "DELETE": false,
        "FILTER": true,
        "EXPORT_PNG_PDF": true,
        "ADD_VISUALS": false,
        "EXPORT_CONFIGURATION": false,
        "DASHBOARD_LINKS": false,
        "SAVE": false,
        },
        "visualSettings": {
        "ACTIONS_ACTION": true,
        "RULERS": true,
        "ZOOM_ACTION": false,
        "FILTER_ACTION": true,
        "COLORS": true,
        "METRICS": true,
        "ACTIONS": true,
        "TREND_ACTION": false,
        "VISUAL_STYLE": true,
        "KEYSET_ACTION": false,
        "KEYSET": false,
        "COPY": true,
        "SETTINGS": true,
        "EXPORT": true,
        "TIMEBAR_PANEL": true,
        "DETAILS_ACTION": true,
        "MAXIMIZE": true,
        "LINK_ACTION": true,
        "FILTER": true,
        "REMOVE": false,
        "GROUPING": true,
        "RENAME": false,
        "SORT": true,
        "TIMEBAR_FIELD": true
        }
    }

    let clicksReady = false;
    
    const handleTableClick = e => {
        try {
            let target = e.target;
            const breadcrumbPath = [...breadcrumbs.current];
            if (target.classList.contains('ag-cell')) {
                let loan_id = target.parentNode.querySelector('[col-id="loan_number"]') ? target.parentNode.querySelector('[col-id="loan_number"]').innerText : null;
                if (location.pathname.includes('/Surveillance') && loan_id) {
                    const fetchPath = async () => {
                        const path: any = await getRedirectPath(loan_id, target);
                        if (path) {
                            const menu = screens.find(x => x.redirect_url.trim().toLowerCase() === path.trim().toLowerCase());
                            const breadcrumbPath = [...breadcrumbs.current];
                            if (menu) {
                                if (!breadcrumbPath.find(x => x.redirect_url === menu.redirect_url)) {
                                    breadcrumbPath.push({
                                        "screen_id": menu.screen_id,
                                        "screen_name": menu.screen_name,
                                        "redirect_url": './Surveillance' + menu.redirect_url,
                                        "icon_url": menu.icon_url
                                    });
                                }
                            }
                            dispatch(setBreadcrumbLocation(breadcrumbPath));
                            if (path.toLowerCase() === "/screennavigations" || path.toLowerCase() === "/users") {
                                toggleAppContainerClass("body_wrapper")
                            }
                            if (path.toLowerCase() === '/loandetail') {
                                if (location.pathname.includes('/LoanDetail')) {
                                    dispatch(setActiveTab(loan_id));
                                    navigation(`${location.pathname}`, { state: { loan_id: loan_id } });
                                }
                                else {
                                    if (location.pathname.includes('/LoanSearchResult')) {
                                        const path = location.pathname.split('/');
                                        dispatch(setActiveTab(loan_id));
                                        navigation(`/${path[1]}/LoanDetail`, { state: { loan_id: loan_id } });
                                    }
                                    else {
                                        dispatch(setActiveTab(loan_id));
                                        navigation(`${location.pathname}/LoanDetail`, { state: { loan_id: loan_id } });
                                    }
                                }
                            }
                            else {
                                navigation(path, { state: { loan_id: loan_id } });
                            }
                        }
                    }
                    fetchPath();
                }
                else {
                    if (props.dashboardName === "transformer_log_dashboard") {
                        let allColumnsList: any[] = target.parentNode.querySelectorAll('div');
                        let file_name = '';
                        let servicer_name = '';
                        let status: string = '';
                        let transformer_log_id = '';
                        let process_id = '';
                        let error_file_path = '';
                        let file_path = '';

                        Array.from(allColumnsList).map(item => {
                            if (item.getAttribute("aria-colindex") == '1') {
                                let tempStr = target.parentElement.getAttribute("row-id");
                                let groupByColumn = tempStr.substring(0, tempStr.lastIndexOf('-'));

                                switch (item.getAttribute("col-id")) {
                                    case 'file_name': {
                                        file_name = groupByColumn
                                        break;
                                    }
                                    case 'servicer_name': {
                                        servicer_name = groupByColumn;
                                        break;
                                    }
                                    case 'status_desc': {
                                        status = groupByColumn;
                                        break;
                                    }
                                    case 'transformer_logs_id': {
                                        transformer_log_id = groupByColumn;
                                        break;
                                    }
                                    case 'process_id': {
                                        process_id = groupByColumn;
                                        break;
                                    }
                                    case 'error_file_path': {
                                        error_file_path = groupByColumn;
                                        break;
                                    }
                                    case 'file_path_in_s3': {
                                        file_path = groupByColumn;
                                        break;
                                    }
                                }
                            }
                            else {
                                switch (item.getAttribute("col-id")) {
                                    case 'file_name': {
                                        file_name = item.innerHTML;
                                        break;
                                    }
                                    case 'servicer_name': {
                                        servicer_name = item.innerHTML;
                                        break;
                                    }
                                    case 'status_desc': {
                                        status = item.innerHTML;
                                        break;
                                    }
                                    case 'transformer_logs_id': {
                                        transformer_log_id = item.innerHTML;
                                        break;
                                    }
                                    case 'process_id': {
                                        process_id = item.innerHTML;
                                        break;
                                    }
                                    case 'error_file_path': {
                                        error_file_path = item.innerHTML;
                                        break;
                                    }
                                    case 'file_path_in_s3': {
                                        file_path = item.innerHTML;
                                        break;
                                    }
                                }
                            }
                        })

                        let statusWithHyphen: any = status.match(/-/g);
                        if (statusWithHyphen.length > 1)
                            status = status.replace(/-/, '_');

                        dispatch(setFileInformation({
                            servicer_name: servicer_name,
                            file_name: file_name,
                            process_name: status.length > 0 ? status.split('-')[0].trim().replace(/ /g, '_') : '',
                            status: status.length > 0 ? status.split(' ').pop() : '',
                            file_path: file_path,
                            process_id: process_id,
                            error_file_path: error_file_path,
                            transformer_log_id: transformer_log_id
                        }))

                        const breadcrumbPath = [...breadcrumbs.current];
                        if (!breadcrumbPath.find(x => x.redirect_url === "/FileLogs")) {
                            breadcrumbPath.push({
                                "screen_id": "",
                                "screen_name": "File Logs",
                                "redirect_url": "/DataLoadStatistics/FileLogs",
                                "icon_url": "fas fa-people-arrows"
                            });
                        }
                        dispatch(setBreadcrumbLocation(breadcrumbPath));
                        navigation(`/DataLoadStatistics/FileLogs`);
                    }
                    else {
                        if (loan_id) {
                            const loanDetailPath = screens.find(x => x.redirect_url === '/LoanDetail')
                            if (loanDetailPath) {
                                breadcrumbPath.push(loanDetailPath);
                            }
                            dispatch(setBreadcrumbLocation(breadcrumbPath));
                            if (location.pathname.includes('/LoanDetail')) {
                                dispatch(setActiveTab(loan_id));
                                navigation(`${location.pathname}`, { state: { loan_id: loan_id } });
                            }
                            else {
                                if (location.pathname.includes('/LoanSearchResult')) {
                                    const path = location.pathname.split('/');
                                    dispatch(setActiveTab(loan_id));
                                    navigation(`/${path[1]}/LoanDetail`, { state: { loan_id: loan_id } });
                                }
                                else {
                                    dispatch(setActiveTab(loan_id));
                                    navigation(`${location.pathname}/LoanDetail`, { state: { loan_id: loan_id } });
                                }
                            }
                        }
                    }
                }
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const initClicks = () => {
        if (clicksReady) {
            return;
        }
        clicksReady = true;
        if (props.gridAbbreviation) {
            let columnName = 'loan_number';
            if (props.dashboardName === "transformer_log_dashboard") {
                columnName = 'file_name'
            }
            const dashboardGrids = appState.logiConfiguration.filter(x => x.configuration_type.includes(props.gridAbbreviation));
            dashboardGrids.forEach(x => {
                const style = document.createElement('style');
                style.setAttribute("name", "user_added");
                style.innerHTML = `.${props.dashboardName} [col-id="${columnName}"].ag-cell {${dashNavCustomStyle}}`;
                document.getElementsByTagName('head')[0].appendChild(style);
                const allColumnsClickableTable = document.querySelector(`[data-widget-id-view="${x?.configuration_value}"] .ag-root`);
                allColumnsClickableTable?.addEventListener('dblclick', e => e.preventDefault());
                allColumnsClickableTable?.addEventListener('click', e => handleTableClick(e));

            })
            if (document.getElementById('embed')) {
                document.getElementById('embed')!.className = props.dashboardName;
            }
        }
    }

    const embedComponent = (embedManager: any) => {
        setLoader(false);
        embedManager.createComponent('dashboard', {
            "dashboardId": appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value,
            "theme": props.theme,
            "header": {
                'visible': props.showEditor,
                'showTitle': props.showTitle,
                'showActions': props.showActions,
                'showEditor': props.showEditor,
            },
            "interactivityProfileName": props.interactivityProfileName,
            "interactivityOverrides": isSharedDashboard.current ?sharedDashboardInteractivity : props.interactivityOverrides
        }).then((dash: any) => {
            dispatch(setCurrentEmbededDashboard(dash.componentInstanceId));
            componentInstanceId.current = dash.componentInstanceId;
            dash.render(document.getElementById('embed'), { width: props.width, height: props.height });
            if (props.gridAbbreviation) {
                dash.addEventListener('composer-dashboard-ready', e => {
                    initClicks();
                });
            }
        });
    }

    useEffect(() => {
        try {
            (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then(async (embedManager: any) => {
                logiEmbedManager.current = embedManager
                setLoader(false);
                embedComponent(embedManager);
            })
                .catch((e: any) => { console.log(`Logi Error: ${e}`); })

            return (() => {
                if (!reloadFlag.current) {
                    dispatch(clearLoanTabs());
                    localStorage.removeItem("loanScreenData");
                }
                dispatch(clearSearchedLoans());
                dispatch(resetFilterKeys());
                if (logiEmbedManager.current && componentInstanceId.current) {
                    logiEmbedManager.current.removeComponent(componentInstanceId.current);
                    dispatch(setCurrentEmbededDashboard(null));
                }
            });
        }
        catch (e: any) {
            console.log(e)
        }
    }, [props.dashboardName]);

    useEffect(() => {
        breadcrumbs.current = appState.breadcrumbLocation;
        const removeButton = document.getElementById('buttonDiv');
        removeButton?.remove();
    }, [appState.breadcrumbLocation])

    const getRedirectPath = async (loan_id: any, target: any) => {
        let path: any;
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId = userGroups.find(g => g.group_id === appState.currentUser?.group_id)?.logi_user_id;
        const navigations = await getAllNavigations();
        if (loan_id && navigations && navigations.length > 0) {
            const loanStatus = target.parentNode.querySelector('[col-id="loan_status_description"]').childElementCount > 0
                ? target.parentNode.getAttribute('row-id').split('-')[0]
                : target.parentNode.querySelector('[col-id="loan_status_description"]').innerText;
            const navigationConfig = navigations.find(z => z.loan_status.toLowerCase() === loanStatus.toLowerCase());
            if (navigationConfig && screens.find(x => x.redirect_url === navigationConfig.path)) {
                path = navigationConfig.path
            }
            else {
                if (loanStatus !== "") {
                    path = location.pathname;
                    setPopup({ ...popup, visible: true, message: "Navigation for the intended loan status is not configured!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                }
            }
        }
        else {
            path = location.pathname;
            setPopup({ ...popup, visible: true, message: "Navigation for the intended loan status is not configured!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
        return path;
    }

    return (
        <>
            <div className={`pagetitle ${location?.pathname?.split('/').length === 2 ? "fixed-position" : ""}`}> <Breadcrumb /> </div>
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
            <div className="container-fluid position-relative">
                <div className="content_section">
                    <div className={`${location.pathname !== props.rootPath ? 'hide' : 'container min_height_dashboard'}`}>
                        {loading ? (<Loader />) : null}
                        <div className={props.dashboardName} id="embed">
                        </div>
                    </div>
                    <Outlet />
                </div>
            </div>
        </>
    );
}

DashboardScreenComponent.defaultProps = {
    composerUrl: '',
    theme: "modern",
    id: '',
    appBanner: false,
    appLogo: false,
    showEditor: true,
    showActions: true,
    showTitle: false,
    width: "100%",
    height: "100%",
    interactivityProfileName: "interactive",
    interactivityOverrides: {
        "name": "interactive",
        "type": "SYSTEM",
        "overrideVisualInteractivity": true,
        "settings": {
            "REFRESH": true,
            "CHANGE_LAYOUT": false,
            "RENAME": false,
            "SHARE_FILTER_SETS": false,
            "DASHBOARD_INTERACTIONS": false,
            "ADD_TO_FAVORITES": false,
            "DELETE": false,
            "FILTER": true,
            "EXPORT_PNG_PDF": true,
            "ADD_VISUALS": false,
            "EXPORT_CONFIGURATION": false,
            "DASHBOARD_LINKS": false,
            "SAVE": true,
        },
        "visualSettings": {
            "ACTIONS_ACTION": true,
            "RULERS": true,
            "ZOOM_ACTION": false,
            "FILTER_ACTION": true,
            "COLORS": true,
            "METRICS": true,
            "ACTIONS": true,
            "TREND_ACTION": false,
            "VISUAL_STYLE": true,
            "KEYSET_ACTION": false,
            "KEYSET": false,
            "COPY": true,
            "SETTINGS": true,
            "EXPORT": true,
            "TIMEBAR_PANEL": true,
            "DETAILS_ACTION": true,
            "MAXIMIZE": true,
            "LINK_ACTION": true,
            "FILTER": true,
            "REMOVE": false,
            "GROUPING": true,
            "RENAME": false,
            "SORT": true,
            "TIMEBAR_FIELD": true
        }
    }
}
export default DashboardScreenComponent;