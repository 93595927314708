import { API, graphqlOperation } from "aws-amplify";
import { createBulkLoanTagMapping, createLoanTagMapping, deleteBulkLoanTagMapping, deleteLoanTagMapping, updateLoanTagComment, updateLoanTagMapping } from "../graphql/mutations";
import { getLoanTagHistory, getLoanTagMapping, listTag } from "../graphql/queries";

export const getTagList = async () => {
    try {
        let response: any = await API.graphql(graphqlOperation(listTag));
        return response && response.data ? response.data.listTag : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertTagLoanMapping= async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createLoanTagMapping, {input: data }));
        return response && response.data ? response.data.createLoanTagMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
export const updateLoanComment= async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(updateLoanTagComment, {input:data}));
        return response && response.data ? response.data.updateLoanTagComment : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getLoanTagMappingList = async (data:any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanTagMapping,{loan_number:data}));
        return response && response.data ? response.data.getLoanTagMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const listLoanTagHistory = async (data:any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanTagHistory,{loan_number:data}));
        return response && response.data ? response.data.getLoanTagHistory : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateTagLoanMappings= async (data:any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(updateLoanTagMapping, {input:data}));
        return response && response.data ? response.data.updateLoanTagMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const deleteTagLoanMappings= async (data:any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(deleteLoanTagMapping, {input:data}));
        return response && response.data ? response.data.deleteLoanTagMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteBulkTagLoanMappings= async (data:any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(deleteBulkLoanTagMapping, {input:data}));
        return response && response.data ? JSON.parse(response.data.deleteBulkLoanTagMapping)  : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertBulkTagLoanMapping= async (data: any,isSelectAll:string,scopeId:string,filterData:String) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createBulkLoanTagMapping, {input: data,isSelectAll:isSelectAll,scopeId:scopeId,filterData:filterData }));
        return response && response.data ? JSON.parse(response.data.createBulkLoanTagMapping).postgresResponse : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}