/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import AWS from 'aws-sdk';
import awsmobile from "../../../aws-exports";
import { createUserAsync, createUserGroupAsync, createUserRoleAsync, deleteUserGroupByUserIdAsync, deleteUserRoleByUserIdAsync, getRolesListAsync, readUserState, updateUserGroupAsync, updateUserRoleAsync } from "../../../redux/reducers/userReducer";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { Loader } from "../../../helpers/loader";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import FormError from "../../common/FormError";
import Select from "react-select";
import Breadcrumb from "../../common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getGroupList } from "../../../services/user.service";
import PasswordValidityViewer from "../../common/PasswordValidityViewer";
import { selectCustomStyles } from "../../common/CustomStyles";
AWS.config.update({
    region: awsmobile.aws_cognito_region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
    })
});
const UserForm = () => {
    const navigation = useNavigate();
    const [userDetail, setUserDetail] = useState({ user_id: "", firstName: "", lastName: "", userId: "", email: "", password: "", phoneNumber: "" });
    const [userRole, setUserRole] = useState<any>(null);
    const [roleOptions, setRoleOptions] = useState<any>([]);
    const [userGroup, setUserGroup] = useState<any>(null);
    const userState = useAppSelector(readUserState);
    const appState = useAppSelector(readAppState);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [error, setError] = useState({ errors: { cognito: null } });
    const dispatch = useAppDispatch();
    const [submit, setSubmit] = useState(false);
    const [userGroupOptions, setUserGroupOptions] = useState<any[]>([]);
    const [showPopover, setPopover] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    
    let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\]{8,}$/
    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/Users")
        );
        userState.user?.is_new ?
            breadcrumbLocation.push({
                screen_name: "Add User",
                redirect_url: "/UserManagement",
                icon_url: "fad fa-toolbox"
            }) :
            breadcrumbLocation.push({
                screen_name: "Edit User",
                redirect_url: "/UserManagement",
                icon_url: "fad fa-toolbox"
            })
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        dispatch(getRolesListAsync()).then((response: any) => {
            setLoader(false);
            if (response.payload.length > 0) {
                const options = response.payload.map(x => {
                    return {
                        label: x.role_name,
                        value: x.role_id
                    }
                })
                setRoleOptions(options);
                !userState.user?.is_new && setUserRole(options.find(x => x.value === userState.user?.role_id));
            }
        }).catch(ex => { console.log(ex) })
        if (!userState.user?.is_new) {
            setUserDetail({ user_id: userState.user!.user_id, firstName: userState.user!.first_name, lastName: userState.user!.last_name, userId: userState.user!.maas_user_id, email: userState.user!.email, phoneNumber: userState.user!.phone_number, password: "" });
        }
        getGroupList().then((response: any) => {
            setLoader(false);
            if (response.length > 0) {
                let groupOptions = response.map(x => {
                    return {
                        label: x.group_name,
                        value: x.group_id
                    }
                })
                groupOptions = groupOptions.filter(x => x.label !== appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value);
                setUserGroupOptions(groupOptions);
                !userState.user?.is_new && setUserGroup(groupOptions.find(x => x.value === userState.user!.group_id));
            }
        }).catch(ex => { console.log(ex) });
    }, []);

    const handleSubmitForm = async () => {
        if (!userRole || userRole.length === 0) {
            setSubmit(true);
            return;
        }
        clearErrorState();
        setLoader(true);
        if (userState.user?.is_new) {
            try {
                var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
                cognitoidentityserviceprovider.adminCreateUser({
                    UserPoolId: awsmobile.aws_user_pools_id,
                    Username: userDetail.userId.toLowerCase(),
                    DesiredDeliveryMediums: [
                        "EMAIL"
                    ],
                    TemporaryPassword: userDetail.password,
                    UserAttributes: [{
                        Name: "email",
                        Value: userDetail.email,
                    },
                    {
                        Name: "email_verified",
                        Value: "true",
                    }]
                }, function (err, data) {
                    if (err) {
                        setLoader(false);
                        window.alert(err.message);
                    }
                    else {
                        const userData = {
                            maas_user_id: userDetail.userId,
                            first_name: userDetail.firstName,
                            last_name: userDetail.lastName,
                            email: userDetail.email,
                            phone_number: userDetail.phoneNumber,
                            created_by: appState.currentUser!.maas_user_id,
                            created_date: new Date().toUTCString(),
                            modified_by: appState.currentUser!.maas_user_id,
                            modified_date: new Date().toUTCString()
                        }
                        dispatch(createUserAsync(userData)).then((data: any) => {
                            if (data.payload && data.payload.user_id) {
                                if (userGroup && userGroup.value) {
                                    dispatch(createUserGroupAsync({
                                        user_id: data.payload.user_id,
                                        group_id: userGroup.value,
                                        created_by: appState.currentUser!.maas_user_id,
                                        created_date: new Date().toUTCString(),
                                        modified_by: appState.currentUser!.maas_user_id,
                                        modified_date: new Date().toUTCString(),
                                    }));
                                }
                                if (userRole && userRole.value) {
                                    dispatch(createUserRoleAsync({
                                        user_id: data.payload.user_id,
                                        role_id: userRole.value,
                                        created_by: appState.currentUser!.maas_user_id,
                                        created_date: new Date().toUTCString(),
                                        modified_by: appState.currentUser!.maas_user_id,
                                        modified_date: new Date().toUTCString(),
                                    }))
                                }
                                dispatch(setBreadcrumbLocation([{
                                    screen_name: "User Management",
                                    redirect_url: "/Users",
                                    icon_url: "fad fa-user"
                                }]));
                                setPopup({ ...popup, visible: true, message: "This user account has been added.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigation("/Users"); setPopup({ ...defaultPopup }) } });
                            }
                        })
                    }         // successful response
                });

            } catch (error: any) {
                let err = !error.message ? { "message": error } : error;
                console.log(error)
                setError({
                    errors: {
                        ...error,
                        cognito: err
                    }
                })
            }
        }
        else {
            if (!userState.user?.group_id) {
                dispatch(createUserGroupAsync({
                    user_id: userState.user?.user_id,
                    group_id: userGroup.value,
                    created_by: appState.currentUser!.maas_user_id,
                    created_date: new Date().toUTCString(),
                    modified_by: appState.currentUser!.maas_user_id,
                    modified_date: new Date().toUTCString(),
                }));
            }
            else {
                if (userGroup && userGroup.value) {
                    dispatch(updateUserGroupAsync({
                        user_id: userState.user?.user_id,
                        group_id: userGroup.value,
                        modified_by: appState.currentUser?.maas_user_id,
                        modified_date: new Date().toUTCString()
                    }));
                } else {
                    dispatch(deleteUserGroupByUserIdAsync(userState.user?.user_id));
                }
            }
            if (!userState.user?.role_id) {
                dispatch(createUserRoleAsync({
                    user_id: userState.user?.user_id,
                    role_id: userRole.value,
                    created_by: appState.currentUser!.maas_user_id,
                    created_date: new Date().toUTCString(),
                    modified_by: appState.currentUser!.maas_user_id,
                    modified_date: new Date().toUTCString(),
                }));
            }
            else {
                if (userRole && userRole.value) {
                    dispatch(updateUserRoleAsync({
                        user_id: userState.user?.user_id,
                        role_id: userRole.value,
                        modified_by: appState.currentUser?.maas_user_id,
                        modified_date: new Date().toUTCString()
                    }))
                }
                else {
                    dispatch(deleteUserRoleByUserIdAsync(userState.user?.user_id))
                }
            }
            dispatch(setBreadcrumbLocation([{
                screen_name: "User Management",
                redirect_url: "/Users",
                icon_url: "fad fa-user"
            }]));
            setPopup({ ...popup, visible: true, message: "Your edits/updates are complete.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigation("/Users"); } });

        }
    }

    const clearErrorState = () => {
        setError(
            {
                errors: {
                    cognito: null,
                }
            });
    };
    const submitButton = () => {
        if (!userState.user?.is_new && ((userRole && userState.user?.role_id !== userRole.value) || (userGroup && userState.user?.group_id !== userGroup.value)) && userDetail.email && userDetail.phoneNumber !== "") {
            return (<button className="btn  btn-primary text-center btn-block" type="submit" onClick={() => handleSubmitForm()}>Save </button>)
        }
        else if (userState.user?.is_new && (userRole && userGroup && userDetail.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) && userDetail.firstName && userDetail.lastName && userDetail.userId && !userState.userList!.find(x => x.maas_user_id?.toLowerCase().trim() === userDetail.userId?.toLowerCase().trim()) && userDetail.phoneNumber && userDetail.password && userDetail.phoneNumber.length === 10 && userDetail.password.length >= 8 && userDetail.password.toLowerCase().match(/[a-z]/) && userDetail.password.match(/(?=.*[a-z])(?=.*[A-Z])/) && userDetail.password.match(/[0-9]/) && userDetail.password.match(/[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,.@#]/) && !userState.userList!.find(x => x.email.toLowerCase().trim() === userDetail.email.toLowerCase().trim()))) {
            return (<button className="btn btn-primary text-center btn-block" type="submit">Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled >Save</button>)
        }
    }

    const handleSelectChange = (e) => {
        setUserRole(e);
    }
    const handleCancel = () => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "User Management",
            redirect_url: "/Users",
            icon_url: "fad fa-user"
        }]));
        navigation('/Users');
    }
    return (
        <>
            <div className="pagetitle"><Breadcrumb /></div>
            <section className="section dashboard">
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
                <div className="block_sect">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className="row">
                            <FormError formerrors={error} />
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="firstname"
                                        placeholder="First Name"
                                        autoComplete="off"
                                        value={userDetail.firstName}
                                        disabled={userState.user?.is_new ? false : true}
                                        {...register("firstname", {
                                            required: true, onChange: (e) => setUserDetail({ ...userDetail, firstName: e.target.value.replace(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/_~`0-9]/, "") })
                                        })}
                                    />
                                    {errors?.firstname?.type === "required" && userState.user?.is_new && <p style={{ color: 'red' }}>Please enter First Name</p>}
                                    <label htmlFor="firstname">First Name</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="lastname" placeholder="Last Name"
                                        autoComplete="off"
                                        value={userDetail.lastName}
                                        disabled={userState.user?.is_new ? false : true}
                                        {...register("lastname", {
                                            required: true, onChange: (e) => setUserDetail({ ...userDetail, lastName: e.target.value.replace(/[$&+,:;=?[\]@#|{}'<>.^*()%!-/_~`0-9]/, "") })
                                        })}
                                    />
                                    {errors.lastname?.type === 'required' && userState.user?.is_new && (<p style={{ color: 'red' }}>Please enter Last Name</p>)}
                                    <label htmlFor="lastname">Last Name</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="regiser_username" placeholder="User Name"
                                        autoComplete="off"
                                        value={userDetail.userId}
                                        disabled={userState.user?.is_new ? false : true}
                                        {...register("regiser_username", {
                                            required: true, onChange: (e) => setUserDetail({ ...userDetail, userId: e.target.value.replace(/[^a-z0-9]/gi, '') })
                                        })}
                                    />
                                    {errors.lastname?.type === 'required' && userState.user?.is_new && (<p style={{ color: 'red' }}>Please enter User Name</p>)}
                                    {userState.user?.is_new && userState.userList!.find(x => x.maas_user_id.toLowerCase().trim() === userDetail.userId.toLowerCase().trim()) && <p style={{ color: 'red' }}>User name already exists!</p>}
                                    <label htmlFor="regiser_username">User ID</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="phonenumber"
                                        placeholder="Phone Number"
                                        autoComplete="off"
                                        value={userDetail.phoneNumber}
                                        disabled={userState.user?.is_new ? false : true}
                                        {...register("phonenumber", {
                                            required: true, onChange: (e) => { setUserDetail({ ...userDetail, phoneNumber: !e.target.value || (e.target.value.length <= 10 && e.target.value.match(/^[0-9]+$/)) ? e.target.value : userDetail.phoneNumber }) }
                                        })}
                                    />
                                    {errors?.phonenumber?.type === "required" && userState.user?.is_new && <p style={{ color: 'red' }}>Please enter Phone Number</p>}
                                    {(userDetail.phoneNumber && userDetail.phoneNumber.length < 10 && userState.user?.is_new) && <p style={{ color: 'red' }}>Please enter valid Phone Number</p>}
                                    <label htmlFor="phonenumber">Phone Number</label>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="email" className="form-control" id="register_email" placeholder="Email ID"
                                        value={userDetail.email}
                                        disabled={userState.user?.is_new ? false : true}
                                        autoComplete="off"
                                        {...register("register_email", {
                                            required: true, onChange: (e) => { setUserDetail({ ...userDetail, email: e.target.value }) }
                                        })} />
                                    {userDetail.email && !userDetail.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) && <p style={{ color: 'red' }}>Please enter valid Email ID</p>}
                                    {errors?.email?.type === "required" && userState.user?.is_new && <p style={{ color: 'red' }}>Please enter Email ID</p>}
                                    {userState.user?.is_new && userState.userList!.find(x => x.email.toLowerCase().trim() === userDetail.email.toLowerCase().trim()) && <p style={{ color: 'red' }}>Email ID is already mapped to another user!</p>}
                                    <label htmlFor="register_email">Email ID</label>
                                    <div id="validationServer03Feedback" className="invalid-feedback">
                                        Please provide a valid ID.
                                    </div>
                                </div>

                            </div>

                            {userState.user?.is_new && <div className="col-lg-4">
                                <div className="form-floating ct_form_field ct_form_icon_group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                        id="register_password"
                                        placeholder="Password"
                                        autoComplete="new-password"
                                        onFocus={() => setPopover(true)}
                                        value={userDetail.password}
                                        {...register("register_password", {
                                            required: true, onChange: (e) => setUserDetail({ ...userDetail, password: e.target.value }), onBlur: () => setPopover(false)
                                        })} />
                                    <span onClick={() => setShowPassword(!showPassword)} className={`far ${showPassword ? "fa-key" : "fa-lock"}`}></span>
                                    <label htmlFor="register_password">Password</label>
                                </div>
                            </div>}
                            <div className="col-lg-4">
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        className="form-control multi-select-dd "
                                        id="role"
                                        options={roleOptions}
                                        value={userRole}
                                        onChange={(e) => handleSelectChange(e)}
                                        placeholder="Select Role..."
                                    />
                                    {submit && (!userRole) && (<p style={{ color: 'red' }}> Please Select Role</p>)}
                                    <label htmlFor="role">User Role</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        className="form-control multi-select-dd"
                                        id="group"
                                        options={userGroupOptions}
                                        value={userGroup}
                                        onChange={(e) => setUserGroup(e)}
                                        placeholder="Select Group..."
                                    />
                                    {submit && (!userGroup) && (<p style={{ color: 'red' }}> Please Select Group</p>)}
                                    <label htmlFor="group">User Group</label>
                                </div>
                            </div>
                            <div className="error-text">
                                {userDetail.password.length > 0 && !passwordPattern.test(userDetail.password) &&
                                    <span>Your password must contain at least 1 Upper Case Letter, 1 Lower Case Letter, 1 Number and 1 Special Character (such as #&!*@)
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt30">
                            <div className="col-lg-2 col">
                                {submitButton()}</div>
                            <div className="col-lg-2 col"><button className="btn btn-outline-primary text-center btn-block" type="submit" onClick={() => handleCancel()}>
                                Cancel</button></div>
                        </div>
                        {userState.user?.is_new && <PasswordValidityViewer password={userDetail.password} showPopover={showPopover} id="register_password" />}
                    </form>
                </div>
            </section>
        </>
    );
}
export default memo(UserForm);
