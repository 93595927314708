import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import appReducer from '../redux/reducers/appReducer';
import ruleReducer from '../redux/reducers/ruleReducer';
import userReducer from '../redux/reducers/userReducer';
import transformerLogsReducer from '../redux/reducers/transformerLogsReducer';
import documentReducer from '../redux/reducers/documentReducer';
import loanReducer from '../redux/reducers/loanReducer';
import reportReducer from '../redux/reducers/reportReducer';
import dashboardReducer from '../redux/reducers/dashboardReducer';
import assignmentClaimReducer from '../redux/reducers/assignmentClaimReducer';
import localStorage from 'redux-persist/es/storage';


const persistConfig = {
  key: 'counter',
  storage: localStorage,
};

const reducers = combineReducers({
  app: appReducer,
  rule:ruleReducer,
  user:userReducer,
  transformerLogs:transformerLogsReducer,
  loan: loanReducer,
  report: reportReducer,
  dashboard: dashboardReducer,
  assignmentClaim: assignmentClaimReducer,
  documents:documentReducer
});


const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      devTools: process.env.NODE_ENV !== 'production'
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
