/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearState, handleSearchWordChange, logout, readAppState, setLogiToken, setNavMenuStatus } from "../../redux/reducers/appReducer";
import { readDashboardState, resetDashboardState, setShowSearchBar } from "../../redux/reducers/dashboardReducer";
import { readLoanState } from "../../redux/reducers/loanReducer";
import QuickLoanSearch from "../LoanDetailComponents/QuickLoanSearch";
import { loadProfile, userActivityLogs, getBrowserName } from "../../services/user.service"
import { Auth } from "aws-amplify";

const Header = (props: any) => {
    const appState = useAppSelector(readAppState);
    const dashBoardState = useAppSelector(readDashboardState);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    const mobileQuery = window.matchMedia('(max-width:1024px)');
    const [profileImage, setProfileImage] = useState("" as any);

    useEffect(() => {
        loadProfileData()
    }, [appState?.currentUser?.profile_path]);

    const focusElement = () => {
        let bodyElement: any = document.querySelectorAll("INPUT,A,BUTTON,H3>SPAN");
        for (let i = 0; i < bodyElement.length; i++) {
            if (bodyElement[i].nodeName === "INPUT" || bodyElement[i].nodeName === "A" || bodyElement[i].nodeName === "BUTTON" || bodyElement[i].parentNode.nodeName === "H3") {
                if (bodyElement[i]?.classList?.length) {
                    for (let j = 0; j < bodyElement[i].classList.length; j++) {
                        if (bodyElement[i].classList[j] === "disabled") {
                            bodyElement[i].tabIndex = -1;
                            break;
                        }
                        else {
                            bodyElement[i].tabIndex = 0;
                        }
                    }
                }
                else {
                    bodyElement[i].tabIndex = 0;
                }
            }
            else {
                bodyElement[i].tabIndex = -1;
            }
        }
    }

    useEffect(() => {
        focusElement()
    }, [focusElement]);

    const loadProfileData = async () => {
        if (appState?.currentUser?.profile_path) {
            const res = await loadProfile(appState?.currentUser?.profile_path);
            setProfileImage(res)
        }
    }

    const logoutHandler = () => {
        userActivityLogs({
            user_id: appState?.currentUser?.user_id,
            maas_user_id: appState?.currentUser?.maas_user_id,
            activity: "Logged Out",
            inserted_by: appState?.currentUser?.maas_user_id,
            inserted_date: new Date().toUTCString(),
            browser_name: getBrowserName(window.navigator.userAgent.toLowerCase())
        }).then((res) => {
            if (res) {
                Auth.signOut();
                dispatch(logout())
                localStorage.clear();
                sessionStorage.clear();
                dispatch(clearState());
                dispatch(resetDashboardState());
                dispatch(setLogiToken(null));
                localStorage.setItem('isLogin', 'false');
                localStorage.setItem('sessionTimeout', 'false');
                localStorage.setItem('maas_user_id', `${appState?.currentUser?.maas_user_id}`);
                localStorage.setItem('logoutdueto', "manually");
                navigate("/login");
            }
        })
    }


    const handleNavMenuStatus = () => {
        if (appState.isNavMenuOpen) {
            dispatch(setNavMenuStatus())
            if (!dashBoardState.showSearchBar) {
                dispatch(setShowSearchBar())
            }
        }
    }
    return (
        <header id="header" className="header fixed-top d-flex align-items-center" >
            <div className="d-flex align-items-center justify-content-between">
                <a className="toggle-sidebar-menu" onClick={() => dispatch(handleNavMenuStatus)}><img src={massCdnUrl + "assets/img/m_menubar.svg"} /></a>
                <a style={{ cursor: "pointer" }} className="logo d-flex align-items-center" onClick={() => navigate("/DashboardLibrary")}>
                    <img src={massCdnUrl + "assets/img/site-logo.png"} alt="" />
                </a>
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {!mobileQuery.matches &&
                        <li className="hsearch_bar w350">
                            <QuickLoanSearch />
                        </li>
                    }
                    <li className="nav-item d-block d-lg-none" onClick={() => { dispatch(setShowSearchBar()) }}>
                        <a className="nav-link nav-icon search-bar-toggle" >
                            <i className="bi bi-search"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown pe-3" >
                        <a className="nav-link nav-profile d-flex align-items-center pe-0 dropdown-toggle" data-bs-toggle="dropdown" >
                            <div><span className="d-none d-md-block  pe-2">Welcome, {appState.currentUser ? appState.currentUser.first_name : ""}</span>
                                <span className="d-none d-md-block  pe-2" style={{ fontWeight: "100", fontSize: "12px" }}>({appState.currentUser?.role_name})</span>
                            </div>
                            {appState?.currentUser?.profile_path ?
                                <img src={profileImage} alt="Profile" width={40} height={40} className="rounded-circle" style={{ backgroundOrigin: "center", backgroundPosition: "center" }} /> :
                                <img src={massCdnUrl + "assets/img/userpic.png"} alt="Profile" className="rounded-circle" />}
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header d-block d-lg-none d-md-none"><h6>Welcome, {appState.currentUser ? appState.currentUser.first_name : ""}</h6><span>({appState.currentUser?.role_name})</span></li>
                            <li className="d-block d-lg-none d-md-none"><hr className="dropdown-divider d-block" /></li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" onClick={() => navigate('/MyProfileAndSettings')} onKeyPress={(e) => {

                                    if (e.key === "Enter") {
                                        navigate('/MyProfileAndSettings')
                                    }
                                }}>
                                    <i className="bi bi-person"></i><span>My Profile and Settings</span>
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item d-flex align-items-center" onClick={() => logoutHandler()} onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        logoutHandler()
                                    }
                                }} >
                                    <i className="bi bi-power"></i><span>Sign Out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </header>
    );
}
export default memo(Header);