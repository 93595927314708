import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Status } from "../../../constants/commonConstants";
import formatDate from "../../../constants/dateFormat";
import { Loader } from "../../../helpers/loader";
import { getInspectionDetailsAsync, readLoanState } from "../../../redux/reducers/loanReducer";

const InspectionDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const loanState = useAppSelector(readLoanState);

    useEffect(() => {
        dispatch(getInspectionDetailsAsync(props.loan_id));
    }, [])

    return (
        <>
            {
                loanState.inspectionDetailsStatus === Status.Loading ? <Loader /> :
                    <div className="row">
                        <div className="col">
                            <div className="row payment-boxes">
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Last Inspection Type</label>
                                        <span>{loanState.inspectionDetails?.last_inspection_type || "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Last Inspection Date</label>
                                        <span>{loanState.inspectionDetails?.last_inspection_date ? formatDate(String(loanState.inspectionDetails?.last_inspection_date)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Property Secured</label>
                                        <span>{loanState.inspectionDetails?.is_property_secured || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Occupancy Status</label>
                                        <span>{loanState.inspectionDetails?.occupancy_status || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Damage Reported</label>
                                        <span>{loanState.inspectionDetails?.is_damage_reported || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Damage Type</label>
                                        <span>{loanState.inspectionDetails?.damage_type || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Damage Reported to Insurance Vendor Date</label>
                                        <span>{loanState.inspectionDetails?.damage_reported_date ? formatDate(String(loanState.inspectionDetails?.damage_reported_date)) : "---"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}

export default InspectionDetails;