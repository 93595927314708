/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState,useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { readAssignmentClaimState, setStep } from "../../redux/reducers/assignmentClaimReducer";
import { setAppliedFilters, setCurrentSource } from "../../redux/reducers/dashboardReducer";
import Breadcrumb from "../common/Breadcrumb";
import AssignmentClaims from "../DashboardComponents/AssignmentClaims";
import LoanDetailMaster from "../LoanDetailComponents/LoanDetailMaster";
import AssignmentClaimsLoanSearch from "./AssignmentClaimsLoanSearch";
import DocumentLevelSearch from "./DocumentLevelSearch";



const AssignmentClaimsMaster = () => {
    const appState = useAppSelector(readAppState);
    const assignmentClaimState = useAppSelector(readAssignmentClaimState);
    const dispatch = useAppDispatch();
    const [currentStep, setCurrentStep] = useState(assignmentClaimState.step);
    const [heading, setHeading] = useState<string>(assignmentClaimState.step && assignmentClaimState.step > 10 ? appState?.breadcrumbLocation[0]?.screen_name : "Assignment Claims");
    const [firstStep, setFirstStep] = useState<number>(assignmentClaimState.step && assignmentClaimState.step > 10 ? assignmentClaimState.step : 11)
    const [currentDashboard, setCurrentDashboard] = useState(currentStep === 11 ? {
        dashboardKey: "assignment_dashboard_id",
        dashboardName: "assignment_dashboard"
    } : { dashboardKey: "assignment_impediment_dashboard_id", dashboardName: "assignment_impediment_dashboard" })

    useEffect(() => {
        setCurrentStep(assignmentClaimState.step);
        if (assignmentClaimState.step > 10) {
            setHeading(appState?.breadcrumbLocation[0]?.screen_name);
            setFirstStep(assignmentClaimState.step);
            if (assignmentClaimState.step === 11) {
                setCurrentDashboard({ dashboardKey: "assignment_dashboard_id", dashboardName: "assignment_dashboard" })
            } else {
                setCurrentDashboard({ dashboardKey: "assignment_impediment_dashboard_id", dashboardName: "assignment_impediment_dashboard" })
            }
        }
    }, [assignmentClaimState.step]);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: assignmentClaimState.step === 11 ? "Assignment Claims" : (assignmentClaimState.step === 12 ? "Assignment Claims Impediments"
                : (assignmentClaimState.step === 2 ? (assignmentClaimState.documentLevelSearch ? "Document Level Search" : "Loan Level Search")
                    : (assignmentClaimState.step === 3 ? "Loan Details" : ""))),
            redirect_url: "/AssignmentClaims",
            icon_url: "fad fa-user"
        }]));
        dispatch(setStep(assignmentClaimState.step));
        return (() => {
            dispatch(setCurrentSource(undefined));
            dispatch(setAppliedFilters(undefined));
        })
    }, []);

    return (
        
        <>
        
            <div className={`pagetitle ${currentStep === 11 || currentStep === 12 ? "fixed-position" : ""}`}><Breadcrumb /></div>
            <div className={`pagetitle ${currentStep === 11 || currentStep === 12 ? "pos-fixed" : ""}`}>
            <aside className= {`steps_tab_sect ${currentStep === 11 || currentStep === 12 ? "fixed-position" : ""}`} style={{marginTop:` ${currentStep === 11 || currentStep === 12 ? "40px" : "15px"}`}}>
                    <ul>
                        <li className={currentStep === 11 || currentStep === 12 ? "active" : ""}>
                            <a onClick={() => {
                            if (firstStep === 11) {
                                dispatch(setBreadcrumbLocation([{
                                    screen_name: "Assignment Claims",
                                    redirect_url: "/AssignmentClaims",
                                    icon_url: "fad fa-user"
                                }])); dispatch(setStep(11))
                                setCurrentDashboard({ dashboardKey: "assignment_dashboard_id", dashboardName: "assignment_dashboard" });
                            }
                            if (firstStep === 12) {
                                dispatch(setBreadcrumbLocation([{
                                    screen_name: "Assignment Claims Impediments",
                                    redirect_url: "/AssignmentClaims",
                                    icon_url: "fad fa-user"
                                }])); dispatch(setStep(12))
                                setCurrentDashboard({ dashboardKey: "assignment_impediment_dashboard_id", dashboardName: "assignment_impediment_dashboard" });
                            }
                        }}><span className="clickable">01</span></a>
                            <br />
                            <label>{heading}</label>
                        </li>
                         <li onClick={() => {
                            if(currentStep===2 || currentStep===3){
                            dispatch(setBreadcrumbLocation([{
                                screen_name: firstStep === 11 ? "Loan List" : "Document List",
                                redirect_url: "/AssignmentClaims",
                                icon_url: "fad fa-user"
                             }])); dispatch(setStep(2))
                            } 
                        }} className={currentStep === 2 ? "active" : ""} >
                              <a ><span style={{ cursor: `${currentStep===11 || currentStep===12 ?'not-allowed':'pointer'}`, borderColor: 'grey' }}>02</span></a>
                            <br />
                            <label>{firstStep === 11 ? "Loan List" : "Document List"}</label>
                        </li> 
                        <li className={currentStep === 3 ? "show active" : ""}>
                            <a ><span style={{ cursor: 'not-allowed', borderColor: 'grey' }}>03</span></a>
                            <br />
                            <label>Loan Details</label>
                        </li>
                    </ul>
                </aside>
            </div>
            <div className={`tab-content ${currentStep === 11 || currentStep === 12 ? "pos-fixed" : ""}`}>
                <div className={`tab-pane p-3 fade ${currentStep === 11 || currentStep === 12 ? "show active" : ""}`} id="tabs1">
                    <div id="Level1Component">
                        <AssignmentClaims key="/AssignmentClaims" rootPath="/AssignmentClaims" dashboardkey={currentDashboard.dashboardKey} dashboardName={currentDashboard.dashboardName} gridAbbreviation="ac_" />
                    </div>
                </div>
                <div className={`tab-pane p-3 fade  ${currentStep === 2 ? "show active" : ""}`} id="tabs2">
                    <div id="Level3Component">
                        {assignmentClaimState.documentLevelSearch ? <DocumentLevelSearch /> : <AssignmentClaimsLoanSearch />}
                    </div>
                </div>
                <div className={`tab-pane p-3 fade  ${currentStep === 3 ? "show active" : ""}`} id="tabs3">
                    <div id="Level4Component">
                        <LoanDetailMaster parentScreen="assignmentClaims" nestedComponent={true} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(AssignmentClaimsMaster);