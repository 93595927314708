import { listScreens, getScreensWithRoles } from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';


export const getAllScreens = async () => {
    try {
        let screenList: any;
        const listScreenResponse: any = await API.graphql(graphqlOperation(listScreens));
        if (listScreenResponse && listScreenResponse.data && listScreenResponse.data.listScreens) {
            screenList = listScreenResponse.data.listScreens;
        }
        return screenList;
    }
    catch (ex) {
        console.log(ex);
    }
}


export const getAllScreensByRole = async () => {
    try {
        let screens: any = await API.graphql(graphqlOperation(getScreensWithRoles));
        return screens.data.getScreensWithRoles;
    }
    catch (ex) {
        console.log(ex);
        return [];
    }
}