import {  getLoanNotesAssetMgmt } from './../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";


export const getNotesByLoanId = async (params: any) => {

    let notesData: any;
    try {
        notesData = await API.graphql(graphqlOperation(getLoanNotesAssetMgmt, params));
        let notes = JSON.parse(notesData.data.getLoanNotesAssetMgmt);
        notes= notes[0].fn_get_loan_notes && JSON.parse(notes[0].fn_get_loan_notes)
        return notes

    } catch (ex) {
        console.log(ex);
    }

}
