export const Status = {
    Idle: "idle",
    Loading: "loading",
    Failed: "failed",
  };

  export const  tagList=[
    { label:"Servicer error", value:"servicer_error"},
    {label:"Evaluate for HFD",value:"evaluate_for_hfd"},
    {label:"HFD", value:"hfd"},
   { label:	"Active Securitization" , value:"active_securitization"}
  ]