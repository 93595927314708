export const searchConstants = {
    loan_id: "loan_id",
    borrower_name: "borrower_name",
    property_state: "property_state",
    property_city: "property_city",
    property_postal_code: "property_postal_code",
    status: "status",
    screen: "screen"
}

export const AssignmentClaims = {
    property_status:"property_status",
    document_status:"document_status",
    document_type:"document_type"
}