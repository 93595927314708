/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { memo, useRef, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import formatDateTable from '../../../constants/dateFormatTable';
import { readAppState } from '../../../redux/reducers/appReducer';
import { getPresignedUrlForService } from '../../../services/upload.service';
import { urlStyle } from '../../common/CustomStyles';
import DocumentPreviewComponent from '../../common/DocumentPreviewComponent';
import GenericAgGrid from '../../common/GenericAgGrid';
// import { useAppSelector } from '../../app/hooks';
// import formatDateTable from '../../constants/dateFormatTable';
// import { readAppState } from '../../redux/reducers/appReducer';
// import { getPresignedUrlForService } from '../../services/upload.service';
// import { urlStyle } from '../common/CustomStyles';
// import DocumentPreviewComponent from '../common/DocumentPreviewComponent';
// import GenericAgGrid from '../common/GenericAgGrid';
import JsZip from 'jszip';
import { getFileData } from '../../../constants/FilePathConstant';

const linkTest = ({ value }) => {
    return <a style={urlStyle}>{value}</a>;
};

const Documents = (props: any) => {
    const [file, setFile] = useState(null as any);
    const appState = useAppSelector(readAppState);
    const gridDocumentsByLoanNumberRef: any = useRef();
    const [isDownloadClicked, setIsDownloadClicked] = useState(false as boolean);
    const [selectedDocuments, setSelectedDocuments] = useState<any>([])
    const [blobes, setBlobs] = useState<any>([])

    const getFilePath = async (file: any, actionPerformed: string) => {
        const { bucket_name, object_key } = getFileData(file);
        let fileType = file?.DOC_NAME ? file.DOC_NAME.substring(file.DOC_NAME.lastIndexOf(".") + 1) : "";
        try {
            let result = await getPresignedUrlForService(bucket_name, object_key, "download");
            if (result) {
                const url = JSON.parse(JSON.parse(result).body).url;
                if (actionPerformed === "DownloadClicked") {
                    downloadFile(url, file.DOC_NAME)
                } else {
                    setIsDownloadClicked(false)
                    setFile({ ...file, FILE_PATH: url, DOC_TYPE: fileType });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    const downloadFile = (url, name) => {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", name);
                a.click();
            }
            );
        });
        setIsDownloadClicked(false)
    }

    const handleRowClick = (row) => {
        if (isDownloadClicked === false) {
            getFilePath(row.data, "PrieviewClicked");
        }
    }

    const getCustomStaticFilter = () => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId: string = userGroups.find(g => g.group_id === appState.currentUser?.group_id)?.logi_user_id;
        return `LOAN_NUMBER = '${props.loan_id}' and SCOPE_ID = '${userGroupId}'`;
    }

    const actionButtons = (params) => {
        return <div className="grid-action" >
            <button onClick={() => { getFilePath(params.data, "DownloadClicked"); setIsDownloadClicked(true) }} title="Download" className="edit-icon_link" ><span className="fas fa-download"></span></button>
        </div>
    }

    const onSelectionChanged = (e) => {
        setSelectedDocuments(e.api.getSelectedRows())
    };

    const columns = [
        { field: "", headerName: "Action", checkboxSelection: true, width: 95 ,sortable: false},
        { field: "DOC_NAME", headerName: "Document Name", cellRendererFramework: linkTest, width: 350 },
        { field: "CATEGORY", headerName: "Category", width: 240 },
        { field: "TYPE", headerName: "Type", width: 350 },
        { field: "SOURCE", headerName: "Source", width: 160 },
        { field: "DOC_LAST_UPDATED_DATE", headerName: "Created Date",width:270,filter: 'agDateColumnFilter', valueFormatter: (param) => formatDateTable(param, false) },
        { field: "S3_BUCKET_URL", headerName: "s3_bucket URL", hide: true },
        { field: "", headerName: "Download", cellRendererFramework: actionButtons, pinned: 'right', filter: false , width: 100}
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true  
    }

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'DOCUMENTS');

    const downloadMultipleDoc = async () => {
        let urls = [] as any;
        let fileData = [] as any;
        let fileCount = selectedDocuments.length
        for (let data of selectedDocuments) {
            const { bucket_name, object_key } = getFileData(data);
            await Promise.all([getPresignedUrlForService(bucket_name, object_key, "download")]).then((result: any) => {
                const url = JSON.parse(JSON.parse(result).body).url;
                urls.push(url)
            });
        };
        downloadMany(urls)
    }

    const downloadMany = async urls => {
        if (urls.length > 5) {
            let urlData = urls.map(url => fetch(url)
                .then(r => r.blob())
                .then(blob => ({
                    name: url.split('/').pop().split('?')[0],
                    data: blob,
                })))
            let blobData = await Promise.all(urlData)
            exportZip(blobData)
        } else {
            const download_next = i => {
                if (i >= urls.length) {
                    return;
                }
                fetch(urls[i]).then(function (t) {
                    return t.blob().then((b) => {
                        var a = document.createElement("a");
                        a.href = URL.createObjectURL(b);
                        a.setAttribute("download", urls[i].split('/').pop().split('?')[0]);
                        a.click();
                    }
                    );
                });
                setTimeout(function () {
                    download_next(i + 1);
                }, 2000);
            }
            download_next(0);
        }
        setIsDownloadClicked(false)
        gridDocumentsByLoanNumberRef.current.api.deselectAll();
    }

    const exportZip = blobs => {
        const zip = JsZip();
        blobs.forEach((blob, i) => {
            zip.file(blob.name, blob.data);
        });
        zip.generateAsync({ type: 'blob' }).then(zipFile => {
            let zipblob = new Blob([zipFile])
            var link = document.createElement('a');
            const currentDate = new Date().getTime();
            const fileName = `combined-${currentDate}.zip`;
            link.href = window.URL.createObjectURL(zipblob);;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    return (
        <>
            {(file && isDownloadClicked === false) ?
                <div>
                    <DocumentPreviewComponent
                        file={file}
                        setFile={setFile}
                        hideDownloadButton={true}
                    />
                </div> :
                !!gridDetails &&
                <div>
                    <div className="row mb20">
                        <div className="col-12 col-lg-12 col-md-12 mb-3">
                            <div className="float-end">
                                <button type="button" className="btn btn-primary btn-sm " style={{ marginRight: "5px" }} disabled={(selectedDocuments.length === 0) ? true : false} id="balance_trans" onClick={() => downloadMultipleDoc()}>Download Multiple File
                                </button>
                            </div>
                        </div>
                    </div>

                    <GenericAgGrid
                        gridColumns={columns}
                        gridDefaultColDef={defaultColDef}
                        gridDetails={gridDetails}
                        rowHeight={40}
                        rowSelection={'multiple'}
                        rowModelType={'serverSide'}
                        modules={[ServerSideRowModelModule]}
                        customStaticFilter={getCustomStaticFilter}
                        parentGridRef={gridDocumentsByLoanNumberRef}
                        onRowClicked={handleRowClick}
                        suppressRowClickSelection={true}
                        onSelectionChanged={(e) => onSelectionChanged(e)}
                    ></GenericAgGrid>
                </div>
            }
        </>
    )
}

export default memo(Documents);