/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useState } from "react";
import ReactSelect from "react-select";
import { useAppSelector } from '../../app/hooks';
import formatDateTable from "../../constants/dateFormatTable";
import useStateList from "../../helpers/stateList";
import { readAppState } from '../../redux/reducers/appReducer';
import { GetLoanSearchByFilter } from "../../services/loanSearch.services";
import { selectCustomStyles } from '../common/CustomStyles';
import Toast from "../StaticComponents/Toast";

const linkTest = ({ value }) => {
    return (
        <a>{value}</a>
    )
}

const LoanSearch = ({ addLoanTab }) => {
    const appState = useAppSelector(readAppState)
    const [search, setSearch] = useState({
        loan_id: undefined,
        borrower_name: undefined,
        property_state: undefined,
        property_city: undefined,
        property_postal_code: undefined,
        offset: 0
    })
    const searchRef = useRef(search as any)
    const gridApi = useRef(null as any);
    const [errMsg, setErrMsg] = useState<any>("");
    const [ShowGrid, setShowGrid] = useState<any>(false);

    const getLoans = async (filter: any) => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let currentUserGroup = userGroups.find(g => g.group_id === appState.currentUser?.group_id)
        const loans: any = await GetLoanSearchByFilter(filter, currentUserGroup?.logi_user_id);
        if (!loans || loans.length === 0) {
            gridApi.current.showNoRowsOverlay()
        }
        return { success: true, rows: loans ? loans : [], lastRow: !loans ? 0 : loans.length < 100 ? loans.length : -1 };
    }

    const handleSearchSubmit = async () => {
        if (gridApi && gridApi.current) {
            gridApi.current.refreshServerSideStore({ purge: true });
        }
        else {
            setShowGrid(true);
        }
    }

    const handelInputChange = (e, key) => {
        let newSearch = { ...search }
        if (key === "property_state") {
            newSearch[key] = e;
        }
        else {
            const value = e.target.value === "" ? null : e.target.value;
            newSearch[key] = value;
        }
        setSearch(newSearch)
        searchRef.current = { ...newSearch };
    }

    const dataSource = {
        getRows: (params) => {
            const filterKeys = { ...searchRef.current };
            getLoans({ ...filterKeys, property_state: filterKeys.property_state ? filterKeys.property_state.value : null, offset: params.request.startRow, page_size: 100 }).then((response) => {
                if (response.success) {
                    params.successCallback(response.rows, response.lastRow);
                } else {
                    params.fail();
                }
            })
        }
    }

    const onGridReady = (params: any) => {
        gridApi.current = params.api;
        params.api.setServerSideDatasource({ ...dataSource });
    }

    const defaultColDef = {
        sortable: true,
        resizable: true
    };

    return (
        <>
            <Toast msg={errMsg} setMsg={setErrMsg} />
            <div className="content_section" style={{ marginTop: "-15px" }} >
                <div className="row justify-content-md-center">
                    <div className="col-md-4 search-input">
                        <div className=" text-center">
                            <h3 className="page_title text-center  card_w_header">Search</h3>
                        </div>
                        <div className="form-floating ct_form_field">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Loan No"
                                value={search.loan_id || ""}
                                onChange={(e) => { handelInputChange(e, "loan_id") }}
                            ></input>
                            <label htmlFor="floatingInput">Loan#</label>
                        </div>

                        <div className="form-floating ct_form_field">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Borrower Name"
                                value={search.borrower_name || ""}
                                onChange={(e) => { handelInputChange(e, "borrower_name") }}
                            ></input>
                            <label htmlFor="floatingInput">Borrower Name</label>
                        </div>

                        <div className="form-floating ct_form_field loan_search">
                            <ReactSelect isClearable={true} styles={selectCustomStyles} options={useStateList().map((stateArr) => {
                                return (
                                    {
                                        value: stateArr[0],
                                        label: stateArr[1]
                                    }
                                )
                            })}
                                
                                className=" form-control multi-select-dropdown"
                                classNamePrefix="multi-select-dropdown"
                                placeholder={""} value={search.property_state} onChange={(e) => { handelInputChange(e, "property_state") }} />
                        </div>

                        <div className="form-floating ct_form_field">
                            <input
                                type="text"
                                id="floatingInput"
                                className="form-control"
                                placeholder="Property City"
                                value={search.property_city || ""}
                                onChange={(e) => { handelInputChange(e, "property_city") }}
                            ></input>
                            <label htmlFor="floatingInput">Property City</label>
                        </div>

                        <div className="form-floating ct_form_field">
                            <input
                                type="text"
                                id="floatingInput"
                                className="form-control"
                                placeholder="Property Zip Code"
                                value={search.property_postal_code || ""}
                                onChange={(e) => { handelInputChange(e, "property_postal_code") }}
                            ></input>
                            <label htmlFor="floatingInput">Property Zip Code</label>
                        </div>

                        <div className="row justify-content-md-center">
                            <div className="col-lg-6">
                                <button
                                    className="btn btn-block btn-lg btn-primary text-center btn-block mb15"
                                    type="submit"
                                    onClick={handleSearchSubmit}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        ShowGrid && <div className="col-md-8">
                            <div
                                style={{ height: "60vh" }}
                                className={`ag-theme-alpine`}
                            >
                                <AgGridReact
                                    columnDefs={[
                                        {
                                            field: "maas_loan_number", headerName: "Loan Id", tooltipField: "maas_loan_number", width: 200, headerTooltip: "Loan Id",
                                            onCellClicked: (e) => { addLoanTab(e.value) }, cellRendererFramework: linkTest, sortable: true
                                        },
                                        { field: "borrower_first_name", headerName: "Borrower First Name", tooltipField: "borrower_first_name", width: 200, headerTooltip: "Borrower First Name" },
                                        { field: "borrower_last_name", headerName: "Borrower Last Name", tooltipField: "borrower_last_name", width: 200, headerTooltip: "Borrower Last Name" },
                                        { field: "borrower_dob", headerName: "Borrower DOB", tooltipField: "borrower_dob", width: 200, headerTooltip: "Borrower DOB", valueFormatter: formatDateTable },
                                        { field: "borrower_mail_address1", headerName: "Borrower Mail Address", tooltipField: "borrower_mail_address1", width: 200, headerTooltip: "Borrower Mail Address" },
                                        { field: "borrower_mail_city", headerName: "Borrower Mail City", tooltipField: "borrower_mail_city", width: 200, headerTooltip: "Borrower Mail City" },
                                        { field: "borrower_mail_state_code", headerName: "Borrower State", tooltipField: "borrower_mail_state_code", width: 200, headerTooltip: "Borrower State" },
                                        { field: "borrower_mail_zip_code", headerName: "Borrower Postal Code", tooltipField: "borrower_mail_zip_code", width: 200, headerTooltip: "Borrower Postal Code" },
                                        { field: "borrower_ssn", headerName: "Borrower Ssn", tooltipField: "borrower_ssn", width: 200, headerTooltip: "Borrower Ssn" },
                                        { field: "closing_date", headerName: "Closing Date", tooltipField: "closing_date", width: 200, headerTooltip: "Closing Date",valueFormatter: formatDateTable },
                                        { field: "co_borrower_dob", headerName: "Coborrower DOB", tooltipField: "co_borrower_dob", width: 200, headerTooltip: "Coborrower DOB", valueFormatter: formatDateTable },
                                        { field: "co_borrower_first_name", headerName: "Coborrower First Name", tooltipField: "co_borrower_first_name", width: 200, headerTooltip: "Coborrower First Name" },
                                        { field: "co_borrower_last_name", headerName: "Coborrower Last Name", tooltipField: "co_borrower_last_name", width: 200, headerTooltip: "Coborrower Last Name" },
                                        { field: "co_borrower_mail_address1", headerName: "Coborrower Mail Address", tooltipField: "co_borrower_mail_address1", width: 200, headerTooltip: "Coborrower Mail Address" },
                                        { field: "co_borrower_mail_city", headerName: "Coborrower Mail City", tooltipField: "co_borrower_mail_city", width: 200, headerTooltip: "Coborrower Mail City" },
                                        { field: "co_borrower_mail_state_code", headerName: "Coborrower State", tooltipField: "co_borrower_mail_state_code", width: 200, headerTooltip: "Coborrower State" },
                                        { field: "co_borrower_mail_zip_code", headerName: "Coborrower Postal Code", tooltipField: "co_borrower_mail_zip_code", width: 200, headerTooltip: "Coborrower Postal Code" },
                                        { field: "co_borrower_ssn", headerName: "Coborrower Ssn", tooltipField: "co_borrower_ssn", width: 200, headerTooltip: "Coborrower Ssn" },
                                        { field: "current_loan_status", headerName: "Current Loan Status", tooltipField: "current_loan_status", width: 200, headerTooltip: "Current Loan Status" },
                                        { field: "fha_case_number", headerName: "FHA Case Number", tooltipField: "fha_case_number", width: 200, headerTooltip: "FHA Case Number" },
                                        { field: "first_payment_date", headerName: "First Payment Date", tooltipField: "first_payment_date", width: 200, headerTooltip: "First Payment Date", valueFormatter: formatDateTable },
                                        { field: "funded_date", headerName: "Funded Date", tooltipField: "funded_date", width: 200, headerTooltip: "Funded Date", valueFormatter: formatDateTable },
                                        { field: "property_address_1", headerName: "Property Address", tooltipField: "property_address_1", width: 200, headerTooltip: "Property Address" },
                                        { field: "property_city", headerName: "Property City", tooltipField: "property_city", width: 200, headerTooltip: "Property City" },
                                        { field: "property_state", headerName: "Property State", tooltipField: "property_state", width: 200, headerTooltip: "Property State" },
                                        { field: "property_zip", headerName: "Property Zip", tooltipField: "property_zip", width: 200, headerTooltip: "Property Zip" },
                                        { field: "property_type", headerName: "Property Type", tooltipField: "property_type", width: 200, headerTooltip: "Property Type" },
                                    ]}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    paginationPageSize={10}
                                    cacheBlockSize={100}
                                    headerHeight={35}
                                    rowHeight={40}
                                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                                    onGridReady={onGridReady}
                                    rowModelType={'serverSide'}
                                    modules={[ServerSideRowModelModule]}
                                    serverSideStoreType={'partial'}
                                />
                            </div>
                        </div>
                    }

                </div>
            </div>
        </>
    );
};
export default LoanSearch;
