import { getScreensWithRoles, listRoles, listScreens } from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { createRole, createScreenRoleMapping, deleteRole, deleteScreenRoleMapping, updateScreenRoleMapping } from '../graphql/mutations';
export const getRoleList = async () => {
    try {
        let response: any = await API.graphql(graphqlOperation(listRoles));
        return response && response.data ? response.data.listRoles : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteRoleById = async (role_id: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(deleteRole, { input: { role_id: role_id } }));
        return response && response.data ? response.data.deleteRole : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getScreenList = async () => {
    try {
        let response: any = await API.graphql(graphqlOperation(listScreens));
        return response && response.data ? response.data.listScreens : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const insertRole = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createRole, { input: data }));
        return response && response.data ? response.data.createRole : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getRoleAssociatedScreens = async () => {
    try {
        let response: any = await API.graphql(graphqlOperation(getScreensWithRoles));
        return response && response.data ? response.data.getScreensWithRoles : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const createRoleScreen = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createScreenRoleMapping, { input: data }));
        return response && response.data ? response.data.createScreenRoleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const updateRoleScreen = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(updateScreenRoleMapping, { input: data }));
        return response && response.data ? response.data.updateScreenRoleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteScreenRoleByRoleId = async (role_id: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(deleteScreenRoleMapping, { input: { role_id: role_id } }));
        return response && response.data ? response.data.deleteScreenRoleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}