import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import React, { useEffect, useState } from "react";

interface CustomTableProps {
  classes?: string;
  flex?: boolean;
  resizable?: boolean;
  floatingFilter?: boolean;
  filter?: boolean;
  editable?: boolean;
  sortable?: boolean;
  rowHeight?: number;
  headerHeight?: number;
  tableHeight?: string;
  ref?: any;
  rows: any[];
  columns: any[];
  onGridReady?: any;
}

export default function CustomTable(props: CustomTableProps) {
  const [dataRows, setDataRows] = useState<any>(null);
  useEffect(() => {
    setDataRows(props.rows);
  }, [props.rows])
  const defaultColDef = {
    sortable: props.sortable,
    editable: props.editable || false,
    filter: props.filter || false,
    floatingFilter: props.floatingFilter || false,
    resizable: props.resizable || false
  };
  const onGridReady = (params: any) => {
    if (props.onGridReady) {
      props.onGridReady(params);
    }
    setDataRows(props.rows);
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      params.api.sizeColumnsToFit();
    }
  }

  const onFilterChanged = (filteredRows) => {
    filteredRows.api.hideOverlay()
    if (filteredRows.api.getDisplayedRowCount() == 0) {
      filteredRows.api.showNoRowsOverlay()
    }
  }

  return (
    <div
      style={{ height: props.tableHeight || "60vh" }}
      className={`ag-theme-alpine ${props.classes || ""}`}
    >
      <AgGridReact
        {...(props.ref ? props.ref : {})}
        headerHeight={props.headerHeight || 35}
        columnDefs={props.columns}
        rowData={dataRows}
        onGridReady={onGridReady}
        rowHeight={props.rowHeight || 40}
        defaultColDef={defaultColDef}
        onFilterChanged={onFilterChanged}
        overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
        modules={[ClientSideRowModelModule]}
      />
    </div>
  );
}
