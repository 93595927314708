/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from '../../../app/store';
import { readAppState } from "../../../redux/reducers/appReducer";
import { setTags } from "../../../redux/reducers/loanReducer";
import { deleteTagLoanMappings, getLoanTagMappingList } from "../../../services/tag.service";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import GenericAgGrid, { getCurrentFilterValue } from "../../common/GenericAgGrid";
import LoanTagHistory from "./LoanTagHistory";
import TagLoanForm from './TagLoanForm';


const Tags = (props: any) => {
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [showTag, setShowTags] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const [showHistory, setShowHistory] = useState(false);
    const gridLoanTagsRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'LOAN_TAGS');

    const [tagsList, setTagsList] = useState(loanState?.tags?.tagList && loanState?.tags?.tagList?.length > 0 ? loanState?.tags?.tagList : [])

    useEffect(() => {
        setTagsList(loanState?.tags?.tagList && loanState?.tags?.tagList?.length > 0 ? loanState?.tags?.tagList : [])
    }, [loanState.tags])

    const loanTagMapping = async (loanId: any) => {
        let tagList: any = []
        await getLoanTagMappingList(loanId).then(data => {
            if (data.length > 0) {
                data.forEach((tag: any) => { tagList.push({ tag_name: tag.tag_name, tag_id: tag.tag_id, tag_description: tag.tag_description, created_by: tag.created_by, created_date: tag.created_date, comments: tag.comments, modified_by: tag.modified_by, modified_date: tag.modified_date, }) });

                dispatch(setTags({ isTagged: true, tagList: tagList }));
            }
            else {
                dispatch(setTags({ isTagged: false, tagList: [] }));
            }
        });
    }
    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }

    const columns: any[] = [
        {
            headerName: 'Tag #', field: 'sr_no', width: 100, headerTooltip: 'Tag #',filter:false            
        },
        { headerName: 'Tag Name', field: 'tag_name', width: 200, headerTooltip: 'Tag Name' },
        { headerName: 'Tag Description', field: 'tag_description', width: 300, headerTooltip: 'Tag Description' },
        { headerName: 'Action Performed By', field: 'created_by', width: 180, headerTooltip: 'Action Performed By' },
        { headerName: 'Comments', field: 'comments', width: 250, headerTooltip: 'Comments' },
        {
            headerName: 'Delete', pinned: 'right', filter: false, width: 90, suppressSizeToFit: true, headerTooltip: 'Delete', cellRendererFramework: (params) =>
                <button title={"Delete"}
                    onClick={() =>
                        setPopup({
                            ...popup,
                            visible: true,
                            message: "Please confirm that you would like to delete this tag.",
                            type: ModalType.ActionDialog,
                            toggle: () => setPopup({ ...defaultPopup }),
                            primaryButtonAction: () => removeTag(params),
                            primaryButtonName: "Delete",
                            secondaryButtonName: "Cancel",
                            secondaryButtonAction: () => setPopup({ ...defaultPopup })
                        })}
                    className="delete_icon_link"><span className="fas fa-trash-alt"></span>
                </button>
        }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const handleTagClick = () => {
        setShowTags(true)
    }

    const removeTag = async (params) => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
            const selectedData = params.data;
            const updateData = {
                loan_number: props.loan_id,
                loan_tag_id: selectedData.loan_tag_id,
                tag_id: selectedData.tag_id,
                modified_date: new Date().toUTCString(),
                modified_by: appState.currentUser!.maas_user_id
            }
            await deleteTagLoanMappings(updateData).then((resp) => {
                if (resp) {
                    loanTagMapping(props.loan_id)
                    setPopup({ ...popup, visible: true, message: "This Tag has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                    gridLoanTagsRef.current.api.refreshServerSideStore();
                }
                else {
                    setPopup({ ...popup, visible: true, message: "Failed to delete tag.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                    loanTagMapping(props.loan_id)
                }
            });
        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "Tag could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
    }

    const preserveFilter = (params: any) => {
        let filterValue = getCurrentFilterValue(gridLoanTagsRef);
        if (params == true) {
            dispatch(setTags({ ...loanState?.tags, is_new: true, is_edit: true, current_page: filterValue.CurrentPage, saved_filter: filterValue.FilterModel, saved_sort: filterValue.SortState }));
        }
    }

    const afterGridLoad = () => {
        if ((!loanState.tags?.is_edit) && (loanState.tags?.saved_sort || loanState.tags?.saved_filter)) {
            gridLoanTagsRef.current.columnApi.applyColumnState({
                state: loanState.tags.saved_sort,
            });
            gridLoanTagsRef.current.api.setFilterModel(loanState.tags.saved_filter);
            dispatch(setTags({ ...loanState?.tags, is_edit: true, current_page: 0 }));
        } else {
            dispatch(setTags({ ...loanState?.tags, saved_sort: null, saved_filter: null, current_page: 0 }));
        }
    }

    return (
        <div >
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
            {!showHistory && !showTag ?
                <><div className="row mb20">
                    <div className="col-12 col-lg-12 col-md-12 mb-3">
                        <div className="float-end">
                            <a className="btn btn-primary btn-sm " style={{ marginRight: "5px" }} id="balance_trans" onClick={() => { preserveFilter(true); handleTagClick() }}>Add Tag
                            </a>
                            <button className="btn btn-primary btn-sm float-end" id="balance_trans" onClick={() => { preserveFilter(true); setShowHistory(true); setShowTags(false) }}> Tag History <i className="fa fa-history"></i>
                            </button>
                        </div>
                    </div>
                </div>
                    {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridLoanTagsRef}
                            afterGridLoad={afterGridLoad}
                            currentPage={loanState.tags?.current_page}
                        ></GenericAgGrid>
                    </div>}
                </> : showTag ?
                    <TagLoanForm loanNumber={props.loan_id} toggle={() => setShowTags(false)} isNew={loanState.tags && loanState?.tags?.tagList?.length > 0 ? false : true} /> :
                    <LoanTagHistory loan_id={props.loan_id} toggle={() => { setShowHistory(false) }} />
            }
        </div>
    )
}

export default Tags;