/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Loader } from "../../helpers/loader";
import { readAppState, setBreadcrumbLocation, setLoaderMessage } from "../../redux/reducers/appReducer";
import { readTransformerLogsState, setFileInformation } from "../../redux/reducers/transformerLogsReducer";
import { executeApprove, getRuleTemplateMapping } from "../../services/transformerLog.service";
import { getPresignedUrlForService, uploadFileToS3 } from "../../services/upload.service";
import { getServicersList } from "../../services/uploadTemplate.service";
import Breadcrumb from "../common/Breadcrumb";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { dateTimeToLocal } from "../../constants/convertToClientDateTime";
import { progressBarStep } from "../../constants/transformerConstant";
import GenericAgGrid from "../common/GenericAgGrid";

const TransformerLogsComponent = () => {
    const [loader, setLoader] = useState(false);
    const [isStatusFailed, setIsStatusFailed] = useState({ enableUploadDownload: false, enableApprove: false })
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [showDetailIcon, setShowDetailIcon] = useState(false);
    const [modal, setModal] = useState(false);
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const transformerLogsState: any = useAppSelector(readTransformerLogsState);
    const [selectedServicer, setSelectedServicer] = useState("");
    const [progressBar, setProgressBar] = useState({ value: 0, processCount: 0 })
    const [localState, setLocalState] = useState<any>({
        file: undefined as any,
    });
    const [prePostRuleValidation, setPrePostRuleValidation] = useState({ post_rule_validation: 0, pre_rule_validation: 0 });
    const [message, setMessage] = useState("");
    const location = useLocation();
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const navigation = useNavigate();
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    let interval;
    var lastRowProcessCode;
    var lastRowStatusCode;
    let enableApprove = false;
    let hardCheckFailed = false;

    useEffect(() => {
        const path = location.pathname.split('/');
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        let updatedBreadcrumb: any[] = [];
        if (path.length >= 2 && breadcrumbLocation.length < 2) {
            path.forEach((x, i) => {
                const screen = screens.find(y => y.redirect_url === `/${x}`);
                if (x === 'FileLogs') {
                    updatedBreadcrumb.push({
                        "screen_id": "",
                        "screen_name": "File Logs",
                        "redirect_url": "/DataLoadStatistics/FileLogs",
                        "icon_url": "fas fa-people-arrows"
                    });
                }
                if (i > 0 && screen) {
                    updatedBreadcrumb.push(screen);
                }
            })
            breadcrumbLocation = [...updatedBreadcrumb];
        }
        else {
            const currentBreadcrumbIndex = appState.breadcrumbLocation.findIndex(x => x.screen_name === "File Logs");
            if (currentBreadcrumbIndex !== -1) {
                breadcrumbLocation = appState.breadcrumbLocation.filter((x, i) => i <= currentBreadcrumbIndex);
            }
            else {
                breadcrumbLocation.push({
                    "screen_id": "",
                    "screen_name": "File Logs",
                    "redirect_url": "/DataLoadStatistics/FileLogs",
                    "icon_url": "fas fa-people-arrows"
                })
            }
        }
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        setLoader(true);
        getServicerList();
        return (() => {
            breadcrumbLocation.pop();
            dispatch(setBreadcrumbLocation(breadcrumbLocation));
        })

    }, []);

    const getServicerList = async () => {
        let defaultServicer = ''
        await getServicersList().then(data => {
            if (data) {
                const servicerArray = data.map(el => {
                    return {
                        label: el.servicer_name,
                        value: el.servicer_bucket
                    }
                });
                if (transformerLogsState.file_information && servicerArray.length > 0) {
                    defaultServicer = servicerArray.find(item => item.label.toLowerCase() === transformerLogsState.file_information?.servicer_name.toLowerCase()).value;
                    setSelectedServicer(defaultServicer);
                }
            }
        })
        const response = await getRuleTemplateMapping(transformerLogsState.file_information.file_name, defaultServicer)
        response && setPrePostRuleValidation({ post_rule_validation: response.post_rule_group_id, pre_rule_validation: response.rule_group_id })
        setLoader(false);
    }

    const handleProgressBar = (statusCode, processCode) => {
        let updatedProgressBar = { ...progressBar }
        if (processCode === "FILES_RECEIVED") {
            updatedProgressBar = { value: 1 / 7 * 100, processCount: 1 }
        }
        else if (processCode === "DATA_TYPE_CHECK") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 2 / 7 * 100, processCount: 2 } : { value: 1 / 7 * 100, processCount: 1 }
        }
        else if (processCode === "STAGING") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 3 / 7 * 100, processCount: 3 } : { value: 2 / 7 * 100, processCount: 2 }
        }
        else if (processCode === "PRE_LOAD_DATA_VALIDATION") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 4 / 7 * 100, processCount: 4 } : { value: 3 / 7 * 100, processCount: 3 }
        }
        else if (processCode === "MAIN") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 5 / 7 * 100, processCount: 5 } : { value: 4 / 7 * 100, processCount: 4 }
        }
        else if (processCode === "PUBLISH") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 6 / 7 * 100, processCount: 6 } : { value: 5 / 7 * 100, processCount: 5 }
        }
        else if (processCode === "POST_LOAD_DATA_VALIDATION") {
            updatedProgressBar = statusCode === "COMPLETED" ? { value: 7 / 7 * 100, processCount: 7 } : { value: 6 / 7 * 100, processCount: 5 }
        }
        setProgressBar({ ...updatedProgressBar })
    }

    const totalTimeTaken = (dt2, dt1) => {
        var diff
        if (dt1 !== undefined && dt2 !== undefined) {
            dt1 = new Date(dt1);
            dt2 = new Date(dt2);
            diff = (dt2.getTime() - dt1.getTime()) / 1000;
            return `${Math.abs(Math.round(diff))} Seconds`;
        }
        else {
            return diff = ""
        }

    }

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'TRANSFORMER_FILE_LOGS');
    const gridTransformerFileLogsRef: any = useRef();

    const getCustomStaticFilter = () => {
        let customFilter = "";
        if (transformerLogsState?.file_information?.transformer_log_id) {
            customFilter = 'transformer_logs_id = ' + transformerLogsState.file_information.transformer_log_id;
        }
        return customFilter;
    }

    const refreshCache = () => {
        try {
            setTimeout(() => {
                gridTransformerFileLogsRef.current?.api.refreshServerSide({ purge: true });
            }, 15000);
        } catch (err) {
            console.error(err);
        }
    }

    const postGridResponse = async (response) => {
        try {
            return new Promise((resolve, reject) => {
                let lastRowData = response[1][0];
                setMessage(response[1][0].message)
                lastRowProcessCode = lastRowData.process_step_code.toUpperCase();
                lastRowStatusCode = lastRowData.status_code.toUpperCase();
                if (lastRowStatusCode === "COMPLETED") {
                    setIsStatusFailed({ enableApprove: false, enableUploadDownload: false })
                }
                else {
                    if (lastRowData && lastRowProcessCode === 'PRE_LOAD_DATA_VALIDATION') {
                        setShowDetailIcon(true);
                        (transformerLogsState.file_information.approvalDisable || (lastRowStatusCode === "COMPLETED")) && setShowDetailIcon(false);
                        if (lastRowData.message === "Waiting for Data Approval - Soft Checks") {
                            enableApprove = true;
                            setIsStatusFailed({ enableApprove: enableApprove, enableUploadDownload: false });
                        }
                        else {
                            hardCheckFailed = true;
                        }
                    }
                    else if (lastRowData && (lastRowStatusCode === "FAILED" && (lastRowData.process_step_id === 3 || (lastRowData.process_step_id === 8 && lastRowData.message === "Waiting for Data Correction - Hard Checks")))) {
                        setIsStatusFailed({ enableApprove: false, enableUploadDownload: true });
                    }
                }

                handleProgressBar(lastRowStatusCode, lastRowProcessCode);

                const processID = response[1].filter(e => e.process_id !== null)[0];
                dispatch(setFileInformation({
                    ...transformerLogsState.file_information,
                    error_file_path: lastRowData.error_file_path,
                    transformer_log_id: lastRowData.transformer_logs_id,
                    process_id: processID ? processID.process_id : null,
                    processName: lastRowProcessCode,
                    status: lastRowStatusCode,
                    is_approve_visible: enableApprove,
                    is_hard_check_failed: hardCheckFailed
                }));

                if (((lastRowProcessCode !== "POST_LOAD_DATA_VALIDATION" && lastRowStatusCode !== "FAILED")
                    || (lastRowProcessCode === "POST_LOAD_DATA_VALIDATION" && lastRowStatusCode === 'STARTED'))
                    || transformerLogsState.file_information.approvalDisable
                ) {

                    if (lastRowProcessCode !== "PUBLISH") {
                        refreshCache();
                    }
                    else {
                        if (lastRowStatusCode === 'STARTED') {
                            refreshCache();
                        }
                    }

                }

                resolve({ isNewPrintRows: false });
            })
        } catch (err) {
            console.error(err);
        }
    }
    // Waiting for Data Approval - Soft Checks
    const columns = [
        { headerName: 'batch_job_logs_detail_id', field: 'batch_job_logs_detail_id', hide: true },
        { headerName: 'Process Name', field: 'process_step_name', headerTooltip: 'Process Step Name' },
        { headerName: 'Message', field: 'message', width: 300, headerTooltip: 'Message' },
        {
            headerName: "Start Time",
            field: "start_time",
            headerTooltip: "Start time",
            cellRendererFramework: (params) => (
                <div>
                    <p>
                        {dateTimeToLocal(params.data.start_time)}
                    </p>
                </div>
            ),
        },
        {
            headerName: "End Time",
            field: "end_time",
            headerTooltip: "End time",
            cellRendererFramework: (params) => (
                <div>
                    <p>
                        {dateTimeToLocal(params.data.end_time)}
                    </p>
                </div>
            ),
        },
        {
            headerName: 'Total Time', field: 'end_time', headerTooltip: 'End Time', cellRendererFramework: (params) => <div>
                <p>{totalTimeTaken(params.data.end_time, params.data.start_time)}</p>
            </div>
        },
        {
            headerName: 'Status', field: 'status_code', width: 150, headerTooltip: 'Status Code', cellRendererFramework: (params) => <div className="row">
                <p style={{ color: `${(params.data.status_code === 'STARTED' && 'orange') || (params.data.status_code === 'COMPLETED' && 'green') || (params.data.status_code === 'FAILED' && 'red')}` }}>
                    {params.data.status_code}
                    {
                        params.data.status_code === 'FAILED' && message == "Waiting for Data Approval - Soft Checks" ?
                            params.data.status_code === 'FAILED' && showDetailIcon && <i style={{ marginLeft: "10px", marginTop: "10%", color: "Black" }} className="fas fa-external-link-square-alt" onClick={() => navigation('/DataLoadStatistics/RuleOutput')} title="Click to open log detail" ></i>
                            :
                            params.data.status_code === 'FAILED'
                    }
                </p>
            </div>
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true, enableTooltip: true
    }

    const downloadFile = async (filePath) => {
        let key = filePath.split(".com/")[1];
        let bucketName = process.env.REACT_APP_SFTP_FILE_BUCKET;
        try {
            const result = await getPresignedUrlForService(bucketName, key, "download")
            if (result) {
                var file_path = JSON.parse(JSON.parse(result)["body"]).url;
                var filename = file_path ? file_path.substring(file_path.lastIndexOf("/") + 1).split("?")[0] : '';
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function () {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(xhr.response);
                    link.download = filename;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                };
                xhr.open('GET', file_path);
                xhr.send();
            }
            else {
                setPopup({ ...popup, visible: true, message: "Something went wrong! Please try again later.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            }
        }
        catch (e) {
            setPopup({ ...popup, visible: true, message: "Something went wrong! Please try again later.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            return false;
        }
    }

    const handleFileSelect = (e: any) => {
        if (e.target.files) {
            for (var fileObject in e.target.files) {
                if (typeof e.target.files[fileObject] === "object") {
                    const fileData = (e.target.files[fileObject]);
                    const splitFileName = fileData.name.split(".");
                    const fileExtension = splitFileName[splitFileName.length - 1];
                    if (!['txt', 'csv', 'xlsx'].includes(fileExtension)) {
                        setPopup({
                            ...popup,
                            visible: true,
                            message: 'File - ' + fileData.name + ' is not valid.',
                            type: ModalType.Snackbar,
                            variant: VariantType.Error,
                            toggle: () => setPopup({ ...defaultPopup }),
                        });
                        return false;
                    }
                    const fileBlob = new Blob([fileData], { type: fileData.type });
                    setLocalState({ ...localState.file, file: { fileData: e.target.files[0], accessUrl: URL.createObjectURL(fileBlob), fileName: fileData.name, fileType: fileData.type, isUploaded: false } });
                }
            }
        }
    }
    const onCancel = () => {
        setLocalState({ ...localState, file: undefined });
        setModal(false)
        setLoader(false)
    }

    const onRemoveFile = () => {
        setLocalState({ ...localState, file: undefined });
    }

    const handleSaveClick = async () => {
        if (localState.file) {
            setLoader(true);
            let key = transformerLogsState?.file_information?.file_path.split(".com/")[1];
            let index = key && key.length > 0 && key.lastIndexOf("/");
            key = key && key.length > 0 && key.substring(0, index);
            let fileNameIndex = localState.file.fileName && localState.file.fileName.lastIndexOf(".");
            if (key && key.length > 0) {
                if (fileNameIndex && localState.file.fileName.substring(0, fileNameIndex) === transformerLogsState.file_information.file_name) {
                    const data = await uploadFile({ file: localState.file, folderName: `${key}`, bucket: process.env.REACT_APP_SFTP_FILE_BUCKET });

                    if (data) {
                        if (transformerLogsState.file_information) {
                            await executeApprove(transformerLogsState.file_information.transformer_log_id, "validate", process.env.REACT_APP_SFTP_FILE_BUCKET, `${key}/${localState.file.fileName}`, appState.currentUser?.user_id)
                        }
                        setLocalState({ ...localState, file: { accessUrl: "", fileName: "", fileType: "", isUploaded: false } });
                        setLoader(false);
                        dispatch(setLoaderMessage(""));
                        setPopup({ ...popup, visible: true, message: "File uploaded successfully!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                        localStorage.removeItem('loaderMessage');
                        setLocalState({ ...localState, file: undefined });
                        setProgressBar({ value: progressBar.value, processCount: progressBar.processCount })
                        interval = setInterval(function () {
                            refreshCache();
                        }, 20000);
                        setIsStatusFailed({ enableUploadDownload: false, enableApprove: isStatusFailed.enableApprove })


                    }
                    else {
                        setLoader(false);
                        setProgressBar({ value: progressBar.value, processCount: progressBar.processCount })
                        setPopup({ ...popup, visible: true, message: "Something went wrong! Please re-upload the file.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                        setLocalState({ ...localState, file: undefined });
                        dispatch(setLoaderMessage(""))
                    }
                }
                else {
                    setModal(false);
                    setLoader(false);
                    setLocalState({ ...localState, file: undefined });
                    setPopup({ ...popup, visible: true, message: `Please re-upload the file with previously provided file name.`, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                    dispatch(setLoaderMessage(""))
                }
            }
            else {
                setModal(false);
                setLoader(false);
                setLocalState({ ...localState, file: undefined });
                setPopup({ ...popup, visible: true, message: `This file cannot be uploaded due to undefined file path.`, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                dispatch(setLoaderMessage(""))
            }
        }
    }

    const uploadFile = async (data: any) => {
        try {
            let bucketName = data.bucket;
            let key = `${data.folderName}/${data.file.fileName}`;
            let filename = data.file.fileName;
            var file = data.file.fileData;

            setModal(false);
            const data1 = await getPresignedUrlForService(bucketName, key, "upload")
            if (data1) {
                let fields = JSON.parse(JSON.parse(data1)["body"]).fields;
                //let preSignedPostUrl = JSON.parse(JSON.parse(data1)["body"]).url;

                // let headersList = {
                //     "Accept": "*/*",
                // }
                let formData = new FormData();
                formData.append("AWSAccessKeyId", fields["AWSAccessKeyId"]);
                formData.append("key", fields["key"]);
                formData.append("policy", fields["policy"]);
                formData.append("signature", fields["signature"]);
                formData.append("x-amz-security-token", fields["x-amz-security-token"]);
                formData.append('file', file, filename);

                //let bodyContent = formData;

                dispatch(setLoaderMessage(`Data Loading Started`));
                try {
                    const result = await uploadFileToS3(bucketName, file, filename, data.file.fileType, data.folderName);
                    //const result = await S3upload(preSignedPostUrl, headersList, bodyContent);
                    if (result)
                        return true;
                    else
                        return false;
                }
                catch (e) {
                    console.error(e);
                    return false;
                }
            }
            else {
                return false;
            }

        }
        catch (e) {
            console.error(e);
            return false;
        }
    }


    return (
        <>
            <div className="pagetitle">
                <div className="row align-items-center" id="dashboardScreen">
                    <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <Breadcrumb />
                    </div>
                    <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse" style={{ height: "fit-content", marginTop: "20px" }}>
                        {(transformerLogsState?.file_information?.status !== "COMPLETED") &&
                            isStatusFailed.enableUploadDownload && <>
                                <a className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { setModal(!modal); }}>
                                    <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-upload`} />Upload File
                                </a>
                                <a className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { downloadFile(transformerLogsState?.file_information?.error_file_path) }}>
                                    <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-download`} />Download File
                                </a>
                            </>}
                    </div>
                </div>
                <ul className="transformerLogProgressBar" style={{ marginTop: '35px' }}>
                    <li className={progressBar.processCount >= progressBarStep[0].value ? "active" : ""}>{progressBarStep[0].label}</li>
                    <li className={progressBar.processCount >= progressBarStep[1].value ? "active" : ""}>{progressBarStep[1].label}</li>
                    <li className={progressBar.processCount >= progressBarStep[2].value ? "active" : ""}>{progressBarStep[2].label}</li>
                    {prePostRuleValidation?.pre_rule_validation > 0 && <li className={progressBar.processCount >= progressBarStep[3].value ? "active" : ""}>{progressBarStep[3].label}</li>}
                    <li className={progressBar.processCount >= progressBarStep[4].value ? "active" : ""}>{progressBarStep[4].label}</li>
                    <li className={progressBar.processCount >= progressBarStep[5].value ? "active" : ""}>{progressBarStep[5].label}</li>
                    {prePostRuleValidation?.post_rule_validation > 0 && <li className={progressBar.processCount >= progressBarStep[6].value ? "active" : ""}>{progressBarStep[6].label}</li>}
                </ul>
            </div>
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
            {loader && <Loader />}

            {!!gridDetails && <div>
                <GenericAgGrid
                    gridColumns={columns}
                    gridDefaultColDef={defaultColDef}
                    gridDetails={gridDetails}
                    rowHeight={40}
                    rowModelType={'serverSide'}
                    modules={[ServerSideRowModelModule]}
                    customStaticFilter={getCustomStaticFilter}
                    parentGridRef={gridTransformerFileLogsRef}
                    postGridResponse={postGridResponse}
                    sizeColumnsToFit={true}
                ></GenericAgGrid>
            </div>}

            <Modal className="upload-file-modal" isOpen={modal} centered toggle={() => setModal(!modal)} backdrop="static">
                <ModalHeader toggle={() => { onCancel() }}>Upload File</ModalHeader>
                <ModalBody>
                    <div>
                        <input id="tapeFile" style={{ display: "none" }} type="file" name="files" accept=".txt, .csv, .xlsx" onClick={(event: any) => event.target.value = null} onChange={(e) => handleFileSelect(e)} />
                        <label className="file-select" htmlFor="tapeFile" style={{}}>
                            <img src={massCdnUrl + "assets/img/site-file-upload.svg"} style={{ width: "45px" }} />
                            <span style={{ display: "block", color: "var(--secondary-color)" }} >{"Click here to browse the file."}</span>
                        </label>
                        <label className="selected-file-name">
                            {localState.file && <>
                                <span style={{ display: "block", color: "var(--primary-color)" }} >{localState.file.fileName}</span>
                                <span className="selected-file-remove" onClick={() => { onRemoveFile() }}>
                                    <i className="fa fa-remove" style={{ verticalAlign: "middle" }}></i>
                                </span>
                            </>
                            }
                        </label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row ">
                        <div className="col-lg-6 col">
                            <button onClick={() => { handleSaveClick() }} className="btn btn-primary btn-sm" disabled={!localState.file}>Upload</button>
                        </div>
                        <div className="col-lg-6 col">
                            <button onClick={() => { onCancel() }} className="btn btn-outline-primary btn-sm">Cancel</button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default memo(TransformerLogsComponent);

