/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridReact } from "@ag-grid-community/react";
import { memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import { getAllApiDashboards } from "../../../services/dashboard.service";
import { getGroupList } from "../../../services/user.service";
import Breadcrumb from "../../common/Breadcrumb";

const AllDashboards = () => {
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const gridAllDashboardsRef: any = useRef();
    const gridApi = useRef(null as any);
    const [rowData, setRowData] = useState<any>(undefined);
    const dashboardState = useAppSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "Dashboards",
            redirect_url: "/AllDashboards",
            icon_url: "fad fa-user"
        }]));
        getAdminAllDashboards();
    }, []);

    const getAdminAllDashboards = async () => {
        const userGroup = await getGroupList();
        // const dashboardListFromState: any = dashboardState.allDashboards;
        let dashboardListFromState: any = await getDashboardsList();
        const AllDashboardsInfo = dashboardListFromState.map(item => {
            let userGroupName = userGroup.filter(data => data.group_name.toLowerCase() == item.creatorName.toLowerCase())[0];
            return {
                dashboard_name: item.name,
                owner_name: userGroupName && userGroupName.group_name ? userGroupName.group_name : item.creatorName,
                // permissions: item.permissions,
                creator_is_active: userGroupName && userGroupName.group_name ? "Yes" : "No",
                created_date: item.createdDate
                // modified_date: item.lastModifiedDate
            }
        });
        setRowData(AllDashboardsInfo.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1));
    }

    const getDashboardsList = async () => {
        try {
            const token = appState.logiToken;
            let list: any;
            if (token) {
                await getAllApiDashboards({ token: token }).then((response) => {
                    if (response && response.length > 0) {
                        list = response;
                    }
                });
            }
            return list;
        } catch (err) {
            console.error(err);
            return null
        }
    }

    const permissionsCss = ({ value }) => {
        let myValue = value.map(item => {
            switch (item) {
                case 'READ':
                    return <span key={item} style={{ color: 'forestgreen', padding: '5px' }}>{'Read'}</span>
                case 'WRITE':
                    return <span key={item} style={{ color: 'blue', padding: '5px' }}>{'Edit'}</span>
                case 'DELETE':
                    return <span key={item} style={{ color: 'red', padding: '5px' }}>{'Delete'}</span>
                default:
                    break;
            }
        });
        return <code>{myValue}</code>;
    };

    const columns: any[] = [
        { headerName: 'Dashboard Name', field: 'dashboard_name', headerTooltip: 'Dashboard Name' },
        { headerName: 'Owner', field: 'owner_name', headerTooltip: 'Owner' },
        //{ headerName: 'Permissions', field: 'permissions', headerTooltip: 'Permissions', cellRendererFramework: permissionsCss },
        { headerName: 'Is Owner Active?', field: 'creator_is_active', headerTooltip: 'Is Owner Active?' },
        { headerName: 'Created Date', field: 'created_date', headerTooltip: 'Created Date' }
        // { headerName: 'Modified Date', field: 'modified_date', headerTooltip: 'Modified Date' }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const onGridReady = (params: any) => {
        try {
            gridApi.current = params.api;
            params.api.sizeColumnsToFit();
        } catch (err) {
            console.error(err);
        }
    };

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    useEffect(() => {
        if (gridApi.current) {
            setTimeout(() => { gridApi.current.sizeColumnsToFit(); }, 500)
        }
    }, [appState.isNavMenuOpen]);

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
            </div>
        </div>
        <section className="section dashboard">
            <div className="ag-theme-alpine" style={{ height: "72vh", width: "100%" }}>
                <AgGridReact
                    ref={gridAllDashboardsRef}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    rowData={rowData}
                    headerHeight={32}
                    rowHeight={40}
                    pagination={true}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}
                ></AgGridReact>
            </div>
        </section>
    </>)
}

export default memo(AllDashboards);
