import { RootState } from './../../app/store';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../constants/commonConstants';
import { DashboardSortingValues } from '../../constants/reportsContants';
import { deleteDashboardById, getFilteredDashboards } from '../../services/dashboard.service';
import { DashboardState } from '../stores/dashboardStore';
import { setCurrentDashboard } from './appReducer';

const initialState: DashboardState = {
    errorMessage: "",
    status: Status.Idle,
    currentDashboards: [],
    allDashboards: [],
    filteredDashboards: [],
    dashboardSortingType: DashboardSortingValues.DashboardNameAtoZ,
    dashboardPageNo: 1,
    isDashboardLoaded: false,
    showSearchBar: true,
};


export const getDashboardsAsync = createAsyncThunk(
    'app/getDashboards',
    async (params: any) => {
        const response = await getFilteredDashboards(params.token, params.dashboardsList, params.isAdminUser, params.userGroups);
        return response;
    }
);

export const deleteDashboardAsync = createAsyncThunk(
    'app/deleteDashboard',
    async (params: any) => {
        const response = await deleteDashboardById(params.token, params.dashboardId);
        return response;
    }
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        sortDashboards: (state: DashboardState, action: PayloadAction<string>) => {
            state.dashboardSortingType = action.payload;
            let sortedDashboards: any[] = [];
            switch (action.payload) {
                case DashboardSortingValues.DashboardNameAtoZ:
                    sortedDashboards = state.allDashboards.sort((a, b) => a.name.localeCompare(b.name));
                    break;
                case DashboardSortingValues.DashboardNameZtoA:
                    sortedDashboards = state.allDashboards.sort((a, b) => b.name.localeCompare(a.name));
                    break;
                case DashboardSortingValues.ModifiedDateAscending:
                    sortedDashboards = state.allDashboards.sort((a, b) => new Date(a.modifiedDate).getTime() > new Date(b.modifiedDate).getTime() ? 1 : -1);
                    break;
                case DashboardSortingValues.ModifiedDateDescending:
                    sortedDashboards = state.allDashboards.sort((a, b) => new Date(a.modifiedDate).getTime() > new Date(b.modifiedDate).getTime() ? -1 : 1);
                    break;
            }
            state.allDashboards = sortedDashboards;
            state.filteredDashboards = sortedDashboards;
            state.currentDashboards = sortedDashboards.slice(0, 15);
        },
        changeDashboardPage: (state: DashboardState, action: PayloadAction<number>) => {
            state.dashboardPageNo = action.payload;
            state.currentDashboards = state.filteredDashboards.slice(((15 * action.payload) - 15), (15 * action.payload));
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        filterDashboards: (state: DashboardState, action: PayloadAction<string>) => {
            let filteredDashboards = state.allDashboards.filter((value) => {
                return value.name.toLowerCase().includes(action.payload.toLowerCase());
            });
            state.filteredDashboards = filteredDashboards;
            state.currentDashboards = filteredDashboards.slice(0, 15);
            state.dashboardPageNo = 1;
        },
        resetFilteredDashboards: (state: DashboardState) => {
            state.filteredDashboards = state.allDashboards;
            state.currentDashboards = state.allDashboards.slice(0, 15);
            state.dashboardPageNo = 1;
        },
        resetDashboardState: (state: DashboardState) => {
            state.errorMessage = "";
            state.status = Status.Idle;
            state.currentDashboards = [];
            state.allDashboards = [];
            state.filteredDashboards = [];
            state.dashboardSortingType = DashboardSortingValues.DashboardNameAtoZ;
            state.dashboardPageNo = 1;
            state.isDashboardLoaded = false;
            state.appliedFilters = undefined;
            state.currentSource = undefined;
        },
        setShowDetails: (state: DashboardState, action: PayloadAction<boolean>) => {
            state.showDetails = action.payload;
        },
        setCurrentSource: (state: DashboardState, action: PayloadAction<any>) => {
            state.currentSource = action.payload;
        },
        setAppliedFilters: (state: DashboardState, action: PayloadAction<any>) => {
            state.appliedFilters = action.payload;
        },
        setShowSearchBar: (state: DashboardState) => {
            return {
                ...state,
                showSearchBar: !state.showSearchBar
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardsAsync.pending, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
                state.isDashboardLoaded = false;
            })
            .addCase(getDashboardsAsync.fulfilled, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                let reports = action.payload.sort((a, b) => a.name.localeCompare(b.name));
                state.allDashboards = reports;
                state.filteredDashboards = reports;
                state.currentDashboards = reports.slice(0, 15);
                state.dashboardPageNo = 1;
                state.isDashboardLoaded = true;
            })
            .addCase(getDashboardsAsync.rejected, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.allDashboards = [];
                state.filteredDashboards = [];
                state.currentDashboards = [];
                state.errorMessage = "There was a problem fetching Reports.";
                state.isDashboardLoaded = true;
            })
            .addCase(deleteDashboardAsync.pending, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(deleteDashboardAsync.fulfilled, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                let dashboards = current(state.allDashboards).filter(i => i.inventoryItemId !== action.payload);
                state.allDashboards = dashboards;
                state.filteredDashboards = dashboards;
                state.currentDashboards = dashboards.slice(0, 15);
                state.dashboardPageNo = 1;
            })
            .addCase(deleteDashboardAsync.rejected, (state: DashboardState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.errorMessage = "There was a problem deleting dashboard."
            })
    }
});

export const {
    sortDashboards,
    changeDashboardPage,
    filterDashboards,
    resetFilteredDashboards,
    setShowDetails,
    resetDashboardState,
    setCurrentSource,
    setAppliedFilters,
    setShowSearchBar
} = dashboardSlice.actions;


export const readDashboardState = (state: RootState) => state.dashboard;
export default dashboardSlice.reducer;