import { useState, useEffect } from "react";
import Select from "react-select";
import { selectCustomStyles } from "../../common/CustomStyles";
import { readAppState } from "../../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setTags, setTagsRefresh } from "../../../redux/reducers/loanReducer";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { getTagList, insertTagLoanMapping } from "../../../services/tag.service";
import { RootState } from "../../../app/store";
import { getLoanTagMappingList } from "../../../services/tag.service";

const TagLoanForm = (props: any) => {
  const loanState = useAppSelector((state: RootState) => state.loan);
  const [tagName, setTagName] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [commentDetail, setCommentDetail] = useState("");
  const appState = useAppSelector(readAppState);
  const dispatch = useAppDispatch();
  const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
  const [popup, setPopup] = useState(defaultPopup);

  useEffect(() => {
    getTagList().then(response => {
      if (response && response.length > 0) {
        let tags: any[] = response;
        let selectedTags: any[] = loanState?.tags?.tagList && loanState?.tags?.tagList?.length > 0 ? loanState?.tags?.tagList : []
        let result: any[] = getUpdatedTags(tags, selectedTags)
        let availableValues: any[] = [];
        result.forEach((x: any) => {
          availableValues.push({
            label: x.tag_name,
            value: x.tag_id
          })
        })
        setTagList(availableValues)
      }
    })
  }, [])

  const loanTagMapping = async (loanId: any) => {
    let tagList: any = []
    await getLoanTagMappingList(loanId).then(data => {
      if (data.length > 0) {
        data.forEach((tag: any) => { tagList.push({ loan_tag_id: tag.loan_tag_id, tag_name: tag.tag_name, tag_id: tag.tag_id, tag_description: tag.tag_description, created_by: tag.created_by, created_date: tag.created_date, comments: tag.comments, modified_by: tag.modified_by, modified_date: tag.modified_date }) });
        dispatch(setTags({ isTagged: true, tagList: tagList }));
      }
      else {
        dispatch(setTags({ isTagged: false, tagList: [] }));
      }
    });
  }

  const getUpdatedTags = (array1: any[], array2: any[]) => {
    let difference: any[] = [];
    difference = array1.filter((item1) =>
      !array2.some((item2) =>
        item2.tag_name === item1.tag_name
      )
    )
    return difference;
  }

  const submitButton = () => {
    let tags: any = []
    tagName.forEach(item => tags.push(item.label));
    tags = tags.join(', ')
    let text = `Are you sure you want to tag loan ${props.loanNumber} under ${tags} ?`;
    let popupText = <p style={{textAlign:"start",marginLeft:"25px",marginRight:"25px"}}>{text.trim()}</p>

    setPopup({
      ...popup,
      visible: true,
      message: popupText,
      type: ModalType.ActionDialog,
      toggle: () => setPopup({ ...defaultPopup }),
      primaryButtonAction: () => { handleLoanTagMapping() },
      primaryButtonName: "Save",
      secondaryButtonName: "Cancel",
      secondaryButtonAction: () => setPopup({ ...defaultPopup })
    });
  }

  const handleTagChange = (e: any) => {
    setTagName(e)
  }

  const handleLoanTagMapping = async () => {
    let tags_id: any = [];
    let tag_name: any = []
    setPopup({ ...defaultPopup });
    tagName.forEach(item => tag_name.push(item.label));
    tagName.forEach(item => tags_id.push(item.value));
    for (let i = 0; i < tags_id.length; i++) {
      let insertValue = {
        loan_number: props.loanNumber,
        tag_id: tags_id[i],
        is_active: true,
        comments: commentDetail,
        inserted_date: new Date().toUTCString(),
        inserted_by: appState.currentUser!.maas_user_id
      };
      await insertTagLoanMapping(insertValue);
    }
    setPopup({ ...popup, visible: true, message: `Tag added successfully`, type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { props.toggle() } });
    dispatch(setTagsRefresh(!loanState.tags?.refresh));
    setTagName("");
    setCommentDetail("");
    loanTagMapping(props.loanNumber);
  }

  return (
    <div className="row">
      {<>{popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
        <div className="col">
          <div className="row payment-boxes ">
            <div className="col-lg-12 payment-box-heading">
              <h6 className="accordion_card1_title">Tag Loan Number ({props.loanNumber})</h6>
            </div>
            <div className="col-lg-4 col-md-4" style={{ float: 'none', margin: '0 auto' }}>
              <div className="form-floating multi-select-field ct_form_field select_dd">
                <Select isClearable={true}
                  isMulti={true}
                  styles={selectCustomStyles}
                  className="form-control  multi-select-dd"
                  id="commentType"
                  options={tagList}
                  value={tagName}
                  menuPlacement="auto"
                  maxMenuHeight={170}
                  onChange={(e) => { handleTagChange(e) }}
                  placeholder="Select Single or Multiple Tags"
                />
                <label htmlFor="commentType">Tags</label>
              </div>

              <div className="form-floating ct_form_field">
                <textarea className="form-control"
                  id="comment"
                  placeholder="Comments.."
                  style={{ height: "auto", minHeight: "80px" }}
                  rows={1}
                  value={commentDetail}
                  onChange={(e) => setCommentDetail(e.target.value)}
                />
                <label htmlFor="comment" ref={el => {
                  if (el) {
                    //el.style.setProperty('padding-right', '90px', 'important');
                    el.style.setProperty('padding-bottom', '0px', 'important');
                  }
                }}
                  style={{ background: "#fff", height: "auto" }}>Add comments for all the selected tags</label>
              </div>
            </div>
            <div className="row justify-content-md-center mt30 gx-3" style={{ margin: "1rem" }}>
              <div className="col-lg-2 col text-center">
                <button className="btn btn-primary text-center btn-block" disabled={(tagName.length === 0 && props.isNew || tagName.length === 0 && !props.isNew) ? true : false} type="submit" onClick={() => { submitButton() }}>Save </button>
              </div>
              <div className="col-lg-2 col text-center">
                <button className="btn btn-outline-primary text-center btn-block" onClick={() => { dispatch(setTags({ ...loanState?.tags, is_edit: false })); props.toggle() }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div> </>}
    </div>

  );
};

export default TagLoanForm;
