/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { createDashboard, getDashboardsWithVisuals, getDashboardToken, getMasterDashboardList, removeDashboard, updateDashboards } from "../../services/dashboard.service";
import { Loader } from "../../helpers/loader";
import { useNavigate } from "react-router-dom";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getCannedReportListAsync, getLogiConfigurationAsync, getMasterDashboardListAsync, getUserGroupsAsync, handleSearchWordChange, readAppState, setCurrentDashboard, setDashboardsList, setDashboardWithVisualsList, setLogiToken, setMasterDashboardList, setMasterVisualList } from "../../redux/reducers/appReducer";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SharePopupComponent from "../common/SharePopupComponent";
import Breadcrumb from "../common/Breadcrumb";
import { changeDashboardPage, deleteDashboardAsync, filterDashboards, getDashboardsAsync, resetFilteredDashboards, sortDashboards } from "../../redux/reducers/dashboardReducer";
import { RootState } from "../../app/store";
import { DashboardSortingTypes } from "../../constants/reportsContants";
import { Status } from "../../constants/commonConstants";
import { readUserState } from "../../redux/reducers/userReducer";
import { getMasterVisualsList } from "../../services/visual.service";

const DashboardLibrary = () => {
  const navigation = useNavigate();
  const [loading, setLoader] = useState(true);
  const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
  const [popup, setPopup] = useState(defaultPopup);
  const appState = useAppSelector(readAppState);
  const userState = useAppSelector(readUserState);
  const dashboardState = useAppSelector((state: RootState) => state.dashboard);
  const dashboards = dashboardState.currentDashboards;
  const dispatch = useAppDispatch();
  const [newDashboard, setNewDashboardData] = useState({ isVisible: false, dashboardName: "" });
  const userGroups = (userState.userGroupList !== undefined && userState.userGroupList.length > 0) ? userState.userGroupList : appState.userGroups;
  const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
  useEffect(() => {
    try {
      (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then(async (embedManager: any) => {
        embedManager.refreshWithToken(() => getMasterToken(true).then((r) => r));
      })
        .catch((e: any) => { console.log(`Logi Error: ${e}`); })
    }
    catch (e: any) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchData();
    dispatch(handleSearchWordChange(""));
    try {
      (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then(async (embedManager: any) => {
        embedManager.refreshWithToken(() => getMasterToken(true).then((r) => r));
      })
        .catch((e: any) => { console.log(`Logi Error: ${e}`); })
    }
    catch (e: any) {
      console.log(e)
    }
  }, []);

  useEffect(() => {
    if (appState.searchWord && appState.searchWord !== "") {
      dispatch(filterDashboards(appState.searchWord));
    }
    else {
      dispatch(resetFilteredDashboards());
      dispatch(changeDashboardPage(dashboardState.dashboardPageNo))
    }
  }, [appState.searchWord]);

  const getMasterToken = async (optional?: boolean) => {
    if (appState.logiToken && optional) {
      return appState.logiToken;
    }
    const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
      return r
    })
    if (token) {
      dispatch(setLogiToken(token));
    }
    return token;
  };

  const fetchData = async () => {
    try {
      let dashboardList: any;
      if (appState.masterDashboardList) {
        dashboardList = appState.masterDashboardList;
      }
      else {
        dashboardList = await getMasterDashboardList(appState.logiConfiguration);
      }
      let userGroup = appState.userGroups;
      const token = await getMasterToken();
      if (token) {
        let params = {
          token: token,
          isAdminUser: appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name') ? appState.currentUser?.maas_user_id === appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value : false,
          dashboardsList: dashboardList,
          userGroups: userGroup
        }
        dispatch(getDashboardsAsync(params)).then(() => {
          dispatch(sortDashboards(dashboardState.dashboardSortingType));
          dispatch(changeDashboardPage(dashboardState.dashboardPageNo))
          setLoader(false);
        })
      }
    }
    catch (ex) {
      setLoader(false);
    }
  }

  const handleDashboardClick = (currentDashboard: any) => {
    dispatch(setCurrentDashboard(currentDashboard));
    setTimeout(() => {
      setPopup({ ...defaultPopup });
      navigation("/DashboardView");
    }, 3000);


  }

  const addDashboard = async (dashboardName: string) => {
    setLoader(true);
    try {
      const masterDashboardsList = appState.masterDashboardList;
      let updatedMasterDashboardList = [...masterDashboardsList]
      if (!masterDashboardsList.find(x => x.name === dashboardName)) {
        const response = await createDashboard({ name: dashboardName, user: appState.currentUser, logiConfiguration: appState.logiConfiguration });
        if (response && response.data) {
          updatedMasterDashboardList.push(response.data)
          let updatedDashboardsList = [...appState.currentUser!.dashboards_list];
          updatedDashboardsList.push({ dashboard_id: response.data.inventoryItemId, dashboard_name: response.data.name })
          const input = {
            group_id: appState.currentUser?.group_id,
            dashboards_list: JSON.stringify({ data: updatedDashboardsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            modified_by: appState.currentUser?.maas_user_id,
            modified_date: new Date().toUTCString()
          }

          dispatch(setMasterDashboardList(updatedMasterDashboardList));
          dispatch(setDashboardsList(updatedDashboardsList));
          updateDashboards(input);
          handleDashboardClick(response.data);
          setPopup({ ...popup, visible: true, message: `${response.data.name} is added to the dashboard library`, type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });

        }

        else {
          setPopup({ ...popup, visible: true, message: "Dashboard could not be created!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });

        }
      }
      else {
        setPopup({ ...popup, visible: true, message: `Failed to save dashboard: Dashboard with name '${dashboardName}' already used by another user.`, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
      }
      setLoader(false);
    }
    catch (e) {
      console.log(e);
      setLoader(false);
    }
  }

  const handleDashboardDelete = async (dashboard: any) => {
    setPopup(defaultPopup);
    const token = await getMasterToken();
    dispatch(deleteDashboardAsync({ token: token, dashboardId: dashboard.inventoryItemId })).then((data) => {
      if (data.payload) {
        removeDashboard(appState, dashboard).then((response) => {
          if (response && response.success) {
            let updatedDashboardsList = appState.currentUser!.dashboards_list.filter(x => x.dashboard_id !== dashboard.inventoryItemId);
            let updatedMasterDashboardList = appState.masterDashboardList.filter(dash => dash.inventoryItemId !== dashboard.inventoryItemId);
            dispatch(setMasterDashboardList(updatedMasterDashboardList));
            dispatch(setDashboardsList(updatedDashboardsList));
            dispatch(handleSearchWordChange(""));
            setPopup({ ...popup, visible: true, message: "This dashboard has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); } });
          }
        })
      }
    })
  }

  const confirmDashboardDelete = (dashboard: any) => {
    setPopup({
      ...popup,
      visible: true,
      message: `Are you sure you want to delete ${dashboard.name}?`,
      type: ModalType.ActionDialog,
      toggle: () => setPopup({ ...defaultPopup }),
      primaryButtonAction: () => handleDashboardDelete(dashboard),
      primaryButtonName: "Delete",
      secondaryButtonName: "Cancel",
      secondaryButtonAction: () => setPopup({ ...defaultPopup })
    });
  }

  const handleShareResponse = (response: boolean) => {
    if (!response) {
      setPopup({
        ...popup,
        visible: true,
        message: `This dashboard cannot be shared. Please check your permissions.`,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup })
      });
    }
  }

  const showDisabledPopover = (isTrue) => {

    if (isTrue) {
      setPopup({
        ...popup,
        visible: true,
        message: `This dashboard cannot be shared. Please check your permissions.`,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup })
      });
    }
    else {
      setPopup({
        ...popup,
        visible: true,
        message: `You do not have permission to delete this dashboard.`,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup })
      });
    }
  }
  return (<>
    <div className="pagetitle">
      <div className="row align-items-center">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <Breadcrumb />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 dashboard-search-container ">
          <input className="form-control local-search" type="search" placeholder="Search Dashboard " value={appState.searchWord} onChange={(e) => dispatch(handleSearchWordChange(e.target.value))} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
          <a className="btn btn-primary float-end" onClick={() => setNewDashboardData({ ...newDashboard, isVisible: true })}
          onKeyPress={(target) => { if (target.charCode === 13) {setNewDashboardData({ ...newDashboard, isVisible: true })}}}>

            <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} />
            Add Dashboard
          </a>
        </div>
      </div>
    </div>

    <section className="section dashboard">
      <div className="row mb-3">
        <div className="col-10">
          <div className="filter_sect">
            <label style={{ marginLeft: "4px" }}> Sort By :</label>
            <div className="dropdown dropdown-menu-end sort-menu-border">
              <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {dashboardState.dashboardSortingType}
              </button>
              <ul style={{ marginLeft: "50px" }} className="dropdown-menu dropdown_arrow" aria-labelledby="dropdownMenuButton1">
                {
                  DashboardSortingTypes.map(t => {
                    return <li key={t}><a className="dropdown-item" onClick={() => dispatch(sortDashboards(t))}
                      onKeyPress={(target) => { if (target.charCode === 13) {dispatch(sortDashboards(t))}}}>{t}</a></li>
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="filter_sect justify-content-end" style={{ height: "36px" }}>
            <label>Total:</label>
            <span className="ms-1">{dashboardState.filteredDashboards?.length}</span>
          </div>
        </div>
      </div>
      <div className="row" >
        {dashboards.length > 0 ?
          dashboards.map((value: any, index) => {
            const enableDeleteShare = appState.isSuperAdminUser || appState.currentUser!.group_name && value.creatorName.toLowerCase() === appState.currentUser!.group_name.toLowerCase();
            return (
              <div className="col-xl-12" key={value.inventoryItemId}>
                <div className="app_info_block">
                  <div className="app_inf_title" onClick={() => handleDashboardClick(value)}
                  onKeyPress={(target) => { if (target.charCode === 13) {handleDashboardClick(value)}}}><h3><span>{value.name}</span></h3></div>
                  <div className="app_inf_action">
                    <ul onKeyPress={(target) => { if (target.charCode === 13) { <SharePopupComponent disabledClickFunction={showDisabledPopover} shareResponse={(response) => handleShareResponse(response)} componentName="Dashboard" dashboardData={value} enableDeleteShare={enableDeleteShare} userGroups={userGroups} />}}}>
                      <SharePopupComponent disabledClickFunction={showDisabledPopover} shareResponse={(response) => handleShareResponse(response)} componentName="Dashboard" dashboardData={value} enableDeleteShare={enableDeleteShare} userGroups={userGroups} />
                      <li>
                        <a className={!enableDeleteShare ? "disabled" : ""} onClick={() => !enableDeleteShare ? showDisabledPopover(false) : confirmDashboardDelete(value)}
                          onKeyPress={(target) => { if (target.charCode === 13) {!enableDeleteShare ? showDisabledPopover(false) : confirmDashboardDelete(value)}}}>
                          <img src={massCdnUrl + "assets/img/site-delete-icon.svg"} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          })
          :
          <div className="text-center">{(!loading || appState.status !== Status.Loading) && dashboardState.isDashboardLoaded && <h6 className="no_records">No Dashboards to show!</h6>}</div>
        }
      </div>
      {dashboards.length > 0 && <nav className="sitepagination mt-2">
        <ul className="pagination custom_pagination justify-content-center">
          <li className={`page-item ${dashboardState.dashboardPageNo === 1 ? "disabled" : ""}`}>
            <button disabled={dashboardState.dashboardPageNo === 1} className="page-link"
              onClick={() => dispatch(changeDashboardPage(dashboardState.dashboardPageNo - 1))}
              onKeyPress={(target) => { if (target.charCode === 13) {dispatch(changeDashboardPage(dashboardState.dashboardPageNo - 1))}}}>
              <span className="fa fa-angle-left"></span> Pre</button>
          </li>
          {
            [...Array(Math.ceil((dashboardState.filteredDashboards?.length || 0) / 15))].map((e, i) => {
              return <li key={"pageno" + i} className={`page-item ${dashboardState.dashboardPageNo === (i + 1) ? "active" : ""} `} aria-current="page">
                <a className="page-link" onClick={() => dispatch(changeDashboardPage(i + 1))}
                onKeyPress={(target) => { if (target.charCode === 13) {dispatch(changeDashboardPage(i + 1))}}}>{i + 1}</a>
              </li>
            })
          }
          <li className={`page-item ${Math.ceil(dashboardState.filteredDashboards?.length / 15) === dashboardState.dashboardPageNo ? "disabled" : ""}`}>
           <button disabled={Math.ceil(dashboardState.filteredDashboards?.length / 15) === dashboardState.dashboardPageNo} className="page-link"
              onClick={() => dispatch(changeDashboardPage(dashboardState.dashboardPageNo + 1))}
              onKeyPress={(target) => { if (target.charCode === 13) {dispatch(changeDashboardPage(dashboardState.dashboardPageNo + 1))}}}>Next
              <span className="fa fa-angle-right"></span></button>
          </li>
        </ul>
      </nav>
      }
    </section>
    {
      newDashboard.isVisible &&
      <Modal centered className="add-dashboard-modal" isOpen={newDashboard.isVisible} toggle={() => setNewDashboardData({ ...newDashboard, dashboardName: "", isVisible: false })} backdrop="static">
        <ModalHeader toggle={() => setNewDashboardData({ ...newDashboard, dashboardName: "", isVisible: false })}>
          Add Dashboard
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col col-lg-9 col-md-9">
              <div className="form-floating ct_form_field ct_form_icon_group">
                <input type="text" className="form-control" id="userId" placeholder={'UserID'} value={newDashboard.dashboardName} onChange={(e) => setNewDashboardData({ ...newDashboard, dashboardName: e.target.value })} />
                <label htmlFor="UserID">Dashboard Name</label>
              </div>
            </div>
            <div className="col-3 col-lg-3 col-md-3">
              <button className="btn btn-primary w-100 mt-4" disabled={newDashboard.dashboardName ? false : true} onClick={() => { setNewDashboardData({ ...newDashboard, dashboardName: "", isVisible: false }); addDashboard(newDashboard.dashboardName) }}
               onKeyPress={(target) => { if (target.charCode === 13) {setNewDashboardData({ ...newDashboard, dashboardName: "", isVisible: false }); addDashboard(newDashboard.dashboardName) }}}>Add</button>

            </div>
          </div>
        </ModalBody>
      </Modal>
    }
    {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
    {(loading || !dashboardState.isDashboardLoaded || (appState.status === Status.Loading)) && <Loader />}
  </>);
}


export default DashboardLibrary;
