/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState, useRef } from "react";
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { getRuleListAsync, readRuleState, resetRuleState, resetSelectedRuleValues, setSelectedRule } from "../../redux/reducers/ruleReducer";
import { bulkInsertBusinessRule, deleteBusinessRuleDetail, getInputOutputTemplates } from "../../services/businessRule.service";
import { setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Loader } from "../../helpers/loader";
import Breadcrumb from "../common/Breadcrumb";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from "react-router-dom";
import { readAppState } from "../../redux/reducers/appReducer";
import * as XLSX from "xlsx";
import GenericAgGrid, { getCurrentFilterValue } from "../common/GenericAgGrid";

const RuleMasterComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, primaryButtonClass: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any, secondaryButtonClass: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(false);
    const [csvArray, setCsvArray] = useState<any>(undefined);
    const [ruleId, setRuleId] = useState<any>();
    const [inputOutputTemplateDetails, setInputOutputTemplateDetails] = useState<any>(undefined);
    const ruleState = useAppSelector(readRuleState);
    const [uploadedDocs, setUploadedDocs] = useState(null as any);
    const [documentModal, setDocumentModal] = useState(false);
    const [inputOutputTemplateList, setInputOutputTemplateList] = useState<any>();
    const dispatch = useAppDispatch();
    const gridRef: any = useRef();
    const [csvFile, setCsvFile] = useState<any>();
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    const gridBusinessRuleRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'BUSINESS_RULE');

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            "screen_name": "Business Rule",
            "redirect_url": "/BusinessRule",
            "icon_url": "fas fa-people-arrows"
        }]));
        const listInputOutputTemplate = async () => {
            await getInputOutputTemplates().then(data => {
                if (data) {
                    setInputOutputTemplateDetails(data)

                    const output_template_fields = data.map((el) => {
                        return {
                            value: el.input_output_mapping_id,
                            label: el.input_output_template_name
                        }
                    })
                    setInputOutputTemplateList(output_template_fields)

                }
            })
                .catch(ex => {
                    console.log(ex);
                });
        }
        listInputOutputTemplate();
    }, [])

    useEffect(() => {
        dispatch(getRuleListAsync());
    }, [popup]);

    useEffect(() => {
        if (ruleState && ruleState.rule_list) {
            setRowData(ruleState.rule_list);
        }
    }, [ruleState])


    let customSort = ` (case when updated_date is null then  inserted_date else updated_date end) desc nulls last`;


    const onGridReady = async (params: any) => {
        try {
            var sort = [{
                colId: "rule_id",
                sort: "desc"
            }];
            params.columnApi.applyColumnState({
                state: sort,
                defaultState: { sort: null }
            })
            //  params.api.sizeColumnsToFit();
            setGridApi(params.api);
        }
        catch (ex) {
            console.log(ex);
        }

    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const csvColumns: any[] = [
        { headerName: 'Rule View', field: 'Rule View', headerTooltip: 'Rule View' },
        { headerName: 'Rule Criteria', field: 'Rule Criteria', headerTooltip: 'Rule Criteria' },
        { headerName: 'Rule Message', field: 'Rule Message', headerTooltip: 'Rule Message' },
        { headerName: 'Rule Row Message', field: 'Rule Row Message', headerTooltip: 'Rule Row Message' },
        { headerName: 'Rule Description', field: 'Rule Description', flex: 1, headerTooltip: 'Rule Description' },
        { headerName: 'Rule Key Column', field: 'Rule Key Column', flex: 1, headerTooltip: 'Rule Key Column' }
    ]

    const columns: any[] = [
        { headerName: 'Rule Id', field: 'rule_id', hide: true },
        { headerName: 'Rule View', field: 'rule_view', minWidth: 250, maxWidth: 300, headerTooltip: 'Rule View' },
        { headerName: 'File Name Format', field: 'template_filename_format', headerTooltip: 'File Name Format', minWidth: 170, maxWidth: 300 },
        { headerName: 'Rule Criteria', field: 'rule_criteria', headerTooltip: 'Rule Criteria', minWidth: 150, maxWidth: 300 },
        { headerName: 'Rule Message', field: 'rule_message', headerTooltip: 'Rule Message', minWidth: 150, maxWidth: 300 },
        { headerName: 'Rule Row Message', field: 'rule_row_message', headerTooltip: 'Rule Row Message', minWidth: 250, maxWidth: 300 },
        { headerName: 'Rule Description', field: 'rule_description', flex: 1, headerTooltip: 'Rule Description', minWidth: 300, maxWidth: 300 },
        {
            headerName: 'Action', pinned: 'right', field: 'rule_name', filter: false, suppressSizeToFit: true, width: 120, cellRendererFramework: (params) => <div className="grid-action">
                <button className="edit-icon_link" onClick={() => { onEditClick(params.data) }}><span className="fas fa-edit"></span></button>
                <button onClick={() => { onDeleteClick(params.data.rule_id) }} className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
            </div>
        }]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }
    const onDeleteClick = async (rule_id) => {
        setPopup({
            ...popup,
            visible: true,
            message: "Please confirm that you would like to delete this rule.",
            type: ModalType.ActionDialog, variant: VariantType.Success,
            toggle: () => setPopup({ ...defaultPopup }),
            primaryButtonAction: () => removeRule(rule_id),
            primaryButtonName: "Delete",
            primaryButtonClass: "btn-danger",
            secondaryButtonAction: () => setPopup({ ...defaultPopup }),
            secondaryButtonName: "Cancel",
        });
        setRuleId(rule_id);
    }
    const removeRule = async (rule_id: any) => {
        try {
            setPopup(defaultPopup);
            if (rule_id) {
                await deleteBusinessRuleDetail(rule_id);
                setPopup({ ...popup, visible: true, message: "This rule has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                gridBusinessRuleRef.current.api.refreshServerSideStore();
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const onEditClick = (data) => {
        preserveFilter(data)
        navigation("/BusinessRuleManagement");
    }

    const onFileSelect = (e: any) => {
        let fileUploaded: any = undefined;
        if (e.target.files) {
            for (var fileObject in e.target.files) {
                if (typeof e.target.files[fileObject] === "object") {
                    const file = (e.target.files[fileObject]);
                    const fileBlob = new Blob([file], { type: file.type });
                    fileUploaded = { accessUrl: URL.createObjectURL(fileBlob), fileName: file.name.replaceAll(" ", "_"), fileType: file.type };
                }
            }
            setUploadedDocs(fileUploaded);
        }
    }

    const submit = async () => {
        const file = csvFile[0];
        const reader = new FileReader();
        const fileFormat = file.name.split(".");
        if (fileFormat[fileFormat.length - 1] === "xlsx") {
            await reader.readAsArrayBuffer(file)
            reader.onload = function (e: any) {
                var data = e.target.result;
                var workbook = XLSX.read(data, {
                    type: 'buffer'
                });
                const wsName = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsName];
                const jsonData: any = XLSX.utils.sheet_to_json(ws)
                setCsvArray(jsonData);
                setDocumentModal(false);
                setUploadedDocs(null);

            }

            reader.readAsText(file);
        } else if (fileFormat[fileFormat.length - 1] === "csv") {
            reader.onload = function (e: any) {
                const text = e.target.result;
                processCSV(text)
            }
            reader.readAsText(file);
        }
        else {
            setPopup({ ...popup, visible: true, message: "Please upload the file with CSV or XLSX format!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }

        setDocumentModal(false);
        setUploadedDocs(null);
    }

    const processCSV = (str, delim = ',') => {
        let headers = str.slice(0, str.indexOf('\n')).split(delim);
        headers[headers.length - 1] = headers[headers.length - 1].replace('\r', '');
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        const newArray = rows.map(row => {
            const values = row.replace('\r', '').split(delim)
            const eachObject = headers.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })

        newArray.splice(-1)
        setCsvArray(newArray)
    }
    const onSaveClick = async () => {
        setLoader(true)
        var strData = ""
        var isValidQuery = true;
        csvArray.forEach((item, index) => {
            item['rule_view'] = item['Rule View'];
            item['rule_criteria'] = item['Rule Criteria'];
            item['rule_message'] = item['Rule Message'];
            item['rule_row_message'] = item['Rule Row Message'];
            item['rule_description'] = item['Rule Description'];
            item['rule_key_column'] = item['Rule Key Column'];
            item['updated_date'] = new Date().toUTCString();
            if (item && item.rule_view && item.rule_criteria && item.rule_message && item.rule_row_message && item.rule_description && item.rule_key_column)
                strData = strData + `('${item.rule_view.replaceAll(`"`, ``).replaceAll(`'`, `''`)}','${item.rule_criteria.replaceAll(`"`, ``).replaceAll(`'`, `''`)}','${item.rule_key_column}','${item.rule_message.replaceAll(`"`, ``).replaceAll(`'`, `''`)}','${item.rule_row_message.replaceAll(`"`, ``).replaceAll(`'`, `''`)}','${item.rule_description.replaceAll(`"`, ``).replaceAll(`'`, `''`)}',true,'${item.updated_date}')${csvArray.length - 1 !== index ? ',' : ''}`
            else {
                setLoader(false);
                setPopup({ ...popup, visible: true, message: "Please upload file with all the required columns!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                isValidQuery = false;
            }
        })
        if (isValidQuery) {
            const responseData = await bulkInsertBusinessRule(strData, csvArray.length)
            if (responseData) {
                setLoader(false);
                setCsvArray(undefined);
                setPopup({ ...popup, visible: true, message: "Import Successful", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); } });
            }
            else {
                setLoader(false);
                setPopup({ ...popup, visible: true, message: "Something went wrong!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            }
        }
    }
    const onCancelClick = () => {
        setUploadedDocs(null);
        setCsvArray(undefined);
        setDocumentModal(false);
        dispatch(resetRuleState());

    }

    const onRemoveFile = () => {
        setUploadedDocs(null);
    }

    const changeHandle = async (value, field) => {
        let updatedRule: any = { ...ruleState.rule };
        updatedRule[field] = value;
        if (field === "input_output_mapping_id") {
            const ruleData = inputOutputTemplateDetails.filter(el => el.input_output_mapping_id === value)
            if (ruleData.length > 0) {
                dispatch(setSelectedRule({ ...updatedRule, rule_view: ruleData[0].rule_view, rule_key_column: ruleData[0].rule_key_column }))
            }
            else {
                dispatch(resetSelectedRuleValues());
            }
        }
        else {
            dispatch(setSelectedRule({ ...updatedRule }))
        }
    }

    const preserveFilter = (params: any) => {
        let filterValue = getCurrentFilterValue(gridBusinessRuleRef);
        if (params == true) {
            dispatch(setSelectedRule({ is_new: true, is_edit: true, current_page: filterValue.CurrentPage, saved_filter: filterValue.FilterModel, saved_sort: filterValue.SortState }));
        } else {
            dispatch(setSelectedRule({ ...params, current_page: filterValue.CurrentPage, saved_filter: filterValue.FilterModel, saved_sort: filterValue.SortState, is_new: false, is_edit: true }));
        }
    }

    const afterGridLoad = () => {
        if ((!ruleState.rule?.is_edit) && (ruleState.rule?.saved_sort || ruleState.rule?.saved_filter)) {
            gridBusinessRuleRef.current.columnApi.applyColumnState({
                state: ruleState.rule.saved_sort,
            });
            gridBusinessRuleRef.current.api.setFilterModel(ruleState.rule.saved_filter);
            dispatch(setSelectedRule({ ...ruleState?.rule, is_edit: true, current_page: 0 }));
        } else {
            dispatch(setSelectedRule({ ...ruleState?.rule, saved_sort: null, saved_filter: null, current_page: 0 }));
        }
    }
    return (
        <>
            <div className="pagetitle">
                <div className="row align-items-center">
                    <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                        <Breadcrumb />
                    </div>
                    <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                        <a className="btn btn-primary" onClick={() => setDocumentModal(!documentModal)}>
                            <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`fa fa-file-import`} />
                            Import Rule
                        </a>
                        <a className="btn btn-primary" style={{ marginRight: "10px" }} onClick={() => { preserveFilter(true); navigation("/BusinessRuleManagement"); }}>
                            <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} /> Add Rule
                        </a>
                    </div>
                </div>
            </div>
            <section className="section dashboard">
                {loader && <Loader />}
                {csvArray && <p className="disablesText">Please preview the below rules detail and click on confirm button to upload the rules. </p>}
                {popup.visible && <CustomPopupComponent message={popup.message} primaryButtonName={popup.primaryButtonName} primaryButtonClass={popup.primaryButtonClass} secondaryButtonAction={popup.secondaryButtonAction} secondaryButtonName={popup.secondaryButtonName} secondaryButtonClass={popup.secondaryButtonClass} variant={popup.variant} isOpen={popup.visible} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} />}

                {!csvArray ? !!gridDetails && <div>
                    <GenericAgGrid
                        gridColumns={columns}
                        gridDefaultColDef={defaultColDef}
                        gridDetails={gridDetails}
                        rowHeight={40}
                        rowModelType={'serverSide'}
                        modules={[ServerSideRowModelModule]}
                        parentGridRef={gridBusinessRuleRef}
                        sizeColumnsToFit={true}
                        afterGridLoad={afterGridLoad}
                        customStaticSort={customSort}
                        currentPage={ruleState.rule?.current_page}
                    ></GenericAgGrid> </div> :
                    <div className="ag-theme-alpine" style={{ height: '72vh', width: '100%' }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={csvColumns}
                            rowData={csvArray}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            rowSelection={'single'}
                            paginationPageSize={10}
                            headerHeight={32}
                            onGridReady={onGridReady}
                            onFilterChanged={onFilterChanged}
                            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                            modules={[ClientSideRowModelModule]}>
                        </AgGridReact>

                    </div>}


                {csvArray && <div className="row justify-content-md-center" style={{ marginTop: "15px" }}>
                    <div className="col-lg-2 col "><button className="btn btn-block btn-lg blue-outline-btn text-center btn-block mb15 " type="submit" onClick={() => { onSaveClick() }}>
                        Confirm</button></div>
                    <div className="col-lg-2 col"><button className="btn btn-block btn-lg btn-primary text-center btn-block mb15" type="submit" onClick={() => { onCancelClick() }}>
                        Cancel</button></div>
                </div>}



                <Modal className="upload-file-modal" isOpen={documentModal} centered toggle={() => setDocumentModal(!documentModal)}>
                    <ModalHeader toggle={() => { onCancelClick() }}>Upload File</ModalHeader>
                    <ModalBody>
                        <div>
                            <input id="tapeFile" style={{ display: "none" }} type="file" name="files" accept=".txt, .csv, .xlsx" onClick={(event: any) => event.target.value = null} onChange={(e) => { e && setCsvFile(e.target.files); onFileSelect(e) }} />
                            <label className="file-select" htmlFor="tapeFile">
                                <img src={massCdnUrl + "assets/img/site-file-upload.svg"} style={{ width: "45px" }} />
                                <span style={{ display: "block", color: "var(--secondary-color)" }}>{"Click here to browse the file."}</span>
                            </label>
                            <label className="selected-file-name">
                                {uploadedDocs && <>
                                    <span style={{ display: "block", color: "var(--primary-color)" }} >{uploadedDocs.fileName}</span>
                                    <span className="selected-file-remove" onClick={() => { onRemoveFile() }}>
                                        <i className="fa fa-remove" style={{ verticalAlign: "middle" }}></i>
                                    </span>
                                </>
                                }
                            </label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="row ">
                            <div className="col-lg-6 col">
                                <button onClick={(e) => { submit() }} className="btn btn-primary btn-sm" disabled={!uploadedDocs}>Upload </button>
                            </div>
                            <div className="col-lg-6 col">
                                <button onClick={() => { onCancelClick() }} className="btn btn-outline-primary btn-sm" >Cancel</button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </section>
        </>
    )
}

export default memo(RuleMasterComponent);