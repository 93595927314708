export const progressBarStep = [

    {
        "label": "FILE RECEIVE",
        "value": 1,

    },
    {
        "label": "DATA TYPE CHECK",
        "value": 2,

    },
   
    {
        "label": "DATA STAGE",
        "value": 3,

    },
    {
        "label": "PRE-LOAD DATA VALIDATION",
        "value": 4,

    },
    {
        "label": "DATA LOAD",
        "value": 5,

    },
    {
        "label": "DATA PUBLISH",
        "value": 6,

    },
    {
        "label": "POST-LOAD DATA VALIDATION",
        "value": 7,

    },

]