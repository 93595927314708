import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import Breadcrumb from "../common/Breadcrumb";
import Email from "./Email";
import Password from "./Password";
import PhoneNumber from "./PhoneNumber";
import UserId from "./UserId";
import Profile from "./Profile";

const MyProfileAndSettings = () => {
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state: RootState) => state.app.currentUser);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "My Profile And Settings",
            redirect_url: "/MyProfileAndSettings",
            icon_url: "fad fa-user"
        }]));
    }, []);


    return (<>
        <div className="pagetitle"><Breadcrumb /></div>

        <section className="section dashboard">
            <div className="block_sect accordion" id="myprofile">
                <div className="row mb-5">
                    <div className="col-lg-9 col-md-7" >
                        <div className="d-flex justify-content-around mb-2" 
                        // style={{border:"1px solid blue"}}
                        >
                            <div className="l_info_block">
                                <label>First Name</label>
                                <span>{userState?.first_name}</span>
                            </div>
                            <div className="l_info_block">
                                <label>Last Name</label>
                                <span>{userState?.last_name}</span>
                            </div>
                            <div className="l_info_block">
                                <label>User ID</label>
                                <span>{userState?.maas_user_id}</span>
                            </div>
                        </div>
                        <div className="row">
                            <PhoneNumber user={userState} />
                        </div>
                        <div className="row mb-1">
                            <Email user={userState} />
                        </div>
                        <div className="row">
                            <Password user={userState} />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-5 mb-2 mt-4">
                        <Profile user={userState} />
                    </div>
                </div>
                {/* <UserId user={userState} /> */}
                
            </div>
        </section>
    </>);
}

export default MyProfileAndSettings;