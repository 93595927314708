/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect,useState,useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readAppState, setBreadcrumbLocation, setLogiToken } from "../../redux/reducers/appReducer";
import { getDashboardToken, getLogiSourceQuery } from "../../services/dashboard.service";
import { getReportsAsync } from "../../redux/reducers/reportReducer";
import userGroups from "../../constants/userGroups";
import { Loader } from "../../helpers/loader";
import GenericReportView from "../common/GenericReportView";
import { clearLoanTabs, clearSearchedLoans, setActiveTab } from "../../redux/reducers/loanReducer";
import { Outlet, useLocation, useNavigate } from "react-router";
import { dashNavCustomStyle } from "../common/CustomStyles";
import { getGridVerticaData } from "../../services/genericAgGrid.service";
import Breadcrumb from "../common/Breadcrumb";
import { getFileData } from "../../constants/FilePathConstant";
import { getPresignedUrlForService } from "../../services/upload.service";
import DocumentPreviewComponent from "../common/DocumentPreviewComponent";
import { setDocInformation } from "../../redux/reducers/documentReducer";

const AllDocumentsGrid = (props: any) => {
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();    
    const [visualId, setVisualId] = useState(null) as any;
    const appStateRef = useRef(appState);
    const breadcrumbs = useRef(appState.breadcrumbLocation);
    const location = useLocation();
    const navigation = useNavigate();
    const logiEmbedManager = useRef(undefined as any);
    const componentInstanceId = useRef(undefined as any);
    const [isLoading, setLoading] = useState(true);
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const [file, setFile] = useState(null as any);
    let count;

    useEffect(() => {
        appStateRef.current = appState;
    }, [appState])

    useEffect(() => {
        breadcrumbs.current = appState.breadcrumbLocation;
    }, [appState.breadcrumbLocation])

    useEffect(() => {        
        getVisualList();   
              
    }, []) 

    useEffect(() => {
        createVisualComponent();        
    }, [visualId])    

    const getVisualList = async () => {
        try {
            const token = await getMasterToken();
            if (token) {
                dispatch(getReportsAsync({ token: token, currentUser: appState.currentUser, logiConfig: appState.logiConfiguration, userGroups })).then((response) => {
                    if (response && response.payload) {                                                
                        const reportVisualByName = response.payload.filter(x => x.visualName == "All Loan Documents")[0];                        
                        setVisualId(reportVisualByName?.id ? reportVisualByName.id : '');
                    }
                });
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const getMasterToken = async () => {
        if (appState.logiToken) {
            return appState.logiToken;
        }
        const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
            console.log(r)
            return r
        })
        if (token) {
            dispatch(setLogiToken(token));
        }
        return token;
    };

    const handleTableClick = e => {
        try {
            const target = e.target;
            const breadcrumbLocation = [...breadcrumbs.current];
            if (target.classList.contains('ag-cell')) {
                let selectedColumn=target.getAttribute('col-id')
                if(selectedColumn=='loan_number'){
                    
                    let loan_id = target.parentNode.querySelector('[col-id="loan_number"]') ? target.parentNode.querySelector('[col-id="loan_number"]').innerText : '';
                    if (loan_id) {
                        let loanDetailPath = screens.find(x => x.redirect_url === '/LoanDetail')
                        if (loanDetailPath) {
                            loanDetailPath = { ...loanDetailPath, redirect_url: "/AllDocuments/LoanDetail" }
                            breadcrumbLocation.push(loanDetailPath);
                        }
                        dispatch(setActiveTab(loan_id));
                        navigation(`/AllDocuments/LoanDetail`, { state: { loan_id: loan_id } });
                        dispatch(setBreadcrumbLocation(breadcrumbLocation));
                    }
                }
                else if(selectedColumn=='doc_name'){
                    let doc_name = target.parentNode.querySelector('[col-id="doc_name"]') ? target.parentNode.querySelector('[col-id="doc_name"]').innerText : '';
                    if (doc_name) {
                        let url = target.parentNode.querySelector('[col-id="s3_bucket_url"]') ? target.parentNode.querySelector('[col-id="s3_bucket_url"]').innerText : '';
                        let fileObj={DOC_NAME:doc_name,S3_BUCKET_URL:url}
                        getFilePath(fileObj).then((response) => {
                            let loanDetailPath = screens.find(x => x.redirect_url === '/DocumentPreviewComponent')
                            if (loanDetailPath) {                                
                                loanDetailPath = { ...loanDetailPath, redirect_url: "/AllDocuments/DocumentPreviewComponent" }                                
                                breadcrumbLocation.push(loanDetailPath);
                                navigation(`/AllDocuments/DocumentPreviewComponent`);
                                dispatch(setBreadcrumbLocation(breadcrumbLocation));
                            }
                        });
                    }
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const initClicks = () => {
        const style = document.createElement('style');
        style.setAttribute("name", "user_added");
        style.innerHTML = `.v${visualId} [col-id="loan_number"].ag-cell  {${dashNavCustomStyle}}`;        
        document.getElementsByTagName('head')[0].appendChild(style);
        const style_doc = document.createElement('style');
        style_doc.setAttribute("name", "user_added");        
        document.getElementsByTagName('head')[0].appendChild(style_doc);
        style_doc.innerHTML = `.v${visualId} [col-id="doc_name"].ag-cell  {${dashNavCustomStyle}}`;
        const allColumnsClickableTable = document.querySelectorAll(`[ref="eBodyViewport"].ag-body-viewport`);
        if (allColumnsClickableTable) {
            allColumnsClickableTable.forEach(x => {
                x?.addEventListener('click', e => handleTableClick(e));
            })
        }
    }

    const getFilePath = async (file: any) => {
        const { bucket_name, object_key } = getFileData(file);
        let fileType = file?.DOC_NAME ? file.DOC_NAME.substring(file.DOC_NAME.lastIndexOf(".") + 1) : "";

        try {
            let result = await getPresignedUrlForService(bucket_name, object_key, "download"); if (result) {
                const url = JSON.parse(JSON.parse(result).body).url;                
                dispatch(setDocInformation({...file,
                            DOC_NAME: file.DOC_NAME,
                            FILE_PATH: url,
                            DOC_TYPE: fileType
                        }))
                       
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    

    const createVisualComponent = () => {
        
        if(visualId){            
            (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then((embedManager: any) => {
                logiEmbedManager.current = embedManager;
                setLoading(false);
                getGridCount();
                if (embedManager && componentInstanceId.current) {
                    logiEmbedManager.current.removeComponent(componentInstanceId.current);
                }                
                embedManager.createComponent('visual-builder', {
                    "visualId": visualId,
                    "theme": props.theme,
                    "header": {
                        'visible': props.showEditor,
                        'showTitle': props.showTitle,
                        'showActions': props.showActions
                    },
                    "interactivityOverrides": props.interactivityOverrides

                }).then((dash: any) => {
                    componentInstanceId.current = dash.componentInstanceId;
                    dash.render(document.getElementById('ReportView'), { width: props.width, height: props.height });
                    dash.addEventListener('composer-visual-builder-ready', e => {
                        initClicks();
                    });
                    dash.addEventListener("composer-visual-rendered", (e) => {
                        const countData=document.getElementById("ag-9-row-count")?.innerHTML                    
                        if(countData === 'More'){                         
                            document.getElementById("ag-9-row-count")!.innerHTML = count;
                        }                    
                        const pageSize=document.getElementById("ag-9-last-row")!.innerText.toString()                     
                        let pageCount=count < parseInt(pageSize) ? 1 : Math.ceil(count / parseInt(pageSize));         
                        const pageCountText=document.getElementById("ag-9-of-page-number")?.innerHTML
                        if(pageCountText === 'More'){                         
                            document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                        }  

                        let nextbtn=document.querySelector('.ag-icon-next');
                        if(!!nextbtn){
                            nextbtn.addEventListener('click', function handleClick(e) {
                                setTimeout(function(){ 
                                    document.getElementById("ag-9-row-count")!.innerHTML=count.toString();
                                    document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                                }, 50);
                            });
                        }
                        let prevbtn=document.querySelector('.ag-icon-previous');
                        if(!!prevbtn){
                            prevbtn.addEventListener('click', function handleClick(e) {
                                setTimeout(function(){ 
                                    document.getElementById("ag-9-row-count")!.innerHTML=count.toString();
                                    document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                                }, 50);
                            });
                        }
                        const id = document.getElementById("refreshButton")
                        if (id === null) {
                            let divObj = document.querySelector('.bp3-button-group');
                            var refreshButton;
                            let refreshSpan1 = document.createElement("span");
                            refreshSpan1.className = "bp3-popover-wrapper";
                            refreshSpan1.id = "refreshspan1"

                            let refreshSpan2 = document.createElement("span");
                            refreshSpan2.id = "refreshspan2"
                            refreshSpan2.className = "bp3-popover-target";
                            refreshButton = document.createElement("button");
                            refreshButton.id = "refreshButton"
                            refreshButton.className = "bp3-button bp3-minimal bp3-small  css-cp19h0";

                            let refreshI = document.createElement("i");
                            refreshI.className = "fas fa-sync";
                            refreshI.style.color = '#5c7080'
                            refreshButton.addEventListener('click', function handleClick(e) {
                                embedManager.refresh();
                            });
                            divObj !== null && divObj.insertBefore(refreshSpan1, divObj.firstChild)
                            document.getElementById("refreshspan1")?.appendChild(refreshSpan2);
                            document.getElementById("refreshspan2")?.appendChild(refreshButton);
                            document.getElementById("refreshButton")?.appendChild(refreshI);
                        }
                    });
                    setLoading(false);
                    return 1;
                });
            })
                .catch((e: any) => { console.log(`Logi Error: ${e}`); })
        }
    }

    const getGridCount = async () => {
        const response = await getGridVerticaData({
            view_name: "maas.VW_GRID_DOCUMENTS",
            where: "",
            order_by: "",
            offset: 20,
            row_fetch: 0
        })
        count = response.length > 0 ? response[0][0].total_rows : 0
    }

    return (<>
        
        
        <div className="pagetitle"> <Breadcrumb /> </div>
                     
                <div className="container-fluid position-relative">
                    <div className="content_section">
                        <div className={`${location.pathname !== "/AllDocuments" ? 'hide' : 'container min_height_dashboard'}`}>
                            {isLoading && <Loader />}
                            <div className={`v${visualId}`} id="ReportView"></div>
                        </div>
                        <Outlet />
                    </div>
                </div>
        
    </>)
}

AllDocumentsGrid.defaultProps = {
    theme: "modern",
    showEditor: true,
    showActions: true,
    showTitle: false,
    interactivityOverrides: {
        "visualSettings": {
            "ACTIONS_ACTION": true,
            "RULERS": true,
            "ZOOM_ACTION": false,
            "FILTER_ACTION": true,
            "COLORS": true,
            "METRICS": true,
            "ACTIONS": true,
            "TREND_ACTION": false,
            "VISUAL_STYLE": true,
            "KEYSET_ACTION": false,
            "KEYSET": false,
            "COPY": true,
            "SETTINGS": true,
            "EXPORT": true,
            "TIMEBAR_PANEL": true,
            "DETAILS_ACTION": true,
            "MAXIMIZE": true,
            "LINK_ACTION": true,
            "FILTER": true,
            "REMOVE": true,
            "GROUPING": true,
            "RENAME": true,
            "SORT": true,
            "TIMEBAR_FIELD": true
        }
    }
}

export default AllDocumentsGrid;