/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from "@ag-grid-community/react";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import formatDateTable from "../../constants/dateFormatTable";
import { getGridFilters } from "../../constants/gridHelper";
import {
  readAppState, setBreadcrumbLocation
} from "../../redux/reducers/appReducer";
import { setStep,readAssignmentClaimState } from "../../redux/reducers/assignmentClaimReducer";
import { readDashboardState } from "../../redux/reducers/dashboardReducer";
import { addLoanTab } from "../../redux/reducers/loanReducer";
import { getLogiSourceQuery } from "../../services/dashboard.service";
import { getAssignmentsByFilter } from "../../services/loanSearch.services";

const linkTest = ({ value }) => {
  return <a style={{ textDecoration: "underline", color: "var(--url-color)" }}>{value}</a>;
};

const AssignmentClaimsLoanSearch = (props: any) => {
  const appState = useAppSelector(readAppState);
  const dashboardState = useAppSelector(readDashboardState);
  const assignmentClaimState = useAppSelector(readAssignmentClaimState);
  const breadcrumbs = useRef(appState.breadcrumbLocation);
  const gridApi = useRef(null as any);
  const dispatch = useAppDispatch();
  const dashboardDetails = useRef({ appliedFilters: dashboardState.appliedFilters, currentSource: dashboardState.currentSource });

  const getLoans = async (filter: any) => {
    let userGroups = appState.userGroups ? appState.userGroups : [];
    let currentUserGroup = userGroups.find(g => g.group_id === appState.currentUser?.group_id)
    let sourceQuery = await getLogiSourceQuery(appState.logiToken, dashboardDetails.current.currentSource || "")
    let data = await getAssignmentsByFilter(filter, currentUserGroup?.logi_user_id, sourceQuery.replace("${User.UserId}", `'${currentUserGroup?.logi_user_id}'`), "MAAS.VW_ASSIGNMENT_CLAIM_LOANS");
    if (data) {
      let loans = data;
      if (!loans || loans.length === 0) {
        gridApi.current.showNoRowsOverlay();
      }
      
      return {
        success: true,
        rows: loans.length ? loans[1] : [],
        lastRow: !loans.length ? 0 : Number(loans[0][0].total_rows)
      };
    } else {
      gridApi.current.showNoRowsOverlay();
      return { success: true, rows: [], lastRow: 0 };
    }
  };

  useEffect(() => {
    dashboardDetails.current = ({ appliedFilters: dashboardState.appliedFilters, currentSource: dashboardState.currentSource })
    if (gridApi && gridApi.current) {
      gridApi.current.setFilterModel(null);
      gridApi.current?.columnModel?.columnApi?.applyColumnState({
        defaultState: { sort: null },
      });
      gridApi.current.refreshServerSideStore({ purge: true });
    }
  }, [dashboardState.appliedFilters, dashboardState.currentSource]);

  useEffect(() => {
    if (gridApi && gridApi.current) {
      setTimeout(() => {
        gridApi.current.sizeColumnsToFit();
      }, 500)
    }
  }, [appState.isNavMenuOpen])

  useEffect(() => {
    dispatch(
      setBreadcrumbLocation([
        {
          screen_name: "Loan List",
          redirect_url: "/AssignmentClaims",
          icon_url: "fad fa-user",
        },
      ])
    );
  }, []);

  useEffect(() => {
    breadcrumbs.current = appState.breadcrumbLocation;
  }, [appState.breadcrumbLocation]);

  const dataSource = {
    getRows: (params) => {
      gridApi.current.hideOverlay();
      let filterData: any[] = dashboardDetails.current.appliedFilters ? [...dashboardDetails.current.appliedFilters] : [];
      const agGridFilterModel = params.request.filterModel;
      if (agGridFilterModel) {
        filterData = getGridFilters(agGridFilterModel, filterData);
      }
      getLoans({
        filter: filterData,
        skip: params.request.startRow,
        row_fetch: 100,
        sort_order: params.request.sortModel && params.request.sortModel.length > 0 ? params.request.sortModel[params.request.sortModel.length - 1].sort : null,
        sort_column: params.request.sortModel && params.request.sortModel.length > 0 ? params.request.sortModel[params.request.sortModel.length - 1].colId : null
      }).then((response) => {
        const element = document.getElementById("collapseExample1");
        if (element) {
          element.classList.remove("show");
        }
        if (response.success) {
          if (response?.rows && response?.rows?.length === 0) {
            gridApi.current.showNoRowsOverlay();
          }
          params.successCallback(response.rows, response.lastRow);
        } else {
          params.fail();
        }
      });
    },
  };

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
    params.api.setServerSideDatasource({ ...dataSource });
    
    if (params.api) {
      
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 500)
    }
  };

  const defaultColDef = {
    sortable: true,
    resizable: true
  };

  const currencyFormatter = (params: any) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(params.value);
  };

  const handleRowClick = (e: any) => {
  if(e.data?.loan_number){
    dispatch(setStep(3))
    dispatch(setBreadcrumbLocation([{
      screen_name: "Loan Details",
      redirect_url: "/AssignmentClaims",
      icon_url: "fad fa-user"
    }]))
    dispatch(addLoanTab(e.data.loan_number));
  };
  }
  return (
    <>
      <section className="section dashboard">
        <div className={"block_sect"} >
          <div style={{ height: "72vh", marginTop: "1em", padding : '1px'}} className={`ag-theme-alpine`}>
            <h6 style={{marginBottom:"12px",marginTop:"-23px"}}>{assignmentClaimState && assignmentClaimState?.heading}</h6>
            <AgGridReact
              columnDefs={[
                {
                  field: "loan_number",
                  headerName: "Loan Number",
                  tooltipField: "loan_number",
                  headerTooltip: "Loan number",
                  cellRendererFramework: linkTest,
                  sortable: true,
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "maximum_claim_amount",
                  valueFormatter: currencyFormatter,
                  type: "rightAligned",
                  headerName: "Amount to be Claim",
                  tooltipField: "maximum_claim_amount",
                  headerTooltip: "Amount to be Claim",
                  width: 200,
                  filter: 'agNumberColumnFilter'
                },
                {
                  field: "current_loan_balance",
                  valueFormatter: currencyFormatter,
                  type: "rightAligned",
                  headerName: "Loan Balance",
                  tooltipField: "current_loan_balance",
                  headerTooltip: "Loan Balance",
                  width: 200,
                  filter: 'agNumberColumnFilter'
                },
                {
                  field: "current_max_claim_percentage",
                  headerName: "MCA",
                  tooltipField: "current_max_claim_percentage",
                  headerTooltip: "MCA",
                  width: 200,
                  filter: 'agNumberColumnFilter'
                },
                {
                  field: "mca_98_percentage_date",
                  headerName: "98% MCA Date",
                  tooltipField: "mca_98_percentage_date",
                  headerTooltip: "98% MCA Date",                  
                  valueFormatter: formatDateTable,
                  width: 200,
                  filter: 'agDateColumnFilter'
                },
                {
                  field: "second_note",
                  headerName: "Second Note",
                  tooltipField: "second_note",
                  headerTooltip: "Second Note",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "first_note",
                  headerName: "First Note",
                  tooltipField: "first_note",
                  headerTooltip: "First Note",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "hud_one",
                  headerName: "HUD1",
                  tooltipField: "hud_one",
                  headerTooltip: "HUD1",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "recorded_2nd_mortgage",
                  headerName: "Recorded 2nd Mortgage",
                  tooltipField: "recorded_2nd_mortgage",
                  headerTooltip: "Recorded 2nd Mortgage",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "flood_insurance",
                  headerName: "Flood Insurance",
                  tooltipField: "flood_insurance",
                  headerTooltip: "Flood Insurance",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "initial_net_claim_amount",
                  headerName: "Issues",
                  tooltipField: "initial_net_claim_amount",
                  headerTooltip: "Issues",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
                {
                  field: "on_hold_reason",
                  headerName: "Assignment On Hold",
                  tooltipField: "on_hold_reason",
                  headerTooltip: "Assignment On Hold",
                  width: 200,
                  filter: 'agTextColumnFilter'
                },
              ]}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              cacheBlockSize={100}
              headerHeight={35}
              rowHeight={40}
              overlayNoRowsTemplate={
                '<span class="ag-overlay-loading-center">No records were found!</span>'
              }
              overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
              onGridReady={onGridReady}
              onRowClicked={(e) => handleRowClick(e)}
              rowModelType={"serverSide"}
              serverSideStoreType={"partial"}
              modules={[ServerSideRowModelModule, RowGroupingModule]}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default AssignmentClaimsLoanSearch;
