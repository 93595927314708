import { useEffect, useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { Status } from "../../../constants/commonConstants";
import { formatAmount } from "../../../helpers/formatAmount";
import { Loader } from "../../../helpers/loader";
import { readAppState } from "../../../redux/reducers/appReducer";
import { getPaymentAndBalanceAsync, readLoanState } from "../../../redux/reducers/loanReducer";

const PaymentInformation = (props: any) => {
    const dispatch = useAppDispatch();
    const paymentAndBalance = useAppSelector((state: RootState) => state.loan.paymentAndBalance || null);
    const [pieChartData, setPieChartData] = useState<any[]>([]);
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector(readLoanState);
    const mobileQuery = window.matchMedia('(max-width:540px)');
    useEffect(() => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let data = {
            loanId: props.loan_id
        }
        dispatch(getPaymentAndBalanceAsync(data));
    }, [])
    useEffect(() => {
        if (paymentAndBalance) {
            if (paymentAndBalance?.current_principal_balance || paymentAndBalance?.current_interest_balance || paymentAndBalance?.current_mip_balance || paymentAndBalance?.current_service_fee_set_aside_amount) {
                let data = [
                    { name: 'Current Principal Balance', value: paymentAndBalance?.current_principal_balance ? Number(paymentAndBalance?.current_principal_balance) : 0, fill: "#0082b5" },
                    { name: 'Interest Balance', value: paymentAndBalance?.current_interest_balance ? Number(paymentAndBalance?.current_interest_balance) : 0, fill: "#008db6" },
                    { name: 'Current MIP Balance', value: paymentAndBalance?.current_mip_balance ? Number(paymentAndBalance?.current_mip_balance) : 0, fill: "#43a79b" },
                    {
                        name: 'Servicer Fee Set Aside', value: paymentAndBalance?.current_service_fee_set_aside_balance
                            ? Number(paymentAndBalance?.current_service_fee_set_aside_balance
                            ) : 0, fill: "#7eb184"
                    },
                ];
                setPieChartData(data);
            }
            else {
                let data01 = [
                    { name: 'Current Principal Balance', value: 0, fill: "#0082b5" },
                    { name: 'Interest Balance', value: 0, fill: "#008db6" },
                    { name: 'Current MIP Balance', value: 0, fill: "#43a79b" },
                    { name: 'Servicer Fee Set Aside', value: 0, fill: "#7eb184" },
                    { name: '', value: 1, fill: "white" },
                ];
                setPieChartData(data01);
            }
        }

    }, [paymentAndBalance]);
    const renderColorfulLegendText = (value: string, entry: any) => {
        return (
            <>
                <span style={{ color: "#999", paddingLeft: 10, fontSize: mobileQuery.matches ? "13px" : "14px" }}>
                    {value}
                </span>{ }
                <span style={{ fontWeight: "bold", fontSize: mobileQuery.matches ? "13px" : "14px" }}>{value === "No Data Available" ? "" : `  -  $${entry.payload?.value}`}</span>
            </>
        );
    };
    return (
        <>{loanState.paymentAndBalanceStatus === Status.Loading ? <Loader /> :
            <div className="row">
                <div className="col col-lg-6">
                    <div className="row payment-boxes">
                        <div className="col-12 payment-box-heading">
                            <h6 className="accordion_card1_title">Principal Limit Calculations</h6>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Original Principal Limit</label>
                                <span>{paymentAndBalance?.original_principal_limit ? formatAmount(Number(paymentAndBalance?.original_principal_limit)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Current Principal Limit</label>
                                <span>{paymentAndBalance?.current_principal_limit_balance ? formatAmount(Number(paymentAndBalance?.current_principal_limit_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label> Current Principal Balance</label>
                                <span>{paymentAndBalance?.current_principal_balance ? formatAmount(Number(paymentAndBalance?.current_principal_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Interest Balance</label>
                                <span>{paymentAndBalance?.current_interest_balance ? formatAmount(Number(paymentAndBalance?.current_interest_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label> Current MIP Balance</label>
                                <span>{paymentAndBalance?.current_mip_balance ? formatAmount(Number(paymentAndBalance?.current_mip_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Service Fee Amount</label>
                                <span>{paymentAndBalance?.current_service_fee_balance ? formatAmount(Number(paymentAndBalance?.current_service_fee_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Current Loan Balance</label>
                                <span>{paymentAndBalance?.current_loan_balance ? formatAmount(Number(paymentAndBalance?.current_loan_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Servicer Fee Set Aside</label>
                                <span>{paymentAndBalance?.current_service_fee_set_aside_balance ? formatAmount(Number(paymentAndBalance?.current_service_fee_set_aside_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Current Repair Set Aside Balance </label>
                                <span>{paymentAndBalance?.repair_set_aside_amount ? formatAmount(Number(paymentAndBalance?.repair_set_aside_amount)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Current First Year Set Aside Balance</label>
                                <span>{paymentAndBalance?.first_year_set_aside_amount ? formatAmount(Number(paymentAndBalance?.first_year_set_aside_amount)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Credit Line Set Aside</label>
                                <span>{"---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Life Expectancy Set Aside</label>
                                <span>{paymentAndBalance?.lesa_amount ? formatAmount(Number(paymentAndBalance?.lesa_amount)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Net Principal Limit</label>
                                <span>{paymentAndBalance?.current_net_principal_limit_balance ? formatAmount(Number(paymentAndBalance?.current_net_principal_limit_balance)) : "---"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row payment-boxes">
                        <div className="col-12 payment-box-heading">
                            <h6 className="accordion_card1_title">Life Expectancy Set Aside</h6>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>LESA Type</label>
                                <span>{paymentAndBalance?.lesa_type || "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>LESA Payment Status</label>
                                <span>{paymentAndBalance?.lesa_payment_status_description || "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Semi-Annual Payment</label>
                                <span>{paymentAndBalance?.lesa_semi_annual_amount ? formatAmount(Number(paymentAndBalance?.lesa_semi_annual_amount)) : "---"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row payment-boxes">
                        <div className="col-12 payment-box-heading">
                            <h6 className="accordion_card1_title">Other Balances</h6>
                        </div>

                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Taxes & Insurance Withheld Balance</label>
                                <span>{paymentAndBalance?.current_tax_insurance_withheld_balance ? formatAmount(Number(paymentAndBalance?.current_tax_insurance_withheld_balance)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>IMIP Paid by Borrower</label>
                                <span>{paymentAndBalance?.imip_paid_by_borrower_amount ? formatAmount(Number(paymentAndBalance?.imip_paid_by_borrower_amount)) : "---"}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>IMIP Paid by Lender</label>
                                <span>{paymentAndBalance?.imip_paid_by_lender_amount ? formatAmount(Number(paymentAndBalance?.imip_paid_by_lender_amount)) : "---"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row payment-boxes">
                        <div className="col-12 payment-box-heading">
                            <h6 className="accordion_card1_title">Credit Line Information</h6>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="l_info_block">
                                <label>Current Net Credit Line</label>
                                <span>{paymentAndBalance?.current_idl_net_credit_line ? formatAmount(Number(paymentAndBalance?.current_idl_net_credit_line)) : "---"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-lg-6">
                    <div className="col col-lg-12">
                        <div className="row payment-boxes">
                            <div className="col-12 payment-box-heading">
                                <h6 className="accordion_card1_title">Loan Balance Distribution</h6>
                            </div>
                            <div style={{ width: '100%', maxHeight: "273px", height: "275px", padding: "0" }} className="interest_graph_sect mb10">
                                <ResponsiveContainer >
                                    <PieChart>
                                        <Legend
                                            iconType="square"
                                            layout="vertical"
                                            verticalAlign={mobileQuery.matches ? "bottom" : "middle"}
                                            iconSize={10}
                                            align={mobileQuery.matches ? "center" : "right"}
                                            formatter={renderColorfulLegendText}
                                        />
                                        <Pie
                                            data={pieChartData}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={mobileQuery.matches ? 50 : 100}
                                            fill="#16A196"
                                            stroke="#00afb1"
                                            strokeWidth={1}
                                            paddingAngle={0}
                                            dataKey="value"
                                        >
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="row payment-boxes" >
                            <div className="col-12 payment-box-heading">
                                <h6 className="accordion_card1_title">Payment Plan Information</h6>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Payment Plan Type</label>
                                    <span>{paymentAndBalance?.payment_plan_description || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Payment Status</label>
                                    <span>{paymentAndBalance?.payment_plan_status_description || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Monthly Status</label>
                                    <span>{paymentAndBalance?.months_remaining_in_term || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Monthly Tax & Insurance Withheld</label>
                                    <span>{paymentAndBalance?.current_tax_insurance_withheld_balance ? formatAmount(Number(paymentAndBalance?.current_tax_insurance_withheld_balance)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Net Monthly Payment</label>
                                    <span>{paymentAndBalance?.net_monthly_payment ? formatAmount(Number(paymentAndBalance?.net_monthly_payment)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Number of Remaining Payments</label>
                                    <span>{paymentAndBalance?.months_remaining_in_term || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Maximum Claim</label>
                                    <span>{paymentAndBalance?.maximum_claim_amount ? formatAmount(Number(paymentAndBalance?.maximum_claim_amount)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Monthly Service Fee</label>
                                    <span>{paymentAndBalance?.monthly_service_fee_amount ? formatAmount(Number(paymentAndBalance?.monthly_service_fee_amount)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block">
                                    <label>Print Statement</label>
                                    <span>{paymentAndBalance?.is_print_statements || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="l_info_block" style={{ marginBottom: '24%' }}>
                                    <label>Prohibit All Correspondence</label>
                                    <span>{paymentAndBalance?.is_prohibit_all_correspondence || "---"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default PaymentInformation;