/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Status } from "../../constants/commonConstants";
import { ReportSortingTypes, visualTypeIcons } from "../../constants/reportsContants";
import { Loader } from "../../helpers/loader";
import { getCannedReportListAsync, handleSearchWordChange, readAppState, setBreadcrumbLocation, setCurrentVisual, setLogiToken, setNewVisualFlag, setReportsList } from "../../redux/reducers/appReducer";
import { changeReportPage, deleteReportAsync, filterReports, getReportsAsync, resetFilteredReports, setAllReports, sortReports, setTab } from "../../redux/reducers/reportReducer";
import { getDashboardToken } from "../../services/dashboard.service";
import { removeReport } from "../../services/visual.service";
import Breadcrumb from "../common/Breadcrumb";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import SharePopupComponent from "../common/SharePopupComponent";
import { readUserState } from "../../redux/reducers/userReducer";

const Visual = () => {
    const navigation = useNavigate();
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const appState = useAppSelector(readAppState);
    const userState = useAppSelector(readUserState);
    const reportState = useAppSelector((state: RootState) => state.report);
    const [reports, setReports] = useState(reportState.currentReports);
    const dispatch = useAppDispatch();
    const userGroups = (userState.userGroupList !== undefined && userState.userGroupList.length > 0) ? userState.userGroupList : appState.userGroups;
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    const [isLoading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(reportState.activeTab);
    const [cannedReports, setCannedReports] = useState([] as any[]);
    const [otherReports, setOtherReports] = useState([] as any[]);
    useEffect(() => {
        getVisualList();
        dispatch(handleSearchWordChange(""));
    }, []);

    const getMasterToken = async () => {
        if (appState.logiToken) {
            return appState.logiToken;
        }
        const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
            return r
        })
        if (token) {
            dispatch(setLogiToken(token));
        }
        return token;
    };
    const getVisualList = async () => {
        try {
            const token = await getMasterToken();
            if (token) {
                dispatch(getReportsAsync({ token: token, currentUser: appState.currentUser, logiConfig: appState.logiConfiguration, userGroups })).then((response) => {
                    if (response && response.payload) {
                        const defaultReports = response.payload.filter(x => appState.cannedReports.includes(x.visualName));
                        setCannedReports(defaultReports);
                        const otherReports = response.payload.filter(x => !appState.cannedReports.includes(x.visualName))
                        setOtherReports(response.payload.filter(x => !appState.cannedReports.includes(x.visualName)));
                        dispatch(setAllReports(activeTab === "canned" ? defaultReports : otherReports));
                        dispatch(sortReports(reportState.reportSortingType));
                        dispatch(changeReportPage(reportState.reportPageNo));
                        setLoading(false);
                    }
                });
            }
        }
        catch (ex) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (appState.searchWord && appState.searchWord !== "") {
            dispatch(filterReports(appState.searchWord));
        }
        else {
            dispatch(resetFilteredReports());
            dispatch(changeReportPage(reportState.reportPageNo));
        }
    }, [appState.searchWord]);

    useEffect(() => {
        setReports(reportState.currentReports)
    }, [reportState.currentReports])


    const handleCreateVisual = () => {
        dispatch(setNewVisualFlag(true));
        const breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation.push({
            "screen_id": "",
            "screen_name": "Visual View",
            "redirect_url": "/VisualView",
            "icon_url": "fas fa-people-arrows"
        });
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        navigation("/VisualView")
        dispatch(setCurrentVisual(undefined));
    }

    const handleVisualClick = (currentVisual: any) => {
        dispatch(setCurrentVisual(currentVisual));
        const breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation.push({
            "screen_id": "",
            "screen_name": "Visual View",
            "redirect_url": "/VisualView",
            "icon_url": "fas fa-people-arrows"
        });
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        navigation("/VisualView");
    }

    const handleReportDelete = async (report: any) => {
        setPopup(defaultPopup);
        const token = await getMasterToken();
        dispatch(deleteReportAsync({ token: token, reportId: report.id })).then((data) => {
            if (data.payload) {
                removeReport(appState, report);
                let updatedReportsList = appState.currentUser!.reports_list.filter(x => x.report_id !== report.id);
                dispatch(setReportsList(updatedReportsList));
                dispatch(handleSearchWordChange(""));
                setPopup({ ...popup, visible: true, message: "This report has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); } });
            }
        });
    }

    const confirmReportDelete = (report: any) => {
        setPopup({
            ...popup,
            visible: true,
            message: `Are you sure you want to delete ${report.visualName}?`,
            type: ModalType.ActionDialog,
            primaryButtonAction: () => handleReportDelete(report),
            toggle: () => setPopup({ ...defaultPopup }),
            primaryButtonName: "Delete",
            secondaryButtonName: "Cancel",
            secondaryButtonAction: () => setPopup({ ...defaultPopup })
        });
    }

    const handleShareResponse = (response: boolean) => {
        if (response) {
            setPopup({
                ...popup,
                visible: true,
                message: `This report has been successfully shared.`,
                type: ModalType.Snackbar,
                variant: VariantType.Success,
                toggle: () => setPopup({ ...defaultPopup })
            });
        }
        else {
            setPopup({
                ...popup,
                visible: true,
                message: `This report cannot be shared. Please check your permissions.`,
                type: ModalType.Snackbar,
                variant: VariantType.Error,
                toggle: () => setPopup({ ...defaultPopup })
            });
        }
    }

    const showDisabledPopover = (isTrue) => {
        if (isTrue)
            setPopup({
                ...popup,
                visible: true,
                message: `This report cannot be shared. Please check your permissions.`,
                type: ModalType.Snackbar,
                variant: VariantType.Error,
                toggle: () => setPopup({ ...defaultPopup })
            });
        else {
            setPopup({
                ...popup,
                visible: true,
                message: `This report cannot be deleted. Please check your permissions.`,
                type: ModalType.Snackbar,
                variant: VariantType.Error,
                toggle: () => setPopup({ ...defaultPopup })
            });
        }
    }

    const getReportsList = () => {
        if (reports.length > 0) {
            return reports.map((value: any, index) => {
                const enableDeleteShare = appState.isSuperAdminUser || appState.currentUser!.group_name && value.creatingUserName.toLowerCase() === appState.currentUser!.group_name.toLowerCase();
                const reportUsedCount = value.dashboardUsageCount;

                return (<div key={`visual${index}`} className="col-xl-12">
                    <div className="app_info_block">
                        <div className="app_inf_icon_col"><i title={value.name} className={visualTypeIcons[value.type]}></i></div>
                        <div className="app_inf_title" onClick={() => handleVisualClick(value)} onKeyPress={(target) => { if (target.charCode === 13) { handleVisualClick(value) } }}><h3><span>{value.visualName}</span></h3></div>
                        <div className="app_inf_action">
                            <ul>
                                <SharePopupComponent disabledReportClickFunction={showDisabledPopover} shareResponse={(response) => handleShareResponse(response)} componentName="Report" visualData={value} enableDeleteShare={enableDeleteShare} userGroups={userGroups} />
                                <li>
                                    <a className={!enableDeleteShare ? "disabled" : ""} onClick={() => (enableDeleteShare && reportUsedCount === 0) ? confirmReportDelete(value) : showDisabledPopover(false)}
                                        onKeyPress={(target) => { if (target.charCode === 13) { (enableDeleteShare && reportUsedCount === 0) ? confirmReportDelete(value) : showDisabledPopover(false) } }}>
                                        <img src={massCdnUrl + "assets/img/site-delete-icon.svg"} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                )
            })
        }
        else {
            return <div className="text-center">
                {(reportState.status !== Status.Loading && reportState.isReportLoaded) &&
                    <h6 className="no_records">No Reports to show!</h6>}
            </div>
        }
    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-12 col-xs-12 dashboard-search-container">
                    <input style={{ width: '138px' }} className="form-control local-search" type="search" placeholder="Search Report" value={appState.searchWord} onChange={(e) => dispatch(handleSearchWordChange(e.target.value))} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                    <a className="btn btn-primary float-end" onClick={() => handleCreateVisual()} onKeyPress={(target) => { if (target.charCode === 13) { handleCreateVisual() } }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} />
                        Add Report
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            <div className="row mb-3">
                <div className="col-10">
                    <div className="filter_sect">
                        <label style={{ marginLeft: "4px" }}> Sort By :</label>
                        <div className="dropdown dropdown-menu-end sort-menu-border">
                            <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {reportState.reportSortingType}
                            </button>
                            <ul style={{ marginLeft: "50px" }} className="dropdown-menu dropdown_arrow " aria-labelledby="dropdownMenuButton1">
                                {
                                    ReportSortingTypes.map(t => {
                                        return <li key={t}><a className="dropdown-item" onClick={() => dispatch(sortReports(t))} onKeyPress={(target) => { if (target.charCode === 13) { dispatch(sortReports(t)) } }}>{t}</a></li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="filter_sect justify-content-end" style={{ height: "36px" }}>
                        <label>Total:</label>
                        <span className="ms-1">{reportState.filteredReports?.length}</span>
                    </div>
                </div>
            </div>
            <div className="loan_d_tags">
                <ul className="nav cust_tabs mb-3" id="myTab" role="tablist" style={{
                    margin: '1px',
                    padding: '1px'
                }}>
                    <li  >
                        <a className={`${activeTab === "canned" ? "active" : ""} `}
                            onClick={() => { setTimeout(() => { setActiveTab("canned"); dispatch(setAllReports(cannedReports)); dispatch(sortReports(reportState.reportSortingType)); dispatch(handleSearchWordChange("")); dispatch(setTab("canned")) }, 50) }}
                            onKeyPress={(target) => { if (target.charCode === 13) { setTimeout(() => { setActiveTab("canned"); dispatch(setAllReports(cannedReports)); dispatch(sortReports(reportState.reportSortingType)); dispatch(handleSearchWordChange("")); dispatch(setTab("canned")) }, 50) } }}
                            href={"#tab1"} data-bs-toggle="tab" aria-selected="true">Canned Reports</a>
                    </li>
                    <li  >
                        <a className={`${activeTab !== "canned" ? "active" : ""} `} onClick={() => { setTimeout(() => { setActiveTab("other"); dispatch(setAllReports(otherReports)); dispatch(sortReports(reportState.reportSortingType)); dispatch(handleSearchWordChange("")); dispatch(setTab("others")) }, 50) }}
                            onKeyPress={(target) => { if (target.charCode === 13) { setTimeout(() => { setActiveTab("other"); dispatch(setAllReports(otherReports)); dispatch(sortReports(reportState.reportSortingType)); dispatch(handleSearchWordChange("")); dispatch(setTab("others")) }, 50) } }}
                            href={"#tab2"} data-bs-toggle="tab" aria-selected="true">Other Reports</a>
                    </li>
                </ul>
                {activeTab &&
                    <div className="tab-content">
                        <div className={`tab - pane fade show active`} id="tab2">
                            <div className="row ">
                                {getReportsList()}
                            </div>
                        </div>
                    </div>
                }
            </div>
            {reports.length > 0 &&
                <nav className="sitepagination mt-2">
                    <ul className="pagination custom_pagination justify-content-center">
                        <li className={`page-item ${reportState.reportPageNo === 1 ? "disabled" : ""}`}>
                            <button disabled={reportState.reportPageNo === 1} className="page-link"
                                onClick={() => dispatch(changeReportPage(reportState.reportPageNo - 1))}
                                onKeyPress={(target) => { if (target.charCode === 13) { dispatch(changeReportPage(reportState.reportPageNo - 1)) } }}>
                                <span className="fa fa-angle-left"></span> Pre</button>
                        </li>
                        {
                            [...Array(Math.ceil((reportState.filteredReports?.length || 0) / 15))].map((e, i) => {
                                return <li key={"pageno" + i} className={`page-item ${reportState.reportPageNo === (i + 1) ? "active" : ""} `} aria-current="page">
                                    <a className="page-link" onClick={() => dispatch(changeReportPage(i + 1))} onKeyPress={(target) => { if (target.charCode === 13) { dispatch(changeReportPage(i + 1)) } }}>{i + 1}
                                    </a>
                                </li>
                            })
                        }
                        <li className={`page-item ${Math.ceil(reportState.filteredReports?.length / 15) === reportState.reportPageNo ? "disabled" : ""}`}>
                            <button disabled={Math.ceil(reportState.filteredReports?.length / 15) === reportState.reportPageNo} className="page-link"
                                onClick={() => dispatch(changeReportPage(reportState.reportPageNo + 1))}
                                onKeyPress={(target) => { if (target.charCode === 13) { dispatch(changeReportPage(reportState.reportPageNo + 1)) } }}>Next
                                <span className="fa fa-angle-right"></span></button>
                        </li>
                    </ul>
                </nav>
            }
        </section>
        {(isLoading || (reportState.status === Status.Loading || !reportState.isReportLoaded)) && <Loader />}
        {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
    </>);
}


export default Visual;