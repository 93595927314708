export function billStatusOptions() {
	return (
		[
			["Initial Bill", "Initial Bill"],
			["Second Bill", "Second Bill"],
			["1st Response Pending From FNMA", "1st Response Pending From FNMA"],
			["2nd Response Pending From FNMA", "2nd Response Pending From FNMA"],
			["Awaiting Payment", "Awaiting Payment"],
			["Impasse", "Impasse"],
			["Impasse Call Complete", "Impasse Call Complete"],
			["Impasse Call Required", "Impasse Call Required"],
			["Closed", "Closed"]
		]
	)
}