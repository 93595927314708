import { API, graphqlOperation } from "aws-amplify";
import { getAllGridCodes, getGridTotalCountAndData, getGridTotalCountAndDataVertica } from "../graphql/queries";


export const getAllGridCodesList = async () => {
    try {
        const allGridCodes: any = await API.graphql(graphqlOperation(getAllGridCodes));
        return allGridCodes.data?.getAllGridCodes ? JSON.parse(allGridCodes.data.getAllGridCodes) : null;
    }
    catch (e) {
        console.error(e);
        return [];
    }
}

export const getGridData = async (data: any) => {
    try {
        // console.log(`- - - Postgre Operation - - -\nview_name: ${data.view_name}\nwhere: ${data.where}\norder_by: ${data.order_by}\noffset: ${data.offset}, row_fetch: ${data.row_fetch}`);
        const gridDataResponse: any = await API.graphql(graphqlOperation(getGridTotalCountAndData,
            {
                view_name: data.view_name,
                where: data.where,
                order_by: data.order_by,
                offset: data.offset,
                row_fetch: data.row_fetch
            }));

        return gridDataResponse && gridDataResponse.data && gridDataResponse.data.getGridTotalCountAndData ? JSON.parse(gridDataResponse.data.getGridTotalCountAndData).gridData : null;
    }
    catch (e) {
        console.error(e);
        return [];
    }
}

export const getGridVerticaData = async (data: any) => {
    try {
        //console.log(`- - - Vertica Operation - - -\nview_name: ${data.view_name}\nwhere: ${data.where}\norder_by: ${data.order_by}\noffset: ${data.offset}, row_fetch: ${data.row_fetch}`);
        const gridDataResponse: any = await API.graphql(graphqlOperation(getGridTotalCountAndDataVertica,
            {
                view_name: data.view_name,
                where: data.where,
                order_by: data.order_by,
                offset: data.offset,
                row_fetch: data.row_fetch
            }));

        return gridDataResponse && gridDataResponse.data ? JSON.parse(gridDataResponse.data.getGridTotalCountAndDataVertica).data : null;
    }
    catch (e) {
        console.error(e);
        return [];
    }
}
