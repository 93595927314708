import { StylesConfig } from "react-select";

export const selectCustomStyles: StylesConfig = {
    control: (provided: Record<string, unknown>, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid var(--border-color)" : "1px solid #cccccc",
        boxShadow: state.isFocused ? "0px 0px 6px var(--box-shadow-color)" : "none",
        "&:hover": {
            border: "1px solid var(--border-color)",
            boxShadow: "0px 0px 6px var(--box-shadow-color)"
        },
        cursor: 'pointer',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: `${state.isDisabled === true ? "not-allowed" : "pointer"}`,
        backgroundColor: (state.isFocused || state.isSelected) ? (state.isSelected) ? "var(--secondary-color) !important" : "var(--base-color)" : "#fff",
        color: (state.isFocused || state.isSelected) ? state.isSelected ? "#FFF !important" : "var(--primary-color)" : "inherit",
        "&:hover": {
            color: "var(--primary-color)",
            background: "var(--base-color)"
        },
    })
};
export const dashNavCustomStyle: string = "color : var(--url-color); text-decoration: underline; cursor : pointer !important;"

export const urlStyle = {
    color: 'var(--url-color)',
    textDecoration: 'underline',
    cursor: 'pointer !important',
};