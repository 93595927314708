import { Popover, PopoverBody } from "reactstrap";

const PasswordValidityViewer = (props) => {
    const x = -150;
    const y = 0;
    const styles = {
        transform: `translate3d(${x}px, ${y}px,0px)`
    };

    let atleast8Chars = props.password.length >= 8 ? true : false;
    let atLeast1Letter = props.password && props.password.toLowerCase().match(/[a-z]/);
    let atLeast1Number = props.password && props.password.match(/[0-9]/);
    let atLeast1LowerUppercase = props.password && props.password.match(/(?=.*[a-z])(?=.*[A-Z])/);
    let atLeast1SpecialCharacter = props.password && props.password.match(/[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,.@#]/);

    return <>
        <Popover style={styles} placement={"bottom"} isOpen={(props.password && props.showPopover) ? true : false} target={props.id}>
            <ul className="dropdown-menu dropdown_arrow show" aria-labelledby={props.id}>
                <li><a className="dropdown-item pt-0 pb-0" ><div style={{ display: "flex", color: atleast8Chars ? "green" : "red" }}>{atleast8Chars ? <span >✓</span> : <span style={{ marginRight: "2px" }}>X</span>}<label style={{ marginLeft: "15px", color: atleast8Chars ? "green" : "red" }}> Password atleast have 8 characters</label><br /></div></a></li>
                <li><a className="dropdown-item pt-0 pb-0" ><div style={{ display: "flex", color: atLeast1Letter ? "green" : "red" }}>{atLeast1Letter ? <span >✓</span> : <span style={{ marginRight: "2px" }}>X</span>}<label style={{ marginLeft: "15px", color: atLeast1Letter ? "green" : "red" }}> Have atleast 1 letter (a,b,c..)</label><br /></div></a></li>
                <li><a className="dropdown-item pt-0 pb-0" ><div style={{ display: "flex", color: atLeast1Number ? "green" : "red" }}>{atLeast1Number ? <span >✓</span> : <span style={{ marginRight: "2px" }}>X</span>} <label style={{ marginLeft: "15px", color: atLeast1Number ? "green" : "red" }}> Have atleast 1 number (1,2,3..)</label><br /></div></a></li>
                <li><a className="dropdown-item pt-0 pb-0" ><div style={{ display: "flex", color: atLeast1LowerUppercase ? "green" : "red" }}>{atLeast1LowerUppercase ? <span >✓</span> : <span style={{ marginRight: "2px" }}>X</span>}<label style={{ marginLeft: "15px", color: atLeast1LowerUppercase ? "green" : "red" }}> Include both uppercase and lowercase</label></div></a></li>
                <li><a className="dropdown-item pt-0 pb-0" ><div style={{ display: "flex", color: atLeast1SpecialCharacter ? "green" : "red" }}>{atLeast1SpecialCharacter ? <span >✓</span> : <span style={{ marginRight: "2px" }}>X</span>} <label style={{ marginLeft: "15px", color: atLeast1SpecialCharacter ? "green" : "red" }}> Have atleast 1 special character (!@#$%^&..)</label></div></a></li>
            </ul>
        </Popover>
    </>
}

export default PasswordValidityViewer;