/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react"
import formatDateTable from "../../../constants/dateFormatTable";
import { getNotesByLoanId } from "../../../services/getLoanNotes.service";
import CustomTable from "../../StaticComponents/CustomTable";

const LoanNotes = (props: any) => {
    const [rowData, setRowData] = useState<any[]>([]);
    const loan_id = props.loan_id;
    let noteDetails: any[] = [];

    const getNotes = async () => {
        noteDetails = await getNotesByLoanId({ loan_id });
        if (noteDetails && noteDetails.length > 0) {
            setRowData(noteDetails);
        }
        return noteDetails;
    }

    useEffect(() => {
        getNotes();
    }, [loan_id])


    const onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    return (<>
        {
            props.isActive &&
            <CustomTable rows={rowData} columns={[
                { field: "note_id", headerName: "Note Id", tooltipField: "note_id", headerTooltip: "Note Id" },
                { field: "note_type_id", headerName: "Note Type Id", tooltipField: "note_type_id", headerTooltip: "Note Type Id" },
                { field: "note_text", headerName: "Note Description", tooltipField: "note_text", headerTooltip: "Note Description" },
                { field: "is_high_importance", headerName: "High Importance", tooltipField: "is_high_importance", headerTooltip: "High Importance" },
                { field: "created_date", headerName: "Created Date", tooltipField: "created_date", headerTooltip: "Created Date", valueFormatter: (param) => formatDateTable(param, true) },
                { field: "created_by", headerName: "Created By", tooltipField: "created_by", headerTooltip: "Created By" }
            ]} flex={true} onGridReady={onGridReady}
                resizable={true}
                filter={true}
                sortable={true}
            />
        }
    </>
    )
}

export default memo(LoanNotes);