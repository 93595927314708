/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { memo, useState,useEffect } from 'react';
import FileViewer from 'react-file-viewer';
import CustomPopupComponent, { ModalType } from './CustomPopupComponent';
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { readDocumentState } from '../../redux/reducers/documentReducer';
import { readAppState, setBreadcrumbLocation} from '../../redux/reducers/appReducer';

const DocumentPreviewComponent = (props: any) => {
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const documentsState: any = useAppSelector(readDocumentState);
    const appState = useAppSelector(readAppState);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const file=(props && props.file)? props.file : documentsState.doc_information;
    const setFile=props.setFile;
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const handleError = (e: any) => {
        alert(e);
        console.log(e);
    }
    const downloadFile = (file) => {
        saveAs(file.FILE_PATH, file.DOC_NAME);
    }

    const closeButtonHandle = () => {
        const location = window.location.pathname.split('/')[1]
        const menu = screens.find(x => x.redirect_url.trim().toLowerCase() === `/${location.trim().toLowerCase()}`);
        let breadcrumbLocation: any[] = [];
        if (menu) {
            breadcrumbLocation.push(menu);
        }
        else {               
                breadcrumbLocation.push({
                    "screen_id": "",
                    "screen_name": location,
                    "redirect_url": `/${location}`,
                    "icon_url": "fas fa-people-arrows"
                });
        }        
        navigate(`/${location}`)        
        dispatch(setBreadcrumbLocation(breadcrumbLocation));            
    }
    
    return (
        <>          
              
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
            <div >
                <div className='m-documents documents'>
                  <div style={{ display: "flex",marginBottom:"10px"}}>                
                    <a role="button" style={{marginRight:"10%",display:"inline-flex"}} onClick={() => (props && props.file)?setFile(null): closeButtonHandle()}  > 
                        <i title="Back to document list" style={{ fontSize: "25", paddingRight: "0.25rem",lineHeight:1.5 }} className="fas fa-long-arrow-left" ></i>
                        {file.DOC_NAME}
                    </a>                
                 </div>
                    {(!props.hideDownloadButton===true) &&
                     <div> <button className="btn btn-primary btn-sm " onClick={() => downloadFile(file)}>Download</button></div>
                    }
                </div>

                <div className="file-container viewer-container">
                    {file.DOC_TYPE !== 'pdf' ? <>
                        <FileViewer
                            style={{ padding: '50px' }}
                            fileType={file.DOC_TYPE}
                            filePath={file.FILE_PATH}
                            onError={handleError}
                            errorComponent={() => setPopup({ ...popup, visible: true, type: ModalType.Snackbar, toggle: () => { setPopup(defaultPopup) }, variant: "danger", message: "Error occured while reading the file." })}
                             />
                    </> :
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: '750px' }}>
                                <Viewer fileUrl={file.FILE_PATH} />
                            </div>
                        </Worker>}
                </div>
            </div>
        </>
    )
}

export default memo(DocumentPreviewComponent);