import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { readTransformerLogsState } from "../../redux/reducers/transformerLogsReducer";
import PopoverComponent from "./PopoverComponent";

const Breadcrumb = (props: any) => {
    const appState = useAppSelector(readAppState);
    // const breadcrumbLocation = useRef(appState.breadcrumbLocation);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const [breadcrumbLocation, setBreadcrumb] = useState(appState.breadcrumbLocation);
    const transformerLogState = useAppSelector(readTransformerLogsState);
    const navigation = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    useEffect(() => {
        setBreadcrumb(appState.breadcrumbLocation);
    }, [appState.breadcrumbLocation]);

    const handleClick = (data: any, i: any) => {
        if (props.pathClicked) {
            props.pathClicked(data.screen_name);
        }
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        if (data.screen_name === 'Results') {
            [breadcrumbLocation[breadcrumbLocation.length - 2], breadcrumbLocation[breadcrumbLocation.length - 1]] = [breadcrumbLocation[breadcrumbLocation.length - 1], breadcrumbLocation[breadcrumbLocation.length - 2]];
        }
        else {
            breadcrumbLocation = breadcrumbLocation.filter((x, index) => index <= i)
        }
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        navigation(data.redirect_url);
    }
    return (
        <Breadcrumbs separator="/" aria-label="breadcrumb">
            {breadcrumbLocation.length > 0 && breadcrumbLocation.map((value, i) => {
                if (i === breadcrumbLocation.length - 1 && value) {
                    if (appState.currentDashboard && value.screen_name === "Dashboard") {
                        return <Typography key={"breadcrumb" + i} color="primary">{appState.currentDashboard.name}</Typography>
                    }
                    else if (appState.currentVisual && value.screen_name === "Visual View") {
                        return <Typography key={"breadcrumb" + i} color="primary">{appState.currentVisual.visualName}</Typography>
                    }
                    else if (appState.isNewVisual && value.screen_name === "Visual View") {
                        return <Typography key={"breadcrumb" + i} color="primary">Add Report</Typography>
                    }
                    else if (value.screen_name === "File Logs") {
                        return <div style={{ display: "inline-flex" }}>
                            <Typography key={"breadcrumb" + i} color="primary">{value.screen_name} </Typography>
                            <PopoverComponent id={"File_" + i} header="File Information" data={{ "File Name": transformerLogState.file_information?.file_name, "Servicer Name": transformerLogState.file_information?.servicer_name, Status: transformerLogState.file_information?.status }} />
                        </div>
                    }
                    else if (value.screen_name === "Loan Details") {
                        return <Typography key={"breadcrumb" + i} color="primary">{value.screen_name} ({loanState.loanTabs ? loanState.loanTabs.length : 0})</Typography>
                    }
                    else if (value.screen_name === "Results") {
                        return <Typography key={"breadcrumb" + i} color="primary">{value.screen_name} ({loanState.loans ? loanState.loans.length : 0})</Typography>
                    }
                    else {
                        return <Typography key={"breadcrumb" + i} color="primary">{value.screen_name}</Typography>
                    }

                } else {
                    if (appState.currentVisual && value.screen_name === "Visual View") {
                        return <Link key={"breadcrumb" + i} underline="hover" color="inherit" onClick={() => handleClick(value, i)}
                        onKeyPress={(target) => { if (target.charCode === 13) {handleClick(value, i)}}}>
                            {appState.currentVisual.visualName}
                        </Link>
                    }
                    return <Link key={"breadcrumb" + i} underline="hover" color="inherit" onClick={() => handleClick(value, i)}
                    onKeyPress={(target) => { if (target.charCode === 13) {handleClick(value, i)}}}>
                        {value ? value.screen_name : ""}
                    </Link>
                }
            })
            }
        </Breadcrumbs >
    );
}

export default memo(Breadcrumb);