import { createUserRole, deleteUserByUserId, insertUser, insertGroup, updateGroupData, createUserGroup, updateUserRole, deleteGroupById, deleteUserGroupByUserId, deleteUserGroupByGroupId, updateUserGroup, deleteUserRoleByUserId } from '../../services/user.service';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
// import { getUser, getUserName } from '../../services/user.service';
import { UserState } from '../stores/userStore';
import { createRoleScreen, deleteRoleById, getRoleList, insertRole, updateRoleScreen } from '../../services/role.service';
import { createLogiUser } from '../../services/logi.service';

const initialState: UserState = {

};
export const getRolesListAsync = createAsyncThunk(
    'user/getRolesList',
    async () => {
        const response = await getRoleList();
        return response;
    }
);

export const createUserAsync = createAsyncThunk(
    'user/createUser',
    async (value: any) => {
        const response = await insertUser(value);
        return response;
    }
);


export const createGroupAsync = createAsyncThunk(
    'user/createGroup',
    async (value: any) => {
        const response = await insertGroup(value);
        return response;
    }
);


export const updateGroupAsync = createAsyncThunk(
    'user/updateGroup',
    async (value: any) => {
        const response = await updateGroupData(value);
        return response;
    }
);

export const deleteGroupAsync = createAsyncThunk(
    'user/deleteGroup',
    async (value: any) => {
        const response = await deleteGroupById(value);
        return response;
    }
);


export const createUserRoleAsync = createAsyncThunk(
    'user/createUserRole',
    async (value: any) => {
        const response = await createUserRole(value);
        return response;
    }
);

export const createUserGroupAsync = createAsyncThunk(
    'user/createUserGroup',
    async (value: any) => {
        const response = await createUserGroup(value);
        return response;
    }
);

export const updateUserGroupAsync = createAsyncThunk(
    'user/updateUserGroup',
    async (value: any) => {
        const response = await updateUserGroup(value);
        return response;
    }
);
export const deleteUserGroupByUserIdAsync = createAsyncThunk(
    'user/deleteUserGroupByUserId',
    async (value: any) => {
        const response = await deleteUserGroupByUserId(value);
        return response;
    }
);

export const createUserGroupByGroupIdAsync = createAsyncThunk(
    'user/deleteUserGroupByGroupId',
    async (value: any) => {
        const response = await deleteUserGroupByGroupId(value);
        return response;
    }
);

export const updateUserRoleAsync = createAsyncThunk(
    'user/updateUserRole',
    async (value: any) => {
        const response = await updateUserRole(value);
        return response;
    }
);

export const deleteUserRoleByUserIdAsync = createAsyncThunk(
    'user/deleteUserRoleByUserId',
    async (value: any) => {
        const response = await deleteUserRoleByUserId(value);
        return response;
    }
)

export const deleteUserAsync = createAsyncThunk(
    'user/deleteUser',
    async (value: any) => {
        const response = await deleteUserByUserId(value);
        return response;
    }
);

export const createRoleAsync = createAsyncThunk(
    'user/createRole',
    async (value: any) => {
        const response = await insertRole(value);
        return response;
    }
);

export const CreateRoleScreensAsync = createAsyncThunk(
    'user/CreateRoleScreens',
    async (value: any) => {
        const response = await createRoleScreen(value);
        return response;
    }
);

export const updateRoleScreensAsync = createAsyncThunk(
    'user/updateRoleScreens',
    async (value: any) => {
        const response = await updateRoleScreen(value);
        return response;
    }
);

export const deleteRoleAsync = createAsyncThunk(
    'user/deleteRole',
    async (value: any) => {
        const response = await deleteRoleById(value);
        return response;
    }
);

export const createLogiUserAsync = createAsyncThunk(
    'user/createLogiUser',
    async (params: any) => {
        const response = await createLogiUser(params.group_name, params.logiConfiguration);
        return response;
    }
);


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: UserState, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setRole: (state: UserState, action: PayloadAction<any>) => {
            state.role = action.payload;
        },
        setUserGroup: (state: UserState, action: PayloadAction<any>) => {
            state.userGroup = action.payload;
        },
        setUserGroupList: (state: UserState, action: PayloadAction<any>) => {
            state.userGroupList = action.payload;
        },
        setUserList: (state: UserState, action: PayloadAction<any>) => {
            state.userList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRolesListAsync.fulfilled, (state: UserState, action: PayloadAction<any>) => {
                state.roles = action.payload;
            })
    }
});

export const { setUser, setRole, setUserGroup, setUserGroupList, setUserList } = userSlice.actions;

export const readUserState = (state: RootState) => state.user;
export default userSlice.reducer;