import { getSupervisorAuthHeaders } from './../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { getLogiAnalyticsToken, getLogiConfigurations } from "../graphql/queries";
import { getDashboardToken } from "./dashboard.service"
const composerUrl = process.env.REACT_APP_COMPOSER_URL || '';


export const getLogiConfigurationDetails = async () => {
    try {
        const configurationResponse: any = await API.graphql(graphqlOperation(getLogiConfigurations));
        if (configurationResponse && configurationResponse.data && configurationResponse.data.getLogiConfigurations && configurationResponse.data.getLogiConfigurations.length > 0) {
            return configurationResponse.data.getLogiConfigurations;
        }
    }
    catch (e: any) {
        console.log(e);
        return null;
    }
}

const getAdminDashboardToken = async (logiAdmin: any) => {
    const token: any = await API.graphql(graphqlOperation(getLogiAnalyticsToken, { data: JSON.stringify({ username: logiAdmin }) }));
    let AnalyticsToken = token.data.getLogiAnalyticsToken;
    return AnalyticsToken;
}


export const createLogiUser = async (username: string, logiConfiguration: any) => {
    try {
        const token = await getDashboardToken({ logiConfiguration: logiConfiguration });
        if (token) {
            let userId: any;
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const input_params = {
                accountId: logiConfiguration.find(x => x.configuration_type === 'logi_company_account_id')?.configuration_value,
                accounts: [
                    {
                        accountId: logiConfiguration.find(x => x.configuration_type === 'logi_company_account_id')?.configuration_value,
                        groups: [logiConfiguration.find(x => x.configuration_type === 'logi_user_group_id')?.configuration_value], //Maas_Users group id
                        roles: [],
                        userAttributes: [],
                        disabled: false
                    }
                ],
                fullname: username,
                name: username,
                password: "Maasuser@123",
                userOrigin: "NATIVE",
                localeSettingsId: "en_US"
            }

            const createUserResponse: any = await axios.post(`${composerUrl}/api/users`, input_params, { headers });
            if (createUserResponse) {
                updateLogiUser(createUserResponse, logiConfiguration)
                userId = createUserResponse.data.userId;
            }
            return userId;
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const updateLogiUser = async (createdUser: any, logiConfiguration: any) => {
    try {
        if (createdUser) {
            const token = await getDashboardToken({ logiConfiguration: logiConfiguration });
            if (token) {
                let userId = createdUser.data.userId;
                const headers = {
                    'Content-Type': 'application/vnd.composer.v3+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                let updatedAccounts: any = [...createdUser.data.accounts];
                updatedAccounts[0].userAttributes.push(
                    {
                        "key": "UserId",
                        "value": `'${createdUser.data.userId}'`,
                        "encrypted": true
                    }
                )
                let updatedUser = { ...createdUser.data, accounts: updatedAccounts };
                const updateUserResponse: any = await axios.put(`${composerUrl}/api/users/${userId}`, updatedUser, { headers });
                return updateUserResponse;
            }
        }
    }
    catch (ex) {
        return null;
    }
}

export const deleteLogiUser = async (userId: any) => {
    try {
        if (userId) {
            const response: any = await API.graphql(graphqlOperation(getSupervisorAuthHeaders));
            if (response && response.data && response.data.getSupervisorAuthHeaders) {
                const headers = JSON.parse(response.data.getSupervisorAuthHeaders);
                await axios.delete(`${composerUrl}/api/users/${userId}`, { headers });
                return { success: "User deleted successfully!" };
            }
            else {
                return { error: "User could not be deleted!" };
            }
        }
    }
    catch (ex) {
        console.log(ex);
        return {error: "User could not be deleted!"};
    }
}

export const grantDashboardPermissions = async (userId: any, dashboardList: any, adminUsername: any, isAdminUser?: boolean) => {
    try {
        if (userId && dashboardList && dashboardList.length > 0) {
            const token = await getAdminDashboardToken(adminUsername);
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v3+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const input_params = [
                    {
                        "sid": {
                            "id": userId,
                            "type": "USER"
                        },
                        "grant": [
                            "READ",
                            "WRITE"
                        ]
                    }
                ]
                if (isAdminUser) {
                    input_params[0].grant.push('DELETE');
                }
                dashboardList.forEach(dashboard => {
                    if (dashboard.associatedItems && dashboard.associatedItems.length > 0) {
                        dashboard.associatedItems.forEach(item => {
                            if (item.type === "DATA_SOURCE") {
                                axios.patch(`${composerUrl}/api/sources/${item.inventoryItemId}/acls/bulk`, input_params, { headers });
                            }
                        })
                    }
                    axios.patch(`${composerUrl}/api/dashboards/${dashboard.inventoryItemId}/acls/bulk`, input_params, { headers });
                });
            }
        }
        return ({ successMessage: true });
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const revokeDashboardPermissions = async (userId: any, dashboardsList: any, dataSourceList: any, adminUsername: any) => {
    try {
        if (userId && dashboardsList && dashboardsList.length > 0) {
            const token = await getAdminDashboardToken(adminUsername);
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v3+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const input_params = [
                    {
                        "sid": {
                            "id": userId,
                            "type": "USER"
                        },
                        "revoke": [
                            "READ",
                            "WRITE",
                            "DELETE"
                        ]
                    }
                ]
                dashboardsList.forEach(dashboard => {
                    if (dashboard.associatedItems && dashboard.associatedItems.length > 0) {
                        dashboard.associatedItems.forEach(item => {
                            if (dataSourceList && dataSourceList.length > 0 && !dataSourceList.find(x => x.source_id === item.inventoryItemId)) {
                                axios.patch(`${composerUrl}/api/sources/${item.inventoryItemId}/acls/bulk`, input_params, { headers });
                            }
                        })
                    }
                    axios.patch(`${composerUrl}/api/dashboards/${dashboard.inventoryItemId}/acls/bulk`, input_params, { headers });
                });
            }
        }
        return ({ successMessage: true });
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}


export const grantReportPermissions = async (userId: any, visualList: any, adminUsername: any, isAdminUser?: boolean) => {
    try {
        if (userId && visualList && visualList.length > 0) {
            const token = await getAdminDashboardToken(adminUsername);
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v3+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const input_params = [
                    {
                        "sid": {
                            "id": userId,
                            "type": "USER"
                        },
                        "grant": [
                            "READ",
                            "WRITE"
                        ]
                    }
                ]
                if (isAdminUser) {
                    input_params[0].grant.push('DELETE');
                }
                visualList.forEach(visual => {
                    if (visual.id) {
                        axios.patch(`${composerUrl}/api/visuals/${visual.id}/acls/bulk`, input_params, { headers });
                    }
                    if (visual.ownerSourceId) {
                        axios.patch(`${composerUrl}/api/sources/${visual.ownerSourceId}/acls/bulk`, input_params, { headers });
                    }
                });
            }
        }
        return ({ successMessage: true });
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const revokeReportPermissions = async (userId: any, visualList: any, sourceList: any, adminUsername: any) => {
    try {
        if (userId && visualList && visualList.length > 0) {
            const token = await getAdminDashboardToken(adminUsername);
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v3+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const input_params = [
                    {
                        "sid": {
                            "id": userId,
                            "type": "USER"
                        },
                        "revoke": [
                            "READ",
                            "WRITE",
                            "DELETE"
                        ]
                    }
                ]
                visualList.forEach(visual => {
                    if (sourceList && sourceList.length > 0 && !sourceList.find(z => z.source_id === visual.ownerSourceId)) {
                        axios.patch(`${composerUrl}/api/sources/${visual.ownerSourceId}/acls/bulk`, input_params, { headers });
                    }
                    axios.patch(`${composerUrl}/api/visuals/${visual.id}/acls/bulk`, input_params, { headers });
                });
            }
        }
        return ({ successMessage: true });
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getLogiUsersList = async (adminUsername: any) => {
    try {
        let usersList: any[] = [];
        const token = await getAdminDashboardToken(adminUsername);
        if (token) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v3+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const response: any = await axios.get(`${composerUrl}/api/users`, { headers });
            if (response && response.data && response.data.length > 0) {
                usersList = [...response.data];
            }
        }
        return usersList;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}