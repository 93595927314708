/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import { createRoleAsync, CreateRoleScreensAsync, readUserState } from "../../../redux/reducers/userReducer";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { Loader } from "../../../helpers/loader";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import FormError from "../../common/FormError";
import Select from "react-select";
import Breadcrumb from "../../common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { createRoleScreen, deleteScreenRoleByRoleId, getRoleAssociatedScreens } from "../../../services/role.service";
import { selectCustomStyles } from "../../common/CustomStyles";
import { screenTypes } from "../../../constants/screenTypes";


const RoleForm = () => {
    const navigation = useNavigate();
    const [roleScreen, setRoleScreen] = useState<any>(null);
    const [screenOptions, setScreenOptions] = useState<any>([]);
    const appState = useAppSelector(readAppState);
    const userState = useAppSelector(readUserState);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(true);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [error, setError] = useState({ errors: { cognito: null } });
    const [roleDetail, setRoleDetail] = useState({ roleName: userState.role?.role_name ? userState.role.role_name : "" });
    const dispatch = useAppDispatch();
    const [submit, setSubmit] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const screens = appState.masterUiScreenList ? appState.masterUiScreenList : [];

    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/Roles")
        );
        userState.role?.is_new ?
            breadcrumbLocation.push({
                screen_name: "Add Role",
                redirect_url: "/RoleManagement",
                icon_url: "fad fa-toolbox"
            }) : breadcrumbLocation.push({
                screen_name: "Edit Role",
                redirect_url: "/RoleManagement",
                icon_url: "fad fa-toolbox"
            });

        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        !userState.role?.is_new && getRoleAssociatedScreens().then((response: any) => {
            if (response && response.length > 0)
                setRoleScreen(response.filter(x => x.role_id === userState.role?.role_id).map(x => {
                    return {
                        label: x.screen_name,
                        value: x.screen_id
                    }
                }));
        })
        setAccessibleScreenOptions();
    }, []);

    const setAccessibleScreenOptions = () => {
        if (screens && screens.length > 0) {
            let accessibleScreens: any[] = [];
            screens.forEach((sc:any) => {
                if (sc.screen_type === screenTypes.UI) {
                    if(sc.screen_name!="Screen Navigations"){
                        accessibleScreens.push({
                            label: sc.screen_name,
                            value: sc.screen_id
                        })
                    }
                }
            })
            setScreenOptions(accessibleScreens);
            setLoader(false);
        }
    }

    const handleSubmitForm = async () => {
        if (!roleScreen && roleScreen.length === 0) {
            userState.role?.is_new && setSubmit(true);
            return;
        }
        clearErrorState();
        setLoader(true);
        setDisable(true);
        try {
            if (userState.role?.is_new) {
                const roleData = {
                    role_name: roleDetail.roleName,
                    created_by: appState.currentUser!.maas_user_id,
                    created_date: new Date().toUTCString(),
                    modified_by: appState.currentUser!.maas_user_id,
                    modified_date: new Date().toUTCString(),
                };
                dispatch(createRoleAsync(roleData)).then((data: any) => {
                    if (data.payload && data.payload.role_id) {
                        roleScreen.forEach(x => {
                            dispatch(CreateRoleScreensAsync({
                                role_id: data.payload.role_id,
                                screen_id: x.value,
                                created_by: appState.currentUser!.maas_user_id,
                                created_date: new Date().toUTCString(),
                                modified_by: appState.currentUser!.maas_user_id,
                                modified_date: new Date().toUTCString(),
                            }));
                        })
                        setPopup({ ...popup, visible: true, message: "This new role has been added.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigate("/Roles"); setPopup({ ...defaultPopup }) } });
                        setTimeout(() => {
                            setPopup({ ...defaultPopup });
                            navigate("/Roles");
                        }, 1000);

                    }
                })
            }
            else {
                await deleteScreenRoleByRoleId(userState.role?.role_id).then(response => {
                    if (response) {
                        roleScreen.forEach(async (x) => {
                            await createRoleScreen({
                                role_id: userState.role?.role_id,
                                screen_id: x.value,
                                created_by: appState.currentUser!.maas_user_id,
                                created_date: new Date().toUTCString(),
                                modified_by: appState.currentUser!.maas_user_id,
                                modified_date: new Date().toUTCString(),
                            });
                            setPopup({ ...popup, visible: true, message: "Your edits/updates are complete.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigation("/Roles"); setPopup({ ...defaultPopup }) } });
                        })
                    }
                })
            }
        } catch (error: any) {
            let err = !error.message ? { "message": error } : error;
            console.log(error)
            setError({
                errors: {
                    ...error,
                    cognito: err
                }
            });
            setLoader(false);
            setDisable(false);
            setPopup({ ...popup, visible: true, message: "Role could not be created!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { navigate("/Roles"); setPopup({ ...defaultPopup }) } });
        }
    }

    const clearErrorState = () => {
        setError(
            {
                errors: {
                    cognito: null,
                }
            });
    };

    const submitButton = () => {
        if ((roleDetail.roleName !== "" && roleScreen && roleScreen.length > 0 && isDirty && !disable && (!userState.role?.is_new || !appState.roles!.find((x: any) => x.role_name.trim().toLowerCase() === roleDetail.roleName.trim().toLowerCase())))) {
            return (<button className="btn btn-primary text-center btn-block" type="submit" onClick={() => { !userState.role?.is_new && handleSubmitForm() }}>Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled >Save</button>)
        }
    }

    const handleSelectChange = (e) => {
        setRoleScreen(e);
    }

    return (
        <>
            <div className="pagetitle"><Breadcrumb /></div>
            <section className="section dashboard">
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
                <div className="block_sect">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className="row">
                            <FormError formerrors={error} />
                            <div className="col-lg-6">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="rolename"
                                        placeholder="Role Name"
                                        value={roleDetail.roleName}
                                        disabled={userState.role?.is_new ? false : true}
                                        autoComplete="off"
                                        {...register("rolename", {
                                            required: true, onChange: (e) => setRoleDetail({ ...roleDetail, roleName: e.target.value })
                                        })}
                                    />
                                    {userState.role?.is_new && errors?.firstname?.type === "required" && <p style={{ color: 'red' }}>Please enter Role Name</p>}
                                    {userState.role?.is_new && appState.roles!.find((x: any) => x.role_name.trim().toLowerCase() === roleDetail.roleName.trim().toLowerCase()) && <p style={{ color: 'red' }}>Role already exists with this name.</p>}
                                    <label htmlFor="rolename">Role Name</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isMulti={true}
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        className="form-control  multi-select-dd"
                                        id="screen"
                                        options={screenOptions}
                                        value={roleScreen}
                                        onChange={(e) => { setIsDirty(true); handleSelectChange(e) }}
                                        placeholder="Select Screen..."
                                    />
                                    {submit && (!roleScreen || roleScreen.length === 0) && (<p style={{ color: 'red' }}> Please Select Screen</p>)}
                                    <label htmlFor="screen">Screen</label>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-md-center mt30">
                            <div className="col-lg-2 col">
                                {submitButton()}</div>
                            <div className="col-lg-2 col"><button className="btn btn-outline-primary text-center btn-block" type="submit" onClick={() => navigation('/Roles')}>
                                Cancel</button></div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
export default memo(RoleForm);
