/* eslint-disable jsx-a11y/anchor-is-valid */
import { ICellRendererParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import formatDateTable from "../../constants/dateFormatTable";
import { readAppState } from "../../redux/reducers/appReducer";
import { getLoansFilterAsync, readLoanState, setActiveTab } from "../../redux/reducers/loanReducer";
import BulkLoanTaggingComponent from '../common/BulkLoanTaggingComponent';

const linkTest = ({ value }) => {
    return (
        <a >{value}</a>
    )
}

const LoanSearchResult = (props: any) => {
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector(readLoanState);
    const navigate = useNavigate();
    const gridApi = useRef(null as any);
    const filter = useRef(appState.filterKeys as any);
    const [isBulkTagging, setIsBulkTagging] = useState<any>(false)
    const getCheckAll=useRef(false);
    const gridRef: any = useRef();
    let filterModel=useRef(null as any);;
    let setFilterData=[]

    const handleAllCheck=(data)=>{
        getCheckAll.current=data  
    }

    const getLoans = async (filter: any) => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId: string = userGroups.find(g => g.group_id === appState.currentUser?.group_id).logi_user_id;
        filter = {
            ...filter,
            groupId: userGroupId
        }
        let data = await dispatch(getLoansFilterAsync(filter));

        if (data.payload && data.payload.length > 0) {
            let loans = data.payload;
            if (!loans || loans.length === 0 || loans[0][0].total_rows === '0') {
               gridApi.current.showNoRowsOverlay()
               return { success: true, rows: [], lastRow: 0 };
            }else{
            gridApi.current.hideOverlay();
            return { success: true, rows: loans ? loans[1] : [], lastRow: !loans ? 0 : Number(loans[0][0].total_rows) };
            }
        }
        else {
            gridApi.current.showNoRowsOverlay();
            return { success: true, rows: [], lastRow: 0 };
        }
    }

    useEffect(() => {
        if (loanState.loans && gridApi.current && gridApi.current.setRowData) {
            gridApi.current.setRowData(loanState.loans)
        }
        else {
            filter.current = appState.filterKeys;
            if (gridApi && gridApi.current) {
                gridApi.current.refreshServerSideStore({ purge: true });
            }
        }
    }, [appState.filterKeys]);


    const dataSource = {
        getRows: (params) => {
            gridApi.current.hideOverlay();
            const filterKeys = filter.current;
            let filterColumn = params.request.sortModel.length > 0 ? params.request.sortModel[0].colId : "loan_number";
            let orderColumn = params.request.sortModel.length > 0 ? params.request.sortModel[0].sort : "asc";
            let filterData: any = [];
            let search_key= {};
            const agGridFilterModel = params.request.filterModel;
            if (agGridFilterModel) {
                Object.keys(agGridFilterModel).forEach(function (key) {
                    var item = agGridFilterModel[key];
                    let filterOperator=textFilterMapper(item)
                    if(key=="tag_name"){
                        filterData.push({ field: key, operation: "", operator: "like", value: "('"+item.filter+"')" });
                    }
                    else{
                        filterData.push({ field: key, operation: "", operator: filterOperator, value: item.filter });
                    }
                });
            }
            for (let x in filterKeys) {
                let fieldName = '';
                if (x === 'search_key') {
                    search_key= filterKeys[x] !== null ? { search_key: filterKeys[x] } : {}
                }
                else if (filterKeys[x]) {
                    let filterOperator= fieldName === 'tag_name' ? " in ('"+ filterKeys[x]+ "')" : "LIKE '%" + filterKeys[x].toLowerCase() + "%'" ;
                    if (x === 'borrower_name') {
                        fieldName = "borrower_first_name || ' ' || borrower_last_name"
                    } else {
                        fieldName = x
                    }
                    
                    if (x === Object.keys(filterKeys)[0]) {
                       
                        fieldName === 'tag_name' ? filterData.push({ field: fieldName, operation: "", operator: "like", value: filterKeys[x] }) : filterData.push({ field: fieldName, operation: "", operator: filterOperator, value: filterKeys[x] })

                    } else {
                        fieldName === 'tag_name' ? filterData.push({ field: fieldName, operation: "and", operator: "like", value: filterKeys[x] }) : filterData.push({ field: fieldName, operation: "and", operator: filterOperator, value: filterKeys[x] })
                    }
                }
            }
            setFilterData=filterData;
            
            getLoans({search_key_data:search_key,  filter: filterData, skip: params.request.startRow, row_fetch: 100, field_name: filterColumn, order: orderColumn }).then((response) => {
                const element = document.getElementById('collapseExample1');
                if (element) {
                    element.classList.remove("show");
                }
                if (response.success) {
                    if (response?.rows && response?.rows?.length === 0) {
                        gridApi.current.showNoRowsOverlay();
                      }
                    params.successCallback(response.rows, response.lastRow);
                } else {
                    params.fail();
                }
            })
        }
    }

    const onGridReady = (params: any) => {
        gridApi.current = params.api;
        params.api.setServerSideDatasource({ ...dataSource }); 
        if(filterModel.current && !isBulkTagging){
            params.api.setFilterModel(filterModel.current)
        }
    }

    function checkbox(params: ICellRendererParams) {
        return params.node.group === true;
    }

    const defaultColDef = {
        sortable: true,
        resizable: true,
        cellRendererParams: {
            checkbox,
        },
        filter: true,
    };
    const columnDefs: any[] = [

        {
            field: "loan_number", headerName: "Loan Number", tooltipField: "loan_number", width: 200, headerTooltip: "Loan Id",
            cellRendererFramework: linkTest,
            sortable: true
        },
        { field: "tag_name", headerName: "Tag Name", tooltipField: "tag_name", width: 200, headerTooltip: "Tag Name" },
        { field: "sbs_loan_status_description", headerName: "Loan Status", tooltipField: "current_loan_status", width: 200, headerTooltip: "Loan Status" },
        { field: "borrower_first_name", headerName: "Borrower First Name", tooltipField: "borrower_first_name", width: 200, headerTooltip: "Borrower First Name" },
        { field: "borrower_last_name", headerName: "Borrower Last Name", tooltipField: "borrower_last_name", width: 200, headerTooltip: "Borrower Last Name" },
        { field: "borrower_birth_date", headerName: "Borrower DOB", tooltipField: "borrower_birth_date", width: 200, headerTooltip: "Borrower DOB", valueFormatter: formatDateTable,filter: 'agDateColumnFilter' },
        { field: "borrower_mailing_address", headerName: "Borrower Mailing Address", tooltipField: "borrower_mail_address", width: 200, headerTooltip: "Borrower Mailing Address" },
        { field: "borrower_mailing_address_city", headerName: "Borrower Mailing City", tooltipField: "borrower_mailing_address_city", width: 200, headerTooltip: "Borrower Mailing City" },
        { field: "borrower_mailing_address_state", headerName: "Borrower Mailing State", tooltipField: "borrower_mailing_address_state", width: 200, headerTooltip: "Borrower Mailing State" },
        { field: "borrower_mailing_zip", headerName: "Borrower Zip Code", tooltipField: "borrower_mailing_zip", width: 200, headerTooltip: "Borrower Zip Code" },
        { field: "borrower_ssn", headerName: "Borrower SSN", tooltipField: "borrower_ssn", width: 200, headerTooltip: "Borrower SSN" },
        { field: "closing_date", headerName: "Closing Date", tooltipField: "closing_date", width: 200, headerTooltip: "Closing Date", valueFormatter: formatDateTable,filter: 'agDateColumnFilter' },
        { field: "fha_case_number", headerName: "FHA Case Number", tooltipField: "fha_case_number", width: 200, headerTooltip: "FHA Case Number" },
        { field: "first_payment_date", headerName: "First Payment Date", tooltipField: "first_payment_date", width: 200, headerTooltip: "First Payment Date", valueFormatter: formatDateTable,filter: 'agDateColumnFilter' },
        { field: "funded_date", headerName: "Funded Date", tooltipField: "funded_date", width: 200, headerTooltip: "Funded Date", valueFormatter: formatDateTable,filter: 'agDateColumnFilter' },
        { field: "property_address_line_1", headerName: "Property Address", tooltipField: "property_address_line_1", width: 200, headerTooltip: "Property Address" },
        { field: "property_city", headerName: "Property City", tooltipField: "property_city", width: 200, headerTooltip: "Property City" },
        { field: "property_state", headerName: "Property State", tooltipField: "property_state", width: 200, headerTooltip: "Property State" },
        { field: "property_zip", headerName: "Property Zip Code", tooltipField: "property_zip", width: 200, headerTooltip: "Property Zip Code" },
        { field: "property_type", headerName: "Property Type", tooltipField: "property_type", width: 200, headerTooltip: "Property Type" },
        { field: "coborrower_birth_date", headerName: "Co-Borrower DOB", tooltipField: "coborrower_birth_date", width: 200, headerTooltip: "Co-Borrower DOB", valueFormatter: formatDateTable },
        { field: "coborrower_first_name", headerName: " Co-Borrower First Name", tooltipField: "coborrower_first_name", width: 200, headerTooltip: " Co-Borrower First Name" },
        { field: "coborrower_last_name", headerName: " Co-Borrower Last Name", tooltipField: "coborrower_last_name", width: 200, headerTooltip: " Co-Borrower Last Name" },
        { field: "coborrower_mailing_address", headerName: " Co-Borrower Mailing Address", tooltipField: "coborrower_mailing_address", width: 200, headerTooltip: " Co-Borrower Mailing Address" },
        { field: "coborrower_mailing_address_city", headerName: "Co-Borrower Mailing City", tooltipField: "coborrower_mailing_address_city", width: 200, headerTooltip: "Co-Borrower Mailing City" },
        { field: "coborrower_mailing_address_state", headerName: " Co-Borrower State", tooltipField: "coborrower_mailing_address_state", width: 200, headerTooltip: " Co-Borrower State" },
        { field: "coborrower_mailing_zip", headerName: " Co-Borrower Zip Code", tooltipField: "coborrower_mailing_zip", width: 200, headerTooltip: " Co-Borrower Zip Code" },
        { field: "coborrower_ssn", headerName: " Co-Borrower SSN", tooltipField: "coborrower_ssn", width: 200, headerTooltip: "Co-Borrower SSN" }


    ]

    const handleRowClick = (e: any) => {
        navigate(`/LoanDetail`, { state: { loan_id: e.data.loan_number } });
        if (props.closeSearchResult) {
            props.closeSearchResult();
        }
        if (loanState.loanTabs && loanState.loanTabs.length > 0 && loanState.loanTabs.find(l => l === e.data.loan_number)) {
            dispatch(setActiveTab(e.data.loan_number));
        }
    }

    const handleToggle = () => {
        setIsBulkTagging(false)
    }

    const handleFilter = () => {
        filterModel.current=gridRef.current.api.getFilterModel()        
    }

    return (
        <>

            {!isBulkTagging ? <div style={{ display: !isBulkTagging ? "block" : "none" }}>
                <section className="section dashboard">
                    <div className="row" style={{ marginBottom: "1rem" }}>
                        <div>
                            <button className="btn btn-primary btn-sm float-end" type="submit" onClick={() => { setIsBulkTagging(true); getCheckAll.current=false; }}>
                                Edit Tags</button>
                        </div>
                    </div>
                    <div style={{ height: "510px" }} className={`ag-theme-alpine`}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={10}
                            cacheBlockSize={100}
                            headerHeight={35}
                            rowHeight={40}
                            rowSelection={'single'}
                            suppressRowClickSelection={true}
                            overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">The criteria you have searched for does not match with our records, please try again.</span>'}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                            onGridReady={onGridReady}
                            onRowClicked={(e) => handleRowClick(e)}
                            rowModelType={'serverSide'}
                            serverSideStoreType={'partial'}
                            modules={[ServerSideRowModelModule]}
                            ref={gridRef}
                            onFilterChanged={handleFilter}
                        />
                        <></>

                    </div>
                </section>
            </div>
                : <BulkLoanTaggingComponent grid={gridApi.current} filterData={filterModel.current} handleRowClick={handleRowClick} onGridReady={onGridReady} defaultColDef={defaultColDef} columnDefs={columnDefs} toggle={handleToggle} handleAllCheck={handleAllCheck} />}
        </>
    );
}

export default LoanSearchResult;


function textFilterMapper(item) {
    let key="";
    switch (item.type) {
        case 'equals':
            return key + " = '" + item.filter.toLowerCase() + "'"  ;
        case 'notEqual':
            return key + " != '" + item.filter.toLowerCase() + "'";
        case 'contains':
            return key + " LIKE '%" + item.filter.toLowerCase() + "%'";
        case 'notContains':
            return key + " NOT LIKE '%" + item.filter.toLowerCase() + "%'";
        case 'startsWith':
            return key + " LIKE '" + item.filter.toLowerCase() + "%'" ;
        case 'endsWith':
            return key + " LIKE '%" + item.filter.toLowerCase() + "'" ;
        default:
            console.error({ "@": "GenericAgGrid.textFilterMapper", Msg: 'unknown text filter type: ' + item.type });
    }
}