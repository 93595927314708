import { LogiFilters } from "../constants/gridFiltersConstant";

export const getFiltersList = async (e: any) => {
    const targetParent = e.target.closest('.resizable');
    if (targetParent) {
        const headerElement = targetParent.children[0].children[0].children[0];
        if (headerElement) {
            const filterButton = headerElement.children[0].children[1].children[0].children[0].children[0].children[0].children[0] as HTMLElement;
            filterButton.dispatchEvent(new MouseEvent('mouseover', { 'bubbles': true }));
            return new Promise<any[]>((resolve, reject) => {
                setTimeout(() => {
                    const tooltipDialog = document.querySelector('[data-testid="filter-tooltip-item"]');
                    const filters: any[] = [];
                    if (tooltipDialog) {
                        const filterList = document.querySelectorAll('[data-testid="filter-tooltip-item"]');
                        Array.from(filterList).forEach(x => {
                            const label = x.children[0].children[0] as HTMLElement;

                            const operation = (x.children[0].children[1].children[0] as HTMLElement)?.innerText === "Condition" ? (x.children[0].children[1].children[1] as HTMLElement)?.innerText.split(" ")[0] : (x.children[0].children[1].children[0] as HTMLElement)?.innerText === "Link - Investor_Name" ? (x.children[0].children[2].children[0] as HTMLElement)?.innerText : (x.children[0].children[1].children[0] as HTMLElement).innerText;

                            let value = (x.children[0].children[1].children[0] as HTMLElement)?.innerText !== "Condition" ? (x.children[0].children[1].children[0] as HTMLElement)?.innerText === "Link - Investor_Name" ? (x.children[0].children[2].children[1] as HTMLElement)?.innerText :  (x.children[0].children[1].children[1] as HTMLElement)?.innerText : (x.children[0].children[1].children[1] as HTMLElement)?.innerText.split(" ")[1];
                            
                            if(operation==="Include" || operation==="Exclude"){
                                if(value.includes('Reverse Mortgage Solutions, Inc.')||value.includes('Reverse Mortgage Solutions, Inc. 2018-09')){
                                    let regex=/Solutions, Inc./g;
                                    value=value.replace(regex,"Solutions_ Inc.")                            
                                    var trimmedStr = value.trim().split(',').map(function (v) {
                                        v=('Reverse Mortgage Solutions_ Inc.'||'Reverse Mortgage Solutions_ Inc. 2018-09')?v.replace('Solutions_ Inc.',"Solutions, Inc."):v
                                        return v.trim();
                                     });
                                    value=trimmedStr.join('\',\'');
                                }
                                else{
                                    var trimmedStr = value.trim().split(',').map(function (v) {
                                         return v.trim();
                                     });
                                    value=trimmedStr.join('\',\'');
                                }
                            }

                            if (operation === "Between" || operation === "Not Between") {
                                value = value.replace(" to\n", ",");
                            }
                            if(operation===">"||operation===">="||operation==="<"||operation==="<="||operation==="="||operation==="<>"){
                                value = value.replace(/,/g , "");
                            }
                            
                            filters.push({
                                field: label.innerText, operation: LogiFilters[operation], value: value, operator: null, source: "dashboard"
                            });
                            //console.log({ field: label.innerText, operation: operation, value: value });
                        })
                        //console.log(filters);
                        filterButton.dispatchEvent(new MouseEvent('mouseout', { 'bubbles': true })); 
                    }
                    resolve(filters);
                }, 1000)
            })
        }
    }
    return [];
}