/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { getLoanTransaction } from '../../../services/getLoanTransacById.service';
import CustomTable from '../../StaticComponents/CustomTable';
import formatAmountTable from '../../../constants/formatAmountTable';
import formatDateTable from '../../../constants/dateFormatTable';

const LoanTransation = ({ loan_id, isActive }) => {
    const [transationdata, setTransationData] = useState<any[]>([]);
    const d = new Date();
    const [toDate, setToDate] = useState("");
    d.setFullYear(d.getFullYear() - 1);
    const [fromDate, setFromDate] = useState("");
    const [gridApi, setGridApi] = useState<any>(null);

    useEffect(() => {
        loanTransactionsData();
    }, [loan_id]);

    useEffect(() => {
        if (gridApi) {
            setTimeout(() => {
                gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [transationdata])

    const loanTransactionsData = async () => {
        let startDate = fromDate;
        let endDate = toDate;
        if (!fromDate) {
            startDate = "0001-01-01";
        }
        if (!toDate) {
            endDate = `${d.getFullYear()+1}-${d.getMonth() + 1 < 10 ? 0 : ""}${d.getMonth() + 1}-${d.getDate() < 10 ? 0 : ""}${d.getDate()}`;
        }
        const apiData: any = await getLoanTransaction(loan_id, startDate, endDate)
        setTransationData(apiData ? apiData : [])
    }

    const onGridReady = (params: any) => {
        setGridApi(params.api);
        setTimeout(() => {
            params.api.sizeColumnsToFit();
        }, 500)
    }

    return (

        <>
            <div className="row align-items-center">
                <div className="col-lg-2 mwidth">
                    <div className="form-floating ct_form_field">
                        <input
                            type="date"
                            className="form-control"
                            id="floatingInput"
                            placeholder=""
                            value={fromDate}
                            onChange={(e) => { setFromDate(e.target.value) }}
                        ></input>
                        <label htmlFor="floatingInput">From</label>
                    </div>
                </div>
                <div className="col-lg-2 mwidth">
                    <div className="form-floating ct_form_field">
                        <input
                            type="date"
                            id="floatingInput"
                            className="form-control"
                            placeholder=""
                            value={toDate}
                            onChange={(e) => { setToDate(e.target.value) }}
                        ></input>
                        <label htmlFor="floatingInput">to</label>
                    </div>
                </div>
                <div className="col-lg-2">

                    <button
                        className="btn btn-primary text-center"
                        onClick={loanTransactionsData}
                    >
                        Search
                    </button>
                </div>
            </div>
            {isActive &&
                <CustomTable rows={transationdata} columns={[
                    { field: "transaction_id", headerName: "Loan Transaction Id", tooltipField: "transaction_id", headerTooltip: "Loan Transaction Id", },
                    {
                        field: "transaction_date", headerName: "Transaction Date", tooltipField: "transaction_date", headerTooltip: "Transaction Date",
                        valueFormatter: (param) => formatDateTable(param, true),
                    },
                    { field: "transaction_description", headerName: "Transaction Description", tooltipField: "tran_description", headerTooltip: "Transaction Description", },
                    { field: "transaction_amount", headerName: "Transaction Amount", tooltipField: "tran_amount", headerTooltip: "Transaction Amount", type: 'rightAligned', valueFormatter: formatAmountTable }

                ]}
                    onGridReady={onGridReady}
                    resizable={true}
                    filter={true}
                    sortable={true}
                />
            }
        </>

    );
}

export default LoanTransation;

