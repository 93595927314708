/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../common/Breadcrumb';
import LoanBankruptcyDetails from './LoanBankruptcyDetails';

const BankruptyDetailScreen = () => {
    const [activeTab, setActiveTab] = useState("");
    const [loanTabs, setLoanTabs] = useState<any>({});
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let loanScreenData: any = localStorage.getItem("loanScreenData");
        const locationData = location.state as any;
        if (!loanScreenData && locationData.loan_id) {
            addLoanTab(locationData.loan_id);
        }
        if (localStorage.getItem("isRefreshed") === 'true') {
            localStorage.setItem("isRefreshed", 'false');
            let loanScreenData: any = localStorage.getItem("loanScreenData");
            if (loanScreenData) {
                loanScreenData = JSON.parse(loanScreenData);
                setActiveTab(loanScreenData.currentTab);
                setLoanTabs(loanScreenData.loanTabs);
            }
        }
        window.addEventListener("beforeunload", handleRefresh);
        return () => {
            sessionStorage.removeItem("LoanViewLocationKey");
            window.removeEventListener("beforeunload", handleRefresh);
        };
    }, []);


    const handleRefresh = () => {
        localStorage.setItem("isRefreshed", 'true');
    }

    const addLoanTab = (loan_id: string) => {
        if (Object.keys(loanTabs).length < 6) {
            if (!loanTabs[loan_id]) {
                let newData = { ...loanTabs };
                newData[loan_id] = 1;
                setLoanTabs(newData);
                localStorage.setItem("loanScreenData", JSON.stringify({
                    loanTabs: newData,
                    currentTab: loan_id
                }));
            }
            setActiveTab(loan_id);
        }
    };

    return (
        <>
            <div className="message_page">
                <Breadcrumb />
                <div className="container-fluid position-relative">
                    <div className="content_section">
                        <div className="dashboard-content">
                            <LoanBankruptcyDetails loan_id={activeTab !== "loansearch" ? activeTab : ""} refresh={refresh} setRefresh={setRefresh} />
                        </div>
                    </div>
                </div>
            </div></>
    );
}
export default BankruptyDetailScreen;