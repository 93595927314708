import { updateGroup, updateGroupDashboardList, updateGroupReportList, updateGroupSourcesList } from './../graphql/mutations';
import { grantDashboardPermissions } from './logi.service';
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import { getLogiAnalyticsToken } from '../graphql/queries';
import { getGroupList } from './user.service';
import { deleteReportById, getVisuals } from './visual.service';
import UserGroupListComponent from '../components/AdminComponents/UserGroupComponents/UserGroupListComponent';
const composerUrl = process.env.REACT_APP_COMPOSER_URL || '';

export const getDashboardToken = async (params?: any) => {
    const userGroup = params && params.user ? params.user.group_name : params.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value;
    const token: any = await API.graphql(graphqlOperation(getLogiAnalyticsToken, { data: JSON.stringify({ username: userGroup }) }));
    let AnalyticsToken = token.data.getLogiAnalyticsToken;
    return AnalyticsToken;
}

export const deleteDashboardById = async (token: any, dashboardId: string) => {
    try {
        const headers = {
            'Accept': 'application/vnd.composer.dashboard.v2+json',
            'Content-Type': 'application/vnd.composer.dashboard.v2+json',
            'Authorization': 'Bearer ' + token.access_token
        }
        const deleteResponse: any = await axios.delete(`${composerUrl}/api/dashboards/${dashboardId}`, { headers });
        if (deleteResponse && deleteResponse.status === 204) {
            return dashboardId;
        }
        else {
            return null;
        }
    }
    catch (e: any) {
        console.log(e);
        return null;
    }
}

export const createDashboard = async (params: any) => {
    try {
        const token: any = await getDashboardToken(params);
        if (token) {
            const headers = {
                'Accept': 'application/vnd.composer.dashboard.v2+json',
                'Content-Type': 'application/vnd.composer.dashboard.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const dashboardResponse: any = await axios.post(`${composerUrl}/api/dashboards`, { name: params.name }, { headers });
            if (dashboardResponse && dashboardResponse.data && dashboardResponse.data.id) {
                const inventoryResponse: any = await getDashboardById({ id: dashboardResponse.data.id, token });
                grantDashboardPermissions(params.logiConfiguration.find(x => x.configuration_type === 'admin_user_id')?.configuration_value, inventoryResponse && inventoryResponse.data ? [inventoryResponse.data] : [], params.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value, true)//access to madmin/maas_admin
                return inventoryResponse;
            }
        }
    }
    catch (ex) {
        console.log(ex);
        return ex;
    }
}


export const updateDashboards = async (data: any) => {
    try {
        const updateDashboardsResponse = await API.graphql(graphqlOperation(updateGroupDashboardList, { input: data }));
        return updateDashboardsResponse;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const updateReports = async (data: any) => {
    try {
        const updateReportsResponse: any = await API.graphql(graphqlOperation(updateGroupReportList, { input: data }));
        return updateReportsResponse;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const updateDataSources = async (data: any) => {
    try {
        const updateSourcesResponse = await API.graphql(graphqlOperation(updateGroupSourcesList, { input: data }));
        return updateSourcesResponse;
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}


export const getDashboardById = async (params: any) => {
    try {
        if (params) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + params.token.access_token
            }
            const dashboardResponse: any = await axios.get(`${composerUrl}/api/inventory/DASHBOARD/${params.id}`, { headers });
            return dashboardResponse;
        }
    }
    catch (ex) {
        console.log(ex);
        return ex;
    }
}


export const getFilteredDashboards = async (token: any, dashboardsList: any[], isAdminUser: boolean, userGroups: any[]) => {
    try {
        if (token) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const inventoryResponse = await axios.get(`${composerUrl}/api/inventory`, { headers });
            if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.length > 0) {
                let dashboardList = inventoryResponse.data.filter(ir => ir.type === "DASHBOARD" && (
                    (dashboardsList.find(x => x.name === ir.name) ||
                        (isAdminUser &&
                            userGroups.length > 0 && userGroups.find(x => x.group_name.toLowerCase() === ir.creatorName.toLowerCase()))
                    )
                ));
                return dashboardList;
            }
        }
        return [];
    }
    catch (ex) {
        console.log(ex);
        return [];
    }
}

export const getAllDashboards = async (token: any, currentUser: any) => {
    try {
        if (token) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const inventoryResponse = await axios.get(`${composerUrl}/api/inventory`, { headers });
            if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.length > 0) {
                if (currentUser) {
                    let dashboardList = inventoryResponse.data.filter(ir => ir.type === "DASHBOARD");
                    return dashboardList;
                }
            }
        }
        return [];
    }
    catch (ex) {
        console.log(ex);
        return [];
    }
}


export const removeDashboard = async (appState: any, dashboardDetail: any) => {
    try {
        const userGroups = await getGroupList();
        if (userGroups && userGroups.length > 0) {
            const updatedList: any[] = [];
            userGroups.forEach(group => {
                const updatedDashboards = group.dashboards_list.data.filter(x => x.dashboard_id !== dashboardDetail.inventoryItemId);
                if (updatedDashboards.length > 0 && group.dashboards_list.data.length > 0 && updatedDashboards.length !== group.dashboards_list.data.length) {
                    updatedList.push({
                        groupId: group.group_id,
                        dashboards: updatedDashboards
                    });
                }
            });
            if (updatedList.length > 0) {
                updatedList.forEach(async z => {
                    const inputData = {
                        group_id: z.groupId,
                        dashboards_list: JSON.stringify({ data: z.dashboards }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                        modified_by: appState.currentUser?.maas_user_id,
                        modified_date: new Date().toUTCString()
                    }
                    await API.graphql(graphqlOperation(updateGroupDashboardList, { input: inputData }));
                });
            }
        }
        return ({ "success": true })
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}


export const getMasterDashboardList = async (logiConfiguration: any) => {
    try {
        if (logiConfiguration && logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value) {
            const token = await getDashboardToken({ user: { group_name: logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value } });
            if (token) {
                const headers = {
                    'Content-Type': 'application/vnd.composer.v2+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const inventoryResponse = await axios.get(`${composerUrl}/api/inventory`, { headers });
                let dashboardList: any[] = [];
                if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.length > 0) {
                    inventoryResponse.data.forEach(ir => {
                        if (ir.type === "DASHBOARD") {
                            dashboardList.push(ir);
                        }
                    });
                    return dashboardList;
                }
            }
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getDashboardsWithVisuals = async (logiConfiguration: any) => {
    try {
        if (logiConfiguration && logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value) {
            let dashboardList: any[] = [];
            const token = await getDashboardToken({ user: { group_name: logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value } });
            if (token) {
                const headers = {
                    'Accept': 'application/vnd.composer.dashboard.v2+json',
                    'Content-Type': 'application/vnd.composer.dashboard.v2+json',
                    'Authorization': 'Bearer ' + token.access_token
                }
                const dashboardResponse = await axios.get(`${composerUrl}/api/dashboards`, { headers });
                if (dashboardResponse && dashboardResponse.data && dashboardResponse.data.content && dashboardResponse.data.content.length > 0) {
                    dashboardList = [...dashboardResponse.data.content]
                }
            }
            return dashboardList;
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getAllApiDashboards = async (params: any) => {
    try {
        if (params.token) {
            const headers = {
                'Accept': 'application/vnd.composer.dashboard.v2+json',
                'Content-Type': 'application/vnd.composer.v2+json',
                'Authorization': 'Bearer ' + params.token.access_token
            }
            let inventoryResponse = await axios.get(`${composerUrl}/api/dashboards`, { headers });
            if (inventoryResponse && inventoryResponse.data && inventoryResponse.data.content && inventoryResponse.data.content.length > 0) {
                return inventoryResponse.data.content;
            }
        }
        return [];
    }
    catch (ex) {
        console.error(ex);
        return null;
    }
}

export const getDashboardWithVisualsById = async (token: any, dashboardId: any) => {
    try {
        if (token) {
            const headers = {
                'Accept': 'application/vnd.composer.dashboard.v2+json',
                'Content-Type': 'application/vnd.composer.dashboard.v2+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const dashboardResponse: any = await axios.get(`${composerUrl}/api/dashboards/${dashboardId}`, { headers });
            return dashboardResponse.data;
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

export const getDeletedGroupDashboardsAndReports = async (data: any) => {
    const token: any = await getDashboardToken({ user: { group_name: data.userGroup.group_name.toLowerCase() } });
    let deletedGroupDashboards = await getAllDashboards(token, data.currentUser);
    let deletedGroupReports = await getVisuals(token, data.currentUser);
    return ({
        deletedGroupDashboards: deletedGroupDashboards,
        deletedGroupReports: deletedGroupReports
    });
}

export const deleteDashboardsAndReportsByGroupId = async (params: any) => {
    try {
        let deletedUserGroup = params.userGroup;
        let deletedDashboards: any = params.deletedGroupDashboards.length > 0 ? params.deletedGroupDashboards.filter(z => z.creatorName.toLowerCase() === deletedUserGroup.group_name.toLowerCase()) : [];
        let deletedReports: any = params.deletedGroupReports.length > 0 ? params.deletedGroupReports.filter(x => x.creatingUserName.toLowerCase() === deletedUserGroup.group_name.toLowerCase()) : [];
        let token: any;
        if (params.logiConfiguration && params.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value) {
            token = await getDashboardToken({ user: { group_name: params.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value } });
        }

        if (deletedDashboards && deletedDashboards.length > 0) {
            deletedDashboards.forEach(async (dash: any) => {
                await deleteDashboardById(token, dash.inventoryItemId);
            });
        }

        token = await getDashboardToken({ user: { group_name: params.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value } });
        if (deletedReports && deletedReports.length > 0) {
            deletedReports.forEach(async (repo: any) => {
                await deleteReportById(token, repo.id);
            });
        }

        if ((deletedDashboards && deletedDashboards.length > 0) || (deletedReports && deletedReports.length > 0) && params.userGroupList.length > 0) {
            params.userGroupList.forEach(async u => {
                let updatedDashboardList: any = [];
                let updatedReportsList: any = [];
                let updatedDataSourcesList: any = [];

                if (params.dashboards.length > 0) {
                    params.dashboards.forEach(rd => {
                        if (!deletedDashboards.find(dd => dd.inventoryItemId === rd.inventoryItemId) && u.dashboards_list.data.find(b => b.dashboard_id === rd.inventoryItemId)) {
                            updatedDashboardList.push({
                                dashboard_id: rd.inventoryItemId,
                                dashboard_name: rd.name
                            });

                            if (rd.associatedItems && rd.associatedItems.length > 0) {
                                rd.associatedItems.forEach(item => {
                                    if (!updatedDataSourcesList.find(s => s.source_id === item.inventoryItemId)) {
                                        updatedDataSourcesList.push({
                                            source_id: item.inventoryItemId,
                                            source_name: item.name
                                        });
                                    }
                                });
                            }
                        }
                    });
                }
                if (params.reports.length > 0) {
                    params.reports.forEach(rr => {
                        if (!deletedReports.find(dr => dr.id === rr.id) && u.reports_list.data.find(v => v.report_id === rr.id)) {
                            updatedReportsList.push({
                                report_id: rr.id,
                                report_name: rr.visualName
                            });

                            if (!updatedDataSourcesList.find(s => s.source_id === rr.ownerSourceId)) {
                                updatedDataSourcesList.push({
                                    source_id: rr.ownerSourceId,
                                    source_name: rr.sourceName
                                });
                            }
                        }
                    })
                }

                const inputData = {
                    group_id: u.group_id,
                    group_name: u.group_name,
                    dashboards_list: JSON.stringify({ data: updatedDashboardList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    data_sources_list: JSON.stringify({ data: updatedDataSourcesList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                    modified_by: params.currentUser?.maas_user_id,
                    modified_date: new Date().toUTCString()
                }

                await API.graphql(graphqlOperation(updateGroup, { input: inputData }));

            });
        }

        return ({ success: true });
    }
    catch (ex) {
        return null
    }
}
export const getLogiSourceQuery = async (token: any, sourceId: any) => {
    try {
        if (token && sourceId) {
            const headers = {
                'Content-Type': 'application/vnd.composer.v3+json',
                'Authorization': 'Bearer ' + token.access_token
            }
            const dashboardResponse: any = await axios.get(`${composerUrl}/api/sources/${sourceId}`, { headers });
            const query: string = dashboardResponse.data && dashboardResponse.data.storageConfiguration ? dashboardResponse.data.storageConfiguration.collection : "";
            if (query) {
                query.replaceAll('\n', " ");
            }
            return query;
        }
        else {
            return "";
        }
    }
    catch (ex) {
        console.log(ex);
        return "";
    }
}
