/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import formatAmount from "../../../constants/formatAmount";
import { getFeeAdjustment } from "../../../services/feeAdjustment.services";

const Section = ({
    heading,
    msg1,
    msg2,
    msg3,
    // msg4,
    // msg5,
    // msg6,
    // msg7,
    // msg8,
    // msg9,
    // subHeading1,
    // subHeading2,
    feeData,
    // table1Columns,
    // table2Rows,
    // table2Columns,
}) => {

    const calcAmount = () => {
        let charges: any[] = [];
        // if (table1Rows) {
        //   table1Rows.forEach((row) => {
        //     charge += row.fee_amount;
        //   });
        // }
        // if (table2Rows) {
        //   table2Rows.forEach((row) => {
        //     adj += row.adjusted_amount;
        //   });
        // }
        if (feeData) {
            if (feeData.late_charge && feeData.late_charge.length > 0) {
                let lateCharges = 0;
                feeData.late_charge.forEach(lc => {
                    lateCharges += lc.fee_amount
                });
                charges.push({ type: "lateCharges", value: lateCharges });
            }
            if (feeData.nsf_charge && feeData.nsf_charge.length > 0) {
                let nsfCharges = 0;
                feeData.nsf_charge.forEach(nc => {
                    nsfCharges += nc.fee_amount
                });
                charges.push({ type: "nsfCharges", value: nsfCharges });
            }
            if (feeData.other_charge && feeData.other_charge.length > 0) {
                let otherCharges = 0;
                feeData.other_charge.forEach(oc => {
                    otherCharges += oc.fee_amount
                });
                charges.push({ type: "otherCharges", value: otherCharges });
            }
        }

        return (
            <>
                <p
                    style={{
                        margin: "0 0.5rem 0",
                        color: "#666",
                        fontSize: "14px",
                    }}
                >
                    {msg1}
                    {formatAmount(charges[0] ? charges[0].value : 0)}
                </p>
                <p
                    style={{
                        margin: "0 0.5rem 0",
                        color: "#666",
                        fontSize: "14px",
                    }}
                >
                    {msg2}
                    {formatAmount(charges[1] ? charges[1].value : 0)}
                </p>
                <p
                    style={{
                        margin: "0 0.5rem 0",
                        color: "#666",
                        fontSize: "14px",
                    }}
                >
                    {msg3}
                    {formatAmount(charges[2] ? charges[2].value : 0)}
                </p>

            </>
        )
    }
    return (
        <div className="card_widget mb20">
            <div className="card_w_header" style={{ width: "100%" }}>{heading}</div>
            <div className="card_w_body">
                {calcAmount()}
            </div>
        </div>
    );
};

const FeeAndCharges = ({ loan_id }) => {
    const [feeData, setFeeData] = useState<any>({})
    useEffect(() => {
        handelFeeAndCharges()
    }, [])

    const handelFeeAndCharges = async () => {
        const feeApiData: any = {}
        setFeeData(feeApiData ? feeApiData : {});
        await getFeeAdjustment(loan_id);
        //setFeeAdjustment(feeAdjustmentApiData ? feeAdjustmentApiData : {});
    }

    return (
        <Section
            heading={"Fee & Charges"}
            msg1={"Total Late Charge Amount: "}
            msg2={"Total NSF Fee Charges: "}
            msg3={"Total Other Fee Charges: "}
            feeData={feeData}
        />
    );
};

export default FeeAndCharges;
