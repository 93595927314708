/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import { memo, useEffect, useState } from "react";
import { severityOptions } from "../../../constants/BusinessRuleConstants";
import { selectCustomStyles } from "../../common/CustomStyles";

const GridDropDownCell = (props: any) => {
    const [cellValue, setCellValue] = useState(props.data.ruleProperty1);

    useEffect(() => {
        setCellValue(props.data.ruleProperty1);
    }, [props.data.ruleProperty1]);

    const handleGridDropdownChange = (e: any) => {
        props.onSelectChange({ ruleId: props.data.ruleId, ruleName: props.data.ruleName, ruleProperty1: e });
    }

    return <>
        <Select
            isSearchable={true}
            options={severityOptions}
            value={cellValue}
            className="multi-select-dropdown"
            classNamePrefix="multi-select-dropdown"
            menuPortalTarget={document.body}
            styles={Object.assign({ menuPortal: base => ({ ...base, zIndex: 9999 }) }, selectCustomStyles)}
            onChange={(e: any) => handleGridDropdownChange(e)} />
    </>
}
export default memo(GridDropDownCell);