/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { getDashboardToken, updateReports } from "../../services/dashboard.service";
import { Loader } from "../../helpers/loader";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readAppState, setBreadcrumbLocation, setCurrentVisual, setLogiToken, setNewVisualFlag, setReportsList } from "../../redux/reducers/appReducer";
import Breadcrumb from "../common/Breadcrumb";
import { grantReportPermissions } from "../../services/logi.service";
import { getVisuals } from "../../services/visual.service";
import { getGroupList } from "../../services/user.service";
import { Outlet, useLocation, useNavigate } from "react-router";
import { dashNavCustomStyle } from "../common/CustomStyles";
import { clearLoanTabs, clearSearchedLoans, setActiveTab } from "../../redux/reducers/loanReducer";
import {getGridVerticaData} from '../../services/genericAgGrid.service';
import {getLogiSourceQuery} from '../../services/dashboard.service';
import { readDashboardState } from "../../redux/reducers/dashboardReducer";

const VisualView = (props: any) => {
    const [loading, setLoader] = useState(true);
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const logiEmbedManager = useRef(undefined as any);
    const componentInstanceId = useRef(undefined as any);
    const currentVisualId = useRef(undefined as any);
    const script = useRef<any>(null);
    const [visualId, setVisualId] = useState<any>(appState && appState.currentVisual ? `${appState.currentVisual.id}` : null);
    const appStateRef = useRef(appState);
    const breadcrumbs = useRef(appState.breadcrumbLocation);
    const location = useLocation();
    const navigation = useNavigate();
    const dashboardState = useAppSelector(readDashboardState);
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const [saveAs, setSaveAs] = useState(false);
    let count;

    const isSharedVisual = appState && appState.currentVisual && appState.currentVisual.creatingUserName.toLowerCase() !== appState.currentUser?.group_name.toLowerCase();
    const sharedVisualInteractivity = {
        "visualSettings": {
            "ACTIONS_ACTION": true,
            "RULERS": true,
            "ZOOM_ACTION": false,
            "FILTER_ACTION": true,
            "COLORS": true,
            "METRICS": true,
            "ACTIONS": true,
            "TREND_ACTION": false,
            "VISUAL_STYLE": true,
            "KEYSET_ACTION": false,
            "KEYSET": false,
            "COPY": true,
            "SETTINGS": true,
            "EXPORT": true,
            "TIMEBAR_PANEL": true,
            "DETAILS_ACTION": true,
            "MAXIMIZE": true,
            "LINK_ACTION": true,
            "FILTER": true,
            "REMOVE": false,
            "GROUPING": true,
            "RENAME": false,
            "SORT": true,
            "TIMEBAR_FIELD": true

        }
    }
    
    useEffect(() => {
        appStateRef.current = appState;
    }, [appState])

    useEffect(() => {
        breadcrumbs.current = appState.breadcrumbLocation;
    }, [appState.breadcrumbLocation])


    const getMasterToken = async () => {
        if (appState.logiToken) {
            return appState.logiToken;
        }
        const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
            return r
        })
        if (token) {
            dispatch(setLogiToken(token.access_token));
        }
        return token;
    };
    const handleTableClick = e => {
        try {
            const target = e.target;
            const breadcrumbLocation = [...breadcrumbs.current];
            if (target.classList.contains('ag-cell')) {
                let loan_id = target.parentNode.querySelector('[col-id="loan_number"]') ? target.parentNode.querySelector('[col-id="loan_number"]').innerText : '';
                if (loan_id) {
                    let loanDetailPath = screens.find(x => x.redirect_url === '/LoanDetail')
                    if (loanDetailPath) {
                        loanDetailPath = { ...loanDetailPath, redirect_url: '/VisualView/LoanDetail' }
                        breadcrumbLocation.push(loanDetailPath);
                    }
                    dispatch(setActiveTab(loan_id));
                    navigation(`/VisualView/LoanDetail`, { state: { loan_id: loan_id } });
                    dispatch(setBreadcrumbLocation(breadcrumbLocation));
                }
            }
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const initClicks = () => {
        const style = document.createElement('style');
        style.setAttribute("name", "user_added");
        style.innerHTML = `.v${visualId} [col-id="loan_number"].ag-cell  {${dashNavCustomStyle}}`;
        document.getElementsByTagName('head')[0].appendChild(style);
        const allColumnsClickableTable = document.querySelectorAll(`[ref="eBodyViewport"].ag-body-viewport`);
        if (allColumnsClickableTable) {
            allColumnsClickableTable.forEach(x => {
                x?.addEventListener('click', e => handleTableClick(e));
            })
        }
    }

    useEffect(() => {
        createVisualComponent();
    }, [visualId]);

    const createVisualComponent = () => {
        (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then((embedManager: any) => {
            logiEmbedManager.current = embedManager;
            
            setLoader(false);
            if (embedManager && componentInstanceId.current && !saveAs) {
                logiEmbedManager.current.removeComponent(componentInstanceId.current);
            }
            embedManager.createComponent('visual-builder', {
                "visualId": visualId,
                "theme": props.theme,
                "header": {
                    'visible': props.showEditor,
                    'showTitle': props.showTitle,
                    'showActions': props.showActions
                },
                "interactivityOverrides": isSharedVisual ? sharedVisualInteractivity : props.interactivityOverrides

            }).then((dash: any) => {
                currentVisualId.current=dash.visualId
                componentInstanceId.current = dash.componentInstanceId;
                
                dash.render(document.getElementById('embed'), { width: props.width, height: props.height });
                dash.addEventListener('composer-visual-builder-ready', e => {
                    initClicks();
                });
                dash.addEventListener("composer-visual-rendered", (e) => {
                    
                    const id = document.getElementById("refreshButton")
                    if (id === null) {
                        
                        let divObj = document.querySelector('.bp3-button-group');
                        var refreshButton;
                        let refreshSpan1 = document.createElement("span");
                        refreshSpan1.className = "bp3-popover-wrapper";
                        refreshSpan1.id = "refreshspan1"
                        let refreshSpan2 = document.createElement("span");

                        refreshSpan2.id = "refreshspan2"
                        refreshSpan2.className = "bp3-popover-target";

                        refreshButton = document.createElement("button");
                        refreshButton.id = "refreshButton"
                        refreshButton.className = "bp3-button bp3-minimal bp3-small  css-cp19h0";
                        let refreshI = document.createElement("i");
                        refreshI.className = "fas fa-sync";
                        refreshI.style.color = '#5c7080'
                        refreshButton.addEventListener('click', function handleClick(e) {
                            embedManager.refresh();
                        });
                        divObj !== null && divObj.insertBefore(refreshSpan1, divObj.firstChild)
                        document.getElementById("refreshspan1")?.appendChild(refreshSpan2);
                        document.getElementById("refreshspan2")?.appendChild(refreshButton);
                        document.getElementById("refreshButton")?.appendChild(refreshI);
                    }

                    let saveHtml = document.querySelector(`[data-testid="dashboard-save-menu-button"]`);
                    if (isSharedVisual && !appState.isSuperAdminUser && !saveAs) {
                        // saveHtml?.parentElement?.parentElement?.style.display = 'none';
                        saveHtml?.remove()
                    }

                    const pageNumber=document.getElementById("ag-9-row-count")
                    if(pageNumber){
                        
                        const countData=document.getElementById("ag-9-row-count")?.innerHTML                    
                        if(countData === 'More'){                         
                            document.getElementById("ag-9-row-count")!.innerHTML = count;
                        }                    
                        const firstRowCount=document.getElementById("ag-9-first-row")!.innerText.toString()
                        const lastRowCount=document.getElementById("ag-9-last-row")!.innerText.toString()
                        const pageSize=Number(lastRowCount)-Number(firstRowCount)+1
                        const pageCount=count < pageSize ? 1 : Math.ceil(count / pageSize);         
                        const pageCountText=document.getElementById("ag-9-of-page-number")?.innerHTML
                        if(pageCountText === 'More'){                         
                            document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                        } 

                        let nextbtn=document.querySelector('.ag-icon-next');
                        if(!!nextbtn){
                            nextbtn.addEventListener('click', function handleClick(e) {
                                setTimeout(function(){ 
                                    document.getElementById("ag-9-row-count")!.innerHTML=count.toString();
                                    document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                                }, 50);
                            });
                        }
                        let prevbtn=document.querySelector('.ag-icon-previous');
                        if(!!prevbtn){
                            prevbtn.addEventListener('click', function handleClick(e) {
                                setTimeout(function(){ 
                                    document.getElementById("ag-9-row-count")!.innerHTML=count.toString();
                                    document.getElementById("ag-9-of-page-number")!.innerHTML = pageCount.toString();
                                }, 50);
                            });
                        }
                    }

                });
                dash.addEventListener("composer-visual-saved", (e) => {
                    handleVisualSave(e);
                }); 
                setLoader(false);
                return 1;              
            })            
            .catch((e: any) => { console.log(`Logi Error: ${e}`); })
        })
            
    }
    
    const getGridCount=async()=>{ 
        let sourceQuery = await getLogiSourceQuery(appState.logiToken, appState.currentVisual.ownerSourceId)        
        sourceQuery=sourceQuery.replaceAll('\n', " ").replace(/  +/g, ' ').toLowerCase();  
        let regExString = sourceQuery.match(new RegExp( "maas.(.*) vw left"));        
        var viewName =regExString?regExString[1]:"" 
        
        const response =await getGridVerticaData({
            view_name: "maas."+viewName,
            where: "",
            order_by: "",
            offset: 20,
            row_fetch: 0
        })
        count=response.length!=0?response[0][0].total_rows:0
 }

    useEffect(() => {
        getGridCount();
        return (() => {
            dispatch(clearLoanTabs());
            dispatch(clearSearchedLoans());
            if (logiEmbedManager.current && componentInstanceId.current) {
                logiEmbedManager.current.removeComponent(componentInstanceId.current);
                if (script && script.current) {
                    document.head.removeChild(script.current);
                }
            }
        });
    }, []);

    useEffect(()=>{
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
      i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/Reports")
    );
        breadcrumbLocation.push({
            "screen_name": "Visual View",
            "redirect_url": "/VisualView",
            "icon_url": "fas fa-people-arrows"
        });
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
    },[visualId,appStateRef.current.currentVisual])


    const handleVisualSave = async (e: any) => {
        if (appStateRef.current.currentVisual && e.detail.visual.visualName === appStateRef.current.currentVisual.visualName && e.detail.visual.id === appStateRef.current.currentVisual.id) {
            return;
        }
        let updatedReportsList = [...appStateRef.current.currentUser!.reports_list];
        if (appStateRef.current.currentVisual && e.detail.visual.visualName !== appStateRef.current.currentVisual.visualName && e.detail.visual.id === appStateRef.current.currentVisual.id) {
            const userGroups = await getGroupList();
            if (userGroups && userGroups.length > 0) {
                let updatedInputs: any[] = [];
                userGroups.forEach(g => {
                    if (g.group_name !== appStateRef.current.currentUser!.group_name && g.reports_list && g.reports_list.data && g.reports_list.data.length > 0) {
                        let updatedList = g.reports_list.data.map(x => {
                            if (x.report_id === e.detail.visual.id) {
                                x = { ...x, report_name: e.detail.visual.visualName };
                            }
                            return x;
                        });
                        updatedInputs.push({
                            group_id: g.group_id,
                            reports_list: JSON.stringify({ data: updatedList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                            modified_by: appStateRef.current.currentUser?.maas_user_id,
                            modified_date: new Date().toUTCString()
                        });
                    }
                });
                if (updatedInputs.length > 0) {
                    updatedInputs.forEach(i => {
                        updateReports(i);
                    });
                }
            }
            updatedReportsList = appStateRef.current.currentUser!.reports_list.map(x => {
                if (x.report_id === e.detail.visual.id) {
                    x = { ...x, report_name: e.detail.visual.visualName };
                }
                return x;
            });
        }
        else if (!appStateRef.current.currentVisual || appStateRef.current.currentVisual.id !== e.detail.visual.id) {
            updatedReportsList.push({ report_id: e.detail.visual.id, report_name: e.detail.visual.visualName })
            const token = await getMasterToken()
            const visualList: any = await getVisuals(token, appStateRef.current.currentUser);
            await grantReportPermissions(appStateRef.current.logiConfiguration.find(x => x.configuration_type === 'admin_user_id')?.configuration_value, [visualList.find(z => z.id === e.detail.visual.id)], appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value, true)//access to madmin/maas_admin
            setSaveAs(true);
        }
        const input = {
            group_id: appStateRef.current.currentUser?.group_id,
            reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            modified_by: appStateRef.current.currentUser?.maas_user_id,
            modified_date: new Date().toUTCString()
        }
        dispatch(setReportsList(updatedReportsList));
        await updateReports(input);

        if (!appStateRef.current.isNewVisual && e.detail.visual.visualName !== appStateRef.current.currentVisual.visualName && appStateRef.current.currentVisual.id !== e.detail.visual.id) {
            dispatch(setNewVisualFlag(false));
            if (logiEmbedManager.current && componentInstanceId.current) {
                logiEmbedManager.current.removeComponent(componentInstanceId.current);
            }
            if (script.current) {
                document.head.removeChild(script.current);
            }
            setVisualId(`${e.detail.visual.id}`);
        }
        else if (appStateRef.current.isNewVisual && e.detail.visual.id) {
            setVisualId(`${e.detail.visual.id}`);
        }
        dispatch(setCurrentVisual(e.detail.visual));
    }



    return (<>
        <div className={`pagetitle ${location?.pathname?.split('/').length === 2 ? "fixed-position" : ""}`} style={{ display: 'inline-flex' }}><Breadcrumb /></div>
        <div className="container-fluid position-relative">

            <div className="content_section">

                <div className={`${location.pathname !== '/VisualView' ? 'hide' : 'container min_height_dashboard'}`}>
                    {loading && <Loader />}
                    <div className={`v${visualId}`} id="embed"></div>
                </div>
                <Outlet />
            </div>
        </div>
    </>);
}

VisualView.defaultProps = {
    composerUrl: '',
    theme: "modern",
    appBanner: false,
    appLogo: false,
    showEditor: true,
    showActions: true,
    showTitle: false,
    width: "100%",
    height: "100%",
    interactivityOverrides: {
        "visualSettings": {
            "ACTIONS_ACTION": true,
            "RULERS": true,
            "ZOOM_ACTION": false,
            "FILTER_ACTION": true,
            "COLORS": true,
            "METRICS": true,
            "ACTIONS": true,
            "TREND_ACTION": false,
            "VISUAL_STYLE": true,
            "KEYSET_ACTION": false,
            "KEYSET": false,
            "COPY": true,
            "SETTINGS": true,
            "EXPORT": true,
            "TIMEBAR_PANEL": true,
            "DETAILS_ACTION": true,
            "MAXIMIZE": true,
            "LINK_ACTION": true,
            "FILTER": true,
            "REMOVE": true,
            "GROUPING": true,
            "RENAME": true,
            "SORT": true,
            "TIMEBAR_FIELD": true

        }
    }
}
export default VisualView;