import { memo, useState } from "react"
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";

const Registration = () => {

    const [userId, setUserId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const navigation = useNavigate();

    const handleSubmit = async event => {
        event.preventDefault();
        navigation("/confirmRegistration")

        try {
            let username = userId;
            await Auth.signUp({
                username,
                password,
                attributes: {
                    email: email
                }
            });
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <section className="section auth">
            <div className="container">
                <h1>Register</h1>
                <form onSubmit={handleSubmit}>
                    <div className="field">
                        <p className="control">
                            <input
                                className="input"
                                type="text"
                                id="userId"
                                placeholder="Enter User ID"
                                value={userId}
                                onChange={(event) => setUserId(event.target.value)}
                            />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left has-icons-right">
                            <input
                                className="input"
                                type="email"
                                id="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="far fa-lock"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control has-icons-left">
                            <input
                                className="input"
                                type="password"
                                id="confirmpassword"
                                placeholder="Confirm password"
                                value={confirmpassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                            />
                            <span className="icon is-small is-left">
                                <i className="far fa-lock"></i>
                            </span>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <Link to="/forgotpassword">Forgot password?</Link>
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <button className="button is-success">
                                Register
                            </button>
                        </p>

                    </div>
                </form>
            </div>
        </section>
    )

}
export default memo(Registration);