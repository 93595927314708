import { memo, useState } from "react";
import { Auth } from "aws-amplify";

const ConfirmRegistration = () => {

    const [userId, setUserId] = useState("");
    const [code, setCode] = useState("");

    const handleSubmit = async () => {
        try {
            await Auth.confirmSignUp(userId, code);

        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <input
                className="input"
                type="text"
                id="userId"

                placeholder="Enter User ID"
                value={userId}
                onChange={(event) => setUserId(event.target.value)}
            />
            <input
                className="input"
                type="text"
                id="otp"

                placeholder="Enter OTP"
                value={code}
                onChange={(event) => setCode(event.target.value)}
            />
            <div className="field">
                <p className="control">
                    <button className="button is-success">
                        Confirm
                    </button>
                </p>

            </div>

        </form>

    )

}

export default memo(ConfirmRegistration);