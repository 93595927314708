/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useAppDispatch,useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { setNoteData } from "../../../redux/reducers/loanReducer";

const NoteEdit = (props: any) => {
  const loanState = useAppSelector((state: RootState) => state.loan);
  const [data, setData] = useState<any>(props.data);
  const dispatch = useAppDispatch();
  
  return (
    <div className="row">
      <div className="col">
        <div className="row payment-boxes ">
          <div className="col-lg-12 payment-box-heading">
            <h6 className="accordion_card1_title">Notes</h6>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="l_info_block">
              <label>Note Type</label>
              <span>{data.sbs_note_type_description || "---"}</span>
            </div>
          </div>
          <div className="col-lg-6 col-md-4">
            <div className="l_info_block">
              <label>Note Text</label>
              <span>{data.note_text || "---"}</span>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                readOnly
                onChange={(e) =>
                  setData({ ...data, is_high_importance: e.target.value })
                }
                disabled={true}
                checked={data.is_high_importance}
                id="flexCheckDefault"
              />
              <label className="form-check-label" htmlFor="is_high_importance">
                High Importance
              </label>
            </div>
          </div>
          <div className="d-flex mt30 justify-content-center">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {dispatch(setNoteData({ ...loanState?.noteData, is_edit: false})); props.toggle()}}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteEdit;