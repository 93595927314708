import { API,  graphqlOperation} from "aws-amplify";
import { listServicers } from "../graphql/queries";


export const getServicersList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listServicers));
        return response && response.data && response.data.listServicers ? response.data.listServicers : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

