/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useAppSelector,useAppDispatch } from "../../../app/hooks";
import { useRef, useState } from "react";
import { listLoanTagHistory } from '../../../services/tag.service';
import formatDateTable from '../../../constants/dateFormatTable';
import { dateTimeToLocal } from '../../../constants/convertToClientDateTime';
import { readAppState } from "../../../redux/reducers/appReducer";
import GenericAgGrid from "../../common/GenericAgGrid";
import { RootState } from '../../../app/store';
import { setTags } from "../../../redux/reducers/loanReducer";



const LoanTagHistory = (props: any) => {
    const dispatch = useAppDispatch();
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const gridLoanTagHistoryRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'TAG_HISTORY');

    

    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }

    const columns: any[] = [        
        { headerName: 'Tag Name', field: 'tag_name', headerTooltip: 'Tag Name', width: 300 },
        { headerName: 'Tag Description', field: 'tag_description', headerTooltip: 'Tag Description', width: 350 },
        { headerName: 'Action', field: 'is_active', width: 200, headerTooltip: 'Action'
        },
        { headerName: 'Action Performed By', field: 'created_by', width: 230 , headerTooltip: 'Action Performed By',filter: 'agDateColumnFilter' ,cellRendererFramework: (params) => (
            <div>
                <p>
                    {params.data.modified_by?params.data.modified_by:params.data.created_by}
                </p>
            </div>
        )},
        {
            headerName: 'Action Performed Date', field: 'created_date', headerTooltip: 'Action Performed Date', width: 220,filter: 'agDateColumnFilter',
            cellRendererFramework: (params) => (
                <div>
                    <p>
                   {dateTimeToLocal(params.data.created_date)}
                    </p>
                </div>
            )
            

        }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    return (
        <>
            <div className="row" style={{ marginBottom: "1rem" }}>
                <div>
                    <button className="btn btn-outline-primary btn-sm float-end" type="submit" onClick={() => {dispatch(setTags({ ...loanState?.tags, is_edit: false})); props.toggle()}}>
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back </button>
                </div>
            </div>
            
            {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridLoanTagHistoryRef}
                        ></GenericAgGrid>
                    </div>}
            
        </>
    )
}

export default LoanTagHistory;