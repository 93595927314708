import { listTag  } from './../graphql/queries';
import { createTag,deleteTag ,updateTag } from './../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';


export const getTagList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listTag));
        if (response && response.data && response.data.listTag) {
            let userData = response.data.listTag.map(x => {
                x = { ...x, dashboards_list: x.dashboards_list ? JSON.parse(x.dashboards_list).data : [], reports_list: x.reports_list ? JSON.parse(x.reports_list).data : [] };
                return x;
            });
            return userData;
        }
        return null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertTag = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createTag, { input: data }));
        return response && response.data && response.data.createTag ? JSON.parse(response.data.createTag).postgresResponse[0]  : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateTagData = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateTag, { input: data}));
        return response && response.data && response.data.updateTag ? JSON.parse(response.data.updateTag).postgresResponse  : null;

    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const deleteTagByTagId = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteTag, { input: data }));
        return response && response.data && response.data.deleteTag ? JSON.parse(response.data.deleteTag).postgresResponse: null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}