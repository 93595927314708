import { useEffect, useState } from "react";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../common/CustomPopupComponent";
import { Loader } from "../../helpers/loader";
import {
  setLoaderMessage,
  readAppState,
  setCurrentUser,
} from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { readTransformerLogsState } from "../../redux/reducers/transformerLogsReducer";
import awsmobile from "../../aws-exports";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { updateUserData, loadProfile } from "../../services/user.service";

const Profile = (props: any) => {
  const appState = useAppSelector(readAppState);
  const [loader, setLoader] = useState(false);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const dispatch = useAppDispatch();
  const [localState, setLocalState] = useState<any>({
    file: undefined as any,
  });
  const [profileImage, setProfileImage] = useState("" as any);
  const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
  const profileBucket = process.env.REACT_APP_PROFILE_BUCKET;

  useEffect(() => {
    loadProfileData();
  }, [localState]);

  const loadProfileData = async () => {
    if (props?.user?.profile_path) {
      const res = await loadProfile(props?.user?.profile_path);
      setProfileImage(res);
    }
  };

  const handleFileSelect = (e: any) => {
    if (e.target.files) {
      for (let fileObject in e.target.files) {
        if (typeof e.target.files[fileObject] === "object") {
          const fileData = e.target.files[fileObject];
          if (fileData.name.includes(" ")) {
            setPopup({
              ...popup,
              visible: true,
              message: "File name should not contain empty spaces!",
              type: ModalType.Snackbar,
              variant: VariantType.Error,
              toggle: () => setPopup({ ...defaultPopup }),
            });
            return;
          }
          if (!/^image\//.test(fileData.type)) {
            setPopup({
              ...popup,
              visible: true,
              message: 'The selected file format is not supported, please upload file of supported format(PNG, JPEG .etc).',//'File - ' + fileData.name + ' is not an image.',
              type: ModalType.Snackbar,
              variant: VariantType.Error,
              toggle: () => setPopup({ ...defaultPopup }),
            });
            return false;
          }
          const fileBlob = new Blob([fileData], { type: fileData.type });
          const data = fileData.name.split(".");
          const imageFormat = data[data.length - 1];
          setLocalState({
            ...localState.file,
            file: {
              fileData: e.target.files[0],
              accessUrl: URL.createObjectURL(fileBlob),
              fileName: `profile${props?.user?.maas_user_id}.${imageFormat}`,
              fileType: fileData.type,
              isUploaded: false,
            },
          });
        }
      }
    }
  };
  const onCancel = () => {
    setLocalState({ ...localState, file: undefined });
    setLoader(false);
  };

  const uploadFile = async (data: any) => {
    try {
      let file = data.file.fileData;
      const target = {
        Bucket: data.bucket,
        Key: `${data.folderName}/${data.file.fileName}`,
        Body: file,
      };
      const credsdata = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: awsmobile.aws_cognito_region,
        }),
        identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      });
      const uploadParallel = new Upload({
        client: new S3Client({
          region: awsmobile.aws_cognito_region,
          credentials: credsdata,
        }),
        params: target,
        leavePartsOnError: false,
      });
      dispatch(setLoaderMessage(`Uploading image!`));
      uploadParallel.on("httpUploadProgress", (progress) => {
        if (progress.loaded && progress.total) {
          setLoader(true)
        }
      });
      const response = await uploadParallel.done();
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const handleUpload = async () => {
    if (localState.file) {
      setLoader(true);
      const data = await uploadFile({
        file: localState.file,
        folderName: `public/profile/${props?.user?.maas_user_id}`,
        bucket: profileBucket,
      });
      if (data) {
        const filePath = `profile/${props?.user?.maas_user_id}/${localState?.file?.fileName}`;
        let res: any = await updateUserData(
          "profile_path",
          filePath,
          appState?.currentUser?.user_id
        );
        dispatch(
          setCurrentUser({ ...appState.currentUser, profile_path: "" })
        )
        dispatch(
          setCurrentUser({ ...appState.currentUser, profile_path: filePath })
        )
        setLocalState({
          ...localState,
          file: undefined
        })
        setLoader(false);
        dispatch(setLoaderMessage(""));
        setPopup({
          ...popup,
          visible: true,
          message: "Your profile picture has been updated successfully.",
          type: ModalType.Snackbar,
          variant: VariantType.Success,
          toggle: () => setPopup({ ...defaultPopup }),
        });
        localStorage.removeItem("loaderMessage");
      }
      else {
        setLoader(false);
        setPopup({
          ...popup,
          visible: true,
          message: "Something went wrong! Please upload the file again.",
          type: ModalType.Snackbar,
          variant: VariantType.Error,
          toggle: () => setPopup({ ...defaultPopup }),
        });
      }
    }
    else {
      setLoader(false);
      setPopup({
        ...popup,
        visible: true,
        message: "Select file first",
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup }),
      });
    }
  };
  return (
    <>
      {popup.visible && (
        <CustomPopupComponent
          isOpen={popup.visible}
          toggle={popup.toggle}
          message={popup.message}
          type={ModalType.Snackbar}
          variant={popup.variant}
        />
      )}
      {loader && <Loader />}
      <div className="profile_edit_form">
        <div className="profile-box m-auto text-center">
          {localState?.file?.accessUrl ? (
            <img
              src={localState?.file?.accessUrl}
              className="mt-2 profile-image"
              alt="Profile image"
            />
          ) : props?.user?.profile_path ? (
            <img
              src={profileImage}
              className="mt-2 profile-image"
              alt="Profile image"
            />
          ) :
            <img
              src={massCdnUrl + "assets/img/userpic.png"}
              className="mt-2 profile-image"
              alt="Profile image"
            />
          }
          <div className="edit-box text-center">
            <input
              id="tapeFile"
              style={{ display: "none" }}
              type="file"
              name="files"
              accept=".png, .jpg, .jpeg"
              onClick={(event: any) => (event.target.value = null)}
              onChange={(e) => handleFileSelect(e)}
            />
            <label
              className=" vertical-center edit-profile"
              htmlFor="tapeFile"
            >
              Edit <span className="fa fa-pen-square" style={{ color: "#006b7f", cursor: "pointer" }}></span>
            </label>
          </div>
        </div>

        <div className="row justify-content-sm-center mt-3 mb-1 ">
          <div className="col-lg-6 col-md-6 col-sm-5 col gx-1" >
            <button
              className="btn btn-primary text-center btn-block"
              disabled={!localState.file}
              onClick={handleUpload}
              style={{marginLeft:"-3px"}}
            >
              Save
            </button>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-5 col gx-0" >
            <button
              className="btn btn-outline-primary text-center btn-block mx-1"
              disabled={!localState.file}
              onClick={onCancel}
              style={{paddingLeft:"5px"}}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
