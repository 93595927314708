import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { SQLOperations } from "../../constants/BusinessRuleConstants";
import { setSelectedRule } from "../../redux/reducers/ruleReducer";
import { useForm } from "react-hook-form";
/* eslint-disable jsx-a11y/anchor-is-valid */
const AdvanceSQLSidebar = (props: any) => {
    //const [wordEntered, setWordEntered] = useState("");
    const [filteredData, setFilteredData] = useState<any>({ fieldList: [], operation: [] });
    const dispatch = useAppDispatch();
    const { handleSubmit} = useForm();
    const [isShow, setIsShow] =useState(false);

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        props.setWordEntered(searchWord);
        const newfieldFilter = props.fieldList.filter((value) => {
            return value.toLowerCase().includes(searchWord.toLowerCase());
        });
        const newOperationFilter = SQLOperations.filter((value) => {
            return value.operationName.toLowerCase().includes(searchWord.toLowerCase());
        });
        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData({ operation: newOperationFilter, fieldList: newfieldFilter });
        }

        const showElement = document.querySelector('#vertacc1[class*="collapse show"]');
        if(showElement){
            setIsShow(true);
        }
        else{
            setIsShow(false);
        }
    };

    const concateSQLQuery = (value, sqlString) => {
        let updatedRule: any = { ...props.ruleState.rule };
        var new_input;
        if (sqlString && sqlString.toLowerCase().includes('field')) {
            new_input = sqlString.replace('field', value);
        }
        else if (sqlString) {
            new_input = sqlString + " " + value;
        }
        else {
            new_input = value;
        }
        updatedRule['rule_criteria'] = new_input;
        dispatch(setSelectedRule({ ...updatedRule }))
    }

    const submitHandler =(event) =>{
        event.preventDefault();
    }

    const templateWordFilter = props.fieldList.filter((value) => {
        return value.toLowerCase().includes(props.wordEntered.toLowerCase());
    });

    const operationWordFilter = SQLOperations.filter((value) => {
        return value.operationName.toLowerCase().includes(props.wordEntered.toLowerCase());
    });

    return (
        <div className="col-xl-4 col-lg-12 col-md-12">
            <div className="block_sect">
                <div className="search_box_add_rule">
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <span className="addb_operation_search">
                            <input className="form-control" type="search" placeholder="Search" aria-label="Search"
                               value={props.wordEntered} onChange={handleFilter}/>
                        </span>
                    </form>
                </div>

                <div className="accordion" id="accordionExample">
                <div className="accordion_card1">
                        <h2 className="accordion_card1_title">
                            <a className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#vertacc2" aria-expanded="false">
                                Template Fields Library
                            </a>
                        </h2>
                        <div id="vertacc2" className={ (!isShow && props.wordEntered) ? 'collapse show' : 'collapse' } data-bs-parent="#accordionExample">
                            <div className="accordion_card1_body">
                                <div className="verticalmenu">
                                    {props.ruleState.rule.rule_view && props.wordEntered ?
                                        filteredData !== undefined && filteredData.length !== 0 &&
                                        filteredData.fieldList.map((value, index) => {
                                            return (
                                                <a key={`editBusinessRule${index}`} onClick={() => concateSQLQuery(value, props.ruleState.rule?.rule_criteria)}>{value && value.replaceAll("_", " ").toUpperCase()}</a>
                                            )
                                        })
                                        : props.fieldList.length > 0 && props.fieldList.map((field, index) => {
                                            return (
                                                <a key={`editBusinessRule${index}`} onClick={() => field && concateSQLQuery(field, props.ruleState.rule?.rule_criteria)}>{field && field.replaceAll("_", " ").toUpperCase()}</a>
                                            )
                                        })}
                                        {!props.ruleState.rule.rule_view && <p className="error-message text-center rule-text-center">Please select any Rule View to access Template Fields Library.</p>}
                                    {props.ruleState.rule.rule_view && templateWordFilter.length === 0 && <p className="error-message text-center rule-text-center">The searched field is not available in the template library.</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion_card1">
                        <h2 className="accordion_card1_title"><a className="btn"  data-bs-toggle="collapse" data-bs-target="#vertacc1"
                            aria-expanded={ (isShow && props.wordEntered) ? "true" : "false"}>
                            Operation Library
                        </a></h2>
                        <div id="vertacc1" className={ (isShow && props.wordEntered) ? 'collapse show' : 'collapse' }  data-bs-parent="#accordionExample">
                            <div className="accordion_card1_body">
                                <div className="verticalmenu">
                                    {props.wordEntered ?
                                        filteredData !== undefined && filteredData.length !== 0 &&
                                        filteredData.operation.map((value, index) => {
                                            return (
                                                <a key={`addBusinessRule${index}`} onClick={() => concateSQLQuery(value.operationValue, props.ruleState.rule?.rule_criteria)}>{value.operationName}</a>
                                            ) 
                                        })
                                        :
                                        SQLOperations.map((opr, index) => {
                                            return (
                                                <a key={`addBusinessRule${index}`} onClick={() => concateSQLQuery(opr.operationValue, props.ruleState.rule?.rule_criteria)}>{opr.operationName}</a>
                                            )
                                        })}
                                        {operationWordFilter.length === 0 && <p className="error-message text-center rule-text-center">Entered operation is invalid</p>}
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    )
}

export default AdvanceSQLSidebar;