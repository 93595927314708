/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { readAppState, setBreadcrumbLocation } from "../redux/reducers/appReducer";
import QuickLoanSearch from "./LoanDetailComponents/QuickLoanSearch";
import Footer from "./StaticComponents/Footer";
import Header from "./StaticComponents/Header";
import SideMenu from "./StaticComponents/SideMenu";

const ProtectedRoute = () => {;
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const isLoggedIn = localStorage?.getItem("isLogin");
  const appState = useAppSelector(readAppState);
  const dashboardState = useAppSelector((state: RootState) => state.dashboard);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  let redirectUrl = (window.location.href).split("/")[3];

  useEffect(() => {
    if (appState.screensAccessible && appState.screensAccessible.length > 0) {
      const breadcrumbLocation = [...appState.breadcrumbLocation];
      const currentLocation = location.pathname.split('/');
      if (currentLocation.length > 2) {
        if (breadcrumbLocation.length <= 1) {
          const updatedBreadcrumb: any[] = [];
          for (var i = 1; i <= currentLocation.length; i++) {
            const screen = appState.screensAccessible.find(x => x.redirect_url === `/${currentLocation[i]}`);
            if (screen) {
              updatedBreadcrumb.push({
                "screen_id": screen.screen_id,
                "screen_name": screen.screen_name,
                "redirect_url": i > 1 ? `./${currentLocation[1]}` + screen.redirect_url : screen.redirect_url,
                "icon_url": screen.icon_url
              })
            }
          }
          dispatch(setBreadcrumbLocation(updatedBreadcrumb));
        }
      }
      else {
        if (breadcrumbLocation.length > 1 || breadcrumbLocation[0]?.redirect_url !== `/${currentLocation[1]}`) {
          const screen = appState.screensAccessible.find(x => x.redirect_url === `/${currentLocation[1]}`);
          if (screen) {
            dispatch(setBreadcrumbLocation([{
              "screen_id": screen.screen_id,
              "screen_name": screen.screen_name,
              "redirect_url": screen.redirect_url,
              "icon_url": screen.icon_url
            }]));
          }
          else{
            if(redirectUrl ==="" || redirectUrl === "login"){
              dispatch(setBreadcrumbLocation([{
                "screen_id": appState?.screensAccessible[0]?.screen_id,
                "screen_name": appState?.screensAccessible[0]?.screen_name,
                "redirect_url": appState?.screensAccessible[0]?.redirect_url,
                "icon_url": appState?.screensAccessible[0]?.icon_url
            }]));
            navigation(appState?.screensAccessible?.length > 0 ? `${appState?.screensAccessible[0].redirect_url}` : "/");
          }
        }

        }
        // else {
        //   if () {
        //     const screen = appState.screensAccessible.find(x => x.redirect_url === `/${currentLocation[1]}`);
        //     dispatch(setBreadcrumbLocation([{
        //       "screen_id": screen.screen_id,
        //       "screen_name": screen.screen_name,
        //       "redirect_url": screen.redirect_url,
        //       "icon_url": screen.icon_url
        //     }]));
        //   }

        // }
      }
    }
  }, [location]);

  return (
    isAuthenticated || isLoggedIn === "true" ?
    <div className={!appState.isNavMenuOpen ? "toggle-sidebar" : ""}>
    <Header />
    <SideMenu />
    <main id="main" className="main m-main-contianer">
      {!dashboardState.showSearchBar && <div className="m-search-box"> <QuickLoanSearch /></div>}
      <Outlet />
    </main>
    <Footer />
  </div> :
  <Navigate to="/login" />
  );
}

export default ProtectedRoute;
