import { API, graphqlOperation } from "aws-amplify";
import { createComment, deleteComment, updateComment } from "../graphql/mutations";
import { getLoanPropertyDetails, getInspectionDetails, getCodeViolations, getLossDrafts, getDisbursements, getLoanDetails, getLoanTransaction, getNotes, getComments, getPaymentAndBalance, getPropertyDetails, getWorkflowType, listWorkflowTask, getTaxDetails, getHoaDetails, getLoanDocuments,getWorkflowTypeById } from "../graphql/queries";


export const fetchLoanDetails = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanDetails, { loan_number: data.loanId, scope_id: data.groupId }));
        return response.data.getLoanDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const getPropertyDetail = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getPropertyDetails, { loan_number: params.loan_number }));
        return response.data.getPropertyDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const getNotesList = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getNotes, params));
        return response.data.getNotes;

    } catch (ex) {
        console.log(ex);
    }
}

export const getCommentList = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getComments, params));
        return response.data.getComments;

    } catch (ex) {
        console.log(ex);
    }
}

export const insertComment = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(createComment, { input: data }));
        return response && response.data ? response.data.createComment : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateCommentData = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(updateComment, { input: data }));
        return response && response.data ? response.data.updateComment : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteCommentById = async (loan_comment_id: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(deleteComment, { input: { loan_comment_id: loan_comment_id } }));
        console.log(response)
        return response && response.data ? response.data.deleteComment : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getLoanTransactions = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanTransaction, params));
        return response.data.getLoanTransaction;

    } catch (ex) {
        console.log(ex);
    }
}

export const getPaymentAndBalanceDetail = async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getPaymentAndBalance, { loan_number: data.loanId }));
        return response.data.getPaymentAndBalance;

    } catch (ex) {
        console.log(ex);
    }
}

export const getDisbursementDetails = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getDisbursements, params));
        return response.data.getDisbursements;

    } catch (ex) {
        console.log(ex);
    }
}

export const listWorkflowTasks = async (params: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(listWorkflowTask, params));
        return response && response.data && response.data.listWorkflowTask ? response.data.listWorkflowTask : null;
    }
    catch (e) {
        console.log(e, 'error');
        return [];
    }
}

export const listWorkflowType = async (params: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getWorkflowType, { loan_number: params }));


        return response && response.data && response.data.getWorkflowType ? response.data.getWorkflowType : null;
    }
    catch (e) {
        console.log(e, 'error');
        return [];
    }
}

export const listWorkflowTypeById = async (params: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getWorkflowTypeById, { loan_number: params }));


        return response && response.data && response.data.getWorkflowTypeById ? response.data.getWorkflowTypeById : null;
    }
    catch (e) {
        console.log(e, 'error');
        return [];
    }
}

export const getLoanPropertyDetail = async (loanNumber: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanPropertyDetails, { loan_number: loanNumber }));
        return response.data.getLoanPropertyDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const getInspectionDetail = async (loanNumber: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getInspectionDetails, { loan_number: loanNumber }));
        return response.data.getInspectionDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const getCodeViolationsDetails = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getCodeViolations, params));
        return response.data.getCodeViolations;

    } catch (ex) {
        console.log(ex);
    }
}

export const getLossDraftsDetails = async (params: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLossDrafts, params));
        return response.data.getLossDrafts;

    } catch (ex) {
        console.log(ex);
    }
}

export const getTaxDetail = async (loanNumber: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getTaxDetails, { loan_number: loanNumber }));
        return response.data.getTaxDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const getHoaDetail = async (loanNumber: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getHoaDetails, { loan_number: loanNumber }));
        return response.data.getHoaDetails;

    } catch (ex) {
        console.log(ex);
    }
}

export const GetLoanDocumentsByLoanSkey = async (loanSkey: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(getLoanDocuments, { loan_skey: loanSkey }));
        return response.data.getLoanDocuments;

    } catch (ex) {
        console.log(ex);
    }
}