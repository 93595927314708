/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import formatDate from '../../constants/dateFormat';
import formatAmount from '../../constants/formatAmount';
import Payments from "./LoanScreenDetails/Payments";
import LoanTransactions from "./LoanScreenDetails/LoanTransations";
import FeeAndCharges from "./LoanScreenDetails/FeeAndCharges";
import { getLoanDetail } from "../../services/getLoanData.service";
import LoanNotes from "./LoanScreenDetails/LoanNotes";
import { Loader } from "../../helpers/loader";
import { useAppSelector } from "../../app/hooks";
import { readAppState } from "../../redux/reducers/appReducer";


const LoanDetails = ({ loan_id, refresh, setRefresh }) => {
  const [loanDetails, setLoanDetails] = useState([] as any);
  const [activeTab, setActiveTab] = useState("payment");
  const [loading, setLoader] = useState(true);
  const appState = useAppSelector(readAppState);

  useEffect(() => {
    handleLoanDetails();
    return () =>{}
  }, [loan_id]);

  useEffect(() => {
    if (refresh) {
      setLoader(true);
      handleLoanDetails();
      setRefresh(false);
    }
  }, [refresh]);

  const handleLoanDetails = async () => {
    let userGroups = appState.userGroups ? appState.userGroups : [];
    let userGroupId = userGroups.find(g => g.group_id === appState.currentUser?.group_id)?.logi_user_id;
    const loanData: any = await getLoanDetail(loan_id, userGroupId);
    if (loanData) {
      setLoanDetails(loanData ? loanData : []);
      setLoader(false);
    }
  }

  return (
    <>
      {loading && <Loader />}
      {!loading &&
        <>
          <div className="header_seciton2">
            <div className="container-fluid position-relative">
              <div className="header2_inner">
                <div className="h2_sect_col2">
                  <div className="customer-detail-box1">
                    <p>Borrower</p>
                    {<h5>{loanDetails?.borrower_first_name} {loanDetails?.borrower_last_name}</h5>}
                  </div>
                  <div className="customer-detail-box1">
                    <p>Co-Borrower(s)</p>
                    {<h5>{loanDetails?.co_borrower_first_name} {loanDetails?.co_borrower_last_name}</h5>}
                  </div>
                  <div className="customer-detail-box1">
                    <p>Loan Number</p>
                    <h5>{loanDetails?.maas_loan_number}</h5>
                  </div>
                  <div className="customer-detail-box1">
                    <p>Loan Status</p>
                    <h5>{loanDetails?.current_loan_status_description}</h5>
                  </div>
                  <div className="customer-detail-box1">
                    <p>Property Address</p>
                    {loanDetails &&
                      <h5>{loanDetails.property_address_1 ? loanDetails.property_address_1 + "," : ""} {loanDetails.property_city ? loanDetails.property_city + "," : ""} {loanDetails.property_state ? loanDetails.property_state + "," : ""} {loanDetails.property_zip ? loanDetails.property_zip + "," : ""}</h5>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="message_page">
            <div className="container-fluid position-relative">
              <div className="content_section">
                <div className="dashboard-content">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card_widget mb20">
                        <div className="card_w_header"> Loan Terms</div>
                        <div className="card_w_body">
                          <ul className="l_info_list_group l_info_two_2col">
                            <li>
                              <span>Origination Date</span><strong>{loanDetails?.funded_date ? formatDate(loanDetails?.funded_date) : "---"}</strong>
                            </li>
                            <li>
                              <span>Original Principal Balance</span><strong>{formatAmount(loanDetails?.note_amount)}</strong>
                            </li>
                            <li>
                              <span>Current Principal Balance</span><strong>{formatAmount(loanDetails?.current_principal_balance)}</strong>
                            </li>
                            <li>
                              <span>Maturity Date</span><strong>{loanDetails?.maturity_date ? formatDate(loanDetails?.maturity_date) : "---"}</strong>
                            </li>
                            <li>
                              <span>Loan Term</span><strong>{`${loanDetails?.original_term ? loanDetails?.original_term : ""} Mo/ ${loanDetails?.amortization_term / 12 ? loanDetails?.amortization_term / 12 : ""} Yrs`}</strong>
                            </li>
                            <li>
                              <span>Remaining Term</span><strong>{`${loanDetails?.current_loan_term ? loanDetails?.current_loan_term : " "} Mo/ ${loanDetails?.current_loan_term / 12 ? (loanDetails?.current_loan_term / 12).toFixed(2) : ""} Yrs`}</strong>

                            </li>
                            <li>
                              <span>Current Interest Rate</span><strong>{`${loanDetails?.current_interest_rate ? loanDetails?.current_interest_rate.toFixed(2) : 0.00}%`}</strong>
                            </li>
                            <li>
                              <span>Loan Type</span><strong>{loanDetails?.amortization_type}</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="card_widget mb20">
                        <div className="card_w_header">
                          Loan Information
                        </div>
                        <div className="card_w_body">
                          <ul className="l_info_list_group l_info_two_2col">
                            <li>
                              <span>Loan Boarding Date</span><strong>{loanDetails?.boarded_date ? formatDate(loanDetails?.boarded_date) : "---"}</strong>
                            </li>
                            <li>
                              <span>Investor Name</span><strong>{loanDetails?.current_investor_name}</strong>
                            </li>
                            <li>
                              <span>Investor ID	</span><strong>{loanDetails?.current_investor_id}</strong>
                            </li>
                            <li>
                              <span>Product Type	</span><strong>{loanDetails?.product_type}</strong>
                            </li>
                            <li>
                              <span>Property Type	</span><strong>{loanDetails?.property_type}</strong>
                            </li>
                            <li>
                              <span>Lien Position</span><strong>{loanDetails?.lien_position}</strong>
                            </li>
                            <li>
                              <span>Value </span><strong>{formatAmount(loanDetails?.current_bpo_value)}</strong>
                            </li>
                            <li>
                              <span>Value Date</span><strong>{loanDetails?.current_bpo_value_date ? formatDate(loanDetails?.current_bpo_value_date) : "---"}</strong>
                            </li>
                            <li>
                              <span>LTV Ratio	</span><strong>{`${loanDetails?.ltv_ratio ? loanDetails?.ltv_ratio : "0.00 "}%`}</strong>
                            </li>
                            <li>
                              <span>Current FICO	</span><strong>{loanDetails?.latest_fico_score}</strong>
                            </li>
                            <li>
                              <span>Occupancy</span><strong>{loanDetails?.occupancy}</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-4">
                      <div className="card_widget mb20">
                        <div className="card_w_header">Contact Information</div>
                        <div className="card_w_body">
                          <ul className="l_info_list_group">
                            <li>
                              <span>Mailing Address</span>
                              {loanDetails &&
                                <strong>{loanDetails.borrower_mail_address1 ? loanDetails.borrower_mail_address1 + "," : ""} {loanDetails.borrower_mail_city ? loanDetails.borrower_mail_city + "," : ""} {loanDetails.borrower_mail_state_code ? loanDetails.borrower_mail_state_code + "," : ""}{loanDetails.borrower_mail_zip_code ? loanDetails.borrower_mail_zip_code + "," : ""}</strong>
                              }
                            </li>
                            <li>
                              <span>Email Address</span><strong>{loanDetails?.borrower_email}</strong>
                            </li>
                            <li>
                              <span>Phone</span><strong>{loanDetails?.borrower_phone?.replaceAll(' ', '').replace(/(\d{3})(\d{3})(\d{4})/, "($1)  $2-$3")}</strong>
                            </li>

                          </ul>
                        </div>
                      </div>

                    </div>
                  </div>
                </div >
                <br />
                <div className="">
                  <ul className="nav cm_tabs" id="pills-tab" role="tablist">

                    <li><a className={`nav-link ${activeTab === "payment" ? "active" : ""}`} onClick={() => setActiveTab("payment")} id="dash-one-tab" data-toggle="tab" href="#dash-one" role="tab" aria-controls="dash-one" aria-selected="true"><span>Payment</span></a></li>

                    <li> <a className={`nav-link ${activeTab === "loantransactions" ? "active" : ""}`} onClick={() => setActiveTab("loantransactions")} id="dash-three-tab" data-toggle="tab" href="#dash-three" role="tab" aria-controls="dash-three" aria-selected="false"><span>Loan Transactions</span></a></li>

                    <li><a className={`nav-link ${activeTab === "feeAndCharges" ? "active" : ""}`} onClick={() => setActiveTab("feeAndCharges")} id="dash-five-tab" data-toggle="tab" href="#dash-five" role="tab" aria-controls="dash-five" aria-selected="false"><span>{"Fee & Charges"}</span></a></li>

                    <li> <a className={`nav-link ${activeTab === "notes" ? "active" : ""}`} onClick={() => setActiveTab("notes")} id="dash-six-tab" data-toggle="tab" href="#dash-six" role="tab" aria-controls="dash-six" aria-selected="false"> <span>Notes</span></a></li>
                  </ul>
                </div>
                <br />


                <div className="tab-content" id="dash-tabContent">
                  <div className={`tab-pane fade ${activeTab === "payment" ? "show active" : ""}`} id="dash-one" role="tabpanel" aria-labelledby="dash-one-tab">
                    <div className="dashboard-content">
                      <Payments loanDetails={loanDetails} /> 
                    </div>
                  </div>

                  <div className={`tab-pane fade ${activeTab === "loantransactions" ? "show active" : ""}`} id="dash-three" role="tabpanel" aria-labelledby="dash-three-tab">
                    <div className="dashboard-content">
                      <LoanTransactions loan_id={loan_id}  isActive = {activeTab === "loantransactions"}/>
                    </div>
                  </div>

                  <div className={`tab-pane fade ${activeTab === "feeAndCharges" ? "show active" : ""}`} id="dash-five" role="tabpanel" aria-labelledby="dash-five-tab">
                    <div className="dashboard-content">
                      <FeeAndCharges loan_id={loan_id} />
                    </div>
                  </div>

                  <div className={`tab-pane fade ${activeTab === "notes" ? "show active" : ""}`} id="dash-six" role="tabpanel" aria-labelledby="dash-six-tab">
                    <div className="dashboard-content">
                      <LoanNotes loan_id={loan_id} isActive = {activeTab === "notes"} />
                    </div >
                  </div >
                </div >
              </div >
            </div >
          </div >
          <br />
        </>}
    </>
  )
}

export default LoanDetails;


