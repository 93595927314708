/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { getCodeViolationsAsync } from "../../../redux/reducers/loanReducer";
import formatDateTable from '../../../constants/dateFormatTable';
import {readAppState}from "../../../redux/reducers/appReducer"
import formatAmountTable from '../../../constants/formatAmountTable';
import GenericAgGrid from "../../common/GenericAgGrid";

const CodeViolations = (props: any) => {
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    const gridCodeViolationRef: any = useRef();
    // const gridRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'CODE_VIOLATION');

    useEffect(() => { }, [activeTab]);

    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        
         return customFilter;
    }

    const columns = [
        { headerName: 'Active Code Violation', field: 'is_active_code_violation', width: 150 },
        { headerName: 'CV Type', field: 'cv_type', width: 150 },
        { headerName: 'CV Received Date', field: 'cv_received_date', width: 150,filter: 'agDateColumnFilter', valueFormatter: (param) => formatDateTable(param, true)},
        { headerName: 'CV Received From', field: 'cv_received_from', width: 150 },
        { headerName: 'CV Fine Amount', field: 'cv_fine_amount', width: 150,filter:'agNumberColumnFilter',valueFormatter: formatAmountTable,type: 'rightAligned'  }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    return (
        <div >
                 {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridCodeViolationRef}
                        ></GenericAgGrid>
                    </div>}
            
        </div>
    );
}

export default CodeViolations;