/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setCommentList,readLoanState } from "../../../redux/reducers/loanReducer";
import CommentsDetailForm from "./CommentsDetailForm";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { deleteCommentById, getCommentList } from '../../../services/loan.service';
import { dateTimeToLocal } from '../../../constants/convertToClientDateTime';
import formatDateTable from '../../../constants/dateFormatTable';
import {readAppState}from "../../../redux/reducers/appReducer"
import GenericAgGrid, { getCurrentFilterValue } from "../../common/GenericAgGrid";

const Comments = (props: any) => {
    const dispatch = useAppDispatch();
    const [isShown, setIsShown] = useState(false);
    const [commentData, setCommentData] = useState<any>(null);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const loanState = useAppSelector(readLoanState);
    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const appState = useAppSelector(readAppState);
    const gridCommentRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'COMMENTS');


    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }



    const columns: any[] = [
        {
            headerName: 'Comment#', field: 'sr_no', width: 150, headerTooltip: 'Comment#',filter:false
        },
        { headerName: 'Comment Type', field: 'comment_type', width: 260, headerTooltip: 'Comment Type' },
        { headerName: 'Description', field: 'comment_description', width: 260 , headerTooltip: 'Description'},
        { headerName: 'Created By', field: 'created_by', width: 150 , headerTooltip: 'Created By'},
        {
            headerName: 'Created Date', field: 'created_date', width: 200, headerTooltip: 'Created Date',filter: 'agDateColumnFilter', 
            cellRendererFramework:(params)=>(
                <div>
                    <p>
                        {
                            dateTimeToLocal(params.data.created_date)
                        }

                    </p>
                </div>
            )

            ,
            
        },
        { headerName: 'Modified By', field: 'modified_by', width: 150, headerTooltip: 'Modified By' },
        {
            headerName: 'Modified Date', field: 'modified_date', width: 200, headerTooltip: 'Modified Date',filter: 'agDateColumnFilter',
            cellRendererFramework:(params)=>(
                <div>
                    <p>
                        {
                            dateTimeToLocal(params.data.modified_date)
                        }

                    </p>
                </div>
            )  
        },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 110, suppressSizeToFit: true, headerTooltip: 'Action', cellRendererFramework: (params) => <div className="grid-action">
                <button onClick={() => { handleClick(params.data); }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
                <button title={"Delete"}
                    onClick={() =>
                        setPopup({
                            ...popup,
                            visible: true,
                            message: "Please confirm that you would like to delete this comment.",
                            type: ModalType.ActionDialog,
                            toggle: () => setPopup({ ...defaultPopup }),
                            primaryButtonAction: () => removeComment(params),
                            primaryButtonName: "Delete",
                            secondaryButtonName: "Cancel",
                            secondaryButtonAction: () => setPopup({ ...defaultPopup })
                        })} className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
            </div >
        }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const handleClick = (data) => {
        preserveFilter(data)
        setIsShown(current => !current);
        
    };
    
    const removeComment = async (params) => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
            const selectedData = params.data;
            await deleteCommentById(selectedData.loan_comment_id);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "This Comment has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
            gridCommentRef.current.api.refreshServerSideStore();
        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "Comment could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
    }

    const preserveFilter=(params: any)=>{
        let filterValue=getCurrentFilterValue(gridCommentRef);
        if(params==true)
        {
            dispatch(setCommentList({  is_new: true,is_edit:true,current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel, saved_sort:filterValue.SortState}));
        }else{

            dispatch(setCommentList({ current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel,loan_comment_id:params.loan_comment_id,comment_type:params.comment_type,comment_description:params.comment_description, saved_sort:filterValue.SortState , is_new: false,is_edit:true }));
        }
    }

    const afterGridLoad=()=>{
        if((!loanState.commentsData?.is_edit) && (loanState.commentsData?.saved_sort || loanState.commentsData?.saved_filter)){
            gridCommentRef.current.columnApi.applyColumnState({
                    state: loanState.commentsData.saved_sort,
              });
              gridCommentRef.current.api.setFilterModel(loanState.commentsData.saved_filter);
              dispatch(setCommentList({ ...loanState?.commentsData, is_edit:true,current_page:0}));
              
        }else{
            dispatch(setCommentList({ ...loanState?.commentsData, saved_sort: null,saved_filter:null,current_page:0}));
        }
    }

    return (
        <div >
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
                {!isShown && <><div className="row mb20">
                    <div className="col-12 col-lg-12 col-md-12 mb-3">
                        <div className="float-end">
                            <a className="btn btn-primary btn-sm " id="balance_trans" onClick={() => {handleClick(true);}}>
                                Add Comment
                            </a>
                        </div>
                    </div>
                </div>
                {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridCommentRef}
                            afterGridLoad={afterGridLoad}
                            currentPage={loanState.commentsData?.current_page}
                        ></GenericAgGrid></div>}
                    </>}
            {isShown && <CommentsDetailForm data={loanState?.commentsData} loan_id={props.loan_id} toggle={() => setIsShown(false)} />}
        </div>
    )
}

export default Comments;