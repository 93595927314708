/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import formatDate from '../../constants/dateFormat';
import formatAmount from '../../constants/formatAmount';
import { Loader } from "../../helpers/loader";
import { readAppState } from "../../redux/reducers/appReducer";
import { getLoanDetail } from "../../services/getLoanData.service";


const LoanBankruptcyDetails = ({ loan_id, refresh, setRefresh }) => {
    const [loanDetails, setLoanDetails] = useState([] as any);
    const [loading, setLoader] = useState(true);
    const appState = useAppSelector(readAppState);


    useEffect(() => {
        handleLoanDetails();
    }, [loan_id]);

    useEffect(() => {
        if (refresh) {
            setLoader(true);
            handleLoanDetails();
            setRefresh(false);
        }
    }, [refresh]);


    const handleLoanDetails = async () => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId = userGroups.find(g => g.group_id === appState.currentUser?.group_id)?.logi_user_id;
        const loanData: any = await getLoanDetail(loan_id, userGroupId);
        if (loanData) {
            console.clear();
            setLoanDetails(loanData ? loanData : []);
            setLoader(false);
        }
    }

    return (
        <>
            <div className="header_seciton2">
                {loading && <Loader />}
                <div className="container-fluid position-relative">

                    <div className="header2_inner">
                        <div className="h2_sect_col2">
                            <div className="customer-detail-box1">
                                <p>Borrower</p>
                                {<h5>{loanDetails?.borrower_first_name} {loanDetails?.borrower_last_name}</h5>}
                            </div>
                            <div className="customer-detail-box1">
                                <p>Co-Borrower(s)</p>
                                {<h5>{loanDetails?.co_borrower_first_name} {loanDetails?.co_borrower_last_name}</h5>}
                            </div>
                            <div className="customer-detail-box1">
                                <p>Loan Number</p>
                                <h5>{loanDetails?.maas_loan_number}</h5>
                            </div>
                            <div className="customer-detail-box1">
                                <p>Loan Status</p>
                                <h5>{loanDetails?.current_loan_status_description}</h5>
                            </div>
                            <div className="customer-detail-box1">
                                <p>Property Address</p>
                                {loanDetails &&
                                    <h5>{loanDetails.property_address_1 ? loanDetails.property_address_1 + "," : ""} {loanDetails.property_city ? loanDetails.property_city + "," : ""} {loanDetails.property_state ? loanDetails.property_state + "," : ""} {loanDetails.property_zip ? loanDetails.property_zip + "," : ""}</h5>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="message_page">
                <div className="container-fluid position-relative">
                    <div className="content_section">
                        <div className="dashboard-content">
                            <div className="row">
                                <div className="col-md-10" style={{ flex: "0 0 85%", maxWidth: "85%", left: "8%" }}>
                                    <div className="card_widget mb20">
                                        <div className="card_w_header"> Loan Bankruptcy Detail</div>
                                        <div className="card_w_body">
                                            <ul className="l_info_list_group l_info_two_3col">
                                                <li>
                                                    <span>Origination Date</span><strong>{loanDetails?.funded_date ? formatDate(loanDetails?.funded_date) : "---"}</strong>
                                                </li>
                                                <li>
                                                    <span>Original Principal Balance</span><strong>{formatAmount(loanDetails?.note_amount)}</strong>
                                                </li>
                                                <li>
                                                    <span>Current Principal Balance</span><strong>{formatAmount(loanDetails?.current_principal_balance)}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Case Number</span><strong>{loanDetails?.bankruptcy_case_number}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Type</span><strong>{loanDetails?.bankruptcy_type}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy File Date</span><strong>{loanDetails?.bankruptcy_filed_date ? formatDate(loanDetails?.bankruptcy_filed_date) : "---"}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Discharge Code</span><strong>{loanDetails?.bankruptcy_7_discharged_code}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Discharge Date</span><strong>{loanDetails?.bankruptcy_7_discharged_date ? formatDate(loanDetails?.bankruptcy_7_discharged_date) : "---" }</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Status Date</span><strong>{loanDetails?.bankruptcy_status_date ? formatDate(loanDetails?.bankruptcy_status_date) : "---"}</strong>
                                                </li>

                                                <li>
                                                    <span>Bankruptcy Vendor</span><strong>{loanDetails?.bankruptcy_vendor}</strong>
                                                </li>
                                                <li>
                                                    <span>Bankruptcy Setup Date</span><strong>{loanDetails?.bankruptcy_setup_date ? formatDate(loanDetails?.bankruptcy_setup_date) : "---"}</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
            <br />
        </>
    )
}

export default LoanBankruptcyDetails;


