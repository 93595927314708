/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import formatDate from "../../../constants/dateFormat";

const WorkflowEdit = (props: any) => {

    const [data, setData] = useState<any>(props.data);


    return (<div className="row">
        <div className="col">
            <div className="row payment-boxes">
                <div className="col-12 payment-box-heading">
                    <h6 className="accordion_card1_title">View Task</h6>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Task Description</label>
                        <span>{data.sbs_workflow_task_description || "---"} </span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Start Date</label>
                        <span>{data.schedule_date ? formatDate(String(data.schedule_date)) : "---"}</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Complete Date</label>
                        <span>{data.complete_date ? formatDate(String(data.complete_date)) : "---"}</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Due Date</label>
                        <span>{data.due_date ? formatDate(String(data.due_date)) : "---"}</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Task Status</label>
                        <span>{data.sbs_status_description || "---"}</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Task Responsible Party</label>
                        <span>{data.sbs_responsible_party_id || "---"}</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="l_info_block">
                        <label>Task Note</label>
                        <span>{data.task_note || "---"}</span>
                    </div>
                </div>
                <div className="d-flex mt30 justify-content-center">
                    <button
                        className="btn btn-primary text-center btn-sm"
                        onClick={() => props.toggle()}>                  
                        Back
                    </button>
                </div>
            </div>
        </div>
    </div>);
}

export default WorkflowEdit;