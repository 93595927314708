
export const getFileData = (file) => {
    let fileUrl = file.S3_BUCKET_URL.replace('S3://', '');
    fileUrl = fileUrl.split("/")
    let bucket_name = fileUrl[0];
    let object_key = fileUrl.splice(1).join('/');
    return {
        bucket_name: bucket_name,
        object_key: object_key
    }
}