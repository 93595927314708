import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Status } from '../../constants/commonConstants';
import { getMasterDashboardList } from '../../services/dashboard.service';
import { getAllGridCodesList } from '../../services/genericAgGrid.service';
import { getLogiConfigurationDetails } from '../../services/logi.service';
import { getAllScreensByRole } from '../../services/screenData.service';
import { getCannedReportList, getCurrentUserData, getGroupList } from '../../services/user.service';
import { AppState } from '../stores/appStore';

const initial_state: AppState = {
    isLoggedIn: false,
    errorMessage: "",
    theme: undefined,
    status: Status.Idle,
    isNavMenuOpen: true,
    loaderMessage: "",
    breadcrumbLocation: [],
    currentDashboard: undefined,
    currentVisual: undefined,
    screensAccessible: [],
    currentUser: undefined,
    isSuperAdminUser: false,
    isNewVisual: false,
    masterDashboardList: [],
    masterUserGroupList: [],
    dataSourceList: [],
    dashboardWithVisualsList: [],
    userGroups: [],
    masterVisualList: [],
    logiConfiguration: [],
    roles: [],
    searchWord: "",
    masterUiScreenList: [],
    logiToken: null,
    cannedReports: [],
    gridCodeList: [],
    sessionTimeout: false
};

export const getCurrentUserDataAsync = createAsyncThunk(
    'app/getCurrentUserData',
    async (param: string) => {
        const response = await getCurrentUserData(param);
        return response;
    }
);

export const getLogiConfigurationAsync = createAsyncThunk(
    'app/getLogiConfiguration',
    async () => {
        const response = await getLogiConfigurationDetails();
        return response;
    }
);

export const getScreensByRoleAsync = createAsyncThunk(
    'app/getScreensByRole',
    async () => {
        const response = await getAllScreensByRole();
        return response;
    }
);

export const getUserGroupsAsync = createAsyncThunk(
    'app/getUserGroups',
    async () => {
        const response = await getGroupList();
        return response;
    }
);

export const getMasterDashboardListAsync = createAsyncThunk(
    'app/getMasterDashboardList',
    async (logConfig: any) => {
        const response = await getMasterDashboardList(logConfig);
        return response;
    }
);

export const getCannedReportListAsync = createAsyncThunk(
    'app/getCannedReportList',
    async () => {
        const response = await getCannedReportList();
        return response;
    }
);

export const getAllGridCodesListAsync = createAsyncThunk(
    'app/getAllGridCodesList',
    async () => {
        const response = await getAllGridCodesList();
        return response;
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState: initial_state,
    reducers: {
        login: (state: AppState, action: PayloadAction<any>) => {
            state.isLoggedIn = true;
            //state.currentUser = action.payload.user;
        },
        logout: (state: AppState) => {
            state.isLoggedIn = false;
            //state.currentUser = action.payload.user;
        },
        setNavMenuStatus: (state: AppState) => {
            state.isNavMenuOpen = !state.isNavMenuOpen;
        },
        handleSearchWordChange: (state: AppState, action: PayloadAction<any>) => {
            state.searchWord = action.payload;
        },
        setBreadcrumbLocation: (state: AppState, action: PayloadAction<any[]>) => {
            if (action.payload) {
                state.breadcrumbLocation = action.payload;
            }
        },
        setCurrentDashboard: (state: AppState, action: PayloadAction<any>) => {
            state.currentDashboard = action.payload;
        },
        setCurrentVisual: (state: AppState, action: PayloadAction<any>) => {
            state.currentVisual = action.payload;
        },
        setLoaderMessage: (state: AppState, action: PayloadAction<any>) => {
            state.loaderMessage = action.payload;
        },
        setScreenList: (state: AppState, action: PayloadAction<any>) => {
            state.screensAccessible = action.payload;
        },
        setCurrentUser: (state: AppState, action: PayloadAction<any>) => {
            state.currentUser = action.payload;
        },
        setIsSuperAdminUser: (state: AppState, action: PayloadAction<any>) => {
            state.isSuperAdminUser = action.payload;
        },
        setNewVisualFlag: (state: AppState, action: PayloadAction<any>) => {
            state.isNewVisual = action.payload;
        },
        clearState: (state: any) => {
            state.isLoggedIn = false;
            state.errorMessage = "";
            state.theme = undefined;
            state.status = Status.Idle;
            state.isNavMenuOpen = true;
            state.loaderMessage = "";
            state.breadcrumbLocation = [];
            state.currentDashboard = undefined;
            state.currentVisual = undefined;
            state.screensAccessible = [];
            state.currentUser = undefined;
            state.isSuperAdminUser = false;
            state.isNewVisual = false;
            state.masterDashboardList = [];
            state.masterUserGroupList = [];
            state.dataSourceList = [];
            state.dashboardWithVisualsList = [];
            state.userGroups = [];
            state.masterVisualList = [];
            state.logiConfiguration = [];
            state.roles = [];
            state.searchWord = "";
            state.masterUiScreenList = [];
            state.gridCodeList = [];
        },
        setDashboardsList: (state: AppState, action: PayloadAction<any>) => {
            state.currentUser!.dashboards_list = action.payload;
        },
        setReportsList: (state: AppState, action: PayloadAction<any>) => {
            state.currentUser!.reports_list = action.payload;
        },
        setMasterUserGroupList: (state: AppState, action: PayloadAction<any>) => {
            state.masterUserGroupList = action.payload;
        },
        setDataSourceList: (state: AppState, action: PayloadAction<any>) => {
            state.dataSourceList = action.payload;
        },
        setDashboardWithVisualsList: (state: AppState, action: PayloadAction<any>) => {
            state.dashboardWithVisualsList = action.payload;
        },
        setMasterVisualList: (state: AppState, action: PayloadAction<any>) => {
            state.masterVisualList = action.payload;
        },
        setRoleList: (state: AppState, action: PayloadAction<any>) => {
            state.roles = action.payload;
        },
        setFilterKeys: (state: AppState, action: PayloadAction<any>) => {
            state.filterKeys = action.payload;
        },
        resetFilterKeys: (state: AppState) => {
            state.filterKeys = undefined;
        },
        setAcDlSearchFilters: (state: AppState, action: PayloadAction<any>) => {
            state.acDlSearchFilters = action.payload;
        },
        setAcFilterKeys: (state: AppState, action: PayloadAction<any>) => {
            state.acLoanFilterKeys = action.payload;
        },
        setMasterDashboardList: (state: AppState, action: PayloadAction<any>) => {
            state.masterDashboardList = action.payload
        },
        setCurrentEmbededDashboard: (state: AppState, action: PayloadAction<any>) => {
            state.currentEmbededDashboard = action.payload;
        },
        setMasterUiScreenList: (state: AppState, action: PayloadAction<any>) => {
            state.masterUiScreenList = action.payload;
        },
        setLogiToken: (state: AppState, action: PayloadAction<any>) => {
            state.logiToken = action.payload;
        },
        setSessionTimeout: (state: AppState, action: PayloadAction<any>) => {
            state.sessionTimeout = action.payload;
        },
        setDescription:(state: AppState, action: PayloadAction<any>) =>{
            state.currentDashboard.description= action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUserDataAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                if (action.payload) {
                    state.currentUser = action.payload;
                }
            })
            .addCase(getLogiConfigurationAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                if (action.payload) {
                    state.logiConfiguration = action.payload;
                }
            })
            .addCase(getScreensByRoleAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                if (action.payload) {
                    state.screensAccessible = action.payload.filter(i => i.role_name === state.currentUser?.role_name).sort((a, b) => a.display_order - b.display_order);
                }
            })
            .addCase(getUserGroupsAsync.pending, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getUserGroupsAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.userGroups = action.payload;
            })
            .addCase(getUserGroupsAsync.rejected, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
            })
            .addCase(getMasterDashboardListAsync.pending, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getMasterDashboardListAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.masterDashboardList = action.payload;
            })
            .addCase(getMasterDashboardListAsync.rejected, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
            })
            .addCase(getCannedReportListAsync.pending, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getCannedReportListAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.cannedReports = action.payload.map(x=> x.canned_report_name);
            })
            .addCase(getCannedReportListAsync.rejected, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
            })
            .addCase(getAllGridCodesListAsync.pending, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getAllGridCodesListAsync.fulfilled, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.gridCodeList = action.payload;
            })
            .addCase(getAllGridCodesListAsync.rejected, (state: AppState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
            })	    
    }
});


export const {
    login,
    logout,
    setNavMenuStatus,
    setBreadcrumbLocation,
    setCurrentDashboard,
    setCurrentVisual,
    setLoaderMessage,
    setScreenList,
    setCurrentUser,
    setIsSuperAdminUser,
    setNewVisualFlag,
    clearState,
    setDashboardsList,
    setReportsList,
    setMasterUserGroupList,
    setDataSourceList,
    setDashboardWithVisualsList,
    setMasterVisualList,
    setRoleList,
    handleSearchWordChange,
    setFilterKeys,
    resetFilterKeys,
    setAcDlSearchFilters,
    setAcFilterKeys,
    setMasterDashboardList,
    setCurrentEmbededDashboard,
    setMasterUiScreenList,
    setLogiToken,
    setSessionTimeout,
    setDescription
} = appSlice.actions;

export const readAppState = (state: RootState) => state.app;
export default appSlice.reducer;