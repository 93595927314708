import { sendEmailService } from './../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";
import { templateConstants } from "../constants/emailTemplateConstants";

export const sendUserIdMail = async (user: any) => {
    try {
        if (user) {
            const appUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
            let templateData: any[] = [];
            templateData.push({
                to: [user.email],
                username: user.maas_user_id,
                appUrl: appUrl
            });

            let emailData = {
                templateName: templateConstants.MaasForgotUserId,
                templateData: templateData,
                action: "SendEmail"
            }

            const emailResponse = await API.graphql(graphqlOperation(sendEmailService, { data: JSON.stringify(emailData) }));
            return emailResponse;
        }
    }
    catch (ex) {
        console.log(ex);
        return null;
    }
}

