import { AssignmentClaimState } from './../stores/assignmentClaimStore';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

const initial_state: AssignmentClaimState = {
    filter: null,
    step: 1,
    heading:"",
    documentLevelSearch: false
};

export const assignmentClaimSlice = createSlice({
    name: 'assignmentClaim',
    initialState: initial_state,
    reducers: {
        setFilter: (state: AssignmentClaimState, action: PayloadAction<any>) => {
            state.filter = action.payload;
        },
        setStep: (state: AssignmentClaimState, action: PayloadAction<any>) => {
            state.step = action.payload;
        },
        setHeading: (state: AssignmentClaimState, action: PayloadAction<any>) => {
            state.heading = action.payload;
        },
        setDocumentLevelSearch: (state: AssignmentClaimState, action: PayloadAction<any>) => {
            state.documentLevelSearch = action.payload;
        },
        resetAssignmentClaimState: (state: AssignmentClaimState) => {
            state.filter = null;
            state.step = 11;
            state.heading = "";
            state.documentLevelSearch = false;
        }
    }
});


export const {
    setFilter,
    setStep,
    setHeading,
    setDocumentLevelSearch
} = assignmentClaimSlice.actions;

export const readAssignmentClaimState = (state: RootState) => state.assignmentClaim;
export default assignmentClaimSlice.reducer;