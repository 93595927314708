
export const SQLOperations = [
  { operationName: "> (Greater than)", operationValue: ">" },
  { operationName: "= (Equal to)", operationValue: "=" },
  { operationName: "< (Less than)", operationValue: "<" },
  { operationName: "! (Not)", operationValue: "!" },
  { operationName: ">= (Greater than equal to)", operationValue: ">=" },
  { operationName: "<= (Less than equal to)", operationValue: "<=" },
  { operationName: "!==(Not equal)", operationValue: "!=" },
  { operationName: "BETWEEN", operationValue: "BETWEEN value1 AND value2" },
  { operationName: "IN", operationValue: "IN(value1,value2)" },
  { operationName: "CASE Statement", operationValue: "CASE WHEN field THEN expression1 ELSE expression2 END" },
  { operationName: "AND", operationValue: "AND" },
  { operationName: "OR", operationValue: "OR" },
  { operationName: "NOT", operationValue: "NOT" },
  { operationName: "LIKE", operationValue: "LIKE '%value%'" },
]

export const LogicalOperations = [
  "AND",
  "OR",
  "IN",
  "NOT"
]
export const condition = [
  "CASE"
]

export const operations = [
  ">",
  "=",
  "<",
  "<=",
  ">=",
  "!=",
  "!",
  "IS",
  "NOT",
  "IN",
  "BETWEEN",
  // "SUM",
  // "AVG",
  // "MIN",
  // "MAX"
]

export const severityOptions = [
  { value: "Low", label: "Low" },
  { value: "Medium", label: "Medium" },
  { value: "High", label: "High" }
];


export const amountColumns = [
  "growth_amount",
  "lesa_semiannual_amount",
  "lien_payoff_amount",
  "origination_fee_amount",
  "total_mandatory_obligation_amount",
  "servicing_fee_margin",
  "closing_fee_amount",
  "prior_sale_price_amount",
  "sch_pymt_funds_req_amount",
  "monthly_principal_adjustment_amount",
  "repair_administration_fee_minimum_amount",
  "lesa_first_year_tax_insurance_amount"
  ,"monthly_principal_amount",
  "initial_available_credit_amount",
  "maximum_claim_amount",
  "monthly_tax_escrow_amount",
  "repair_administration_fee_percent",
  "idl_amount",
  "monthly_service_fee_amount",
  "initial_monthly_scheduled_payment_amount",
  "payments_remain"
]

  export const numericColumns = [
  "hmbs_servicing_fee_margin","non_borrowing_spouse_gender","sbs_product_type_category_id","sbs_loan_number",
  "is_phone_scrubbed","is_owner_occupied","is_flood_insurance","Hermit_loan_number","servicer_loan_number","hud_system_loan_id"
  ,"servicer_id"
  ]

export const skeyColumns = ["hmbs_servicing_fee_code","product_type_category_skey","parent_loan_skey","loan_sub_status_code","scheduled_payment_day","loan_servicer_skey"
  ,"hud_system_loan_skey","original_loan_number","lesa_payment_status_skey","investor_skey","fha_case_number","payment_plan_type","rsa_includes_administration_fee_flag"
  ,"last_participation_id","prior_servicer_loan_number"  ,"loan_channel_skey","loan_pool_skey","was_prior_sale_transfer_flag","hmbs_servicing_fee_type_skey"
  ,"mers_min_number","payment_status_skey","product_type_skey","prohibit_all_correspondence_flag","lesa_type","adp_code","loan_skey","print_statements_flag",
  "investor_loan_number","no_growth","loan_status"]

export const percentageColumns=["current_rate_index","servicing_fee_margin","repair_administration_fee_percent",
    "debenture_interest_rate","daily_debenture_rate","interest_rate_ceiling","expected_interest_rate","current_interest_rate",
  "mip_rate",,"original_interest_rate"]
