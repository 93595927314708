/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { createGroupAsync, readUserState, createLogiUserAsync, updateGroupAsync } from "../../../redux/reducers/userReducer";
import { Loader } from "../../../helpers/loader";
import { getCurrentUserDataAsync, readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import FormError from "../../common/FormError";
import Select from "react-select";
import Breadcrumb from "../../common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getAllDashboards, getDashboardToken } from "../../../services/dashboard.service";
import { getVisuals } from "../../../services/visual.service";
import { grantDashboardPermissions, grantReportPermissions, revokeDashboardPermissions, revokeReportPermissions } from "../../../services/logi.service";
import { selectCustomStyles } from "../../common/CustomStyles";
import { getRuleGroupListAsync, readRuleState } from "../../../redux/reducers/ruleReducer";
import { refreshUserGroupData, updateRecords } from "../../../services/user.service";

const UserGroupForm = () => {
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const userState = useAppSelector(readUserState);
    const [userGroupDetail, setUserGroupDetail] = useState({
        group_id: userState.userGroup?.group_id || "",
        group_name: userState.userGroup?.group_name || "",
        dashboards_list: null as any,
        reports_list: null as any,
        logi_user_id: userState.userGroup?.logi_user_id || "",
        data_sources_list: userState.userGroup?.data_sources_list ? userState.userGroup?.data_sources_list.data.map(x => { return { value: x.source_id, label: x.source_name } }) : null as any
    });
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, onButtonClick: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(true);
    const { handleSubmit } = useForm();
    const [error, setError] = useState({ errors: { cognito: null } });
    const dispatch = useAppDispatch();
    const [dashboardOptions, setDashboardOptions] = useState<any>([]);
    const [reportOptions, setReportOptions] = useState<any>([]);
    const [permissionArrayLength, setPermissionArrayLength] = useState({
        dashboardLength: userState.userGroup?.dashboards_list ? userState.userGroup?.dashboards_list.data.length : 0,
        reportLength: userState.userGroup?.reports_list ? userState.userGroup?.reports_list.data.length : 0,
        dataSourcesLength: userState.userGroup?.data_sources_list ? userState.userGroup?.data_sources_list.data.length : 0
    })
    const [grantPermissionList, setGrantPermissionList] = useState({ dashboards: [] as any[], reports: [] as any[], dataSources: [] as any[] });
    const [revokePermissionList, setRevokePermissionList] = useState({ dashboards: [] as any[], reports: [] as any[], dataSources: [] as any[] });
    const ruleState = useAppSelector(readRuleState);
    const [ruleGroupList, setRuleGroupList] = useState<any[]>([]);
    const [ruleGroup, setRuleGroup] = useState<any>("");
    const [isValueChange, setIsValueChange] = useState(false);
    const oldRuleGroupValue = userState.userGroup?.rule_group_id;

    useEffect(() => {
        getHeight("data", "report");
    }, [userGroupDetail])

    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/UserGroups")
        );
        userState.userGroup?.is_new ?
            breadcrumbLocation.push({
                screen_name: "Add User Group",
                redirect_url: "/UserGroupManagement",
                icon_url: "fad fa-toolbox"
            }) : breadcrumbLocation.push({
                screen_name: "Edit User Group",
                redirect_url: "/UserGroupManagement",
                icon_url: "fad fa-users"
            });
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        setRuleGroupOptions();
        getDashboardToken({ logiConfiguration: appState.logiConfiguration }).then(async (token) => {
            if (token) {
                let selectedValues = {
                    dashboards: [] as any[],
                    reports: [] as any[]
                }
                let dash = [] as any[];
                Promise.all([getAllDashboards(token, appState.currentUser), getVisuals(token, appState.currentUser)]).then((response: any) => {
                    if (response[0] && response[0].length > 0) {
                        const dashboards: any[] = [];
                        response[0].forEach((x: any) => {
                            if ((x.creatorName === appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value) || userState.userGroupList!.find(y => y.group_name.toLowerCase() === x.creatorName)) {
                                dashboards.push({
                                    label: x.name,
                                    value: x
                                })
                            }
                        });
                        if (!userState.userGroup?.is_new) {
                            userState.userGroup?.dashboards_list.data.forEach(dashboard => {
                                if (dashboards.find(x => x.value.inventoryItemId === dashboard.dashboard_id)) {
                                    dash.push(dashboards.find(x => x.value.inventoryItemId === dashboard.dashboard_id));
                                }
                            })
                            selectedValues = { ...selectedValues, dashboards: dash };
                        }

                        setDashboardOptions(dashboards);
                    }
                    if (response[1] && response[1].length > 0) {
                        const reports: any[] = [];
                        response[1].forEach((x: any) => {
                            if ((x.creatingUserName === appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value) || userState.userGroupList!.find(y => y.group_name.toLowerCase() === x.creatingUserName)) {
                                reports.push({
                                    label: x.visualName,
                                    value: x
                                });
                            }
                        });
                        if (!userState.userGroup?.is_new) {
                            let repo = [] as any[];
                            userState.userGroup?.reports_list.data.forEach(report => {
                                if (reports.find(x => x.value.id === report.report_id)) {
                                    repo.push(reports.find(x => x.value.id === report.report_id));
                                }
                                else if (appState.masterVisualList && appState.masterVisualList.length > 0 && appState.masterVisualList.find(vis => vis.id === report.report_id)) {
                                    let visual = appState.masterVisualList.find(vis => vis.id === report.report_id);
                                    repo.push({
                                        label: visual.visualName,
                                        value: visual
                                    });
                                }
                            })
                            selectedValues = { ...selectedValues, reports: repo };
                        }
                        setReportOptions(reports);
                    }
                    !userState.userGroup?.is_new && setUserGroupDetail({ ...userGroupDetail, dashboards_list: selectedValues.dashboards, reports_list: selectedValues.reports });
                    setLoader(false);
                }).catch(ex => { console.log(ex) });;
            }
        }).catch(ex => { console.log(ex) });
    }, []);

    const setRuleGroupOptions = async () => {
        let ruleGroupOptions: any[] = [];
        let ruleGroups: any;
        let userGroups: any = userState?.userGroupList || [];
        let filteredRuleGroups: any[] = [];


        if (ruleState && ruleState.rule_group_list) {
            ruleGroups = ruleState.rule_group_list;
        }
        else {
            await dispatch(getRuleGroupListAsync()).then((ruleGroupResponse) => {
                ruleGroups = ruleGroupResponse.payload
            })
        }
        if (ruleGroups && ruleGroups.length > 0) {
            userGroups.forEach(gr => {
                if (gr.rule_group_id) {
                    if (filteredRuleGroups.length > 0) {
                        filteredRuleGroups = filteredRuleGroups.filter(rg => rg.rule_group_id !== gr.rule_group_id);
                    }
                    else {
                        filteredRuleGroups = ruleGroups.filter(rg => rg.rule_group_id !== gr.rule_group_id);
                    }
                }
            });
            if (filteredRuleGroups.length === 0) {
                filteredRuleGroups = ruleGroups;
            }
            filteredRuleGroups.forEach(rg => {
                ruleGroupOptions.push({
                    label: rg.rule_group_name,
                    value: rg.rule_group_id
                });
            });
        }
        setRuleGroupList(ruleGroupOptions);
        if (!userState.userGroup?.is_new) {
            let existingRuleGroup = ruleGroups.find(x => x.rule_group_id === userState.userGroup?.rule_group_id);
            if (existingRuleGroup) {
                setRuleGroup({
                    label: existingRuleGroup.rule_group_name,
                    value: existingRuleGroup.rule_group_id
                })
            }
        }
    }

    const handleDataRefresh = async (rule_group_id: any) => {
        let ruleGroupId = rule_group_id || "";
        let response = await refreshUserGroupData(ruleGroupId);
        if (response && response.statusCode === 200 && response.body && response.body.transformerLogId) {
            return { transformer_logs_id: response.body.transformerLogId };
        }
        else {
            return null;
        }
    }

    const handleSubmitForm = async (e: any) => {
        setLoader(true);
        try {

            let userGroupData: any = {
                group_name: userGroupDetail.group_name,
                dashboards_list: JSON.stringify({ data: userGroupDetail.dashboards_list.map(x => { return { dashboard_id: x.value.inventoryItemId, dashboard_name: x.label } }) }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                reports_list: JSON.stringify({ data: userGroupDetail.reports_list.map(x => { return { report_id: x.value.id, report_name: x.label } }) }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                created_by: appState.currentUser!.maas_user_id,
                created_date: new Date().toUTCString(),
                modified_by: appState.currentUser!.maas_user_id,
                modified_date: new Date().toUTCString(),
                rule_group_id: ruleGroup?.value || ""
            }

            let sourceList: any[] = [];
            if (userGroupDetail.dashboards_list && userGroupDetail.dashboards_list.length > 0) {
                userGroupDetail.dashboards_list.forEach(dashboard => {
                    if (dashboard.value.associatedItems && dashboard.value.associatedItems.length > 0) {
                        dashboard.value.associatedItems.forEach(item => {
                            if (item.type === "DATA_SOURCE" && !sourceList.find(z => z.source_id === item.inventoryItemId)) {
                                sourceList.push({
                                    source_id: item.inventoryItemId,
                                    source_name: item.name
                                });
                            }
                        });
                    }
                });
            }

            if (userGroupDetail.reports_list && userGroupDetail.reports_list.length > 0) {
                userGroupDetail.reports_list.forEach(report => {
                    if (report.value && !sourceList.find(z => z.source_id === report.value.ownerSourceId)) {
                        sourceList.push({
                            source_id: report.value.ownerSourceId,
                            source_name: report.value.sourceName
                        });
                    }
                });
            }

            userGroupData = {
                ...userGroupData,
                data_sources_list: JSON.stringify({ data: sourceList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            }

            dispatch(createLogiUserAsync({ group_name: userGroupDetail.group_name, logiConfiguration: appState.logiConfiguration })).then(async (data: any) => {
                if (data && data.payload) {
                    let transformerLogsId: any;
                    if (ruleGroup?.value) {
                        let refreshResponse = await handleDataRefresh(ruleGroup?.value);
                        transformerLogsId = refreshResponse ? refreshResponse.transformer_logs_id.toString() : "";
                    }
                    userGroupData = {
                        ...userGroupData,
                        logi_user_id: data.payload,
                        transformer_logs_id: transformerLogsId || ""
                    }
                    const userGroupResponse: any = await dispatch(createGroupAsync(userGroupData));
                    Promise.all([grantDashboardPermissions(userGroupData.logi_user_id, userGroupDetail.dashboards_list.map(x => x.value), appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value),
                    grantReportPermissions(userGroupData.logi_user_id, userGroupDetail.reports_list.map(x => x.value), appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value)]).then(response => {
                        let resetDashboards = false;
                        let resetReports = false;
                        if (!response[0]) {
                            resetDashboards = true;
                            userGroupData = {
                                ...userGroupData, dashboards_list: JSON.stringify({ data: [] }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                data_sources_list: JSON.stringify({ data: [] }).replaceAll(/"/g, '\\"').replaceAll("'", "''")
                            }
                        }
                        if (!response[1]) {
                            resetReports = true;
                            userGroupData = { ...userGroupData, reports_list: JSON.stringify({ data: [] }).replaceAll(/"/g, '\\"').replaceAll("'", "''") }
                        }
                        if (resetDashboards || resetReports) {
                            dispatch(updateGroupAsync({
                                group_id: userGroupResponse.payload.group_id,
                                group_name: userGroupResponse.payload.group_name,
                                dashboards_list: userGroupData.dashboards_list,
                                reports_list: userGroupData.reports_list,
                                data_sources_list: userGroupData.data_sources_list,
                                modified_by: appState.currentUser?.maas_user_id,
                                modified_date: new Date().toUTCString(),
                                rule_group_id: userGroupResponse.payload.rule_group_id
                            }))
                        }
                    });
                    setPopup({ ...popup, visible: true, message: "This user group has been added. ", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigation("/UserGroups"); setPopup({ ...defaultPopup }) } });
                    return;
                }
                else {
                    setLoader(false);
                    setPopup({ ...popup, visible: true, message: "User Group could not be created!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { navigation("/UserGroups"); setPopup({ ...defaultPopup }) } });
                }
            })
        } catch (error: any) {
            setLoader(false);
            let err = !error.message ? { "message": error } : error;
            console.log(error)
            setError({
                errors: {
                    ...error,
                    cognito: err
                }
            });
            setLoader(false);
            setPopup({ ...popup, visible: true, message: "User Group could not be created!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { navigation("/UserGroups"); setPopup({ ...defaultPopup }) } });
        }
    }

    const handleSubmitEditForm = async () => {
        setLoader(true);
        let transformerLogsId: any;
        if (ruleGroup && ruleGroup.value && oldRuleGroupValue !== ruleGroup.value) {
            let refreshResponse = await handleDataRefresh(ruleGroup?.value);
            transformerLogsId = refreshResponse ? refreshResponse.transformer_logs_id.toString() : "";
        }
        else if (oldRuleGroupValue && oldRuleGroupValue !== "" && !ruleGroup) {
            transformerLogsId = "";
            const inactiveStatus = "D"
            let updateResponse = await updateRecords(userGroupDetail.group_id, appState.currentUser?.maas_user_id, inactiveStatus);
        }
        else if (oldRuleGroupValue && oldRuleGroupValue !== "") {
            transformerLogsId = userState.userGroup?.transformer_logs_id
        }

        let currentSourceList: any[] = [];
        if (userGroupDetail.dashboards_list && userGroupDetail.dashboards_list.length > 0) {
            userGroupDetail.dashboards_list.forEach(dashboard => {
                if (dashboard.value.associatedItems && dashboard.value.associatedItems.length > 0) {
                    dashboard.value.associatedItems.forEach(item => {
                        if (item.type === "DATA_SOURCE" && !currentSourceList.find(z => z.source_id === item.inventoryItemId)) {
                            currentSourceList.push({
                                source_id: item.inventoryItemId,
                                source_name: item.name
                            });
                        }
                    });
                }
            });
        }
        if (userGroupDetail.reports_list && userGroupDetail.reports_list.length > 0) {
            userGroupDetail.reports_list.forEach(report => {
                if (report.value && !currentSourceList.find(z => z.source_id === report.value.ownerSourceId)) {
                    currentSourceList.push({
                        source_id: report.value.ownerSourceId,
                        source_name: report.value.sourceName
                    });
                }
            });
        }

        let updateDashboardsFlag = false;
        let updateReportsFlag = false;
        let updatedDashboards: any = [...userGroupDetail.dashboards_list.map(x => { return { dashboard_id: x.value.inventoryItemId, dashboard_name: x.label } })];
        let updatedReports: any = [...userGroupDetail.reports_list.map(x => { return { report_id: x.value.id, report_name: x.label } })]
        let sourceList: any = [...currentSourceList];

        await grantDashboardPermissions(userGroupDetail.logi_user_id, grantPermissionList.dashboards.map(x => x.value), appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value).then(response => {
            if (response) {
                revokeDashboardPermissions(userGroupDetail.logi_user_id, revokePermissionList.dashboards.map(dashboard => dashboard.value), currentSourceList, appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value).then(response => {
                    if (!response) {
                        setPopup({ ...popup, visible: true, message: "An error occured while setting dashboard permissions!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                        updateDashboardsFlag = true;
                        revokePermissionList.dashboards.forEach(dash => {
                            updatedDashboards.push({
                                dashboard_id: dash.value.inventoryItemId,
                                dashboard_name: dash.label
                            })
                            if (dash.associatedItems && dash.associatedItems.length > 0) {
                                dash.associatedItems.forEach(item => {
                                    if (!sourceList.find(z => z.source_id === item.inventoryItemId)) {
                                        sourceList.push({
                                            source_id: item.inventoryItemId,
                                            source_name: item.name
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
            else {
                setPopup({ ...popup, visible: true, message: "An error occured while setting dashboard permissions!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                updateDashboardsFlag = true;
                updatedDashboards = [...userState.userGroup?.dashboards_list.data];
                sourceList = [...userState.userGroup?.data_sources_list];
            }
        });

        await grantReportPermissions(userGroupDetail.logi_user_id, grantPermissionList.reports.map(x => x.value), appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value).then(response => {
            if (response) {
                revokeReportPermissions(userGroupDetail.logi_user_id, revokePermissionList.reports.map(x => x.value), currentSourceList, appState.logiConfiguration.find(x => x.configuration_type === 'logi_admin_user_name')?.configuration_value).then(response => {
                    if (!response) {
                        setPopup({ ...popup, visible: true, message: "An error occured while setting reports permissions!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                        updateReportsFlag = true;
                        revokePermissionList.reports.forEach(report => {
                            updatedReports.push({
                                report_id: report.value.id,
                                report_name: report.label
                            })
                            if (report.value) {
                                if (!sourceList.find(z => z.source_id === report.value.ownerSourceId)) {
                                    sourceList.push({
                                        source_id: report.value.ownerSourceId,
                                        source_name: report.value.sourceName
                                    })
                                }
                            }
                        })

                    }
                })
            }
            else {
                setPopup({ ...popup, visible: true, message: "An error occured while setting reports permissions!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                updateReportsFlag = true;
                updatedReports = [...userState.userGroup?.reports_list.data];
                sourceList = [...userState.userGroup?.data_sources_list];
            }
        });


        dispatch(updateGroupAsync({
            group_id: userGroupDetail.group_id,
            group_name: userGroupDetail.group_name,
            dashboards_list: JSON.stringify({ data: updatedDashboards }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            reports_list: JSON.stringify({ data: updatedReports }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            data_sources_list: JSON.stringify({ data: sourceList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
            modified_by: appState.currentUser?.maas_user_id,
            modified_date: new Date().toUTCString(),
            rule_group_id: ruleGroup?.value || "",
            transformer_logs_id: transformerLogsId || ""
        })).then(async response => {
            if (appState.currentUser!.group_id === userGroupDetail.group_id) {
                dispatch(getCurrentUserDataAsync(appState.currentUser!.user_id));
            }
            if (!updateReportsFlag && !updateDashboardsFlag) {
                setPopup({ ...popup, visible: true, message: "Your edits/updates are complete.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { navigation("/UserGroups"); setPopup({ ...defaultPopup }) } });
            }
            else {
                navigation("/UserGroups");
            }
        })
    }

    const getDashboardVisuals = (dashboards: any) => {
        setLoader(true);
        let reportsList = userGroupDetail.reports_list ? [...userGroupDetail.reports_list] : [];

        if (!userGroupDetail.dashboards_list || (dashboards.length > 0 && dashboards.length > userGroupDetail.dashboards_list.length)) {
            const dash = appState.dashboardWithVisualsList.find(board => board.id === dashboards[dashboards.length - 1].value.inventoryItemId);
            if (dash && dash.widgets && dash.widgets.length > 0) {
                dash.widgets.forEach(y => {
                    if (!reportsList.find(x => x.value.id === y.visualId)) {
                        const visual = reportOptions.find(repo => repo.value.id === y.visualId);
                        if (visual) {
                            reportsList.push(visual);
                        }
                    }
                });
            }
        }
        else {
            let removedDashboards: any[] = [];
            userGroupDetail.dashboards_list.forEach(li => {
                if (!dashboards.find(item => item.value.inventoryItemId === li.value.inventoryItemId)) {
                    removedDashboards.push(li);
                }
            });
            removedDashboards.forEach(x => {
                const dashboardWithVisuals = appState.dashboardWithVisualsList.find(board => board.id === x.value.inventoryItemId);
                if (dashboardWithVisuals && dashboardWithVisuals.widgets && dashboardWithVisuals.widgets.length > 0) {
                    dashboardWithVisuals.widgets.forEach(y => {
                        reportsList = reportsList.filter(repo => repo.value.id !== y.visualId);
                    });
                }
            })

        }

        setLoader(false);
        return reportsList;
    }

    const submitButton = () => {
        if (userState.userGroup?.is_new ? appState.masterUserGroupList && appState.masterUserGroupList.length > 0 && !appState.masterUserGroupList!.find(x => x.name === userGroupDetail.group_name.trim().toLowerCase()) && userGroupDetail.group_name !== "" && userGroupDetail.dashboards_list && userGroupDetail.dashboards_list.length > 0 && userGroupDetail.reports_list && userGroupDetail.reports_list.length > 0 && !appState.masterUserGroupList!.find(x => x.name === userGroupDetail.group_name.trim().toLowerCase()) : userGroupDetail.group_name !== "" && ((!ruleGroup && userState.userGroup?.rule_group_id) || (ruleGroup && ruleGroup.value !== userState.userGroup?.rule_group_id) || isValueChange) && userGroupDetail.dashboards_list && userGroupDetail.dashboards_list.length > 0 && userGroupDetail.reports_list && userGroupDetail.reports_list.length > 0) {
            return (<button className="btn btn-primary text-center btn-block" type="submit">Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled >Save</button>)
        }
    }

    const handleReportSelect = (e: any[], isVisualAdded?: boolean) => {
        setIsValueChange(true)
        let reportGrantList = [...grantPermissionList.reports];
        let reportRevokeList = [...revokePermissionList.reports];
        if ((isVisualAdded) || e.length > permissionArrayLength.reportLength) {
            e.forEach(report => {
                if (!userState.userGroup?.reports_list.data.find(y => y.report_id === report.value.id) && !grantPermissionList.reports.find(z => z.value.id === report.value.id)) {
                    reportGrantList.push(report);
                }
            })
            let updatedRevokeList: any[] = [];
            reportRevokeList.forEach(li => {
                if (!e.find(y => y.value.id === li.value.id)) {
                    updatedRevokeList.push(li);
                }
            })
            reportRevokeList = [...updatedRevokeList];
        }
        else {
            userState.userGroup?.reports_list.data.forEach(report => {
                if (!e.find(y => y.value.id === report.report_id) && !revokePermissionList.reports.find(z => z.value.id === report.report_id)) {
                    const revokeElement = reportOptions.find(x => x.value.id === report.report_id);
                    if (revokeElement) {
                        reportRevokeList.push(revokeElement);
                    }
                }
            })
            let updatedGrantList: any[] = [];
            e.forEach(x => {
                if (!userState.userGroup?.reports_list.data.find(y => y.report_id === x.value.id)) {
                    updatedGrantList.push(x);
                }
            })
            reportGrantList = [...updatedGrantList];
        }
        if (typeof (isVisualAdded) === "boolean") {
            return {
                reportGrantList: reportGrantList,
                reportRevokeList: reportRevokeList
            }
        }
        else {
            setGrantPermissionList({ ...grantPermissionList, reports: reportGrantList });
            setRevokePermissionList({ ...revokePermissionList, reports: reportRevokeList });
            setPermissionArrayLength({ ...permissionArrayLength, reportLength: e.length });
            setUserGroupDetail({ ...userGroupDetail, reports_list: e })
        }
    }

    const handleDashboardSelect = (e: any) => {
        setIsValueChange(true)

        let dashboardGrantList = [...grantPermissionList.dashboards];
        let dashboardRevokeList = [...revokePermissionList.dashboards];
        let dashboardVisuals = [...userGroupDetail.reports_list];
        let masterDashboardsList = [...appState.dashboardWithVisualsList];

        if (e.length >= permissionArrayLength.dashboardLength) {
            e.forEach(dashboard => {
                if ((!userState.userGroup?.dashboards_list.data.find(y => y?.dashboard_id === dashboard?.value?.inventoryItemId) && !grantPermissionList.dashboards.find(z => z?.value?.inventoryItemId === dashboard?.value?.inventoryItemId)) || revokePermissionList.dashboards.find(x => x?.value?.inventoryItemId === dashboard?.value?.inventoryItemId)) {
                    dashboardGrantList.push(dashboard);
                    const dashboardWithVisual = masterDashboardsList.find(board => board.id === dashboard.value.inventoryItemId);
                    if (dashboardWithVisual && dashboardWithVisual.widgets && dashboardWithVisual.widgets.length > 0) {
                        dashboardWithVisual.widgets.forEach(vis => {
                            if (!dashboardVisuals.find(db => db.id === vis.visualId)) {
                                const visual = appState.masterVisualList && appState.masterVisualList.length > 0 ? appState.masterVisualList.find(repo => repo.id === vis.visualId) : null;
                                if (visual) {
                                    dashboardVisuals.push({
                                        label: visual.visualName,
                                        value: visual
                                    });
                                }
                            }
                        });
                    }
                }
            });
            let updatedRevokeList: any[] = [];
            dashboardRevokeList.forEach(li => {
                if (!e.find(y => y?.value?.inventoryItemId === li?.value?.inventoryItemId)) {
                    updatedRevokeList.push(li);
                }
            })
            dashboardRevokeList = [...updatedRevokeList];
            const response = handleReportSelect(dashboardVisuals, true);
            if (response) {
                setGrantPermissionList({ ...grantPermissionList, dashboards: dashboardGrantList, reports: response.reportGrantList });
                setRevokePermissionList({ ...revokePermissionList, dashboards: dashboardRevokeList, reports: response.reportRevokeList });
                setPermissionArrayLength({ ...permissionArrayLength, dashboardLength: e.length, reportLength: dashboardVisuals.length });
                setUserGroupDetail({ ...userGroupDetail, dashboards_list: e, reports_list: dashboardVisuals });
                return;
            }
        }
        else {
            userState.userGroup?.dashboards_list.data.forEach(dashboard => {
                if (!e.find(y => y?.value?.inventoryItemId === dashboard.dashboard_id) && !revokePermissionList.dashboards.find(z => z?.value?.inventoryItemId === dashboard.dashboard_id)) {
                    dashboardRevokeList.push(dashboardOptions.find(z => z?.value?.inventoryItemId === dashboard.dashboard_id));
                }
            });

            userGroupDetail.dashboards_list.forEach(dash => {
                if (!e.find(y => y.value.inventoryItemId === dash.value.inventoryItemId)) {
                    const removedDashboard = masterDashboardsList.find(board => board.id === dash.value.inventoryItemId);
                    if (removedDashboard && removedDashboard.widgets && removedDashboard.widgets.length > 0) {
                        removedDashboard.widgets.forEach(vis => {
                            dashboardVisuals = dashboardVisuals.filter(visual => visual.value.id !== vis.visualId);
                        });
                    }
                }
            });

            let updatedGrantList: any[] = [];
            e.forEach(x => {
                if (!userState.userGroup?.dashboards_list.data.find(y => y.dashboard_id === x.value.inventoryItemId)) {
                    updatedGrantList.push(x);
                }
            })
            dashboardGrantList = [...updatedGrantList];
            const response = handleReportSelect(dashboardVisuals, false);
            if (response) {
                setGrantPermissionList({ ...grantPermissionList, dashboards: dashboardGrantList, reports: response.reportGrantList });
                setRevokePermissionList({ ...revokePermissionList, dashboards: dashboardRevokeList, reports: response.reportRevokeList });
                setPermissionArrayLength({ ...permissionArrayLength, dashboardLength: e.length, reportLength: dashboardVisuals.length });
                setUserGroupDetail({ ...userGroupDetail, dashboards_list: e, reports_list: dashboardVisuals })
                return;
            }
        }
        setGrantPermissionList({ ...grantPermissionList, dashboards: dashboardGrantList });
        setRevokePermissionList({ ...revokePermissionList, dashboards: dashboardRevokeList });
        setPermissionArrayLength({ ...permissionArrayLength, dashboardLength: e.length });
        setUserGroupDetail({ ...userGroupDetail, dashboards_list: e })
    }

    const getHeight = (e: any, type: string) => {
        let divElement: any;
        let anotherDivElement: any;
        if (type === "dashboardList") {
            divElement = document.querySelector("#dashboardList");
            anotherDivElement = document.querySelector("#report");
        }
        else {
            divElement = document.querySelector("#report");
            anotherDivElement = document.querySelector("#dashboardList");
        }
        let controlDiv: any = divElement?.querySelector(`.${divElement?.children[2]?.classList[0]}`)
        let anotherControlDiv: any = anotherDivElement?.querySelector(`.${anotherDivElement?.children[2]?.classList[0]}`)
        let valueContainerDiv: any = controlDiv?.children[0];
        let anotherValueContainerDiv: any = anotherControlDiv?.children[0];
        valueContainerDiv?.classList.remove("user-group-select")
        anotherValueContainerDiv?.classList.remove("user-group-select")
        let valueDivHeight: any = valueContainerDiv?.clientHeight;
        let anotherValueDivHeight: any = anotherValueContainerDiv?.clientHeight;
        let indicatorClassNames = controlDiv?.children[1]?.classList;
        let anotherIndicatorClassNames = anotherControlDiv?.children[1]?.classList;
        let indicatorClassName: any;
        let anotherIndicatorClassName: any;
        for (let i = 0; i < indicatorClassNames.length; i++) {
            let res = indicatorClassNames[i].match(/css/);
            if (res) {
                indicatorClassName = indicatorClassNames[i]
            }
        }
        for (let i = 0; i < anotherIndicatorClassNames.length; i++) {
            let res = anotherIndicatorClassNames[i].match(/css/);
            if (res) {
                anotherIndicatorClassName = anotherIndicatorClassNames[i]
            }
        }
        let innerDiv: any = divElement?.querySelector(`.${indicatorClassName}`);
        let anotherInnerDiv: any = anotherDivElement?.querySelector(`.${anotherIndicatorClassName}`)
        if (!e.length) {
            if (type === "report") {
                valueContainerDiv?.classList.remove("user-group-select")
                innerDiv?.classList.add(`${indicatorClassName}`)
                innerDiv?.classList.remove("sticky-menubar")
                getHeight("data", "dashboardList")
            }
            else {
                innerDiv?.classList.add(`${indicatorClassName}`)
                anotherInnerDiv?.classList.add(`${indicatorClassName}`)
                valueContainerDiv?.classList.remove("user-group-select")
                anotherValueContainerDiv?.classList.remove("user-group-select")
                innerDiv?.classList.remove("sticky-menubar")
                anotherInnerDiv?.classList.remove("sticky-menubar")
            }
        }
        else {
            if (valueDivHeight > 250 && anotherValueDivHeight > 250) {
                innerDiv?.classList.add("sticky-menubar")
                valueContainerDiv?.classList.add("user-group-select")
                valueContainerDiv.scrollTop = valueContainerDiv.scrollHeight
                anotherInnerDiv?.classList.add("sticky-menubar")
                anotherValueContainerDiv?.classList.add("user-group-select")
                anotherValueContainerDiv.scrollTop = anotherValueContainerDiv.scrollHeight
            }
            else if (valueDivHeight === 250 && anotherValueDivHeight < 250) {
                innerDiv?.classList.add(`${indicatorClassName}`)
                innerDiv?.classList.remove("sticky-menubar")
                valueContainerDiv?.classList.remove("user-group-select")
            }
            else if (valueDivHeight === 250 && anotherValueDivHeight > 250) {
                anotherInnerDiv?.classList.add("sticky-menubar")
                anotherValueContainerDiv?.classList.add("user-group-select")
                anotherValueContainerDiv.scrollTop = anotherValueContainerDiv.scrollHeight
            }
            else if (anotherValueDivHeight === 250 && valueDivHeight < 250) {
                anotherInnerDiv?.classList.add(`${indicatorClassName}`)
                anotherInnerDiv?.classList.remove("sticky-menubar")
                anotherValueContainerDiv?.classList.remove("user-group-select")
            }
            else if (anotherValueDivHeight === 250 && valueDivHeight > 250) {
                innerDiv?.classList.add("sticky-menubar")
                valueContainerDiv?.classList.add("user-group-select")
                valueContainerDiv.scrollTop = valueContainerDiv.scrollHeight
            }
            else if (valueDivHeight > 250 && anotherValueDivHeight < 250) {
                innerDiv?.classList.add("sticky-menubar")
                valueContainerDiv?.classList.add("user-group-select")
                valueContainerDiv.scrollTop = valueContainerDiv.scrollHeight
                anotherInnerDiv?.classList.add(`${indicatorClassName}`)
                anotherInnerDiv?.classList.remove("sticky-menubar")
                anotherValueContainerDiv?.classList.remove("user-group-select")
            }
            else if (anotherValueDivHeight > 250 && valueDivHeight < 250) {
                anotherInnerDiv?.classList.add("sticky-menubar")
                anotherValueContainerDiv?.classList.add("user-group-select")
                anotherValueContainerDiv.scrollTop = anotherValueContainerDiv.scrollHeight
                innerDiv?.classList.add(`${indicatorClassName}`)
                innerDiv?.classList.remove("sticky-menubar")
                valueContainerDiv?.classList.remove("user-group-select")
            }
            else {
                innerDiv?.classList.add(`${indicatorClassName}`)
                anotherInnerDiv?.classList.add(`${indicatorClassName}`)
                innerDiv?.classList.remove("sticky-menubar")
                anotherInnerDiv?.classList.remove("sticky-menubar")
                valueContainerDiv?.classList.remove("user-group-select")
                anotherValueContainerDiv?.classList.remove("user-group-select")
            }
        }
    }

    return (
        <>
            <div className="pagetitle"><Breadcrumb /></div>
            <section className="section dashboard">
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
                <div className="block_sect">
                    <form onSubmit={handleSubmit(userState.userGroup?.is_new ? handleSubmitForm : handleSubmitEditForm)}>
                        <FormError formerrors={error} />
                        <div className="row" style={{ marginLeft: "0px" }}>
                            <div className="col-lg-6">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="userGroupName"
                                        placeholder="User Group Name"
                                        value={userGroupDetail.group_name}
                                        disabled={userState.userGroup?.is_new ? false : true}
                                        onChange={(e) => setUserGroupDetail({ ...userGroupDetail, group_name: e.target.value })}
                                    />
                                    <label htmlFor="userGroupName">User Group Name</label>
                                    {userState.userGroup?.is_new && appState.masterUserGroupList!.find(x => x.name === userGroupDetail.group_name.trim().toLowerCase()) && <p style={{ color: 'red' }}>User group already exists with this name.</p>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        className="form-control multi-select-dd "
                                        id="ruleGroupName"
                                        options={ruleGroupList}
                                        value={ruleGroup}
                                        onChange={(e) => setRuleGroup(e)}
                                        placeholder="Select Rule Group..."
                                    />
                                    <label htmlFor="ruleGroupName">Rule Group</label>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginLeft: "0px", marginBottom: "40px" }}>
                            <div className="col-lg-6">
                                <label htmlFor="dashboardList" className="user-group-label">Dashboard</label>
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        id="dashboardList"
                                        options={dashboardOptions}
                                        value={userGroupDetail.dashboards_list}
                                        onChange={(e: any) => {
                                            userState.userGroup?.is_new ? setUserGroupDetail({ ...userGroupDetail, dashboards_list: e, reports_list: getDashboardVisuals(e) }) : handleDashboardSelect(e);
                                            getHeight(e, 'dashboardList')
                                        }}
                                        isMulti={true}
                                        maxMenuHeight={190}
                                        closeMenuOnScroll={false}
                                        placeholder="Select Dashboard..."
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="report" className="user-group-label">Report</label>
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        id="report"
                                        options={reportOptions}
                                        value={userGroupDetail.reports_list}
                                        onChange={(e: any) => {
                                            userState.userGroup?.is_new ? setUserGroupDetail({ ...userGroupDetail, reports_list: e }) : handleReportSelect(e)
                                            getHeight(e, 'report')
                                        }}
                                        isMulti={true}
                                        maxMenuHeight={220}
                                        closeMenuOnScroll={false}
                                        placeholder="Select Report..."
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt30">
                            <div className="col-lg-2 col">
                                {submitButton()}</div>
                            <div className="col-lg-2 col"><button className="btn btn-outline-primary text-center btn-block" type="submit" onClick={() => navigation('/UserGroups')}>
                                Cancel</button></div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
export default memo(UserGroupForm);