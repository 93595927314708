export const AgGridFilters = {
    "equals": "=",
    "notEqual": "<>",
    "inRange": "BETWEEN",
    "contains": "CONTAINS",
    "notContains": "NOT CONTAINS",
    "blank": "IS NULL",
    "notBlank": "IS NOT NULL",
    "startsWith": "STARTSWITH",
    "endsWith": "ENDSWITH",
    "greaterThan": ">",
    "smallerThan": "<",
    "lessThanOrEqual": "<=",
    "greaterThanOrEqual": ">="
}

export const LogiFilters = {
    "=": "=",
    "≠": "<>",
    ">": ">",
    "<": "<",
    "≥": ">=",
    "≤": "<=",
    "Between": "BETWEEN",
    "Not Between": "NOT BETWEEN",
    "Include": "IN",
    "Exclude": "NOT IN"
}