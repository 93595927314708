/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../helpers/loader";
import { sendUserIdMail } from "../../services/mail.service";
import { getUsers } from "../../services/user.service";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";


const ForgotPassword = (props: any) => {
    const navigation = useNavigate();
    const [loader, setLoader] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [userList, setUserList] = useState<any>([]);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;

    useEffect(() => {
        getUsers().then(response => {
            setUserList(response);
        });
    }, []);

    const handleKeyPress = (target) => {
        if (target.charCode === 13) {
            userVerificationHandler();
        }
    }


    const userVerificationHandler = async () => {
        setLoader(true);
        try {
            if (userList && userList.length > 0) {
                let currentUser = userList.find(u => u.email.toLowerCase() === emailAddress.toLowerCase());
                if (currentUser) {
                    const response: any = await sendUserIdMail(currentUser);
                    if (response && response.data && response.data.sendEmailService && JSON.parse(response.data.sendEmailService).statusCode === 200) {
                        setEmailAddress('');
                        setPopup({ ...popup, visible: true, message: "We've sent your User ID to your email address.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); navigation("/login") } });
                    
                    }
                    else {
                        setPopup({ ...popup, visible: true, message: "Email could not be sent!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                        setLoader(false);
                    }
                }
                else {
                    setPopup({ ...popup, visible: true, message: "We’re sorry but we can’t find any account associated with the entered Email Id. Please try again.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                    setLoader(false);
                }
            }
        }
        catch (error: any) {
            setLoader(false);
        }
    }
    const isDisabled = () => {
        let disabled = !emailAddress ;
        if (!ValidatePassword(emailAddress)) {
            disabled = true;
        }
        return disabled;
    }
    function ValidatePassword(email: any) {
        let isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ? true : false;
        return isValid
    }

    return <>
        <div className="login_page">
            <div className="login_section">
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent message={popup.message} bottom="0.1rem" isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}

                <div className="login_logo_sect">
                    <div className="login_logo">
                        <span className="l_m_logo"><img src={massCdnUrl + "assets/img/site-login.png"} /></span>

                    </div>
                </div>
                <div className="login_form_sect">
                    <div className="login_form_in_sect">
                        <h1 className="login_title">Forgot User ID</h1>
                        <p className="mb-0">Please enter the following information to help us recover your User ID. Your User ID
                         will be sent to the Email ID associated with your account</p>
                        <div className="form-floating ct_form_field">
                            <input type="text" className="form-control" id="email" placeholder="Enter Email Address"
                                value={emailAddress}
                                onKeyPress={handleKeyPress}
                                {...register("register_email", {
                                    required: true, onChange: (e) => { setEmailAddress(e.target.value) }
                                })} />
                            <label htmlFor="email">Enter Your Email ID</label>
                            {errors?.email?.type && errors?.email?.type.toString() === "required" && <p style={{ color: 'red' }}>Please enter valid Email Address</p>}
                        </div>

                        <div className="d-grid mt-4">
                            <button disabled={isDisabled()} className="btn btn-primary text-center" type="submit" 
                            onClick={handleSubmit(userVerificationHandler)}>Submit</button>
                        </div>

                        <span className="or_divider mt-2"></span>

                        <div className="d-grid mt-3">
                            <button onClick={() => navigation("/login")} className="btn btn-outline-primary text-center">Back to Login</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </>
}

export default memo(ForgotPassword);