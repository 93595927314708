import { AgGridFilters } from './gridFiltersConstant';
export const getGridFilters = (agGridFilterModel: any, filters: any) => {
    const filterData = [...filters];
    for (var field in agGridFilterModel) {
        if (agGridFilterModel[field]["filterType"] === "date") {
            if (agGridFilterModel[field]["type"] === "blank" || agGridFilterModel[field]["type"] === "notBlank") {
                filterData.push({
                    field: field,
                    value: "",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else if (agGridFilterModel[field]["type"] === "inRange") {
                filterData.push({
                    field: field,
                    value: "'" + agGridFilterModel[field]["dateFrom"] + "','" + agGridFilterModel[field]["dateTo"] + "'",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else if (agGridFilterModel[field]["dateFrom"]) {
                filterData.push({
                    field: field,
                    value: "'" + agGridFilterModel[field]["dateFrom"] + "'",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else {
                if (agGridFilterModel[field]["condition1"]["type"] === "inRange") {
                    filterData.push({
                        field: field,
                        value: "'" + agGridFilterModel[field]["condition1"]["dateFrom"] + "','" + agGridFilterModel[field]["condition1"]["dateTo"] + "'",
                        operation: AgGridFilters[agGridFilterModel[field]["condition1"]["type"]],
                        operator: agGridFilterModel[field]["operator"],
                        source: "agGrid"
                    })
                } else {
                    filterData.push({
                        field: field,
                        value: "'" + agGridFilterModel[field]["condition1"]["filter"] + "'",
                        operation: AgGridFilters[agGridFilterModel[field]["condition1"]["type"]],
                        operator: agGridFilterModel[field]["operator"],
                        source: "agGrid"
                    })
                }
                if (agGridFilterModel[field]["condition2"]["type"] === "inRange") {
                    filterData.push({
                        field: field,
                        value: "'" + agGridFilterModel[field]["condition2"]["dateFrom"] + "','" + agGridFilterModel[field]["condition2"]["dateTo"] + "'",
                        operation: AgGridFilters[agGridFilterModel[field]["condition2"]["type"]],
                        operator: null,
                        source: "agGrid"
                    })
                } else {
                    filterData.push({
                        field: field,
                        value: "'" + agGridFilterModel[field]["condition2"]["filter"] + "'",
                        operation: AgGridFilters[agGridFilterModel[field]["condition2"]["type"]],
                        operator: null,
                        source: "agGrid"
                    })
                }
            }
        }
        else if (agGridFilterModel[field]["filterType"] === "number") {
            if (agGridFilterModel[field]["type"] === "blank" || agGridFilterModel[field]["type"] === "notBlank") {
                filterData.push({
                    field: field,
                    value: "",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else if (agGridFilterModel[field]["type"] === "inRange") {
                filterData.push({
                    field: field,
                    value: agGridFilterModel[field]["filter"] + "," + agGridFilterModel[field]["filterTo"],
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else if (agGridFilterModel[field]["filter"]) {
                filterData.push({
                    field: field,
                    value: agGridFilterModel[field]["filter"],
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else {
                if (agGridFilterModel[field]["condition1"]["type"] === "inRange") {
                    filterData.push({
                        field: field,
                        value: agGridFilterModel[field]["condition1"]["filter"] + "," + agGridFilterModel[field]["condition1"]["filterTo"],
                        operation: AgGridFilters[agGridFilterModel[field]["condition1"]["type"]],
                        operator: agGridFilterModel[field]["operator"],
                        source: "agGrid"
                    })
                } else {
                    filterData.push({
                        field: field,
                        value: agGridFilterModel[field]["condition1"]["filter"],
                        operation: AgGridFilters[agGridFilterModel[field]["condition1"]["type"]],
                        operator: agGridFilterModel[field]["operator"],
                        source: "agGrid"
                    })
                }
                if (agGridFilterModel[field]["condition2"]["type"] === "inRange") {
                    filterData.push({
                        field: field,
                        value: agGridFilterModel[field]["condition2"]["filter"] + "," + agGridFilterModel[field]["condition2"]["filterTo"],
                        operation: AgGridFilters[agGridFilterModel[field]["condition2"]["type"]],
                        operator: null,
                        source: "agGrid"
                    })
                } else {
                    filterData.push({
                        field: field,
                        value: agGridFilterModel[field]["condition2"]["filter"],
                        operation: AgGridFilters[agGridFilterModel[field]["condition2"]["type"]],
                        operator: null,
                        source: "agGrid"
                    })
                }
            }
        }
        else {
            if (agGridFilterModel[field]["type"] === "blank" || agGridFilterModel[field]["type"] === "notBlank") {
                filterData.push({
                    field: field,
                    value: "",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else if (agGridFilterModel[field]["filter"]) {
                filterData.push({
                    field: field,
                    value: "'" + agGridFilterModel[field]["filter"] + "'",
                    operation: AgGridFilters[agGridFilterModel[field]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
            else {
                filterData.push({
                    field: field,
                    value: agGridFilterModel[field]["condition1"] ? "'" + agGridFilterModel[field]["condition1"]["filter"] + "'" : "",
                    operation: AgGridFilters[agGridFilterModel[field]["condition1"]["type"]],
                    operator: agGridFilterModel[field]["operator"],
                    source: "agGrid"
                })
                filterData.push({
                    field: field,
                    value: agGridFilterModel[field]["condition2"] ? "'" + agGridFilterModel[field]["condition2"]["filter"] + "'" : "",
                    operation: AgGridFilters[agGridFilterModel[field]["condition2"]["type"]],
                    operator: null,
                    source: "agGrid"
                })
            }
        }
    }
    return filterData
}