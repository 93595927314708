/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState } from "react";
import Select from 'react-select';
import awsmobile from "../../aws-exports"
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Loader } from "../../helpers/loader";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { getServicersList } from "../../services/uploadTemplate.service";
import Breadcrumb from "../common/Breadcrumb";
import { readAppState, setBreadcrumbLocation, setLoaderMessage } from "../../redux/reducers/appReducer";
import { selectCustomStyles } from "../common/CustomStyles";

const UploadTemplateComponent = () => {
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: () => onCancel() };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(false);
    const [onClick, setOnClick] = useState(false);
    const [servicer, setServicer] = useState<any>(undefined)
    const [selectedServicer, setSelectedServicer] = useState("");
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const [localState, setLocalState] = useState<any>({
        file: undefined as any,
    });
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];

    useEffect(() => {
        const listServicers = async () => {
            setLoader(true);
            await getServicersList().then(data => {
                if (data) {
                    const servicerArray = data.map(el => {
                        return {
                            label: el.servicer_name,
                            value: el.servicer_bucket
                        }
                    });
                    setServicer(servicerArray)
                    setLoader(false)
                }

            })
        }
        listServicers();
    }, [])

    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        const currentBreadcrumbLocation = breadcrumbLocation.findIndex(x => x.redirect_url === "/BusinessRule");
        if (currentBreadcrumbLocation !== -1) {
            breadcrumbLocation = breadcrumbLocation.filter((x, i) => i <= currentBreadcrumbLocation);
        } else {
            breadcrumbLocation.push(screens.find(x => x.redirect_url === "/BusinessRule"));
        }
        dispatch(setBreadcrumbLocation(breadcrumbLocation));

    }, [popup, onClick])

    const handleFileSelect = (e: any) => {
        if (e.target.files) {
            for (var fileObject in e.target.files) {
                if (typeof e.target.files[fileObject] === "object") {
                    const fileData = (e.target.files[fileObject]);
                    if (fileData.name.includes(" ")) {
                        setPopup({ ...popup, visible: true, message: "File name should not contain empty spaces!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                        return;
                    }
                    const fileBlob = new Blob([fileData], { type: fileData.type });
                    setLocalState({ ...localState.file, file: { fileData: e.target.files[0], accessUrl: URL.createObjectURL(fileBlob), fileName: fileData.name.replaceAll(" ", "_"), fileType: fileData.type, isUploaded: false } });
                }
            }
        }
    }
    const onCancel = () => {
        setPopup({ ...defaultPopup });
        setLocalState({ ...localState, file: undefined });
        setSelectedServicer("");
        setLoader(false)
    }

    const handleSaveClick = async () => {
        setOnClick(true)
        if (localState.file && selectedServicer !== "") {
            setLoader(true);
            const data = await uploadFile({ file: localState.file, folderName: "public/Input", bucket: selectedServicer });
            if (data) {
                setLocalState({ ...localState, file: { accessUrl: "", fileName: "", fileType: "", isUploaded: false } });
                setLoader(false);
                dispatch(setLoaderMessage(""));
                setPopup({ ...popup, visible: true, message: "File uploaded successfully!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                localStorage.removeItem('loaderMessage');
                setOnClick(false);
            }
            else {
                setLoader(false);
                setOnClick(false);
                setPopup({ ...popup, visible: true, message: "Something went wrong!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                dispatch(setLoaderMessage(""))
            }
        }
    }

    const drop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0].name.includes(" ")) {
            setPopup({ ...popup, visible: true, message: "File name should not contain empty spaces!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            return;
        }
        const fileBlob = new Blob([e.dataTransfer.files[0]], { type: e.dataTransfer.files[0].type });
        setLocalState({ ...localState, file: { fileData: e.dataTransfer.files[0], accessUrl: URL.createObjectURL(fileBlob), fileName: e.dataTransfer.files[0].name.replaceAll(" ", "_"), fileType: e.dataTransfer.files[0].type, isUploaded: false } });

    }
    const dragOver = (e) => {
        e.preventDefault();
    }
    const dragStart = (e) => {
        e.preventDefault();
    }

    const uploadFile = async (data: any) => {
        try {
            var file = data.file.fileData;
            const target = {
                Bucket: data.bucket,
                Key: `${data.folderName}/${data.file.fileName}`,
                Body: file
            };
            const credsdata = fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: awsmobile.aws_cognito_region }),
                identityPoolId: awsmobile.aws_cognito_identity_pool_id,

            })
            const uploadParallel = new Upload({
                client: new S3Client({
                    region: awsmobile.aws_cognito_region,
                    credentials: credsdata,
                }),
                params: target,
                leavePartsOnError: false

            });
            dispatch(setLoaderMessage(`Data Loading Started`));

            await uploadParallel.on("httpUploadProgress", progress => {
                if (progress.loaded && progress.total) {
                    dispatch(setLoaderMessage(`${Math.floor((progress.loaded / progress.total) * 100)}% Data Loaded`));
                }
            });
            const response = await uploadParallel.done();
            return response;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    return (
        <>
            <div className="pagetitle"><Breadcrumb /></div>
            <section className="section dashboard">
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent toggle={popup.toggle} isOpen={popup.visible} primaryButtonAction={popup.primaryButtonAction} message={popup.message} type={popup.type} variant={popup.variant} />}
                <div className="justify-content-md-center">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-4">
                            <h6 className="disablesText" style={{ marginTop: "20px" }}>Servicer Name</h6>
                            <div className=" form-floating ct_form_field">
                                <Select
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={selectCustomStyles}
                                    options={servicer}
                                    value={servicer?.filter(x => x.value === (selectedServicer ? selectedServicer : ""))}
                                    onChange={(e) => { setSelectedServicer(e.value) }} />
                                {onClick && !selectedServicer && <p className="is_empty">Please select servicer</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <div className="col-lg-4" onDrop={drop} onDragOver={dragOver} onDragStart={dragStart} >
                            <div className="drag-area">
                                <div className="upload-block" style={{ padding: "19px", minWidth: "max-content", minHeight: "max-content", textAlign: "center" }} >
                                    <div className="uploadIcon" ><i className="fas fa-cloud-upload-alt"></i></div>
                                    <header className="disablesText" style={{ margin: "5px" }}>Drag and Drop to Upload File</header>
                                    <span className="disablesText">OR</span>
                                    <br />
                                    <label style={{ marginTop: "5px", color: "#2377BC", fontWeight: "600" }} className=" text-center " htmlFor="fileUpload" >
                                        <i style={{ marginTop: "0.3rem" }} aria-hidden="true" />
                                        <span style={{ fontSize: "inherit", width: "fit-content" }} >Browse File</span>
                                    </label>
                                    <br />
                                    <input style={{ display: 'none' }} multiple type="file" onClick={(event: any) => event.target.value = null} onChange={handleFileSelect} className="form-control" id="fileUpload" placeholder="Upload" />
                                    {localState.file && <a style={{ marginLeft: "5px" }} >{localState.file.fileName}</a>}
                                    {onClick && !localState.file && <p className="is_empty">Please upload file</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row justify-content-md-center mt30">
                    <div className="col-lg-2 col"><button onClick={() => { handleSaveClick() }} className="btn btn-block btn-lg btn-primary text-center btn-block mb15" type="submit">
                        Upload</button></div>
                    <div className="col-lg-2 col"><button className="btn btn-block btn-lg blue-outline-btn text-center btn-block" type="submit" onClick={() => { onCancel() }} >
                        Reset</button></div>
                </div>
            </section>
        </>
    )
}

export default memo(UploadTemplateComponent);