/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Auth } from "aws-amplify";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../helpers/loader";
import { getUserList } from "../../services/user.service";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import PasswordValidityViewer from "../common/PasswordValidityViewer";

const ForgotPassword = (props: any) => {
    const [userId, setUserId] = useState(props.user);
    const [codeSendFlag, setCodeSendFlag] = useState(false);
    const navigation = useNavigate();
    const [loader, setLoader] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newpasswordconfirm, setNewPasswordConfirm] = useState('');
    const [code, setCode] = useState('');
    const [newPasswordInputType, changeNewPasswordType] = useState("password");
    const [reEnterPasswordInputType, changeReenterPasswordType] = useState("password");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [resetResendButton, setResetResendButton] = useState(false);
    const [showPopover, setPopover] = useState(false);
    const [popup, setPopup] = useState({ isOpen: false, toggle: () => { setPopup({ ...popup, isOpen: false }) }, message: "", variant: "" });
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    let passwordPattern =   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\]{8,}$/
    useEffect(() => {
        getUserList()
    }, [])

    const passwordVerificationHandler = async () => {
        setLoader(true);
        try {
            await Auth.forgotPasswordSubmit(userId, code, newPassword).then(response => {
                setPopup({ ...popup, isOpen: true, message: "Your password has been reset! Please login to your account.", variant: VariantType.Success, toggle: () => { setPopup({ ...popup }); navigation("/login") } });
            });

        }
        catch (error: any) {
            setLoader(false);
            let err: any;
            err = error.code === 'InvalidPasswordException' ? "Your User ID is incorrect.Please try again." : error.message;
            err = error.code === 'CodeMismatchException' ? 'You’ve entered an incorrect verification code. Please click "Resent Code" to receive a new code.' : error.message;
            setPopup({ isOpen: true, toggle: () => { setPopup({ ...popup, isOpen: false }) }, message: err, variant: "danger" });
        }
    }

    const viewNewPassword = (e: any) => {
        if (newPasswordInputType === "password") {
            changeNewPasswordType("text");
        }
        else {
            changeNewPasswordType("password");
        }
    }

    const viewReenterPassword = (e: any) => {
        if (reEnterPasswordInputType === "password") {
            changeReenterPasswordType("text");
        }
        else {
            changeReenterPasswordType("password");
        }
    }

    const forgotPasswordHandler = async () => {
        setLoader(true);
        try {

            await Auth.forgotPassword(userId);
            setNewPassword('');
            setNewPasswordConfirm('');
            setCode('');
            setLoader(false);
            setCodeSendFlag(true);
            setPopup({ isOpen: true, toggle: () => { setPopup({ ...popup, isOpen: false }) }, message: "The verification code was sent to your email.", variant: VariantType.Success });
        }
        catch (error: any) {
            let err = error.code === "UserNotFoundException" ? "You’ve entered an incorrect User ID. Please enter the correct User ID to continue." : error.message;
            setPopup({ isOpen: true, toggle: () => { setPopup({ ...popup, isOpen: false }) }, message: err, variant: "danger" });
            //navigation("/login");
        }
        finally {
            setLoader(false);
        }
    }

    const handleKeyPress = (target: any, elementId: string) => {
        if (target.charCode === 13) {
            if (elementId === "forgot_userId" && userId !== "") {
                forgotPasswordHandler();
            }
            else if (elementId === " forgot_reenter_password" && code !== "" && newPassword !== "" && newpasswordconfirm !== "" && newPassword === newpasswordconfirm) {
                passwordVerificationHandler()
            }
        }
    }

    const codeValidation = (e) => {
        if (/^\d+\.?\d*$/.test(e.target.value)) {
            setCode(e.target.value);
        }
        else if (e.target.value === "") {
            setCode(e.target.value);
        }

    }

    function ValidatePassword(password) {
        let isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\]{8,}$/.test(password) ? true : false;
        return isValid
    }
    
    const isDisabled = () => {
        let disabled = code === "" || newPassword === "" || newpasswordconfirm === "" || newPassword !== newpasswordconfirm;
        if (!ValidatePassword(newPassword) || !ValidatePassword(newpasswordconfirm)) {
            disabled = true;
        }
        return disabled;
    }

    return <>
        <div className="login_page">
            <div className="login_section ">
                {loader && <Loader />}
                <div className="login_logo_sect">
                    <div className="login_logo">
                        <span className="l_m_logo"><img alt="logo" src={massCdnUrl + "assets/img/site-login.png"} /></span>
                    </div>
                </div>

                <div className="login_form_sect">
                    {popup.isOpen && <CustomPopupComponent isOpen={popup.isOpen} bottom="0.1rem" toggle={popup.toggle} message={popup.message} type={ModalType.Snackbar} variant={popup.variant} />}
                    <div className="login_form_in_sect">
                        {
                            codeSendFlag ?
                                <>
                                    <h1 className="login_title">Reset Password</h1>
                                    <p className="mb-0">Please create a new password for your account</p>
                                    <div className="form-floating ct_form_field">
                                        <input
                                            type="text"
                                            autoComplete="new-password"
                                            className="form-control"
                                            placeholder="Enter your code here"
                                            id="verifcation_code"
                                            aria-describedby="emailHelp"
                                            value={code} maxLength={6}
                                            onChange={(e) => { codeValidation(e) }} />
                                        <label htmlFor="verifcation_code">Enter your code here</label>
                                    </div>

                                    <div className="form-floating ct_form_field ct_form_icon_group">
                                        <input
                                            type={newPasswordInputType}
                                            className="form-control "
                                            id="forgot_new_password"
                                            autoComplete="new-password"
                                            placeholder="New Password"
                                            value={newPassword}
                                            onFocus={() => setPopover(true)}
                                            {...register("forgot_new_password", {
                                                required: true, onChange: (e) => setNewPassword(e.target.value), onBlur: () => { setPopover(false) }
                                            })}
                                        />
                                        <span onClick={viewNewPassword} className={`far ${newPasswordInputType === "text" ? "fa-key" : "fa-lock"}`} />
                                        <label htmlFor="forgot_new_password"> New Password</label>
                                        {errors.newPassword?.type && errors.newPassword?.type.toString() === 'required' && <p style={{ color: 'red' }}>Password is required</p>}
                                        {
                                            !showPopover && newPassword !== "" && !passwordPattern.test(newPassword) &&
                                            (
                                                <span className="error-message">
                                                    Your password must contain at least 1 Upper Case Letter, 1 Lower Case Letter, 1 Number and 1 Special Character (such as #&!*@)
                                                </span>
                                            )}
                                    </div>

                                    <div className="form-floating ct_form_field ct_form_icon_group">
                                        <input type={reEnterPasswordInputType} className="form-control" id="forgot_reenter_password" autoComplete="new-password"
                                            placeholder="Re-Enter New Password" onKeyPress={(e) => handleKeyPress(e, "forgot_reenter_password")}
                                            value={newpasswordconfirm}
                                            {...register("forgot_reenter_password", {
                                                required: true, onChange: (e) => { setNewPasswordConfirm(e.target.value) },
                                                onBlur: () => setPopover(false),
                                                validate: value => value === newPassword || "New password and re-entered password does not match",
                                            })} />
                                        <span onClick={viewReenterPassword} className={`far ${reEnterPasswordInputType === "text" ? "fa-key" : " fa-lock"}`} />
                                        <label htmlFor="forgot_reenter_password">Re-Enter New Password</label>
                                        {errors.newpasswordconfirm?.type && errors.newpasswordconfirm?.type.toString() === 'required' && <p style={{ color: 'red' }}>Confirm password is required</p>}
                                        {errors.newpasswordconfirm?.type && errors.newpasswordconfirm?.type.toString() === 'validate' && <p style={{ color: 'red' }}>Password does not match</p>}
                                    </div>
                                    {(passwordPattern.test(newPassword) && newPassword && newpasswordconfirm && newpasswordconfirm !== newPassword && isDisabled()) && <div className="row"> <p style={{ color: 'red' }}>Your passwords do not match. Please re-enter your new password.</p></div>}
                                    <div className="row">
                                        <div className="col">
                                            <button disabled={isDisabled()}
                                                onClick={handleSubmit(passwordVerificationHandler)}
                                                className="btn btn-primary text-center w-100" type="submit">Submit</button>
                                        </div>
                                        <div className="col">
                                            <button disabled={resetResendButton} className="btn btn-primary text-center w-100" type="submit"
                                                onClick={(e) => {
                                                    setResetResendButton(true); forgotPasswordHandler(); setTimeout(() => setResetResendButton(false), 10000);
                                                }}>Resend Code</button>
                                        </div>
                                    </div>

                                    <span className="or_divider mt-2"></span>

                                    <div className="d-grid mt-3">
                                        <button onClick={() => navigation("/login")} className="btn btn-outline-primary text-center">Back to Login</button>
                                    </div>

                                    <PasswordValidityViewer password={newPassword} showPopover={showPopover} id="forgot_new_password" />
                                </>
                                :
                                <>
                                    <h1 className="login_title">Forgot Password</h1>
                                    <p className="m0">Please enter the User ID associated with your account. We will send a 6 digit verification code to your email.</p>
                                    <div className="form-floating ct_form_field">
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            className="form-control"
                                            id="forgot_userId"
                                            placeholder="userId"
                                            aria-describedby="emailHelp"
                                            value={userId}
                                            onKeyPress={(e) => handleKeyPress(e, "forgot_userId")}
                                            onChange={(e) => setUserId(e.target.value)} />
                                        <label htmlFor="forgot_userId">User ID</label>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button disabled={!userId || userId === "" ? true : false} className="btn btn-primary text-center" type="submit" onClick={forgotPasswordHandler}>
                                            Send Code</button>
                                    </div>
                                    <span className="or_divider"></span>
                                    <div className="d-grid mt-4">
                                        <button onClick={() => navigation("/login")} className="btn btn-outline-primary text-center mt15">Back to Login</button>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default memo(ForgotPassword);