import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../constants/commonConstants';
import { ReportSortingValues } from '../../constants/reportsContants';
import { deleteReportById, getFilteredVisuals } from '../../services/visual.service';
import { ReportState } from '../stores/reportStore';

const initialState: ReportState = {
    errorMessage: "",
    status: Status.Idle,
    currentReports: [],
    allReports: [],
    filteredReports: [],
    reportSortingType: ReportSortingValues.ReportNameAtoZ,
    reportPageNo: 1,
    isReportLoaded: false,
    activeTab:"canned"
};


export const getReportsAsync = createAsyncThunk(
    'app/getReports',
    async (params: any) => {
        const response = await getFilteredVisuals(params.token, params.currentUser, params.logiConfig, params.userGroups);
        var reportList = response.filter(x => x.dashboardUsageCount == 0)
        return reportList;
    }
);

export const deleteReportAsync = createAsyncThunk(
    'app/deleteReport',
    async (params: any) => {
        const response = await deleteReportById(params.token, params.reportId);
        return response;
    }
);

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        sortReports: (state: ReportState, action: PayloadAction<string>) => {
            state.reportSortingType = action.payload;
            let sortedReports: any[] = [];
            switch (action.payload) {
                case ReportSortingValues.ReportNameAtoZ:
                    sortedReports = state.allReports.sort((a, b) => a.visualName.localeCompare(b.visualName));
                    break;
                case ReportSortingValues.ReportNameZtoA:
                    sortedReports = state.allReports.sort((a, b) => b.visualName.localeCompare(a.visualName));
                    break;
                case ReportSortingValues.CreatedDateAscending:
                    sortedReports = state.allReports.sort((a, b) => new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? 1 : -1);
                    break;
                case ReportSortingValues.CreatedDateDescending:
                    sortedReports = state.allReports.sort((a, b) => new Date(a.createdDate).getTime() > new Date(b.createdDate).getTime() ? -1 : 1);
                    break;
                case ReportSortingValues.ModifiedDateAscending:
                    sortedReports = state.allReports.sort((a, b) => new Date(a.lastModifiedDate).getTime() > new Date(b.lastModifiedDate).getTime() ? 1 : -1);
                    break;
                case ReportSortingValues.ModifiedDateDescending:
                    sortedReports = state.allReports.sort((a, b) => new Date(a.lastModifiedDate).getTime() > new Date(b.lastModifiedDate).getTime() ? -1 : 1);
                    break;
            }
            state.allReports = sortedReports;
            state.filteredReports = sortedReports;
            state.currentReports = sortedReports.slice(0, 15);
        },
        changeReportPage: (state: ReportState, action: PayloadAction<number>) => {
            state.reportPageNo = action.payload;
            state.currentReports = state.filteredReports.slice(((15 * action.payload) - 15), (15 * action.payload));
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        filterReports: (state: ReportState, action: PayloadAction<string>) => {
            let filteredReports = state.allReports.filter((value) => {
                return value.visualName.toLowerCase().includes(action.payload.toLowerCase());
            });
            state.filteredReports = filteredReports;
            state.currentReports = filteredReports.slice(0, 15);
            state.reportPageNo = 1;
        },
        resetFilteredReports: (state: ReportState) => {
            state.filteredReports = state.allReports;
            state.currentReports = state.allReports.slice(0, 15);
            state.reportPageNo = 1;
        },
        setAllReports: (state: ReportState, action: PayloadAction<any>) => {
            state.allReports = action.payload;
            state.filteredReports = action.payload;
            state.currentReports = action.payload.slice(0, 15);
            state.reportPageNo = 1;
        },
        setTab: (state: ReportState, action: PayloadAction<any>) => {
            state.activeTab = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReportsAsync.pending, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
                state.isReportLoaded = false;
            })
            .addCase(getReportsAsync.fulfilled, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                let reports = action.payload.sort((a, b) => a.visualName.localeCompare(b.visualName));
                state.allReports = reports;
                state.filteredReports = reports;
                state.currentReports = reports.slice(0, 15);
                state.reportPageNo = 1;
                state.isReportLoaded = true;
            })
            .addCase(getReportsAsync.rejected, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.allReports = [];
                state.filteredReports = [];
                state.currentReports = [];
                state.errorMessage = "There was a problem fetching Reports.";
                state.isReportLoaded = true;
            })
            .addCase(deleteReportAsync.pending, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(deleteReportAsync.fulfilled, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                let reports = current(state.allReports).filter(i => i.id !== action.payload);
                state.allReports = reports;
                state.filteredReports = reports;
                state.currentReports = reports.slice(0, 15);
                state.reportPageNo = 1;
            })
            .addCase(deleteReportAsync.rejected, (state: ReportState, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.errorMessage = "There was a problem deleting Report."
            })
    }
});

export const {
    sortReports,
    changeReportPage,
    filterReports,
    resetFilteredReports,
    setAllReports,
    setTab
} = reportSlice.actions;

export default reportSlice.reducer;