/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Loader } from "../../helpers/loader";
import { getFiltersList } from "../../helpers/logiHelper";
import { readAppState, resetFilterKeys, setBreadcrumbLocation, setCurrentEmbededDashboard, setLogiToken } from "../../redux/reducers/appReducer";
import { setDocumentLevelSearch, setStep,setHeading } from "../../redux/reducers/assignmentClaimReducer";
import { setAppliedFilters, setCurrentSource } from "../../redux/reducers/dashboardReducer";
import { addLoanTab, clearLoanTabs } from "../../redux/reducers/loanReducer";
import { getDashboardToken } from "../../services/dashboard.service";
import { dashNavCustomStyle } from "../common/CustomStyles";

const AssignmentClaims = (props: any) => {
  const [loading, setLoader] = useState(true);
  const appState = useAppSelector(readAppState);
  const dispatch = useAppDispatch();
  const logiEmbedManager = useRef(undefined as any);
  const widgets = useRef(undefined as any);
  const widgetsFields = useRef([] as any);
  const componentInstanceId = useRef(undefined as any);
  const reloadFlag = useRef(false);
  const breadcrumbs = useRef(appState.breadcrumbLocation);
  const isSharedDashboard =useRef(false) 
  const current_dashboard_id=useRef('')
 
  const getMasterToken = async () => {
    if (appState.logiToken) {
      return appState.logiToken;
    }
    const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration }).then(r => {
      return r
    })
    if (token) {
      dispatch(setLogiToken(token.access_token));
    }
    return token;
  };

  
  useEffect(()=>{
    current_dashboard_id.current=appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value?appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value.split('+')[1]:''    
    const currentDashboard = appState && (appState.masterDashboardList.find(dash => dash.inventoryItemId === current_dashboard_id.current));
    isSharedDashboard.current = currentDashboard && currentDashboard.creatorId !== (appState.userGroups.find(g => g.group_id === appState.currentUser?.group_id)).logi_user_id;

},[])

const sharedDashboardInteractivity = {
"name": "interactive",
"type": "SYSTEM",
"overrideVisualInteractivity": true,
"settings": {
  "REFRESH": true,
  "CHANGE_LAYOUT": false,
  "RENAME": false,
  "SHARE_FILTER_SETS": false,
  "DASHBOARD_INTERACTIONS": false,
  "ADD_TO_FAVORITES": false,
  "DELETE": false,
  "FILTER": true,
  "EXPORT_PNG_PDF": true,
  "ADD_VISUALS": false,
  "EXPORT_CONFIGURATION": false,
  "DASHBOARD_LINKS": false,
  "SAVE": false,
},
"visualSettings": {
  "ACTIONS_ACTION": true,
  "RULERS": true,
  "ZOOM_ACTION": false,
  "FILTER_ACTION": true,
  "COLORS": true,
  "METRICS": true,
  "ACTIONS": true,
  "TREND_ACTION": false,
  "VISUAL_STYLE": true,
  "KEYSET_ACTION": false,
  "KEYSET": false,
  "COPY": true,
  "SETTINGS": true,
  "EXPORT": true,
  "TIMEBAR_PANEL": true,
  "DETAILS_ACTION": true,
  "MAXIMIZE": true,
  "LINK_ACTION": true,
  "FILTER": true,
  "REMOVE": false,
  "GROUPING": true,
  "RENAME": false,
  "SORT": true,
  "TIMEBAR_FIELD": true
}
}


  useEffect(() => {
    if (logiEmbedManager && logiEmbedManager.current) {
      logiEmbedManager.current.removeComponent(componentInstanceId.current);
      embedComponent(logiEmbedManager.current);
    }
  }, [props.dashboardName, props.dashboardkey]);

  const handleTableClick = async (e) => {
    try {
      const filterList = await getFiltersList(e);
      const target = e.target;
      if (target.classList.contains('ag-cell')) {
        let loan_id = target.parentNode.querySelector('[col-id="loan_number"]') ? target.parentNode.querySelector('[col-id="loan_number"]').innerText : null;
        if (loan_id) {
          dispatch(setBreadcrumbLocation([{
            screen_name: "Loan Details",
            redirect_url: "/AssignmentClaims",
            icon_url: "fad fa-user"
          }]));
          dispatch(setStep(3));
          dispatch(addLoanTab(loan_id));
        }
      }
      if (target.hasAttribute('data-test') && target.getAttribute('data-test') === "kpi-primary-value" && target.nextElementSibling.hasAttribute('data-test') && target.nextElementSibling.getAttribute('data-test') === "kpi-primary-label" && target.nextElementSibling.innerText.toLowerCase() === "loan count") {
        const currentWidgetId = target.closest(".zd-grid-item-body").getAttribute("data-widget-id-view");
        let visualName = '';
        if (currentWidgetId) {
          const currentWidget = widgets.current.find(x => x.widgetId === currentWidgetId);
          visualName=currentWidget?.visualName;
          if (currentWidget) {
            dispatch(setCurrentSource(currentWidget.source.sourceId));
            const currentWidgetFields = widgetsFields.current.find(x => x.widgetId === currentWidgetId);
            if (currentWidgetFields && currentWidgetFields.fields) {
              const updatedFilterList = filterList.map(filter => {
                const currentField = currentWidgetFields.fields.find(x => x.label === filter.field);
                if (currentField && currentField.type !== 'NUMBER') {
                  filter.value = "'" + filter.value + "'";
                }
                filter.field = currentField.name;
                return filter;
              })
              dispatch(setAppliedFilters(updatedFilterList));
            }
          }
        }
        if (props.dashboardName === "assignment_impediment_dashboard") {
          dispatch(setDocumentLevelSearch(true));
          dispatch(setBreadcrumbLocation([{
            screen_name: "Document List",
            redirect_url: "/AssignmentClaims",
            icon_url: "fad fa-user"
          }]));
        }
        else {

          dispatch(setBreadcrumbLocation([{
            screen_name: "Loan List",
            redirect_url: "/AssignmentClaims",
            icon_url: "fad fa-user"
          }]));
          dispatch(setDocumentLevelSearch(false));
        }
        dispatch(setStep(2));
        dispatch(setHeading(visualName));
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }

  const initClicks = (dashEvent: any) => {
    if (props.gridAbbreviation) {
      let columnName = 'loan_number';
      const dashboardGrids = appState.logiConfiguration.filter(x => x.configuration_type.includes(props.gridAbbreviation));
      dashboardGrids.forEach(x => {
        const style = document.createElement('style');
        style.setAttribute("name", "user_added");
        style.innerHTML = `.${props.dashboardName} [col-id="${columnName}"].ag-cell {${dashNavCustomStyle}}`;
        document.getElementsByTagName('head')[0].appendChild(style);
      })
      const allColumnsClickableTable = document.querySelectorAll(`[ref="eBodyViewport"].ag-body-viewport`);
      if (allColumnsClickableTable) {
        allColumnsClickableTable.forEach(x => {
          x?.addEventListener('click', e => handleTableClick(e));
        })
      }

      const allKpis = document.querySelectorAll(`[data-test="kpi-primary-value"]`);
      if (allKpis) {
        if (allKpis) {
          allKpis.forEach(x => {
            x?.addEventListener('click', e => handleTableClick(e));
            if (x.nextElementSibling && x.nextElementSibling.getAttribute('data-test') === "kpi-primary-label") {
              const kpiPrimaryLable: any = x;
              if (kpiPrimaryLable.nextElementSibling.innerText.toLowerCase() === "loan count") {
                x.classList.add('clickable');
              }
            }
          })
        }
      }
      if (document.getElementById(`${props.dashboardName} embed`)) {
        document.getElementById(`${props.dashboardName} embed`)!.className = props.dashboardName;
      }
    }
  }

  const embedComponent = (embedManager: any) => {
    setLoader(false);
    embedManager.createComponent('dashboard', {
      "dashboardId": appState.logiConfiguration.find(x => x.configuration_type === props.dashboardkey)?.configuration_value,
      "theme": props.theme,
      "header": {
        'visible': props.showEditor,
        'showTitle': props.showTitle,
        'showActions': props.showActions,
        'showEditor': props.showEditor,
      },
      "interactivityProfileName": props.interactivityProfileName,
      "interactivityOverrides":  isSharedDashboard.current ?sharedDashboardInteractivity : props.interactivityOverrides

    }).then((dash: any) => {
      dispatch(setCurrentEmbededDashboard(dash.componentInstanceId));
      componentInstanceId.current = dash.componentInstanceId;
      dash.render(document.getElementById(`${props.dashboardName} embed`), { width: props.width, height: props.height });
      if (props.gridAbbreviation) {
        dash.addEventListener('composer-dashboard-ready', function (e) {
          initClicks(e);
          widgets.current = e.detail.dashboard.widgets;
        });
        dash.addEventListener('composer-visual-rendered', function (e) {
          const fields = e.detail.visualization.getAllFields();
          const widgetId = e.detail.visual.widgetId;
          const updatedFieldsList = [...widgetsFields.current];
          updatedFieldsList.push({
            widgetId: widgetId,
            fields: fields
          })
          widgetsFields.current = updatedFieldsList;
        });
      }
    });
  }

  useEffect(() => {
    try {
      (window as any).initLogiEmbedManager({ getToken: () => getMasterToken().then((r) => r) }).then((embedManager: any) => {
        logiEmbedManager.current = embedManager
        setLoader(false);
        embedComponent(embedManager);

      })
        .catch((e: any) => { console.log(`Logi Error: ${e}`); })
      return (() => {
        widgetsFields.current = [];
        if (!reloadFlag.current) {
          dispatch(clearLoanTabs());
          localStorage.removeItem("loanScreenData");
        }
        dispatch(resetFilterKeys());
        if (logiEmbedManager.current && componentInstanceId.current) {
          logiEmbedManager.current.removeComponent(componentInstanceId.current);
          dispatch(setCurrentEmbededDashboard(null));
        }
      });
    }
    catch (e: any) {
      console.log(e)
    }
  }, []);

  useEffect(() => {
    breadcrumbs.current = appState.breadcrumbLocation;
  }, [appState.breadcrumbLocation])

  return (
    <>
      <div className="assignment_claim container-fluid position-relative">
        <div className="content_section">
          <div className={`container min_height_dashboard`}>
            {loading ? (<Loader />) : null}
            <div className={props.dashboardName} id={`${props.dashboardName} embed`}>
            </div>
          </div>
        </div>
      </div>
    </>);
}

AssignmentClaims.defaultProps = {
  composerUrl: '',
  theme: "modern",
  id: '',
  appBanner: false,
  appLogo: false,
  showEditor: true,
  showActions: true,
  showTitle: false,
  width: "100%",
  height: "100%",
  interactivityProfileName: "interactive",
  interactivityOverrides: {
    "name": "interactive",
    "type": "SYSTEM",
    "overrideVisualInteractivity": true,
    "settings": {
      "REFRESH": true,
      "CHANGE_LAYOUT": false,
      "RENAME": false,
      "SHARE_FILTER_SETS": false,
      "DASHBOARD_INTERACTIONS": false,
      "ADD_TO_FAVORITES": false,
      "DELETE": false,
      "FILTER": true,
      "EXPORT_PNG_PDF": true,
      "ADD_VISUALS": false,
      "EXPORT_CONFIGURATION": false,
      "DASHBOARD_LINKS": false,
      "SAVE": true,
    },
    "visualSettings": {
      "ACTIONS_ACTION": true,
      "RULERS": true,
      "ZOOM_ACTION": false,
      "FILTER_ACTION": true,
      "COLORS": true,
      "METRICS": true,
      "ACTIONS": true,
      "TREND_ACTION": false,
      "VISUAL_STYLE": true,
      "KEYSET_ACTION": false,
      "KEYSET": false,
      "COPY": true,
      "SETTINGS": true,
      "EXPORT": true,
      "TIMEBAR_PANEL": true,
      "DETAILS_ACTION": true,
      "MAXIMIZE": true,
      "LINK_ACTION": true,
      "FILTER": true,
      "REMOVE": false,
      "GROUPING": true,
      "RENAME": false,
      "SORT": true,
      "TIMEBAR_FIELD": true
    }
  }
}
export default memo(AssignmentClaims);