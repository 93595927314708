import React, { memo } from "react";
import { useState } from "react";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

const PopoverComponent = (props: any) => {

    const [isOpen, toggle] = useState(false);

    const renderData = () => {
        let data: any = [];
        for (const key in props.data) {
            data.push(
                <React.Fragment key={props.data.file_name + key}>
                    <small><b>{key}</b>: {props.data[key]}</small>
                    <br />
                </React.Fragment>);
        }
        return data;
    }

    return (<>
        <div className="btn-group-toggle" style={{ marginLeft: "5px", marginTop: "1px" }} data-toggle="buttons">
            <span id={props.id} className={"fas fa-info-circle theme_icon"} onMouseOut={() => toggle(false)} onMouseOver={() => toggle(true)} />
        </div>

        <Popover trigger="legacy" placement="bottom" isOpen={isOpen} target={props.id} toggle={() => toggle(!isOpen)}>
            <PopoverHeader>{props.header}</PopoverHeader>
            <PopoverBody>{renderData()}</PopoverBody>
        </Popover>
    </>
    );
}

export default memo(PopoverComponent);