import { createRuleGroup, createRuleGroupMapping, updateRuleGroup, deleteRuleGroupByRuleGroupId, deleteRuleGroupMappingByRuleGroupId, updateInputOutputTemplateMapping } from './../graphql/mutations';
import { API, graphqlOperation } from "aws-amplify";
import { createBusinessRule, createBusinessRuleMapping, createBusinessRuleMappingInBulk, createBussinessRuleInBulk, deleteBusinessRule, updateBusinessRule } from "../graphql/mutations";
import { getBusinessRule, getExcutedRule, getOutputTemplateTableData, listRuleGroup, listRuleGroupMapping, listInputOutputTemplate, listInputOutputTemplateField } from "../graphql/queries";

export const insertBusinessRule = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createBusinessRule, { input: data }));
        return response && response.data && response.data.createBusinessRule ? response.data.createBusinessRule : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const insertBusinessRuleMapping = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createBusinessRuleMapping, { input: data }));
        return response && response.data && response.data.createBusinessRuleMapping ? response.data.createBusinessRuleMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getOutputTemplateTable = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getOutputTemplateTableData, { output_template_id: data }));
        return response && response.data && response.data.getOutputTemplateTableData ? response.data.getOutputTemplateTableData : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const executeRuleQuery = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getExcutedRule, { ruleQuery: data }));
        return response && response.data && response.data.getExcutedRule ? JSON.parse(response.data.getExcutedRule) : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getRuleDetail = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(getBusinessRule));
        if (response && response.data && response.data.getBusinessRule) {
            return response.data.getBusinessRule.map(x => {
                if (x.basic_sql_query) {
                    x.basic_sql_query = JSON.parse(x.basic_sql_query).data;
                }
                return x;
            })
        }
        else {
            return null;
        }
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteBusinessRuleDetail = async (id: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteBusinessRule, { input: { rule_id: id } }));
        return response && response.data && response.data.deleteBusinessRule ? response.data.deleteBusinessRule : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const getInputOutputTemplates = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listInputOutputTemplate));
        return response && response.data && response.data.listInputOutputTemplate ? response.data.listInputOutputTemplate : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getInputOutputTemplateFields = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(listInputOutputTemplateField, { rule_view: data }));
        return response && response.data && response.data.listInputOutputTemplateField ? response.data.listInputOutputTemplateField : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateBusinessRuleData = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateBusinessRule, { input: data }));
        return response && response.data && response.data.updateBusinessRule ? response.data.updateBusinessRule : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const bulkInsertBusinessRule = async (data: any, limit: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createBussinessRuleInBulk, { value: data, inputLimit: limit }));
        return response && response.data && response.data.createBussinessRuleInBulk ? response.data.createBussinessRuleInBulk : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
export const bulkInsertBusinessRuleMapping = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createBusinessRuleMappingInBulk, { value: data }));
        return response && response.data && response.data.createBusinessRuleMappingInBulk ? response.data.createBusinessRuleMappingInBulk : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const getRuleGroupList = async () => {
    try {
        const response: any = await API.graphql(graphqlOperation(listRuleGroup));
        return response && response.data && response.data.listRuleGroup ? response.data.listRuleGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
export const getRuleGroupMappingList = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(listRuleGroupMapping, { rule_group_id: data }));
        return response && response.data && response.data.listRuleGroupMapping ? response.data.listRuleGroupMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
export const insertRuleGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createRuleGroup, { input: data }));
        return response && response.data && response.data.createRuleGroup ? response.data.createRuleGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}
export const insertRuleGroupMapping = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(createRuleGroupMapping, { input: data }));
        return response && response.data && response.data.createRuleGroupMapping ? response.data.createRuleGroupMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}


export const editRuleGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateRuleGroup, { input: data }));
        return response && response.data && response.data.updateRuleGroup ? response.data.updateRuleGroup : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteRuleGroup = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteRuleGroupByRuleGroupId, { input: data }));
        return response && response.data && response.data.deleteRuleGroupByRuleGroupId ? response.data.deleteRuleGroupByRuleGroupId : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const deleteRuleGroupMapping = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(deleteRuleGroupMappingByRuleGroupId, { input: data }));
        return response && response.data && response.data.deleteRuleGroupMappingByRuleGroupId ? response.data.deleteRuleGroupMappingByRuleGroupId : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const UpdateInputOutputTemplateGroupId = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(updateInputOutputTemplateMapping, { input: data }));
        return response && response.data && response.data.updateInputOutputTemplateMapping ? response.data.updateInputOutputTemplateMapping : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

