/* eslint-disable jsx-a11y/anchor-has-content */
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { SubMenus } from "../../constants/screensWithSideMenu";
import { setShowSearchBar } from "../../redux/reducers/dashboardReducer";
import { readAppState, setBreadcrumbLocation, setNavMenuStatus } from "../../redux/reducers/appReducer";
import { readDashboardState } from '../../redux/reducers/dashboardReducer'
import { setStep } from "../../redux/reducers/assignmentClaimReducer";
import { clearLoanTabs, setDocumentsData } from "../../redux/reducers/loanReducer";
import { getBrowserName, userActivityLogs } from "../../services/user.service";

/* eslint-disable jsx-a11y/anchor-is-valid */
const SideMenu = () => {
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector((state: RootState) => state.loan);
    const dispatch = useAppDispatch();
    const navigation = useNavigate();
    const location = useLocation();
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const sideNavRef = useRef<HTMLDivElement>(null);
    const mobileQuery = window.matchMedia('(max-width:1024px)');

    useEffect(() => {
        if (!appState.isNavMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [appState.isNavMenuOpen]);

    const handleClickOutside = (event: MouseEvent) => {
        if (!appState.isNavMenuOpen && mobileQuery.matches) {
            if (sideNavRef.current && !sideNavRef.current.contains(event.target as Node)) {
                setTimeout(() => {
                    dispatch(setNavMenuStatus())
                }, 100)

            }
        }

    }
    const handleBreadcrumb = (path: string) => {
        dispatch(setDocumentsData({ ...loanState?.documentsData, saved_sort: null, saved_filter: null, current_page: 0, is_edit: false }));
        const menu = screens.find(x => x.redirect_url.trim().toLowerCase() === path.trim().toLowerCase());
        if (menu) {
            if (menu.screen_name === "Assignment Claims") {
                dispatch(setStep(11));
            }
            if (menu.screen_name === "Admin") {
                dispatch(setBreadcrumbLocation([{
                    "screen_id": menu.screen_id,
                    "screen_name": "User Management",
                    "redirect_url": menu.redirect_url,
                    "icon_url": menu.icon_url
                }]))
            }
            else {
                dispatch(setBreadcrumbLocation([{
                    "screen_id": menu.screen_id,
                    "screen_name": menu.screen_name,
                    "redirect_url": menu.redirect_url,
                    "icon_url": menu.icon_url
                }]));
            }
        }
    }
    const handleNavigationToggle = () => {
        if (mobileQuery.matches && !appState.isNavMenuOpen) {
            dispatch(setNavMenuStatus())
        }
    }
    const menuClickHandle = (path: string) => {
        handleClearLoanTabs(path);
        handleBreadcrumb(path);
        navigation(path);
        userActivityLogs({
            user_id: appState?.currentUser?.user_id,
            maas_user_id: appState?.currentUser?.maas_user_id,
            activity: `Visited ${path.replace("/", "")} Screen`,
            inserted_by: appState?.currentUser?.maas_user_id,
            inserted_date: new Date().toUTCString(),
            browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
        })
    }

    const subMenuClickHandle = (path: string) => {
        if (path === "/AssignmentClaims") {
            handleClearLoanTabs(path);
            navigation(path);
            userActivityLogs({
                user_id: appState?.currentUser?.user_id,
                maas_user_id: appState?.currentUser?.maas_user_id,
                activity: `Visited ${path.replace("/", "")} Screen`,
                inserted_by: appState?.currentUser?.maas_user_id,
                inserted_date: new Date().toUTCString(),
                browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
            })
            dispatch(setBreadcrumbLocation([{
                screen_name: "Assignment Claims",
                redirect_url: "/AssignmentClaims",
                icon_url: "fad fa-user"
            }])); dispatch(setStep(11))
        }
        if (path === "/AssignmentClaimsImpediments") {
            handleClearLoanTabs("/AssignmentClaims");
            navigation("/AssignmentClaimsImpediments");
            userActivityLogs({
                user_id: appState?.currentUser?.user_id,
                maas_user_id: appState?.currentUser?.maas_user_id,
                activity: "Visited AssignmentClaimsImpediments Screen",
                inserted_by: appState?.currentUser?.maas_user_id,
                inserted_date: new Date().toUTCString(),
                browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
            })
            dispatch(setBreadcrumbLocation([{
                screen_name: "Assignment Claims Impediments",
                redirect_url: "/AssignmentClaimsImpediments",
                icon_url: "fad fa-user"
            }])); dispatch(setStep(12))
        }
        if (path === "/BillSummary") {
            handleClearLoanTabs("/BillSummary");
            navigation("/BillSummary");
            userActivityLogs({
                user_id: appState?.currentUser?.user_id,
                maas_user_id: appState?.currentUser?.maas_user_id,
                activity: "Visited FNMA Billing Summary Screen",
                inserted_by: appState?.currentUser?.maas_user_id,
                inserted_date: new Date().toUTCString(),
                browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
            })
            dispatch(setBreadcrumbLocation([{
                screen_name: "Billing Summary",
                redirect_url: "/BillSummary",
                icon_url: "fad fa-user"
            }]));
        }
        if (path === "/OpenBillAnalysis") {
            handleClearLoanTabs("/OpenBillAnalysis");
            navigation("/OpenBillAnalysis");
            userActivityLogs({
                user_id: appState?.currentUser?.user_id,
                maas_user_id: appState?.currentUser?.maas_user_id,
                activity: "Visited FNMA Open Bill Analysis Screen",
                inserted_by: appState?.currentUser?.maas_user_id,
                inserted_date: new Date().toUTCString(),
                browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
            })
            dispatch(setBreadcrumbLocation([{
                screen_name: "Open Bill Analysis",
                redirect_url: "/OpenBillAnalysis",
                icon_url: "fad fa-user"
            }]));
        }
        else {
            handleClearLoanTabs(path);
            handleBreadcrumb(path[0]);
            navigation(path);
            userActivityLogs({
                user_id: appState?.currentUser?.user_id,
                maas_user_id: appState?.currentUser?.maas_user_id,
                activity: `Visited ${path.replace("/", "")} Screen`,
                inserted_by: appState?.currentUser?.maas_user_id,
                inserted_date: new Date().toUTCString(),
                browser_name:getBrowserName(window.navigator.userAgent.toLowerCase())
            })
        }
    }

    const handleClearLoanTabs = (path: string) => {
        handleNavigationToggle();
        if (`/${location.pathname.split('/')[1]}` !== path) {
            dispatch(clearLoanTabs());
        }
    }
    const menuToggle = (event, hasSubMenu, screen, selector) => {
        if (event.key === "Enter") {
            if (!hasSubMenu) {
                menuClickHandle(screen.redirect_url);
            } else {
                const target = document.getElementById(selector);
                if (target) {
                    target.classList.toggle("show");
                }
            }
        }
    }
    const subMenuToggle = (event, hasMenu, selector, url) => {
        if (event.key == "Enter") {
            if (!hasMenu) {
                subMenuClickHandle(url);
            } else {
                const target = document.getElementById(selector);
                if (target) {
                    target.classList.toggle("show");
                }
            }
        }
    }
    const showSubmenu = (item: any, i, hasMenu, isSubMenuActive, subSelector, selector, customKeyStr) => {
        return <li className="clickable" key={customKeyStr + item.url + "subm" + i}>
            <a onClick={() => !hasMenu ? subMenuClickHandle(item.url) : "#"}
                onKeyPress={(event) => subMenuToggle(event, hasMenu, subSelector, item.url)}
                className={`nav-link collapsed ${isSubMenuActive ? "active " : ""} `} data-bs-target={`#${subSelector}`} data-bs-toggle="collapse" aria-expanded="false">
                <i className='bi bi-circle-fill'></i><span>{item.screenName}</span>
                {hasMenu && <i className="navarrow bi bi-chevron-down ms-auto"></i>}
            </a>
            {
                item.subMenus?.length > 0 &&
                <ul id={subSelector} className="nav-content collapse clickable" data-bs-parent={`#${selector}`}>
                    {item.subMenus.map((subItem, i) => {
                        let isSubSubMenuActive = subItem.url.includes(location.pathname);
                        return (
                            <li className="clickable" key={customKeyStr + subItem.url[0] + "subm"}>
                                <a className={`nav-link  ${isSubSubMenuActive ? "active" : ""} `} onClick={() => { handleClearLoanTabs(subItem.url[0]); handleBreadcrumb(subItem.url[0]); navigation(subItem.url[0]) }}
                                    onKeyPress={(e) => { if (e.key === "Enter") { handleClearLoanTabs(subItem.url[0]); handleBreadcrumb(subItem.url[0]); navigation(subItem.url[0]) } }}>
                                    <div className="sidenav-icon"><i style={{ marginTop: "9px" }} className={`bi bi-circle  ${isSubSubMenuActive ? "sidebar-submenu-active" : ""} `}></i></div>
                                    <span>{subItem.screenName}</span>
                                </a>
                            </li>
                        )
                    })
                    }
                </ul>
            }
        </li>
    };

    return (
        <aside id="sidebar" ref={sideNavRef} className={`sidebar ${!appState.isNavMenuOpen ? "active" : ""}`}>
            <a className="toggle-sidebar-btn" onClick={() => dispatch(setNavMenuStatus())} onKeyPress={(target) => { if (target.charCode === 13) { dispatch(setNavMenuStatus()) } }}></a>
            <ul className="sidebar-nav" id="sidebar-nav">
                {
                    appState.screensAccessible && appState.screensAccessible.filter(v => !v.is_hidden).map((screen: any, i: any) => {
                        let hasSubMenu = SubMenus[screen.screen_name] && SubMenus[screen.screen_name].length > 0;
                        let selector = "sidemenu" + i;
                        let isActive = (location.pathname === screen.redirect_url) || (hasSubMenu && SubMenus[screen.screen_name]?.filter(i => i.subMenus?.filter(s => s.url.includes(location.pathname)).length > 0 || i.url === location.pathname).length > 0) ||
                            appState.breadcrumbLocation.filter(i => i.redirect_url === screen.redirect_url).length > 0;

                        return <li className="nav-item" key={screen.screen_id + "nav"}>
                            <a className={`nav-link collapsed ${isActive ? "active" : ""} `}
                                onClick={() => !hasSubMenu ? menuClickHandle(screen.redirect_url) : "#"}
                                onKeyPress={(event) => menuToggle(event, hasSubMenu, screen, selector)}
                                data-bs-target={`#${selector}`}
                                data-bs-toggle="collapse">
                                <div className="sidenav-icon"><i title={screen.screen_name} className={screen.icon_url}></i></div>
                                <span>{screen.screen_name}</span>
                                {hasSubMenu && <i className="bi bi-chevron-down ms-auto"></i>}
                            </a>
                            {hasSubMenu &&
                                <ul id={selector} className="nav-content collapse" data-bs-parent="#sidebar-nav" >
                                    {
                                        SubMenus[screen.screen_name].map((item, i) => {
                                            let subSelector = "submenu" + item.screenName.trim() + i;
                                            let isSubMenuActive = item.subMenus?.filter(i => i.url.includes(location.pathname)).length > 0 || location.pathname === item.url;
                                            let hasMenu = item.subMenus?.length > 0;
                                            return <>
                                                {item.visibility == 'SuperAdmin' ?
                                                    appState.isSuperAdminUser &&
                                                    showSubmenu(item, i, hasMenu, isSubMenuActive, subSelector, selector, 'SA')
                                                    : showSubmenu(item, i, hasMenu, isSubMenuActive, subSelector, selector, 'User')
                                                }
                                            </>
                                        })
                                    }
                                </ul>
                            }
                        </li>
                    })
                }
            </ul>
        </aside>);
}

export default SideMenu;