const FormError = (props)=>{
       if (props.apierrors) {
            return (
              <div className="error container help is-danger is_duplicate" >
                <div className="row justify-content-center">{props.apierrors}</div>
              </div>
            );
          } else if (props.formerrors && props.formerrors.errors.cognito) {
            return (
              <div className="error">
                <div className="row justify-content-center  is_duplicate">
                  {props.formerrors.errors.cognito.message}
                </div>
              </div>
            );
          } else {
            return <div />;
          }
        }

export default FormError;