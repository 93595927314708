import { getAllLoansStatus, getLoanDataAssetMgmt, getMaasLoansByFilter } from './../graphql/queries';
import { API, graphqlOperation } from "aws-amplify";

export const getLoanDetail = async (params: any, userGroupId : String) => {
    let loanDetail: any;
    try {
        loanDetail = await API.graphql(graphqlOperation(getLoanDataAssetMgmt, {loan_id : params, scope_id: userGroupId}));
        let loanDetails = JSON.parse(loanDetail.data.getLoanDataAssetMgmt);
        return loanDetails

    } catch (ex) {
        console.log(ex);
    }
}

export const getLoansByFilterAsync = async (params: any) => {
    try {
        var data: any = {
            loan_number: params.loan_id ? params.loan_id : null,
            borrower_full_name: params.borrower_full_name ? params.borrower_full_name : null,
            zip_code: params.property_postal_code ? params.property_postal_code : null,
            property_state: params.property_state ? params.property_state : null,
            property_city: params.property_city ? params.property_city : null,
            offset: params.offset ? params.offset : 0,
            page_size: params.page_size ? params.page_size : null
        };
        Object.entries(data).forEach(element => {
            if (element[1] === null) { delete data[element[0]] }
        });
        let response: any = await API.graphql(graphqlOperation(getMaasLoansByFilter, { input: data }));
        response = response && response.data && response.data.getMaasLoansByFilter ? JSON.parse(response.data.getMaasLoansByFilter) : [];
        response = JSON.parse(response[0].jsonb_agg);

        return response
    } catch (ex) {
        console.log(ex);
        return [];
    }
}

export const getAllLoans = async () => {
    let loans
    try {
        let response: any = await API.graphql(graphqlOperation(getAllLoans));
        loans = JSON.parse(response.data.getAllLoans);
        loans = JSON.parse(loans[0].fn_get_all_loans);
        loans = loans.filter(z => z.maas_loan_number && z.maas_loan_number !== null && z.maas_loan_number !== "");
        return loans;
    } catch (ex) {
        console.log(ex);
    }
}

export const getAllStatus = async (groupId: String) => {
    let statusList: any[] = [];
    try {
        let response: any = await API.graphql(graphqlOperation(getAllLoansStatus, {scope_id: groupId}));
        if (response && response.data && response.data.getAllLoansStatus) {
            statusList = JSON.parse(response.data.getAllLoansStatus);
            return statusList;
        }
    }
    catch (ex) {
        console.log(ex);
    }
}
