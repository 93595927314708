/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState, useRef } from "react";
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { deleteTagByTagId} from "../../../services/tagList.service";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import Breadcrumb from "../../common/Breadcrumb";
import { Loader } from "../../../helpers/loader";
import GenericAgGrid, { getCurrentFilterValue } from "../../common/GenericAgGrid";
import { readLoanState,setTagDetails,resetTagValues,setTagList } from "../../../redux/reducers/loanReducer";

const TagListComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const loanState = useAppSelector(readLoanState);
    const dispatch = useAppDispatch();
    const gridTagListRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'TAGS');

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "Tag Management",
            redirect_url: "/Tags",
            icon_url: "fad fa-user"
        }]));
        dispatch(resetTagValues());
    }, []);
    

    const actionButtons = (params) => {
        return <div className="grid-action">
            <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
            <button disabled={appState.currentUser?.user_id === params.data.user_id} onClick={() => {
                setPopup({
                    ...popup,
                    visible: true,
                    message: "Please confirm that you would like to delete this tag.",
                    type: ModalType.ActionDialog,
                    toggle: () => setPopup({ ...defaultPopup }),
                    primaryButtonAction: () => removeTag(params),
                    primaryButtonName: "Delete",
                    secondaryButtonName: "Cancel",
                    secondaryButtonAction: () => setPopup({ ...defaultPopup })
                });
            }} title="Delete" className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
        </div>
    }

    useEffect(() => { 
        if (gridApi) {
            setTimeout(() => {
                gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen])

    const postGridResponse = async (response) => {
        try {
            return new Promise((resolve, reject) => {
                dispatch(setTagList(response[1]));
                resolve({ isNewPrintRows: false });
            })
        } catch (err) {
            console.error(err);
        }
    }

    const handleEditClick = (params: any) => {
        preserveFilter(params);
        navigation("/TagManagement");
    }

    const columns: any[] = [
        { headerName: 'Tag Id', field: 'tag_id', hide: true },
        { headerName: 'Tag Name', field: 'tag_name', headerTooltip: 'Tag Name' },
        { headerName: 'Tag Description', field: 'tag_description', headerTooltip: 'Tag Description' },
        { headerName: 'Priority', field: 'priority',filter:'agNumberColumnFilter', headerTooltip: 'Priority' },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 110, suppressSizeToFit: true, cellRendererFramework: actionButtons
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const removeTag = async (params) => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
                const selectedData = params.data;
                let tags = loanState.tagDetails;
                dispatch(setTagDetails(tags));
                const data ={
                    tag_id:parseInt(selectedData.tag_id),
                    modified_date:new Date().toUTCString(),
                    modified_by:appState.currentUser!.maas_user_id
                }
                await deleteTagByTagId(data).then((response) => {
                    gridTagListRef.current.api.refreshServerSideStore();
                })
                setLoading(false);
                setPopup({ ...popup, visible: true, message: "This Tag  has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });                
            }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "Tag could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
    }

    const preserveFilter=(params: any)=>{
        let filterValue=getCurrentFilterValue(gridTagListRef);
        if(params==true)
        {
            dispatch(setTagDetails({ ...loanState?.tagDetails, is_new: true,is_edit:true,current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel, saved_sort:filterValue.SortState}));
        }else{
            dispatch(setTagDetails({ current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel, saved_sort:filterValue.SortState, tag_id:params.data.tag_id,tag_name:params.data.tag_name,tag_description:params.data.tag_description,priority:params.data.priority, is_new: false,is_edit:true }));
        }
    }

    const afterGridLoad=()=>{
        if((!loanState.tagDetails?.is_edit) && (loanState.tagDetails?.saved_sort || loanState.tagDetails?.saved_filter)){
            gridTagListRef.current.columnApi.applyColumnState({
                    state: loanState.tagDetails.saved_sort,
              });
              gridTagListRef.current.api.setFilterModel(loanState.tagDetails.saved_filter);
              dispatch(setTagDetails({ ...loanState?.tagDetails, is_edit:true,current_page:0}));
              
        }else{
            dispatch(setTagDetails({ ...loanState?.tagDetails, saved_sort: null,saved_filter:null,current_page:0}));
        }
    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                    <a  className="btn btn-primary" onClick={() => { preserveFilter(true); navigation("/TagManagement"); }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} /> Add Tag
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            {loading && <Loader />}
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
            {!!gridDetails && 
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            parentGridRef={gridTagListRef}
                            postGridResponse={postGridResponse}
                            sizeColumnsToFit={true}
                            afterGridLoad={afterGridLoad}
                            currentPage={loanState.tagDetails?.current_page}
                        ></GenericAgGrid>
                    }
        </section>
    </>
    )
}

export default memo(TagListComponent);