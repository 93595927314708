/* eslint-disable react-hooks/exhaustive-deps */
import Amplify from "aws-amplify";
import { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import { useAppDispatch } from "./app/hooks";
import awsExports from "./aws-exports";
import RoleFormComponent from "./components/AdminComponents/RoleComponents/RoleFormComponent";
import RoleListComponent from "./components/AdminComponents/RoleComponents/RoleListComponent";
import RuleGroupFormComponent from "./components/AdminComponents/RuleGroupComponent/RuleGroupFormComponent";
import RuleGroupListComponent from "./components/AdminComponents/RuleGroupComponent/RuleGroupListComponent";
import ScreenNavigations from "./components/AdminComponents/ScreenNavigations";
import FileUpload from "./components/AdminComponents/FileUpload";
import TagFormComponent from "./components/AdminComponents/TagsComponent/TagFormComponent";
import TagListComponent from "./components/AdminComponents/TagsComponent/TagListComponent";
import UserFormComponent from "./components/AdminComponents/UserComponents/UserFormComponent";
import UserListComponent from "./components/AdminComponents/UserComponents/UserListComponent";
import UserGroupFormComponent from "./components/AdminComponents/UserGroupComponents/UserGroupFormComponent";
import UserGroupListComponent from "./components/AdminComponents/UserGroupComponents/UserGroupListComponent";
import AssignmentClaimMaster from "./components/AssignmentClaimsComponents/AssignmentClaimMaster";
import AssignmentClaimsLoanSearch from "./components/AssignmentClaimsComponents/AssignmentClaimsLoanSearch";
import DocumentLevelSearch from "./components/AssignmentClaimsComponents/DocumentLevelSearch";
import FNMABillsView from "./components/BillComponents/Bill";
import BillView from "./components/BillComponents/BillDetail";
import OpenBillAnalysis from "./components/BillComponents/OpenBillAnalysis";
import BillSummary from "./components/BillComponents/BillSummary";
import BusinessRuleFormComponent from "./components/BusinessRulesComponent/BusinessRuleFormComponent";
import BusinessRuleMasterComponent from "./components/BusinessRulesComponent/BusinessRuleMasterComponent";
import DocumentPreviewComponent from "./components/common/DocumentPreviewComponent";
import DashboardLibrary from "./components/DashboardComponents/DashboardLibrary";
import DashboardScreenComponent from "./components/DashboardComponents/DashboardScreen";
import DashboardView from "./components/DashboardComponents/DashboardView";
import Visual from "./components/DashboardComponents/Visuals";
import VisualView from "./components/DashboardComponents/VisualView";
import LoanDetailMaster from "./components/LoanDetailComponents/LoanDetailMaster";
import LoanSearchResult from "./components/LoanDetailComponents/LoanSearchResult";
import BankruptyDetailScreen from "./components/LoanDetails/BankruptyScreen";
import LoanScreen from "./components/LoanDetails/LoanScreen";
import ChangePassword from "./components/LoginComponent/ChangePassword";
import ForgotPassword from "./components/LoginComponent/ForgotPassword";
import ForgotUserId from "./components/LoginComponent/ForgotUserId";
import { Login } from './components/LoginComponent/Login';
import MyProfileAndSettings from "./components/MyProfileAndSettingsComponents/MyProfileAndSettings";
import ProtectedRoute from "./components/ProtectedRoute";
import ConfirmRegistration from "./components/RegistrationComponent/ConfirmRegistration";
import Registration from "./components/RegistrationComponent/Registration";
import TransformerLogsComponent from "./components/TransformerLogsComponent/TransformerLogsComponent";
import TransformerLogsDetailComponent from "./components/TransformerLogsComponent/TransformerLogsDetailComponent";
import UploadTemplateComponent from "./components/UploadTemplateComponent/UploadTemplateComponent";
import { getLogiConfigurationAsync, readAppState, setSessionTimeout } from "./redux/reducers/appReducer";
import AllLoanDocuments from "./components/Documents/AllLoanDocuments";
import SessionTimeout from "./components/SessionTimeout/SessionTimeout";
import AllDashboards from "./components/AdminComponents/Inventories/AllDashboards";
import AllReports from "./components/AdminComponents/Inventories/AllReports";
import AllDocumentsGrid from "./components/Documents/AllDocumentGrid";
import { useAppSelector } from "./app/hooks";
Amplify.configure(awsExports);
const App = () => {

  const appState = useAppSelector(readAppState)
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSessionTimeout(false));
  },
 [])
  return (
    <Router>
      <Routes>
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ForgotUserId" element={<ForgotUserId />} />
        <Route path="/login" element={<Login />} />

        <Route path='/' element={<ProtectedRoute />}>
          <Route path="/BillView" element={<BillView />} />
          <Route path="/Reports" element={<Visual />} />
          <Route path="/DashboardLibrary" element={<DashboardLibrary />} />
          <Route path="/DashboardView" element={<DashboardView />} />
          <Route path="/LoanDetail" element={<LoanDetailMaster />} />
          <Route path="/LoanSearchResult" element={<LoanSearchResult />} />
          <Route path="/LoanView" element={<LoanScreen />} />
          <Route path="/ScreenNavigations" element={<ScreenNavigations />} />
          <Route path="/BankruptLoanView" element={<BankruptyDetailScreen />} />
          <Route path="/BusinessRule" element={<BusinessRuleMasterComponent />} />
          <Route path="/BusinessRuleManagement" element={<BusinessRuleFormComponent />} />
          <Route path="/FileUpload" element={<UploadTemplateComponent />} />
          <Route path="/AllDashboards" element={<AllDashboards />} />
          <Route path="/AllReports" element={<AllReports />} />
          <Route path="/Users" element={<UserListComponent />} />
          <Route path="/UserManagement" element={<UserFormComponent />} />
          <Route path="/Roles" element={<RoleListComponent />} />
          <Route path="/RoleManagement" element={<RoleFormComponent />} />
          <Route path="/UserGroups" element={<UserGroupListComponent />} />
          <Route path="/UserGroupManagement" element={<UserGroupFormComponent />} />
          <Route path="/Tags" element={<TagListComponent />} />
          <Route path="/TagManagement" element={<TagFormComponent />} />
          <Route path="/RuleGroup" element={<RuleGroupListComponent />} />
          <Route path="/RuleGroupManagement" element={<RuleGroupFormComponent />} />
          <Route path="/UploadFile" element={<FileUpload />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/confirmRegistration" element={<ConfirmRegistration />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/MyProfileAndSettings" element={<MyProfileAndSettings />} />
          <Route path="/DataLoadStatistics" element={<DashboardScreenComponent key="/DataLoadStatistics" rootPath="/DataLoadStatistics" dashboardkey="transformer_logs_dashboard_id" dashboardName="transformer_log_dashboard" gridAbbreviation='transformer_logs_grid' />} />
          <Route path="/DataLoadStatistics/FileLogs" element={<TransformerLogsComponent />} />
          <Route path="/DataLoadStatistics/RuleOutput" element={<TransformerLogsDetailComponent />} />
          <Route path="/LoanAdminAndAdvances" element={<DashboardScreenComponent key="/LoanAdminAndAdvances" rootPath="/LoanAdminAndAdvances" dashboardkey="loan_admin_dashboard_id" dashboardName="loan_admin" />} />
          <Route path="/VisualView" element={<VisualView />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/Surveillance" element={<DashboardScreenComponent key="/Surveillance" rootPath="/Surveillance" dashboardkey="surveillance_dashboard_id" dashboardName="surveillance" gridAbbreviation="surveillance_grid_" />}>
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/AssetManagement" element={<DashboardScreenComponent key="/AssetManagement" rootPath="/AssetManagement" dashboardkey="asset_management_dashboard_id" dashboardName="asset_management_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/LossMitigation" element={<DashboardScreenComponent key="/LossMitigation" rootPath="/LossMitigation" dashboardkey="loss_mitigation_dashboard_id" dashboardName="loss_mitigation_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/CollateralManagement" element={<DashboardScreenComponent key="/CollateralManagement" rootPath="/CollateralManagement" dashboardkey="collateral_dashboard_id" dashboardName="collateral_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/Reo" element={<DashboardScreenComponent key="/Reo" rootPath="/Reo" dashboardkey="reo_dashboard_id" dashboardName="reo_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/ReoDashboard" element={<DashboardScreenComponent key="/ReoDashboard" rootPath="/ReoDashboard" dashboardkey="reo_dashboard_id" dashboardName="reo_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/AssignmentClaims" element={<AssignmentClaimMaster />} />
          <Route path="/AssignmentClaimsImpediments" element={<AssignmentClaimMaster />} />
          <Route path="/DefaultDashboard" element={<DashboardScreenComponent key="/DefaultDashboard" rootPath="/DefaultDashboard" dashboardkey={"default_dashboard_id"} dashboardName="default_dashboard" gridAbbreviation="df_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/BankruptcyDashboard" element={<DashboardScreenComponent key="/BankruptcyDashboard" rootPath="/BankruptcyDashboard" dashboardkey="bankruptcy_overview_dashboard_id" dashboardName="bankruptcy_dashboard" gridAbbreviation="bk_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/HUDRepurchases" element={<DashboardScreenComponent key="/HUDRepurchases" rootPath="/HUDRepurchases" dashboardkey="hud_repurchases_dashboard_id" dashboardName="hud_repurchases_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/CallCenterData" element={<DashboardScreenComponent key="/CallCenterData" rootPath="/CallCenterData" dashboardkey="call_center_dashboard_id" dashboardName="call_center_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/FNMABilling" element={<DashboardScreenComponent key="/FNMABilling" rootPath="/FNMABilling" dashboardkey="fnma_billing_dashboard_id" dashboardName="fnma_billing_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/FNMABillsView" element={<FNMABillsView />} />
          <Route path="/BillSummary" element={<DashboardScreenComponent key="/BillSummary" rootPath="/BillSummary" dashboardkey="fnma_billing_summary_dashboard_id" dashboardName="fnma_billing_summary_dashboard" gridAbbreviation="fnma_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/OpenBillAnalysis" element={<DashboardScreenComponent key="/OpenBillAnalysis" rootPath="/OpenBillAnalysis" dashboardkey="fnma_open_bill_dashboard_id" dashboardName="open_bill_analysis_dashboard" gridAbbreviation="fnma_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>

          <Route path="/ReoPropertyPreservation" element={<DashboardScreenComponent key="/ReoPropertyPreservation" rootPath="/ReoPropertyPreservation" dashboardkey="reo_property_preservation_dashboard_id" dashboardName="reo_property_preservation_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/ForeclosureDashboard" element={<DashboardScreenComponent key="/ForeclosureDashboard" rootPath="/ForeclosureDashboard" dashboardkey="foreclosure_dashboard_id" dashboardName="foreclosure_dashboard" gridAbbreviation="fc_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/DefaultClaimDashboard" element={<DashboardScreenComponent key="/DefaultClaimDashboard" rootPath="/DefaultClaimDashboard" dashboardkey="default_claim_dashboard_id" dashboardName="default_claim_dashboard" gridAbbreviation="dfc_" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/AssignmentClaimsLoanSearch" element={<AssignmentClaimsLoanSearch key="/AssignmentClaimsLoanSearch" rootPath="/AssignmentClaimsLoanSearch" dashboardkey="assignment_claims_loan_search_dashboard_id" dashboardName="assignment_claims_loan_search_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/DocumentLevelSearch" element={<DocumentLevelSearch key="/DocumentLevelSearch" rootPath="/DocumentLevelSearch" dashboardkey="document_level_search_dashboard_id" dashboardName="document_level_search_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/ServicerMatricesLevel1" element={<DashboardScreenComponent key="/ServicerMatricesLevel1" rootPath="/ServicerMatricesLevel1" dashboardkey="servicer_matrices_level_1_id" dashboardName="servicer_matrices_level_1_dashboard" />} >
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/LossShare" element={<DashboardScreenComponent key="/LossShare" rootPath="/LossShare" dashboardkey="loss_share_dashboard_id" dashboardName="loss_share_dashboard" />} />
          <Route path="/LoanAdministration" element={<DashboardScreenComponent key="/LoanAdministration" rootPath="/LoanAdministration" dashboardkey="loan_administration_dashboard_id" dashboardName="loan_administration_dashboard" />} />

          <Route path="/LoanAdvances" element={<DashboardScreenComponent key="/LoanAdvances" rootPath="/LoanAdvances"
            dashboardkey="loan_advances_dashboard_id" dashboardName="loan_advances_dashboard" />} />
          <Route path="/Documents" element={<AllLoanDocuments key="/Documents" rootPath="/Documents" dashboardkey="documents_dashboard" dashboardName="documents" gridAbbreviation="documents_dashboard" />}>
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
          </Route>
          <Route path="/AllDocuments" element={<AllDocumentsGrid key="/AllDocuments" rootPath="/AllDocuments" dashboardkey="all_documents_dashboard" dashboardName="all_documents" gridAbbreviation="all_documents_dashboard" />}>
            <Route path="LoanDetail" element={<LoanDetailMaster nestedComponent={true} />} />
            <Route path="DocumentPreviewComponent" element={<DocumentPreviewComponent nestedComponent={true} />} />
          </Route>

          <Route path="/" element={<ProtectedRoute />} />  {/**IMP :: Do not add any route below this **/}
        </Route>
      </Routes>
      <SessionTimeout />
    </Router >
  );
}

export default App;