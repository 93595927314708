/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import exportFromJSON from 'export-from-json';
import { memo, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import formatDateTable from "../../constants/dateFormatTable";
import { Loader } from "../../helpers/loader";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { readTransformerLogsState, setFileInformation } from "../../redux/reducers/transformerLogsReducer";
import { executeApprove } from "../../services/transformerLog.service";
import { getServicersList } from "../../services/uploadTemplate.service";
import Breadcrumb from "../common/Breadcrumb";
import CustomPopupComponent, { ModalType } from "../common/CustomPopupComponent";
import GenericAgGrid from "../common/GenericAgGrid";

const TransformerLogsDetailComponent = () => {
    const dispatch = useAppDispatch();
    const transformerLogsState: any = useAppSelector(readTransformerLogsState);
    const appState = useAppSelector(readAppState);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [loader, setLoader] = useState(false);
    const [rows, setRows] = useState<any>();
    const [selectedServicer, setSelectedServicer] = useState("");
    const location = useLocation();
    const navigation = useNavigate();
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const gridTransformerLogDetailRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'FAILED_RULE_OUTPUT');

    useEffect(() => {
        const path = location.pathname.split('/');
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        let updatedBreadcrumb: any[] = [];
        if (path.length >= 2 && breadcrumbLocation.length < 2) {
            path.forEach((x, i) => {
                if (x === "LogDetails") {
                    updatedBreadcrumb.push({
                        "screen_id": "",
                        "screen_name": "File Logs",
                        "redirect_url": "/DataLoadStatistics/FileLogs",
                        "icon_url": "fas fa-people-arrows"
                    });
                    updatedBreadcrumb.push({
                        screen_name: "Rule Output",
                        redirect_url: "/RuleOutput",
                        icon_url: ""
                    })
                }
                else {
                    const screen = screens.find(y => y.redirect_url === `/${x}`);
                    if (i > 0 && screen) {
                        updatedBreadcrumb.push(screen);
                    }
                }
            })
            breadcrumbLocation = [...updatedBreadcrumb];
        }
        else {
            const currentBreadcrumbIndex = appState.breadcrumbLocation.findIndex(x => x.screen_name === "Rule Output");
            if (currentBreadcrumbIndex !== -1) {
                breadcrumbLocation = appState.breadcrumbLocation.filter((x, i) => i <= currentBreadcrumbIndex);
            }
            else {
                breadcrumbLocation.push({
                    screen_name: "Rule Output",
                    redirect_url: "/RuleOutput",
                    icon_url: ""
                })
            }
        }
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        getServicerList();
    }, []);

    const handleApprove = async () => {
        setLoader(true)
        await executeApprove(transformerLogsState.file_information.transformer_log_id, "ruleApproval", "", "", 0).then(data => {
            // if (!data || data.length === 0) {
            //     setPopup({ ...popup, visible: true, message: "Something went wrong!", variant: "danger", type: ModalType.Snackbar });
            // } Temp Fix-----Need to check if approval lambda return something accordingly condition will change.
            if (data === null) {
                setPopup({ ...popup, visible: true, message: "File has been approved!", variant: "success", type: ModalType.Snackbar });
                dispatch(setFileInformation({ ...transformerLogsState.file_information, approvalDisable: true }));
            }
            else {
                setPopup({ ...popup, visible: true, message: "Something went wrong!", variant: "danger", type: ModalType.Snackbar });
            }
            setLoader(false);
        })
    }

    const getCustomStaticFilter = () => {
        let customFilter = 'process_id = ' + transformerLogsState.file_information.process_id;
        return customFilter;
    }

    const postGridResponse = async (response) => {
        try {
            return new Promise((resolve, reject) => {
                setRows(response[1]);
                resolve({ isNewPrintRows: false });
            })
        } catch (err) {
            console.error(err);
        }
    }

    const columns = [
        { headerName: 'Rule View', field: 'rule_view', headerTooltip: 'Rule View' },
        { headerName: 'Rule Key Column', field: 'rule_key_column', width: 200, headerTooltip: 'Rule Key Column' },
        { headerName: 'Rule Criteria', field: 'rule_criteria', headerTooltip: 'Rule Criteria' },
        { headerName: 'Rule Row Message', field: 'rule_row_message', headerTooltip: 'Rule Row Message' },
        { headerName: 'Rule Message', field: 'rule_message', headerTooltip: 'Rule Message' },
        { headerName: 'Rule Description', field: 'rule_description', headerTooltip: 'Rule Description' },
        { headerName: 'Created Date', field: 'inserted_date', headerTooltip: 'Created Date', filter: 'agDateColumnFilter', valueFormatter: (param) => formatDateTable(param, true) }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    const getServicerList = async () => {
        await getServicersList().then(data => {
            if (data) {
                const servicerArray = data.map(el => {
                    return {
                        label: el.servicer_name,
                        value: el.servicer_bucket
                    }
                });
                if (transformerLogsState.file_information && servicerArray.length > 0) {
                    const defaultServicer = servicerArray.find(item => item.label === transformerLogsState.file_information?.servicer_name)?.value;
                    setSelectedServicer(defaultServicer);
                }
            }
        })
    }

    const exportData = () => {
        const data = rows
        const fileName = 'ExecutedRules'
        const exportType = 'csv'
        exportFromJSON({ data, fileName, exportType })
    }

    return (
        <>
            <div className="main_wrapper" >
                <div className="pagetitle"><Breadcrumb /></div>
                <div className="pagetitle">
                    <div className="row align-items-center">
                        <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                            <h6 className="mb-0"><b>{transformerLogsState.file_information.file_name}</b></h6>
                        </div>
                        {rows && rows.length > 0 &&
                            <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                                {transformerLogsState.file_information && (transformerLogsState.file_information.is_approve_visible || transformerLogsState.file_information.is_hard_check_failed) && <a className="btn  btn-primary ml-10" onClick={() => exportData()}><span className="fa fa-file-export " />Export Executed Rules</a>}
                                {transformerLogsState.file_information && transformerLogsState.file_information.is_approve_visible && <a className={`btn  btn-primary ml-10 ${transformerLogsState.file_information.approvalDisable ? "disabled" : ""}`} onClick={() => { handleApprove() }} >
                                    <span className="fas fa-check-circle" />Approve</a>}
                            </div>
                        }
                    </div>
                </div>
                {loader && <Loader />}
                {popup.visible && <CustomPopupComponent toggle={() => { setPopup(defaultPopup); popup.variant === "success" && navigation('/DataLoadStatistics/FileLogs'); }} isOpen={popup.visible} message={popup.message} type={popup.type || ModalType.ActionDialog} variant={popup.variant} />}
                <section className="section dashboard">
                    {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridTransformerLogDetailRef}
                            postGridResponse={postGridResponse}
                        ></GenericAgGrid>
                    </div>}

                </section>
            </div>
        </>
    )
}

export default memo(TransformerLogsDetailComponent);