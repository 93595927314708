
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'react-querybuilder/dist/query-builder.css'
import { memo, useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { amountColumns, condition, LogicalOperations, numericColumns, operations, percentageColumns, skeyColumns } from "../../constants/BusinessRuleConstants";
import { executeRuleQuery, getInputOutputTemplateFields, getInputOutputTemplates, insertBusinessRule, updateBusinessRuleData } from "../../services/businessRule.service";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { Loader } from "../../helpers/loader";
import { readRuleState, resetSelectedRuleValues, setSelectedRule } from "../../redux/reducers/ruleReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatQuery, parseSQL, RuleGroupType } from 'react-querybuilder';
import Breadcrumb from "../common/Breadcrumb";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { useNavigate } from "react-router-dom";
import BasicQueryBuilderComponent from "./BasicQueryBuilderComponent";
import DataGrid from './DataGrid';
import AceRuleEditor from './AceRuleEditor';
import AdvanceSQLSidebar from './AdvanceSQLSidebar';
import { selectCustomStyles } from '../common/CustomStyles';
import { queryByAltText } from '@testing-library/react';

const BusinessRuleFormComponent = () => {
    const [rowData, setRowData] = useState<any>(undefined);
    const [columns, setColumns] = useState<any>(undefined);
    const [inputOutputTemplateDetails, setInputOutputTemplateDetails] = useState<any>(undefined);
    const [fieldList, setFieldList] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: () => onConfirmation(), secondaryButtonAction: () => setPopup({ ...defaultPopup }) };
    const [popup, setPopup] = useState(defaultPopup);
    const [inputOutputTemplateList, setInputOutputTemplateList] = useState<any>();
    const [customSuggestions, setCustomSuggestions] = useState<any>();
    const [ruleView, setRuleView] = useState<any>();
    const navigation = useNavigate();
    const initialQuery: RuleGroupType = {
        combinator: 'and',
        rules: [],
    };
    const [preview, setPreview] = useState(false);
    const ruleState = useAppSelector(readRuleState);
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const selectQuery = useRef("");
    const [columnWithDataType, setColumnWithData] = useState([] as any);
    const [wordEntered, setWordEntered] = useState<any>('');
    const handleWord = data => {
        setWordEntered(data.target.value);
    }
    const getQuery = () => {
        try {
            if (ruleState && ruleState.rule && ruleState.rule.rule_criteria) {
                return parseSQL(ruleState.rule.rule_criteria || "")
            }
            else {
                return initialQuery;
            }
        }
        catch (e) {
            return initialQuery;
        }

    }
    const [query, setQuery] = useState(getQuery()); const [advanceSql, setAdvanceSql] = useState(ruleState.rule?.is_advance_sql || false);
    const [ruleKeyColumn, setRuleKeyColumn] = useState<any>(null);
    const [ruleKeyColumnList, setRuleKeyColumnList] = useState<any>([]);
    const [ruleRowMessageSuggestions, setRuleRowMessageSuggestions] = useState<any>([]);

    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/BusinessRule")
        );

        ruleState.rule?.is_new ?
            breadcrumbLocation.push({
                "screen_id": "",
                "screen_name": "Add Business Rule",
                "redirect_url": "/BusinessRuleManagement",
                "icon_url": "fas fa-people-arrows"
            }) : breadcrumbLocation.push({
                "screen_id": "",
                "screen_name": "Edit Business Rule",
                "redirect_url": "/BusinessRuleManagement",
                "icon_url": "fas fa-people-arrows"
            });
        dispatch(setBreadcrumbLocation(breadcrumbLocation));

        if (ruleState.rule && ruleState.rule.rule_view) {
            listInputOutputTemplateFields(ruleState.rule.rule_view);
        }

        const listInputOutputTemplate = async () => {
            await getInputOutputTemplates().then(data => {
                if (data) {
                    setInputOutputTemplateDetails(data)
                    const output_template_fields = data.map((el) => {
                        return {
                            value: el.rule_view,
                            label: el.template_filename_format
                        }
                    })
                    setInputOutputTemplateList(output_template_fields)
                }
            })
        }
        listInputOutputTemplate();
    }, [])


    const listInputOutputTemplateFields = async (rule_view) => {
        await getInputOutputTemplateFields(rule_view).then(data => {
            if (data) {
                let field_list: any[] = [];
                let column_list: any[] = [];
                let ruleRowMessageFields: any[] = [];

                data.filter(i => i.input_template_field !== null).forEach(el => {
                    field_list.push(el.input_template_field)
                    ruleRowMessageFields.push(`{{${el.input_template_field}}}`);
                    column_list.push({
                        label: el.input_template_field,
                        value: el.field_data_type === 'character varying' ? `lower(${el.input_template_field})` : el.input_template_field
                    });
                }
                )
                setColumnWithData(data);
                setRuleKeyColumnList(column_list);
                setFieldList(field_list);
                setRuleKeyColumn(ruleState && ruleState.rule && ruleState.rule.rule_key_column ? column_list.find(x => x.value.replace(/lower\((.*?)\)/, '$1') === ruleState.rule!.rule_key_column.replace(/lower\((.*?)\)/, '$1')) : null);
                setCustomSuggestions([...field_list, ...LogicalOperations, ...condition, ...operations])
                setRuleRowMessageSuggestions([...ruleRowMessageFields]);
            }
        })
    }
    const handleExport = () => {
        if (selectQuery) {
            // dispatch(getRuleDataCsv(selectQuery));
        }
    }

    const executeQuery = async (rule_view) => {
        if (!isValid()) {
            return;
        };
        let sql
        setPreview(true);
        setLoader(true);
        if (ruleState && ruleState.rule?.rule_view) {
            const inputOutputTemplateData = inputOutputTemplateDetails.find(el => el.rule_view === rule_view)
            if (advanceSql) {
                if (inputOutputTemplateData) {
                    if (ruleState.rule && ruleState.rule.rule_criteria && ruleState.rule.rule_criteria.toLowerCase().includes('case')) {
                        const fieldExist = fieldList.filter(field => ruleState.rule && ruleState.rule.rule_criteria.toLowerCase().replaceAll(" ", "").includes('when' + field));
                        if (fieldExist) {
                            sql = ruleState.rule?.rule_criteria ? `SELECT * FROM ${inputOutputTemplateData.rule_view} WHERE  ${fieldExist}=${ruleState.rule?.rule_criteria}`
                                : `SELECT * FROM ${inputOutputTemplateData.rule_view}`
                        }
                    }
                    else {
                        sql = ruleState.rule?.rule_criteria ? `SELECT  * FROM ${inputOutputTemplateData.rule_view} where ${ruleState.rule?.rule_criteria}`
                            : `SELECT  * FROM ${inputOutputTemplateData.rule_view}`
                    }
                }
            }
            else {
                let updatedQuery = updateQueryByDataType(formatQuery(query, "sql"))
                sql = ruleState.rule?.rule_criteria ? `SELECT  * FROM ${inputOutputTemplateData.rule_view} where ${updatedQuery}`
                    : `SELECT  * FROM ${inputOutputTemplateData.rule_view}`
            }
            sql = sql.replaceAll(`"`, `'`);
            if (!sql.toLowerCase().includes(' limit ')) {
                sql = sql + '  LIMIT 60';
            }
            selectQuery.current = sql;
            await executeRuleQuery(sql).then(data => {
                if (data && data.length > 0 && data[0] !== null) {
                    data.forEach(val => {
                        for (let key in val) {
                            if (skeyColumns.indexOf(key) == -1) {
                                if (!Number.isNaN(+val[key]) && !Number.isNaN(parseFloat(val[key]))) {
                                    if (percentageColumns.indexOf(key) != -1) {
                                        //console.log(key)
                                        val[key] = parseFloat(val[key]).toFixed(2) + "%";
                                    } else if (numericColumns.indexOf(key) != -1) {
                                        val[key] = parseFloat(val[key]);
                                    }
                                    else if (amountColumns.indexOf(key) != -1) {
                                        val[key] = "$" + parseFloat(val[key]).toFixed(2);
                                    }
                                    else {
                                        val[key] = parseFloat(val[key]);
                                    }
                                }
                            }
                        }
                    });
                    setRowData(data);
                    setLoader(false)
                    setPopup(defaultPopup)
                    const columnName = Object.keys(data[0])
                    const colData = columnName.map(cols => {
                        var splitedColumn = cols.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                        return {
                            headerName: splitedColumn,
                            field: cols,
                            width: 150,
                            tooltip: splitedColumn,
                            headerTooltip: splitedColumn,
                            type: (amountColumns.indexOf(cols) >= 0 || percentageColumns.indexOf(cols) >= 0) ? 'rightAligned' : ''
                        }
                    })
                    setColumns(colData);
                }
                else {
                    setRowData(undefined)
                    setLoader(false)
                    setPopup({ ...popup, visible: true, message: "No row data found for executed rule!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                }
            })
                .catch((ex) => {
                    console.log(ex);
                    setLoader(false);
                })
        }
        else {
            setPopup({ ...popup, visible: true, message: "Please fill required field!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
        setLoader(false);
    }

    const onSaveClick = async () => {
        if (!ruleKeyColumn) {
            setLoader(false);
            return;
        }
        setLoader(true)
        if (ruleState.rule?.is_new) {
            const data = {
                rule_view: ruleState.rule?.rule_view,
                rule_message: ruleState.rule?.rule_message,
                rule_key_column: ruleState.rule?.rule_key_column,
                rule_row_message: ruleState.rule?.rule_row_message,
                rule_criteria: ruleState.rule?.rule_criteria ? ruleState.rule?.rule_criteria.replaceAll(`'`, `''`).replaceAll(`"`, `''`) : "",
                rule_description: ruleState.rule?.rule_description,
                is_advance_sql: advanceSql,
                basic_sql_query: JSON.stringify({ data: query }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                inserted_date: new Date().toUTCString(),
                updated_date: new Date().toUTCString()
            }
            const responseData = await insertBusinessRule(data)
            if (responseData && responseData.rule_id) {
                setPopup({ ...popup, visible: true, message: "This rule has been added!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); dispatch(setSelectedRule({ is_edit: true })); navigation("/BusinessRule"); } });
            }
            else {
                setPopup({ ...popup, visible: true, message: "Something went wrong!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                setLoader(false);
            }
        }
        else {
            const data = {
                rule_view: ruleState.rule?.rule_view,
                rule_message: ruleState.rule?.rule_message,
                rule_key_column: ruleState.rule?.rule_key_column,
                rule_row_message: ruleState.rule?.rule_row_message,
                rule_criteria: ruleState.rule?.rule_criteria ? ruleState.rule?.rule_criteria.replaceAll(`'`, `''`).replaceAll(`"`, `''`) : "",
                rule_description: ruleState.rule?.rule_description,
                rule_id: ruleState.rule?.rule_id,
                input_output_mapping_id: ruleState.rule?.input_output_mapping_id,
                is_advance_sql: advanceSql,
                basic_sql_query: query ? JSON.stringify({ data: query }).replaceAll(/"/g, '\\"').replaceAll("'", "''") : '',
                updated_date: new Date().toUTCString()
            }
            await updateBusinessRuleData(data).then(data => {
                if (data) {
                    setPopup({ ...popup, visible: true, message: "Data Rule updated successfully!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); dispatch(setSelectedRule({ is_edit: true })); navigation("/BusinessRule"); } });
                }
                else {
                    setPopup({ ...popup, visible: true, message: "Something went wrong!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
                    setLoader(false);
                }
            })
                .catch((ex) => {
                    console.log(ex);
                    setLoader(false);
                })
        }
    }

    const changeHandle = async (value, field) => {
        let updatedRule: any = { ...ruleState.rule };
        updatedRule[field] = value;
        if (field === "rule_view") {
            await listInputOutputTemplateFields(value);
            const ruleData = inputOutputTemplateDetails.filter(el => el.rule_view === value)
            if (ruleData.length > 0) {
                dispatch(setSelectedRule({ ...updatedRule, rule_view: ruleData[0].rule_view, rule_key_column: ruleData[0].rule_key_column }))
                setRuleView(ruleData[0].rule_view);
            }
            else {
                dispatch(resetSelectedRuleValues());
                setWordEntered('');
                setRowData(undefined);
                setColumns(undefined);
                setFieldList([]);
                setQuery(initialQuery);
                setRuleKeyColumnList([]);
            }
            setRuleKeyColumn(null);
            setWordEntered('');
        }
        else {
            dispatch(setSelectedRule({ ...updatedRule }))
        }
    }

    const updateQueryByDataType = (query: any) => {
        if (query) {
            query = query.split(" ")
            query = query.map(x => {
                let temp = x.split("(");
                let compareValue = temp.length > 1 ? temp[1] : x
                if (columnWithDataType.find(y => y.input_template_field === compareValue && y.field_data_type === 'character varying')) {
                    x = temp.length > 1 ? `(lower(${compareValue})` : `lower(${compareValue})`;
                }
                return x;
            })
            return query.join(" ");
        }
    }

    const updateUserInput = (query: any) => {
        if (query && query.rules) {
            query = {
                ...query, rules: query.rules.map(x => {
                    if (x.field && columnWithDataType.find(y => y.input_template_field === x.field && y.field_data_type === 'character varying')) {
                        x = { ...x, value: x.value.toLowerCase() }
                    }
                    else if (x.rules) {
                        x = updateUserInput(x);
                    }
                    return x;
                })
            }
        }
        return query;
    }

    const handleQueryChange = (query: any) => {
        query = updateUserInput(query);
        let updatedRule: any = { ...ruleState.rule };
        setQuery(query);
        updatedRule['rule_criteria'] = formatQuery(query, 'sql') === "(1 = 1)" ? "" : formatQuery(query, 'sql');
        dispatch(setSelectedRule({ ...updatedRule }))
    }

    const onConfirmation = () => {
        setPopup(defaultPopup);
        navigation("/BusinessRule");
    }

    const isValid = () => {
        let message = !ruleState.rule?.rule_message ? "Please Enter Rule Message" :
            !ruleState.rule?.rule_row_message ? "Please Enter Rule Row Message" :
                !ruleState.rule?.rule_description ? "Please Enter Rule Description" :
                    !ruleState.rule?.rule_key_column ? "Please Select Rule Key Column" :
                        !ruleState.rule?.rule_view ? "Please Select Rule View" : "";

        if (message) {
            setPopup({ ...popup, visible: true, type: ModalType.Snackbar, toggle: () => { setPopup(defaultPopup) }, variant: "danger", message: message });
            setLoader(false);
            return false;
        }
        return true;
    }

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                Please select Rule View first
            </components.NoOptionsMessage>
        );
    };

    return <>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-12 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                {preview &&
                    <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                        <a className="btn btn-primary" style={{ marginLeft: "5px" }} onClick={() => { onSaveClick() }} >
                            <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-floppy-disk`} /> Save Rule
                        </a>
                        <a className="btn btn-primary" onClick={() => { setPreview(false) }} >
                            <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-angle-left`} /> Edit Rule
                        </a>
                    </div>
                }
            </div>
        </div>
        {loader && <Loader />}
        {popup.visible && <CustomPopupComponent toggle={popup.toggle} isOpen={popup.visible} primaryButtonAction={popup.primaryButtonAction} secondaryButtonAction={popup.secondaryButtonAction} message={popup.message} type={popup.type || ModalType.ActionDialog} variant={popup.variant} />}
        <section className="section dashboard">
            {!preview && <>
                <div className="row">
                    <div className={!advanceSql ? `col-xl-12 col-lg-12 col-md-12` : `col-xl-8 col-lg-8 col-md-8`}>
                        <div className="block_sect mb-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-floating multi-select-field ct_form_field select_dd">
                                        <Select
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={selectCustomStyles}
                                            placeholder="Select Rule View"
                                            className="Select-option form-control multi-select-dd"
                                            options={inputOutputTemplateList}
                                            value={inputOutputTemplateList?.filter(x => x.value === (ruleState.rule && ruleState.rule.rule_view ? ruleState.rule.rule_view : ""))}
                                            onChange={(e) => { changeHandle(e?.value, "rule_view"); }} />
                                        <label>Rule View</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating multi-select-field ct_form_field select_dd">
                                        <Select
                                            id="ruleKeyColumn"
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={selectCustomStyles}
                                            className="Select-option form-control multi-select-dd"
                                            options={ruleKeyColumnList}
                                            value={ruleKeyColumn}
                                            placeholder="Select Rule Key Column"
                                            components={{ NoOptionsMessage }}
                                            onChange={(e) => {
                                                dispatch(setSelectedRule({ ...ruleState.rule, rule_key_column: e?.value || "" }));
                                                setRuleKeyColumn(e);
                                            }} />
                                        <label>Rule Key Column</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating ct_form_field">
                                        <input type="text" className="form-control"
                                            id="ruleMessage"
                                            placeholder="Enter New Rule Name"
                                            value={ruleState.rule?.rule_message}
                                            onChange={(e) => changeHandle(e.target.value, "rule_message")}
                                        />
                                        <label htmlFor="ruleMessage">Rule Message</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating ct_form_field">
                                        <input type="text" className="form-control"
                                            id="ruleDescription"
                                            placeholder="Enter New Rule"
                                            value={ruleState.rule?.rule_description}
                                            onChange={(e) => changeHandle(e.target.value, "rule_description")}
                                        />
                                        <label htmlFor="ruleDescription">Rule Description</label>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <AceRuleEditor field='rule_row_message' label="Rule Row Message" id="ruleRowMessage" value={ruleState.rule?.rule_row_message} ruleState={ruleState} customSuggestions={ruleRowMessageSuggestions} />
                                </div>
                            </div>
                        </div>

                        <div className="block_sect">
                            <div className="row">
                                <div className="col-lg-6 col">
                                    <a className={`btn btn-lg btn-outline-secondary w-100 ${!advanceSql ? "active" : ""}`}
                                        onClick={() => setAdvanceSql(false)}>
                                        <span className="fa fa-send"></span> Basic</a>
                                </div>
                                <div className="col-lg-6 col">
                                    <a className={`btn btn-lg btn-outline-secondary w-100 ${advanceSql ? "active" : ""}`}
                                        onClick={() => setAdvanceSql(true)}>
                                        <span className="fa fa-code"></span> Advance SQL</a>
                                </div>
                                {!advanceSql &&
                                    <div className="mt-3 col-lg-12 col-md-12 col-sm-12">
                                        <BasicQueryBuilderComponent
                                            fieldList={fieldList}
                                            query={query}
                                            ruleState={ruleState}
                                            handleQueryChange={(query: any) => handleQueryChange(query)}
                                        />

                                        <div className="mb-3 custm_field mt20">
                                            <label htmlFor='rule_criteria'>Rule Criteria</label>
                                            <textarea id="rule_criteria" className="form-control" disabled={true} value={formatQuery(query, 'sql') === "(1 = 1)" ? undefined : formatQuery(query, 'sql')} />
                                        </div>
                                    </div>
                                }
                                {advanceSql &&
                                    <div className="mt-3 col-lg-12 col-md-12 col-sm-12">
                                        <AceRuleEditor field='rule_criteria' id="ruleCriteria" value={ruleState.rule?.rule_criteria} label="Rule Criteria" ruleState={ruleState} customSuggestions={customSuggestions} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {advanceSql &&
                        <AdvanceSQLSidebar ruleState={ruleState} fieldList={fieldList} setWordEntered={setWordEntered} wordEntered={wordEntered} />
                    }
                    {!preview && <div className={!advanceSql ? `col-xl-12 col-lg-12 col-md-12` : `col-xl-8 col-lg-8 col-md-8`}>
                        <div className='row justify-content-center'>
                            <div className=" col col-lg-2 col-md-4">
                                <a className="btn btn-primary btn-lg w-100" onClick={() => { executeQuery(ruleState.rule?.rule_view); }}>Preview </a>
                            </div>
                            <div className="col col-lg-2 col-md-4 ">
                                <a className="btn btn-outline-primary btn-lg w-100" onClick={() => { dispatch(setSelectedRule({ ...ruleState.rule, is_edit: false })); navigation("/BusinessRule"); }}>Cancel </a>
                            </div>
                        </div>
                    </div>}
                </div>
            </>}
            {preview &&
                <>
                    <DataGrid ruleState={ruleState} executeQuery={executeQuery}
                        rowData={rowData} columns={columns} />
                </>
            }
        </section>
    </>
}
export default memo(BusinessRuleFormComponent);