import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from "react-router";
import CustomPopupComponent, { ModalType, VariantType } from '../common/CustomPopupComponent';
import PasswordValidityViewer from '../common/PasswordValidityViewer';
import { Loader } from '../../helpers/loader';
import Breadcrumb from '../common/Breadcrumb';
import { setBreadcrumbLocation } from '../../redux/reducers/appReducer';
import { useAppDispatch } from '../../app/hooks';


const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [inputType, changeInputType] = useState({ currentPassword: false, updatedPassword: false });
    const [loader, setLoader] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPopover, setPopover] = useState(false);
    const navigation = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            "screen_id": "",
            "screen_name": "Change Password",
            "redirect_url": "/ChangePassword",
            "icon_url": "fas fa-address-book"
        }]));
    }, []);
    const handleSubmit = async (event: any) => {
        setLoader(true);
        // AWS Cognito integration here
        try {

            const user = await Auth.currentAuthenticatedUser();
            let res: any = await Auth.changePassword(
                user,
                oldPassword,
                newPassword,
            );
            setPopup({ ...popup, visible: true, message: "Your password has been changed successfully!", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }) } });
            setTimeout(() => { navigation('/DashboardLibrary'); }, 3000);
            setLoader(false);


        } catch (error: any) {
            setLoader(false);
            let err = error.code === "NotAuthorizedException" ? "Incorrect Password! the password you entered is incorrect. Please try again." : error.message;
            setPopup({ ...popup, visible: true, message: err, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
            console.log(error);
        }
    };

    function ValidatePassword(password) {
        let isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password) ? true : false;
        return isValid
    }

    const isDisabled = () => {
        let disabled = !oldPassword || !newPassword || newPassword !== confirmPassword || newPassword === oldPassword;
        if (!ValidatePassword(newPassword) || !ValidatePassword(confirmPassword)) {
            disabled = true;
        }
        return disabled;
    }

    return (
        <>
            <div className="pagetitle"><Breadcrumb /></div>
            <section className="section dashboard">
                {popup.visible && <CustomPopupComponent isOpen={popup.visible} toggle={popup.toggle} message={popup.message} type={ModalType.Snackbar} variant={popup.variant} />}
                {loader && <Loader />}
                <div className="block_sect">
                <div className="col-lg-6">
                    <div className="form-floating ct_form_field ct_form_icon_group">
                        <input
                            className="form-control"
                            type={inputType.currentPassword ? 'text' : 'password'}
                            id="oldpassword"
                            placeholder="Enter Current password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <label>{'Current Password'}</label>
                        <span onClick={e => changeInputType({ currentPassword: !inputType.currentPassword, updatedPassword: inputType.updatedPassword })} className={`far ${inputType.currentPassword ? "fa-key" : "fa-lock"}`}></span>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating ct_form_field ct_form_icon_group " >
                            <input
                                className="form-control"
                                type={inputType.updatedPassword ? 'text' : 'password'}
                                id="checkNewPassword"
                                placeholder={'Enter New Password'}
                                autoComplete="new-password"
                                value={newPassword}
                                onFocus={() => setPopover(true)}
                                onBlur={() => setPopover(false)}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <label htmlFor="checkNewPassword">{'Enter New Password'}</label>
                            <span onClick={e => changeInputType({ currentPassword: inputType.currentPassword, updatedPassword: !inputType.updatedPassword })} className={`far ${inputType.updatedPassword ? "fa-key" : "fa-lock"}`}></span>
                            {!showPopover && newPassword !== '' && oldPassword !== '' && newPassword === oldPassword && <span className="error-text">The new password entered is same as old password, please try resetting the password again.</span>}
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="form-floating ct_form_field ct_form_icon_group" >
                            <input
                                className="form-control"
                                type="password"
                                id="check_confirm_password"
                                placeholder={'Re-Enter New Password'}
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <label htmlFor="check_confirm_password">{'Re-Enter New Password'}</label>
                            {!showPopover && confirmPassword !== '' && newPassword !== oldPassword && newPassword !== confirmPassword && <span className="error-text">Passwords do not match. Please try again.</span>}
                        </div>
                    </div>
                    </div>
                    <br />
                    <div className="row justify-content-md-center mt30">
                        <div className="col-lg-2 col">
                            <button disabled={isDisabled()} className="btn btn-lg btn-primary btn-block text-center" onClick={handleSubmit}>{'Save'}</button>
                        </div>
                        <div className="col-lg-2 col"><button className="btn blue-outline-btn text-center btn-block" type="submit" onClick={() => navigation("/DashboardLibrary")}>
                            Cancel</button>
                        </div>
                    </div>
                    <PasswordValidityViewer password={newPassword} showPopover={showPopover} id="checkNewPassword" />
                </div>
            </section>
        </>
    );
}

export default ChangePassword;

