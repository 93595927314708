import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { deleteRuleGroup, deleteRuleGroupMapping, editRuleGroup, getRuleDetail, getRuleGroupList, getRuleGroupMappingList, insertBusinessRule, insertRuleGroup, insertRuleGroupMapping, UpdateInputOutputTemplateGroupId } from '../../services/businessRule.service';
import { RuleState } from '../stores/ruleStore';

const initialState: RuleState = {
    rule_list: undefined,
    rule: undefined,
    rule_group_list: undefined,
    rule_group: undefined
};


export const getRuleListAsync = createAsyncThunk(
    'rule/getRule',
    async () => {
        let response = await getRuleDetail();
        return response;
    }
);

export const createRuleAsync = createAsyncThunk(
    'rule/createRule',
    async (data: any) => {
        let response = await insertBusinessRule(data.ruleDetail);
        return response;
    }
);

export const getRuleGroupListAsync = createAsyncThunk(
    'rule/getRuleGroupList',
    async () => {
        const response = await getRuleGroupList();
        return response;
    }
);
export const getRuleGroupMappingListAsync = createAsyncThunk(
    'rule/getRuleGroupMappingList',
    async (param: any) => {
        const response = await getRuleGroupMappingList(param);
        return response;
    }
);

export const CreateRuleGroupAsync = createAsyncThunk(
    'rule/CreateRuleGroup',
    async (value: any) => {
        const response = await insertRuleGroup(value);
        return response;
    }
);

export const CreateRuleGroupMappingAsync = createAsyncThunk(
    'rule/CreateRuleGroupMapping',
    async (value: any) => {
        const response = await insertRuleGroupMapping(value);
        return response;
    }
);

export const UpdateInputOutputTemplateMappingAsync = createAsyncThunk(
    'rule/updateInputOutputTemplateMapping',
    async (value: any) => {
        const response = await UpdateInputOutputTemplateGroupId(value);
        return response;
    }
)

export const updateRuleGroupAsync = createAsyncThunk(
    'rule/updateRuleGroup',
    async (value: any) => {
        const response = await editRuleGroup(value);
        return response;
    }
);

export const deleteRuleGroupAsync = createAsyncThunk(
    'rule/deleteRuleGroup',
    async (value: any) => {
        const response = await deleteRuleGroup(value);
        return response;
    }
);

export const deleteRuleGroupMappingAsync = createAsyncThunk(
    'rule/deleteRuleGroupMapping',
    async (value: any) => {
        const response = await deleteRuleGroupMapping(value);
        return response;
    }
);

export const ruleSlice = createSlice({
    name: 'rule',
    initialState,
    reducers: {
        setSelectedRule: (state: RuleState, action: PayloadAction<any>) => {
            state.rule = action.payload;
        },
        setRuleList: (state: RuleState, action: PayloadAction<any>) => {
            state.rule_list = action.payload;
        },
        resetSelectedRuleValues: (state: RuleState) => {
            if (state.rule) {
                state.rule.rule_id = "";
                state.rule.rule_view = "";
                state.rule.rule_row_message = "";
                state.rule.rule_criteria = "";
                state.rule.input_output_mapping_id = "";
                state.rule.rule_key_column = "";
                state.rule.selected_rule = "";
                state.rule.basic_sql_query = "";
            }
        },
        resetRuleState: (state: RuleState) => {
            state.rule = undefined
            state.rule_list = undefined;
            state.rule_group_list = undefined;
            state.rule_group = undefined;
        },
        setSelectedRuleGroup: (state: RuleState, action: PayloadAction<any>) => {
            state.rule_group = action.payload;
        },
        setRuleGroupList: (state: RuleState, action: PayloadAction<any>) => {
            state.rule_group_list = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRuleListAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.rule_list = action.payload;
            })
            .addCase(createRuleAsync.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.rule) {
                    const updatedList = state.rule_list ? [...state.rule_list] : [];
                    updatedList.push(action.payload.rule);
                    state.rule_list = updatedList;
                }
            })
            .addCase(getRuleGroupListAsync.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload) {
                    state.rule_group_list = action.payload;
                }
            })
    }
})
export const { setSelectedRule, setRuleList, resetRuleState, setSelectedRuleGroup, setRuleGroupList, resetSelectedRuleValues } = ruleSlice.actions;
export const readRuleState = (state: RootState) => state.rule;
export default ruleSlice.reducer;