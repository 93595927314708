import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Status } from "../../../constants/commonConstants";
import formatDate from "../../../constants/dateFormat";
import {formatAmount, formatAsPercent} from "../../../helpers/formatAmount";
import { Loader } from "../../../helpers/loader";
import { getLoanPropertyDetailsAsync, readLoanState } from "../../../redux/reducers/loanReducer";

const PropertyDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const loanState = useAppSelector(readLoanState);

    useEffect(() => {
        dispatch(getLoanPropertyDetailsAsync(props.loan_id));
    }, [])

    return (
        <>
            {loanState.propertyDetailsStatus === Status.Loading ? <Loader /> :
                <div className="row">
                    <div className="col">
                        <div className="row payment-boxes">
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Address</label>
                                    <span>{loanState.propertyDetails?.property_address || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Type</label>
                                    <span>{loanState.propertyDetails?.property_type || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Value - As is Value</label>
                                    <span>{loanState.propertyDetails?.actual_property_value ? formatAmount(Number(loanState.propertyDetails?.actual_property_value)) : "TBD"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Value - Repaired Value</label>
                                    <span>{loanState.propertyDetails?.repaired_property_value ? formatAmount(Number(loanState.propertyDetails?.repaired_property_value)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Value Date</label>
                                    <span>{loanState.propertyDetails?.property_value_date ? formatDate(String(loanState.propertyDetails?.property_value_date)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Property Value Type</label>
                                    <span>{loanState.propertyDetails?.property_value_type || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Owner Occupied</label>
                                    <span>{loanState.propertyDetails?.is_owner_occupied || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Latest Occupancy Date</label>
                                    <span>{loanState.propertyDetails?.latest_occupancy_date ? formatDate(String(loanState.propertyDetails?.latest_occupancy_date)) : "---" }</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Vacancy Date</label>
                                    <span>{loanState.propertyDetails?.vacancy_date ? formatDate(String(loanState.propertyDetails?.vacancy_date)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Loan to Value</label>
                                    <span>{loanState.propertyDetails?.loan_to_value ? formatAsPercent(Number(loanState.propertyDetails?.loan_to_value)) : "---"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default PropertyDetails;