/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule} from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { listWorkflowTaskAsync } from "../../../redux/reducers/loanReducer";
import { listWorkflowType } from '../../../services/loan.service';
import { Loader } from '../../../helpers/loader';
import CustomPopupComponent, { ModalType, VariantType } from '../../common/CustomPopupComponent';
import WorkflowEdit from './WorkflowEdit';
import Select from "react-select";
import {readAppState}from "../../../redux/reducers/appReducer"
import { selectCustomStyles} from "../../common/CustomStyles";
import formatDateTable from '../../../constants/dateFormatTable';
import GenericAgGrid from "../../common/GenericAgGrid";


const Workflow = (props: any) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    const [isEdit, setEditVisibility] = useState(false);
    const [workflowData, setWorkflowData] = useState<any>(null);
    
    const [workflowOptions, setWorkflowOptions] = useState<any>([]);
    const [selectedWorkflowData, setSelectedWorkflowData] = useState<any>(null);
    const gridRef: any = useRef();
    const loanState = useAppSelector((state: RootState) => state.loan);
    const appState = useAppSelector(readAppState);
    const [loader, setLoader] = useState(false);
    const [filter, setFilter] = useState({
        workflow_instance_id: "",
    })
    const filterRef = useRef(filter as any);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    let filterKeys;
    let  gridDetails =appState.gridCodeList?.find(g => g.grid_code === 'WORK_FLOW');
    const gridWorkFlowRef: any = useRef();
    
    

    useEffect(() => {
        workflow();
    },[activeTab])
    
    const workflow = async () => {
        setLoader(false);
        await listWorkflowType(props.loan_id).then(data => {
            if (data.length > 0) {
                const workflowOption = data.map(e => {
                    return {
                        label: e.sbs_workflow_type_description,
                        value: e.sbs_workflow_instance_id
                    }
                })
                setFilter({ workflow_instance_id: workflowOption[0].value })
                filterRef.current = { workflow_instance_id: workflowOption[0].value };
                setWorkflowOptions(workflowOption);
                setSelectedWorkflowData(workflowOption[0]);
                setLoader(false);
            }
            else{
                setLoader(false);
                setPopup({ ...popup, visible: true, message: `Workflow not found for loan number ${props.loan_id}`, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => {setPopup({ ...defaultPopup }) } });
            }
        })
    }


    const getCustomStaticFilter = async () => {
        let wfInstanceId = 0;
        
        if(filterRef.current.workflow_instance_id === "" || filterRef.current.workflow_instance_id === undefined ||  filterRef.current.workflow_instance_id === null) {
            let data= await listWorkflowType(props.loan_id);
            if (data.length > 0) {
                wfInstanceId = data.sbs_workflow_instance_id;
            }
        }
        filterKeys = (filterRef.current.workflow_instance_id !== "" && filterRef.current.workflow_instance_id !== undefined && filterRef.current.workflow_instance_id !== null) ? filterRef.current.workflow_instance_id : wfInstanceId;
        let customFilter = `loan_number = '${props.loan_id}' and sbs_workflow_instance_id = '${filterKeys}' `;
        return customFilter;
    }

    const columns = [
        {
            headerName: 'Workflow Task#', field: 'workflow_task_id', width: 150, cellRendererFramework: (params) =>
                <a className="link-primary" onClick={() => { setEditVisibility(true); setWorkflowData(params.data) }}>{params.data.workflow_task_id}</a>
        },
        { headerName: 'Task Description', field: 'sbs_workflow_task_description', width: 150 },
        { headerName: 'Start Date', field: 'schedule_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true)},
        { headerName: 'Due Date', field: 'due_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true)},
        { headerName: 'Complete Date', field: 'complete_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Task Note', field: 'task_note', width: 150 },
        { headerName: 'Task Result', field: 'sbs_workflow_task_result_description', width: 150 },
        { headerName: 'Task Responsible Party', field: 'sbs_responsible_party_id', width: 200 },
        { headerName: 'Created By', field: 'sbs_created_by', width: 150 },
        { headerName: 'Created Date', field: 'sbs_created_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) },
        { headerName: 'Modified By', field: 'sbs_modified_by', width: 150 },
        { headerName: 'Modified Date', field: 'sbs_modified_date', width: 150,filter: 'agDateColumnFilter',valueFormatter: (param) => formatDateTable(param, true) }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    const handleChange = (e) => {        
        setFilter({ workflow_instance_id: e.value});
        filterRef.current = { workflow_instance_id: e.value };
        if(filter.workflow_instance_id !== e.value){
            gridWorkFlowRef.current.api.refreshServerSideStore({ purge: true })
            gridWorkFlowRef.current.api.setFilterModel(null)
        }
        setSelectedWorkflowData(e)
    }


    return (<>

        <div style={{ display: !isEdit ? "block" : "none" }}>
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} variant={popup.variant} />}
            <div className="row">
                <div className="float-r col col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                    <div className="font-color form-floating multi-select-field ct_form_field select_dd " style={{ marginBottom: "10px"}}>
                        <Select
                            isSearchable={true}
                            isClearable={false}
                            styles={selectCustomStyles}
                            options={workflowOptions}
                            className="font-color form-control multi-select-dd dropdown-padding"
                            placeholder={""}
                            value={selectedWorkflowData}
                            onChange={(e) => { handleChange(e) }} />
                         <label>Workflow Type</label>    
                    </div>
                   
                </div>
            </div>
            {!!gridDetails   && <div>
                <GenericAgGrid
                    gridColumns={columns}
                    gridDefaultColDef={defaultColDef}
                    gridDetails={gridDetails}
                    rowHeight={40}
                    rowModelType={'serverSide'}
                    modules={[ServerSideRowModelModule]}
                    customStaticFilter={getCustomStaticFilter}
                    parentGridRef={gridWorkFlowRef}
                ></GenericAgGrid>
            </div>}
        </div>
        {isEdit && <WorkflowEdit data={workflowData} toggle={() => setEditVisibility(false)} />}
        {loader && <Loader />}
    </>
    ) 
    
}

export default Workflow;