/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { memo, useEffect, useState, useRef } from "react";
import { searchConstants } from "../../constants/searchConstants";
import { Loader } from "../../helpers/loader";
import {
  deleteNavigationAsync,
  getAllNavigations,
  saveNavigationAsync,
} from "../../services/navigationConfiguration.service";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../common/CustomPopupComponent";
import { getAllStatus } from "../../services/getLoanData.service";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  readAppState,
  setBreadcrumbLocation,
} from "../../redux/reducers/appReducer";
import Breadcrumb from "../common/Breadcrumb";
import { selectCustomStyles } from "../common/CustomStyles";

const ScreenNavigations = () => {
  const appState = useAppSelector(readAppState);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    onButtonClick: null as any,
    toggle: null as any,
    primaryButtonAction: null as any,
    primaryButtonName: null as any,
    secondaryButtonAction: null as any,
    secondaryButtonName: null as any,
  };
  const [statusOptions, setStatusOption] = useState<any[]>([]);
  const [screenOptions, setScreenOption] = useState<any[]>([]);
  const [loading, setLoader] = useState(true);
  const [dropdownLoader, setDropdownLoader] = useState(true);
  const [selectedValues, setSelectedValues] = useState({
    status: null as any,
    screen: null as any,
  });
  const [popup, setPopup] = useState(defaultPopup);
  const [navigations, setNavigations] = useState<any>(undefined);
  const [gridApi, setGridApi] = useState<any>(null);
  const [override, setOverride] = useState<any>(false);
  const [screenList, setScreenList] = useState<any[]>([]);
  const [disable,setDisable]=useState<boolean>(false)
  const dispatch = useAppDispatch();

  const columns: any[] = [
    {
      field: "loan_status",
      headerName: "Loan Status",
      tooltipField: "loan_status",
      headerTooltip: "Loan Status",

    },
    {
      field: "name",
      headerName: "Navigate To",
      tooltipField: "name",
      headerTooltip: "Navigate To",

    },
    {
      headerName: "Action",
      pinned: "right",
      suppressSizeToFit: true,
      field: "counter_party_name",
      filter: false,
      width: 115,
      cellRendererFramework: (params) => (
        <div>
          <button
            onClick={() => handleDelete(params)}
            className="delete_icon_link"
          >
            <span className="fas fa-trash-alt"></span>
          </button>
        </div>
      ),
    },
  ];
  const defaultColDef = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
    enableTooltip: true,
    enableColResize: true,
  };
  const gridRef: any = useRef();

  useEffect(() => {
    dispatch(
      setBreadcrumbLocation([
        {
          screen_name: "Screen Navigations",
          redirect_url: "/ScreenNavigations",
          icon_url: "fad fa-toolbox",
        },
      ])
    );
    getAllData();
  }, []);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  }, [appState.isNavMenuOpen]);

  const getLoanStatus = async () => {
    let userGroups = appState.userGroups ? appState.userGroups : [];
    let currentUserGroup = userGroups.find(g => g.group_id === appState.currentUser?.group_id)
    let loanStatus = await getAllStatus(currentUserGroup?.logi_user_id);
    if (loanStatus && loanStatus.length > 0) {
      let status: any[] = [];
      let statusOptionArray: any[] = [];
      loanStatus.forEach((z) => {
        if (!status.find(x => x === z.sbs_loan_status_description) && z.sbs_loan_status_description !== "" && z.sbs_loan_status_description) {
          status.push(z.sbs_loan_status_description);
          statusOptionArray.push({
            value: z.sbs_loan_status_description,
            label: z.sbs_loan_status_description
          })
        }
      })
      setStatusOption(statusOptionArray);
    }
    setLoader(false)
    setDropdownLoader(false);
  }

  const getAllData = async () => {
    setLoader(true);
    setDropdownLoader(true);
    try {
      await Promise.all([getLoanStatus(), getScreens(), listNavigations()]);
    } catch (ex) {
      setLoader(false);
    }
  };

  const getScreens = async () => {
    const screenList: any = appState.screensAccessible ? appState.screensAccessible : []
    if (screenList && screenList.length > 0) {
      let screenData: any = screenList.map((z) => {
        return {
          value: z.screen_name,
          label: z.screen_name
        }
      });
      setScreenList(screenList);
      setScreenOption(screenData);
    }
  }


  const listNavigations = async () => {
    const navigationList = await getAllNavigations();
    if (navigationList && navigationList.length > 0) {
      setNavigations(navigationList);
      setLoader(false);
    }else{setNavigations(navigationList);
      setLoader(false);}
  };

  const handleInputChange = (e, key) => {
    if (key === searchConstants.status) {
      setSelectedValues({ ...selectedValues, status: e });
    } else {
      setSelectedValues({ ...selectedValues, screen: e });
    }
  };

  const save = async () => {
    let input: any = { loan_status: selectedValues.status.value };
    let screen = screenList.find(
      (x) => x.screen_name === selectedValues.screen.value
    );
    input = {
      ...input,
      screen_id: screen.screen_id,
      name: screen.screen_name,
      path: screen.redirect_url,
    };
    const response = await saveNavigationAsync(input);
    if (
      response &&
      response.data &&
      (response.data.createNavigation || response.data.updateNavigation)
    ) {
      setSelectedValues({ status: null, screen: null });
      setDisable(false);
      setPopup({
        ...popup,
        visible: true,
        message: "Screen navigation successfully added",
        type: ModalType.Snackbar,
        variant: VariantType.Success,
      });
      const navigationList = await getAllNavigations();
      if (navigationList && navigationList.length > 0) {
        setNavigations(navigationList);
      }
    }
    setTimeout(() => setPopup({ ...defaultPopup }), 2000);
    setOverride(false);
  };
  const handleSaveClick = async event => {
    
    setLoader(true);
    setDisable(true);
    if (selectedValues.screen && selectedValues.status) {
      if (
        !navigations.find((x) => x.loan_status === selectedValues.status.label)
      ) {
        save();
      } else {
        setDisable(true);
        setOverride(true);
        setPopup({
          ...popup,
          color: "red",
          visible: true,
          message:
            "This screen navigation already exists. Would you like to edit or replace it?",
          type: ModalType.ActionDialog,
          toggle: () => setPopup({ ...defaultPopup }),
          primaryButtonName: "Override",
          primaryButtonAction: async () => save(),
          secondaryButtonName: "Cancel",
          secondaryButtonAction: () => {
            setPopup({ ...defaultPopup });
            setOverride(false);
            setDisable(false);
          },
        });
      }
    }
    setLoader(false);
    
  };

  const removeNavigation = async (loan_status_id) => {
    setPopup(defaultPopup);
    setLoader(true);
    try {
      const response = await deleteNavigationAsync(loan_status_id);
      if (response) {
        await listNavigations();
        setPopup({
          ...popup,
          visible: true,
          message: "This screen navigation has been deleted.",
          type: ModalType.Snackbar,
          variant: VariantType.Success,
          toggle: () => setPopup({ ...defaultPopup }),
        });
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      setPopup({
        ...popup,
        visible: true,
        message: "Navigation could not be deleted!",
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup }),
      });
    }
    setTimeout(() => setPopup({ ...defaultPopup }), 2000);
  };

  const onGridReady = (params: any) => {
    params.api.sizeColumnsToFit();
    setGridApi(params.api);

  };

  const handleDelete = (params) => {
    setPopup({
      ...popup,
      color: "red",
      visible: true,
      message:
        "Please confirm that you would like to delete this screen navigation.",
      type: ModalType.ActionDialog,
      toggle: () => setPopup({ ...defaultPopup }),
      primaryButtonAction: async () => {
        override ? await save() : await removeNavigation(params.data.status_id);
      },
      primaryButtonName: override ? "Override" : "Delete",
      secondaryButtonName: "Cancel",
      secondaryButtonAction: () => setPopup({ ...defaultPopup }),
    });
  };

  return (
    <>
      <div className="pagetitle">
        <Breadcrumb />
      </div>
      <section className="section dashboard">
        {loading && <Loader />}
        {popup.visible && (
          <CustomPopupComponent
            isOpen={popup.visible}
            message={popup.message}
            primaryButtonName={popup.primaryButtonName}
            secondaryButtonName={popup.secondaryButtonName}
            secondaryButtonAction={popup.secondaryButtonAction}
            toggle={popup.toggle}
            primaryButtonAction={popup.primaryButtonAction}
            type={popup.type}
            variant={popup.variant}
          />
        )}
        <div className="block_sect">
          <div className="row mb-3">
            <div className="col-lg-4 form-group custm_field">
              <label>Loan Status</label>
              <Select
                isSearchable={true}
                isClearable={true}
                styles={selectCustomStyles}
                options={statusOptions}
                value={selectedValues.status}
                onChange={(e) => {
                  handleInputChange(e, "status");
                }}
                placeholder="Select Loan Status"
                isDisabled={false}
                isLoading={dropdownLoader}
              />
            </div>
            <div className="col-lg-4 form-group custm_field">
              <label>Navigate To</label>
              <Select
                isSearchable={true}
                isClearable={true}
                styles={selectCustomStyles}
                options={screenOptions}
                value={selectedValues.screen}
                onChange={(e) => {
                  handleInputChange(e, "screen");
                }}
                placeholder="Select Screen"
                isDisabled={false}
                isLoading={dropdownLoader}
              />
            </div>
            <div
              className="col-lg-4 form-group custm_field"
              style={{ marginTop: "27px" }}
            >
              <button
                className="btn btn-primary text-center"
                type="submit"
                onClick={(e) => handleSaveClick(e)}
                disabled={disable || !selectedValues.screen || !selectedValues.status}
              >
                Save
              </button>
            </div>
          </div>
            <div
              className="ag-theme-alpine" style={{ height:"65vh", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={columns}
                rowData={navigations}
                rowSelection={"single"}
                defaultColDef={defaultColDef}
                paginationPageSize={10}
                onGridReady={onGridReady}
                overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                overlayLoadingTemplate={
                  '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                }
                modules={[ClientSideRowModelModule]}></AgGridReact>
            </div>
        </div>
      </section>
    </>
  );
};

export default memo(ScreenNavigations);
