export const userGroups = {
  Loss_Mitigation: "jwilliams",
  Collateral_Management: "oscott",
  Default_Management: "mjohnson",
  Admin: "maas_admin",
};

export default function useCountryList() {
  return [
    ["+91", "(+91)IND"],
    ["+1", "(+1)USA"],
    ["+93", "(+93)AFG"],
    ["+83", "(+83)CHN"],
    ["+45", "(+45)DNK"],
  ];
}
