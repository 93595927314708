/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState, useRef } from "react";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { readAppState, setBreadcrumbLocation, setRoleList } from "../../../redux/reducers/appReducer";
import Breadcrumb from "../../common/Breadcrumb";
import { setRole } from "../../../redux/reducers/userReducer";
import { deleteRoleById, deleteScreenRoleByRoleId, getRoleList } from "../../../services/role.service";
import { deleteUserRoleByRoleId } from "../../../services/user.service";
import { Loader } from "../../../helpers/loader";

const RoleListComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            "screen_id": "",
            "screen_name": "Role Management",
            "redirect_url": "/Roles",
            "icon_url": "fas fa-address-book"
        }]));
    }, []);

    useEffect(() => {
        if (gridApi) {
            setTimeout(() => {
                    gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen]);

    const onGridReady = async (params: any) => {
        params.api.sizeColumnsToFit();
        let roles: any;
        try {
            roles = await getRoleList();
            dispatch(setRoleList(roles));
            setRowData(roles);
            params.api.paginationGoToPage(10)
            setGridApi(params.api);
            var sort = [{
                colId: "created_date",
                sort: "desc"
            }];
            params.columnApi.applyColumnState({
                state: sort,
                defaultState: { sort: null }
            })
            setLoading(false);
        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const handleEditClick = (params: any) => {
        dispatch(setRole(params.data));
        navigation("/RoleManagement")
    }

    const columns: any[] = [
        { headerName: 'Role Id', field: 'role_id', hide: true },
        { headerName: 'Created Date', field: 'created_date', hide:true },
        { headerName: 'Role Name', field: 'role_name', headerTooltip: 'Role Name' },
        { headerName: 'Action', pinned: 'right', filter: false, width: 110, suppressSizeToFit: true, cellRendererFramework: (params) => <div className="grid-action">
                <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
                <button disabled={params.data.role_name === "Administrator"} title={params.data.role_name === "Administrator" ? "Cannot delete administrator role" : "Delete"}
                    onClick={() =>
                        setPopup({
                            ...popup,
                            visible: true,
                            message: "Please confirm that you would like to delete this role.",
                            type: ModalType.ActionDialog,
                            toggle: () => setPopup({ ...defaultPopup }),
                            primaryButtonAction: () => removeRole(),
                            primaryButtonName: "Delete",
                            secondaryButtonName: "Cancel",
                            secondaryButtonAction: () => setPopup({ ...defaultPopup })
                        })} className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
            </div >
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }
    const gridRef: any = useRef();

    const removeRole = async () => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
            const selectedData = gridApi.getSelectedRows();
            if (selectedData[0].role_id !== appState.currentUser!.role_id) {
                gridApi.updateRowData({ remove: selectedData });
                const updatedRoleList = appState.roles.filter((x: any) => x.role_id !== selectedData[0].role_id);
                dispatch(setRoleList(updatedRoleList));
                await deleteRoleById(selectedData[0].role_id);
                await deleteUserRoleByRoleId(selectedData[0].role_id);
                await deleteScreenRoleByRoleId(selectedData[0].role_id);
                setLoading(false);
                setPopup({ ...popup, visible: true, message: "This role has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
            }
            else {
                setLoading(false);
                setPopup({ ...popup, visible: true, message: "Role cannot be deleted! Logged in user belongs to this role.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
            }
        }

        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "Role could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }

    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                    <a className="btn btn-primary" onClick={() => { navigation("/RoleManagement"); dispatch(setRole({ is_new: true })) }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} /> Add Role
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            {loading && <Loader />}
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
            <div className="ag-theme-alpine" style={{ height:'72vh', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columns}
                    rowData={rowData}
                    rowSelection={'single'}
                    defaultColDef={defaultColDef}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}>
                </AgGridReact>
            </div>
        </section>
    </>
    )
}

export default memo(RoleListComponent);