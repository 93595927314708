var currentDate = new Date();
const convertToClientDateTime = (dt1, dt2) => {    
    var diff;
    if (dt1 !== null && dt2 !== null) {
        dt2 = new Date(dt2);
        diff = currentDate.getTime() - dt2.getTime();
        dt1 = new Date(new Date(dt1).getTime() + diff);
        return formatDate(dt1); 
    } else {
        return (dt1 = "");
    }
};

const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
};

export const  formatDate = (date) => {
    return (
        [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),

        ].join("-") +
        " " +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(":")
    );
};

export const dateTimeToLocal=(date)=>{
    //chrome
    if (navigator.userAgent.indexOf("Chrome") != -1 ) {
        if(date)
        {let dateUTC=date+' UTC'
        dateUTC=new Date(dateUTC).toString();
        dateUTC= formatDate(new Date(dateUTC));
        return dateUTC;}
        else{
            return date;
        }
      }

      // FIREFOX
      else if (navigator.userAgent.indexOf("Firefox") != -1 ) {
        if(date) {
            let dateUTC=date ;
            dateUTC=new Date(dateUTC);
            dateUTC= formatDate(new Date(dateUTC));
            let UTCDate = dateUTC.split("-");
            let convertedUTCDate = new Date(`${UTCDate[0]}`+"/"+`${UTCDate[1]}`+"/"+`${UTCDate[2]}`+" UTC");
            let finalUTCDate = convertedUTCDate.toString().split(" ");
            let localeDate = convertedUTCDate.toLocaleString().split(",")[0].split("/");
            let finalDate=`${Number(localeDate[0]) < 10 ? `0${localeDate[0]}`:localeDate[0]}`+"-"+`${Number(localeDate[1]) < 10 ?`0${localeDate[1]}`:localeDate[1]}`+"-"+`${finalUTCDate[3]}`+" "+`${finalUTCDate[4]}`
            return finalDate;
        }
        else{
            return date;
        }
      }
    }

export default convertToClientDateTime;