/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import Select from 'react-select';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateGroupAsync } from '../../redux/reducers/userReducer';
import { grantDashboardPermissions, grantReportPermissions } from '../../services/logi.service';
import { getDashboardToken, getDashboardWithVisualsById, updateDataSources, updateReports } from '../../services/dashboard.service';
import { readAppState } from '../../redux/reducers/appReducer';
import { getVisuals } from '../../services/visual.service';
import { selectCustomStyles } from './CustomStyles';
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";

const customStyles = {
    control: (base, state: any) => ({
        ...base,
        minHeight: 30,
        width: 250,
        minWidth: 250,
        border: state.isFocused ? "1px solid var(--border-color)" : "1px solid #cccccc",
        boxShadow: state.isFocused ? "0px 0px 6px var(--box-shadow-color)" : "none",
        "&:hover": {
            border: "1px solid var(--border-color)",
            boxShadow: "0px 0px 6px var(--box-shadow-color)"
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "var(--secondary-color) !important" : "#FFF",
        color: state.isSelected ? "#FFF !important" : "inherit",
        "&:hover": {
            color: "var(--primary-color)",
            background: "var(--base-color)"
        }
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: 4
    }),
    clearIndicator: base => ({
        ...base,
        padding: 4
    }),
    multiValue: base => ({
        ...base,
        backgroundColor: "white"
    }),
    valueContainer: base => ({
        ...base,
        padding: '0px 6px'
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};

const SharePopupComponent = (props: any) => {
    const [isOpen, toggle] = useState(false);
    const [userGroupOptions, setUserGroupOptions] = useState<any>([]);
    const [userGroupList, setUserGroupList] = useState<any>([]);
    const [selectedGroups, setSelectedGroups] = useState<any>([]);
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);
    const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);


    useEffect(() => {
        const userGroups = props.userGroups;
        if (userGroups && userGroups.length > 0) {
            let groups = userGroups.map(g => {
                return {
                    label: g.group_name,
                    value: g.group_id
                }

            });
            groups = groups.filter(gr => gr.label !== appState.logiConfiguration.find(x=> x.configuration_type === 'admin_user_name')?.configuration_value && gr.label !== appState.currentUser!.group_name);
            setUserGroupOptions(groups);
            setUserGroupList(userGroups);
        }
    }, [isOpen]);

    const submitButton = () => {
        if (selectedGroups && selectedGroups.length > 0) {
            return (<button className="btn btn-primary btn-block" type="submit" onClick={() => { handleShare(); toggle(!isOpen) }}
            onKeyPress={(target) => { if (target.charCode === 13) {
                handleShare(); toggle(!isOpen) }}}>Share</button>)
        }
        else {
            return (<button className="btn btn-primary btn-block" type="submit" disabled >Share</button>)
        }
    }

    const handleShare = async () => {

        let selectedGroupList: any[] = [];
        let updatedDashboardsList: any[] = [];
        let updatedReportsList: any[] = [];
        let updatedDataSourcesList: any[] = [];
        let grantReportPermissionsList: any[] = [];

        if (selectedGroups && selectedGroups.length > 0) {
            selectedGroups.forEach(gr => {
                selectedGroupList.push(userGroupList.find(z => z.group_id === gr.value));
            });
        }

        const token = await getDashboardToken({ user: appState.currentUser, logiConfiguration: appState.logiConfiguration });
        const visualList = await getVisuals(token, appState.currentUser);

        if (props.componentName === "Dashboard") {
            if (selectedGroupList && selectedGroupList.length > 0) {
                const sharedDashboard = await getDashboardWithVisualsById(token, props.dashboardData.inventoryItemId);

                if (sharedDashboard && sharedDashboard.widgets && sharedDashboard.widgets.length > 0) {
                    sharedDashboard.widgets.forEach(vis => {
                        if (visualList.find(v => v.id === vis.visualId)) {
                            grantReportPermissionsList.push(visualList.find(v => v.id === vis.visualId));
                        }
                    });
                }

                selectedGroupList.forEach(async (sg) => {
                    updatedDashboardsList = sg.dashboards_list ? [...sg.dashboards_list.data] : [];
                    updatedReportsList = sg.reports_list ? [...sg.reports_list.data] : [];
                    updatedDataSourcesList = sg.data_sources_list ? [...sg.data_sources_list.data] : [];

                    if (!updatedDashboardsList.find(x => x.dashboard_id === props.dashboardData.inventoryItemId)) {
                        updatedDashboardsList.push({ dashboard_id: props.dashboardData.inventoryItemId, dashboard_name: props.dashboardData.name });
                    }

                    if (props.dashboardData && props.dashboardData.associatedItems && props.dashboardData.associatedItems.length > 0) {
                        props.dashboardData.associatedItems.forEach(item => {
                            if (item.type === "DATA_SOURCE" && !updatedDataSourcesList.find(z => z.source_id === item.inventoryItemId)) {
                                updatedDataSourcesList.push({
                                    source_id: item.inventoryItemId,
                                    source_name: item.name
                                });
                            }
                        });
                    }

                    grantReportPermissionsList.forEach(r => {
                        if (!updatedReportsList.find(y => y.report_id === r.id)) {
                            updatedReportsList.push({ report_id: r.id, report_name: r.visualName });
                        }
                    })

                    await Promise.all([grantDashboardPermissions(sg.logi_user_id, [props.dashboardData], appState.logiConfiguration.find(x=> x.configuration_type === 'logi_admin_user_name')?.configuration_value), grantReportPermissions(sg.logi_user_id, grantReportPermissionsList, appState.logiConfiguration.find(x=> x.configuration_type === 'logi_admin_user_name')?.configuration_value)]).then(response => {
                        if (response[0] && response[1]) {
                            setPopup({
                                ...popup,
                                visible: true,
                                message: `This dashboard has been successfully shared.`,
                                type: ModalType.Snackbar,
                                variant: VariantType.Success,
                                toggle: () => setPopup({ ...defaultPopup })
                            });

                            dispatch(updateGroupAsync({
                                group_id: sg.group_id,
                                group_name: sg.group_name,
                                dashboards_list: JSON.stringify({ data: updatedDashboardsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                data_sources_list: JSON.stringify({ data: updatedDataSourcesList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                modified_by: appState.currentUser?.maas_user_id,
                                modified_date: new Date().toUTCString()
                            }));

                            props.shareResponse(true);
                        }
                        else {
                            props.shareResponse(false);
                        }
                    });
                });
            }
        }
        else if (props.componentName === "Report") {
            if (props.visualData) {
                selectedGroupList.forEach(sg => {
                    updatedReportsList = sg.reports_list ? [...sg.reports_list.data] : [];
                    updatedDataSourcesList = sg.data_sources_list ? [...sg.data_sources_list.data] : [];

                    if (!updatedReportsList.find(x => x.report_id === props.visualData.id)) {
                        updatedReportsList.push({
                            report_id: props.visualData.id,
                            report_name: props.visualData.visualName
                        });
                    }

                    if (!updatedDataSourcesList.find(z => z.source_id === props.visualData.ownerSourceId)) {
                        updatedDataSourcesList.push({
                            source_id: props.visualData.ownerSourceId,
                            source_name: props.visualData.sourceName
                        });
                    }

                    grantReportPermissions(sg.logi_user_id, [props.visualData], appState.logiConfiguration.find(x=> x.configuration_type === 'logi_admin_user_name')?.configuration_value).then(response => {
                        if (response) {
                            const reportsInput = {
                                group_id: sg.group_id,
                                reports_list: JSON.stringify({ data: updatedReportsList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                modified_by: appState.currentUser?.maas_user_id,
                                modified_date: new Date().toUTCString()
                            }
                            const dataSourceInput = {
                                group_id: sg.group_id,
                                data_sources_list: JSON.stringify({ data: updatedDataSourcesList }).replaceAll(/"/g, '\\"').replaceAll("'", "''"),
                                modified_by: appState.currentUser?.maas_user_id,
                                modified_date: new Date().toUTCString()
                            }
                            updateReports(reportsInput);
                            updateDataSources(dataSourceInput);
                            props.shareResponse(true);
                        }
                        else {
                            props.shareResponse(false);
                        }
                    });
                });
            }
        }
        setSelectedGroups([]);
    }



    return <>
        <li>
            <a onClick={() => { !props.enableDeleteShare? props.componentName === "Dashboard"? props.disabledClickFunction(true):props.disabledReportClickFunction(true) : toggle(!isOpen); setSelectedGroups([])}}  onKeyPress={(target) => { if (target.charCode === 13) {!props.enableDeleteShare? props.componentName === "Dashboard"? props.disabledClickFunction(true):props.disabledReportClickFunction(true) : toggle(!isOpen); setSelectedGroups([])}}}
                id={props.dashboardData ? "popover" + props.dashboardData.inventoryItemId : props.visualData ? "popover" + props.visualData.id : "popover"}>
                <img src={massCdnUrl + "assets/img/site-share-icon.svg"} className={!props.enableDeleteShare ? "disabled" : ""}  />
            </a>
        </li>
        <Popover className = "share-popover" modifiers={{ flip: { enabled: true, behavior: ['bottom', 'top'] } }} trigger="legacy" placement="bottom" isOpen={isOpen} toggle={() => { props.enableDeleteShare && toggle(!isOpen); setSelectedGroups([]) }} target={props.dashboardData ? "popover" + props.dashboardData.inventoryItemId : props.visualData ? "popover" + props.visualData.id : "popover"} >
            <PopoverHeader style={{ backgroundColor: "#00B6DD", color: "white" }}>Share {props.componentName}<span style={{ float: 'right', margin: "5px" }} tabIndex={0} className="fas fa-close tooltip_close" onClick={() => { toggle(!isOpen); setSelectedGroups([]) }} 
             onKeyPress={(target) => { if (target.charCode === 13) {toggle(!isOpen); setSelectedGroups([]) }} }/></PopoverHeader>
            <PopoverBody>
                <div className="col" style={{ padding: "0px" }}>
                    <div className="form-floating ct_form_field select_dd mb-4">
                        <Select
                            isSearchable={true}
                            isClearable={true}
                            id="groupList"
                            options={userGroupOptions}
                            isMulti={true}
                            value={selectedGroups}
                            onChange={(e) => setSelectedGroups(e)}
                            placeholder="Select Groups..."
                            menuPlacement="auto"
                            maxMenuHeight={150}
                            styles={customStyles} />
                    </div>

                    <div className="row justify-content-md-center gx-1">
                        <div className='col-lg-4 col'>
                            {submitButton()}
                        </div>
                        <div className='col-lg-4 col '>
                            <button className="btn btn-outline-primary btn-block" style={{ marginLeft: "10px" }} onClick={() => { toggle(!isOpen); setSelectedGroups([]) }} >Cancel</button>
                        </div>
                    </div>
                </div>
            </PopoverBody>
        </Popover>
        {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
    </>
}
export default SharePopupComponent;

