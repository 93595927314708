import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Status } from '../../constants/commonConstants';
import { fetchLoanDetails, getCodeViolationsDetails, getDisbursementDetails, getHoaDetail, getInspectionDetail, getLoanPropertyDetail, getLoanTransactions, getLossDraftsDetails, getNotesList, getCommentList, getPaymentAndBalanceDetail, getPropertyDetail, getTaxDetail, listWorkflowTasks, insertComment, updateCommentData, deleteCommentById, GetLoanDocumentsByLoanSkey } from '../../services/loan.service';
import { GetLoanSearchByFilter } from '../../services/loanSearch.services';
import { LoanState } from '../stores/loanStore';
import { insertTag, updateTagData } from '../../services/tagList.service'
import { updateFnmaBillDetail } from '../../services/bill.service';


const initialState: LoanState = {
    status: Status.Idle,
    errorMessage: "",
    loanDetail: undefined,
    loanTransactions: [],
    loans: [],
    comments: [],
    activeTab: "",
    loanTabs: [],
    paymentAndBalance: undefined,
    propertyDetails: undefined,
    inspectionDetails: undefined,
    taxDetails: undefined,
    hoaDetails: undefined,
    loanTransactionsStatus: Status.Idle,
    paymentAndBalanceStatus: Status.Idle,
    propertyDetailsStatus: Status.Idle,
    inspectionDetailsStatus: Status.Idle,
    taxDetailsStatus: Status.Idle,
    hoaDetailsStatus: Status.Idle,
    commentsStatus: Status.Idle,
    disbursementsStatus: Status.Idle,
    commentsData: undefined,
    tagDetails: undefined,
    tagNameList: [],
    documentList: [],
    tags: undefined,
    fnmaBillDetails: undefined,
    documents: [],
    noteData: undefined
};

export const getLoansFilterAsync = createAsyncThunk(
    'rule/getLoansByFilter',
    async (param: any) => {
        let response = await GetLoanSearchByFilter(param);
        return response;
    }
);

export const getLoanDetailsAsync = createAsyncThunk(
    'rule/getLoanDetails',
    async (params: any) => {
        let response = await fetchLoanDetails(params);
        return response;
    }
);

export const getLoanTransactionAsync = createAsyncThunk(
    'rule/getLoanTransactions',
    async (params: any) => {
        let response = await getLoanTransactions(params);
        return response;
    }
);

export const getDisbursementsAsync = createAsyncThunk(
    'rule/getDisbursements',
    async (params: any) => {
        let response = await getDisbursementDetails(params);
        return response;
    }
);

export const getPaymentAndBalanceAsync = createAsyncThunk(
    'rule/getPaymentAndBalance',
    async (params: any) => {
        let response = await getPaymentAndBalanceDetail(params);
        return response;
    }
);

export const getNotesAsync = createAsyncThunk(
    'rule/getNotes',
    async (param: any) => {
        let response = await getNotesList(param);
        return response;
    }
);

export const listWorkflowTaskAsync = createAsyncThunk(
    'rule/listWorkflowTask/fulfilled/',
    async (param: any) => {
        let response = await listWorkflowTasks(param);
        return response;
    }
);

export const getLoanPropertyDetailsAsync = createAsyncThunk(
    'rule/getLoanPropertyDetails',
    async (loanNumber: any) => {
        let response = await getLoanPropertyDetail(loanNumber);
        return response;
    }
);

export const getInspectionDetailsAsync = createAsyncThunk(
    'rule/getInspectionDetails',
    async (loanNumber: any) => {
        let response = await getInspectionDetail(loanNumber);
        return response;
    }
);

export const getCodeViolationsAsync = createAsyncThunk(
    'rule/getCodeViolations',
    async (params: any) => {
        let response = await getCodeViolationsDetails(params);
        return response;
    }
);

export const getLossDraftAsync = createAsyncThunk(
    'rule/getLossDrafts',
    async (params: any) => {
        let response = await getLossDraftsDetails(params);
        return response;
    }
);

export const getTaxDetailsAsync = createAsyncThunk(
    'rule/getTaxDetails',
    async (loanNumber: any) => {
        let response = await getTaxDetail(loanNumber);
        return response;
    }
);

export const getHoaDetailsAsync = createAsyncThunk(
    'rule/getHoaDetails',
    async (loanNumber: any) => {
        let response = await getHoaDetail(loanNumber);
        return response;
    }
);

export const createCommentAsync = createAsyncThunk(
    'rule/insertComment',
    async (value: any) => {
        const response = await insertComment(value);
        return response;
    }
);

export const updateCommentAsync = createAsyncThunk(
    'rule/updateComment',
    async (value: any) => {
        const response = await updateCommentData(value);
        return response;
    }
);

export const getCommentListAsync = createAsyncThunk(
    'rule/getCommentList',
    async (param: any) => {
        let response = await getCommentList(param);
        return response;
    }
);
export const createTagAsync = createAsyncThunk(
    'tag/createTag',
    async (value: any) => {
        const response = await insertTag(value);
        return response;
    }
);
export const updateTagAsync = createAsyncThunk(
    'tag/updateTagData',
    async (value: any) => {
        const response = await updateTagData(value);
        return response;
    }
);

export const updateFnmaBillAsync = createAsyncThunk(
    'fnmaBill/updateFnmaBillDetail',
    async (value: any) => {
        const response = await updateFnmaBillDetail(value);
        return response;
    }
);

export const getLoanDocumentsAsync = createAsyncThunk(
    'rule/getLoanDocumentsAsync',
    async (param: any) => {
        let response = await GetLoanDocumentsByLoanSkey(param);
        return response;
    }
);


export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        removeLoanTab: (state, action) => {
            let tabs = state.loanTabs.filter(i => i !== action.payload);
            state.loanTabs = tabs;
            if (state.activeTab === action.payload) {
                state.activeTab = state.loanTabs.length > 0 ? state.loanTabs[0] : "";
            }
        },
        addLoanTab: (state, action) => {
            let currentTabs = current(state.loanTabs);
            let tabs = currentTabs ? JSON.parse(JSON.stringify(currentTabs)) : [];
            if (!tabs.includes(action.payload)) {
                tabs.push(action.payload);
            }
            state.loanTabs = tabs;
            state.activeTab = action.payload;
        },
        clearLoanTabs: (state) => {
            state.activeTab = "";
            state.loanTabs = [];
        },
        clearSearchedLoans: (state) => {
            state.loans = undefined;
        },
        setCommentList: (state: LoanState, action: PayloadAction<any>) => {
            state.commentsData = action.payload;
        },
        setTagDetails: (state: LoanState, action: PayloadAction<any>) => {
            state.tagDetails = action.payload;
        },
        setNoteData: (state: LoanState, action: PayloadAction<any>) => {
            state.noteData = action.payload;
        },
        setTagList: (state: LoanState, action: PayloadAction<any>) => {
            state.tagNameList = action.payload;
        },
        setDocumentList: (state: LoanState, action: PayloadAction<any>) => {
            state.documentList = action.payload;
        },
        setDocumentsData: (state: LoanState, action: PayloadAction<any>) => {
            state.documentsData = action.payload;
        },

        resetTagValues: (state: LoanState) => {
            if (state.tagDetails) {
                state.tagDetails.tag_id = "";
                state.tagDetails.tag_name = "";
                state.tagDetails.tag_description = "";
                state.tagDetails.priority = "";
            }
        },
        setTags: (state: LoanState, action: PayloadAction<any>) => {
            state.tags = action.payload;
        },
        setFnmaBillDetails: (state: LoanState, action: PayloadAction<any>) => {
            state.fnmaBillDetails = action.payload;
        },
        setTagsRefresh: (state: LoanState, action: PayloadAction<boolean>) => {
            if (state.tags) {
                state.tags.refresh = action.payload;
            }
        }
    },


    extraReducers: (builder) => {
        builder
            .addCase(getLoansFilterAsync.pending, (state, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getLoansFilterAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.loans = action.payload;
            })
            .addCase(getLoansFilterAsync.rejected, (state, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.errorMessage = "Error Fetching Loans!"
            })
            .addCase(getLoanDetailsAsync.pending, (state, action: PayloadAction<any>) => {
                state.status = Status.Loading;
            })
            .addCase(getLoanDetailsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.loanDetail = action.payload;
            })
            .addCase(getLoanDetailsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.status = Status.Idle;
                state.errorMessage = "Error Fetching Loan Details!"
            })
            .addCase(getPaymentAndBalanceAsync.pending, (state, action: PayloadAction<any>) => {
                state.paymentAndBalanceStatus = Status.Loading;
            })
            .addCase(getPaymentAndBalanceAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.paymentAndBalanceStatus = Status.Idle;
                state.paymentAndBalance = action.payload;
            })
            .addCase(getPaymentAndBalanceAsync.rejected, (state, action: PayloadAction<any>) => {
                state.paymentAndBalanceStatus = Status.Idle;
                state.errorMessage = "Error Fetching Payment Details!"
            })

            .addCase(getLoanPropertyDetailsAsync.pending, (state, action: PayloadAction<any>) => {
                state.propertyDetailsStatus = Status.Loading;
            })
            .addCase(getLoanPropertyDetailsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.propertyDetailsStatus = Status.Idle;
                state.propertyDetails = action.payload;
            })
            .addCase(getLoanPropertyDetailsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.propertyDetailsStatus = Status.Idle;
                state.errorMessage = "Error Fetching Property Details!"
            })

            .addCase(getInspectionDetailsAsync.pending, (state, action: PayloadAction<any>) => {
                state.inspectionDetailsStatus = Status.Loading;
            })
            .addCase(getInspectionDetailsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.inspectionDetailsStatus = Status.Idle;
                state.inspectionDetails = action.payload;
            })
            .addCase(getInspectionDetailsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.inspectionDetailsStatus = Status.Idle;
                state.errorMessage = "Error Fetching Inspection Details!"
            })

            .addCase(getTaxDetailsAsync.pending, (state, action: PayloadAction<any>) => {
                state.taxDetailsStatus = Status.Loading;
            })
            .addCase(getTaxDetailsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.taxDetailsStatus = Status.Idle;
                state.taxDetails = action.payload;
            })
            .addCase(getTaxDetailsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.taxDetailsStatus = Status.Idle;
                state.errorMessage = "Error Fetching Tax Details!"
            })

            .addCase(getHoaDetailsAsync.pending, (state, action: PayloadAction<any>) => {
                state.hoaDetailsStatus = Status.Loading;
            })
            .addCase(getHoaDetailsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.hoaDetailsStatus = Status.Idle;
                state.hoaDetails = action.payload;
            })
            .addCase(getHoaDetailsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.hoaDetailsStatus = Status.Idle;
                state.errorMessage = "Error Fetching Property Details!"
            })

            .addCase(getCommentListAsync.pending, (state, action: PayloadAction<any>) => {
                state.commentsStatus = Status.Loading;
            })
            .addCase(getCommentListAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.commentsStatus = Status.Idle;
                state.comments = action.payload;
            })
            .addCase(getCommentListAsync.rejected, (state, action: PayloadAction<any>) => {
                state.commentsStatus = Status.Idle;
                state.errorMessage = "Error Fetching Comments!"
            })
            .addCase(getLoanDocumentsAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.documents = action.payload;
            })
            .addCase(getLoanDocumentsAsync.rejected, (state, action: PayloadAction<any>) => {
                state.documents = [];
            })


    }
})
export const { setActiveTab, removeLoanTab, addLoanTab, clearLoanTabs, clearSearchedLoans, setCommentList, setFnmaBillDetails, setTags, setTagDetails, setNoteData, resetTagValues, setTagList, setTagsRefresh,setDocumentsData,setDocumentList } = loanSlice.actions;
export const readLoanState = (state: RootState) => state.loan;
export default loanSlice.reducer;