import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Status } from "../../../constants/commonConstants";
import formatDate from "../../../constants/dateFormat";

import {formatAmount} from "../../../helpers/formatAmount";
import { Loader } from "../../../helpers/loader";
import { getHoaDetailsAsync, readLoanState } from "../../../redux/reducers/loanReducer";

const HoaDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const loanState = useAppSelector(readLoanState);

    useEffect(() => {
        dispatch(getHoaDetailsAsync(props.loan_id));
    }, [])

    return (<>
        {
            loanState.hoaDetailsStatus === Status.Loading ? <Loader /> :
                <div className="row">
                    <div className="col">
                        <div className="row payment-boxes">
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Required HOA</label>
                                    <span>{loanState.hoaDetails?.is_required_hoa || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>HOA Name</label>
                                    <span>{loanState.hoaDetails?.hoa_name || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>HOA Lien Status</label>
                                    <span>{loanState.hoaDetails?.hoa_lien_status || "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Lien Recording Date</label>
                                    <span>{loanState.hoaDetails?.lien_recording_date ? formatDate(String(loanState.hoaDetails?.lien_recording_date)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Lien Filing Date</label>
                                    <span>{loanState.hoaDetails?.lien_filing_date ? formatDate(String(loanState.hoaDetails?.lien_filing_date)) : "---"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Lien Amount</label>
                                    <span>{loanState.hoaDetails?.lien_amount ? formatAmount(Number(loanState.hoaDetails?.lien_amount)) : "---"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
    </>)
}

export default HoaDetails;