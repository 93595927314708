/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

const Toast = ({ msg = "", setMsg = (newMsg) => {}, bootstrapColor="danger", seconds = 4 }) => {
  useEffect(() => {
    if (msg) {
      setTimeout(() => {
        setMsg("");
      }, 1000*seconds);
    }
  }, [msg]);

  return (
    <div
      className={`toast rounded-pill ${msg ? "show" : "hide"}`}
      style={{
        position: "fixed",
        zIndex: 10003,
        width: "350px",
        top: 30,
        left: 70,
      }}
    >
      <div className={`toast-header bg-${bootstrapColor} text-light rounded-pill`}>
        <strong className="mr-auto p-2">{msg}</strong>
        <a
          className="ml-2 mb-1 close"
          onClick={() => setMsg("")}
          style={{ cursor: "pointer" }}
        >
          <span aria-hidden="true">&times;</span>
        </a>
      </div>
    </div>
  );
};

export default Toast;
