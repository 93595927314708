/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { addLoanTab, removeLoanTab, setActiveTab } from "../../redux/reducers/loanReducer";
import Breadcrumb from "../common/Breadcrumb";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import LoanDetail from "./LoanDetail";
import { readAssignmentClaimState, setStep } from "../../redux/reducers/assignmentClaimReducer";

const LoanDetailMaster = (props: any) => {
    const loanState = useAppSelector((state: RootState) => state.loan);
    const appState = useAppSelector(readAppState);
    const loanTabs = loanState.loanTabs;
    const activeTab = loanState.activeTab;
    const [refresh, setRefresh] = useState(true);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const screens = appState.screensAccessible && appState.screensAccessible.length > 0 ? appState.screensAccessible : [];
    const assignmentClaimState = useAppSelector(readAssignmentClaimState);

    useEffect(() => {
        if (!props.nestedComponent) {
            dispatch(setBreadcrumbLocation([{
                screen_name: "Loan Details",
                redirect_url: "/LoanDetails",
                icon_url: "fad fa-user"
            }]))
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem("isRefreshed") === 'true') {
            localStorage.setItem("isRefreshed", 'false');
        }
        window.addEventListener("beforeunload", handleRefresh);
        return () => {
            sessionStorage.removeItem("LoanViewLocationKey");
            window.removeEventListener("beforeunload", handleRefresh);
        };
    }, []);

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData && locationData.loan_id && !loanTabs.includes(locationData.loan_id)) {
            dispatch(addLoanTab(locationData.loan_id));
            dispatch(setActiveTab(locationData.loan_id));
        }
    }, [location])

    useEffect(() => {
        const locationData = location.state as any;
        if (locationData && locationData.loan_id && loanTabs.length === 0) {
            dispatch(addLoanTab(locationData.loan_id));
            dispatch(setActiveTab(locationData.loan_id));
        }
    }, [loanState.loanTabs])


    const handleRefresh = () => {
        localStorage.setItem("isRefreshed", 'true');
    }

    const closeButtonHandle = (tab) => {
        const tabs = loanTabs.filter(loan => loan !== tab);
        if (tabs.length === 0) {
            dispatch(removeLoanTab(tab));
            const location = window.location.pathname.split('/')[1]
            const menu = screens.find(x => x.redirect_url.trim().toLowerCase() === `/${location.trim().toLowerCase()}`);
            let breadcrumbLocation: any[] = [];
            if (menu) {
                breadcrumbLocation.push(menu);
            }
            else {
                if (location === 'VisualView') {
                    breadcrumbLocation = [{
                        "screen_id": "",
                        "screen_name": "Reports",
                        "redirect_url": `/Reports`,
                        "icon_url": "fas fa-people-arrows"
                    }, {
                        "screen_id": "",
                        "screen_name": "Visual View",
                        "redirect_url": "/VisualView",
                        "icon_url": "fas fa-people-arrows"
                    }]
                }
                else {
                    breadcrumbLocation.push({
                        "screen_id": "",
                        "screen_name": location,
                        "redirect_url": `/${location}`,
                        "icon_url": "fas fa-people-arrows"
                    });
                }

            }
            if (!props.nestedComponent) {
                navigate('/DashboardLibrary')
                breadcrumbLocation = [{
                    "screen_id": "",
                    "screen_name": "Dashboard Library",
                    "redirect_url": `/DashboardLibrary`,
                    "icon_url": "fas fa-people-arrows"
                }];
            }
            else if (props.parentScreen === "assignmentClaims") {
                breadcrumbLocation = [{
                    screen_name: assignmentClaimState.documentLevelSearch ? "Document List" : "Loan List ",
                    redirect_url: "/AssignmentClaims",
                    icon_url: "fad fa-user"
                }]
                dispatch(setStep(2));
            }
            else {
                navigate(`/${location}`)
            }
            dispatch(setBreadcrumbLocation(breadcrumbLocation));
        }
        dispatch(removeLoanTab(tab));

    }

    const handleTabSwitch = (tab: any) => {
        if (activeTab !== tab) {
            setRefresh(true)
        }
    }

    return (<>
        {!props.nestedComponent && <div className="pagetitle">
            <div className="row">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
            </div>
        </div>}
        <section className="section dashboard">
            <div className="block_sect p-0">
                <div className="loan_d_tags">
                    <ul className="nav cust_tabs" id="myTab" role="tablist">
                        {
                            loanTabs && loanTabs.map((tab, i) => {
                                return (<li key={i} className={`${activeTab === tab ? "nrefresh_icon" : ""} `}>
                                    <a className={`${activeTab === tab ? "active" : ""} `} onClick={() => { dispatch(setActiveTab(tab)); handleTabSwitch(tab) }}
                                        href={"#tab" + i} data-bs-toggle="tab" aria-selected="true">{tab}</a>
                                    <a className="tab_close_btn " onClick={() => closeButtonHandle(tab)}><span className="fa fa-close"></span></a>
                                    {activeTab === tab && <a className="tab_close_btn t_refresh" onClick={() => setRefresh(!refresh)}><span className="fa fa-refresh"></span></a>}
                                </li>
                                )
                            })
                        }
                    </ul>
                    {activeTab &&
                        <div className="tab-content">
                            <div className={`tab - pane fade ${activeTab !== "loansearch" ? "show active" : ""} `} id="tab1">
                                <LoanDetail activeTab={loanState.activeTab} refresh={refresh} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    </>);
}

export default LoanDetailMaster;