/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, memo } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { Status } from "../../constants/commonConstants";
import { LoanDetailTabs } from "../../constants/loanDetail";
import { formatAmount, formatAsPercent } from "../../helpers/formatAmount";
import { Loader } from "../../helpers/loader";
import { getLoanDetailsAsync, setTags } from "../../redux/reducers/loanReducer";
import Disbursements from "./LoanDetailModules/Disbursements";
import FeesAndCharges from "./LoanDetailModules/FeesAndCharges";
import LoanTransactions from "./LoanDetailModules/LoanTransactions";
import Notes from "./LoanDetailModules/Notes";
import PaymentInformation from "./LoanDetailModules/PaymentInformation";
import Remittence from "./LoanDetailModules/Remittence";
import Workflow from "./LoanDetailModules/Workflow";
import CelinkWorkflow from "./LoanDetailModules/CelinkWorkflow";
import PropertyDetails from "./LoanDetailModules/PropertyDetails";
import CodeViolations from "./LoanDetailModules/CodeViolations";
import LossDraft from "./LoanDetailModules/LossDraft";
import InspectionDetails from "./LoanDetailModules/InspectionDetails";
import TaxDetails from "./LoanDetailModules/TaxDetails";
import HoaDetails from "./LoanDetailModules/HoaDetails";
import { readAppState } from "../../redux/reducers/appReducer";
import Comments from "./LoanDetailModules/Comments";
import Tags from "./LoanDetailModules/Tags";
import { Popover, PopoverBody } from "reactstrap";
import { getLoanTagMappingList } from "../../services/tag.service";
import formatDate from "../../constants/dateFormat";
import Documents from "./LoanDetailModules/Documents";


interface LoanDetailProps {
    refresh: boolean;
    activeTab: string;
}

const LoanDetail = (props: LoanDetailProps) => {
    const loanState = useAppSelector((state: RootState) => state.loan);
    const [tab, setTab] = useState(LoanDetailTabs.LoanTransactions);
    const [showPopover, setShowPopover] = useState(false);
    const [isOpen, toggle] = useState(false);
    const loanDetail = loanState?.loanDetail;
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);

    useEffect(() => {
        if (props.activeTab) {
            getLoanDetails();
        }
        loanTagMapping(props.activeTab);
    }, [props.activeTab, props.refresh]);

    useEffect(() => {
        if (loanState.tags?.refresh) {
            loanTagMapping(props?.activeTab);
        }

    }, [loanState.tags?.refresh]);

    const loanTagMapping = async (loanId: any) => {
        let tagList: any = []
        await getLoanTagMappingList(loanId).then(data => {
            if (data.length > 0) {
                data.forEach((tag: any) => { tagList.push({ tag_name: tag.tag_name, tag_id: tag.tag_id, tag_description: tag.tag_description, created_by: tag.created_by, created_date: tag.created_date, comments: tag.comments, modified_by: tag.modified_by, modified_date: tag.modified_date }) });
                dispatch(setTags({ isTagged: true, tagList, refresh: false }));
            }
            else {
                dispatch(setTags({ isTagged: false, tagList: [], refresh: false }));
            }
        });
    }

    const getLoanDetails = async () => {
        let userGroups = appState.userGroups ? appState.userGroups : [];
        let userGroupId: string = userGroups.find(g => g.group_id === appState.currentUser?.group_id).logi_user_id;
        let data = {
            loanId: props.activeTab,
            groupId: userGroupId
        }
        dispatch(getLoanDetailsAsync(data));
        //props.setRefresh();
        setTab(LoanDetailTabs.LoanTransactions);
    }
    const renderData = () => {
        let data: any = [];
        for (const value in loanState.tags?.tagList) {
            data.push(
                <>
                    <small><b>{loanState.tags?.tagList[value].tag_name}</b></small>
                    <br />
                </>);
        }
        return data;
    }

    const popover = () => {
        return (<>
            <i style={{ marginLeft: "5px", marginTop: "1px" }} data-toggle="buttons" id={"tags1"} className={"fas fa-tags btn-group-toggle"} onMouseOut={() => toggle(false)} onMouseOver={() => toggle(true)} />
            <Popover trigger="legacy" placement="right" isOpen={isOpen} target={"tags1"} toggle={() => toggle(!isOpen)}>
                <PopoverBody>
                    {renderData()}
                </PopoverBody>
            </Popover>
        </>)

    }


    return (
        <>

            {loanState.status === Status.Loading && <Loader />}
            <div className="accordion" id="accordionExample">
                <div className="accordion_card1">
                    <h2 className="accordion_card1_title">
                        <a className="btn" data-bs-toggle="collapse" data-bs-target="#vertacc1" aria-expanded="true">
                            Loan Details   {loanState.tags && loanState.tags.isTagged && popover()}
                        </a>
                    </h2>
                    <div id="vertacc1" className="collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion_card1_body padding15">
                            <div className="row">
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Servicer Loan Number</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.sbs_loan_number || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Loan Number</label>
                                        <span>{props.activeTab}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Ginnie Mae Number</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.gnma_loan_number || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>FHA Case Number</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.fha_case_number || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Investor Loan Number</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.investor_loan_number || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Product Type</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.sbs_product_type_description || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>HUD Insured</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.is_hud_insured || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Securitized</label>
                                        <span>{loanState.status === Status.Loading ? "TBD" : loanDetail?.is_securitized || "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Loan Status</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.sbs_loan_status_description || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Loan Sub-Status</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.sbs_loan_sub_status_description || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Monthly Payment Amount</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.monthly_payment_amount ? formatAmount(Number(loanDetail?.monthly_payment_amount)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Payment Plan Type</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.sbs_payment_plan_description || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>ARM Type</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.arm_type || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Current Interest Rate</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.current_interest_rate? formatAsPercent(Number(loanDetail?.current_interest_rate)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Expected Interest Rate</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.expected_interest_rate ? formatAsPercent(Number(loanDetail?.expected_interest_rate)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Closing Date</label>
                                        <span>{loanState.status === Status.Loading ? "---" :loanDetail?.closing_date ? formatDate(String(loanDetail?.closing_date)) : "---" }</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Funding Date</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.funding_date ? formatDate(String(loanDetail?.funding_date)) : "---" }</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>MIC Date</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.mic_date ? formatDate(String(loanDetail?.mic_date)) :"---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>MERS MIN#</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.mers_min || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>HERMIT Skey</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.hermit_skey || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Active Campaign Type</label>
                                        <span>{loanState.status === Status.Loading ? "TBD" : loanDetail?.active_campaign_type || "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Last Borrower Contact Date</label>
                                        <span>{loanState.status === Status.Loading ? "TBD" : loanDetail?.last_borrower_contact_date || "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Debenture Interest Rate</label>
                                        <span>{loanState.status === Status.Loading ? "---" : loanDetail?.debenture_interest_rate ? formatAsPercent(Number(loanDetail?.debenture_interest_rate)) : "---"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion_card1">
                    <h2 className="accordion_card1_title">
                        <a className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#vertacc2"
                            aria-expanded="false">
                            Borrower Details
                        </a>
                    </h2>
                    <div id="vertacc2" className="collapse" data-bs-parent="#accordionExample">
                        <div className="accordion_card1_body padding15">
                            <div className="row">
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Borrower Name</label>
                                        <span>{loanDetail?.borrower_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Co-Borrower Name </label>
                                        <span>{loanDetail?.coborrower_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Property Address</label>
                                        <span>{loanDetail?.property_address || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Mailing Address</label>
                                        <span>{loanDetail?.mailing_address || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Phone(Home)</label>
                                        <span>{loanDetail?.home_phone || "---"}</span>
                                    </div>
                                </div>

                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Death Date</label>                                        
                                        <span>{loanDetail?.death_date ? formatDate(String(loanDetail?.death_date)) : "---" }</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Borrower Age</label>
                                        <span>{loanDetail?.borrower_age || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Estimated Death Date</label>
                                        <span>{loanDetail?.estimated_death_date || "TBD"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion_card1">
                    <h2 className="accordion_card1_title">
                        <a className="btn" type="button" data-bs-toggle="collapse" data-bs-target="#vertacc3"
                            aria-expanded="false">
                            Servicing and Balance Information
                        </a>
                    </h2>
                    <div id="vertacc3" className="collapse" data-bs-parent="#accordionExample">
                        <div className="accordion_card1_body padding15">
                            <div className="row">
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Servicer Name</label>
                                        <span>{loanDetail?.servicer_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Original Loan Balance</label>
                                        <span>{formatAmount(loanDetail?.original_loan_balance ? Number(loanDetail?.original_loan_balance) : 0) || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Investor Name</label>
                                        <span>{loanDetail?.investor_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Total Funds Available</label>
                                        <span>{formatAmount(loanDetail?.total_funds_available ? Number(loanDetail?.total_funds_available) : 0) || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Investor Pool</label>
                                        <span>{loanDetail?.investor_pool_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Maximum Claim Amount</label>
                                        <span>{loanDetail?.maximum_claim_amount ? formatAmount(Number(loanDetail?.maximum_claim_amount)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>% of Max Claim</label>
                                        <span>{loanDetail?.current_max_claim_percentage || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Loan Balance D&P</label>
                                        <span>{loanDetail?.loan_balance_at_due_and_payable_date ? formatAmount(Number(loanDetail?.loan_balance_at_due_and_payable_date)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Curtailment Event Missed</label>
                                        <span>{loanDetail?.curtailment_event_missed || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Estimated Curtailment Amount</label>
                                        <span>{loanDetail?.estimated_curtailment_amount != null ? formatAmount(Number(loanDetail?.estimated_curtailment_amount)) : "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Loss Reserve Amount</label>
                                        <span>{loanDetail?.loss_reserve_amount ? formatAmount(Number(loanDetail?.loss_reserve_amount)) : "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Sum of specific advances from transaction</label>
                                        <span>{loanDetail?.sum_of_specific_advances ? formatAmount(Number(loanDetail?.sum_of_specific_advances)) : "TBD"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Payoff Quote Date</label>
                                        <span>{loanDetail?.payoff_quote_date || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Payoff Quote Amount</label>
                                        <span>{formatAmount(loanDetail?.payoff_quote_amount ? Number(loanDetail?.payoff_quote_amount) : 0) || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Prior Servicer Name</label>
                                        <span>{loanDetail?.prior_servicer_name || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Prior Servicer Loan Number</label>
                                        <span>{loanDetail?.prior_servicer_loan_number || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Service Transfer Date</label>
                                        <span>{loanDetail?.service_transfer_date || "---"}</span>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-3 col-md-3">
                                    <div className="l_info_block">
                                        <label>Max Claim % at Transfer</label>
                                        <span>{loanDetail?.max_claim_transfer_percent ? formatAmount(Number(loanDetail?.max_claim_transfer_percent)) : "TBD"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="block_sect p-0">
                <div className="loan_d_tags">
                    <ul className="nav cust_tabs" id="myTab" role="tablist">
                        <li><a onClick={() => setTab(LoanDetailTabs.LoanTransactions)} className={tab === LoanDetailTabs.LoanTransactions ? "active" : ""} aria-selected="true">Loan Transactions</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.PaymentInformation)} className={tab === LoanDetailTabs.PaymentInformation ? "active" : ""} aria-selected="false">Payment Information</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.Disbursements)} className={tab === LoanDetailTabs.Disbursements ? "active" : ""} aria-selected="false">Disbursements</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.Notes)} className={tab === LoanDetailTabs.Notes ? "active" : ""} aria-selected="false">Notes</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.Workflow)} className={tab === LoanDetailTabs.Workflow ? "active" : ""} aria-selected="false">Workflow</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.LoanPropertyDetails)} className={tab === LoanDetailTabs.LoanPropertyDetails ? "active" : ""} aria-selected="false">Property Details</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.InspectionDetails)} className={tab === LoanDetailTabs.InspectionDetails ? "active" : ""} aria-selected="false">Inspection Details</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.CodeViolations)} className={tab === LoanDetailTabs.CodeViolations ? "active" : ""} aria-selected="false">Code Violations</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.LossDraft)} className={tab === LoanDetailTabs.LossDraft ? "active" : ""} aria-selected="false">Loss Draft</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.TaxDetails)} className={tab === LoanDetailTabs.TaxDetails ? "active" : ""} aria-selected="false">Tax Details</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.HOADetails)} className={tab === LoanDetailTabs.HOADetails ? "active" : ""} aria-selected="false">HOA Details</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.CommentDetails)} className={tab === LoanDetailTabs.CommentDetails ? "active" : ""} aria-selected="false"> Comments</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.TagDetails)} className={tab === LoanDetailTabs.TagDetails ? "active" : ""} aria-selected="false"> Tags</a></li>
                        <li><a onClick={() => setTab(LoanDetailTabs.Documents)} className={tab === LoanDetailTabs.Documents ? "active" : ""} aria-selected="false"> Documents</a></li>
                    </ul>
                    <div className="tab-content additional-tab">
                        {tab === LoanDetailTabs.LoanTransactions &&
                            <div className={`tab-pane fade p-3 ${tab === LoanDetailTabs.LoanTransactions ? "show active" : ""}`} id="tabs1">
                                <LoanTransactions key={props.activeTab + "T"} loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.PaymentInformation &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.PaymentInformation ? "show active" : ""}`} id="tabs2">
                                <PaymentInformation loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.Disbursements &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.Disbursements ? "show active" : ""}`} id="tabs3">
                                <Disbursements loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.Remittence &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.Remittence ? "show active" : ""}`} id="tabs4">
                                <Remittence />
                            </div>}
                        {tab === LoanDetailTabs.FeeAndCharges &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.FeeAndCharges ? "show active" : ""}`} id="tabs5">
                                <FeesAndCharges />
                            </div>}
                        {tab === LoanDetailTabs.Notes &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.Notes ? "show active" : ""}`} id="tabs6">
                                <Notes loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.Workflow &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.Workflow ? "show active" : ""}`} id="tabs7">
                                {loanDetail?.servicer_name.toLowerCase() === 'celink' ?
                                 <CelinkWorkflow loan_id={props.activeTab} /> :
                                 <Workflow loan_id={props.activeTab} />
                                }
                            </div>}

                        {tab === LoanDetailTabs.LoanPropertyDetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.LoanPropertyDetails ? "show active" : ""}`} id="tabs8">
                                <PropertyDetails loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.InspectionDetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.InspectionDetails ? "show active" : ""}`} id="tabs9">
                                <InspectionDetails loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.CodeViolations &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.CodeViolations ? "show active" : ""}`} id="tabs10">
                                <CodeViolations loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.LossDraft &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.LossDraft ? "show active" : ""}`} id="tabs11">
                                <LossDraft loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.TaxDetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.TaxDetails ? "show active" : ""}`} id="tabs12">
                                <TaxDetails loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.HOADetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.HOADetails ? "show active" : ""}`} id="tabs13">
                                <HoaDetails loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.CommentDetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.CommentDetails ? "show active" : ""}`} id="tabs14">
                                <Comments loan_id={props.activeTab} />
                            </div>}

                        {tab === LoanDetailTabs.TagDetails &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.TagDetails ? "show active" : ""}`} id="tabs15">
                                <Tags loan_id={props.activeTab} />
                            </div>}
                        {tab === LoanDetailTabs.Documents &&
                            <div className={`tab-pane p-3 fade ${tab === LoanDetailTabs.Documents ? "show active" : ""}`} id="tabs16">
                                {<Documents loan_skey={loanDetail?.sbs_loan_number}  loan_id={props.activeTab} />}
                            </div>}

                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(LoanDetail);