/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Select from "react-select";
import { setFilterKeys } from "../../redux/reducers/appReducer";
import ReactSelect from "react-select";
import useStateList from "../../helpers/stateList";
import Toast from "../StaticComponents/Toast";
import { clearSearchedLoans, setTags } from "../../redux/reducers/loanReducer";
import { selectCustomStyles } from "../common/CustomStyles";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LoanSearchResult from "./LoanSearchResult";
import { getTagList } from "../../services/tag.service";
import { readLoanState } from "../../redux/reducers/loanReducer";

const QuickLoanSearch = () => {
    const initialSearchParams = { loan_number: null, borrower_name: null, property_state: null, property_city: null, investor_id: null, property_zip: null, search_key: null, tag_name: null }
    const dispatch = useAppDispatch();
    const [tagName, setTagName] = useState<any>([]);
    const [tagList, setTagList] = useState<any>([]);
    const [wordEntered, setWordEntered] = useState<any>("");
    const [propertyState, setPropertyState] = useState<any>({});
    const [errMsg, setErrMsg] = useState<any>("");
    const [search, setSearch] = useState({ ...initialSearchParams })
    const searchRef = useRef(search as any);
    const [loadScreen, setLoadScreen] = useState();
    const [error, setError] = useState<any>({ loan_number: "", property_zip: "" });
    const [searchResult, toggleSearchResult] = useState(false);
    const loanState = useAppSelector(readLoanState);

    useEffect(() => {
        getTagListFunction()

    }, [])

    const getTagListFunction = async () => {
        await getTagList().then(response => {
            if (response && response.length > 0) {
                let tags: any[] = [];
                response.forEach((x: any) => {
                    tags.push({
                        label: x.tag_name,
                        value: x.tag_id
                    })
                })
                setTagList(tags)
            }
        })
    }

    const handelInputChange = (e, key) => {
        let newSearch = { ...search }
        if (key !== 'search_key') {
            newSearch['search_key'] = null;
        }
        let value;
        switch (key) {
            case 'property_state': {
                value = e?.value;
                break;
            }
            case 'property_zip': {
                value = e.target.value.slice(0, 6);
                setError({ ...error, property_zip: value.length < 5 && value.length !== 0 ? "Please enter correct Postal Code" : "" });
                break;
            }
            case 'loan_number': {
                value = e.target.value.slice(0, 10);
                setError({ ...error, loan_number: value.length < 10 && value.length !== 0 ? "Please enter correct Loan Number" : "" });
                break;
            }
            case 'tag_name': {
                let tags: any = []
                if (e.length > 0) {
                    e.forEach(item => tags.push("'" + item.label + "'"));
                    tags = tags.join(',');
                    tags = "(" + tags + ")"
                }
                value = tags
                break;
            }
            default: value = e.target.value === "" ? null : e.target.value;
        }

        newSearch[key] = value;
        setSearch(newSearch);
        const entries = Object.entries(newSearch);
        const nonEmptyOrNull = entries.filter(([key, val]) => val !== undefined && val !== null)
        const output = Object.fromEntries(nonEmptyOrNull);
        searchRef.current = { ...output };

    }

    const handelSearchSubmit = () => {
        dispatch(clearSearchedLoans());
        let searchInput = {};
        for (var key in searchRef.current) {
            searchInput[key] = searchRef.current[key] ? searchRef.current[key].trim() : searchRef.current[key]
        }
        dispatch(setFilterKeys({ ...searchInput }))
        toggleSearchResult(!searchResult);
        setWordEntered("");
        setSearch({ ...initialSearchParams });
        searchRef.current = { ...initialSearchParams };
        setPropertyState(null);
        setTagName([]);
    }

    const closeDropDown = () => {
        const dropDownElement = document.getElementById('loandsearchdropdown');
        if (dropDownElement) {
            dropDownElement.click();
        }
    }

    const onCancel = () => {
        closeDropDown();
        setSearch(initialSearchParams);
        setPropertyState({});
        setTagName([]);
        searchRef.current = initialSearchParams;
    }
    const handleTagChange = (e: any) => {
        setTagName(e)
    }
    const onToggle = () => {
        dispatch(setTags({ isTagged: loanState.tags?.isTagged, tagList: loanState.tags?.tagList, refresh: true }));
        return toggleSearchResult(!searchResult)
    }

    return (
        <>
            <div className=" float-r col col-lg-7 col-md-7 col-sm-7 col-xs-7">
                <div className="dropdown dropdown-menu-end">
                    <form className=" form-inline header_searchbox justify-content-end text-center " style={{ flexWrap: "nowrap", display: "flex" }}>
                        <input className="mb15 form-control rounded-pill loan-search-input" type="search" placeholder="Loan Search" aria-label="Search"
                            value={wordEntered} onChange={(e) => { setWordEntered(e.target.value); handelInputChange(e, "search_key"); }}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {
                                    ev.preventDefault();
                                    handelSearchSubmit();
                                }
                            }} />
                        <div style={{ marginRight: "9px", position: "absolute", alignItems: "center", display: "flex" }}>
                            <div className="payment-transaction dropdown dropdown-menu-end">
                                <a className=" float-end" role="button" id="loandsearchdropdown" aria-hidden="true"
                                    data-bs-toggle="dropdown" aria-expanded="false" style={{ marginTop: "6px" }} data-bs-auto-close="outside" onClick={getTagListFunction} ></a>
                                <div className="dropdown-menu search_dropdown dropdown_arrow" aria-labelledby="loandsearchdropdown">
                                    <div className="">
                                        <h6><strong>Loan Level Search</strong></h6>
                                        <div className="form-floating ct_form_field mb-1 mt-0">
                                            <input type="number" className="form-control" id="floatingInput" placeholder="Loan Number"
                                                value={search.loan_number || ""}
                                                onChange={(e) => { handelInputChange(e, "loan_number") }} />
                                            <label htmlFor="floatingInput">Loan Number</label>
                                            {error.loan_number && <span className="danger">{error.loan_number}</span>}
                                        </div>
                                        <div className="form-floating ct_form_field mb-1 mt-0">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Borrower Name"
                                                value={search.borrower_name || ""} onChange={(e) => { handelInputChange(e, "borrower_name") }} />
                                            <label htmlFor="floatingInput">Borrower Name</label>
                                        </div>
                                        <div className="form-floating multi-select-field ct_form_field mb-1 mt-0  loan_search">
                                            <ReactSelect isClearable={true} options={useStateList().map((stateArr) => {
                                                return (
                                                    {
                                                        value: stateArr[0],
                                                        label: stateArr[1]
                                                    }
                                                )
                                            })}
                                                className="form-control"
                                                classNamePrefix="multi-select-dropdown"
                                                styles={Object.assign({ menuPortal: base => ({ ...base, zIndex: 9999, height: 50 }) }, selectCustomStyles)}
                                                placeholder={""}
                                                value={propertyState} onChange={(e) => { handelInputChange(e, "property_state"); setPropertyState(e) }} />
                                            <label htmlFor="floatingInput">Property State</label>
                                        </div>
                                        <div className="form-floating ct_form_field mb-1 mt-0">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Property City"
                                                value={search.property_city || ""} onChange={(e) => { handelInputChange(e, "property_city") }} />
                                            <label htmlFor="floatingInput">Property City</label>
                                        </div>
                                        <div className="form-floating ct_form_field mb-1 mt-0">
                                            <input type="number" className="form-control" id="floatingInput" placeholder="Property Zip Code"
                                                value={search.property_zip || ""}
                                                onChange={(e) => { handelInputChange(e, "property_zip") }} />
                                            <label htmlFor="floatingInput">Property Zip Code</label>
                                            {error.property_zip && <span className="danger">{error.property_zip}</span>}
                                        </div>
                                        <div className="form-floating ct_form_field mb-1 mt-0">
                                            <input type="text" className="form-control" id="floatingInput" placeholder="Investor ID"
                                                value={search.investor_id || ""} onChange={(e) => { handelInputChange(e, "investor_id") }} />
                                            <label htmlFor="floatingInput">Investor ID</label>
                                        </div>
                                        <div className="form-floating multi-select-field ct_form_field select_dd">
                                            <Select isClearable={true}
                                                isMulti={true}
                                                styles={selectCustomStyles}
                                                className="form-control  multi-select-dd"
                                                id="commentType"
                                                options={tagList}
                                                value={tagName}
                                                menuPlacement="auto"
                                                maxMenuHeight={150}
                                                onChange={(e) => { handleTagChange(e); handelInputChange(e, "tag_name"); }}
                                                placeholder="Select Tags..."
                                            />
                                            <label htmlFor="commentType">Tags</label>
                                        </div>
                                        <div className="row quick-search-btn  mb-0 mt-2 justify_center">
                                            <div className="col-lg-5 col-md-5  col-sm-6 mb-2">
                                                <button type="button" onClick={() => { handelSearchSubmit(); closeDropDown(); }}
                                                    className={`btn btn-primary w-100 text-center ${(error.loan_number || error.property_zip) ? "disabled" : ""}`} >
                                                    Search
                                                </button>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-6">
                                                <button type="button" onClick={() => onCancel()} className="btn btn-outline-primary w-100 text-center">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <Toast msg={errMsg} setMsg={setErrMsg} />
                                </div>


                            </div>
                            <div className="payment-transaction ">
                                <button className="btn search_bar search-btn-ls" type="button" onClick={() => { handelSearchSubmit(); }}><span className="fa fa-search "></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal size="lg" isOpen={searchResult} toggle={() => onToggle()}>
                <ModalHeader toggle={() => onToggle()}>Loan Search Result</ModalHeader>
                <ModalBody>
                    <LoanSearchResult globalSearchResult={true} closeSearchResult={() => { toggleSearchResult(!searchResult); }} />
                </ModalBody>
            </Modal>
        </>
    );
}

export default QuickLoanSearch;