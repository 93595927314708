
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { severityOptions } from "../../../constants/BusinessRuleConstants";
import { Loader } from "../../../helpers/loader";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import { CreateRuleGroupAsync, CreateRuleGroupMappingAsync, deleteRuleGroupMappingAsync, getRuleGroupMappingListAsync, getRuleListAsync, readRuleState, UpdateInputOutputTemplateMappingAsync, updateRuleGroupAsync } from "../../../redux/reducers/ruleReducer";
import Breadcrumb from "../../common/Breadcrumb";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { selectCustomStyles } from "../../common/CustomStyles";
import FormError from "../../common/FormError";
import GridDropDownCell from "./GridDropDownCell";
const RuleGroupForm = () => {
    const navigation = useNavigate();
    const ruleState = useAppSelector(readRuleState);
    const [ruleGroupDetail, setRuleGroupDetail] = useState({
        rule_group_id: ruleState.rule_group ? ruleState.rule_group?.rule_group_id : "",
        rule_group_name: ruleState.rule_group ? ruleState.rule_group?.rule_group_name : "",
        rule_list_with_severity: [] as any[],
        post_process_function: ruleState.rule_group ? ruleState.rule_group?.post_process_function : ""
    });
    const [ruleList, setRuleList] = useState<any>(null);
    const [rulesListCopy, setRuleListCopy] = useState<any>({
        rule_group_id: ruleState.rule_group ? ruleState.rule_group?.rule_group_id : "",
        rule_group_name: ruleState.rule_group ? ruleState.rule_group?.rule_group_name : "",
        rule_list_with_severity: [] as any[],
        post_process_function: ruleState.rule_group ? ruleState.rule_group?.post_process_function : ""
    });
    const [filteredRuleList, setFilteredRuleList] = useState<any>(null);
    const appState = useAppSelector(readAppState);
    const [loader, setLoader] = useState(true);
    const { register, handleSubmit, formState: { errors, isDirty, isValid }, trigger, getValues } = useForm({
        mode: "onChange", defaultValues: {
            rule_group_name: ruleState.rule_group ? ruleState.rule_group?.rule_group_name : "",
            post_process_function: ruleState.rule_group ? ruleState.rule_group?.post_process_function : "",
        }
    });
    const [error, setError] = useState({ errors: { cognito: null } });
    const dispatch = useAppDispatch();
    const [submit, setSubmit] = useState(false);
    const [isDisablePostProcessfuntion, setIsDisablePostProcessfunction] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, primaryButtonName: null as any, primaryButtonAction: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [formChange, setFormChange] = useState(true);
    const [isTableChange, setIsTableChange] = useState(true);

    useEffect(() => {
        if (!isDirty && ruleGroupDetail.rule_list_with_severity.length >= 0) {
            if (ruleGroupDetail.rule_list_with_severity.length !== rulesListCopy.rule_list_with_severity.length) {
                setIsTableChange(false);
            } else {
                const areEqual = deepCompareArrays(ruleGroupDetail.rule_list_with_severity, rulesListCopy.rule_list_with_severity);
                setIsTableChange(areEqual);
            }
        } else {
            setIsTableChange(true);
        }

    }, [ruleGroupDetail.rule_list_with_severity])

    function deepCompareArrays(arr1, arr2) {
        let result = JSON.stringify(arr1) === JSON.stringify(arr2);
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i].ruleProperty1.value !== arr2[i].ruleProperty1.value) {
                return false;
            }
        }
        return result;
    }

    useEffect(() => {
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/RuleGroup")
        );
        ruleState.rule_group?.is_new ?
            breadcrumbLocation.push({
                screen_name: "Add Rule Group",
                redirect_url: "/RuleGroupManagement",
                icon_url: "fad fa-toolbox"
            }) : breadcrumbLocation.push({
                screen_name: "Edit Rule Group",
                redirect_url: "/RuleGroupManagement",
                icon_url: "fad fa-toolbox"
            });

        dispatch(setBreadcrumbLocation(breadcrumbLocation));
        if (ruleState.rule_group?.is_new) {
            dispatch(getRuleListAsync()).then((response: any) => {
                setLoader(false);
                if (response && response.payload && response.payload.length > 0) {
                    const options = response.payload.map(x => {
                        return {
                            label: x.rule_description,
                            value: x.rule_id
                        }
                    })
                    setRuleList(options)
                    setFilteredRuleList(options);
                }
            }).catch(ex => console.log(ex));
        }
        else {
            Promise.all([dispatch(getRuleGroupMappingListAsync(ruleState.rule_group!.rule_group_id)), dispatch(getRuleListAsync())]).then((responseList: any) => {
                setLoader(false);
                if (responseList && responseList[0].payload && responseList[0].payload.length > 0 && responseList[1] && responseList[1].payload) {
                    let selectedRuleList: any[] = [];
                    responseList[0].payload.forEach(x => {
                        const rule = responseList[1].payload.find(y => y.rule_id.toString() === x.rule_id);
                        if (rule) {
                            selectedRuleList.push({
                                ruleId: rule.rule_id,
                                ruleName: rule.rule_description,
                                ruleProperty1: severityOptions.find(severity => x.rule_property1 && severity.value.toLowerCase() === x.rule_property1.toLowerCase())
                            });
                        }
                    })
                    const RuleListDeepCopy = JSON.parse(JSON.stringify({ ...ruleGroupDetail, rule_list_with_severity: selectedRuleList }))
                    setRuleListCopy(RuleListDeepCopy)
                    setRuleGroupDetail({ ...ruleGroupDetail, rule_list_with_severity: selectedRuleList });
                }
                if (responseList[1] && responseList[1].payload) {
                    var exceptedRuleList = responseList[1].payload.filter(function (cv) {
                        return !responseList[0].payload.find(function (e) {
                            return e.rule_id == cv.rule_id;
                        });
                    });
                    const options = exceptedRuleList.map(x => {
                        return {
                            label: x.rule_description,
                            value: x.rule_id
                        }
                    })
                    setRuleList(options)
                    setFilteredRuleList(options)
                }
            })
        }
    }, []);

    const addRuleNote = () => {
        if (ruleGroupDetail.rule_list_with_severity.length === 1 && ruleGroupDetail.post_process_function) {
            return <div className='d-flex flex-column mt-2 rule_group_validation'>
                <h4 className='mx-2'>Note :</h4>
                <div className='d-flex flex-row align-item-center'>
                    <p className='align-self-start'>*</p>
                    <p> As you have provided Post Processing Function Name you can select only one Rule and this Rule Group will be applied to the Investor Grouping module only.</p>
                </div>
            </div>
        } else if (ruleGroupDetail.rule_list_with_severity.length === 1 && !ruleGroupDetail.post_process_function) {
            return <div className='d-flex flex-column mt-2 rule_group_validation'>
                <h4 className='mx-2'>Note :</h4>
                <div className='d-flex flex-row align-item-center'>
                    <p className='align-self-start'>*</p>
                    <p>As you have not provided any Post Processing Function Name the selected Rule will be applied to the Data Loading Module.</p>
                </div>
            </div>
        } else if (ruleGroupDetail.rule_list_with_severity.length > 1) {
            return <div className='d-flex flex-column mt-2 rule_group_validation'>
                <h4 className='mx-2'>Note :</h4>
                <div className='d-flex flex-row  align-item-center'>
                    <p className='align-self-start'>*</p>
                    <p>As you have selected more than one Rule the Post Processing Function Name is disabled and this Rule Group will be applied only to the Data loading Module.</p>
                </div>
            </div>
        }
    }
    const isOptionDisabled = () => {
        if (ruleGroupDetail.rule_list_with_severity.length == 1 && ruleGroupDetail.post_process_function) {
            return true
        }
        return false

    }
    const handleSubmitForm = async () => {
        try {
            if (ruleState.rule_group?.is_new) {
                if (!ruleList && ruleList.length === 0) {
                    setSubmit(true);
                    return;
                }
                clearErrorState();
                setLoader(true);
                const ruleGroupData = {
                    rule_group_name: ruleGroupDetail.rule_group_name,
                    post_process_function: ruleGroupDetail.post_process_function || ""
                };
                dispatch(CreateRuleGroupAsync(ruleGroupData)).then((data: any) => {
                    if (data.payload && data.payload.rule_group_id) {
                        let insertValues = "";
                        ruleGroupDetail.rule_list_with_severity.forEach((x, i) => {
                            insertValues = insertValues + `${i > 0 ? "," : ""} (${data.payload.rule_group_id}, ${x.ruleId}, '${x.ruleProperty1?.value || ""}')`;
                        });
                        if (insertValues && insertValues.length > 0) {
                            insertValues = insertValues + ";";
                        }
                        insertValues.replaceAll(/"/g, '\\"').replaceAll("'", "''");
                        dispatch(CreateRuleGroupMappingAsync({ rule_group_id: data.payload.rule_group_id, rule_group_mapping_values: insertValues }));
                        dispatch(UpdateInputOutputTemplateMappingAsync({ rule_group_id: data.payload.rule_group_id, rule_id: ruleGroupDetail.rule_list_with_severity[0].ruleId }));
                        setPopup({ ...popup, visible: true, message: "This rule group has been added. ", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); navigation("/RuleGroup") } });
                    }
                }).catch((ex) => {
                    setLoader(false);
                    setPopup({ ...popup, visible: true, message: "Rule group failed to save.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }); navigation("/RuleGroup") } });
                })
            }
            else {
                setLoader(true);
                const ruleGroupData = {
                    rule_group_id: ruleGroupDetail.rule_group_id,
                    rule_group_name: ruleGroupDetail.rule_group_name,
                    modified_by: appState.currentUser!.maas_user_id,
                    modified_date: new Date().toUTCString(),
                    post_process_function: ruleGroupDetail.post_process_function || ""
                };
                dispatch(updateRuleGroupAsync(ruleGroupData)).then((data: any) => {
                    if (data.payload) {
                        dispatch(deleteRuleGroupMappingAsync({ rule_group_id: ruleGroupDetail.rule_group_id })).then(() => {
                            let insertValues = "";
                            ruleGroupDetail.rule_list_with_severity.forEach((x, i) => {
                                insertValues = insertValues + `${i > 0 ? "," : ""} (${data.payload.rule_group_id}, ${x.ruleId}, '${x.ruleProperty1.value}')`;
                            });
                            if (insertValues && insertValues.length > 0) {
                                insertValues = insertValues + ";";
                            }
                            insertValues.replaceAll(/"/g, '\\"').replaceAll("'", "''")
                            dispatch(CreateRuleGroupMappingAsync({ rule_group_id: ruleGroupDetail.rule_group_id, rule_group_mapping_values: insertValues }));
                            setPopup({ ...popup, visible: true, message: "Your edits/updates are complete.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { setPopup({ ...defaultPopup }); navigation("/RuleGroup") } });
                            setPopup({ ...popup, visible: true, message: "Your edits/updates are complete.", type: ModalType.Snackbar, variant: "success" });

                        }).catch(() => {
                            setPopup({ ...popup, visible: true, message: "Rule group and rule mapping failed.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                            setLoader(false);
                        })
                    }
                })
                    .catch(() => {
                        setLoader(false);
                        setPopup({ ...popup, visible: true, message: "Failed to update rule group.", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
                    })
            }
        } catch (error: any) {
            setLoader(false);
            setPopup({ ...popup, visible: true, message: `Rule group failed to ${ruleState.rule_group?.is_new ? 'Save' : 'Update'}.`, type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
            let err = !error.message ? { "message": error } : error;
            console.log(error)
            setError({
                errors: {
                    ...error,
                    cognito: err
                }
            })
        }
    }
    const clearErrorState = () => {
        setError(
            {
                errors: {
                    cognito: null,
                }
            });
    };
    const submitButton = () => {
        if (ruleGroupDetail.rule_list_with_severity.length > 0 && (ruleState.rule_group?.is_new ? !ruleState!.rule_group_list!.find(x => x.rule_group_name === ruleGroupDetail.rule_group_name) : !ruleState!.rule_group_list!.find(x => x.rule_group_name === ruleGroupDetail.rule_group_name && x.rule_group_id !== ruleState.rule_group?.rule_group_id) || ruleGroupDetail?.post_process_function !== ruleState.rule_group?.post_process_function)) {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled={!isDirty ? isTableChange : !isDirty} >Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled >Save</button>)
        }
    }
    const manageRuleValidations = (e) => {
        const updatedList = [...ruleGroupDetail.rule_list_with_severity];
        updatedList.push({ ruleId: e.value, ruleName: e.label, ruleProperty1: severityOptions.find(x => x.label === "Low") });
        setFilteredRuleList(filteredRuleList.filter(item => item.value !== e.value));
        setRuleGroupDetail({ ...ruleGroupDetail, rule_list_with_severity: updatedList });
    }
    const handleSelectChange = (e) => {
        if (ruleGroupDetail.rule_list_with_severity.length === 0) {
            manageRuleValidations(e)
        } else {
            if (!ruleGroupDetail.post_process_function) {
                setIsDisablePostProcessfunction(true)
                manageRuleValidations(e)
            }
        }
        setFormChange(false);

    }
    const viewportHandler = (event, gridApi) => {
        // NOTE: This doesn't actually work at time of writing
        if (gridApi && gridApi.api && gridApi.api.sizeColumnsToFit) {
            setTimeout(() => {
                gridApi.api.sizeColumnsToFit();
            }, 500)
        }
    }

    const onGridReady = async (params: any) => {
        if (window && window.visualViewport) {
            window.visualViewport.addEventListener("resize", (e) => viewportHandler(e, params));
        }
        params.api.sizeColumnsToFit();
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const frameworkComponents = {
        gridDropDownCell: GridDropDownCell
    }

    const handleDelete = (params) => {
        const filterSaveData = ruleGroupDetail.rule_list_with_severity.filter(rule => rule.ruleId !== params.data.ruleId)
        if (filterSaveData.length <= 1) {
            setIsDisablePostProcessfunction(false)
        }
        setRuleGroupDetail({ ...ruleGroupDetail, rule_list_with_severity: filterSaveData });

        let filteredOptions = filteredRuleList;
        const rule = ruleList.find(x => x.value === params.data.ruleId);
        if (rule) {
            filteredOptions.push(rule)
        } else {
            filteredOptions.push({ 'label': params.data.ruleName, 'value': params.data.ruleId })
        }
        setFilteredRuleList(filteredOptions);
        setFormChange(false);
    }

    const onSelectChange = (e: any) => {
        const updatedList = ruleGroupDetail.rule_list_with_severity.map(x => {
            if (x.ruleId === e.ruleId) {
                x.ruleProperty1 = e.ruleProperty1;
            }
            return x;
        })
        setRuleGroupDetail({ ...ruleGroupDetail, rule_list_with_severity: updatedList });
    }

    const columns: any[] = [
        { headerName: 'Rule ID', field: 'ruleId', hide: true },
        { headerName: 'Rule', field: 'ruleName', headerTooltip: 'Rule', maxWidth: 1000 },
        { headerName: 'Priority', field: 'ruleProperty1', maxWidth: 250, left: 300, cellRenderer: 'gridDropDownCell', cellRendererParams: { onSelectChange: onSelectChange, value: 'ruleProperty1' }, cellClass: "selectable_Column" },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 90, suppressSizeToFit: true, cellRendererFramework: (params) => <div className="row">
                <a title={"Delete"} onClick={() => handleDelete(params)} className="delete_icon_link"><span className="fas fa-trash-alt"></span></a>
            </div>
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: false, filter: true
    }

    return (
        <>  <div className="pagetitle"><Breadcrumb /></div>
            {loader && <Loader />}
            {popup.visible && <CustomPopupComponent isOpen={popup.visible} toggle={popup.toggle} message={popup.message} type={popup.type} variant={popup.variant} />}
            <section className="section dashboard">
                <div className="block_sect">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className="row">
                            <FormError formerrors={error} />
                              <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="ruleGroupName"
                                        placeholder="Rule Group Name"
                                        value={ruleGroupDetail.rule_group_name}
                                        autoComplete="off"
                                        {...register("rule_group_name", {
                                            required: true, onChange: (e) => setRuleGroupDetail({ ...ruleGroupDetail, rule_group_name: e.target.value })
                                        })}
                                    />
                                    {errors?.rule_group_name?.type === "required" && <p style={{ color: 'red' }}>Please enter Rule Group Name</p>}
                                    {ruleState && ruleState.rule_group?.is_new && ruleState!.rule_group_list && ruleState!.rule_group_list.find(x => x.rule_group_name === ruleGroupDetail.rule_group_name) && <p style={{ color: 'red' }}>Rule group name already exists!</p>}
                                    <label htmlFor="ruleGroupName">Rule Group Name</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating multi-select-field ct_form_field select_dd">
                                    <Select
                                        isSearchable={true}
                                        isOptionDisabled={isOptionDisabled}
                                        isClearable={true}
                                        styles={selectCustomStyles}
                                        className="Select-option form-control multi-select-dd"
                                        id="rule"
                                        value={null}
                                        options={filteredRuleList}
                                        onChange={(e) => handleSelectChange(e)}
                                        placeholder="Select Rule..."
                                    />
                                    {submit && (!ruleGroupDetail.rule_list_with_severity || ruleGroupDetail.rule_list_with_severity.length === 0) && (<p style={{ color: 'red' }}> Please Select Rule</p>)}
                                    <label htmlFor="rule">Rule</label>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-floating ct_form_field">
                                    <input type="text" className="form-control"
                                        id="postProcessFunction"
                                        disabled={ruleState.rule_group?.is_new ? isDisablePostProcessfuntion : ruleState.rule_group?.is_new === false && ruleGroupDetail.rule_list_with_severity.length > 1 ? true : false}
                                        placeholder="Function Name"
                                        value={ruleGroupDetail.post_process_function}
                                        autoComplete="off"
                                        {...register("post_process_function", {
                                            required: false, onChange: (e) => setRuleGroupDetail({ ...ruleGroupDetail, post_process_function: e.target.value })
                                        })}
                                    />
                                    <label htmlFor="postProcessFunction">Post Processing Function Name</label>
                                </div>
                            </div>
                        </div>

                        {ruleGroupDetail.rule_list_with_severity.length > 0 && <div className="ag-theme-alpine" style={{ height: '37vh', width: '100%' }}>
                            <AgGridReact
                                frameworkComponents={frameworkComponents}
                                columnDefs={columns}
                                rowData={ruleGroupDetail.rule_list_with_severity.length > 0 ? ruleGroupDetail.rule_list_with_severity : undefined}
                                rowSelection={'single'}
                                defaultColDef={defaultColDef}
                                paginationPageSize={10}
                                onGridReady={onGridReady}
                                onFilterChanged={onFilterChanged}
                                overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                                modules={[ClientSideRowModelModule]}>
                            </AgGridReact>
                        </div>
                        }
                        {
                            addRuleNote()

                        }
                        <div className="row justify-content-md-center mt-4">
                            <div className="col-lg-2 col">
                                {submitButton()}</div>
                            <div className="col-lg-2 col"><button className="btn btn-outline-primary text-center btn-block" type="submit" onClick={() => navigation('/RuleGroup')}>
                                Cancel</button></div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
export default memo(RuleGroupForm);
