import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { DocumentState } from '../stores/documentStore';

const initialState: DocumentState= {
   doc_information:undefined
};

export const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
       
        setDocInformation:(state: DocumentState, action: PayloadAction<any>)=>{
            state.doc_information=action.payload;
        },
        resetDocumentState: (state: DocumentState) => {
            state.doc_information = undefined;
        }
    }   

})
export const { setDocInformation,resetDocumentState} = documentSlice.actions;


export const readDocumentState = (state: RootState) => state.documents;
export default documentSlice.reducer;