import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { TransformerLogsState } from '../stores/transformerLogsStore';

const initialState: TransformerLogsState= {
   file_information:undefined
};

export const transformerLogsSlice = createSlice({
    name: 'transformerLogs',
    initialState,
    reducers: {
       
        setFileInformation:(state: TransformerLogsState, action: PayloadAction<any>)=>{
            state.file_information=action.payload;
        },
        resetTransformerLogState: (state: TransformerLogsState) => {
            state.file_information = undefined;
        }
    }   

})
export const { setFileInformation,resetTransformerLogState} = transformerLogsSlice.actions;


export const readTransformerLogsState = (state: RootState) => state.transformerLogs;
export default transformerLogsSlice.reducer;