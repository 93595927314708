import { API, graphqlOperation } from "aws-amplify";
import {  getLoanTransactionByLoanIdAssetMgmt } from '../graphql/queries';


export const getLoanTransaction = async (loan_id: string, from_date: string, to_date: string) => {

  let getLoanTransaction: any;
  try {

    getLoanTransaction = await API.graphql(graphqlOperation(getLoanTransactionByLoanIdAssetMgmt, { loan_id: loan_id, from_date: from_date, to_date: to_date }));
    let loanTransaction = JSON.parse(getLoanTransaction.data.getLoanTransactionByLoanIdAssetMgmt);
    loanTransaction=JSON.parse(loanTransaction[0].fn_get_loan_transaction_by_loan_id)
    return loanTransaction

  } catch (ex) {
    console.log(ex);
  }
}