/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import CustomPopupComponent, { ModalType, VariantType, } from "../common/CustomPopupComponent";
import { readAppState, setCurrentUser } from "../../redux/reducers/appReducer";
import { RootState } from "../../app/store";
import { useNavigate } from "react-router";
import { Loader } from "../../helpers/loader";
import { updateUserData } from "../../services/user.service";
import useCountryList from "../../constants/userGroups";

const PhoneNumber = (props: any) => {
  const appState = useAppSelector(readAppState);
  const userState = useAppSelector((state: RootState) => state.app.currentUser);
  const numData: any = userState?.phone_number;
  let number = numData.length > 10 ? numData.slice(numData.length - 10, numData.length) : numData;
  const [phoneNumber, setPhoneNumber] = useState(number);
  // const num: any = userState?.phone_number;
  // const resetNum: any = num?.substring(num.length - 10);
  // const resetCode: any = num?.substring(0, num.length - 10);
  // const [phoneNumber, setPhoneNumber] = useState(resetNum);
  // let countryCodeValue = useCountryList().filter((i) => i[0] === resetCode)[0];
  // const [countryCode, setCountryCode] = useState<any>({
  //   label:countryCodeValue? countryCodeValue[1]:null,
  //   value: countryCodeValue? countryCodeValue[0]:null,
  // });
  const [validNumber, setValidNumber] = useState<any>(number.length === 10 ? true : false);
  const navigation = useNavigate();
  const [loader, setLoader] = useState(false);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const dispatch = useAppDispatch();

  const isDisabled = () => {
    // let disabled = !phoneNumber || !countryCode || phoneNumber.length < 10;
    let disabled = !phoneNumber || phoneNumber.length < 10;

    if (number === phoneNumber) {
      return true
    }
    return disabled;
  };

  const handleSubmit = async (event: any) => {
    // with country code
    // let number = countryCode?.value + phoneNumber;
    let number = phoneNumber;
    setLoader(true);
    try {
      // const user = await Auth.currentAuthenticatedUser();
      // await Auth.updateUserAttributes(user, {
      //   phone_number: number,
      // });
      await updateUserData(
        "phone_number",
        number,
        appState?.currentUser?.user_id
      );
      setPopup({
        ...popup,
        visible: true,
        message: "Your contact detail has been changed successfully!",
        type: ModalType.Snackbar,
        variant: VariantType.Success,
        toggle: () => {
          setPopup({ ...defaultPopup });
        },
      });
      dispatch(
        setCurrentUser({ ...appState.currentUser, phone_number: number })
      );
      setPhoneNumber(number)
      setValidNumber(number.length === 10 ? true : false)
      setTimeout(() => {
        navigation("/MyProfileAndSettings");
      }, 3000);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
    }
  };

  const handleReset = () => {
    setPhoneNumber(number);
    setValidNumber(number.length === 10 ? true : false)
    // setCountryCode({ label: countryCodeValue[1], value: countryCodeValue[0] });
  };

  const blockInvalidChar = (e) =>
    ["e", "E", "-"].includes(e.key) && e.preventDefault();

  return (
    <>
      {popup.visible && (
        <CustomPopupComponent
          isOpen={popup.visible}
          toggle={popup.toggle}
          message={popup.message}
          type={ModalType.Snackbar}
          variant={popup.variant}
        />
      )}
      {loader && <Loader />}
      <h3 className="profile_edit_title">
        Contact Detail
        <a className="p_edit_icon" data-bs-toggle="collapse" data-bs-target="#vertacc4" aria-expanded="true">
          <span className="fa fa-pen-square" style={{ color: "#006b7f", cursor: "pointer" }}></span>
        </a>
      </h3>
      <div className="p_info_edit_block">
        <span className="p_info_edit_col1">Mobile</span>
        <div className="col">
          <div className="l_info_block">
            <span className="p_info_edit_mobile">{userState?.phone_number}</span>
          </div>
        </div>
      </div>
      <div id="vertacc4" className="collapse mb-3" data-bs-parent="#myprofile">
        <div className="profile_edit_form">
          <div className="row">
            {/* <div className="col-lg-2 mt-2">
              <div className=" form-floating multi-select-field ct_form_field select_dd">
                <Select
                  isSearchable={true}
                  isClearable={true}
                  className="form-control"
                  id="country_code"
                  value={countryCode}
                  options={useCountryList().map((stateArr) => {
                    return {
                      value: stateArr[0],
                      label: stateArr[1],
                    };
                  })}
                  onChange={(e) => {
                    setCountryCode(e);
                  }}
                  placeholder={"Country Code"}
                  isDisabled={false}
                />
                <label htmlFor="country_code">Country Code</label>
              </div>
            </div> */}
            {/* <br /> */}
            <div className="col-lg-6">
              <div className="ct_form_field input-field">
                <label className="mx-3" htmlFor="phoneNumber">Mobile :</label>
                <input
                  type="text"
                  pattern="[+0-9]{10}"
                  className="customized-input transparent-background"
                  placeholder=""
                  id="phoneNumber"
                  onKeyDown={blockInvalidChar}
                  value={phoneNumber}
                  onChange={(e) => {
                    let len = e.target.value.length;
                    if (len <= 10) {
                      setPhoneNumber(e.target.value.replace(/[^+0-9]/g, ''))
                      if (len == 10) {
                        setValidNumber(true);
                      }
                      else {
                        setValidNumber(false);
                      }
                    }
                  }}
                />
                {!validNumber && (
                  <p className="ms-3 error-message">
                    Please enter a valid mobile number.
                  </p>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center gx-0">
            <div className="col-lg-2 col-md-5 col-5 ">
              <button
                data-bs-toggle="collapse"
                data-bs-target="#vertacc4"
                disabled={isDisabled()}
                className="btn btn-primary text-center btn-block"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
            <div className="col-lg-2 col-md-5 col-5 ">
              <button
                type="submit"
                className="btn btn-outline-primary text-center btn-block mx-3 "
                onClick={handleReset}
                data-bs-toggle="collapse" data-bs-target="#vertacc4"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneNumber;
