/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridReact } from "@ag-grid-community/react";
import { memo, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import { getReportsAsync, setAllReports } from "../../../redux/reducers/reportReducer";
import { getGroupList } from "../../../services/user.service";
import Breadcrumb from "../../common/Breadcrumb";

const AllReports = () => {
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const gridAllReportsRef: any = useRef();
    const gridApi = useRef(null as any);
    const [rowData, setRowData] = useState<any>(undefined);
    const reportState = useAppSelector((state: RootState) => state.report);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "Reports",
            redirect_url: "/AllReports",
            icon_url: "fad fa-user"
        }]));
        getAdminAllReports();
    }, []);

    const getAdminAllReports = async () => {
        const userGroup = await getGroupList();
        const reportList: any = await getReportList(userGroup);
        const AllReportsInfo = reportList.map(item => {
            let userGroupName = userGroup.filter(data => data.group_name.toLowerCase() == item.creatingUserName.toLowerCase())[0];
            return {
                report_name: item.visualName,
                owner_name: userGroupName && userGroupName.group_name ? userGroupName.group_name : item.creatingUserName,
                creator_is_active: userGroupName && userGroupName.group_name ? "Yes" : "No",
                created_date: item.createdDate
                // modified_date: item.lastModifiedDate
            }
        });
        setRowData(AllReportsInfo.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1));
    }

    const getReportList = async (userGroup) => {
        let reportListFromState: any = reportState.allReports;
        let list: any;
        if (reportListFromState.length <= 0) {
            const token = appState.logiToken;
            if (token) {
                await dispatch(getReportsAsync({ token: token, currentUser: appState.currentUser, logiConfig: appState.logiConfiguration, userGroup })).then((response) => {
                    if (response && response.payload) {
                        const defaultReports = response.payload.filter(x => appState.cannedReports.includes(x.visualName));
                        dispatch(setAllReports(defaultReports));
                        list = defaultReports
                    }
                });
            }
        }
        else {
            list = reportListFromState;
        }
        return list
    }

    const columns: any[] = [
        { headerName: 'Report Name', field: 'report_name', headerTooltip: 'Report Name' },
        { headerName: 'Owner', field: 'owner_name', headerTooltip: 'Owner' },
        { headerName: 'Is Owner Active?', field: 'creator_is_active', headerTooltip: 'Is Owner Active?' },
        { headerName: 'Created Date', field: 'created_date', headerTooltip: 'Created Date' }
        // { headerName: 'Modified Date', field: 'modified_date', headerTooltip: 'Modified Date' }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const onGridReady = (params: any) => {
        try {
            gridApi.current = params.api;
            params.api.sizeColumnsToFit();
        } catch (err) {
            console.error(err);
        }
    };

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    useEffect(() => {
        if (gridApi.current) {
            setTimeout(() => { gridApi.current.sizeColumnsToFit(); }, 500)
        }
    }, [appState.isNavMenuOpen]);

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
            </div>
        </div>
        <section className="section dashboard">
            <div className="ag-theme-alpine" style={{ height: "72vh", width: "100%" }}>
                <AgGridReact
                    ref={gridAllReportsRef}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    rowData={rowData}
                    headerHeight={32}
                    rowHeight={40}
                    pagination={true}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}
                ></AgGridReact>
            </div>
        </section>
    </>)
}

export default memo(AllReports);
