import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import PasswordValidityViewer from "../common/PasswordValidityViewer";
import Cookies from 'js-cookie';
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const ResetPassword = (props: any) => {
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [inputType, changeInputType] = useState({
        newPassword: false,
        confirmPassword: false,
    });
    const navigation = useNavigate();
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const [showPopover, setPopover] = useState(false);
    const password = Cookies.get('password');
    const dispatch = useAppDispatch();
    const appState = useAppSelector(readAppState);


    const handlePasswordChange = async () => {
        props.setLoader(true);
        Auth.completeNewPassword(props.user, newPassword).then(response => {
            if (response) {
                Cookies.remove("password");
                sessionStorage.setItem("isAuthenticated", "true");
                setPopup({ ...popup, visible: true, message: "Your password was successfully reset.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => { handleNavigation(); setPopup({ ...defaultPopup }) } });
            }
        }).catch(exception => {
            setPopup({ ...popup, visible: true, message: "Something went wrong. Please contact to administrator!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => { setPopup({ ...defaultPopup }) } });
            console.log(exception);
            props.setLoader(false);
        })
    }

    const handleNavigation = () => {
        let accessibleScreens = appState.screensAccessible;
        if (accessibleScreens && accessibleScreens.length > 0) {
            dispatch(setBreadcrumbLocation([{
                "screen_id": accessibleScreens[0].screen_id,
                "screen_name": accessibleScreens[0].screen_name,
                "redirect_url": accessibleScreens[0].redirect_url,
                "icon_url": accessibleScreens[0].icon_url
            }]));
            navigation(`${accessibleScreens[0].redirect_url}`)
        }
        else {
            navigation("/")
        }
    }

    const handleCancel = () => {
        Cookies.remove("password");
        props.updateResetPassword(false)
    }

    let passwordValidations = {
        atleast8Chars: newPassword.length >= 8 ? true : false,
        atLeast1Letter: !!newPassword && !!newPassword.toLowerCase().match(/[a-z]/),
        atLeast1Number: !!newPassword && !!newPassword.match(/[0-9]/),
        atLeast1LowerUppercase: !!newPassword && !!newPassword.match(/(?=.*[a-z])(?=.*[A-Z])/),
        atLeast1SpecialCharacter: !!newPassword && !!newPassword.match(/[-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,.@#]/),
    }

    let isAllValidationsMet = passwordValidations.atleast8Chars && passwordValidations.atLeast1Letter &&
        passwordValidations.atLeast1Number && passwordValidations.atLeast1LowerUppercase && passwordValidations.atLeast1SpecialCharacter;

    return <>
        {popup.visible && <CustomPopupComponent isOpen={popup.visible} toggle={popup.toggle} message={popup.message} type={ModalType.Snackbar} variant={popup.variant} />}
        <h1 className="login_title">Welcome to Mortgage Assets Management!</h1>
        <p className="log_sub_title">Please reset your temporary password to continue.</p>

        <div className="form-floating ct_form_field ct_form_icon_group">
            <input type={inputType.newPassword ? "text" : "password"} onFocus={() => setPopover(true)} onBlur={() => setPopover(false)} className="form-control" id="reset_new_passwrod" placeholder={'New Password'} autoComplete="new-password" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
            <span
                onClick={(e) =>
                    changeInputType({
                        newPassword: !inputType.newPassword,
                        confirmPassword: inputType.confirmPassword,
                    })
                }
                className={`far ${inputType.newPassword ? "fa-key" : "fa-lock"}`}
            ></span>
            <label htmlFor="reset_new_passwrod">{'New Password'}</label>
            {newPassword === password && <span className="error-text" id="error-text"> Your new password should not be same as current password.</span>}

        </div>
        <div className="form-floating ct_form_field ct_form_icon_group">
            <input type={inputType.confirmPassword ? "text" : "password"} className="form-control" id="reset_confirm_password" placeholder={'Confirm Password'} autoComplete="new-password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
            <span
                onClick={(e) =>
                    changeInputType({
                        newPassword: inputType.newPassword,
                        confirmPassword: !inputType.confirmPassword,
                    })
                }
                className={`far ${inputType.confirmPassword ? "fa-key" : "fa-lock"}`}
            ></span>
            <label htmlFor="reset_confirm_password">{'Confirm Password'}</label>
        </div>
        <div className="error-text">
            {confirmPassword.length > 0 && newPassword !== confirmPassword && newPassword !== password && <span style={{marginTop:"-6px"}}>Passwords do not match. Please try again.</span>}
            {confirmPassword.length > 0 && newPassword == confirmPassword && !isAllValidationsMet &&
                <span style={{marginTop:"25px"}}>Your password must contain at least 1 Upper Case Letter, 1 Lower Case Letter, 1 Number and 1 Special Character (such as #&!*@)
                </span>}

        </div>
        <br />
        <div className="row" style={{marginTop: confirmPassword.length > 0 && newPassword == confirmPassword && !isAllValidationsMet   ? "15px" : "-23px" 
        }}>
            <div className="col">
                <button disabled={!newPassword || newPassword !== confirmPassword || newPassword === password || !isAllValidationsMet}
                    className="btn btn-lg btn-primary btn-block text-center" onClick={handlePasswordChange}>
                    Save</button>
            </div>
            <div className="col">
                <button className="btn blue-outline-btn text-center btn-block" type="submit" onClick={() => handleCancel()}>
                    Cancel</button>
            </div>
        </div>
        {newPassword !== password &&
            <PasswordValidityViewer password={newPassword} passwordValidations={passwordValidations} showPopover={showPopover} id="reset_new_passwrod" />
        }
    </>
}

export default memo(ResetPassword);