import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { Module, RowModelType, ServerSideStoreType } from "@ag-grid-community/core";
import { AgGridReact } from '@ag-grid-community/react';
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { readAppState } from "../../redux/reducers/appReducer";
import { getGridData, getGridVerticaData } from '../../services/genericAgGrid.service';


export interface GenericAgGridProps {
    gridColumns: any;
    gridDefaultColDef: any;
    gridDetails: any;
    pagination?: boolean;
    paginationPageSize?: number;
    cacheBlockSize?: number;
    headerHeight?: number;
    rowHeight?: number;
    rowModelType?: RowModelType;
    serverSideStoreType?: ServerSideStoreType;
    modules?: Module[];
    customStaticFilter?: () => void;
    customStaticSort?: string;
    parentGridRef: any;
    postGridResponse?: (response: any) => any;
    sizeColumnsToFit?: boolean;
    afterGridLoad?: () => any;
    currentPage?: any;
    isVerticaDataSource?: boolean;
    onRowClicked?: (row: any) => void;
    rowSelection?:any;
    rowMultiSelectWithClick?:boolean;
    onSelectionChanged?:any;
    suppressRowClickSelection?:boolean;

}

const GenericAgGrid = (props: GenericAgGridProps) => {
    const gridApi = useRef(null as any);
    const [rows, setRows] = useState<any>();
    const appState = useAppSelector(readAppState); 
    

    const getData = async (params: any) => {
        try {
            gridApi.current.hideOverlay();
            const customStaticFilter = props.customStaticFilter ? await props.customStaticFilter() : '';
            const whereClause = whereSql(params.request, customStaticFilter, props.gridDetails.where_clause);
            const orderByClause = orderBySql(params.request, props.customStaticSort, props.gridDetails.order_by_clause);
            let gridData = [], printRows = [], totalRowsCount = -1;
            if (props.gridDetails.is_vertica_data_source) {
                await getGridVerticaData(
                    {
                        view_name: props.gridDetails.grid_view,
                        where: whereClause,
                        order_by: orderByClause,
                        offset: params.request.startRow,
                        row_fetch: params.request.endRow
                    }
                ).then(async data => {
                    if (data.length > 0) {
                        gridData = data;
                        printRows = data[1];
                        totalRowsCount = Number(data[0][0].total_rows);

                        if (props.postGridResponse) {
                            let processedResponse = await props.postGridResponse(data);
                            if (processedResponse?.isNewPrintRows) {
                                printRows = processedResponse.newPrintRows;
                                totalRowsCount = processedResponse.newTotalRowsCount;
                            }
                        }
                    }
                });
            }
            else {
                await getGridData(
                    {
                        view_name: props.gridDetails.grid_view,
                        where: whereClause,
                        order_by: orderByClause,
                        offset: params.request.startRow,
                        row_fetch: params.request.endRow
                    }
                ).then(async data => {
                    if (data.length > 0) {
                        gridData = data;
                        printRows = data[1];
                        totalRowsCount = data[0][0].total_rows;

                        if (props.postGridResponse) {
                            let processedResponse = await props.postGridResponse(data);
                            if (processedResponse.isNewPrintRows) {
                                printRows = processedResponse.newPrintRows;
                                totalRowsCount = processedResponse.newTotalRowsCount;
                            }
                        }
                    }
                });
            }

            if (gridData.length > 0) {
                if (printRows.length === 0) {
                    gridApi.current.showNoRowsOverlay();
                }

                return {
                    success: true,
                    rows: printRows,
                    lastRow: !printRows ? 0 : totalRowsCount
                };
            }
            else {
                return { success: false };
            }

        } catch (err) {
            console.error(err);
            return { success: false };
        }
    }

    const dataSource = {
        getRows: async (params) => {
            await getData(params).then((response) => {
                if (response.success) {
                    setRows(response.rows);
                    params.successCallback(response.rows, response.lastRow);
                } else {
                    params.fail();
                }
            })
        }
    }

    const onGridReady = async (params: any) => {
        gridApi.current = params.api;
        await params.api.setServerSideDatasource({ ...dataSource });

        if (props.sizeColumnsToFit) {
            params.api.sizeColumnsToFit();
        }

        if (props.afterGridLoad) {
            props.afterGridLoad();
        }
    }

    useEffect(() => {
        if (props.sizeColumnsToFit && props.parentGridRef.current.api) {
            setTimeout(() => { props.parentGridRef.current.api.sizeColumnsToFit(); }, 500);
        }
    }, [appState.isNavMenuOpen]);

    const onFirstDataRendered = useCallback((params) => {
        props.parentGridRef.current!.api.paginationGoToPage(props.currentPage || 0);
    }, []);



    return (
        <>
            < div className="generic-ag-grid" >
                <div className="ag-theme-alpine" style={{ height: '72vh', width: '100%' }}>
                    <AgGridReact
                        ref={props.parentGridRef}
                        columnDefs={props.gridColumns}
                        defaultColDef={props.gridDefaultColDef}
                        pagination={props.pagination || true}
                        paginationPageSize={props.paginationPageSize || 10}
                        cacheBlockSize={props.cacheBlockSize || 100}
                        headerHeight={props.headerHeight || 32}
                        rowHeight={props.rowHeight || 25}
                        overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                        onGridReady={onGridReady}
                        rowModelType={props.rowModelType || 'clientSide'}
                        serverSideStoreType={props.serverSideStoreType || 'partial'}
                        modules={props.modules || [ClientSideRowModelModule]}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowClicked={props.onRowClicked}
                        rowSelection={props.rowSelection ||'single'}
                        rowMultiSelectWithClick={props.rowMultiSelectWithClick ||false}
                        onSelectionChanged={props.onSelectionChanged}
                        suppressRowClickSelection={props.suppressRowClickSelection||false}
                    />
                </div>
            </div>
        </>
    )
}

export default memo(GenericAgGrid);


function whereSql(request, customStaticFilter, defaultGridFilter) {
    var whereParts: string[] = [];
    var whereClause: string = '';
    var filterModel = request.filterModel;

    if (filterModel) {
        Object.keys(filterModel).forEach(function (key) {
            var item = filterModel[key];
            switch (item.filterType) {
                case 'text':
                    whereParts.push(createFilterSql(textFilterMapper, key, item));
                    break;
                case 'number':
                    whereParts.push(createFilterSql(numberFilterMapper, key, item));
                    break;
                case 'date':
                    whereParts.push(createFilterSql(dateFilterMapper, key, item));
                    break;
                default:
                    // console.log('unknown filter type: ' + item.filterType);
                    console.error({ "@": "GenericAgGrid.whereSql", Msg: 'unknown filter type: ' + item.filterType });
                    break;
            }
        });
    }

    if (!!customStaticFilter) {
        whereClause = customStaticFilter;
    }

    if (!!defaultGridFilter) {
        whereClause = !!whereClause ? whereClause + ' AND ' + defaultGridFilter : defaultGridFilter;
    }

    if (whereParts.length > 0) {
        whereClause = (!!whereClause ? whereClause + ' AND ' : '') + '(' + whereParts.join(' AND ') + ')';
    }

    return !!whereClause ? whereClause : '';
}

function createFilterSql(mapper, key, item) {
    if (item.operator) {
        var condition1 = mapper(key, item.condition1);
        var condition2 = mapper(key, item.condition2);

        return '(' + condition1 + ' ' + item.operator + ' ' + condition2 + ')';
    }

    return mapper(key, item);
}

function textFilterMapper(key, item) {
    switch (item.type) {
        case 'equals':
            return key + " = '" + item.filter + "'";
        case 'notEqual':
            return key + "' != '" + item.filter + "'";
        case 'contains':
            return key + "::VARCHAR(255) ILIKE '%" + item.filter + "%'::VARCHAR(255)";
        case 'notContains':
            return key + " NOT ILIKE '%" + item.filter + "%'";
        case 'startsWith':
            return key + " ILIKE '" + item.filter + "%'";
        case 'endsWith':
            return key + " ILIKE '%" + item.filter + "'";
        default:
            console.error({ "@": "GenericAgGrid.textFilterMapper", Msg: 'unknown text filter type: ' + item.type });
    }
}

function numberFilterMapper(key, item) {
    switch (item.type) {
        case 'equals':
            return key + ' = ' + item.filter;
        case 'notEqual':
            return key + ' != ' + item.filter;
        case 'greaterThan':
            return key + ' > ' + item.filter;
        case 'greaterThanOrEqual':
            return key + ' >= ' + item.filter;
        case 'lessThan':
            return key + ' < ' + item.filter;
        case 'lessThanOrEqual':
            return key + ' <= ' + item.filter;
        case 'inRange':
            // return ('(' + key + ' >= ' + item.filter + ' and ' + key + ' <= ' + item.filterTo + ')');
            return (`(${key} >= ${item.filter} and ${key} <= ${item.filterTo})`);
        default:
            console.error({ "@": "GenericAgGrid.numberFilterMapper", Msg: 'unknown number filter type: ' + item.type });
    }
}

function dateFilterMapper(key, item) {
    switch (item.type) {
        case 'equals':
            return key + "::date = '" + item.dateFrom + "'";
        case 'notEqual':
            return key + "::date != '" + item.dateFrom + "'";
        case 'greaterThan':
            return key + "::date > '" + item.dateFrom + "'";
        case 'lessThan':
            return key + "::date < '" + item.dateFrom + "'";
        case 'inRange':
            return (`(${key}::date >= '${item.dateFrom}' and ${key}::date <= '${item.dateTo}')`);
        default:
            console.error({ "@": "GenericAgGrid.dateFilterMapper", Msg: 'unknown date filter type: ' + item.type });
    }
}

function orderBySql(request, customStaticSort, defaultStaticSort) {
    var sortModel = request.sortModel, orderByClause: string = '';

    var sorts = sortModel.map(function (s) {
        return s.colId + ' ' + s.sort.toUpperCase();
    });

    if (!!customStaticSort) {
        orderByClause = customStaticSort;
    }

    if (!!defaultStaticSort) {
        orderByClause = !!orderByClause ? orderByClause + ', ' + defaultStaticSort : defaultStaticSort;
    }

    if (sorts.length > 0)
        orderByClause = sorts.join(', '); //(!!orderByClause ? orderByClause + ', ' : '') + sorts.join(', ');
    else
        orderByClause = !!orderByClause ? orderByClause : ''

    return orderByClause;
}

export function getCurrentFilterValue(ref) {
    var currentPage = ref.current.api.paginationGetCurrentPage();
    var filterModel = ref.current.api.getFilterModel();
    var colState = ref.current!.columnApi.getColumnState();
    var sortState = colState
        .filter(function (s) {
            return s.sort != null;
        })
        .map(function (s) {
            return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
        });
    return { CurrentPage: currentPage, FilterModel: filterModel, SortState: sortState }
}
