// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const percentFormatter = new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2
    //maximumFractionDigits: 6,
});


export const formatAmount = (text: number) => {
    return text ? formatter.format(text) : '$0.00'
}

export const formatAsPercent = (num : number) => {
    return num ? percentFormatter.format(num / 100) : '0.00%';
}
