import { API, graphqlOperation } from "aws-amplify";
import { executeAprrovalBatchProcess, listRuleOutputByProcessId, listTransformerLogs,getTemplateRuleMapping, getRuleOutputTotalrowsByProcessId } from "../graphql/queries";

export const getTransformerLogList = async (transformerLogId) => {
    try {
        const response: any = await API.graphql(graphqlOperation(listTransformerLogs, { transformer_logs_id: transformerLogId }));
        return response && response.data && response.data.listTransformerLogs ? response.data.listTransformerLogs : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export const executeApprove = async (transformer_log_id, step, bucketName, s3FilePath, userID) => {
    try {
        var response: any;
        if (step === "ruleApproval") { response = await API.graphql(graphqlOperation(executeAprrovalBatchProcess, { transformerLogId: transformer_log_id, invokeFromUI: true, step: step })); }
        else {
            response = await API.graphql(graphqlOperation(executeAprrovalBatchProcess, { transformerLogId: transformer_log_id, invokeFromUI: true, step: step, bucket: bucketName, s3FilePath: s3FilePath, userID: userID }));
        }
        return response && response.data && response.data.executeAprrovalBatchProcess ? response.data.executeAprrovalBatchProcess : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}
export const getRuleOuptputList = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(listRuleOutputByProcessId, { process_id: data.process_id, offset: data.offset, page_size: data.pageSize }));
        return response && response.data && response.data.listRuleOutputByProcessId ? JSON.parse(response.data.listRuleOutputByProcessId) : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}

export const getRuleOuptputTotalrows = async (data: any) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getRuleOutputTotalrowsByProcessId, { process_id: data.process_id }));
        return response && response.data && response.data.getRuleOutputTotalrowsByProcessId ? JSON.parse(response.data.getRuleOutputTotalrowsByProcessId)[0].total_rows : 0;
    }
    catch (e) {
        console.log(e);
        return -1;
    }
}

export const getRuleTemplateMapping = async (fileName,bucketName) => {
    try {
        const response: any = await API.graphql(graphqlOperation(getTemplateRuleMapping, { bucket: bucketName, file_name: fileName }));
        return response && response.data && response.data.getTemplateRuleMapping ? response.data.getTemplateRuleMapping[0] : null;
    }
    catch (e) {
        console.log(e);
        return [];
    }
}