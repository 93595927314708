import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../common/CustomPopupComponent";
import PasswordValidityViewer from "../common/PasswordValidityViewer";
import { Loader } from "../../helpers/loader";
import { useAppDispatch } from "../../app/hooks";

const Password = (props: any) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [inputType, changeInputType] = useState({
    currentPassword: false,
    updatedPassword: false,
  });
  const [loader, setLoader] = useState(false);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPopover, setPopover] = useState(false);
  const navigation = useNavigate();

  let passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\]{8,}$/
  const handleSubmit = async (event: any) => {
    setLoader(true);
    // AWS Cognito integration here
    try {
      const user = await Auth.currentAuthenticatedUser();
      let res: any = await Auth.changePassword(user, oldPassword, newPassword);
      setPopup({
        ...popup,
        visible: true,
        message: "Your password has been changed successfully!",
        type: ModalType.Snackbar,
        variant: VariantType.Success,
        toggle: () => {
          setPopup({ ...defaultPopup });
        },
      });
      handleReset()
      setTimeout(() => {
        navigation("/MyProfileAndSettings");
      }, 3000);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      let err =
        error.code === "NotAuthorizedException"
          ? "Incorrect  Current Password! The password you entered is incorrect. Please try again."
          : error.message;
      setPopup({
        ...popup,
        visible: true,
        message: err,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => {
          setPopup({ ...defaultPopup });
        },
      });
      handleReset()
      console.log(error);
    }
  };

  const validatePasswords = (password: any): boolean => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\])[a-zA-Z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/\\]{8,}$/;
    const isValid = regex.test(password);
    return isValid;
  }
  const isDisabled = () => {
    let disabled =
      !oldPassword ||
      !newPassword ||
      newPassword !== confirmPassword ||
      newPassword === oldPassword;
    if (!validatePasswords(newPassword) || !validatePasswords(confirmPassword)) {
      disabled = true;
    }
    return disabled;
  };

  const handleReset = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <>
      {popup.visible && (
        <CustomPopupComponent
          isOpen={popup.visible}
          toggle={popup.toggle}
          message={popup.message}
          type={ModalType.Snackbar}
          variant={popup.variant}
        />
      )}
      {loader && <Loader />}
      <h3 className="profile_edit_title">
        Password
        <a
          className="p_edit_icon"
          data-bs-toggle="collapse"
          data-bs-target="#vertacc5"
          aria-expanded="true"
        >
          <span className="fa fa-pen-square" style={{ color: "#006b7f", cursor: "pointer" }}></span>
        </a>
      </h3>
      <div className="p_info_edit_block">
        <span className="p_info_edit_col1">Password</span>
        <span className="p_info_edit_col2" >********</span>
      </div>
      <div id="vertacc5" className="collapse" data-bs-parent="#myprofile">
        <div className="profile_edit_form pb-3">
          <div className="row">
            <div className="col-lg-4">
              <div className="form-floating ct_form_field ct_form_icon_group">
                <input
                  className="form-control transparent-background"
                  type={inputType.currentPassword ? "text" : "password"}
                  id="oldpassword"
                  placeholder="Enter Current password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <label className="re-enter-new-password">{"Current Password"}</label>
                <span 
                  onClick={(e) =>
                    changeInputType({
                      currentPassword: !inputType.currentPassword,
                      updatedPassword: inputType.updatedPassword,
                    })
                  }
                  className={`far ${inputType.currentPassword ? "fa-key" : "fa-lock"
                    }`}
                ></span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-floating ct_form_field ct_form_icon_group ">
                <input
                  className="form-control transparent-background"
                  type="password"
                  id="checkNewPassword"
                  placeholder={"Enter New Password"}
                  autoComplete="new-password"
                  value={newPassword}
                  onFocus={() => setPopover(true)}
                  onBlur={() => setPopover(false)}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <label htmlFor="checkNewPassword">
                  {"Enter New Password"}
                </label>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-floating ct_form_field ct_form_icon_group">
                <input
                  className="form-control transparent-background"
                  type={inputType.updatedPassword ? "text" : "password"}
                  id="checkConfirmPassword"
                  placeholder={"Re-Enter New Password"}
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <label className="re-enter-new-password" htmlFor="checkConfirmPassword">
                  {"Re-Enter New Password"}
                </label>
                <span
                  onClick={(e) =>
                    changeInputType({
                      currentPassword: inputType.currentPassword,
                      updatedPassword: !inputType.updatedPassword,
                    })
                  }
                  className={`far ${inputType.updatedPassword ? "fa-key" : "fa-lock"
                    }`}
                ></span>
              </div>
            </div>
          </div>
          <div className="row" style={{ textAlign: "center", marginBottom: "-10px" }}>
            {!showPopover &&
              newPassword !== "" &&
              oldPassword !== "" &&
              newPassword === oldPassword && (
                <span className="error-message">
                  The new password entered is same as old password, please
                  try resetting the password again.
                </span>
              )
            }
            {
              !showPopover &&
                confirmPassword !== "" &&
                newPassword !== '' && !passwordPattern.test(newPassword) ?
                <span className="error-message">
                  Your password must contain at least 1 Upper Case Letter, 1 Lower Case Letter, 1 Number and 1 Special Character (such as #&!*@)
                </span> :
                !showPopover &&
                confirmPassword !== "" &&
                newPassword !== oldPassword &&
                newPassword !== confirmPassword && (
                  <span className="error-message">
                    Your passwords do not match. Please re-enter your new
                    password.
                  </span>
                )
            }

          </div>
          <br />
          <div className="row justify-content-md-center mt30">
            <div className="col-lg-2 col gx-2">
              <button
                data-bs-toggle="collapse"
                data-bs-target="#vertacc5"
                disabled={isDisabled()}
                className="btn btn-primary text-center btn-block"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
            <div className="col-lg-2 col gx-1">
              <button
                className="btn btn-outline-primary text-center btn-block mx-1"
                type="submit"
                onClick={handleReset}
                data-bs-toggle="collapse"
                data-bs-target="#vertacc5"
              >
                Cancel
              </button>
            </div>
          </div>
          <PasswordValidityViewer
            password={newPassword}
            showPopover={showPopover}
            id="checkNewPassword"
          />
        </div>
      </div>
    </>
  );
};

export default Password;
