/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useEffect, useState, useRef } from "react";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import AWS from 'aws-sdk';
import { deleteUserByUserId, deleteUserGroupByUserId, deleteUserRoleByUserId, getUserList } from "../../../services/user.service";
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import Breadcrumb from "../../common/Breadcrumb";
import { readUserState, setUser, setUserList } from "../../../redux/reducers/userReducer";
import { Loader } from "../../../helpers/loader";
import awsmobile from "../../../aws-exports";
AWS.config.update({
    region: awsmobile.aws_cognito_region,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
    })
});


const UserListComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const userState = useAppSelector(readUserState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            screen_name: "User Management",
            redirect_url: "/Users",
            icon_url: "fad fa-user"
        }]));
    }, []);

    const actionButtons = (params) => {
        return <div className="grid-action">
            <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
            <button disabled={appState.currentUser?.user_id === params.data.user_id} onClick={() => {
                setPopup({
                    ...popup,
                    visible: true,
                    message: "Please confirm that you would like to delete this user account.",
                    type: ModalType.ActionDialog,
                    toggle: () => setPopup({ ...defaultPopup }),
                    primaryButtonAction: () => removeUser(),
                    primaryButtonName: "Delete",
                    secondaryButtonName: "Cancel",
                    secondaryButtonAction: () => setPopup({ ...defaultPopup })
                });
            }} title="Delete" className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
        </div>
    }

    useEffect(() => {
        if (gridApi) {
            setTimeout(() => {
                gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen])

    const onGridReady = async (params: any) => {
        try {
            let users: any;
            users = await getUserList();
            dispatch(setUserList(users));
            users = users.filter(x => x.maas_user_id !== appState.logiConfiguration.find(x => x.configuration_type === 'admin_user_name')?.configuration_value);
            setRowData(users);
            params.api.paginationGoToPage(10);
            // params.api.sizeColumnsToFit();
            setGridApi(params.api);
            var sort = [{
                colId: "created_date",
                sort: "desc"
            }];
            params.columnApi.applyColumnState({
                state: sort,
                defaultState: { sort: null }
            })
            setLoading(false);
        }
        catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const handleEditClick = (params: any) => {
        let data = { ...params.data, is_new: false };
        dispatch(setUser(data));
        navigation("/UserManagement");
    }

    const columns: any[] = [
        { headerName: 'User Id', field: 'user_id', hide: true },
        { headerName: 'Created Date', field: 'created_date', hide: true },
        { headerName: 'First Name', field: 'first_name', headerTooltip: 'First Name', minWidth: 150, maxWidth: 170 },
        { headerName: 'Last Name', field: 'last_name', headerTooltip: 'Last Name', minWidth: 150, maxWidth: 180 },
        { headerName: 'Phone Number', field: 'phone_number', headerTooltip: 'Phone Number', minWidth: 150, maxWidth: 200 },
        { headerName: 'Email Address', field: 'email', headerTooltip: 'Email Address', minWidth: 200, maxWidth: 300 },
        { headerName: 'User Role', field: 'role_name', headerTooltip: 'User Role', minWidth: 150, maxWidth: 200 },
        { headerName: 'User Group', field: 'group_name', headerTooltip: 'User Group', minWidth: 200, maxWidth: 350 },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 110, suppressSizeToFit: true, cellRendererFramework: actionButtons

        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }
    const gridRef: any = useRef();

    const removeUser = async () => {
        setPopup(defaultPopup);
        setLoading(true);
        try {
            const selectedData = gridApi.getSelectedRows();
            if (selectedData[0].user_id !== appState.currentUser!.user_id) {
                var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
                cognitoidentityserviceprovider.adminDeleteUser({
                    UserPoolId: awsmobile.aws_user_pools_id,
                    Username: selectedData[0].maas_user_id
                }, async (err, data) => {
                    if (err) {
                        setLoading(false);
                    }
                    else {
                        let users = userState.userList;
                        users = users!.filter(z => z.user_id !== selectedData[0].user_id);
                        dispatch(setUserList(users));
                        gridApi.updateRowData({ remove: selectedData });
                        deleteUserByUserId(selectedData[0].user_id);
                        deleteUserRoleByUserId(selectedData[0].user_id);
                        deleteUserGroupByUserId(selectedData[0].user_id);
                        setLoading(false);
                        setPopup({ ...popup, visible: true, message: "This user account has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
                    }
                });
            }
            else {
                setLoading(false);
                setPopup({ ...popup, visible: true, message: "User is logged in so cannot be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
            }

        }
        catch (error) {
            console.log(error);
            setLoading(false);
            setPopup({ ...popup, visible: true, message: "User could not be deleted!", type: ModalType.Snackbar, variant: VariantType.Error, toggle: () => setPopup({ ...defaultPopup }) });
        }
    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                    <a className="btn btn-primary" onClick={() => { navigation("/UserManagement"); dispatch(setUser({ ...userState.user, is_new: true })) }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} /> Add User
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            {loading && <Loader />}
            {popup.visible && <CustomPopupComponent message={popup.message} isOpen={popup.visible} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} toggle={popup.toggle} primaryButtonAction={popup.primaryButtonAction} type={popup.type} variant={popup.variant} />}
            <div className="ag-theme-alpine" style={{ height: "72vh", width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columns}
                    rowData={rowData}
                    rowSelection={'single'}
                    defaultColDef={defaultColDef}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}>
                </AgGridReact>
            </div>
        </section>
    </>
    )
}

export default memo(UserListComponent);