export const LoanTransactionTypes = {
    BalanceTransactions: "Balance Transactions",
    CreditLinePrincipalLimitTransaction: "Credit Line/Principal Limit Transaction",
    SetAsideTransaction: "Set Aside Transaction"
}

export const LoanDetailTabs = {
    LoanTransactions: "Loan Transactions",
    PaymentInformation: "Payment Information",
    Disbursements: "Disbursements",
    Remittence: "Remittence",
    FeeAndCharges: "Fee & Charges",
    Notes: "Notes",
    Workflow: "Workflow",
    LoanPropertyDetails: "Property Details",
    InspectionDetails: "Inspection Details",
    CodeViolations: "Code Violations",
    LossDraft: "Loss Draft",
    TaxDetails: "Tax Details",
    HOADetails: "HOA Details",
    CommentDetails:"Comment Details",
    TagDetails:"Tag Details",
    Documents: "Documents"
}

export function commentOptions() {
	return (
		[
			["Note", "Note"],
			["Other", "Other"]
		]
	)
}