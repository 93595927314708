import { API, graphqlOperation } from 'aws-amplify';
import { updateFnmaBill } from '../graphql/mutations';
import { listFnmaBillsView } from '../graphql/queries';
export const getBillList = async () => {
    try {
        let response: any = await API.graphql(graphqlOperation(listFnmaBillsView));
        return response && response.data ? response.data.listFnmaBillsView : null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

export const updateFnmaBillDetail= async (data: any) => {
    try {
        let response: any = await API.graphql(graphqlOperation(updateFnmaBill, {input:data}));
        return response && response.data && response.data.updateFnmaBill ? JSON.parse(response.data.updateFnmaBill).postgresResponse[0]: null;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}