/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Select from "react-select";
import { useEffect, useState, useRef } from "react";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import CustomPopupComponent, {
  ModalType,
  VariantType,
} from "../common/CustomPopupComponent";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Breadcrumb from "../common/Breadcrumb";
import { Loader } from "../../helpers/loader";
import { selectCustomStyles } from "../common/CustomStyles";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import awsmobile from "../../aws-exports";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import formatDateTable from "../../constants/dateFormatTable";
import GenericAgGrid from "../common/GenericAgGrid";
import {
  setLoaderMessage,
  readAppState,
  setBreadcrumbLocation,
} from "../../redux/reducers/appReducer";
import {
  insertDocument,
  getAllBucketList,
} from "../../services/upload.service";

const FileUpload = () => {
  const [gridApi, setGridApi] = useState<any>();
  const [loading, setLoader] = useState(false);
  const defaultPopup = {
    visible: false as any,
    type: null as any,
    color: null as any,
    variant: null as any,
    message: null as any,
    toggle: null as any,
    primaryButtonAction: null as any,
    primaryButtonName: null as any,
    secondaryButtonAction: null as any,
    secondaryButtonName: null as any,
  };
  const [popup, setPopup] = useState(defaultPopup);
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [optionValue, setOptionValue] = useState<any>(null);
  const [bucketData, setBucketData] = useState<any>(null);
  const appState = useAppSelector(readAppState);
  const [disable, setDisable] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const gridFilesRef: any = useRef();
  const [localState, setLocalState] = useState<any>({ file: undefined as any });
  const massCdnUrl = process.env.REACT_APP_MAAS_CDN_URL;
  let isDisabled = disable || !optionValue || !localState.file;

  let gridDetails = appState.gridCodeList?.find((g) => g.grid_code === "FILEUPLOAD");

  useEffect(() => {
    dispatch(
      setBreadcrumbLocation([
        {
          screen_name: "File Upload",
          redirect_url: "/UploadFile",
          icon_url: "fad fa-user",
        },
      ])
    );
    listBuckets();
  }, []);

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.sizeColumnsToFit();
      }, 500);
    }
  }, [appState.isNavMenuOpen]);

  const columns: any[] = [
    {
      field: "document_name",
      headerName: "Document Name",
      headerTooltip: "Document Name",
    },
    {
      field: "uploaded_date",
      headerName: "Uploaded Date",
      headerTooltip: "Uploaded Date",
      valueFormatter: formatDateTable,
      filter: 'agDateColumnFilter'
    },
    {
      field: "s3_bucket_name",
      headerName: "S3 Bucket",
      headerTooltip: "S3 Bucket",
    },
  ];

  const defaultColDef = {
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const onCancel = () => {
    setLocalState({ ...localState, file: undefined });
    setOptionValue(null);
    setLoader(false);
  };

  const listBuckets = async () => {
    const bucketList = await getAllBucketList();
    if (bucketList && bucketList.length > 0) {
      let screenData: any = bucketList.map((z) => {
        return {
          value: z.file_automation_bucket_detail_id,
          label: z.bucket_path,
        };
      });
      setListOptions(screenData);
      setBucketData(bucketList);
      setLoader(false);
    }
  };

  const handleUpload = async () => {
    if (localState.file) {
      setLoader(true);
      setDisable(true);
      let bucketName = bucketData.find((x) => x.bucket_path === optionValue?.label)
      const data = await uploadFile({
        file: localState.file,
        bucketPath: bucketName.bucket_path,
        bucket: bucketName.bucket_name,
      });
      if (data) {
        const Filedata = {
          document_name: localState?.file?.fileName,
          uploaded_date: new Date().toUTCString(),
          s3_bucket_name: bucketName.bucket_path,
          inserted_by: appState.currentUser!.maas_user_id,
          inserted_date: new Date().toUTCString(),
        };
        let res: any = await insertDocument(Filedata);
        setLocalState({
          ...localState,
          file: {
            accessUrl: "",
            fileName: "",
            fileType: "",
            isUploaded: false,
          },
        });
        setLoader(false);
        setDisable(false);
        dispatch(setLoaderMessage(""));
        setOptionValue(null);
        setPopup({
          ...popup,
          visible: true,
          message: "File uploaded successfully!",
          type: ModalType.Snackbar,
          variant: VariantType.Success,
          toggle: () => setPopup({ ...defaultPopup }),
        });
        gridFilesRef.current.api.refreshServerSideStore();
        localStorage.removeItem("loaderMessage")
      } else {
        setLoader(false);
        setDisable(true);
        setPopup({
          ...popup,
          visible: true,
          message: "Something went wrong! Please re-upload the file.",
          type: ModalType.Snackbar,
          variant: VariantType.Error,
          toggle: () => setPopup({ ...defaultPopup }),
        });
      }
    } else {
      setLoader(false);
      setPopup({
        ...popup,
        visible: true,
        message: `Please re-upload the file with previously provided file name.`,
        type: ModalType.Snackbar,
        variant: VariantType.Error,
        toggle: () => setPopup({ ...defaultPopup }),
      });
    }
  };

  const uploadFile = async (data: any) => {
    try {
      let file = data.file.fileData;
      const target = {
        Bucket: data.bucket,
        Key: `${data.bucketPath}/${data.file.fileName}`,
        Body: file,
      };
      const credsdata = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({
          region: awsmobile.aws_cognito_region,
        }),
        identityPoolId: awsmobile.aws_cognito_identity_pool_id,
      });
      const uploadParallel = new Upload({
        client: new S3Client({
          region: awsmobile.aws_cognito_region,
          credentials: credsdata,
        }),
        params: target,
        leavePartsOnError: false,
      });
      dispatch(setLoaderMessage(`Uploading File!`));
      uploadParallel.on("httpUploadProgress", (progress) => {
        if (progress.loaded && progress.total) {
          setLoader(false);
        }
      });
      const response = await uploadParallel.done();

      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const handleFileSelect = (e: any) => {
    if (e.target.files) {
      for (var fileObject in e.target.files) {
        if (typeof e.target.files[fileObject] === "object") {
          const fileData = e.target.files[fileObject];
          const splitFileName = fileData.name.split(".");
          const fileExtension = splitFileName[splitFileName.length - 1];
          if (fileData.name.includes(" ")) {
            setPopup({
              ...popup,
              visible: true,
              message: "File name should not contain empty spaces!",
              type: ModalType.Snackbar,
              variant: VariantType.Error,
              toggle: () => setPopup({ ...defaultPopup }),
            });
            return;
          }
          if (!["txt", "csv", "xlsx"].includes(fileExtension)) {
            setPopup({
              ...popup,
              visible: true,
              message: "File - " + fileData.name + " is not valid.",
              type: ModalType.Snackbar,
              variant: VariantType.Error,
              toggle: () => setPopup({ ...defaultPopup }),
            });
            return false;
          }
          const fileBlob = new Blob([fileData], { type: fileData.type });
          setLocalState({
            ...localState.file,
            file: {
              fileData: e.target.files[0],
              accessUrl: URL.createObjectURL(fileBlob),
              fileName: fileData.name.replaceAll(" ", "_"),
              fileType: fileData.type,
              isUploaded: false,
            },
          });
        }
      }
    }
  };

  const handleSelcetChange = (e) => {
    setOptionValue(e);
  };

  let customSort = ` uploaded_date desc `;

  const getFileType = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };
  const removeFileType = (filename) => {
    return filename.substring(0, filename.lastIndexOf('.'));
  };

  return (
    <>
      <div className="pagetitle">
        <Breadcrumb />
      </div>
      <section className="section dashboard">
        {loading && <Loader />}
        {popup.visible && (
          <CustomPopupComponent
            isOpen={popup.visible}
            message={popup.message}
            primaryButtonName={popup.primaryButtonName}
            secondaryButtonName={popup.secondaryButtonName}
            secondaryButtonAction={popup.secondaryButtonAction}
            toggle={popup.toggle}
            primaryButtonAction={popup.primaryButtonAction}
            type={popup.type}
            variant={popup.variant}
          />
        )}
        <div className="block_sect">
          <div className="row mb-3 d-flex align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-4  fileUpload1 ">
              <Select
                isSearchable={true}
                isClearable={true}
                styles={selectCustomStyles}
                options={listOptions}
                value={optionValue}
                onChange={(e) => {
                  handleSelcetChange(e);
                }}
                placeholder="Select S3 Bucket"
                isDisabled={false}
              />
            </div>
            <div className={`${localState?.file?.fileName ? "col-lg-3" : "col-lg-3"} +"col-md-4 col-sm-4 col-xs-4 d-flex align-items-center fileUpload1`}>
              <div className="file-upload-input">
                <input className="form-control"
                  id="tapeFile"
                  style={{ display: "none" }}
                  type="file"
                  name="files"
                  accept=".txt, .csv, .xlsx"
                  onClick={(event: any) => (event.target.value = null)} onChange={(e) => handleFileSelect(e)} />
                <label
                  style={{ color: "grey" }}
                  htmlFor="tapeFile"
                  className=" vertical-center d-flex align-item-center justify-content-between">{localState?.file?.fileName ? (
                    <div className="filename-container" >
                      <div className="filename-text">
                        <span>{removeFileType(localState?.file?.fileName)}</span>
                      </div>
                      <div className="filename-ext">
                        <span>.{getFileType(localState?.file?.fileName)}</span>
                      </div>
                    </div>) : (<>
                      <span >{"Click here to select the file."} </span>
                    </>)} <span> <img src={massCdnUrl + "assets/img/site-file-upload.svg"} style={{ width: "20px" }} /></span> </label>
              </div>
            </div>
            <div className=" col-12 col-lg-4 col-md-4 col-4 ">
              <div className="row justify-content-center my-2 my-md-2">
                <div className="col-lg-5 col-md-5 col-5 col-sm-2 col-xs-5 col-5" >
                  <button
                    className={`btn btn-primary text-center btn-block ${isDisabled ? "disabled" : "enabled"
                      }`}
                    type="submit"
                    onClick={() => handleUpload()}
                    disabled={isDisabled}
                  >
                    Upload
                  </button>
                </div>
                <div className="col-lg-5 col-md-5 col-5 col-sm-2 col-xs-5 col-5">
                  <button
                    className="btn btn-outline-primary text-center btn-block not-allowed"
                    onClick={onCancel}
                    disabled={!localState.file}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {!!gridDetails && (
              <div>
                <GenericAgGrid
                  gridColumns={columns}
                  gridDefaultColDef={defaultColDef}
                  gridDetails={gridDetails}
                  rowHeight={40}
                  rowModelType={"serverSide"}
                  modules={[ServerSideRowModelModule]}
                  customStaticSort={customSort}
                  parentGridRef={gridFilesRef}
                // afterGridLoad={afterGridLoad}
                // currentPage={loanState.noteData?.current_page}
                ></GenericAgGrid>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FileUpload;
