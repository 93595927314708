import { API, graphqlOperation } from "aws-amplify";
import { listLoanDetails, listLoansByFilter, listAssignmentClaimsLoansByFilter, listWorkflowDocumentsByFilter } from "../graphql/queries";

export const GetLoanSearchByFilter = async (data: any, accessId?: String) => {
  let groupId = accessId ? accessId : data.groupId;
  try {
    if (data.search_key_data.search_key) {
      const response: any = await API.graphql(graphqlOperation(listLoansByFilter,
        {
          filterValue: data.search_key_data.search_key,
          filter: data.filter,
          skip: data.skip,
          row_fetch: data.row_fetch,
          fields: data.field_name,
          order: data.order,
          scope_id: groupId
        }));
      if (response && response.data && response.data.listLoansByFilter) {
        const loanSearchFilter = JSON.parse(response.data.listLoansByFilter);
        return loanSearchFilter;
      }
      else {
        return [];
      }
    }
    else {
      const response: any = await API.graphql(graphqlOperation(listLoanDetails,
        {
          filter: data.filter,
          skip: data.skip,
          row_fetch: data.row_fetch,
          fields: data.field_name,
          order: data.order,
          scope_id: groupId
        }));        
        if (response && response.data && response.data.listLoanDetails) {
          const loanSearchFilter = JSON.parse(response.data.listLoanDetails);
          return loanSearchFilter;
        }
        else {
          return [];
        }
    }

  }
  catch (err) {
    console.log(err);
  }
}

export const getWorkflowDocumentByFilters = async (data: any) => {
  try {

    const response: any = await API.graphql(graphqlOperation(listWorkflowDocumentsByFilter,
      {
        filter: data.filter,
        skip: data.skip,
        row_fetch: data.row_fetch
      }));
    const workflowDocumentByFilter = response.data.listWorkflowDocumentsByFilter;
    return workflowDocumentByFilter;
  }
  catch (err) {
    console.log(err);
  }
}

export const getAssignmentsByFilter = async (param: any, groupId: String, sourceQuery: String, viewName: String) => {
  try {
    const response: any = await API.graphql(graphqlOperation(listAssignmentClaimsLoansByFilter,
      {
        filter: param.filter,
        skip: param.skip,
        row_fetch: param.row_fetch,
        scope_id: groupId,
        source_query: sourceQuery,
        view_name: viewName,
        sort_order: param.sort_order,
        sort_column : param.sort_column
      }));
    const loanSearchFilter = JSON.parse(response.data.listAssignmentClaimsLoansByFilter);
    return loanSearchFilter;
  }
  catch (ex) {
    console.log(ex);
    return [];
  }
}
