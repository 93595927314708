/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { memo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import formatDateTable from "../../constants/dateFormatTable";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { readLoanState, setFnmaBillDetails } from "../../redux/reducers/loanReducer";
import Breadcrumb from "../common/Breadcrumb";
import GenericAgGrid, { getCurrentFilterValue } from "../common/GenericAgGrid";

const Bill = () => {
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();    
    const loanState = useAppSelector(readLoanState);

    const gridFnmaBillsRef: any = useRef();
    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'FNMA_BILLS');

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            "screen_id": "",
            "screen_name": "Bills View",
            "redirect_url": "/FNMABillsView",
            "icon_url": "fas fa-address-book"
        }]));
    }, []);

    const currencyFormatter = (params: any) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(params.value);
    };

    const handleEditClick = (params: any) => {
        preserveFilter(params);
        navigation("/BillView")
    }

    const columns: any[] = [
        { headerName: 'Initial Bill #', field: 'initial_bill_number', headerTooltip: 'Initial Bill #', minWidth: '130' },
        { headerName: 'Second Bill #', field: 'second_bill_number', headerTooltip: 'Second Bill #', minWidth: '140' },
        { headerName: 'Bill Group Id', field: 'bill_group_id', headerTooltip: 'Bill Group Id', minWidth: '150' },
        { headerName: 'Portfolio', field: 'bill_reporting_servicer_name', headerTooltip: 'Portfolio', minWidth: '140' },
        { headerName: 'Bill Status', field: 'bill_status', headerTooltip: 'Bill Status', minWidth: '130' },
        { headerName: 'Bill Stage', field: 'bill_stage', headerTooltip: 'Bill Stage', minWidth: '130'  },
        { headerName: 'Total Bill Amount', field: 'total_bill_amount', headerTooltip: 'Total Bill Amount', minWidth: '150', valueFormatter: currencyFormatter },
        { headerName: 'Total Loans', field: 'total_loans', headerTooltip: 'Total Loans', minWidth: '120' },
        { headerName: 'Bill Categories', field: 'bill_category', headerTooltip: 'Bill Categories', minWidth: '150' },
        { headerName: 'Billing Date', field: 'billing_date', headerTooltip: 'Billing Date', minWidth: '150',filter: 'agDateColumnFilter', valueFormatter: (param) => formatDateTable(param, false) },
        { headerName: 'Second Bill Rebuttal Received Date', field: 'rebuttal_received_stage_2_date', headerTooltip: '2nd Bill Rebuttal Received Date',filter: 'agDateColumnFilter', minWidth: '150',valueFormatter: (param) => formatDateTable(param, false) },
        { headerName: 'Second Bill FNMA Response Date', field: 'fnma_response_stage_3_date', headerTooltip: '2nd Bill FNMA Response',filter: 'agDateColumnFilter', minWidth: '150',valueFormatter: (param) => formatDateTable(param, false) },
        { headerName: 'Impasse Rebuttal Received Date', field: 'rebuttal_received_stage_4_date', headerTooltip: 'Impasse Rebuttal Received Date',filter: 'agDateColumnFilter', minWidth: '150',valueFormatter: (param) => formatDateTable(param, false) },
        { headerName: 'Impasse FNMA Response Date', field: 'fnma_response_stage_5_date', headerTooltip: 'Impasse FNMA Response Date',filter: 'agDateColumnFilter', minWidth: '150',valueFormatter: (param) => formatDateTable(param, false) },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 80, suppressSizeToFit: true, cellRendererFramework: (params) => <div className="grid-action">
                <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
            </div>
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }

    const preserveFilter=(params: any)=>{
        let filterValue=getCurrentFilterValue(gridFnmaBillsRef);
        
            dispatch(setFnmaBillDetails({ fnma_billing_id:params.data.fnma_billing_id
                ,initial_bill_number:params.data.initial_bill_number
                ,bill_group_id:params.data.bill_group_id
                ,second_bill_number:params.data.second_bill_number
                ,bill_status:params.data.bill_status
                ,bill_stage:params.data.bill_status
                ,total_bill_amount:params.data.total_bill_amount
                ,total_loans:params.data.total_loans
                ,bill_category:params.data.bill_category
                ,billing_date:params.data.billing_date
                ,rebuttal_received_stage_2_date:params.data.rebuttal_received_stage_2_date
                ,fnma_response_stage_3_date:params.data.fnma_response_stage_3_date
                ,rebuttal_received_stage_4_date:params.data.rebuttal_received_stage_4_date
                ,fnma_response_stage_5_date:params.data.fnma_response_stage_5_date
                ,bill_reporting_servicer_name:params.data.bill_reporting_servicer_name
                , is_new: false,is_edit:true,
                current_page:filterValue.CurrentPage, saved_filter:filterValue.FilterModel, saved_sort:filterValue.SortState }));
        
    }

    const afterGridLoad=()=>{
        if((!loanState.fnmaBillDetails?.is_edit) && (loanState.fnmaBillDetails?.saved_sort || loanState.fnmaBillDetails?.saved_filter)){
            gridFnmaBillsRef.current.columnApi.applyColumnState({
                    state: loanState.fnmaBillDetails.saved_sort,
              });
              gridFnmaBillsRef.current.api.setFilterModel(loanState.fnmaBillDetails.saved_filter);
              dispatch(setFnmaBillDetails({ ...loanState?.fnmaBillDetails, is_edit:true,current_page:0}));
              
        }else{
            dispatch(setFnmaBillDetails({ ...loanState?.fnmaBillDetails, saved_sort: null,saved_filter:null,current_page:0}));
        }
    }


    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
            </div>
        </div>
        {!!gridDetails && <div>
            <GenericAgGrid
                gridColumns={columns}
                gridDefaultColDef={defaultColDef}
                gridDetails={gridDetails}
                rowHeight={40}
                rowModelType={'serverSide'}
                modules={[ServerSideRowModelModule]}
                parentGridRef={gridFnmaBillsRef}
                afterGridLoad={afterGridLoad}
                currentPage={loanState.fnmaBillDetails?.current_page}
            ></GenericAgGrid>
        </div>}
    </>
    )
}
export default memo(Bill);