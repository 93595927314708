/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNavigation = /* GraphQL */ `
  mutation CreateNavigation(
    $input: CreateNavigationInput
    $condition: ModelNavigationConditionInput
  ) {
    createNavigation(input: $input, condition: $condition) {
      status_id
      loan_status
      screen_id
      name
      path
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateNavigation = /* GraphQL */ `
  mutation UpdateNavigation(
    $input: UpdateNavigationInput
    $condition: ModelNavigationConditionInput
  ) {
    updateNavigation(input: $input, condition: $condition) {
      status_id
      loan_status
      screen_id
      name
      path
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteNavigation = /* GraphQL */ `
  mutation DeleteNavigation(
    $input: DeleteNavigationInput
    $condition: ModelNavigationConditionInput
  ) {
    deleteNavigation(input: $input, condition: $condition) {
      status_id
      loan_status
      screen_id
      name
      path
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: updateUserInput) {
    updateUser(input: $input) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      role_id
      role_name
      created_date
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      role_id
      role_name
      created_date
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      role_id
      role_name
      created_date
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      server_time
      loan_comment_id
      loan_number
      comment_type
      comment_description
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      server_time
      loan_comment_id
      loan_number
      comment_type
      comment_description
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      server_time
      loan_comment_id
      loan_number
      comment_type
      comment_description
      created_date
      created_by
      modified_date
      modified_by
    }
  }
`;
export const createUserRoleMapping = /* GraphQL */ `
  mutation CreateUserRoleMapping(
    $input: CreateUserRoleMappingInput
    $condition: ModelUserRoleMappingConditionInput
  ) {
    createUserRoleMapping(input: $input, condition: $condition) {
      user_id
      role_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateUserRoleMapping = /* GraphQL */ `
  mutation UpdateUserRoleMapping(
    $input: UpdateUserRoleMappingInput
    $condition: ModelUserRoleMappingConditionInput
  ) {
    updateUserRoleMapping(input: $input, condition: $condition) {
      user_id
      role_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteUserRoleMappingByUserId = /* GraphQL */ `
  mutation DeleteUserRoleMappingByUserId(
    $input: DeleteUserRoleMappingByUserIdInput
    $condition: ModelUserRoleMappingConditionInput
  ) {
    deleteUserRoleMappingByUserId(input: $input, condition: $condition) {
      user_id
      role_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteUserRoleMappingByRoleId = /* GraphQL */ `
  mutation DeleteUserRoleMappingByRoleId(
    $input: DeleteUserRoleMappingByRoleIdInput
    $condition: ModelUserRoleMappingConditionInput
  ) {
    deleteUserRoleMappingByRoleId(input: $input, condition: $condition) {
      user_id
      role_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const createScreenRoleMapping = /* GraphQL */ `
  mutation CreateScreenRoleMapping(
    $input: CreateScreenRoleMappingInput
    $condition: ModelScreenRoleMappingConditionInput
  ) {
    createScreenRoleMapping(input: $input, condition: $condition) {
      screen_id
      role_id
    }
  }
`;
export const updateScreenRoleMapping = /* GraphQL */ `
  mutation UpdateScreenRoleMapping(
    $input: UpdateScreenRoleMappingInput
    $condition: ModelScreenRoleMappingConditionInput
  ) {
    updateScreenRoleMapping(input: $input, condition: $condition) {
      screen_id
      role_id
    }
  }
`;
export const deleteScreenRoleMapping = /* GraphQL */ `
  mutation DeleteScreenRoleMapping(
    $input: DeleteScreenRoleMappingInput
    $condition: ModelScreenRoleMappingConditionInput
  ) {
    deleteScreenRoleMapping(input: $input, condition: $condition) {
      screen_id
      role_id
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const createDashboard = /* GraphQL */ `
  mutation CreateDashboard(
    $input: CreateDashboardInput
    $condition: ModelDashboardConditionInput
  ) {
    createDashboard(input: $input, condition: $condition) {
      dashboard_id
      dashboard_name
      logi_dashboard_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateDashboard = /* GraphQL */ `
  mutation UpdateDashboard(
    $input: UpdateDashboardInput
    $condition: ModelDashboardConditionInput
  ) {
    updateDashboard(input: $input, condition: $condition) {
      dashboard_id
      dashboard_name
      logi_dashboard_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteDashboard = /* GraphQL */ `
  mutation DeleteDashboard(
    $input: DeleteDashboardInput
    $condition: ModelDashboardConditionInput
  ) {
    deleteDashboard(input: $input, condition: $condition) {
      dashboard_id
      dashboard_name
      logi_dashboard_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      report_id
      report_name
      logi_report_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      report_id
      report_name
      logi_report_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      report_id
      report_name
      logi_report_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const createUserGroupMapping = /* GraphQL */ `
  mutation CreateUserGroupMapping(
    $input: CreateUserGroupMappingInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    createUserGroupMapping(input: $input, condition: $condition) {
      user_id
      group_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateUserGroupMapping = /* GraphQL */ `
  mutation UpdateUserGroupMapping(
    $input: UpdateUserGroupMappingInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    updateUserGroupMapping(input: $input, condition: $condition) {
      user_id
      group_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteUserGroupMappingByUserId = /* GraphQL */ `
  mutation DeleteUserGroupMappingByUserId(
    $input: DeleteUserGroupMappingByUserIdInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    deleteUserGroupMappingByUserId(input: $input, condition: $condition) {
      user_id
      group_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const deleteUserGroupMappingByGroupId = /* GraphQL */ `
  mutation DeleteUserGroupMappingByGroupId(
    $input: DeleteUserGroupMappingByGroupIdInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    deleteUserGroupMappingByGroupId(input: $input, condition: $condition) {
      user_id
      group_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateGroupDashboardList = /* GraphQL */ `
  mutation UpdateGroupDashboardList(
    $input: UpdateUserGroupDashboardListInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    updateGroupDashboardList(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateGroupReportList = /* GraphQL */ `
  mutation UpdateGroupReportList(
    $input: UpdateUserGroupReportListInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    updateGroupReportList(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateUserPassword = /* GraphQL */ `
  mutation UpdateUserPassword($input: UpdateUserPasswordInput) {
    updateUserPassword(input: $input) {
      user_id
      maas_user_id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
export const createBusinessRule = /* GraphQL */ `
  mutation CreateBusinessRule($input: CreateBusinessRule) {
    createBusinessRule(input: $input) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const createBusinessRuleMapping = /* GraphQL */ `
  mutation CreateBusinessRuleMapping($input: CreateBusinessRuleMapping) {
    createBusinessRuleMapping(input: $input) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const updateBusinessRule = /* GraphQL */ `
  mutation UpdateBusinessRule($input: UpdateBusinessRule) {
    updateBusinessRule(input: $input) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const deleteBusinessRule = /* GraphQL */ `
  mutation DeleteBusinessRule($input: DeleteBusinessRule) {
    deleteBusinessRule(input: $input) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const createBussinessRuleInBulk = /* GraphQL */ `
  mutation CreateBussinessRuleInBulk($value: String, $inputLimit: Int) {
    createBussinessRuleInBulk(value: $value, inputLimit: $inputLimit) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const createBusinessRuleMappingInBulk = /* GraphQL */ `
  mutation CreateBusinessRuleMappingInBulk($value: String) {
    createBusinessRuleMappingInBulk(value: $value) {
      rule_id
      rule_view
      rule_message
      rule_criteria
      rule_row_message
      rule_description
      rule_key_column
      input_output_mapping_id
      is_active
      is_advance_sql
      template_filename_format
      updated_date
    }
  }
`;
export const createRuleGroup = /* GraphQL */ `
  mutation CreateRuleGroup($input: CreateRuleGroupInput) {
    createRuleGroup(input: $input) {
      rule_group_id
      rule_group_name
      post_process_function
    }
  }
`;
export const createRuleGroupMapping = /* GraphQL */ `
  mutation CreateRuleGroupMapping($input: RuleGroupMappingInput) {
    createRuleGroupMapping(input: $input) {
      rule_group_mapping_id
      rule_id
      rule_group_id
      rule_property1
    }
  }
`;
export const updateRuleGroup = /* GraphQL */ `
  mutation UpdateRuleGroup($input: UpdateRuleGroupInput) {
    updateRuleGroup(input: $input) {
      rule_group_id
      rule_group_name
      post_process_function
    }
  }
`;
export const deleteRuleGroupByRuleGroupId = /* GraphQL */ `
  mutation DeleteRuleGroupByRuleGroupId($input: DeleteRuleGroupInput) {
    deleteRuleGroupByRuleGroupId(input: $input) {
      rule_group_id
      rule_group_name
      post_process_function
    }
  }
`;
export const deleteRuleGroupMappingByRuleGroupId = /* GraphQL */ `
  mutation DeleteRuleGroupMappingByRuleGroupId($input: DeleteRuleGroupInput) {
    deleteRuleGroupMappingByRuleGroupId(input: $input) {
      rule_group_mapping_id
      rule_id
      rule_group_id
      rule_property1
    }
  }
`;
export const updateGroupSourcesList = /* GraphQL */ `
  mutation UpdateGroupSourcesList(
    $input: UpdateGroupSourceListInput
    $condition: ModelUserGroupMappingConditionInput
  ) {
    updateGroupSourcesList(input: $input, condition: $condition) {
      group_id
      group_name
      dashboards_list
      reports_list
      logi_user_id
      data_sources_list
      rule_group_id
      transformer_logs_id
      created_by
      created_date
      modified_by
      modified_date
    }
  }
`;
export const updateInputOutputTemplateMapping = /* GraphQL */ `
  mutation UpdateInputOutputTemplateMapping(
    $input: updateInputOutputTemplatInput
  ) {
    updateInputOutputTemplateMapping(input: $input)
  }
`;
export const updateUserGroupRecords = /* GraphQL */ `
  mutation UpdateUserGroupRecords($input: UpdateRecordsInput) {
    updateUserGroupRecords(input: $input)
  }
`;
export const createLoanTagMapping = /* GraphQL */ `
  mutation CreateLoanTagMapping($input: CreateTagLoanMappingInput) {
    createLoanTagMapping(input: $input)
  }
`;
export const updateLoanTagMapping = /* GraphQL */ `
  mutation UpdateLoanTagMapping($input: UpdateTagLoanMappingInput) {
    updateLoanTagMapping(input: $input)
  }
`;
export const updateLoanTagComment = /* GraphQL */ `
  mutation UpdateLoanTagComment($input: UpdateTagCommentInput) {
    updateLoanTagComment(input: $input)
  }
`;
export const deleteLoanTagMapping = /* GraphQL */ `
  mutation DeleteLoanTagMapping($input: DeleteTagLoanInput) {
    deleteLoanTagMapping(input: $input)
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput) {
    createTag(input: $input)
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput) {
    updateTag(input: $input)
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput) {
    deleteTag(input: $input)
  }
`;
export const verticaCrudAsap = /* GraphQL */ `
  mutation VerticaCrudAsap($input: [VerticaCrudPayload]) {
    verticaCrudAsap(input: $input)
  }
`;
export const createBulkLoanTagMapping = /* GraphQL */ `
  mutation CreateBulkLoanTagMapping(
    $input: [CreateTagLoanMappingInput]
    $isSelectAll: String
    $scopeId: String
    $filterData: String
  ) {
    createBulkLoanTagMapping(
      input: $input
      isSelectAll: $isSelectAll
      scopeId: $scopeId
      filterData: $filterData
    )
  }
`;
export const deleteBulkLoanTagMapping = /* GraphQL */ `
  mutation DeleteBulkLoanTagMapping($input: BulkDeleteTagLoanInput) {
    deleteBulkLoanTagMapping(input: $input)
  }
`;
export const updateFnmaBill = /* GraphQL */ `
  mutation UpdateFnmaBill(
    $input: UpdateFnmaBillInput
    $condition: ModelFnmaBillConditionInput
  ) {
    updateFnmaBill(input: $input, condition: $condition)
  }
`;
export const updateDatabase = /* GraphQL */ `
  mutation UpdateDatabase($input: String) {
    updateDatabase(input: $input)
  }
`;
export const createTagSync = /* GraphQL */ `
  mutation CreateTagSync($input: CreateTagInput) {
    createTagSync(input: $input)
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput) {
    createDocument(input: $input) {
      document_upload_detail_id
      document_name
      uploaded_date
      s3_bucket_name
      inserted_date
      inserted_by
      updated_date
      updated_by
    }
  }
`;
export const createUserActivityLog = /* GraphQL */ `
  mutation CreateUserActivityLog($input: CreateUserActivityLog) {
    createUserActivityLog(input: $input) {
      user_activity_log_id
      user_id
      maas_user_id
      activity
      inserted_date
      updated_by
      updated_date
      inserted_by
      browser_name
    }
  }
`;
