/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useRef, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { getDisbursementsAsync } from "../../../redux/reducers/loanReducer";
import formatDateTable from '../../../constants/dateFormatTable';
import formatAmountTable from '../../../constants/formatAmountTable';
import {readAppState}from "../../../redux/reducers/appReducer"
import GenericAgGrid from "../../common/GenericAgGrid";

const Disbursements = (props: any) => {
    const dispatch = useAppDispatch();
    const activeTab = useAppSelector((state: RootState) => state.loan.activeTab);
    const appState = useAppSelector(readAppState);
    const gridDisbursementRef: any = useRef();
    // const gridRef: any = useRef();

    let gridDetails = appState.gridCodeList?.find(g => g.grid_code === 'DISBURSEMENT');

    useEffect(() => {}, [activeTab]);

    
    const getCustomStaticFilter = () => {
        let customFilter = `loan_number = '${props.loan_id}'`;
        return customFilter;
    }
    const columns = [
        { headerName: 'Disbursement Date', field: 'disbursed_date',filter: 'agDateColumnFilter', width: 150,valueFormatter: (param) => formatDateTable(param, true)},
        { headerName: 'Disbursement Type', field: 'sbs_disbursement_type_description', width: 150 },
        { headerName: 'Transaction Type', field: 'transaction_type', width: 150 },
        { headerName: 'Receipt', field: 'receipt', width: 150 },
        { headerName: 'Check#', field: 'check_id', width: 150 },
        { headerName: 'Disbursement Amount', field: 'check_amount', width: 150,filter:'agNumberColumnFilter', valueFormatter: formatAmountTable,type: 'rightAligned' },
        { headerName: 'Pay To', field: 'pay_to', width: 150 },
        { headerName: 'Estimated', field: 'is_estimated', width: 150 },
        { headerName: 'Disbursed', field: 'is_disbursed', width: 150 },
        { headerName: 'Voided', field: 'is_voided', width: 150 },
        { headerName: 'Payment Stop', field: 'is_payment_stopped', width: 150 },
        { headerName: 'Cleared', field: 'is_cleared', width: 150 },
        { headerName: 'Reimbursement', field: 'is_reimbursable', width: 150 }
    ];

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, suppressMovable: true
    }

    return (
        <div >
            <div className="row mb20">
                 
                    {/****Commented for future posibility***
                     <div className="col col-lg-6 col-md-6">
                        <div className="float-end">
                            <a  className="btn btn-outline-primary btn-sm filter_btn" style={{ marginRight: "5px" }}><span>Filter</span><span className="bi bi-filter"></span></a>
                            <a  className="btn  btn-primary btn-sm">Actions <span className="fa fa-caret-down"></span></a>
                        </div>
                    </div> */}
                </div>
            {!!gridDetails && <div>
                        <GenericAgGrid
                            gridColumns={columns}
                            gridDefaultColDef={defaultColDef}
                            gridDetails={gridDetails}
                            rowHeight={40}
                            rowModelType={'serverSide'}
                            modules={[ServerSideRowModelModule]}
                            customStaticFilter={getCustomStaticFilter}
                            parentGridRef={gridDisbursementRef}
                        ></GenericAgGrid>
                    </div>}
            
        </div>
    );
}

export default Disbursements;