import { useState ,useEffect,useRef, forwardRef } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Breadcrumb from "../common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { readAppState, setBreadcrumbLocation } from "../../redux/reducers/appReducer";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import CustomPopupComponent, { ModalType, VariantType } from "../common/CustomPopupComponent";
import { billStatusOptions } from "../../constants/billView";
import { selectCustomStyles } from "../common/CustomStyles";
import { updateFnmaBillAsync,setFnmaBillDetails,readLoanState } from "../../redux/reducers/loanReducer";
import "react-datepicker/dist/react-datepicker.css";
import formatDateTable from "../../constants/dateFormatTable";

const FnmaBillDetailForm = (props: any) => {
    const navigation = useNavigate();
    const loanState = useAppSelector(readLoanState);
    const [statusType, setStatusType] = useState<any>(null);
    const [localStatusList, setLocalStatusList] = useState<any>();
    const [localStage2Date, setLocalStage2Date] = useState<any>(loanState.fnmaBillDetails?.rebuttal_received_stage_2_date?new Date(String(loanState.fnmaBillDetails?.rebuttal_received_stage_2_date)):"");
    const [localStage3Date, setLocalStage3Date] = useState<any>(loanState.fnmaBillDetails?.fnma_response_stage_3_date? new Date(String(loanState.fnmaBillDetails?.fnma_response_stage_3_date)):"");
    const [localStage4Date, setLocalStage4Date] = useState<any>(loanState.fnmaBillDetails?.rebuttal_received_stage_4_date?new Date(String(loanState.fnmaBillDetails?.rebuttal_received_stage_4_date)): "");
    const [localStage5Date, setLocalStage5Date] = useState<any>(loanState.fnmaBillDetails?.fnma_response_stage_5_date?new Date(String(loanState.fnmaBillDetails?.fnma_response_stage_5_date)): "");
    const [statusOptions, setStatusOptions] = useState<any>([]);
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const ref = useRef(null);  
    
    
    useEffect(() => {
      setLocalStatusList(loanState.fnmaBillDetails?.bill_status)

        let billStatusOption=billStatusOptions().map((statusArr) => {
            return {
              value: statusArr[0],
              label: statusArr[1],
            };
          })
        setStatusOptions(billStatusOption);
        let breadcrumbLocation = [...appState.breadcrumbLocation];
        breadcrumbLocation = breadcrumbLocation.filter((x, i) =>
            i <= breadcrumbLocation.findIndex(x => x.redirect_url === "/FNMABillsView")
        );
        breadcrumbLocation.push({
            screen_name: "Edit Bill View",
            redirect_url: "/BillView",
            icon_url: "fad fa-users"
        });
        setStatusType(billStatusOption.find(x => x.value === loanState.fnmaBillDetails?.bill_status))
        dispatch(setBreadcrumbLocation(breadcrumbLocation));
    }, []);

    const CustomInput = forwardRef((props: any, ref) => {
      return (
        <div className="input-group date loan-search-input pe-0 h-auto">
          <input type="text" {...props} ref={ref} className="form-control border border-none"   id="kt_datepicker_3"/>
          <div className="input-group-append " {...props} ref={ref}>
            <span className="input-group-text">
              <i className="fa fa-calendar" ></i>
            </span>
          </div>
        </div>
      )
    });

    const handleChnage = (e, field_name) => {
       let billDetail = { ...loanState.fnmaBillDetails };
      if(field_name=="bill_status"){
         setStatusType(e)
         billDetail[field_name] =(e.value);
      }
      else{
        if(field_name=="rebuttal_received_stage_2_date"){setLocalStage2Date(e)}
        else if(field_name=="fnma_response_stage_3_date"){setLocalStage3Date(e)}
        else if(field_name=="rebuttal_received_stage_4_date"){setLocalStage4Date(e)}
        else if(field_name=="fnma_response_stage_5_date"){setLocalStage5Date(e)}
      }
       dispatch(setFnmaBillDetails({ ...billDetail }));
    };

  const handleSubmitForm=()=>{
      
        const billData = {
            fnma_billing_id:loanState.fnmaBillDetails?.fnma_billing_id,
            bill_status: statusType.value,
            rebuttal_received_stage_2_date:localStage2Date?getFomatedDate(localStage2Date):null,
            fnma_response_stage_3_date:localStage3Date?getFomatedDate(localStage3Date):null,
            rebuttal_received_stage_4_date:localStage4Date?getFomatedDate(localStage4Date):null,
	          fnma_response_stage_5_date:localStage5Date?getFomatedDate(localStage5Date):null,            
            modified_by: appState.currentUser!.maas_user_id,
            modified_date: new Date().toUTCString(),
        }; 
              
        
        dispatch(updateFnmaBillAsync(billData)).then((data: any) => { 
            if (data.payload && data.payload.fnma_billing_id) {            
                setPopup({ ...popup, visible: true, message: "Bill data is updated.", type: ModalType.Snackbar, variant: VariantType.Success });
                setTimeout(() => {
                    setPopup({ ...defaultPopup }); 
                    navigation("/FNMABillsView");               
                }, 2000);
                setStatusType('')
            }
        })
    
  }


  const submitButton = () => {
        if ((loanState.fnmaBillDetails?.bill_status && loanState.fnmaBillDetails?.bill_status !== "" && loanState.fnmaBillDetails?.bill_status != localStatusList) 
          ||(localStage2Date && getFomatedDate(loanState.fnmaBillDetails?.rebuttal_received_stage_2_date) != getFomatedDate(localStage2Date))
          ||(localStage3Date && getFomatedDate(loanState.fnmaBillDetails?.fnma_response_stage_3_date) != getFomatedDate(localStage3Date))
          ||(localStage4Date && getFomatedDate(loanState.fnmaBillDetails?.rebuttal_received_stage_4_date) != getFomatedDate(localStage4Date))
          ||(localStage5Date && getFomatedDate(loanState.fnmaBillDetails?.fnma_response_stage_5_date) != getFomatedDate(localStage5Date))
        ) {
            return (<button className="btn btn-primary text-center btn-block"  type="submit" onClick={() => { handleSubmitForm() }}>Save </button>)
        }
        else {
            return (<button className="btn btn-primary text-center btn-block" type="submit" disabled >Save</button>)
        }
    }

    const getFomatedDate=(date)=>{
       var formatedDate = new Date(date);
       return formatedDate.toLocaleDateString()
    }
    
  return (
    <>
    {popup.visible && (
          <CustomPopupComponent
            message={popup.message}
            isOpen={popup.visible}
            toggle={popup.toggle}
            type={popup.type}
            variant={popup.variant}
          />
        )}
    <div className="pagetitle"><Breadcrumb /></div>
    <section className="section dashboard">
    <div className="block_sect">
      <div className="row" style={{ marginLeft: "0px" }}>
        <div className="col-lg-4">
          <div className="l_info_block">
              <label>Initial Bill # </label>
              <span>{loanState.fnmaBillDetails?.initial_bill_number ? loanState.fnmaBillDetails?.initial_bill_number : "---"}</span>
          </div>
          <div className="l_info_block">
              <label>Bill Group Id </label>
              <span> {loanState.fnmaBillDetails?.bill_group_id ? loanState.fnmaBillDetails?.bill_group_id : "---"} </span>
          </div>
          <div className="l_info_block">
              <label>Second Bill # </label>
              <span> {loanState.fnmaBillDetails?.second_bill_number ? loanState.fnmaBillDetails?.second_bill_number : "---"} </span>
          </div>            
          <div className="l_info_block">
            <label>Portfolio </label>
            <span> {loanState.fnmaBillDetails?.bill_reporting_servicer_name  ? loanState.fnmaBillDetails?.bill_reporting_servicer_name : "---"} </span>
          </div>
          <div className="form-floating multi-select-field ct_form_field select_dd mt-0 mb-0 w-75">
            <Select isClearable={true}
                      styles={selectCustomStyles}
                      className="form-control  multi-select-dd"
                      id="billstatus"
                      options={statusOptions}
                      value={statusType}
                      onChange={(e) => {handleChnage(e, "bill_status");}}
                      placeholder="Select Type..."
                  />
            <label htmlFor="billstatus">Bill Status</label>
          </div> 
        </div> 
        <div className="col-lg-4">                              
          <div className="l_info_block">
            <label>Bill Stage </label>
            <span> {loanState.fnmaBillDetails?.bill_status ? loanState.fnmaBillDetails?.bill_status : "---"} </span>
          </div> 
          <div className="l_info_block ">
            <label>Total Bill Amount </label>
            <span>$ {loanState.fnmaBillDetails?.total_bill_amount ? Number(loanState.fnmaBillDetails?.total_bill_amount).toFixed(2) : "---"} </span>
          </div>
          <div className="l_info_block ">
            <label>Total Loans</label>
            <span> {loanState.fnmaBillDetails?.total_loans ? loanState.fnmaBillDetails?.total_loans : "---"} </span>
          </div>
          <div className="l_info_block ">
            <label>Bill Categories</label>
            <span> {loanState.fnmaBillDetails?.bill_category ? loanState.fnmaBillDetails?.bill_category : "---"} </span>
          </div>
          <div className="l_info_block ">
            <label>Billing Date</label>
            <span> {loanState.fnmaBillDetails?.billing_date ? formatDateTable({value:loanState.fnmaBillDetails?.billing_date}, false) : "---"} </span>
          </div>
        </div>
          <div className="col-lg-4 mt-1">
            
            <div className="l_info_block">
              <label htmlFor="secondbillrebuttalreceiveddate">Second Bill Rebuttal Received Date</label>
              <DatePicker  id="secondbillrebuttalreceiveddate" selected={localStage2Date}  value={localStage2Date} 
              onChange={(e) => {handleChnage(e, "rebuttal_received_stage_2_date");}} dateFormat="MM/dd/yyyy"
                 popperPlacement="bottom"  customInput={<CustomInput inputRef={ref}/>} />
              
            </div> 
            <div className="l_info_block">
              <label htmlFor="secondbillfnmaresponse">Second Bill FNMA Response Date</label>
              <DatePicker id="secondbillfnmaresponse" selected={localStage3Date} value={localStage3Date || "" } 
              popperPlacement="bottom" customInput={<CustomInput inputRef={ref} />} 
                onChange={(e) => {handleChnage(e, "fnma_response_stage_3_date");}}  />
              
            </div>
            <div className="l_info_block">
              <label htmlFor="impasserebuttalreceiveddate">Impasse Rebuttal Received Date</label>
              <DatePicker id="impasserebuttalreceiveddate" selected={localStage4Date} value={localStage4Date || "" } 
              popperPlacement="bottom" customInput={<CustomInput inputRef={ref}/>} 
                 onChange={(e) => {handleChnage(e, "rebuttal_received_stage_4_date");}}  />
              
            </div>
            <div className="l_info_block">
            <label htmlFor="impasserefnmaresponsedate">Impasse FNMA Response Date</label>
              <DatePicker id="impasserefnmaresponsedate" selected={localStage5Date} value={localStage5Date || "" } 
              popperPlacement="bottom" customInput={<CustomInput inputRef={ref}/>} 
                 onChange={(e) => {handleChnage(e, "fnma_response_stage_5_date");}} />
              
            </div>
          </div>
          <div className="row justify-content-md-center mt-4">
              <div className="col-lg-2 col">
                  {submitButton()}</div>
              <div className="col-lg-2 col"><button className="btn blue-outline-btn text-center btn-block" type="submit" onClick={() =>{ navigation('/FNMABillsView'); dispatch(setFnmaBillDetails({ ...loanState?.fnmaBillDetails, is_edit: false}))}}>
                  Cancel</button></div>
          </div>
        </div>
      </div>
    </section>
   
    </>
  );
};

export default FnmaBillDetailForm;
