import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Status } from "../../../constants/commonConstants";
import formatDate from "../../../constants/dateFormat";
import { Loader } from "../../../helpers/loader";
import { getTaxDetailsAsync, readLoanState } from "../../../redux/reducers/loanReducer";

const TaxDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const loanState = useAppSelector(readLoanState);

    useEffect(() => {
        dispatch(getTaxDetailsAsync(props.loan_id));
    }, [])

    return (
        <>
            {loanState.taxDetailsStatus === Status.Loading ? <Loader /> :
                <div className="row">
                    <div className="col">
                        <div className="row payment-boxes">
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Tax Status</label>
                                    <span>{loanState.taxDetails?.tax_status || "TBD"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Tax Frequency</label>
                                    <span>{loanState.taxDetails?.tax_frequency || "TBD"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Last Tax Disbursement Date</label>
                                    <span>{loanState.taxDetails?.last_tax_disbursement_date ? formatDate(String(loanState.taxDetails?.last_tax_disbursement_date)) : "TBD"}</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3">
                                <div className="l_info_block">
                                    <label>Next Tax Disbursement Due Date</label>
                                    <span>{loanState.taxDetails?.next_tax_disbursement_due_date ? formatDate(String(loanState.taxDetails?.next_tax_disbursement_due_date)) : "TBD"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>);
}

export default TaxDetails;