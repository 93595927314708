/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState, useRef } from "react";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import CustomPopupComponent, { ModalType, VariantType } from "../../common/CustomPopupComponent";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { readAppState, setBreadcrumbLocation } from "../../../redux/reducers/appReducer";
import Breadcrumb from "../../common/Breadcrumb";
import { deleteRuleGroupAsync, deleteRuleGroupMappingAsync, getRuleGroupListAsync, setRuleGroupList, setSelectedRuleGroup } from "../../../redux/reducers/ruleReducer";
import { Loader } from "../../../helpers/loader";

const RuleGroupListComponent = () => {
    const [gridApi, setGridApi,] = useState<any>();
    const [rowData, setRowData] = useState<any>(undefined);
    const defaultPopup = { visible: false as any, type: null as any, color: null as any, variant: null as any, message: null as any, toggle: null as any, primaryButtonAction: null as any, primaryButtonName: null as any, secondaryButtonAction: null as any, secondaryButtonName: null as any };
    const [popup, setPopup] = useState(defaultPopup);
    const navigation = useNavigate();
    const appState = useAppSelector(readAppState);
    const dispatch = useAppDispatch();
    const [ruleGroupList, setRuleGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(setBreadcrumbLocation([{
            "screen_id": "",
            "screen_name": "Rule Group Management",
            "redirect_url": "/RuleGroup",
            "icon_url": "fas fa-address-book"
        }]));
    }, []);

    useEffect(() => {
        if (gridApi) {
            setTimeout(() => {
                gridApi.sizeColumnsToFit();
            }, 500)
        }
    }, [appState.isNavMenuOpen])

    const onGridReady = async (params: any) => {
        try {
            dispatch(getRuleGroupListAsync()).then((response) => {
                setRuleGroups(response.payload);
                setRowData(response.payload);
                params.api.paginationGoToPage(10)
                setGridApi(params.api);
                var sort = [{
                    colId: "rule_group_id",
                    sort: "desc"
                }];
                params.api.sizeColumnsToFit();
                params.columnApi.applyColumnState({
                    state: sort,
                    defaultState: { sort: null }
                });
            }).catch(ex => console.log(ex));
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onFilterChanged = (filteredRows) => {
        filteredRows.api.hideOverlay()
        if (filteredRows.api.getDisplayedRowCount() == 0) {
            filteredRows.api.showNoRowsOverlay()
        }
    }

    const handleEditClick = (params: any) => {
        let data = { ...params.data, is_new: false }
        dispatch(setSelectedRuleGroup(data));
        navigation("/RuleGroupManagement")
    }

    const columns: any[] = [
        { headerName: 'Rule Group Id', field: 'rule_group_id', hide: true },
        { headerName: 'Rule Group Name', field: 'rule_group_name', headerTooltip: 'Rule Group Name' },
        {
            headerName: "Used In", valueGetter: (params) => { return params.data.post_process_function ? "Investor Grouping" : "Data Loading" }
        },
        {
            headerName: 'Action', pinned: 'right', filter: false, width: 110, suppressSizeToFit: true, cellRendererFramework: (params) => <div className="grid-action">
                <button onClick={() => { handleEditClick(params) }} title="Edit" className="edit-icon_link"><span className="fas fa-edit"></span></button>
                <button title={"Delete"} onClick={() => setPopup({
                    ...popup,
                    visible: true,
                    message: "Please confirm that you would like to delete this rule group.",
                    type: ModalType.ActionDialog,
                    toggle: () => setPopup({ ...defaultPopup }),
                    primaryButtonAction: () => removeRuleGroup(),
                    primaryButtonName: "Delete",
                    secondaryButtonName: "Cancel",
                    secondaryButtonAction: () => setPopup({ ...defaultPopup })
                })} className="delete_icon_link"><span className="fas fa-trash-alt"></span></button>
            </div>
        }
    ]

    const defaultColDef = {
        editable: false, sortable: true, resizable: true, filter: true, enableTooltip: true
    }
    const gridRef: any = useRef();

    const removeRuleGroup = async () => {
        try {
            setPopup(defaultPopup);;
            setLoading(true);
            const selectedData = gridApi.getSelectedRows();
            const filteredRuleGroups = ruleGroupList && ruleGroupList.length > 0 && ruleGroupList.filter((gr: any) => gr.rule_group_id !== selectedData[0].rule_group_id);
            gridApi.updateRowData({ remove: selectedData });
            dispatch(setRuleGroupList(filteredRuleGroups));
            await dispatch(deleteRuleGroupAsync({ rule_group_id: selectedData[0].rule_group_id }));
            await dispatch(deleteRuleGroupMappingAsync({ rule_group_id: selectedData[0].rule_group_id }));
            setPopup({ ...popup, visible: true, message: "This rule group has been deleted.", type: ModalType.Snackbar, variant: VariantType.Success, toggle: () => setPopup({ ...defaultPopup }) });
            setLoading(false);
        }
        catch (error) {
            console.log(error);
            setLoading(false);
        }

    }

    return (<>
        <div className="pagetitle">
            <div className="row align-items-center">
                <div className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7">
                    <Breadcrumb />
                </div>
                <div className="col col-lg-5 col-md-5 col-sm-5 d-flex flex-row-reverse">
                    <a className="btn btn-primary" onClick={() => { navigation("/RuleGroupManagement"); dispatch(setSelectedRuleGroup({ is_new: true })) }}>
                        <i style={{ fontSize: "15px", paddingRight: "0.25rem" }} className={`far fa-plus-circle`} />
                        Add Rule Group
                    </a>
                </div>
            </div>
        </div>
        <section className="section dashboard">
            {loading && <Loader />}
            {popup.visible && <CustomPopupComponent message={popup.message} primaryButtonAction={popup.primaryButtonAction} primaryButtonName={popup.primaryButtonName} secondaryButtonName={popup.secondaryButtonName} secondaryButtonAction={popup.secondaryButtonAction} isOpen={popup.visible} toggle={popup.toggle} type={popup.type} />}
            <div className="ag-theme-alpine" style={{ height: '72vh', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columns}
                    rowData={rowData}
                    rowSelection={'single'}
                    defaultColDef={defaultColDef}
                    paginationPageSize={10}
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterChanged}
                    overlayNoRowsTemplate={'<span class="ag-overlay-loading-center">No records were found!</span>'}
                    overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
                    modules={[ClientSideRowModelModule]}>
                </AgGridReact>
            </div>
        </section>
    </>
    )
}

export default memo(RuleGroupListComponent);